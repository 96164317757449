import React from 'react';
import { connect } from 'react-redux';
import { faCheckCircle, faEye, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const mapDispatchToProps = {};
const mapStateToProps = () => {};

const TaxInformation = () => (
  <TaxInformationContainer>
    <TaxFieldsGroup>
      <PageHeader>Fields</PageHeader>
      <PageContent>
        <InputsLine>
          <InputGroup>
            <InputLabel>Name</InputLabel>
            <StyledInput type='"text"' />
          </InputGroup>
          <InputGroup>
            <InputLabel>Email</InputLabel>
            <StyledInput type='"text"' />
          </InputGroup>
          <InputGroup>
            <InputLabel>Contact No</InputLabel>
            <StyledInput type='"text"' />
          </InputGroup>
        </InputsLine>
        <InputsLine>
          <InputGroup>
            <InputLabel>Date</InputLabel>
            <StyledInput type='"text"' />
          </InputGroup>
          <InputGroup>
            <InputLabel>Address</InputLabel>
            <StyledInput type='"text"' />
          </InputGroup>
          <InputGroup />
        </InputsLine>
        <InputsLine>
          <InputGroup>
            <InputLabel>Text</InputLabel>
            <StyledInput type='"text"' />
          </InputGroup>
          <InputGroup>
            <InputLabel>Text</InputLabel>
            <StyledInput type='"text"' />
          </InputGroup>
          <InputGroup>
            <InputLabel>Text</InputLabel>
            <StyledInput type='"text"' />
          </InputGroup>
        </InputsLine>
        <InputsLine>
          <InputGroup>
            <InputLabel>Text</InputLabel>
            <StyledInput type='"text"' />
          </InputGroup>
          <InputGroup>
            <InputLabel>Text</InputLabel>
            <StyledInput type='"text"' />
          </InputGroup>
          <InputGroup>
            <InputLabel>Text</InputLabel>
            <StyledInput type='"text"' />
          </InputGroup>
        </InputsLine>
      </PageContent>
    </TaxFieldsGroup>
    <DocumentsContainer>
      <PageHeader>Documents</PageHeader>
      <PageContent>
        <DocumentsLine>
          {Array(4)
            .fill(1)
            .map((index) => (
              <TaxDocumentCard key={index}>
                <CardHeader>
                  <ButtonContainer background="#49d36019">
                    <ButtonIcon icon={faPencilAlt} color="#49D360" />
                  </ButtonContainer>
                  <ButtonContainer background="#4B4D6719">
                    <ButtonIcon icon={faEye} color="#4B4D67" />
                  </ButtonContainer>
                </CardHeader>
                <CardBody>
                  <Title>Lorem Ipsum</Title>
                  <SubHeading>Sith delor</SubHeading>
                </CardBody>
                <CardFooter>
                  <ButtonIcon icon={faCheckCircle} color="#49D360" width={24} height={24} />
                </CardFooter>
              </TaxDocumentCard>
            ))}
        </DocumentsLine>
      </PageContent>
    </DocumentsContainer>
  </TaxInformationContainer>
);

export default connect(mapStateToProps, mapDispatchToProps)(TaxInformation);

interface IconContainerInterface {
  background: string;
}

const ButtonContainer = styled.div`
  margin-left: 7px;
  height: 38px;
  width: 38px;
  padding: 6px 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props: IconContainerInterface) => props.background && props.background};
  border-radius: 14px;
  cursor: pointer;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
  ${(props: { color?: string }) => props.color && `color: ${props.color};`};
`;

const TaxInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  flex: 1;
  min-height: 90vh;
`;

const PageHeader = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  color: #3c3f41;
`;
const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TaxFieldsGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputsLine = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  margin-bottom: 20px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  padding-right: 20px;
`;

const InputLabel = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #bdbdbd;
`;
const StyledInput = styled.input`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #828282;
  border: 0;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 11px;
  &:focus {
    outline: none;
    border: 0;
    border-bottom: 1px solid #f2f2f2;
  }
`;

const TaxDocumentCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
  background: #ffffff;
  border: 1px solid rgba(56, 46, 70, 0.08);
  border-radius: 8px;
  margin-right: 30px;
`;
const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-bottom: 3px solid #f2f2f2;
`;
const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 15px;
  border-bottom: 1px solid #f2f2f2;
`;
const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 13px 15px;
`;
const DocumentsLine = styled.div`
  display: flex;
`;

const Title = styled.span`
  margin: 0;
  margin-bottom: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #4b4d67;
`;
const SubHeading = styled.span`
  margin: 0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #828282;
`;
