import { connect } from 'react-redux';
import QRCode from 'components/ProfileMenu/QrCode/QrCode';
import { getUser } from 'store/selector/auth.selector';
import { Show } from 'modules/Show/show.types';

const mapStateToProps = (state: Show) => {
  const user = getUser(state);
  return { user };
};

export default connect(mapStateToProps, null)(QRCode);
