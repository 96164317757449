import React, { FunctionComponent, useEffect } from 'react';
import RequestItem from 'components/bgPerformer/CastingRequest/RequestItem/RequestItem';
import Style from 'components/bgPerformer/CastingRequest/CastingRequest.module.css';
import src from 'assets/images/no-request.png';
import Button from 'components/controls/button/button';
import handSvg from 'assets/svg/hand.svg';
import reloadSvg from 'assets/svg/reload.svg';
import { Spinner } from 'react-bootstrap';
import { BookingRequest, BookingRequestList } from 'modules/jobs/types';
import { ICancelRequestParams } from 'modules/params/param.type';
import { JOB_STATUS_BOOKED, JOB_STATUS_CANCELLED, SCREEN_SIZE_MOBILE, TOAST_IDS } from 'store/castingPax.constants';
import { useSelector } from 'react-redux';
import { getScreenWidth } from 'store/selector/general.selector';
import { ScreenSize } from 'modules/general/general.type';
import { toast } from 'react-toastify';

interface CastingRequestProps {
  castingRequest: BookingRequestList;
  castingRequestBegin: Function;
  openJobDetails: Function;
  loading: boolean;
  acceptCastingRequestBegin: Function;
  allBookings: BookingRequestList;
}
const CastingRequest: FunctionComponent<CastingRequestProps> = (props: CastingRequestProps) => {
  const { castingRequest, castingRequestBegin, openJobDetails, loading, acceptCastingRequestBegin, allBookings } =
    props;

  useEffect(() => {
    castingRequestBegin({ status: ['requested'] });
  }, []);

  const loadMoreHandle = () => console.log('Load more');

  const notifyError = () => {
    if (!toast.isActive(TOAST_IDS.CANCEL_BOOKING_ID)) {
      toast.error('Cancel booking on existing booking dates', { toastId: TOAST_IDS.CANCEL_BOOKING_ID });
    }
  };

  const handleAction = (job: BookingRequest, status: string) => {
    if (allBookings && job) {
      const currentBooking: any = allBookings.filter((b: BookingRequest) => {
        let checkDate: string = '0';
        if (b.status === JOB_STATUS_BOOKED && b.id !== job.id) {
          job.dates.forEach((x) => {
            if (b.dates.includes(x)) checkDate = '1';
          });
        }
        return checkDate === '0' ? null : b;
      });
      if (currentBooking.length === 0) {
        const params: ICancelRequestParams = { id: job.id, status };
        acceptCastingRequestBegin(params);
      } else notifyError();
    }
  };
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);

  if (currentScreenSize === SCREEN_SIZE_MOBILE) {
    return (
      <div className={Style.castingRequestWrapper}>
        <div className={`${Style.castingRequestTitle} ${Style.requestCastingTitle}`}>
          <img src={handSvg} alt="" />
          <p className="mb-0 ml-2">
            Casting Request
            {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
          </p>
        </div>
        <div className={Style.castingTableMobile}>
          <div className="requestTableWrapperMobile">
            {castingRequest && castingRequest.length > 0 ? (
              <table className="table">
                {castingRequest.map((job: BookingRequest) => (
                  <RequestItem
                    job={job}
                    handleConfirm={() => {
                      handleAction(job, JOB_STATUS_BOOKED);
                    }}
                    handleReject={() => {
                      handleAction(job, JOB_STATUS_CANCELLED);
                    }}
                    key={job.id}
                    handleDetail={openJobDetails}
                  />
                ))}
              </table>
            ) : (
              <div className="text-center mb-3">
                <img src={src} alt="" />
              </div>
            )}
            <div className="text-center mt-3">
              <Button
                label="Load More"
                icon={loading ? '' : reloadSvg}
                showLoading={loading}
                clickHandler={loadMoreHandle}
                cssClass={Style.loadMoreBtn}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={Style.castingRequestWrapper}>
      <div className={Style.castingRequestTitle}>
        <img src={handSvg} alt="" />
        <p className="mb-0 ml-2">
          Casting Request
          {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
        </p>
      </div>
      <div className="text-center">
        <div className="customScrollBar table-responsive requestTableWrapper">
          {castingRequest && castingRequest.length > 0 ? (
            <table className="table">
              {castingRequest.map((job: BookingRequest) => (
                <RequestItem
                  job={job}
                  handleConfirm={() => {
                    handleAction(job, JOB_STATUS_BOOKED);
                  }}
                  handleReject={() => {
                    handleAction(job, JOB_STATUS_CANCELLED);
                  }}
                  key={job.id}
                  handleDetail={openJobDetails}
                />
              ))}
            </table>
          ) : (
            <img src={src} alt="" />
          )}
        </div>
      </div>
      {castingRequest && castingRequest.length > 10 && (
        <div className="text-center">
          <Button
            label="Load More"
            icon={loading ? '' : reloadSvg}
            showLoading={loading}
            clickHandler={loadMoreHandle}
            cssClass={Style.loadMoreBtn}
          />
        </div>
      )}
    </div>
  );
};

export default CastingRequest;
