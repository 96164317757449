import React, { FunctionComponent, useState } from 'react';
import DashboardInput from 'components/controls/input/dashboardInput/dashboardInput';
import Style from 'components/bgPerformer/ExpandedSearch/SaveFilter/SaveFilter.module.css';
import { JobSearchFilter, JobSearchFilterItem, JobSearchFilterList } from 'modules/jobs/types';
import saveSvgGreen from 'assets/svg/save-green.svg';
import saveSvg from 'assets/svg/save.svg';
import { ISaveJobSearchFilter } from 'modules/params/param.type';
import Loader from 'components/controls/Loader/Loader';
import jobApi from 'store/services/job.services';
import { useDispatch } from 'react-redux';
import { updateJobSearchFilterList } from 'store/actions/job.actions';

interface SaveFilterProps {
  filterParam: JobSearchFilter;
  list: JobSearchFilterList;
}

const SaveFilter: FunctionComponent<SaveFilterProps> = (props) => {
  const { filterParam, list } = props;
  const [name, setName] = useState<string>('');
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const isValidName = () =>
    name.length > 0 &&
    ((filterParam.category && filterParam.category.length > 0) ||
      (filterParam.location && filterParam.location?.length > 0));

  const handleSave = async () => {
    if (!isValidName()) return;
    const obj: ISaveJobSearchFilter = {
      title: name,
      params: filterParam,
    };
    setShowLoading(true);
    try {
      const res: any = await jobApi.saveJobSearchFilter(obj);
      dispatch(updateJobSearchFilterList(res));
      setName('');
    } catch (error) {
      console.log('Error!', error);
    } finally {
      setShowLoading(false);
    }
  };

  const showButton = () => {
    if (showLoading) return <Loader isSmallView />;
    return (
      <div onClick={handleSave} className="pr-2" role="presentation">
        <img src={isValidName() ? saveSvgGreen : saveSvg} alt="" />
      </div>
    );
  };

  const handleOnSelect = (item: JobSearchFilterItem) => {
    dispatch(updateJobSearchFilterList(item));
  };

  return (
    <div className={Style.multiSelectWrapper}>
      <div className={`d-flex align-items-center ${Style.searchBottomBorder}`}>
        <DashboardInput
          cssClass={`mb-0 ${Style.searchAddList}`}
          handleChange={setName}
          value={name}
          placeholder="type saved filed name"
        />
        {showButton()}
      </div>
      <div className="pl-3 pt-2">
        <ul className="list-unstyled">
          {list.map((item: JobSearchFilterItem) => (
            <li className="position-relative" key={item.id}>
              <div className="custom-checkbox-wrapper form-group mb-1">
                <input
                  type="checkbox"
                  id={item.title}
                  checked={item.isChecked || false}
                  onChange={() => {
                    handleOnSelect(item);
                  }}
                />
                <label className="mb-0" htmlFor={item.title}>
                  {item.title}
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SaveFilter;
