import React, { FunctionComponent, useState } from 'react';
import Style from 'components/bgPerformer/Booking/Booking.module.css';
import BookingItemDetail from 'components/bgPerformer/Booking/BookingItem/BookingItemDetail';
import exportSvg from 'assets/svg/export.svg';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import { saveBookingData } from 'helpers/utils';
import { Link } from 'react-router-dom';
import PopoverStickOnHover from 'components/controls/Popover/PopoverStick';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import exclamationSvg from 'assets/svg/exclamation-mark.svg';
import BookingButton from 'components/bgPerformer/BookingButton/BookingButton';
import { BookingRequest } from 'modules/jobs/types';
import DateItem from 'components/dateItem/dateItem';

interface BookingItemProps {
  handleDetail?: (e: React.FormEvent<HTMLDivElement>) => void;
  job: BookingRequest;
}
const BookingItem: FunctionComponent<BookingItemProps> = (props: BookingItemProps) => {
  const { handleDetail, job } = props;
  const [toggleDetail, setToggleDetail] = useState(false);
  const handleClick = () => setToggleDetail(!toggleDetail);

  const icon = () => (
    <>
      <BookingButton job={job.jobId.id} jobDates={job.dates} isCancelOnly />
      <div onClick={handleDetail} onKeyUp={handleDetail} tabIndex={0} role="button">
        <img src={exportSvg} alt="" className="ml-3" />
      </div>
    </>
  );

  const handleLinkClick = (event: any) => {
    event.preventDefault();
  };

  const handleContextClick = () => {
    saveBookingData.save(job);
  };

  return (
    <>
      <div className={Style.bookingItemWrapper}>
        <div className={`${Style.bookingTableWrapper}`}>
          <div className="bookingItem">
            <PopoverStickOnHover item={job} status="call" fullDetailHandle={handleDetail}>
              <Link
                className={`mb-0 text-decoration-none d-block ${Style.castingCallTitle}`}
                to="/performer/home"
                onClick={handleLinkClick}
                onContextMenu={handleContextClick}
              >
                <DateItem cssClass={Style.columnMargin} dates={job.dates} />
              </Link>
            </PopoverStickOnHover>
          </div>
          <div className="bookingItem">
            <p className={`mb-0 ${Style.bookingItemDesc}`}>
              {job.jobId?.title}
              {job.isDateChange && (
                <PopoverContainer itemComponent={() => {}} toolTip="Job date has been changed">
                  <img src={exclamationSvg} alt="exclamation mark" className={Style.exclamationMark} />
                </PopoverContainer>
              )}
            </p>
          </div>
          <div className="bookingItem">
            <div className="d-flex justify-content-end">
              <div className="d-flex align-items-center">
                {icon()}
                <div className="ml-3">
                  <div tabIndex={0} role="button" onKeyPress={handleClick} onClick={handleClick}>
                    <img src={downArrowSvg} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {toggleDetail && job.jobId && <BookingItemDetail job={job.jobId} />}
      </div>
    </>
  );
};

export default BookingItem;
