import React, { useState } from 'react';
import styled from 'styled-components';
import StyledDropdown from 'views/Payroll/styles/bg-dropdown/Dropdown.styled';
import Modal from 'components/Payroll/VoucherManagement/voucher/utils/modal';
import StyledBgCordinator from '../../../styles/show/components/BgCordinator.style';
import StyledBgCordinatorWrapper from '../../../styles/show/components/BgCordinatorWrapper';
import DownArrow from '../../common/DownArrow';
import DropdownItem from './DropdownItem';

interface Props {
  coordinators: any;
}

const BgCordinator: React.FC<Props> = (props: Props) => {
  const { coordinators } = props;
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const toggleDropdown = () => setOpenDropdown(!openDropdown);

  return (
    <StyledBgCordinatorWrapper onClick={toggleDropdown} open={openDropdown}>
      <StyledBgCordinator>
        <p>BG Coordinator</p>
        <div>
          <DownArrow color=" #2D9CDB" toggleIcon={false} />
        </div>
      </StyledBgCordinator>
      <StyledDropDownWrapper open={openDropdown}>
        <StyledDropdown>
          <div>
            {coordinators.map((item: any) => (
              <DropdownItem key={item.id} imageUrl={item.avatar} role={item.name} id={item.id} />
            ))}
          </div>
        </StyledDropdown>
      </StyledDropDownWrapper>
      {openDropdown && <Modal closeModal={toggleDropdown} />}
    </StyledBgCordinatorWrapper>
  );
};

export default BgCordinator;

interface DropdownProps {
  open?: boolean;
}

export const StyledDropDownWrapper = styled.div<DropdownProps>`
  height: fit-content;
  position: relative;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  box-sizing: border-box;
  position: absolute;
  padding-top: 5px;
  width: 167px;
  color: blue;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 121px;
  gap: 14px;
  left: calc(50% - 167px / 2 + 10px);
  top: 45px;
  border: 2px solid ${({ theme }) => theme.colors.lightBlue};
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border: 2px solid ${({ theme }) => theme.colors.lightBlue};
  z-index: 7;

  &::after {
    content: '';
    position: absolute;
    height: 14px;
    width: 14px;
    right: 24px;
    top: -8px;
    background: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.lightBlue};
    border-right: transparent;
    border-bottom: transparent;
    transform: rotate(45deg);
  }
`;
