import createSelector from 'utils/reselect';

const performerStateData = (state: any) => {
  const { performer } = state;
  return performer || {};
};

export const getProfile = createSelector(performerStateData, (performer) => performer.getIn(['profile', 'data']));

export const getResidencyDocs = createSelector(performerStateData, (performer) =>
  performer.get('residency_docs' || []),
);

export const getCounter = createSelector(performerStateData, (performer) => performer.get('counter'));

export const fileUploadErros = createSelector(
  performerStateData,
  (performer) => performer.get('fileUploadError') || [],
);

export const getImageFiles = createSelector(performerStateData, (performer) => performer.get('imageFiles') || []);

export const getFolderId = createSelector(performerStateData, (performer) => performer.get('folderId') || '');

export const getEditableId = createSelector(performerStateData, (performer) => performer.get('editableId') || null);

export const profileLoading = createSelector(performerStateData, (performer) =>
  performer.getIn(['profile', 'loading']),
);

export const getPerformerSkills = createSelector(performerStateData, (performer) => performer.get('profile'));

export const savingSkills = createSelector(performerStateData, (performer) => performer.get('skillLoading'));

export const fullViewImage = createSelector(performerStateData, (performer) => performer.get('fullViewImage'));

export const showLoading = createSelector(performerStateData, (performer) => performer.get('loading'));

export const showResumeLoading = createSelector(
  performerStateData,
  (performer) => performer.get('resumeLoading') || false,
);

export const getRentalLoading = createSelector(
  performerStateData,
  (performer) => performer.get('rentalLoading') || false,
);

export const getVoucherLoading = createSelector(
  performerStateData,
  (performer) => performer.get('voucherLoading') || false,
);

export const getVoucher = createSelector(performerStateData, (performer) => performer.get('voucherInfo') || {});
