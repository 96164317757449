import React, { FunctionComponent, useState } from 'react';
import Style from 'components/castingDirector/PopOvers/CoordinatorDetail/Coordinator.module.css';
import crewCallTimeSvg from 'assets/svg/crew-call-time.svg';
import saveSvg from 'assets/svg/save-green.svg';
import showApi from 'store/services/show.services';
import removeSvg from 'assets/svg/remove-2.svg';
import { MatrixType } from 'modules/Show/show.types';
import { LOADER_STATUS } from 'store/castingPax.constants';
import Loader from 'components/controls/Loader/Loader';

interface ICrewCallDetailProps {
  workingDay: MatrixType;
  handleCrewCall: Function;
  cssClass?: string;
}
const CrewCallDetail: FunctionComponent<ICrewCallDetailProps> = (props) => {
  const { workingDay, handleCrewCall, cssClass } = props;

  const [callTime, setCalltime] = useState<string>(
    workingDay.crewCall && workingDay.crewCall !== ' ' ? workingDay.crewCall : '',
  );
  const [isLoading, setIsLoading] = useState<number>(LOADER_STATUS.NA);
  const [callTimeError, setCallTimeError] = useState<boolean>(false);

  const validateTime = (time: any) => {
    const validTime = time.replaceAll(/\s/g, '');
    return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])$/.test(validTime);
  };

  const handleDeleteCrewCall = () => {
    setIsLoading(LOADER_STATUS.PENDING);
    showApi
      .uploadCrewCall({
        id: workingDay.id,
        callTime: ' ',
      })
      .then(() => {
        setIsLoading(LOADER_STATUS.COMPLETED);
        setCalltime('');
        handleCrewCall('');
      })
      .catch((err: any) => {
        console.log(err.message);
        setIsLoading(LOADER_STATUS.COMPLETED);
      });
  };

  const handleSubmit = () => {
    if (workingDay.crewCall !== callTime) {
      if (validateTime(callTime)) {
        setCallTimeError(false);
        setIsLoading(LOADER_STATUS.PENDING);
        showApi
          .uploadCrewCall({
            id: workingDay.id,
            callTime,
          })
          .then(() => {
            setIsLoading(LOADER_STATUS.COMPLETED);
            handleCrewCall(callTime);
          })
          .catch((err: any) => {
            console.log(err.message);
            setIsLoading(LOADER_STATUS.COMPLETED);
          });
      } else setCallTimeError(true);
    }
  };

  return (
    <div className={`${Style.mainWrapper} ${cssClass} pb-0`}>
      <div className={`${Style.searchSection} d-flex`}>
        <img src={crewCallTimeSvg} alt="" className={Style.searchIcon} />
        <input
          onBlur={(e) => setCalltime(e.target.value)}
          className={callTimeError ? 'border-danger' : ''}
          type="text"
          value={callTime}
          onChange={(e) => setCalltime(e.target.value)}
          placeholder="Enter Crew Call"
        />
        {workingDay.crewCall && callTime && workingDay.crewCall === callTime ? (
          <span
            onClick={() => handleDeleteCrewCall()}
            onKeyPress={() => handleDeleteCrewCall()}
            role="button"
            tabIndex={0}
            className="mt-2 mr-3"
          >
            {isLoading === LOADER_STATUS.PENDING ? (
              <Loader isSmallView />
            ) : (
              <img src={removeSvg} style={{ width: '15px' }} alt="" />
            )}
          </span>
        ) : (
          <div onClick={handleSubmit} onKeyUp={handleSubmit} className="mt-2 mr-3" role="button" tabIndex={0}>
            {isLoading === LOADER_STATUS.PENDING ? (
              <Loader isSmallView />
            ) : (
              <img className="ml-2" src={saveSvg} alt="" style={{ width: '15px' }} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CrewCallDetail;
