import React, { FunctionComponent, useEffect, useState } from 'react';
import api from 'store/services/show.services';
import Header from 'components/topHeader/topHeader';
import signupCatStyle from 'components/singupCategory/signupCategory.module.css';
import { LOADER_STATUS } from 'store/castingPax.constants';
import Loader from 'components/controls/Loader/Loader';

interface IConfirmCallSheetProps {
  match: any;
}
const ConfirmCallSheet: FunctionComponent<IConfirmCallSheetProps> = (props) => {
  const { match } = props;
  const { token, workingDayId } = match.params;
  const [response, setResponse] = useState<string>('');
  const [loader, setLoader] = useState<number>(LOADER_STATUS.NA);

  useEffect(() => {
    try {
      if (token) {
        setLoader(LOADER_STATUS.PENDING);
        api
          .validateConfirmCallSheet({
            token,
            workingDayId,
          })
          .then((res) => {
            if (res.result) {
              setResponse('Thank you for confirming your call time');
              setLoader(LOADER_STATUS.COMPLETED);
            }
          })
          .catch((err: any) => {
            console.log('err', err.message);
            setResponse('Link is expired');
            setLoader(LOADER_STATUS.COMPLETED);
          });
      }
    } catch (err: any) {
      setLoader(LOADER_STATUS.COMPLETED);
      console.log('err', err.message);
    }
  }, []);

  return (
    <>
      <Header />
      <div className={signupCatStyle.backgroundWrapper}>
        <div className={signupCatStyle.bgInnerContent}>
          {loader ? (
            <Loader isSmallView />
          ) : (
            <>
              <h1>{response}</h1>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ConfirmCallSheet;
