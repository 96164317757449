import React, { useState } from 'react';
// import StyledDropdown from 'views/Payroll/styles/bg-dropdown/Dropdown.styled';
import StyledDropDownWrapper from 'views/Payroll/styles/bg-dropdown/DropdownWrapper.styled';
import styled from 'styled-components';
// import Style from 'components/controls/Modal/Modal.module.css';
import CheckBoxItem from './CheckBoxItem.component';

interface Props {
  checked: boolean;
  toggleCheck: () => void;
  className?: string;
  handleFilter: Function;
}

const HideCompletedMenu: React.FC<Props> = (props: Props) => {
  const { handleFilter } = props;
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const toggleDropdown = () => setOpenDropdown(!openDropdown);
  return (
    <>
      <CustomDropDown open onClick={toggleDropdown}>
        <CheckBoxItem label="Hide Completed" handleFilter={handleFilter} />
      </CustomDropDown>
    </>
  );
};

export default HideCompletedMenu;

const CustomDropDown = styled(StyledDropDownWrapper)`
  position: absolute;
  margin-top: 18px;
  left: 0px;
  width: 200px;
  height: 80px;
  padding: 10px 5px;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0px;
`;
