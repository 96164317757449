import React, { FunctionComponent } from 'react';
import { Table } from 'react-bootstrap';
import Item from 'components/Producer/Matrix/Invoices/item';
import Loader from 'components/controls/Loader/Loader';
import { DailyInvoice, DailyInvoiceList } from 'modules/general/general.type';

interface IDailyCostProps {
  items: DailyInvoiceList;
  loading: boolean | undefined;
  print: any;
}
const DailyCostItem: FunctionComponent<IDailyCostProps> = (props: IDailyCostProps) => {
  const { items, loading, print } = props;
  return (
    <>
      <Table striped ref={print}>
        <thead>
          <tr>
            <th>Date</th>
            <th style={{ width: '50%' }}>Description</th>
            <th>Amount</th>
            <th>Invoice No</th>
            <th>Pdf</th>
          </tr>
        </thead>
        {loading ? (
          <Loader isSmallView />
        ) : (
          <tbody>{items && items.map((item: DailyInvoice, index: number) => <Item key={index} item={item} />)}</tbody>
        )}
      </Table>
      <br />
    </>
  );
};

export default DailyCostItem;
