import { connect } from 'react-redux';
import Voucher from 'components/Payroll/VoucherManagement/voucher/Voucher/Voucher';
import { Show } from 'modules/Show/show.types';
import {
  breakdownPdf,
  getMatrixData,
  getMatrixSearchSortingOrder,
  getSelectedShow,
  getSelectedShowDate,
  getSelectedShowId,
  getSelectedworkingDayId,
  getShowCalendarDates,
  getShowSelectedDates,
  isMatrixLoading,
  matrixPagination,
} from 'store/selector/show.selector';
import {
  getMatrixDataBegin,
  searchExistingMatrix,
  rateFilterExistingMatrix,
  errorNotesFilterExistingMatrix,
  hideFilterExistingMatrix,
  setDatesForFitler,
  sortMatrixData,
  fetchMatrixPerformerBegin,
} from 'store/actions/show.actions';

const mapStateToProps = (state: Show) => {
  const selectedWorkingDayId = getSelectedworkingDayId(state);
  const selectedShowDate = getSelectedShowDate(state);
  const showCalendarDates = getShowCalendarDates(state);
  const selectedShow = getSelectedShow(state);
  const dates = getShowSelectedDates(state);
  const showId = getSelectedShowId(state);
  const matrixData = getMatrixData(state);
  const sortingOrder = getMatrixSearchSortingOrder(state);
  const pagination = matrixPagination(state);
  const loading = isMatrixLoading(state);
  const breakdownPdfSelector = breakdownPdf(state);

  return {
    selectedShow,
    showCalendarDates,
    selectedShowDate,
    selectedWorkingDayId,
    dates,
    showId,
    matrixData,
    sortingOrder,
    pagination,
    loading,
    breakdownPdfSelector,
  };
};

const mapDispatchToProps = {
  getMatrixDataBegin,
  sortMatrixData,
  setDatesForFitler,
  searchExistingMatrix,
  rateFilterExistingMatrix,
  errorNotesFilterExistingMatrix,
  hideFilterExistingMatrix,
  fetchMatrixPerformer: fetchMatrixPerformerBegin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Voucher);
