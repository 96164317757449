import Button from 'components/controls/button/button';
import DashboardTextarea from 'components/controls/textArea/dashbaordTextarea/dashboardTextarea';
import React, { FunctionComponent, useState } from 'react';

interface CancelReasonProps {
  handleCancel: Function;
  btnLabel?: string;
  placeholderTxt?: string;
}
const CancelReason: FunctionComponent<CancelReasonProps> = (props: CancelReasonProps) => {
  const { handleCancel, btnLabel, placeholderTxt } = props;
  const [reason, setReason] = useState<string>('');
  const [disable, setDisable] = useState<boolean>(true);

  return (
    <div>
      <DashboardTextarea
        placeholder={placeholderTxt ?? 'please enter reason'}
        inputChangeHandler={(e: React.FormEvent<HTMLTextAreaElement>) => {
          const val = e.currentTarget.value;
          setReason(val);
          if (val && val.length > 5) {
            setDisable(false);
          }
        }}
      />
      <div className="text-center">
        <Button cssClass="mr-2" type="customCancelBtn" label="Close" clickHandler={() => document.body.click()} />
        <Button
          type="customSaveBtn"
          label={btnLabel ?? 'Confirm'}
          isDisabled={disable}
          clickHandler={() => {
            handleCancel(reason);
          }}
        />
      </div>
    </div>
  );
};

export default CancelReason;
