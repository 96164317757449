import styled from 'styled-components';

const StyledShowInfo = styled.div`
  padding: 23px 30px;
  height: fit-content;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  @media only screen and (max-width: 1199px) {
    padding: 15px 15px;
  }
`;

export default StyledShowInfo;
