import { createContext } from 'react';

export type PusherContextType = {
  tiggerPusherMessage: (message: any) => void;
  unSubscribeChannel: () => void;
};

const PusherContext = createContext<PusherContextType>({
  tiggerPusherMessage: () => console.log('Hello'),
  unSubscribeChannel: () => console.log('Unsubscribe'),
});
export default PusherContext;
