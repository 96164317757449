import React, { FunctionComponent } from 'react';
import dashboardInputStyle from 'components/controls/input/dashboardInput/dashboardinput.module.css';

interface DashboardInputProps {
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  type?: string;
  value?: string;
  cssClass?: string;
  inputLabel?: string;
  requiredField?: boolean;
  handleChange?: Function;
  inputChangeHandler?: (event: React.FormEvent<HTMLInputElement>) => void;
  length?: number;
  pattern?: any;
  minVal?: string;
}

const DashboardInput: FunctionComponent<DashboardInputProps> = (props: DashboardInputProps) => {
  const {
    label,
    placeholder,
    value,
    type,
    handleChange,
    errorMessage,
    inputChangeHandler,
    cssClass,
    inputLabel,
    length,
    requiredField,
    pattern,
    minVal,
  } = props;

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;

    if (handleChange) {
      if (type === 'number' && minVal !== undefined) {
        const numericValue = parseFloat(newValue);
        const minNumeric = parseFloat(minVal);

        if (!Number.isNaN(numericValue) && numericValue < minNumeric) {
          handleChange(minVal);
        } else {
          handleChange(newValue);
        }
      } else {
        handleChange(newValue);
      }
    }
  };

  const loadInput = () => (
    <>
      <span className={`${inputLabel} ${requiredField && dashboardInputStyle.requiredField}`}>{label}</span>
      <input
        pattern={pattern}
        value={value}
        type={type}
        min={minVal}
        maxLength={length}
        placeholder={placeholder}
        className={dashboardInputStyle.dashboardInput}
        onChange={handleOnChange}
      />
    </>
  );

  return (
    <div className={`form-group ${dashboardInputStyle.dashboardInputWrapper} ${cssClass}`}>
      {loadInput()}
      {errorMessage ? <span className={dashboardInputStyle.errorMessage}>{errorMessage}</span> : ''}
    </div>
  );
};

DashboardInput.defaultProps = {
  length: 250,
};

export default DashboardInput;
