import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PlaceholderImage from 'assets/images/dropzone-placeholder.png';
import Style from 'components/bgPerformer/Images/Image.module.css';
import deleteSvg from 'assets/svg/delete-red.svg';
import checkSvg from 'assets/svg/check.svg';
import exclamationSvg from 'assets/svg/exclamation-mark.svg';
import InputStyle from 'components/controls/dropzone/imageStyle.module.css';
import Loader from 'components/controls/Loader/Loader';
import PdfIcon from 'assets/svg/pdf_icon.svg';
import { fileUploadingProgress } from 'store/selector/auth.selector';
import { useDispatch, useSelector } from 'react-redux';
import { getImageFiles } from 'store/selector/performer.selector';
import { deleteFileError, imageUploadFiles } from 'store/actions/performer.actions';
import PopoverContainer from '../Popover/PopoverContainer';

interface DropZoneProps {
  type: string;
  fileUploadHandler: Function;
  loader: boolean;
  closeDialog: Function;
  isMultiple?: Boolean;
}

const DropZone: FunctionComponent<DropZoneProps> = (props: DropZoneProps) => {
  const { fileUploadHandler, type, loader, closeDialog, isMultiple } = props;
  const [file, setFile] = useState<File[]>([]);
  const showFileUploadingProgress = useSelector(fileUploadingProgress);
  const prevImageFiles = useSelector(getImageFiles);
  const [prevFiles, setPrevFiles] = useState<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (type !== 'pdf' && type !== 'rental') {
      setPrevFiles(prevImageFiles);
      fileUploadHandler(prevImageFiles, false);
      if (prevImageFiles.length > 0 && prevImageFiles.filter((f: any) => f.status !== 'success').length === 0) {
        closeDialog();
      }
    }
  }, [prevImageFiles]);

  useEffect(() => {
    console.log('');
    return () => closeDialog();
  }, []);

  const getUrl = (f: File) => {
    if (type === 'pdf') return PdfIcon;
    if (f instanceof Blob) {
      return URL.createObjectURL(f);
    }
    return '';
  };

  const onDrop = (acceptedFiles: any) => {
    if (type === 'pdf') {
      if (isMultiple && acceptedFiles && acceptedFiles[0]) {
        const tmpfile: File[] = file;
        for (let i = 0; i < acceptedFiles.length; i += 1) {
          tmpfile.push(acceptedFiles[i]);
        }
        setFile([...tmpfile]);
        fileUploadHandler(tmpfile);
        return;
      }
    }
    if (acceptedFiles && acceptedFiles[0] && isMultiple) {
      const tmpfile: File[] = [...file];
      for (let i = 0; i < acceptedFiles.length; i += 1) {
        tmpfile.push(acceptedFiles[i]);
      }
      setFile([...tmpfile]);
      fileUploadHandler(tmpfile);
    } else if (acceptedFiles && acceptedFiles[0]) {
      const tmpFile: any = [];
      for (let i = 0; i < acceptedFiles.length; i += 1) {
        tmpFile.push({ file: acceptedFiles[i], status: 'pending', id: Math.ceil(Math.random() * 101.7) });
      }
      if (type === 'rental') {
        setFile(acceptedFiles);
        fileUploadHandler(acceptedFiles);
        return;
      }
      setFile(tmpFile);
      dispatch(imageUploadFiles(tmpFile));
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: type === 'pdf' ? 'application/pdf' : 'image/*',
  });

  const onImageChange = (event: any) => {
    if (type === 'pdf') {
      if (event.target.files && event.target.files[0] && isMultiple) {
        const tmpFile: any = [...file];
        for (let i = 0; i < event.target.files.length; i += 1) {
          tmpFile.push(event.target.files[i]);
        }
        setFile([...tmpFile]);
        fileUploadHandler([...tmpFile]);
      } else if (event.target.files && event.target.files[0]) {
        setFile([event.target.files[0]]);
        fileUploadHandler(event.target.files[0]);
      }
      return;
    }
    if (type === 'rental') {
      if (event.target.files && event.target.files[0]) {
        setFile([event.target.files[0]]);
        fileUploadHandler(event.target.files);
      }
      return;
    }
    if (event.target.files && event.target.files[0]) {
      const tmpFile: any = [];
      for (let i = 0; i < event.target.files.length; i += 1) {
        tmpFile.push({ file: event.target.files[i], status: 'pending', id: Math.ceil(Math.random() * 101.7) });
      }
      setFile([...tmpFile]);
      dispatch(imageUploadFiles(tmpFile));
    }
  };

  const deleteFile = (index: number, e: any) => {
    e.stopPropagation();
    dispatch(deleteFileError(index));
    file.splice(index, 1);
    setFile([...file]);
  };

  return (
    <div className={Style.imageItemDialogWrapper} {...getRootProps()}>
      <input {...getInputProps()} onChange={onImageChange} />
      {loader && type !== 'pdf' && <Loader isSmallView />}
      {showFileUploadingProgress && type === 'pdf' && <Loader isSmallView />}
      {type === 'pdf' ? (
        <>
          <img src={PlaceholderImage} alt="" className={`${Style.popupImage}`} />
          <p className={`mb-0 ${Style.popupTitle}`}>Drop the files here ...</p>
        </>
      ) : (
        <>
          <img style={{ padding: '25px 0 0' }} src={PlaceholderImage} alt="" className={`${Style.popupImage}`} />
          <p style={{ padding: '25px 0 15px 0' }} className={`mb-0 ${Style.popupTitle}`}>
            <span className={`mb-0 pl-2 ${Style.chooseFileText}`}> Choose your file</span>
            <br />
            <span className={`mb-0 mt-0 pl-2 ${Style.fileSizeText}`}> min file 250px*250px</span>
          </p>
        </>
      )}

      <div className={InputStyle.mainDiv}>
        {prevFiles &&
          prevFiles.map((f: any, index: number) => (
            <div key={index} className={InputStyle.previewFiles}>
              <img src={getUrl(f.file)} alt="file" className={`hello ${InputStyle.previewImage}`} />
              {f.status === 'error' && (
                <div>
                  <span
                    className={`mb-0 ${InputStyle.actions}`}
                    onClick={(e) => deleteFile(f.id, e)}
                    onKeyPress={(e) => deleteFile(f.id, e)}
                    role="button"
                    tabIndex={0}
                  >
                    <img src={deleteSvg} alt="delete" className={InputStyle.actionImage} />
                  </span>
                  <PopoverContainer
                    cssClass={Style.bookingMessage}
                    itemComponent={() => {}}
                    toolTip="Image size should be 250px*250px"
                  >
                    <span className={InputStyle.actionsExclamation}>
                      <img src={exclamationSvg} alt="" className={InputStyle.actionImage} />
                    </span>
                  </PopoverContainer>
                </div>
              )}
              {f.status === 'success' && (
                <span
                  className={`mb-0 ${InputStyle.actionsSuccess}`}
                  onClick={(e) => deleteFile(f.id, e)}
                  onKeyPress={(e) => deleteFile(f.id, e)}
                  role="button"
                  tabIndex={0}
                >
                  <img src={checkSvg} alt="" className={InputStyle.actionImage} />
                </span>
              )}
            </div>
          ))}
        {type === 'rental' && file[0] && (
          <div className={InputStyle.previewFiles}>
            <img src={getUrl(file[0])} alt="file" className={`hello ${InputStyle.previewImage}`} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DropZone;
