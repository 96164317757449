import Button from 'components/controls/button/button';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import Style from 'components/bgPerformer/skills/Skills.module.css';
import { PerformerSkillList, PerformerSkill, User } from 'modules/user/types';
import AboveAverage from 'assets/images/meter.png';
import plusSvg from 'assets/svg/plus-circle.svg';
import Dialog from 'components/bgPerformer/skills/Dialog/Dialog';
import { connect } from 'react-redux';
import { setAllSkillFalse } from 'store/actions/general.actions';
import SkillViewItem from 'components/bgPerformer/skills/SkillViewItem';
import {
  deletePerformerSkillBegin,
  savePerformerSkillsBegin,
  savePerformerSkillsSignUpBegin,
} from 'store/actions/performer.actions';
import { SkillCategory, SkillCategoryList } from 'modules/general/general.type';
import CPModal from 'components/Modal/CPModal';
import { useLocation } from 'react-router';

interface SkillsProps {
  user: User;
  skills?: PerformerSkillList;
  saveSkill: Function;
  resetSkillStatus: Function;
  saveProfileSkill: Function;
  layer?: Function;
  saveSkillRef?: any;
  parentSkillDialog?: Function;
  skillClass?: string;
  signUpClass?: string;
  signUpPerformerClass?: string;
  deleteBGSkill: Function;
}
const Skill: FunctionComponent<SkillsProps> = (props) => {
  const {
    user,
    skills,
    saveSkill,
    resetSkillStatus,
    saveProfileSkill,
    layer,
    saveSkillRef,
    parentSkillDialog,
    skillClass,
    signUpClass,
    signUpPerformerClass,
    deleteBGSkill,
  } = props;
  const [skillDialog, setSkillDialog] = useState<boolean>(false);
  const [skillList, setSkillList] = useState<PerformerSkillList>(skills || []);
  const [messagePopup, setMessagePopup] = useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    if (saveSkillRef) {
      saveSkillRef.current = handleSaveSkills;
    }
  }, []);

  useEffect(() => {
    resetSkillStatus();
    if (!skillDialog) {
      if (layer) layer(false);
    }
  }, [skillDialog]);

  useEffect(() => {
    if (skills) setSkillList(skills);
  }, [skills]);

  const handleClick = () => {
    if (parentSkillDialog) {
      parentSkillDialog(true);
      return;
    }
    setSkillDialog(true);
    if (layer) layer(true);
  };

  const handleSkillChange = (value: string, item: PerformerSkill) => {
    const prevSkills = skillList;
    const index = prevSkills.findIndex((x: PerformerSkill) => x.id === item.id);

    if (index !== -1) {
      prevSkills[index].skillLevel = value;
      setSkillList([...prevSkills]);
    }

    if (location.pathname === '/profile/PERFORMER') {
      saveProfileSkill({
        skillLevel: value,
        skillIds: [item.skillCategoryId.id],
      });
    } else {
      saveSkill({
        skillLevel: value,
        skillIds: [item.skillCategoryId.id],
      });
    }
  };

  const existisSkillsIds = useMemo(
    () => skillList.map((x: PerformerSkill) => (x.skillCategoryId ? x.skillCategoryId.id : '')),
    [skillList],
  );

  const handleSaveSkills = (newSkills: SkillCategoryList, skillLevel: string) => {
    if (newSkills.length === 0) return;

    const maxSkills: number = user.subscription ? user.subscription.skills : 3;
    const newIds = newSkills.map((x: SkillCategory) => x.id);

    const skillIds =
      existisSkillsIds.length === 0
        ? newIds
        : newIds.concat(existisSkillsIds).reduce((a: string[], b: string) => {
            if (!a.includes(b)) a.push(b);
            return a;
          }, []);

    if (skillIds.length > maxSkills) {
      setMessagePopup(true);
      return;
    }
    const uniqueIds = skillIds.filter((x: string) => newIds.includes(x));
    if (location.pathname === '/profile/PERFORMER') {
      saveProfileSkill({
        skillLevel,
        skillIds: uniqueIds,
      });
    } else {
      saveSkill({
        skillLevel,
        skillIds: uniqueIds,
      });
    }
  };
  const getSkillTitle = () => {
    if (location.pathname === '/profile/performer') return Style.skillTitleSignUp;
    if (location.pathname === '/performer/home') return Style.skillTitle;
    return '';
  };
  const handleDeleteSkill = (skillId: string) => {
    if (skillId && skills) {
      const prev: PerformerSkillList = skillList.filter((x: PerformerSkill) => x.id !== skillId);
      console.log('prev delete', prev);
      setSkillList([...prev]);
      deleteBGSkill({ id: skillId });
    }
  };

  return (
    <>
      <div className={`${Style.getSkillsWrapper} ${getSkillTitle()}`}>
        <div className="d-flex align-items-center">
          <img src={AboveAverage} alt="sort" />
          <p className={`mb-0 pl-2 ${Style.skillHeading}`}>Special Skills</p>
        </div>
        <div>
          <Button
            icon={plusSvg}
            cssClass="addNewImage"
            label="Add New Skill"
            type="primary"
            clickHandler={handleClick}
          />
        </div>
      </div>
      <div className={`d-flex flex-wrap mt-30 ${Style.specialSkillsWrapper} ${signUpPerformerClass}`}>
        {skillList.map((skill: PerformerSkill) => (
          <SkillViewItem
            handleDeleteSkill={handleDeleteSkill}
            key={skill.id}
            skill={skill}
            signUpClass={signUpClass}
            skillClass={skillClass}
            handleSkillChange={(value: string) => {
              handleSkillChange(value, skill);
            }}
          />
        ))}
      </div>
      {skillDialog && <Dialog closeDialog={setSkillDialog} handleSaveSkills={handleSaveSkills} />}
      <CPModal isOkOnly isShow={messagePopup} hideModal={() => setMessagePopup(false)}>
        <p>Limit exceed! Please upgrage to pro</p>
      </CPModal>
    </>
  );
};

const mapDispatchToProps = {
  saveSkill: savePerformerSkillsBegin,
  saveProfileSkill: savePerformerSkillsSignUpBegin,
  resetSkillStatus: setAllSkillFalse,
  deleteBGSkill: deletePerformerSkillBegin,
};

export default connect(null, mapDispatchToProps)(Skill);
