import React, { useEffect, useState } from 'react';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchIcon from 'assets/images/thinSearchIcon.svg';
import styled from 'styled-components';
import { faker } from '@faker-js/faker';
import Modal from 'components/Payroll/VoucherManagement/voucher/utils/modal';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledSearchTab from '../../styles/filter/SearchTab.styled';
import Style from '../../../Sort/Sort.module.css';

interface Props {
  placeholderText?: string;
  borderRight?: string;
  radius?: number;
  handleSearch?: Function;
}

export const fakeUsers = Array(10)
  .fill(1)
  .map(() => {
    const firstName = faker.name.firstName();
    const lastName = faker.name.lastName();
    return {
      id: faker.datatype.uuid(),
      name: `${firstName} ${lastName}`,
      avatar: faker.image.avatar(),
      firstName,
      lastName,
    };
  });
const SearchTab: React.FC<Props> = (props) => {
  const { placeholderText, borderRight, radius, handleSearch } = props;
  const [search, setSearch] = useState<string>('');
  const [toggleIcon, setToggleIcon] = useState<boolean>(false);

  useEffect(() => {
    let timeOutId: any;
    if (handleSearch) timeOutId = setTimeout(() => handleSearch(search), 300);
    return () => clearTimeout(timeOutId);
  }, [search]);

  const handleSearchText = (event: React.FormEvent<HTMLInputElement>) => {
    console.log('Current search value:', event.currentTarget.value);
    setSearch(event.currentTarget.value);
  };

  const handleClick = () => {
    setToggleIcon(!toggleIcon);
  };

  const handleChange = (e: any) => {
    console.log('Current value in input:', e.target.value);
    setToggleIcon(!toggleIcon);
    setSearch(e.target.value);
  };

  return handleSearch ? (
    <div className="form-group position-relative mb-0 w-100 px-1">
      <input
        type="text"
        placeholder="Search"
        onChange={handleSearchText}
        className={`border-0 form-control ${Style.searchArea}`}
      />
      <FontAwesomeIcon icon={faSearch} className={Style.inputSearchIcon} />
    </div>
  ) : (
    <>
      <SearchContainer>
        <StyledSearchTab borderRight={borderRight} radius={radius}>
          <IconContainer>
            <StyledSearchIcon src={SearchIcon} />
          </IconContainer>
          <SearchInput type="text" placeholder={placeholderText} value={search} onChange={handleChange} />
        </StyledSearchTab>
        <SearchResultsContainer visible={toggleIcon}>
          {fakeUsers
            .filter((user) => {
              if (!search) return true;
              return user.name.includes(search);
            })
            .map((user) => (
              <ResultItem key={user.id}>
                <Avatar>
                  <UserIcon src={user.avatar} />
                </Avatar>
                <UserTitle>{user.name}</UserTitle>
              </ResultItem>
            ))}
        </SearchResultsContainer>
        {toggleIcon && <Modal closeModal={handleClick} />}
      </SearchContainer>
    </>
  );
};

export default SearchTab;
export const ResultItem = styled.div`
  background-color: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;
  padding-left: 10px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  &:hover {
    background-color: #f8f8f8;
    transition: all 0.3s ease-in-out;
  }
  &:last-child {
    border-bottom: 0;
  }
`;
export const UserTitle = styled.p`
  margin: 0;
  padding-left: 10px;
  font-size: 14px;
  color: #1f3148;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
`;

export const SearchResultsContainer = styled.div<{ visible: boolean }>`
  z-index: 11;
  padding: 0 10px;
  position: absolute;
  flex-direction: column;
  top: 60px;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e4f0fc;
`;
const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const StyledSearchIcon = styled.img`
  font-size: 13px;
  font-weight: 300 !important;
  color: #4b4d67;
`;

const IconContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #828282;
`;

const SearchInput = styled.input`
  height: 100%;
  border: none;
  padding: 0 7px;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  font-family: 'Montserrat';
  width: 90%;
  &::placeholder {
    color: #4b4d67;
    font-style: normal;
    font-size: 15px;
    font-weight: 400;
  }
`;

export const Avatar = styled.div`
  position: relative;
  width: 100%;
  max-width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 10%;
`;

export const UserIcon = styled.img`
  width: 100%;
  height: auto;
`;
