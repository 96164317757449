import { SubscriptionPlan } from 'modules/user/types';
import React, { FunctionComponent, useMemo } from 'react';
import Style from 'components/ProfileMenu/Settings/Setting.module.css';
import unionImage from 'assets/images/union.png';
import enterpriseSvg from 'assets/svg/Enterprise.svg';
import boosterSvg from 'assets/svg/booster.svg';
import proSvg from 'assets/svg/pro.svg';
import Button from 'components/controls/button/button';

interface ItemProps {
  item: SubscriptionPlan;
  clickHandle: Function;
  index: number;
  currentPlan?: SubscriptionPlan;
}

const Item: FunctionComponent<ItemProps> = (props: ItemProps) => {
  const { item, clickHandle, index, currentPlan } = props;

  const icon = useMemo(() => {
    switch (item.category) {
      case 'Advantage':
        return boosterSvg;
      case 'Pro':
        return proSvg;
      default:
        return enterpriseSvg;
    }
  }, [item]);

  const handleClick = () => clickHandle(item);

  const jobText = () => `${item.jobs === 999 ? 'Unlimited' : item.jobs} weekly job to apply`;

  const addCssClass = useMemo(() => {
    switch (index) {
      case 1:
        return Style.secondCard;
      case 2:
        return Style.thirdCard;
      default:
        return '';
    }
  }, [index]);

  return (
    <div className={`position-relative ${Style.firstCard} ${addCssClass}`}>
      <div className={Style.cardInnerImg}>
        <img src={unionImage} alt="union" />
        <div className={Style.cardImageText}>
          <img src={icon} alt="enterprise" />
          <p className="text-white mb-0 ml-2">{item.category}</p>
        </div>
      </div>
      <div className={Style.firstCardInner}>
        <h4 className="font-weight-bold text-white">{item.title}</h4>
        <div className="d-flex justify-content-center align-items-baseline line-height-normal">
          <p className="mb-0 text-white font-weight-bold fontTwenty">{item.ratePerMonth}</p>
          <p className={`mb-0 text-white font-weight-bold ${Style.monthText}`}> / Month*</p>
        </div>
        <p className="mb-0 mt-2 text-white font-weight-normal fontFourteen">&nbsp;</p>
      </div>
      <hr className={Style.horizontalRow} />
      <p className="text-white mb-0 fontFourteen font-weight-normal">Features</p>
      <ul className={Style.listingWrapper}>
        <li className="text-white fontFourteen font-weight-normal">{`${item.photos} Profile Pictures`}</li>
        <li className="text-white fontFourteen font-weight-normal">{`${item.skills} Profile Skills`}</li>
        <li className="text-white fontFourteen font-weight-normal">{jobText()}</li>
      </ul>
      {currentPlan && currentPlan.id !== item.id && (
        <Button clickHandler={handleClick} label="Change" cssClass={Style.chooseButton} />
      )}
    </div>
  );
};

export default Item;
