import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MatrixItemList, MatrixType } from 'modules/Show/show.types';
import { getMatrixData, getSelectedShowId, getShowSelectedDates } from 'store/selector/show.selector';
import MatrixDetail from 'components/Producer/Matrix/MatrixItem/MatrixDetails';
import { getMatrixDataBegin } from 'store/actions/show.actions';

interface IMatrixProps {
  matrixData: MatrixItemList;
  getMatrixDataFetch: Function;
  showId: string;
  showDates: string[];
}
const MatrixView: FunctionComponent<IMatrixProps> = (props: IMatrixProps) => {
  const { matrixData, getMatrixDataFetch, showId, showDates } = props;
  const [data, setData] = useState<MatrixItemList>();

  useEffect(() => {
    if (showId) getMatrixDataFetch({ date: showDates, showId, page: 1, limit: 6 });
  }, [showDates, showId]);

  useEffect(() => {
    setData(matrixData);
  }, [matrixData]);

  return <>{data && data.map((item: MatrixType) => <MatrixDetail key={item.id} dayItem={item} />)}</>;
};

const mapStateToProps = (state: any) => {
  const matrixData = getMatrixData(state);
  const showId = getSelectedShowId(state);
  const showDates = getShowSelectedDates(state);
  return { matrixData, showId, showDates };
};
const mapDispatchToProps = {
  getMatrixDataFetch: getMatrixDataBegin,
};

export default connect(mapStateToProps, mapDispatchToProps)(MatrixView);
