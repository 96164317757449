import React, { FunctionComponent, useEffect } from 'react';
import Style from 'components/bgPerformer/Update/Update.module.css';
import src from 'assets/images/no-update.png';
import UpdateItem from 'components/bgPerformer/Update/UpdateItem/UpdateItem';
import mikeSvg from 'assets/svg/mike.svg';
import { BookingRequest, BookingRequestList } from 'modules/jobs/types';
import { IPerformerId } from 'modules/params/param.type';
import Loader from 'components/controls/Loader/Loader';
import { useSelector } from 'react-redux';
import { getScreenWidth } from 'store/selector/general.selector';
import { ScreenSize } from 'modules/general/general.type';
import { SCREEN_SIZE_MOBILE } from 'store/castingPax.constants';
// import { Spinner } from 'react-bootstrap';
// import handSvg from '../../../assets/svg/hand.svg';

interface UpdateProps {
  updatedNews: BookingRequestList;
  getUpdateNews: Function;
  loading: boolean;
}
const PerformerUpdate: FunctionComponent<UpdateProps> = (props: UpdateProps) => {
  const { getUpdateNews, updatedNews, loading } = props;
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);
  useEffect(() => {
    const params: IPerformerId = { isUpdate: true, status: ['booked', 'requested', 'pending'] };
    getUpdateNews(params);
  }, []);

  const getResponsiveClasses = () => {
    if (currentScreenSize === SCREEN_SIZE_MOBILE) {
      return `d-flex ${Style.castingRequestTitle} ${Style.requestCastingTitle}`;
    }
    return `px-0 ${Style.castingUpdateTitle}`;
  };

  return (
    <div className={Style.castingUpdateWrapper}>
      <div className={getResponsiveClasses()}>
        <img src={mikeSvg} alt="" />
        <p className="mb-0 ml-2">Updates</p>
        {loading && <Loader isSmallView />}
      </div>
      <div className={`customScrollbar ${Style.updateInner}`}>
        {updatedNews && updatedNews.length > 0 ? (
          updatedNews.map((news: BookingRequest) => <UpdateItem key={news.id} news={news} />)
        ) : (
          <div className="text-center">
            <img src={src} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

export default PerformerUpdate;
