import React, { FunctionComponent, useEffect, useState } from 'react';
import SignupStyle from 'views/signup/signup.module.css';
import Button from 'components/controls/button/button';
import ImageInput from 'components/controls/fileInput/ImageInput/ImageInput';
import GenderTypes from 'helpers/data/Gender';
import { ProfileInputProps } from 'modules/user/types';
import signupProfileStyle from 'views/signup/profile/signupProfile.module.css';
import DropDownSearch from 'components/controls/select/DropDownSearch';
import Style from 'components/CreateShow/CreateShow.module.css';
import {
  getHeightOptions,
  /* getInseamOptions,
  getJacketSizeOptions,
  getNeckOptions,
  getSleeveOptions,
  getWasitOption, */
  getWeightOption,
  // NumberOptions,
} from 'helpers/data/SelectOptions';
import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';
import { formatHeight, formatWeight, getName } from 'helpers/utils';
import InputField from 'components/controls/input/input';
import Skill from 'components/bgPerformer/skills/Skill';
import { useSelector } from 'react-redux';
import { getProfile } from 'store/selector/performer.selector';
import { useLocation } from 'react-router';

const BgProformer: FunctionComponent<ProfileInputProps> = (props: ProfileInputProps) => {
  const { handleClick, isLoading, authError, uploadProfileImage, user, saveSkillRef, setSkillDialog } = props;
  const profileData = useSelector(getProfile);
  const [sex, setSex] = useState('');
  const [age, setAge] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [hair, setHair] = useState('');
  const [eyeColor, setEyeColor] = useState('');
  const [disable, setDisable] = useState(false);
  const location = useLocation();
  /* const [chest, setChest] = useState<string>('');
  const [waist, setWaist] = useState<string>('');
  const [neck, setNeck] = useState<string>('');
  const [jacket, setJacket] = useState<string>('');
  const [inseam, setInseam] = useState<string>('');
  const [sleeve, setSleeve] = useState<string>('');
  const [shoes, setShoes] = useState<string>(''); */

  useEffect(() => {
    const isValidInput =
      sex.length > 0 &&
      age.length > 0 &&
      weight.length > 0 &&
      height.length > 0 &&
      hair.length > 0 &&
      eyeColor.length > 0;

    setDisable(!isValidInput);
  });

  const handleSubmitProfile = () => {
    const bgPerformer = {
      gender: sex,
      dateOfBirth: age,
      height,
      weight,
      hair,
      eyeColor,
    };

    handleClick(Object.assign(user, { bgPerformer, isCompleted: true }));
  };
  const getSkills = () => {
    if (location.pathname === '/profile/PERFORMER' && !profileData) return [];
    return profileData.bgPerformer.skills;
  };

  return (
    <div className={`${SignupStyle.signUpWrapper} ${SignupStyle.BgPerformerPage}`}>
      <TextErrorMessage message={authError} />
      <div className="row searchAreaSignup">
        <div className="col-sm-5">
          <ImageInput handelFile={uploadProfileImage} userId={user.id} />
        </div>
        <div className="col-sm-7">
          <label className="green-text">Full Name</label>
          <InputField label="" value={getName(user)} />
        </div>
      </div>
      <div className="row profileCreation">
        <div className="col-sm-6">
          <DropDownSearch
            label="Sex"
            cssClass={Style.createInputLabel}
            value={sex}
            data={GenderTypes}
            defaultValue="Select"
            handleSelect={setSex}
          />
        </div>
        <div className="col-sm-6">
          <label className="green-text">Age</label>
          <InputField label="" type="date" value={age} handleChange={setAge} />
        </div>
        <div className="col-sm-6">
          <DropDownSearch
            label="Height"
            value={formatHeight(height || '')}
            cssClass={Style.createInputLabel}
            data={getHeightOptions()}
            defaultValue="select"
            handleSelect={setHeight}
            sort
          />
        </div>
        <div className="col-sm-6">
          <DropDownSearch
            label="Weight"
            value={formatWeight(weight)}
            cssClass={Style.createInputLabel}
            data={getWeightOption()}
            defaultValue="select"
            handleSelect={setWeight}
          />
        </div>
      </div>
      <div className="row profileCreation">
        <div className="col-sm-6">
          <DropDownSearch
            label="Hair"
            value={hair}
            cssClass={Style.createInputLabel}
            optionType="hair"
            defaultValue="select"
            handleSelect={setHair}
          />
        </div>
        <div className="col-sm-6">
          <DropDownSearch
            label="Eye color"
            value={eyeColor}
            cssClass={Style.createInputLabel}
            optionType="eyes"
            defaultValue="select"
            handleSelect={setEyeColor}
          />
        </div>
        <div className="col-sm-12">
          <div className={`text-left ${signupProfileStyle.addSkillWrapper}`}>
            {/* <img src={plusIcon} alt="" /> */}
            <Skill
              signUpClass={Style.signupPerformerColor}
              signUpPerformerClass={Style.signupPerformerWrapper}
              skills={getSkills()}
              user={user}
              saveSkillRef={saveSkillRef}
              parentSkillDialog={setSkillDialog}
            />
          </div>
        </div>
      </div>

      {/*  <div className="row profileCreation">
        <div className="col-sm-6">
          <DropDownSearch
            value={chest}
            label="Chet/Bust"
            cssClass={Style.createInputLabel}
            defaultValue="select"
            data={NumberOptions(15, 80)}
            handleSelect={setChest}
          />
        </div>
        <div className="col-sm-6">
          <DropDownSearch
            value={waist ?? ''}
            cssClass={Style.createInputLabel}
            label="Waist"
            defaultValue="select"
            data={getWasitOption()}
            handleSelect={setWaist}
          />
        </div>
      </div>
      <div className="row profileCreation">
        <div className="col-sm-6">
          <DropDownSearch
            value={neck ?? ''}
            cssClass={Style.createInputLabel}
            label="Neck"
            data={getNeckOptions()}
            defaultValue="select"
            handleSelect={setNeck}
          />
        </div>
        <div className="col-sm-6">
          <DropDownSearch
            value={inseam ?? ''}
            cssClass={Style.createInputLabel}
            label="Inseam"
            data={getInseamOptions()}
            defaultValue="select"
            handleSelect={setInseam}
          />
        </div>
      </div>

      <div className="row profileCreation">
        <div className="col-sm-6">
          <DropDownSearch
            value={sleeve ?? ''}
            cssClass={Style.createInputLabel}
            data={getSleeveOptions()}
            label="Sleeve"
            defaultValue="select"
            handleSelect={setSleeve}
          />
        </div>
        <div className="col-sm-6">
          <DropDownSearch
            value={jacket ?? ''}
            cssClass={Style.createInputLabel}
            data={getJacketSizeOptions()}
            label="Jacket"
            defaultValue="select"
            handleSelect={setJacket}
          />
        </div>
      </div>
      <div className="row profileCreation">
        <div className="col-sm-6">
          <DropDownSearch
            value={shoes ?? ''}
            cssClass={Style.createInputLabel}
            data={NumberOptions(5, 15)}
            label="Shoe size"
            defaultValue="select"
            handleSelect={setShoes}
          />
        </div>
        <div className="col-sm-6" />
      </div> */}

      <div className="text-center mt-4">
        <Button
          label="Continue to finish your profile"
          cssClass=" login-btn w-100"
          isDisabled={disable}
          showLoading={isLoading}
          clickHandler={handleSubmitProfile}
        />
      </div>
    </div>
  );
};
export default BgProformer;
