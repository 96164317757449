import React from 'react';
import styled from 'styled-components';
// import TableIcon from 'assets/images/tableIcon.svg';
// import Fourcell from 'assets/images/Fourcell.svg';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterTab from './FilterTab';
// import SearchTab from '../VoucherManagement/filter-tab/SearchTab';
import { FilterComponentContainer } from '../VoucherManagement/voucher/FilterTabsWrapper';

interface Props {
  handleSort?: any;
}

interface StyledContainerProps {
  color?: 'white' | 'gray';
  selectedItem?: '1' | '2';
}

const recentlyBookedData = [
  {
    id: '1',
    label: 'Name',
    value: 'Name',
  },
  {
    id: '2',
    label: 'Voucher Info',
    value: 'Voucher Info',
  },
  {
    id: '3',
    label: 'Tax Files',
    value: 'Tax Files',
  },
];

const UserSortFilter: React.FC<Props> = (props) => {
  const { handleSort } = props;
  // const [filter, setFilter] = useState('');
  // console.log(filter);
  return (
    <StyledUserWrapper>
      <StyledSortFilterBottonWrapper className="table-filter-custom">
        <StyledContainer color="gray">
          <FontAwesomeIcon icon={faTable} />
        </StyledContainer>
        <StyledContainer color="white">
          <FontAwesomeIcon icon={faTable} />
        </StyledContainer>
        <FilterComponentContainer>
          <FilterTab
            withLabel
            data={recentlyBookedData}
            label="Sort By"
            fontSize={15}
            fontWeight={400}
            checked
            toggleCheck={() => {}}
            height={50}
            style={{
              lineHeight: '18px',
              borderRadius: '8px',
              paddingRight: '18px',
              fontSize: '15px',
              fontWeight: '400',
            }}
            handleFilter={handleSort}
          />
        </FilterComponentContainer>
        {/* <SearchContainer>
          <SearchTab placeholderText="Search" radius={8} />
        </SearchContainer> */}
      </StyledSortFilterBottonWrapper>
    </StyledUserWrapper>
  );
};

export default UserSortFilter;

// const SearchContainer = styled.div`
//   display: flex;
//   align-items: center;
//   position: relative;
//   flex: 1;
//   height: 100%;
//   border-radius: 8px;
// `;
const StyledUserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 30px;
`;

const StyledSortFilterBottonWrapper = styled.div`
  height: 50px;
  width: 676px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

// const IconImage = styled.img`
//   height: 24px;
//   width: 24px;
//   z-index: 5;
// `;

const StyledContainer = styled.div<StyledContainerProps>`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${({ color }) => {
    switch (color) {
      case 'white':
        return '#fff';
      case 'gray':
        return '#4B4D67';
      default:
        return '';
    }
  }};
  color: ${({ color }) => {
    switch (color) {
      case 'white':
        return 'rgba(75, 77, 103, 0.8)';
      case 'gray':
        return '#fff';
      default:
        return '';
    }
  }};
  border-radius: 10px;
`;
