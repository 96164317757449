import React, { useState, useRef, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import calendarIcon from 'assets/svg/calendarIcon.svg';
import Slider from 'react-slick';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
// import { useAppDispatch } from 'store/hooks/hooks';
import { getScreenWidthValue } from 'store/selector/general.selector';
import DatePicker from './VoucherDatePicker';
import DateBox from './DateBox';
import { setDatesForFitler } from '../../../../store/actions/show.actions';
// import { addPayroll } from '../../../../store/reducers/payroll.reducers';

interface Props {
  sideNavOpen: boolean;
  showCalendarDates: any;
  selectedDateId: Function;
}

const DateList: React.FC<Props> = (props: Props) => {
  const { showCalendarDates, selectedDateId } = props;
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const [openDate, setOpenDate] = useState<boolean>(false);
  const currentScreenSize: number = useSelector(getScreenWidthValue);

  const dataListRectWidth = useMemo(() => {
    if (!currentScreenSize) return 1535;
    if (currentScreenSize < 1174) return 1080 - 65;
    return currentScreenSize > 1600 ? 1535 : currentScreenSize - 124 - 65;
  }, [currentScreenSize]);
  const dispatch = useDispatch();

  const toggleOpenDate = () => {
    setOpenDate(!openDate);
  };

  const handleSelect = (id: number, date: string) => {
    dispatch(setDatesForFitler([date]));
    // console.log('id', id, setSelectedId);
    selectedDateId(id);
    setSelectedId(id);
    // dispatch(addPayroll({ title: `Added date ${id}` }));
  };
  useEffect(() => {}, [selectedId]);
  const sliderRef = useRef<any>();

  const slideCount = Math.floor(dataListRectWidth / 86);

  let settings = {
    infinite: false,
    speed: 400,
    dots: false,
    slidesToShow: slideCount,
    slidesToScroll: slideCount,
    height: 'auto',
  };
  if (currentScreenSize <= 812) {
    settings = {
      ...settings,
      slidesToShow: 3, // Adjust the number of slides as needed for screens up to 768px
      slidesToScroll: 1, // Scroll one slide at a time
    };
  } else if (currentScreenSize <= 1163) {
    settings = {
      ...settings,
      slidesToShow: 6, // Show only one slide
      slidesToScroll: 1, // Scroll one slide at a time
    };
  } else {
    settings = {
      ...settings,
      slidesToShow: slideCount, // Show the calculated number of slides based on screen width
      slidesToScroll: slideCount, // Scroll the calculated number of slides based on screen width
    };
  }
  return (
    <ListContainer>
      <StyledDateList pageWidth={dataListRectWidth}>
        <StyledController onClick={() => sliderRef?.current?.slickPrev()}>
          <StyledChevron icon={faChevronLeft} />
        </StyledController>
        <StyledContainer>
          <Slider ref={sliderRef} {...settings}>
            {showCalendarDates.map((data: any, i: number) => (
              <DateBox
                key={i}
                month={data.month}
                day={data.day}
                id={data.id}
                date={data.date}
                isSelected={data.isSelected}
                onClick={handleSelect}
              />
            ))}
          </Slider>
        </StyledContainer>
        <StyledController onClick={() => sliderRef?.current?.slickNext()}>
          <StyledChevron icon={faChevronRight} />
        </StyledController>
      </StyledDateList>
      <StyledDateWrapper>
        <StyledDateButton onClick={toggleOpenDate}>
          <img src={calendarIcon} alt="calendar" />
        </StyledDateButton>
        <DatePickerContainer>
          <DatePicker open={openDate} />
        </DatePickerContainer>
      </StyledDateWrapper>
    </ListContainer>
  );
};

export default DateList;

const StyledContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;
  width: 100%;
`;

const StyledDateWrapper = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
`;

const DatePickerContainer = styled.div``;

export const StyledDateButton = styled.div`
  width: 60px;
  height: 60px;
  border: none;
  outline: none;
  cursor: pointer;
`;

const ListContainer = styled.div`
  display: flex;
  flex: 1;
  width: calc(100% - 64px);
`;

const StyledDateList = styled.div<{ pageWidth?: number }>`
  display: flex;
  border-radius: 8px;
  padding: 0 23px;
  align-items: center;
  font-weight: 500;
  margin-right: 5px;
  // width: ${({ pageWidth }) => `${pageWidth}px`};
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  @media only screen and (max-width: 1440px) {
    // width: 1160px;
    padding: 0 15px;
  }
  @media only screen and (max-width: 1199px) {
    // width: 891px;
    padding: 0 15px;
  }
  @media only screen and (max-width: 991px) {
    // width: 609px;
    padding: 0 10px;
  }
`;

const StyledChevron = styled(FontAwesomeIcon)`
  color: #4b4d67;
  background-color: #fff;
  margin-left: 8px;
`;

const StyledController = styled.div`
  display: flex;
  padding: 10px;
  cursor: pointer;
`;
