import { IUserSearch } from 'modules/params/param.type';
import { apiCall } from './service';
import { URL_HELP_CENTER, URL_SEARCH_COORDINATOR, URL_SEARCH_DIRECTOR, URL_SEARCH_NAME, URL_SEARCH_SHOW } from './URL';

export const userApi = {
  /* async searchUser(search: IUserSearch) {
    try {
      const res = await apiCall.post({ url: URL_SEARCH_NAME, isAuthToken: false, params: search });
      return res;
    } catch (error) {
      console.log('Error ', error.message);
      return { data: [] };
    }
  }, */

  searchUser(params: IUserSearch) {
    return apiCall.post({ url: URL_SEARCH_NAME, isAuthToken: true, params });
  },

  async searchShowUser(search: IUserSearch) {
    try {
      const res = await apiCall.post({ url: URL_SEARCH_DIRECTOR, isAuthToken: false, params: search });
      return res;
    } catch (error) {
      console.log('Error ', error.message);
      return { data: [] };
    }
  },
  async searchCoordinator(search: IUserSearch) {
    return apiCall.post({ url: URL_SEARCH_COORDINATOR, isAuthToken: false, params: search });
  },
  async searchShow(search: IUserSearch) {
    try {
      const res = await apiCall.post({ url: URL_SEARCH_SHOW, isAuthToken: false, params: search });
      return res;
    } catch (error) {
      console.log('Error ', error.message);
      return { data: [] };
    }
  },
  fetchHelpCenterData() {
    return apiCall.get({ url: URL_HELP_CENTER, isAuthToken: true, params: {} });
  },
};

export default userApi;
