import { BookingRequest, JobEvent, JobEventList, CalendarItem } from 'modules/jobs/types';
import createSelector from 'utils/reselect';
import moment from 'moment';
import { defaultPagination, getInUTCFormat, getStatusTitle } from 'helpers/utils';
import { User } from 'modules/user/types';
import { JOB_STATUS_BOOKED, JOB_STATUS_PENDING } from 'store/castingPax.constants';

const jobStateDate = (state: any) => {
  const { job } = state;
  return job || {};
};

const userStateData = (state: any) => {
  const { users } = state;
  return users;
};

export const getJobRequestLoading = createSelector(jobStateDate, (job) => job.get('loading') || false);

export const getRequestLoading = createSelector(jobStateDate, (job) => job.get('requestLoading') || false);

export const getCastingRequest = createSelector(jobStateDate, (job) => job.getIn(['castingRequest', 'data']));
export const getCastingRequestLoading = createSelector(jobStateDate, (job) => job.getIn(['castingRequest', 'loading']));

export const getBookingRequest = createSelector(jobStateDate, (job) => job.getIn(['booking', 'data'] || []));

export const getCastingCalls = createSelector(jobStateDate, (job) => job.getIn(['calls', 'data']));
export const castingCallPagination = createSelector(jobStateDate, (job) => job.getIn(['calls', 'pagination']));

export const getBookingIds = createSelector(jobStateDate, (job) => {
  const bookings = job.getIn(['booking', 'data']) || [];
  const castingRequest = job.getIn(['castingRequest', 'data']) || [];
  const ids: string[] = [];

  if (bookings && bookings.length > 0) bookings.forEach((x: BookingRequest) => ids.push(x.jobId.id));
  if (castingRequest && castingRequest.length > 0) castingRequest.forEach((x: BookingRequest) => ids.push(x.jobId.id));

  return ids;
});

export const getAllBookingRequest = createSelector(jobStateDate, (job) => {
  const bookings = job.getIn(['booking', 'data']) || [];
  const castingRequest = job.getIn(['castingRequest', 'data']) || [];

  if (bookings && bookings.length > 0) {
    if (castingRequest && castingRequest.length > 0) {
      bookings.map((b: BookingRequest) => {
        const requestIndex = castingRequest.findIndex((c: BookingRequest) => c.id === b.id);
        if (requestIndex === '-1') bookings.push(castingRequest[requestIndex]);
        return b;
      });
    }
  } else if (castingRequest && castingRequest.length > 0) return [...castingRequest];
  return [...bookings];
});

export const getBookingData = createSelector(jobStateDate, (job) => {
  const bookings = job.getIn(['booking', 'data']) || [];
  const castingRequest = job.getIn(['castingRequest', 'data']) || [];
  return [...bookings, ...castingRequest];
});

export const getBookingDates = createSelector(jobStateDate, (job) => {
  const bookingRequests = job.getIn(['booking', 'data']) || [];
  const bookings = bookingRequests.filter((j: BookingRequest) => j.status === 'booked');
  const dates: string[] = [];

  bookings.forEach((x: BookingRequest) => {
    x.jobId.dates.map((d) => dates.push(d));
  });

  return dates;
});

export const getCastingCallsLoading = createSelector(jobStateDate, (job) => job.getIn(['calls', 'loading']));

export const getSelectedJob = createSelector(jobStateDate, (job) => job.get('selectedJob') || null);

export const getSelectedBooking = createSelector(jobStateDate, (job) => job.get('selectedBooking'));

export const getCastingUpdates = createSelector(jobStateDate, (job) => job.getIn(['updatedNews', 'data']));

export const getCastingUpdatesLoading = createSelector(jobStateDate, (job) => job.getIn(['updatedNews', 'loading']));

export const getSearchJobResult = createSelector(jobStateDate, (job) => {
  const results = job.getIn(['searchJobs', 'data']) || [];
  const sortingOrder = job.getIn(['searchJobs', 'sort']) || 1;
  if (sortingOrder === -1 && results.length > 0) {
    return [...results].reverse();
  }
  return results;
});

export const getSearchJobSortOrder = createSelector(jobStateDate, (job) => job.getIn(['searchJobs', 'sort']) || 1);
export const getSearchJobResultLoading = createSelector(jobStateDate, (job) => job.getIn(['searchJobs', 'loading']));
export const jobSearchPagination = createSelector(jobStateDate, (job) => job.getIn(['searchJobs', 'pagination']));

export const getJobEvents = createSelector(jobStateDate, userStateData, (job, users) => {
  const user: User = JSON.parse(JSON.stringify(users.get('user')));
  const bookingRequests = job.getIn(['booking', 'data']) || [];
  const calendarDates = job.getIn(['availableDates', 'data']) || [];

  if (!bookingRequests) return [];
  const list: JobEventList = [];
  bookingRequests.forEach((b: BookingRequest) => {
    const tmpObj = { ...b };
    const { dates } = tmpObj;
    if (b.newDates) {
      b.newDates.forEach((nd: string) => {
        const pos: number = dates.indexOf(nd);
        if (pos !== -1) dates.splice(pos, 1);
      });
    }
    dates.forEach((d: string) => {
      const datePart = d.substring(0, 10);
      if (moment(datePart).isSameOrAfter(moment(), 'day')) {
        const index = list.findIndex((e: JobEvent) => e.date.includes(datePart));
        if (index === -1) {
          const event: JobEvent = {
            id: Math.random().toString(),
            start: new Date(getInUTCFormat(d)),
            end: new Date(getInUTCFormat(d)),
            allDay: true,
            date: moment(d).format('YYYY-MM-DD'),
            status: b.status,
            jobs: [b],
          };
          list.push(event);
        } else {
          if (b.status === JOB_STATUS_BOOKED) list[index].status = b.status;
          const prevJobs = list[index].jobs || [];
          list[index].jobs = [...prevJobs, b];
        }
      }
    });
    if (b.newDates) {
      b.newDates.forEach((d: string) => {
        const datePart = d.substring(0, 10);
        if (moment(datePart).isSameOrAfter(moment(), 'day')) {
          const index = list.findIndex((e: JobEvent) => e.date.includes(datePart));
          if (index === -1) {
            const event: JobEvent = {
              id: Math.random().toString(),
              start: new Date(getInUTCFormat(d)),
              end: new Date(getInUTCFormat(d)),
              allDay: true,
              date: moment(d).format('YYYY-MM-DD'),
              status: JOB_STATUS_PENDING,
              jobs: [b],
            };
            list.push(event);
          } else {
            const prevJobs = list[index].jobs || [];
            list[index].jobs = [...prevJobs, b];
          }
        }
      });
    }
  });

  calendarDates.forEach((c: CalendarItem) => {
    const index = list.findIndex((e: JobEvent) => e.date.includes(c.workDate));
    if (index === -1) {
      const event: JobEvent = {
        id: Math.random().toString(),
        start: new Date(getInUTCFormat(c.workDate)),
        end: new Date(getInUTCFormat(c.workDate)),
        allDay: true,
        date: moment(c.workDate).format('YYYY-MM-DD'),
        status: getStatusTitle(c.workStatus),
        jobs: [],
      };
      list.push(event);
    } else if (index !== -1 && c.workStatus === 2 && list[index].status !== JOB_STATUS_BOOKED) {
      list[index].status = getStatusTitle(c.workStatus);
    } else if (index !== -1 && list[index].jobs?.length === 0) {
      list[index].status = getStatusTitle(c.workStatus);
    }
  });

  let i = 0;
  const date = moment();
  let ttlCalendarDays = 14;

  if (user && user.subscription) {
    ttlCalendarDays = user.subscription.days || 14;
  }

  for (i = 1; i <= ttlCalendarDays; i += 1) {
    const dateLabel = date.format('YYYY-MM-DD');
    const index = list.findIndex((e: JobEvent) => e.date.includes(dateLabel));
    if (index === -1) {
      const event: JobEvent = {
        id: Math.random().toString(),
        start: new Date(getInUTCFormat(dateLabel)),
        end: new Date(getInUTCFormat(dateLabel)),
        allDay: true,
        date: dateLabel,
        status: getStatusTitle(0),
        jobs: [],
      };
      list.push(event);
    }

    date.add(1, 'days');
  }
  console.log('Job Event : ', list);
  return list;
});

export const getPendingBooking = createSelector(jobStateDate, (job) => {
  const bookingRequests = job.getIn(['booking', 'data']) || [];
  return bookingRequests.filter((j: BookingRequest) => j.status === 'pending' || j.status === 'requested');
});

export const getConfirmedBooking = createSelector(jobStateDate, (job) => {
  const bookingRequests = job.getIn(['booking', 'data']) || [];
  return bookingRequests.filter((j: BookingRequest) => j.status === 'booked');
});

export const isSearchView = createSelector(jobStateDate, (job) => job.get('isSearchView') || false);

export const jobSearchFilterList = createSelector(jobStateDate, (job) => job.get('jobSearchFilter') || []);

export const workedDaysList = createSelector(jobStateDate, (job) => {
  const items: any = job.getIn(['workedDays', 'data']) || { data: [] };
  return items.data;
});

export const workedDaysPagination = createSelector(jobStateDate, (job) => {
  const items: any = job.getIn(['workedDays', 'data']) || { pagination: defaultPagination() };
  return items.pagination;
});

export const getCalendarDates = createSelector(jobStateDate, (job) => job.getIn(['availableDates', 'data']) || []);
