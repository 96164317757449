import React, { FunctionComponent, useMemo } from 'react';
import Style from 'components/Producer/Matrix/Budget/Popover/Popover.module.css';
import Loader from 'components/controls/Loader/Loader';
import { CSVLink } from 'react-csv';
import { DailyInvoice } from 'modules/general/general.type';
import moment from 'moment';

interface PopoverItemProps {
  item: any;
  data?: any;
  type: string;
  handleClick: Function;
  isloading?: number;
  loaderType?: string;
}
const PopoverItem: FunctionComponent<PopoverItemProps> = (props: PopoverItemProps) => {
  const { item, handleClick, isloading, loaderType, data, type } = props;
  const handleSubmit = () => handleClick();

  const csvData = useMemo(() => {
    if (type === 'invoice') {
      return data.map((i: DailyInvoice) => {
        const obj = {
          date: moment(i.createdAt).format('DD-MM-YYYY'),
          description: i.description,
          amount: i.totalBill,
          invoiceNo: i.invoiceNo,
        };
        return obj;
      });
    }
    return [];
  }, [data]);

  return (
    <div className={Style.itemWrapper} onClick={handleSubmit} onKeyPress={handleSubmit} role="button" tabIndex={0}>
      {item.type === 'csv' && type === 'invoice' ? (
        <CSVLink filename={`${item.type}.csv`} data={csvData}>
          <img src={item.icon} alt="" />
        </CSVLink>
      ) : (
        <img src={item.icon} alt="" />
      )}
      <span>{loaderType && loaderType === item.type && isloading === 1 ? <Loader isSmallView /> : ''}</span>
    </div>
  );
};

export default PopoverItem;
