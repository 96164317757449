import React, { FunctionComponent } from 'react';
import Style from 'components/castingDirector/PopOvers/CoordinatorDetail/Coordinator.module.css';
import plusSvg from 'assets/svg/plus-blue.svg';
import removeSvg from 'assets/svg/remove-2.svg';
import { User } from 'modules/user/types';
import { getImageThumbnail, getName } from 'helpers/utils';

interface SearchListProps {
  item: User;
  isAdded: boolean;
  handleClick: Function;
}
const SearchList: FunctionComponent<SearchListProps> = (props: SearchListProps) => {
  const { item, isAdded, handleClick } = props;
  const clickHandler = () => {
    handleClick(item, !isAdded);
  };
  return (
    <div
      className={`d-flex justify-content-between align-items-center ${Style.searchListWrapper} ${
        isAdded && Style.selected
      }`}
    >
      <div className="d-flex">
        <img src={getImageThumbnail(item.profilePicUrl)} className={Style.profileImage} alt="" />
        <p className={`mb-0 ${Style.profileName}`}>{getName(item)}</p>
      </div>
      <div onClick={clickHandler} onKeyUp={clickHandler} tabIndex={0} role="button">
        <img src={isAdded ? removeSvg : plusSvg} alt="" className={`mb-0 ${Style.iconImage}`} />
      </div>
    </div>
  );
};

export default SearchList;
