import React, { FunctionComponent } from 'react';
import HomeTabs from 'components/TopNavBar/Tabs';
import Style from 'components/TopNavBar/TopNavBar.module.css';
import { useSelector } from 'react-redux';
import { getUser } from 'store/selector/auth.selector';
import ShowTopNavigation from 'components/ShowTopNavigation';

const TopNavigatoinArea: FunctionComponent = () => {
  const user = useSelector(getUser);

  const setNavigationComponent = () => {
    if (user.role === 'PRODUCTION' || user.role === 'COORDINATOR' || user.role === 'PAYROLL') {
      return <ShowTopNavigation cssClass={`coordinatorSlider ${Style.coordinatorSlider}`} />;
    }
    return <HomeTabs />;
  };

  return (
    <div className={`${Style.tabsWrapper}`}>
      <div className="web-display-only w-100">{setNavigationComponent()}</div>
    </div>
  );
};

export default TopNavigatoinArea;
