import { SubscriptionPlan } from 'modules/user/types';
import React, { FunctionComponent, useState } from 'react';
import Style from 'components/ProfileMenu/Settings/Setting.module.css';
import Button from 'components/controls/button/button';
import { subscriptionStatus } from 'store/selector/auth.selector';
import { updateSubscriptionBegin } from 'store/actions/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_STATUS } from 'store/castingPax.constants';

interface ConfirmationProps {
  item?: SubscriptionPlan;
  handleClose: Function;
}

const Confirmation: FunctionComponent<ConfirmationProps> = (props) => {
  const { item, handleClose } = props;
  const [planType, setPlanType] = useState<string>('monthly');
  const dispatch = useDispatch();
  const apiStatus: string = useSelector(subscriptionStatus);

  const handleClick = () => {
    if (item) dispatch(updateSubscriptionBegin({ id: item.id, type: planType }));
  };

  if (!item) {
    return <></>;
  }

  const handleSelection = (e: any) => {
    setPlanType(e);
    console.log(e);
  };

  const ShowSelection = () => {
    if (item.ratePerMonth > 0 && item.ratePerYear > 0) {
      return (
        <div className="row">
          <div className="col-sm-6">
            <div className="d-flex justify-content-between align-items-center">
              <div className="custom-radioBtn">
                <ul>
                  <li>
                    <input
                      type="radio"
                      id="monthly"
                      name="selector"
                      onChange={() => handleSelection('monthly')}
                      defaultChecked
                    />
                    <label htmlFor="monthly">{`Monthly ${item.ratePerMonth}$`}</label>
                    <div className="check" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="d-flex justify-content-between align-items-center">
              <div className="custom-radioBtn">
                <ul>
                  <li>
                    <input type="radio" id="yearly" name="selector" onChange={() => handleSelection('yearly')} />
                    <label htmlFor="yearly">{`Yearly ${item.ratePerYear}$`}</label>
                    <div className="check" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };

  return (
    <>
      {ShowSelection()}
      <div className={`position-relative ${Style.firstCard} ${Style.firstCardPopup}`}>
        <div className={Style.firstCardInner}>
          <h4 className="font-weight-bold text-white">{item.title}</h4>
          <div className="d-flex justify-content-center align-items-baseline line-height-normal">
            <p className="mb-0 text-white font-weight-bold fontTwenty">{item.ratePerMonth}</p>
            <p className={`mb-0 text-white font-weight-bold ${Style.monthText}`}> / Month*</p>
          </div>
          <p className="mb-0 mt-2 text-white font-weight-normal fontFourteen">&nbsp;</p>
        </div>
        <hr className={Style.horizontalRow} />
        <p className="text-white mb-0 fontFourteen font-weight-normal">Features</p>
        <ul className={Style.listingWrapper}>
          <li className="text-white fontFourteen font-weight-normal">{`${item.photos} Profile Pictures`}</li>
          <li className="text-white fontFourteen font-weight-normal">{`${item.skills} Profile Skills`}</li>
        </ul>
      </div>
      <div className="text-center py-4">
        <Button
          clickHandler={handleClick}
          label="Confirm"
          showLoading={apiStatus === FETCH_STATUS.LOADING}
          type="primary"
          cssClass="mr-3"
        />
        <Button clickHandler={() => handleClose()} label="Cancel" type="secondary" />
      </div>
    </>
  );
};

export default Confirmation;
