import React, { FunctionComponent } from 'react';
import FullViewImage from 'assets/images/full-view.png';
import EditImage from 'assets/images/edit.png';
import DeleteImage from 'assets/images/delete.png';
import Style from 'components/bgPerformer/Images/Image.module.css';
import { PerformerImage } from 'modules/user/types';
import { Draggable } from 'react-beautiful-dnd';
import PlaceholderImage from 'assets/images/img-placholder.png';
import Loader from 'components/controls/Loader/Loader';

interface PhotoViewProps {
  photo: PerformerImage;
  handleDelete: Function;
  handleEdit: Function;
  handleUploadImage: Function;
  openFullViewImage: Function;
  index: number;
  editableId: string;
}

const DragablePhoto: FunctionComponent<PhotoViewProps> = (props: PhotoViewProps) => {
  const { handleEdit, handleDelete, photo, index, handleUploadImage, openFullViewImage, editableId } = props;

  const handleEditClick = () => {
    handleEdit(photo);
  };

  const handleDeleteClick = () => {
    handleDelete(photo);
  };

  const handleFullView = () => {
    openFullViewImage(photo.image);
  };

  const handleFiles = (file: PerformerImage) => {
    handleUploadImage('img', file.folderId, file.sequence);
  };

  return (
    <>
      <Draggable
        draggableId={`${photo.id}-${index}`}
        key={`${photo.id}-${index}`}
        index={index}
        isDragDisabled={photo.isDisable}
      >
        {(provided) => (
          <div
            className={Style.imageItem}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {photo.isDisable ? (
              <div className={`${Style.imagePlaceholder}`} onClick={() => handleFiles(photo)} role="presentation">
                <div className="w-100">
                  <img src={PlaceholderImage} alt="" />
                  <p className="mb-0 mt-3 text-center">Add new image</p>
                </div>
              </div>
            ) : (
              <>
                <div className={`${Style.imageLoader}`}>{photo.id === editableId && <Loader isSmallView />}</div>
                <div className={Style.imagesWrapper}>
                  <img src={photo.image} alt="" className={`${Style.image}`} />
                  <img
                    src={FullViewImage}
                    alt=""
                    className={`${Style.fullView}`}
                    onClick={handleFullView}
                    onKeyPress={handleFullView}
                    role="presentation"
                  />
                  <img
                    src={EditImage}
                    alt=""
                    className={`${Style.edit}`}
                    onClick={handleEditClick}
                    onKeyPress={handleEditClick}
                    role="presentation"
                  />
                  <img
                    src={DeleteImage}
                    alt=""
                    className={`${Style.delete}`}
                    onClick={handleDeleteClick}
                    onKeyPress={handleDeleteClick}
                    role="presentation"
                  />
                </div>
              </>
            )}
          </div>
        )}
      </Draggable>
    </>
  );
};

export default DragablePhoto;
