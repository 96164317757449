import { faker } from '@faker-js/faker';
import {
  IAddBudget,
  IAddCoordinator,
  IAddShowAccess,
  IBGCInvitation,
  IBgSizeCardParams,
  IBreakdownParams,
  IcallTime,
  IChangeAtoms,
  IChangeAtomsTitle,
  IChangeRoleParams,
  ICopyMatrixData,
  ICopyMatrixItem,
  IDailyCost,
  IDownloadInvoicesPdf,
  IEmailTemplateParams,
  IIDParams,
  IIDS,
  IMatrixParams,
  INewAtomsParam,
  IPerformerSearchParam,
  IProfileFileParam,
  IRemoveCallSheetFile,
  IRemoveFile,
  IRemovePerformerFromAtoms,
  ISendJobRequestParam,
  ISetMatrixEmailTemplate,
  IShowId,
  IShowListingParams,
  IValidateCallSheet,
  IBreakdownPdfParams,
} from 'modules/params/param.type';
import { CloseOrDeleteShowJob, DateJobRequestParams, ShowCreateParams, ShowDayJob } from 'modules/Show/show.types';
import { apiCall } from 'store/services/service';
import {
  URL_CREATE_ROLE,
  URL_CREATE_SHOW,
  URL_SHOW_LISTING,
  URL_UPDATE_SHOW,
  URL_SHOW_JOB_LISTING,
  URL_UPDATE_ROLE,
  URL_EMAIL_TEMPLATE,
  URL_SEARCH_PERFORMER,
  URL_JOB_REQUEST_TO_PERFORMER,
  URL_SHOW_PERMISSION,
  URL_SHOW_WITH_JOBS,
  URL_SHOW_DETAILS,
  URL_SAVE_EMAIL_TEMPLATE,
  URL_DELETE_WORKING_DAY,
  URL_MATRIX_DATES,
  URL_MATRIX_PERFORMER,
  URL_NEW_ATOMS,
  URL_ADD_COORDINATOR,
  URL_UPDATE_MATRIX_ITEM,
  URL_UPDATE_MATRIX_ATOMS_POS,
  URL_UPDATE_ATOMS_TITLE,
  URL_DELETE_MATRIX_ITEM,
  URL_DELETE_MATRIX_READ,
  URL_SET_MATRIX_EMAIL_TEMPALTE,
  URL_MATRIX_SEND_EMAIL,
  URL_UPLOAD_CALL_SHEET_FILE,
  URL_UPDATE_MATRIX_STATUS,
  URL_ADD_ITEM_IN_MATRIX,
  URL_MATRIX_USERS,
  URL_MATRIX_USER_SHOW_ACCESS,
  URL_MATRIX_USER_REMOVE_ACCESS,
  URL_MATRIX_SHOW_STATS,
  URL_PRODUCER_MATRIX,
  URL_SHOW_EPISODES,
  URL_BOOKING_STATS,
  URL_CHANGE_USER_ROLE,
  URL_ADD_SHOW_ACCESS,
  URL_SHOW_EPISODES_DAYS,
  URL_ADD_BUDGET,
  URL_COPY_MATRIX_ITEM,
  URL_UPDATE_MATRIX_PERFORMER_TIME,
  URL_ACCEPT_SHOW_PERMISSION,
  URL_SHOW_FILE_REMOVE,
  URL_SHOW_FILE_UPLOAD,
  URL_CUSTOM_PERFORMER_RATE,
  URL_ALL_SHOW,
  URL_SHOW_IMAGE_UPLOAD,
  URL_CREW_CALL,
  URL_DAILY_COSTS,
  URL_BG_COORDINATOR_INVITATION,
  URL_CALCULATE_WAGES,
  URL_SEND_INVOICE_ON_EMAIL,
  URL_DOWNLOAD_INVOICE_ON_EMAIL,
  URL_CLOSE_DELETE_ROLE,
  URL_VALIDATE_CONFIRM_CALL_SHEET,
  URL_REMOVE_CALL_SHEET_FILE,
  URL_SEND_BG_SIZE_CARD,
  URL_BREAKDOWN_MATRIX,
  URL_PERFORMER_RATE,
  URL_PAYROLL_USER,
  URL_CUSTOM_RATES,
  URL_SHARE_CUSTOM_RATES,
  URL_BREAKDOWN,
} from 'store/services/URL';

export const showApi = {
  fetchShowListing(params: IShowListingParams) {
    return apiCall.post({ url: URL_SHOW_LISTING, isAuthToken: true, params });
  },

  fetchRateListing() {
    return apiCall.get({ url: URL_PERFORMER_RATE, isAuthToken: true, params: {} });
  },
  fetchCustomRateListing() {
    return apiCall.get({ url: URL_CUSTOM_PERFORMER_RATE, isAuthToken: true, params: {} });
  },
  fetchShowDetail(params: IShowId) {
    return apiCall.post({ url: URL_SHOW_DETAILS, isAuthToken: true, params });
  },

  createShow(show: ShowCreateParams) {
    return apiCall.post({ url: URL_CREATE_SHOW, isAuthToken: true, params: show });
  },

  showAccessRequest(params: IAddShowAccess) {
    return apiCall.post({ url: URL_SHOW_PERMISSION, isAuthToken: true, params });
  },

  acceptAhowAccessRequest(params: IAddShowAccess) {
    return apiCall.post({ url: URL_ACCEPT_SHOW_PERMISSION, isAuthToken: true, params });
  },

  updateShow(show: ShowCreateParams) {
    return apiCall.post({ url: URL_UPDATE_SHOW, isAuthToken: true, params: show });
  },

  createRole(show: ShowDayJob) {
    return apiCall.post({
      url: show.id === '-1' ? URL_CREATE_ROLE : URL_UPDATE_ROLE,
      isAuthToken: false,
      params: show,
    });
  },

  closeOrDeleteRole(params: CloseOrDeleteShowJob) {
    return apiCall.post({
      url: URL_CLOSE_DELETE_ROLE,
      isAuthToken: true,
      params,
    });
  },

  fetchDayJobListing(params: DateJobRequestParams) {
    return apiCall.post({ url: URL_SHOW_JOB_LISTING, isAuthToken: false, params });
  },
  searchPerformer(params: IPerformerSearchParam) {
    return apiCall.post({ url: URL_SEARCH_PERFORMER, isAuthToken: false, params });
  },
  sendRequestToPerfomer(params: ISendJobRequestParam) {
    return apiCall.post({ url: URL_JOB_REQUEST_TO_PERFORMER, isAuthToken: false, params });
  },

  sendShowPermissionRequest(showId: String) {
    return apiCall.post({ url: URL_SHOW_PERMISSION, isAuthToken: false, params: { showId } });
  },

  fetchEmailTemplates(params: IEmailTemplateParams) {
    return apiCall.post({ url: URL_EMAIL_TEMPLATE, isAuthToken: false, params });
  },

  fetchShowJobs(params: IIDS) {
    return apiCall.post({ url: URL_SHOW_WITH_JOBS, isAuthToken: false, params });
  },

  saveEmailTemplate(params: IEmailTemplateParams) {
    return apiCall.post({ url: URL_SAVE_EMAIL_TEMPLATE, isAuthToken: true, params });
  },
  deleteWorkingDay(params: IIDParams) {
    return apiCall.post({ url: URL_DELETE_WORKING_DAY, isAuthToken: true, params });
  },
  fetchMatrixData(params: IMatrixParams) {
    return apiCall.post({ url: URL_MATRIX_DATES, isAuthToken: true, params });
  },
  fetchMatrixPerformer(params: IMatrixParams) {
    return apiCall.post({ url: URL_MATRIX_PERFORMER, isAuthToken: true, params });
  },
  fetchShowHistory(params: IMatrixParams) {
    console.log(params);
    const array = Array(12).fill('');
    const data = array.map((item, index) => ({
      showBudget: {
        budget: faker.finance.amount(3000, 20000, 2),
        expense: faker.finance.amount(2000, 21000, 2),
      },
      showTitle: faker.name.firstName(),
      season: 'S3',
      episode: `E${index + 6}`,
    }));
    return data;
  },

  fetchNotesReview(params: IMatrixParams) {
    console.log(params);
    const noteItems = [
      { date: 'Aug 20', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
      { date: 'Aug 20', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
      { date: 'Aug 22', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
      { date: 'Sep 1', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
      { date: 'Sep 2', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
      { date: 'Sep 9', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
      { date: 'Sep 9', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
      { date: 'Sep 9', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
      { date: 'Sep 9', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
    ];
    return noteItems;
  },

  fetchRecentActions(params: IMatrixParams) {
    console.log(params);
    const actionItems = [
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.lastName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.lastName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.lastName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.lastName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.lastName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.lastName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.lastName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'delete', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
      { type: 'edit', name: faker.name.firstName(), description: faker.lorem.sentence(4) },
    ];
    return actionItems;
  },
  fetchCostData(params: IMatrixParams) {
    console.log(params);
    const array = Array(7).fill('');
    const data = array.map((item, index) => ({
      showBudget: {
        budget: faker.finance.amount(3000, 20000, 2),
        expense: faker.finance.amount(2000, 21000, 2),
      },
      showTitle: faker.name.firstName(),
      season: 'S3',
      episode: `E${index + 6}`,
    }));
    return data;
  },

  fetchErrorNotes(param: any) {
    console.log(param);
    const noteItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const dataArray: any = [];
    noteItems.forEach(() => {
      dataArray.push(faker.lorem.sentence(5));
    });
    return dataArray;
  },

  saveNewAtoms(params: INewAtomsParam) {
    return apiCall.post({ url: URL_NEW_ATOMS, isAuthToken: true, params });
  },
  saveCustomRates(params: any) {
    return apiCall.post({ url: URL_CUSTOM_RATES, isAuthToken: true, params });
  },
  shareCustomRates(params: any) {
    return apiCall.post({ url: URL_SHARE_CUSTOM_RATES, isAuthToken: true, params });
  },
  addMatrixCoordinator(params: IAddCoordinator) {
    return apiCall.post({ url: URL_ADD_COORDINATOR, isAuthToken: true, params });
  },
  breakdown(params: IBreakdownPdfParams) {
    return apiCall.post({ url: URL_BREAKDOWN, isAuthToken: true, params });
  },
  updateMatrixItem(params: { data: any[] }) {
    return apiCall.post({ url: URL_UPDATE_MATRIX_ITEM, isAuthToken: true, params });
  },
  changeMatrixAtoms(params: IChangeAtoms) {
    return apiCall.post({ url: URL_UPDATE_MATRIX_ATOMS_POS, isAuthToken: true, params });
  },
  updateAtomsName(params: IChangeAtomsTitle) {
    return apiCall.post({ url: URL_UPDATE_ATOMS_TITLE, isAuthToken: true, params });
  },
  removePerformerFromAtoms(params: IRemovePerformerFromAtoms) {
    return apiCall.post({ url: URL_DELETE_MATRIX_ITEM, isAuthToken: true, params });
  },
  deleteMatrixItemRead(params: any) {
    return apiCall.post({ url: URL_DELETE_MATRIX_READ, isAuthToken: true, params });
  },
  setMatrixEmailTemplate(params: ISetMatrixEmailTemplate) {
    return apiCall.post({ url: URL_SET_MATRIX_EMAIL_TEMPALTE, isAuthToken: true, params });
  },
  sendMatrixEmail(params: ISetMatrixEmailTemplate) {
    return apiCall.post({ url: URL_MATRIX_SEND_EMAIL, isAuthToken: true, params });
  },
  uploadCallSheet(params: IProfileFileParam) {
    return apiCall.filePost(URL_UPLOAD_CALL_SHEET_FILE, params);
  },
  removeCallSheet(params: IRemoveCallSheetFile) {
    return apiCall.post({ url: URL_REMOVE_CALL_SHEET_FILE, isAuthToken: true, params });
  },
  updateMatrixStatus(params: IMatrixParams) {
    return apiCall.post({ url: URL_UPDATE_MATRIX_STATUS, isAuthToken: true, params });
  },
  addItemInMatrix(params: ICopyMatrixData) {
    return apiCall.post({ url: URL_ADD_ITEM_IN_MATRIX, isAuthToken: true, params });
  },
  fetchShowUser(params: IMatrixParams) {
    return apiCall.post({ url: URL_MATRIX_USERS, isAuthToken: true, params });
  },
  fetchUserShowAccess(params: IMatrixParams) {
    return apiCall.post({ url: URL_MATRIX_USER_SHOW_ACCESS, isAuthToken: true, params });
  },
  removeShowAccess(params: IMatrixParams) {
    return apiCall.post({ url: URL_MATRIX_USER_REMOVE_ACCESS, isAuthToken: true, params });
  },
  fetchShowStats(params: IMatrixParams) {
    return apiCall.post({ url: URL_MATRIX_SHOW_STATS, isAuthToken: true, params });
  },
  fetchProducerMatrix(params: IMatrixParams) {
    return apiCall.post({ url: URL_PRODUCER_MATRIX, isAuthToken: true, params });
  },
  fetchPayRollUser(params: any = {}) {
    return apiCall.get({ url: URL_PAYROLL_USER, isAuthToken: true, params });
  },
  fetchShowEpisodes(params: IMatrixParams) {
    return apiCall.post({ url: URL_SHOW_EPISODES, isAuthToken: true, params });
  },
  fetchShowEpisodesDays(params: IMatrixParams) {
    return apiCall.post({ url: URL_SHOW_EPISODES_DAYS, isAuthToken: true, params });
  },
  fetchBookingStats(params: IMatrixParams) {
    return apiCall.post({ url: URL_BOOKING_STATS, isAuthToken: true, params });
  },
  fetchDailyCosts(params: IDailyCost) {
    return apiCall.post({ url: URL_DAILY_COSTS, isAuthToken: true, params });
  },
  changeUserRole(params: IChangeRoleParams) {
    return apiCall.post({ url: URL_CHANGE_USER_ROLE, isAuthToken: true, params });
  },
  addShowAccess(params: IAddShowAccess) {
    return apiCall.post({ url: URL_ADD_SHOW_ACCESS, isAuthToken: true, params });
  },
  addBudget(params: IAddBudget) {
    return apiCall.post({ url: URL_ADD_BUDGET, isAuthToken: true, params });
  },
  copyMatrixItem(params: ICopyMatrixItem) {
    return apiCall.post({ url: URL_COPY_MATRIX_ITEM, isAuthToken: true, params });
  },
  updateMatrixTiming(params: { data: any[] }) {
    return apiCall.post({ url: URL_UPDATE_MATRIX_PERFORMER_TIME, isAuthToken: true, params });
  },
  uploadShowFile(payload: IProfileFileParam) {
    return apiCall.filePost(URL_SHOW_FILE_UPLOAD, payload);
  },
  removeShowFile(params: IRemoveFile) {
    return apiCall.post({ url: URL_SHOW_FILE_REMOVE, isAuthToken: true, params });
  },
  getAllShows() {
    return apiCall.post({ url: URL_ALL_SHOW, isAuthToken: true, params: {} });
  },
  uploadShowImage(payload: IProfileFileParam) {
    return apiCall.filePost(URL_SHOW_IMAGE_UPLOAD, payload);
  },
  uploadCrewCall(params: IcallTime) {
    return apiCall.post({ url: URL_CREW_CALL, isAuthToken: true, params });
  },
  coordinatorInvitation(params: IBGCInvitation) {
    return apiCall.post({ url: URL_BG_COORDINATOR_INVITATION, isAuthToken: true, params });
  },
  calculateWages(params: { id: string }) {
    return apiCall.post({ url: URL_CALCULATE_WAGES, isAuthToken: true, params });
  },
  sendInvoiceOnEmail(params: { id: string }) {
    return apiCall.post({ url: URL_SEND_INVOICE_ON_EMAIL, isAuthToken: true, params });
  },
  downloadInvoicesPdf(params: IDownloadInvoicesPdf) {
    return apiCall.post({ url: URL_DOWNLOAD_INVOICE_ON_EMAIL, isAuthToken: true, params });
  },
  validateConfirmCallSheet(params: IValidateCallSheet) {
    return apiCall.post({ url: URL_VALIDATE_CONFIRM_CALL_SHEET, isAuthToken: true, params });
  },
  sendBgSizeCard(params: IBgSizeCardParams) {
    return apiCall.post({ url: URL_SEND_BG_SIZE_CARD, isAuthToken: true, params });
  },
  matrixBreakdown(params: IBreakdownParams) {
    return apiCall.post({ url: URL_BREAKDOWN_MATRIX, isAuthToken: true, params });
  },
};

export default showApi;
