import { fromJS } from 'immutable';
import ACTIONS from 'store/castingPax.constants';

const initialState = fromJS({
  loading: false,
  values: {},
  rateValues: '',
});

export default function rateReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.CREATE_RATE_VALUES.PENDING: {
      return state.set('loading', true).set('values', {}).set('error', '');
    }
    case ACTIONS.CREATE_RATE_VALUES.SUCCESS: {
      return state.set('loading', false).set('error', '').set('values', action.data.data);
    }
    case ACTIONS.CREATE_RATE_VALUES.ERROR: {
      return state.set('loading', false).set('values', {}).set('error', action);
    }

    case ACTIONS.UPDATE_RATE_VALUES.PENDING: {
      return state.set('loading', true).set('values', {}).set('error', '');
    }
    case ACTIONS.UPDATE_RATE_VALUES.SUCCESS: {
      return state.set('loading', false).set('error', '').set('values', action.data.data);
    }
    case ACTIONS.UPDATE_RATE_VALUES.ERROR: {
      return state.set('loading', false).set('values', {}).set('error', action);
    }

    case ACTIONS.VIEW_RATE_VALUES.PENDING: {
      return state.set('loading', true).set('values', {}).set('error', '');
    }
    case ACTIONS.VIEW_RATE_VALUES.SUCCESS: {
      return state.set('loading', false).set('error', '').set('values', action.data.data);
    }
    case ACTIONS.VIEW_RATE_VALUES.ERROR: {
      return state.set('loading', false).set('values', {}).set('error', action);
    }
    case ACTIONS.VIEW_RATE_TITLE_BEGIN: {
      return state.set('rateValues', action.payload.title);
    }

    case ACTIONS.UPDATE_RATE_TITLE.PENDING: {
      return state.set('loading', true).set('values', {}).set('error', '');
    }
    case ACTIONS.UPDATE_RATE_TITLE.SUCCESS: {
      console.log('reducers...', action.data.data);
      return state
        .set('loading', false)
        .set('error', '')
        .set('customRateList', action.data.data)
        .set('values', action.data.data);
    }
    case ACTIONS.UPDATE_RATE_TITLE.ERROR: {
      return state.set('loading', false).set('values', {}).set('error', action);
    }
    default: {
      return state;
    }
  }
}
