import React, { FunctionComponent } from 'react';
import Style from 'components/Producer/Matrix/UserManagementItem/Popover/RolesPopover.module.css';
import checkSvg from 'assets/svg/only-check.svg';
import { SelectOptionType } from 'modules/PerformerList/list.types';

interface RolesPopoverProps {
  selected: string;
  handleClick?: Function;
  data: SelectOptionType[];
}

const RolesPopover: FunctionComponent<RolesPopoverProps> = (props: RolesPopoverProps) => {
  const { selected, handleClick, data } = props;

  const onHandleClick = (item: SelectOptionType) => {
    document.body.click();
    if (handleClick) {
      handleClick(item);
    }
  };

  return (
    <div className={Style.mainWrapper}>
      {data.map((item: SelectOptionType) => (
        <div
          key={item.value}
          className={`${Style.innerWrapper}`}
          onClick={() => onHandleClick(item)}
          onKeyPress={() => onHandleClick(item)}
          role="button"
          tabIndex={0}
        >
          <p className={`mb-0 ${Style.title}`}>{item.label}</p>
          {selected === item.value && <img src={checkSvg} alt="" className={Style.checkIcon} />}
        </div>
      ))}
    </div>
  );
};

export default RolesPopover;
