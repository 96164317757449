import React, { FunctionComponent, useEffect, useState } from 'react';
import Header from 'components/topHeader/topHeader';
import signupCatStyle from 'components/singupCategory/signupCategory.module.css';
import api from 'store/services/auth.services';
import { IValidateToken } from 'modules/params/param.type';
import Loader from 'components/controls/Loader/Loader';
import { Link } from 'react-router-dom';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';

interface ConfirmAccountProps {
  match: any;
}

const ResetPassword: FunctionComponent<ConfirmAccountProps> = (props: ConfirmAccountProps) => {
  const { match } = props;
  const { token } = match.params;
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (token) {
      const params: IValidateToken = {
        id: '1',
        token,
      };
      api
        .confirmAccountEmail(params)
        .then(() => {
          setIsValid(true);
          setIsLoading(false);
        })
        .catch(() => {
          setIsValid(false);
          setIsLoading(false);
        });
    }
  }, []);

  if (isLoading) {
    return <Loader isSmallView />;
  }

  const redirectButton = () => {
    const user = getItemFromLocalStorage('user');
    if (user) return <Link to="/">home</Link>;
    return <Link to="/login">Login</Link>;
  };

  return (
    <>
      {isValid ? (
        <>
          <Header />
          <div className={signupCatStyle.backgroundWrapper}>
            <div className={signupCatStyle.bgInnerContent}>
              <h1>Confirm Email</h1>
              <p className={signupCatStyle.alfterHeading}>Email confirmed. Thank you.</p>
            </div>
          </div>
        </>
      ) : (
        <div>
          Link Expired return to
          {redirectButton()}
        </div>
      )}
    </>
  );
};
export default ResetPassword;
