import React from 'react';
import styled from 'styled-components';
import DownloadAll from './DownloadAll';
import TaxFileDownload from './TaxFileDownload';
import MoreFilePop from './Popup/MoreFilesPopup';

interface Props {}

const TaxFilesRenderer: React.FC<Props> = () => (
  <Wrapper>
    <TextItem>Tax Files</TextItem>
    <ControlContainer>
      <TaxFileDownload number={1} />
      <TaxFileDownload background="rgba(235, 87, 87, 0.1)" number={2} />
      <TaxFileDownload background="rgba(39, 174, 96, 0.1)" number={3} />
      <MoreFilePop />
      <DownloadAll />
    </ControlContainer>
  </Wrapper>
);

export default TaxFilesRenderer;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  width: 100%;
`;

const TextItem = styled.div`
  color: #2f80edcc;
`;

const ControlContainer = styled.div`
  display: flex;
`;
