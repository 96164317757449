import React, { FunctionComponent } from 'react';
import Style from 'components/bgPerformer/CastingRequest/CastingRequest.module.css';
import Button from 'components/controls/button/button';
import { ShowDayJob } from 'modules/Show/show.types';
import DateItem from 'components/dateItem/dateItem';
import exportSvg from 'assets/svg/export-white.svg';

interface CallDetailProps {
  item?: ShowDayJob;
  handleMouseLeave: Function;
  fullDetailHandle?: Function;
  dates: string[];
}
const CastingCallDetail: FunctionComponent<CallDetailProps> = (props: CallDetailProps) => {
  const { item, handleMouseLeave, fullDetailHandle, dates } = props;

  const handleClick = () => {
    handleMouseLeave();
    if (fullDetailHandle) fullDetailHandle();
  };
  return (
    <div className={Style.itemDetailPopoverWrapper}>
      <div className={Style.itemDetailPopoverHeader}>
        <p className="mb-0">{item?.title}</p>
      </div>
      <div className={Style.itemDetailPopoverBody}>
        <div className={Style.popoverLocationWrapper}>
          <p className={`mb-2 ${Style.popoverLocationTitle}`}>Location</p>
          <p className={`mb-0 ${Style.popoverLocationDesc}`}>{item?.location ? item?.location.label : ''}</p>
        </div>
        <div className={Style.popoverLocationWrapper}>
          <p className={`mb-2 ${Style.popoverLocationTitle}`}>Description</p>
          <p className={`mb-3 ${Style.popoverLocationDesc}`}>{item?.remarks}</p>
        </div>
        <div className="d-flex ml-4 mt-4 text-center">
          <DateItem dates={dates} cssClass={`${Style.columnMargin}`} />
        </div>
        <div className="text-center mt-4 px-3">
          <Button
            label="Full Detail"
            clickHandler={handleClick}
            rightIcon={exportSvg}
            cssClass={Style.popoverDetailBtn}
          />
        </div>
      </div>
    </div>
  );
};

export default CastingCallDetail;
