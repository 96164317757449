import React, { FunctionComponent } from 'react';
import PerformerProfileStyle from 'components/bgPerformer/BasicInfo/BasicInfo.module.css';
import PhoneInputFormat from 'components/phoneInput/phoneInputFormat';
import ProfileInfo from 'components/PerformerProfile/ProfileInfo';
import { User } from 'modules/user/types';
import Button from 'components/controls/button/button';
import payrollSvg from 'assets/svg/dollar.svg';
import addNewImage from 'assets/images/addNewImage.png';
import imagePreviewIcon from 'assets/images/imagePreviewIcon.svg';
import { useSelector } from 'react-redux';
import { resumeUploadingProgress } from 'store/selector/auth.selector';
import uploadSvg from 'assets/svg/upload.svg';
import editSvg from 'assets/svg/edit-white.svg';
import exportWhiteSvg from 'assets/svg/export-white.svg';
import sizeCardSvg from 'assets/svg/size-card.svg';
import { formatHeight, formatWeight, getAge, getFileName, getUnionClass, getUnionValue } from 'helpers/utils';
import Resume from '../Resume/Resume';

interface BasicInfoProps {
  profile: User;
  handleEdit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleSizeCard: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleResumeUpload: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleEditResume: Function;
}
const BasicInfo: FunctionComponent<BasicInfoProps> = (props: BasicInfoProps) => {
  const { profile, handleEdit, handleResumeUpload, handleEditResume, handleSizeCard } = props;
  const showResumeUploadingProgress = useSelector(resumeUploadingProgress);
  const hide: string = '-1';
  const handlePayroll = () => console.log('handle Size card');

  const showResumeUploadedIcon = () => {
    const fileName1 = getFileName(profile.resumeUrl1 || '');
    const fileName2 = getFileName(profile.resumeUrl2 || '');
    return (
      (profile.resumeUrl1 !== undefined && fileName1 !== 'null' && fileName1 !== 'file') ||
      (profile.resumeUrl2 !== undefined && fileName2 !== 'null' && fileName2 !== 'file')
    );
  };
  const onImageError = (e: any) => {
    const placeholderImage = addNewImage;
    e.target.src = placeholderImage;
  };
  return (
    <div className={PerformerProfileStyle.basicInfoProfile}>
      <div className={`${PerformerProfileStyle.imageWrapper}`}>
        <img src={profile.profilePicUrl} alt="" className={PerformerProfileStyle.profileImage} onError={onImageError} />
      </div>
      <div className={`${PerformerProfileStyle.infoWrapper}`}>
        <div className={PerformerProfileStyle.infoHeadingSection}>
          <p className={`mb-0 ${PerformerProfileStyle.profileName}`}>{`${profile.firstName} ${profile.lastName}`}</p>
          <div className={PerformerProfileStyle.payrollsButtonWrapper}>
            {hide === '2' && (
              <div>
                <Button
                  label="Payroll"
                  type="primary"
                  cssClass="d-flex align-items-center fontFourteen font-weight-normal px-2.5 py-2"
                  icon={payrollSvg}
                  clickHandler={handlePayroll}
                />
              </div>
            )}
            <div
              className="ml-0 ml-sm-3 ml-xl-3 ml-md-3 ml-xl-3
            mb-3 mb-sm-0 mb-xl-0 mb-md-0 mb-xl-0 uploadResumeBtn dropdown"
            >
              <Button
                label="Upload resume"
                icon={uploadSvg}
                rightIcon={showResumeUploadedIcon() ? exportWhiteSvg : null}
                type="secondary"
                showLoading={showResumeUploadingProgress}
                clickHandler={handleResumeUpload}
                cssClass="dropbtn d-flex align-items-center fontFourteen font-weight-normal px-3 py-2"
              />
              <div className="dropdown-content">
                <Resume user={profile} handleEditResume={handleEditResume} />
              </div>
            </div>
            <div className="ml-0 ml-sm-3 ml-xl-3 ml-md-3 ml-xl-3 mb-3 mb-sm-0 mb-xl-0 mb-md-0 mb-xl-0">
              <Button
                label="edit profile details"
                icon={editSvg}
                type="secondary"
                clickHandler={handleEdit}
                cssClass="d-flex align-items-center fontFourteen font-weight-normal px-3 py-2"
              />
            </div>
          </div>
        </div>
        <div className={`position-relative ${PerformerProfileStyle.profileCard}`}>
          <div className={PerformerProfileStyle.performerId}>
            <p className="mb-0 mt-0">
              <span className={`${PerformerProfileStyle.afterTitle} ${getUnionClass(profile)}`}>
                {getUnionValue(profile)}
              </span>
            </p>
          </div>
          <div className="row mb-2 pt-3">
            <div className="col-sm-12 pt-2">
              <div className={PerformerProfileStyle.profileInfoWrapper}>
                <div className={PerformerProfileStyle.profileInfoInner}>
                  <ProfileInfo label="Gender" value={profile.bgPerformer?.gender} />
                </div>
                <div className={PerformerProfileStyle.profileInfoInner}>
                  <ProfileInfo label="Age" value={getAge(profile.bgPerformer?.dateOfBirth)} />
                </div>
                <div className={PerformerProfileStyle.profileInfoInner}>
                  <ProfileInfo label="Weight" value={formatWeight(profile.bgPerformer?.weight)} />
                </div>
                <div className={PerformerProfileStyle.profileInfoInner}>
                  <ProfileInfo label="Height" value={formatHeight(profile.bgPerformer?.height || '')} />
                </div>
                <div className={PerformerProfileStyle.profileInfoInner}>
                  <ProfileInfo label="City" value={profile?.address} />
                </div>
                <div className={PerformerProfileStyle.profileInfoInner}>
                  <PhoneInputFormat label="Phone" value={profile.phoneNo} />
                </div>
                <div className={PerformerProfileStyle.profileInfoInner}>
                  <ProfileInfo label="Email" value={profile.email} />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end pb-3">
            <Button
              cssClass="sizeCard-btn d-flex align-items-center justify-content-center"
              label="Size Card"
              icon={sizeCardSvg}
              type="primary"
              clickHandler={handleSizeCard}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
