import { fromJS } from 'immutable';
import ACTIONS, { FETCH_STATUS, ROLE_PERFORMER } from 'store/castingPax.constants';
import { PerformerImage, User } from 'modules/user/types';
import { selectedTabState } from 'helpers/utils';

const initialState = fromJS({
  user: {},
  loading: false,
  privacyLoading: false,
  resumeLoading: false,
  error: '',
  step: 1,
  searchUserList: [],
  selectedTab: selectedTabState.get() || '1',
  isSignUpCompleted: false,
  refreshPusher: false,
  fileLoading: false,
  cardDetails: null,
  cardError: null,
  cardLoading: false,
  subscriptionStatus: 'NA',
  historyData: [],
  historyPagination: [],
  historyloading: false,
  businessLoading: false,
});

export default function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.PROFILE_UPADTE.PENDING:
    case ACTIONS.LOGIN.PENDING: {
      return state.set('loading', true);
    }
    case ACTIONS.LOGIN.SUCCESS: {
      return state.set('loading', false).set('error', '').set('user', action.data).set('refreshPusher', true);
    }
    case ACTIONS.LOGIN.ERROR: {
      console.log('Error', action);
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.AUTHENTICATED_USER: {
      return state.set('user', action.data);
    }
    case ACTIONS.SIGNUP.PENDING: {
      return state.set('loading', true);
    }
    case ACTIONS.SIGNUP.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', '')
        .set('step', action.step)
        .set('isSignUpCompleted', action.step === 1)
        .set('user', action.data);
    }
    case ACTIONS.PROFILE_UPADTE.ERROR:
    case ACTIONS.SIGNUP.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.PROFILE_UPADTE.SUCCESS: {
      return state.set('user', action.data).set('loading', false).set('error', '');
    }
    case ACTIONS.UPDATE_PERFORMER_DATA: {
      return state.set('user', action.data);
    }
    case ACTIONS.DELETE_PERFORMER_IMAGE_AUTH: {
      const prev = state.get('user');
      const index = prev.bgPerformer.images.findIndex((x: PerformerImage) => x.id === action.data.id);
      const prevImages = prev.bgPerformer.images;
      if (index !== -1) {
        prevImages.splice(index, 1);
        prev.bgPerformer.images = [...prevImages];
      }
      return state.set('user', { ...prev });
    }

    case ACTIONS.PEROFILE_UPDATE: {
      if (action.data === ROLE_PERFORMER) {
        return state.set('step', 1).set('isSignUpCompleted', true).set('refreshPusher', true);
      }
      return state.set('step', 1).set('isSignUpCompleted', true).set('user', {});
    }
    case ACTIONS.CHANGE_SELECTED_TAB:
      return state.set('selectedTab', action.data);
    case ACTIONS.AUTH_LOGOUT:
      return state.set('user', null).set('selectedTab', '1');
    case ACTIONS.RESET_ERROR_MESSAGE:
      return state.set('loading', false).set('error', '');
    case ACTIONS.PROFILE_FILE_UPLOAD.PENDING: {
      if (action.data === 'resume') return state.set('resumeLoading', true);
      return state.set('fileLoading', true);
    }
    case ACTIONS.PROFILE_FILE_UPLOAD.ERROR: {
      return state.set('fileLoading', false).set('resumeLoading', false);
    }
    case ACTIONS.PROFILE_FILE_UPLOAD.SUCCESS: {
      if (action.payload.updateCall) {
        return state.set('user', action.data).set('fileLoading', false).set('resumeLoading', false);
      }
      return state.set('fileLoading', false).set('resumeLoading', false);
    }
    case ACTIONS.UPDATE_SUBSCRIPTION_PLAN.PENDING: {
      return state.set('subscriptionStatus', FETCH_STATUS.LOADING);
    }
    case ACTIONS.UPDATE_SUBSCRIPTION_PLAN.SUCCESS: {
      const prevUser: User = state.get('user');
      prevUser.subscription = action.data.subscriptionPlan;
      prevUser.subscriptionExpirAt = action.data.subscriptionExpirAt;
      return state.set('user', { ...prevUser }).set('subscriptionStatus', FETCH_STATUS.SUCCESS);
    }
    case ACTIONS.UPDATE_SUBSCRIPTION_PLAN.ERROR: {
      return state.set('subscriptionStatus', FETCH_STATUS.ERROR);
    }
    case ACTIONS.GET_CARD_DETAILS.SUCCESS: {
      return state.set('cardDetails', action.data);
    }
    case ACTIONS.SAVE_CARD_DATA.PENDING: {
      return state.set('cardError', '').set('cardLoading', true);
    }
    case ACTIONS.SAVE_CARD_DATA.SUCCESS: {
      const prevUser: User = state.get('user');
      prevUser.cardId = action.data.id;
      return state
        .set('user', { ...prevUser })
        .set('cardError', '')
        .set('cardLoading', false);
    }
    case ACTIONS.SAVE_CARD_DATA.ERROR: {
      return state.set('cardError', action.error).set('cardLoading', false);
    }
    case ACTIONS.CLEAR_CARD_DATA_ERROR: {
      return state.set('cardError', '');
    }
    case ACTIONS.NOTIFICATION_PRIVACY_STATUS.PENDING: {
      return state.set('privacyLoading', action.status === 'LOADING');
    }
    case ACTIONS.NOTIFICATION_PRIVACY_STATUS.SUCCESS: {
      return state.set('privacyLoading', false).set('error', '').set('user', action.data);
    }
    case ACTIONS.NOTIFICATION_PRIVACY_STATUS.ERROR: {
      return state.set('privacyLoading', false).set('error', action.error);
    }
    case ACTIONS.DELETE_ACCOUNT.PENDING: {
      return state.set('closeLoading', true).set('error', false);
    }
    case ACTIONS.DELETE_ACCOUNT.ERROR: {
      return state.set('closeLoading', false).set('error', action.error);
    }
    case ACTIONS.SUBSCRIPTION_HISTORY.PENDING: {
      return state.set('historyloading', true).set('error', '');
    }
    case ACTIONS.SUBSCRIPTION_HISTORY.ERROR: {
      return state.set('historyloading', false).set('error', action.error);
    }
    case ACTIONS.SUBSCRIPTION_HISTORY.SUCCESS: {
      console.log(action.data);
      return state
        .set('historyData', action.data.data)
        .set('historyPagination', action.data.pagination)
        .set('historyloading', false)
        .set('error', '');
    }
    case ACTIONS.STRIPE_LINKED_ACCOUNT.SUCCESS: {
      return state.set('user', action.data);
    }
    case ACTIONS.STRIPE_LINKED_ACCOUNT.ERROR: {
      return state.set('error', action.error);
    }
    case ACTIONS.UPDATE_BUSINESS_INFO.PENDING: {
      return state.set('businessLoading', true).set('error', '');
    }
    case ACTIONS.UPDATE_BUSINESS_INFO.SUCCESS: {
      return state.set('user', action.data).set('businessLoading', false).set('error', '');
    }
    case ACTIONS.UPDATE_BUSINESS_INFO.ERROR: {
      return state.set('error', action.error).set('businessLoading', false);
    }
    case ACTIONS.UPDATE_PERFORMER_RENTAL_IMAGES: {
      const prevUser: User = state.get('user');
      if (prevUser.id) {
        if (action.data) {
          prevUser.rentalImages = action.data;
        }
        return state.set('user', { ...prevUser });
      }
      return state;
    }
    case ACTIONS.REMOVE_PERFORMER_RENTAL_IMAGES: {
      const prevUser: User = state.get('user');
      if (prevUser.id && action.data) {
        const { rentalImages } = prevUser;
        if (rentalImages && rentalImages.length > 0) {
          const index = rentalImages.findIndex((r: any) => r.id === action.data);
          if (index !== -1 && prevUser.rentalImages) {
            prevUser.rentalImages.splice(index, 1);
          }
        }
        return state.set('user', { ...prevUser });
      }
      return state;
    }
    case ACTIONS.UPDATE_PERFORMER_RENTAL_TYPE: {
      const prevUser: User = state.get('user');
      if (prevUser.id && action.data) {
        const { rentalImages } = prevUser;
        if (rentalImages && rentalImages.length > 0) {
          const index = rentalImages.findIndex((r: any) => r.id === action.payload);
          if (index !== -1 && prevUser.rentalImages) {
            prevUser.rentalImages.splice(index, 1, action.data);
          }
        }
        return state.set('user', { ...prevUser });
      }
      return state;
    }

    default: {
      return state;
    }
  }
}
