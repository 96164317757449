import React, { FunctionComponent, useState, useEffect } from 'react';
import Style from 'components/ProfileMenu/Settings/Setting.module.css';
import Input from 'components/controls/input/dashboardInput/dashboardInput';
import { api } from 'store/services/auth.services';
import { userApi } from 'store/services/user.services';
import ApiResponse from 'modules/response/response.types';
import { User, UserList } from 'modules/user/types';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
// import Loader from 'components/controls/Loader/Loader';
import SearchItem from './components/searchItem';

// import api from 'store/services/user.services';

// interface InviteUserFormInterface {
//   handleSubmit: (email: string) => void;
// }

interface Props {
  handleClose: () => void;
}
const InviteUserForm: FunctionComponent<Props> = (props) => {
  const { handleClose } = props;
  // const [email, setEmail] = useState<string>('');
  const [, setToggleResult] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [result, setResult] = useState<UserList>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [, setError] = useState<string>('');

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await api.invitePayrollUserRequest({ email: search }).then(() => {
        setLoading(false);
      });
    } catch (er) {
      console.log(er);
      setLoading(false);
    } finally {
      handleClose();
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => searchRecord(), 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
    console.log(result);
  };

  const searchRecord = () => {
    if (!search) {
      handleCloseSearchResult();
      return;
    }
    setLoading(true);
    setError('');
    fetchRecords(1);
  };
  const fetchRecords = (page: number) => {
    try {
      userApi.searchUser({ searchText: search, type: 'short', page }).then((res: ApiResponse) => {
        if (res.pagination) {
          const users = res.pagination.page === 1 ? res.data : [...result, ...res.data];
          setResult(users.filter((user: any) => user.role === '62d7fe87bbaec958ec06e3a4'));
          // setPagination(res.pagination);
        } else {
          setResult(res.data);
        }
        setLoading(false);
        setToggleResult(true);
      });
    } catch (e: any) {
      setLoading(false);
      setError(e.message);
    }
  };

  const handleCloseSearchResult = () => {
    setToggleResult(false);
    setSearch('');
    setResult([]);
    // setPagination(defaultPagination());
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="row pt-4">
        <div className="col-sm-12">
          <Input
            placeholder="Email"
            type="email"
            cssClass={Style.input}
            inputLabel={Style.settingInputLabel}
            value={search}
            inputChangeHandler={handleOnChange}
          />
          <SearchList>
            {result.map((u: User, index: number) => (
              <SearchItem item={u} key={index} handleClick={setSearch} />
            ))}
          </SearchList>
        </div>
      </div>
      <div className="text-center pt-4 w-100">
        <CustomButton type="submit" onClick={handleSubmit}>
          {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Invite'}
        </CustomButton>
      </div>
    </form>
  );
};

export default InviteUserForm;

// const StyledLoader = styled.div``;

const SearchList = styled.div`
  position: relative;
  left: 0;
  z-index: 15;
  top: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 0 0 10px 10px;
  margin-top: 10px;
`;

// const LoadingImage = styled.div`
//   position: absolute;
//   border: 5px solid #333;
//   border-radius: 50%;
//   height: 20px;
//   width: 20px;
// `;

const CustomButton = styled.button`
  background-color: #f1f1f5;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
  border: none;
  color: #696974 !important;
  min-width: 150px;
  height: 48px;
  letter-spacing: 0.1px;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
`;
