import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './date.css';

const localizer = momentLocalizer(moment);

interface DatePickerProps {
  open: boolean;
}

interface Props {
  open: boolean;
  height?: number;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  onChangeDate?: any;
}

const DatePicker: React.FC<Props> = (props) => {
  const { open, height, setDate, onChangeDate } = props;
  const defaultDate = useMemo(() => new Date('2022-05-12T13:45:00-05:00'), []);

  const events = [
    {
      title: 'My Event',
      start: '2015-04-12T13:45:00-05:00',
      end: '2015-04-12T14:00:00-05:00',
    },
  ];

  const selectChange = (data: Date) => {
    console.log('selecting change');
    console.log(data);
    setDate(data.toDateString());
    onChangeDate(data.toDateString(), 'date');
  };

  return (
    <StyledDatePicker open={open}>
      <Calendar
        localizer={localizer}
        events={events}
        onNavigate={selectChange}
        startAccessor="start"
        endAccessor="end"
        style={{ height: `${height}px` || '500px', display: 'flex', alignItems: 'center' }}
        // views={{ week: false }}
        defaultDate={defaultDate}
      />
    </StyledDatePicker>
  );
};

export default DatePicker;

const StyledDatePicker = styled.section<DatePickerProps>`
  opacity: ${({ open }) => (open ? 1 : 0)};
  background: white;
  height: 450.6px;
  border-radius: 10px;
  z-index: 9999;

  &::after {
    content: '';
    position: absolute;
    height: 14px;
    width: 14px;
    background: white;
    top: -7px;
    right: 310px;
    transform: rotate(45deg);
    border: 2px solid ${({ theme }) => theme.colors.lightBlue};
    border-bottom: transparent;
    border-right: transparent;
  }
`;
