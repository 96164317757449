import { connect } from 'react-redux';
import { Show } from 'modules/Show/show.types';
import {
  clearJobErrorMessage,
  createJobBegin,
  setDatesForFitler,
  setSelectedJob,
  setPerformerTab,
  closeOrDeleteJobBegin,
} from 'store/actions/show.actions';
import { setSelectedTab } from 'store/actions/auth.actions';
import {
  getApiError,
  getEmailTemplateAsOpt,
  getEmailTemplates,
  getPerformerTab,
  getRequestTextAsOpt,
  getSelectedShow,
  getSelectedShowDate,
  getShowJob,
  getShowWorkingDays,
  isLoading,
} from 'store/selector/show.selector';
import ShowRoleEntry from 'components/ShowJob/ShowJobEntry/ShowJobEntry';
import { getRates } from 'store/selector/general.selector';

const mapStateToProps = (state: Show) => {
  const selectedJob = getShowJob(state);
  const selectedDate = getSelectedShowDate(state);
  const templateList = getEmailTemplates(state);
  const workingDaysList = getShowWorkingDays(state);
  const showLoading = isLoading(state);
  const templateListOpt = getEmailTemplateAsOpt(state);
  const requestListOpt = getRequestTextAsOpt(state);
  const rateList = getRates(state);
  const selectedShow = getSelectedShow(state);
  const apiError = getApiError(state);
  const perfromerTab = getPerformerTab(state);

  return {
    selectedJob,
    selectedDate,
    templateList,
    workingDaysList,
    showLoading,
    templateListOpt,
    requestListOpt,
    rateList,
    selectedShow,
    apiError,
    perfromerTab,
  };
};

const mapDispatchToProps = {
  createJobBegin,
  setSelectedTab,
  setSelectedJob,
  clearJobErrorMessage,
  setDatesForFitler,
  setPerformerTab,
  closeOrDeleteJobBegin,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowRoleEntry);
