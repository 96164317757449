import React, { FunctionComponent, useEffect, useState } from 'react';
import { MatrixObject, MatrixType, Show } from 'modules/Show/show.types';
import { fetchProducerMatrixBegin } from 'store/actions/show.actions';
import { connect } from 'react-redux';
import MatrixItem from 'components/Producer/Matrix/MatrixItem/MatrixItem';
import PaginationComponent from 'components/controls/pagination/Pagination';
import { Pagination } from 'modules/jobs/types';
import { defaultPagination } from 'helpers/utils';

interface MatrixListingProps {
  show: Show;
  matrix: MatrixObject;
  fetchMatrixData: Function;
}

const MatrixListing: FunctionComponent<MatrixListingProps> = (props: MatrixListingProps) => {
  const { show, matrix, fetchMatrixData } = props;
  const [pagination, setPagination] = useState<Pagination>(defaultPagination());

  useEffect(() => {
    loadData(pagination.page);
  }, []);

  useEffect(() => {
    if (matrix && matrix.pagination) {
      setPagination(matrix.pagination);
    }
  }, [matrix]);

  const loadData = (page: number) => {
    fetchMatrixData({ id: show.id, page, limit: 10 });
  };

  const getMatrixData = (): MatrixType[] => {
    if (matrix && matrix.data) {
      return matrix.data;
    }
    return [];
  };

  const handlePages = (updatePage: any) => {
    loadData(updatePage.selected + 1);
  };

  return (
    <>
      {getMatrixData().map((item: MatrixType) => (
        <MatrixItem key={item.id} workingDay={item} stats={matrix.stats} />
      ))}
      <div className="mt-3">
        <PaginationComponent
          page={pagination.page}
          totalPages={pagination.totalPages > 0 ? pagination.totalPages : 1}
          handlePagination={handlePages}
        />
      </div>
    </>
  );
};

const mapStateToDispatch = {
  fetchMatrixData: fetchProducerMatrixBegin,
};

export default connect(null, mapStateToDispatch)(MatrixListing);
