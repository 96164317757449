export type Options = {
  id: string;
  type: string;
  value: string;
  label: string;
  description?: string;
  icon?: string;
  dates?: string[];
};

export type PerformerVoucher = {
  performerId: string;
  name: string;
  street: string;
  number: number;
  apartmentNumber: number;
  address: string;
  state: string;
  zipCode: number;
  city: string;
  location: Options;
  sin: string;
  gst: string;
  union: string;
  nonUnion: boolean;
  fullUnion: boolean;
  apprentice: boolean;
  extraUnion: boolean;
};

export type CastingDirectorFilters = {
  id: string;
  title: string;
};

export type RateOptions = {
  id: string;
  title: string;
  value: String;
  label: string;
  rate: number;
  code: string;
};

export type RateOptionList = RateOptions[];

export type RentalOption = {
  id: string;
  value: string;
  label: string;
};

export type RentalOptionList = RentalOption[];

export type OptionList = Options[];

export type SearchJobParams = {
  category?: string;
  location?: OptionList;
};

export type ToastItem = {
  id: number;
  title: string;
  description?: string;
  type: string;
};

export type SkillCategory = {
  id: string;
  title: string;
  parentCategory?: string;
  subCategories?: SkillCategoryList;
  isChecked?: boolean;
};

export type role = {
  id: string;
  name: string;
  date: Date;
};

export type rolelist = role[];

export type Notification = {
  id: string;
  title: string;
  message: string;
  description: string;
  type: string;
  isSeen: boolean;
  updatedAt: string;
  data: any;
};

export type EmailTemplate = {
  id: string;
  showId: string;
  title: string;
  subject: string;
  templateHtml: string;
  templateType: string;
  messageType: string;
  type: string;
  createdFrom: string;
  attachements: string[];
};

export type EmailTemplateList = EmailTemplate[];

export type SkillCategoryList = SkillCategory[];

export type ToastItemList = ToastItem[];

export type NotificationList = Notification[];

export type EventItem = {
  id: number;
  title: string;
  allDay?: boolean;
  start: Date;
  end: Date;
  dateInNumber: number;
  description: string;
  status: string;
};

export type EventItemList = EventItem[];

export type MessageBoxItem = {
  title?: string;
  message?: string;
  type: number;
};

export type HelpCenterQuestion = {
  id: string;
  title: string;
  answer: string;
  itemPosition: number;
  categoryId: string;
};

export type HelpCenterItem = {
  id: string;
  title: string;
  itemPosition: number;
  children: HelpCenterQuestion[];
};

export type HelpCenterList = HelpCenterItem[];

export type DailyInvoice = {
  id: string;
  description: string;
  file: string;
  invoiceNo: number;
  createdAt: string;
  totalBill: number;
};

export type DailyInvoiceList = DailyInvoice[];

export type OPTION_TYPE =
  | 'ethinicity'
  | 'eyes'
  | 'hair'
  | 'union'
  | 'gender'
  | 'bodytype'
  | 'rental'
  | 'skills'
  | 'location'
  | 'minheight'
  | 'maxheight'
  | 'minweight'
  | 'maxweight'
  | 'minage'
  | 'maxage'
  | 'waist'
  | 'maxwaist'
  | 'neck'
  | 'maxneck'
  | 'jacket'
  | 'maxjacket'
  | 'inseam'
  | 'maxinseam'
  | 'sleeve'
  | 'maxsleeve'
  | 'shoe'
  | 'maxshoe'
  | 'chest'
  | 'maxchest'
  | 'category'
  | 'skillLevel'
  | 'activeStatus';

export type ScreenSize = 'MOBILE' | 'TABLET' | 'LAPTOP' | 'MEDIUMTABLET' | 'DESKTOP';

export const bgSizeCardEmpty = {
  type: '',
  ids: [],
  email: '',
};

export const breakdownEmpty = {
  type: '',
  ids: [],
  email: '',
  workingDayId: '',
};
