import React, { useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import downloadIcon from 'assets/svg/downloadBlueIcon.svg';
import MoreFilesButton from '../MoreFilesButton';

const MoreFilePop = () => {
  const tippyRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const popUpContent = (
    <PopWrapper>
      <TaxFileDownload color="#56CCF2" number={4} />
      <TaxFileDownload color="#2D9CDB" number={5} />
      <TaxFileDownload color="#9B51E0" number={6} />
      <TaxFileDownload color=" #F2994A" number={7} />
    </PopWrapper>
  );

  return (
    <Tippy
      ref={tippyRef}
      content={popUpContent}
      visible={visible}
      allowHTML
      arrow
      appendTo={document.body}
      interactive
      onClickOutside={hide}
      placement="bottom"
    >
      <Wrapper onClick={show}>
        <MoreFilesButton />
      </Wrapper>
    </Tippy>
  );
};

export default MoreFilePop;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  background: rgba(75, 77, 103, 0.1);
  margin-left: 5px;
`;

const PopWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 153px;
  height: 155.33px;
  right: -25px;
  top: 5px;
  background: #ffffff;
  border: 2px solid #e4f0fc;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  &::after {
    content: '';
    position: absolute;
    right: 15px;
    top: -7px;
    height: 14px;
    width: 14px;
    transform: rotate(45deg);
    border: 2px solid #e4f0fc;
    background: white;
    border-right: none;
    border-bottom: none;
  }
`;

interface Props {
  number?: number;
  color?: string;
}

const TaxFileDownload: React.FC<Props> = ({ number, color }) => (
  <Container>
    <TextItem color={color}>{`# ${number}`}</TextItem>
    <ImageBox src={downloadIcon} alt="download" />
  </Container>
);

const Container = styled.div`
  height: 36px;
  width: 100%;
  padding: 0 23px;
  border-bottom: 1px solid #e4f0fc;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ImageBox = styled.img`
  background: transparent;
  cursor: pointer;
`;

const TextItem = styled.p<Props>`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ color }) => color || '#2f80edcc'};
  font-weight: 500;
  margin-bottom: 0px;
`;
