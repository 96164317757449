import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment-timezone';
import Styles from 'components/Payroll/VoucherManagement/Invoice/InvoicePDF.module.css';
import { User } from 'modules/user/types';
import ubcpIcon from 'assets/svg/ubcp.svg';
import actraLogo from 'assets/svg/actra.svg';
import html2canvas from 'html2canvas';
import Jspdf from 'jspdf';

interface Props {
  item?: any;
  allRates?: any;
  allCustomRates?: any;
  isLoading: Function;
  isDownload?: boolean;
  onCompleteDownLoad?: Function;
}

const InvoicePDF: React.FC<Props> = (props: Props) => {
  const { item, isLoading, isDownload, onCompleteDownLoad } = props;
  const [inits, setInits] = useState<any>('');
  const [NDB, setNDB] = useState<boolean>(false);
  const [lunch, setLunch] = useState('');
  const [producer, setProducer] = useState<User>();
  const [template, setTemplate] = useState('UBCP');
  const invoiceRef = useRef<any>(null);

  useEffect(() => {
    if (isDownload && item && item?.signInTime) {
      isLoading(true);
      const element: any = invoiceRef.current;

      html2canvas(element).then((canvas) => {
        const imgWidth = 208;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const imgData = canvas.toDataURL('img/png');
        const pdf = new Jspdf('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save(`${item?.performer.firstName}${item?.performer.lastName}.pdf`);
        isLoading(false);
        if (onCompleteDownLoad) {
          onCompleteDownLoad();
        }
      });
    }
  }, [item, isDownload]);

  const producerName = item?.producer?.length > 0 && item?.producer[0].firstName + item?.producer[0].lastName;
  const totalAmount = item.totalWageAmount + item.totalCostumeAmount + item.totalMileageAmount || 0;

  const getFullUnionData = () => (
    <>
      <div
        className={`mt-4 d-flex align-items-center 
    text-align-center justify-content-between ${Styles.invoiceTitle}`}
      >
        <div>
          <p className={`m-0 ${Styles.logoRec}`}>
            <span>Casting</span>
            Pax
          </p>
        </div>
        <h2 className={Styles.headingTwo}>&nbsp; wages pending accounting approval</h2>
      </div>
      <div className={Styles.invoiceHeader}>
        <div className="row d-flex">
          <div className="col-4 text-center">
            <h1 className={Styles.headingOne}>
              background
              <br />
              performer voucher
              <span style={{ fontWeight: 400 }}>
                member and
                <br />
                permitee
              </span>
            </h1>
          </div>
          <div className="col-4">
            <div className={`text-center ${Styles.imgBox}`}>
              <img src={ubcpIcon} alt="ubcp" style={{ maxWidth: '250px' }} />
            </div>
          </div>
          <div className="col-4 contact-details text-center">
            <p className={Styles.paragraph}>
              UNION OF B.C. PERFORMERS
              <br />
              #300 -380 WEST 2nd AVE
              <br />
              VANCOUVER, B.C.
              <br />
              V5Y 1C8
              <br />
              TEL #: (604) 689-0727
              <br />
              FAX #: (604) 689-1145
              <br />
            </p>
          </div>
        </div>
      </div>
      <form action="">
        <h2 className={`text-center ${Styles.headingTwo}`}>Download and save the copies of your digital Voucher</h2>
        <table className={Styles.table}>
          <tr>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper w-100" style={{ display: 'flex', alignItems: 'center' }}>
                <div className={`align-items-center d-flex ${Styles.checkUbc}`} style={{ paddingRight: '20px' }}>
                  <label htmlFor="name" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    UBCP MEMBER:
                  </label>
                  <input
                    className={Styles.invoiceInpu}
                    type="checkbox"
                    id="agree"
                    name="agree"
                    defaultChecked={item?.unionNo === null || item?.unionNo === ''}
                  />
                  <label htmlFor="agree" className={Styles.customLabel}>
                    No
                  </label>
                  <input
                    className={Styles.invoiceInpu}
                    type="checkbox"
                    id="agree"
                    name="agree"
                    defaultChecked={item?.unionNo !== ''}
                  />
                  <label htmlFor="agree" className={Styles.customLabel}>
                    Yes:
                  </label>
                </div>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="mbr" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    MBR.#
                  </label>
                  <input
                    className={Styles.invoiceInput}
                    type="number"
                    id="MBR"
                    name="MBR"
                    placeholder={item?.unionNo}
                  />
                </div>
              </div>
            </td>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="date" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    DATE:
                  </label>
                  <input
                    className={Styles.invoiceInput}
                    type="text"
                    id="date"
                    name="date"
                    placeholder={moment(new Date()).format('DD MMM YYYY')}
                    // placeholder={moment(item?.date).format('DD MMM YYYY')}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="perfomer" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    PERFORMER NAME
                  </label>
                  <input
                    value={item?.name}
                    className={`text-capitalize ${Styles.invoiceInput}`}
                    type="number"
                    id="perfomer"
                    name="perfomer"
                    placeholder={item.name || item.performerName}
                  />
                </div>
              </div>
            </td>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="mbr" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    CALL ME:
                  </label>
                  <input
                    className={Styles.invoiceInput}
                    type="number"
                    id="MBR"
                    name="MBR"
                    placeholder={item?.callTime}
                  />
                </div>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="mbr" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    CREW CALL:
                  </label>
                  <input
                    className={Styles.invoiceInput}
                    type="text"
                    id="CrewCall"
                    name="CrewCall"
                    placeholder={item?.workingDayId?.crewCall}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="mbr" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    AGENT (if applicable):
                  </label>
                  <input className={Styles.invoiceInput} type="number" id="MBR" name="MBR" placeholder="" />
                </div>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <input className={Styles.invoiceInpu} type="checkbox" id="agree" name="agree" />
                  <label htmlFor="agree" className={Styles.customLabel}>
                    Mail chq to Agent
                  </label>
                </div>
              </div>
            </td>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="location" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    LOCATION
                  </label>
                  <input
                    className={Styles.invoiceInput}
                    type="text"
                    id="location"
                    name="location"
                    placeholder={item?.location || item?.jobId?.location.name || ''}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="mbr" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    ADDRESS
                  </label>
                  <input
                    className={`w-100 ${Styles.invoiceInput}`}
                    type="number"
                    id="MBR"
                    name="MBR"
                    placeholder={item?.address || item.performer.address}
                  />
                </div>
              </div>
            </td>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="travel" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    TRAVEL TIME:
                  </label>
                  <input
                    className={Styles.invoiceInput}
                    type="text"
                    id="travel"
                    name="travel"
                    placeholder={item?.mileage}
                  />
                </div>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="km" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    KM:
                  </label>
                  <input
                    className={Styles.invoiceInput}
                    type="text"
                    id="km"
                    name="km"
                    placeholder={item?.mileageType}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }} />
            </td>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <div className="check-ubc" style={{ paddingRight: '5px' }}>
                  <input className={Styles.invoiceInpu} type="checkbox" defaultChecked={NDB} id="agree" name="agree" />
                  <label htmlFor="agree" className={Styles.customLabel}>
                    NON DEDUCTABLE MAEL AT (TIME)
                  </label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="postal" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    POSTAL CODE:
                  </label>
                  <input
                    className={Styles.invoiceInput}
                    placeholder={item.zipCode || item.performer.postCode}
                    type="text"
                    id="postal"
                    name="postal"
                  />
                </div>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="mobile" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    TEL#:
                  </label>
                  <input
                    className={Styles.invoiceInput}
                    type="number"
                    id="mobile"
                    name="mobile"
                    placeholder={item?.performer?.phoneNo}
                  />
                </div>
              </div>
            </td>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="meal" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    1ST MEAL
                  </label>
                  {/* <label htmlFor="meal">From</label> */}
                  <input
                    className={Styles.invoiceInput}
                    type="text"
                    id="meal"
                    name="meal"
                    placeholder={item?.lunch || ''}
                  />
                </div>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="mobile" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    to:
                  </label>
                  <input className={Styles.invoiceInput} type="number" id="mobile" name="mobile" placeholder={lunch} />
                  <input className={Styles.invoiceInpu} type="checkbox" id="agree" name="agree" />
                  <label htmlFor="agree" className={Styles.customLabel}>
                    Penalty
                  </label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="sin" className={`${Styles.customLabel} ${Styles.labelWidth} ${Styles.labelSin}`}>
                    S.I.N.#:
                  </label>
                  <input className={Styles.invoiceInput} placeholder={item.sin} type="text" id="sin" name="sin" />
                </div>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="dob" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    IF CHILD D.O.B
                  </label>
                  <input className={Styles.invoiceInput} type="text" id="dob" name="dob" placeholder="" />
                </div>
              </div>
            </td>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="meal" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    2nd MEAL
                  </label>
                  {/* <label for="postal">From</label> */}
                  <input className={Styles.invoiceInput} type="text" id="meal" name="meal" placeholder="" />
                </div>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="mobile" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    to:
                  </label>
                  <input className={Styles.invoiceInput} type="number" id="mobile" name="mobile" placeholder="" />
                  <input type="checkbox" checked id="agree" name="agree" />
                  <label htmlFor="agree" className={Styles.customLabel}>
                    Penalty
                  </label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="gst" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    G.S.T.# :
                  </label>
                  <input className={Styles.invoiceInput} placeholder={item.gst} type="text" id="gst" name="gst" />
                </div>
              </div>
            </td>
            {/* <td className="tabl-data-line"> */}
            {/* <div className="form-group-wrapper" style={{display: 'flex'}} /> */}
            {/* </td> */}
          </tr>
          <tr>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="productionName" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    PRODUCTION NAME :
                  </label>
                  <input
                    className={Styles.invoiceInput}
                    type="text"
                    id="productionName"
                    name="productionName"
                    placeholder={item?.showId?.title}
                  />
                </div>
              </div>
            </td>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }} />
            </td>
          </tr>
          <tr>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label
                    htmlFor="producer"
                    className={`${Styles.customLabel} ${Styles.labelWidth} 
                         ${Styles.labelProducer}`}
                  >
                    PRODUCER:
                  </label>
                  <input
                    className={`text-capitalize ${Styles.invoiceInput}`}
                    type="text"
                    id="producer"
                    name="producer"
                    placeholder={`${producerName || ''}`}
                  />
                </div>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="mobile" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    TEL#:
                  </label>
                  <input
                    className={Styles.invoiceInput}
                    type="number"
                    id="mobile"
                    name="mobile"
                    placeholder={item?.showId.productionPhone}
                  />
                </div>
              </div>
            </td>
            <td className={Styles.tableDataline}>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="wrap time" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    WRAP TIME:
                  </label>
                  <input
                    className={Styles.invoiceInput}
                    type="text"
                    id="wrap time"
                    name="wrap time"
                    placeholder={item?.wrapTime}
                  />
                </div>
                <div className={`align-items-center ${Styles.memberNo}`} style={{ display: 'flex' }}>
                  <label htmlFor="gst" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                    PERFORMER&apos;S INITIALS:
                  </label>
                  <input
                    className={`text-uppercase ${Styles.invoiceInput}`}
                    type="text"
                    id="gst"
                    name="gst"
                    placeholder={inits}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="">
              <div className="form-group-wrapper">
                <input
                  className={`text-uppercase w-100 text-center 
                  ${Styles.performerSignature} ${Styles.invoiceInput}`}
                  type="text"
                  id="gst"
                  name="gst"
                  placeholder={`${producerName || ''}`}
                />
                <p
                  className={`${Styles.paragraph} ${Styles.tableDatasing}`}
                  style={{ textAlign: 'center', position: 'relative' }}
                >
                  PRODUCER REPRESENTATIVE SIGNATURE
                </p>
              </div>
            </td>
            <td className="">
              <div
                className={`align-items-center ${Styles.memberNo} ${Styles.tableDataline}`}
                style={{ display: 'flex', position: 'relative', padding: '10px' }}
              >
                <label htmlFor="RATE" className={`${Styles.customLabel} ${Styles.labelWidth}`}>
                  RATE :
                </label>
                <input
                  type="number"
                  id="RATE"
                  name="RATE"
                  placeholder={` $${totalAmount}`}
                  style={{ fontSize: '16px', color: '#000' }}
                  className={Styles.invoiceInput}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className="">
              <div className="form-group-wrapper">
                <div className="form-group-wrapper">
                  <input
                    className={`text-uppercase w-100 text-center 
                    ${Styles.performerSignature} ${Styles.invoiceInput}`}
                    type="text"
                    id="gst"
                    name="gst"
                    placeholder={item?.name || item.performerName}
                  />
                  <p
                    className={`${Styles.tableDatasing} ${Styles.paragraph}`}
                    style={{ textAlign: 'center', position: 'relative' }}
                  >
                    PERFORMERS SIGNATURE
                  </p>
                </div>
              </div>
            </td>
            <td className={Styles.tableDataline}>
              <p className={Styles.paragraph}>CATEGORY: (Check All Categories Work)</p>
              <div className="form-group-wrapper" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="colum">
                  <input
                    type="checkbox"
                    id="agree"
                    name="agree"
                    defaultChecked={
                      item?.jobId?.rate?.values?.isAddOns &&
                      item?.jobId?.rate?.values?.addOns === 'General Background Performer (GBP)'
                    }
                  />
                  <label htmlFor="agree" className={Styles.customLabel}>
                    GENERAL .B.P.
                  </label>
                </div>
                <div className="colum">
                  <input
                    type="checkbox"
                    id="agree"
                    name="agree"
                    defaultChecked={
                      item?.jobId?.rate?.values?.isAddOns && item?.jobId.rate.values.addOns === 'Stand In (SI)'
                    }
                  />
                  <label htmlFor="agree" className={Styles.customLabel}>
                    STAND IN:
                  </label>
                </div>
                <div className="colum">
                  <input
                    type="checkbox"
                    id="agree"
                    name="agree"
                    defaultChecked={
                      item?.jobId.rate.values.isAddOns && item?.jobId.rate.values.addOns === 'Photo Double (PDBL)'
                    }
                  />
                  <label htmlFor="agree" className={Styles.customLabel}>
                    PHOTO DOUBLE
                  </label>
                </div>
              </div>
              <div className="form-group-wrapper" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="colum">
                  <input
                    type="checkbox"
                    id="agree"
                    name="agree"
                    defaultChecked={
                      item?.jobId.rate.values.isAddOns &&
                      item?.jobId.rate.values.addOns === 'Special Ability Background Performer (SABP)'
                    }
                  />
                  <label htmlFor="agree" className={Styles.customLabel}>
                    SPECIAL ABILITY
                  </label>
                </div>
                <div className="colum">
                  <input type="checkbox" id="agree" name="agree" />
                  <label htmlFor="agree" className={Styles.customLabel}>
                    WARDROBE CALL
                  </label>
                </div>
                <div className="colum">
                  <input type="checkbox" id="agree" name="agree" />
                  <label htmlFor="agree" className={Styles.customLabel}>
                    PHOTO SESSION
                  </label>
                </div>
              </div>
              <div className="form-group-wrapper" style={{ display: 'flex' }}>
                <input type="checkbox" id="agree" name="agree" />
                <label htmlFor="agree" className={Styles.customLabel}>
                  OTHER:
                </label>
              </div>
            </td>
          </tr>
        </table>
        <div
          className={Styles.bottomcontainer}
          style={{ display: 'flex', width: '100%', justifyContent: 'space-around', marginBottom: '10px' }}
        >
          <div className="col">
            <p className={Styles.paragraph}>
              Base rate:
              {` $${item?.voucherRate || 0.0}`}
            </p>
            <p className={Styles.paragraph}>
              Overtime 1.5x hours:&nbsp;
              {item?.extraTimeRates?.oneHalfRate?.totalHrs}
            </p>
            <p className={Styles.paragraph}>
              Overtime 1.5x pay:&nbsp;
              {item?.extraTimeRates?.oneHalfRate?.totalRate}
            </p>
            <br />
            <p className={Styles.paragraph}>
              Overtime 2.5x hours:&nbsp;
              {item?.extraTimeRates?.twoHalfRate?.totalHrs}
            </p>
            <p className={Styles.paragraph}>
              Overtime 2.5x pay:
              {item?.extraTimeRates?.twoHalfRate?.totalRate}
            </p>
          </div>
          <div className="col">
            <p className={Styles.paragraph}>
              Overtime 2.0x hours:&nbsp;
              {item?.extraTimeRates?.twoXRate?.totalHrs}
            </p>
            <p className={Styles.paragraph}>
              Overtime 2.0x pay:
              {item?.extraTimeRates?.twoXRate?.totalRate}
            </p>
            <br />
            <br />
            <p className={Styles.paragraph}>
              Overtime 3x hours:&nbsp;
              {item?.extraTimeRates?.threeHalfRate?.totalHrs}
            </p>
            <p className={Styles.paragraph}>
              Overtime 3x pay:
              {item?.extraTimeRates?.threeHalfRate?.totalRate}
            </p>
            <p className={Styles.paragraph} />
          </div>

          <div className="col">
            <p className={Styles.paragraph}>MPV1: </p>
            <p className={Styles.paragraph}>MPV2: </p>
            <p className={Styles.paragraph} />
          </div>
          <div className="col">
            <p className={Styles.paragraph}>
              Travel (Distance):
              {` $${item?.mileageType || 0.0}`}
            </p>
            <p className={Styles.paragraph} />
            <p className={Styles.paragraph} />
          </div>
        </div>
      </form>
      <div className="px-3">
        <p>
          This employment is subject to all the terms of the Collective Bargaining Agreement between the Union of B.C.
          Performers and the Producers.
        </p>
        <p>
          Ihereby give and grant to the Company named above hereof all rights of every kind and character whatsoever in
          and to all work heretofore done, and all poses, acts, plays, and appearances heretofore made by me for you and
          in all and to all of the results and proceeds of my services heretofore rendered for you, as well in and to
          the right to use my name, likeness, and photographs, whether still or moving for commercial purposes. I
          further give and grant the said company the right to reproduce in any manner whatsoever any recordings
          heretofore made by said company of my voice and all instrumental, musical, or other sound effects produced by
          me. I further agree that in the event of a retake of any of all or any of the scenes in which I participate,
          of if any additional scenes are required (whether originally contemplated or not) I will return to work and
          render my services in such scenes at the same basic rate of compensation as that paid me for the original
          taking.
        </p>
        <p>
          I further authorize the producer to make such deductions as are required pursuant to the Agreement and remit
          such deductions with the appropriate employer contributions to the Union for disposition, in accordance with
          the Collective Agreement or the Constitution and By-laws of the Union.
        </p>
      </div>
    </>
  );

  const getNonUnionData = () => (
    <div className={Styles.performerWrapper}>
      <div className={`d-flex align-items-center text-center justify-content-between ${Styles.invoiceTitle}`}>
        <div>
          <p className={`m-0 ${Styles.logoRec}`}>
            <span>Casting</span>
            Pax
          </p>
        </div>
        <h2 className={Styles.headingTwo}>&nbsp; wages pending accounting approval</h2>
      </div>
      <div className={`d-flex justify-content-center align-items-center ${Styles.nonUnionBox}`}>
        Non-Union Background Performer Voucher
        <br />
        Download and save copies of your Digital Voucher.
      </div>
      <div className={Styles.mainBoxWrapper}>
        <div className={`d-flex ${Styles.singleBox}`}>
          <div className={`${Styles.singleFirstBox}`}>
            <div>
              Performer Name
              <p>{item?.name || item.performerName}</p>
            </div>
          </div>
          <div className={`${Styles.singleSecondBox}`}>
            Date
            <p>{moment(item?.date).format('DD MMM YYYY')}</p>
          </div>
        </div>
        <div className={`d-flex ${Styles.singleBox}`}>
          <div className={`${Styles.singleFirstBox}`}>
            <div>
              Address
              <p>{item?.address}</p>
            </div>
          </div>
          <div className={`${Styles.singleSecondBox}`}>
            Call Time
            <p>{item?.callTime}</p>
          </div>
        </div>
        <div className={`d-flex ${Styles.singleBox}`}>
          <div className={`${Styles.singleFirstBox}`}>
            <div>
              Phone
              <p>{item?.jobId.showId.productionPhone}</p>
            </div>
          </div>
          <div className={`${Styles.singleSecondBox}`}>
            Wrap Time
            <p>{item?.wrapTime}</p>
          </div>
        </div>
        <div className={`d-flex ${Styles.singleBox}`}>
          <div className={`${Styles.singleFirstBox}`}>
            <div>
              Production Title
              <p>{item?.showId?.title}</p>
            </div>
          </div>
          <div className={`${Styles.singleSecondBox}`}>
            Rate/ Hour &nbsp;
            <p>{`$${item?.voucherRate || 0.0}`}</p>
          </div>
        </div>
        <div className={`d-flex ${Styles.singleBox}`}>
          <div className={`${Styles.singleFirstBox}`}>
            <div>
              Episode Title and Number
              <p>details</p>
            </div>
          </div>
          <div className={`${Styles.singleSecondBox}`}>
            Union Deductions
            <p>details</p>
          </div>
        </div>
        <div className={`d-flex ${Styles.singleBox}`}>
          <div className={`${Styles.singleFirstBox}`}>
            <div>
              Production Company
              <p>details</p>
            </div>
          </div>
          <div className={`${Styles.singleSecondBox}`}>
            Total Amount Due
            <p>{`$${totalAmount}`}</p>
          </div>
        </div>
        <div className={`d-flex ${Styles.singleBox}`}>
          <div className={`${Styles.singleFirstBox}`}>
            <div>
              Producer’s Representative
              <p className={`text-center ${Styles.performerSignature}`}>{`${producerName || ''}`}</p>
            </div>
            <div className={`${Styles.singleThirdBox}`}>
              Comments
              <p>Details</p>
            </div>
          </div>
          <div className={`${Styles.singleSecondBox}`}>
            UBCP Apprentice or BEM Membership (#)
            <p>details</p>
          </div>
        </div>
        <div className={`${Styles.pageBreakAfter}`} />
        <div className={Styles.pageSpace} />
        <div className={`d-flex ${Styles.singleBox}`}>
          <div className={`${Styles.singleFirstBox}`}>
            <div>
              Performer’s Signature
              <p className={`text-center ${Styles.performerSignature}`}>{item?.name || item.performerName}</p>
            </div>
          </div>
          <div className={`${Styles.singleSecondBox}`}>
            S.I.N.#
            <p>{item.sin}</p>
          </div>
        </div>
        <div className={`d-flex ${Styles.singleBox}`}>
          <div className={`${Styles.singleFirstBox}`}>
            <div>
              Agent’s ( If applicable)
              <p>details</p>
            </div>
          </div>
          <div className={`${Styles.singleSecondBox}`}>
            GST/ HST (#)
            <p>{item.gst}</p>
          </div>
        </div>
        <div className={`d-flex w-100 ${Styles.singleBox}`}>
          <div className={`${Styles.singleFirstBox}`} style={{ flex: '0 0 100%' }}>
            <div>
              Additional Comments:
              <p>details</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${Styles.bottomcontainer} ${Styles.performerBgInner}`}
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
          marginBottom: '50px',
          marginTop: '50px',
        }}
      >
        <div className="col">
          <p className={Styles.paragraph}>
            Overtime 1.5x hours:&nbsp;
            {item?.extraTimeRates?.oneHalfRate?.totalHrs}
          </p>
          <p className={Styles.paragraph}>
            Overtime 1.5x pay:&nbsp;
            {item?.extraTimeRates?.oneHalfRate?.totalRate}
          </p>
          <br />
          <p className={Styles.paragraph}>
            Overtime 2.5x hours:&nbsp;
            {item?.extraTimeRates?.twoHalfRate?.totalHrs}
          </p>
          <p className={Styles.paragraph}>
            Overtime 2.5x pay:
            {item?.extraTimeRates?.twoHalfRate?.totalRate}
          </p>
        </div>
        <div className="col">
          <p className={Styles.paragraph}>
            Overtime 2.0x hours:&nbsp;
            {item?.extraTimeRates?.twoXRate?.totalHrs}
          </p>
          <p className={Styles.paragraph}>
            Overtime 2.0x pay:
            {item?.extraTimeRates?.twoXRate?.totalRate}
          </p>
          <br />

          <p className={Styles.paragraph}>
            Overtime 3x hours:&nbsp;
            {item?.extraTimeRates?.threeHalfRate?.totalHrs}
          </p>
          <p className={Styles.paragraph}>
            Overtime 3x pay:
            {item?.extraTimeRates?.threeHalfRate?.totalRate}
          </p>
          <p className={Styles.paragraph} />
        </div>
        <div className="col">
          <p className={Styles.paragraph}>Other (If there is any other fees): &nbsp;</p>
          <p className={Styles.paragraph}>
            Base rate: &nbsp;
            {` $${item?.voucherRate || 0.0}`}
          </p>
        </div>
      </div>
      <div className="">
        <p>
          This employment is subject to all the terms of the Collective Bargaining Agreement between the Union of Union
          of B.C. Performers and the Producers.
        </p>
        <p>
          Ihereby give and grant to the Company named above hereof all rights of every kind and character whatsoever in
          and to all work heretofore done, and all poses, acts, plays, and appearances heretofore made by me for you and
          in all and to all of the results and proceeds of my services heretofore rendered for you, as well as in and to
          the right to use my name, likeness, and photographs, whether still or moving for commercial purposes. I
          further give and grant the said company the right to reproduce in any manner whatsoever any recordings
          heretofore made by said company of my voice and all instrumental, musical, or other sound effects produced by
          me. I further agree that in the event of a retake of any of all or any of the scenes in which I participate,
          of if any additional scenes are required (whether originally contemplated or not) I will return to work and
          render my services in such scenes at the same basic rate of compensation as that paid me for the original
          taking.
        </p>
        <p>
          I further authorize the producer to make such deductions as are required pursuant to the Collecting Agreement
          and remit such deductions with the appropriate employer contributions to the Union for disposition, in
          accordance with the Collective Agreement or the Constitution and By-laws of the Union.
        </p>
      </div>
    </div>
  );

  const getApprenticeData = () => (
    <div className={Styles.bgVoucherWrapper}>
      <div className="d-flex align-items-center">
        <img src={actraLogo} alt="actra logo" style={{ maxWidth: '100px' }} />
        <div className={`d-flex align-items-center text-center justify-content-between ${Styles.invoiceTitle}`}>
          <div>
            <p className={`m-0 ${Styles.logoRec}`}>
              <span>Casting</span>
              Pax
            </p>
          </div>
          <h2 className={Styles.headingTwo}>&nbsp; wages pending accounting approval</h2>
        </div>
      </div>
      <h2 className={`text-center ${Styles.mainHeading}`}>BACKGROUND VOUCHER</h2>
      <span className="mb-0">Select One</span>
      <div className="d-flex w-100 flex-wrap">
        <div className={`${Styles.voucherFirstCol} ${Styles.voucherCheckBoxCol}`}>
          <div className="d-flex w-100">
            <div
              className="w-50 d-flex flex-wrap"
              style={{ paddingTop: '6px', paddingBottom: '6px', paddingLeft: '6px', paddingRight: '6px' }}
            >
              <div className="colum">
                <input type="checkbox" id="full-member" name="full-member" />
                <label htmlFor="full-member" className={Styles.customLabel}>
                  Full Member:
                </label>
              </div>
              <div className="colum">
                <input type="checkbox" id="aabp" name="aabp" />
                <label htmlFor="aabp" className={Styles.customLabel}>
                  AABP
                </label>
              </div>
              <div className="colum">
                <input type="checkbox" id="performer" name="performer" />
                <label htmlFor="performer" className={Styles.customLabel}>
                  Performer #
                </label>
              </div>
              <div className="colum">
                <input type="checkbox" id="Apprentice" name="Apprentice" />
                <label htmlFor="Apprentice" className={Styles.customLabel}>
                  Apprentice
                </label>
              </div>
              <div className="colum">
                <input type="checkbox" id="Permitee" name="Permitee" />
                <label htmlFor="Permitee" className={Styles.customLabel}>
                  Permitee
                </label>
              </div>
            </div>
            <div
              style={{
                borderLeft: '1px solid #E0E0E0',
                paddingLeft: '10px',
                paddingTop: '6px',
                paddingBottom: '6px',
              }}
            >
              <label className={Styles.voucherLabel}>Performer #</label>
              <p className="mb-0">{item?.unionNo}</p>
            </div>
          </div>
          <div className={Styles.secondDivBorder}>
            <label className={Styles.voucherLabel}>Performer Name</label>
            <p className="mb-0">{item?.name || item.performerName}</p>
          </div>
        </div>
        <div className={Styles.voucherSecondCol}>
          <label className={Styles.voucherLabel}>Date</label>
          <p>{moment(item?.date).format('DD MMM YYYY')}</p>
        </div>
        <div className={Styles.voucherThirdCol}>
          <div className={Styles.thirdColPadd}>
            <label className={Styles.voucherLabel}>Category</label>
            <p className="mb-0" />
          </div>
          <div className={Styles.secondDivBorder}>
            <label className={Styles.voucherLabel}>Upgrade</label>
            <p className={`mb-0 ${Styles.voucherDetailText}`} />
          </div>
        </div>

        <div className={`${Styles.voucherFirstCol}`}>
          <div className={`${Styles.secondDivBorder} h-100`}>
            <label className={Styles.voucherLabel}>Address</label>
            <p className="mb-0">{item.address}</p>
          </div>
          {/* <div className={Styles.secondDivBorder}> */}
          {/*  <label className={Styles.voucherLabel} /> */}
          {/*  <p className="mb-0" /> */}
          {/* </div> */}
        </div>
        <div className={Styles.voucherSecondCol}>
          <div className="d-flex">
            <div className="w-50">
              <label className={Styles.voucherLabel}>Call Time</label>
              <p className="mb-0">{item?.callTime}</p>
            </div>
            <div className="w-50">
              <label className={Styles.voucherLabel}>Crew Call</label>
              <p className="mb-0">{item?.workingDayId?.crewCall}</p>
            </div>
          </div>
        </div>
        <div className={Styles.voucherThirdCol}>
          <div className={Styles.thirdColPadd}>
            <label className={Styles.voucherLabel}>Nudity</label>
            <p className="mb-0" />
          </div>
          <div className={Styles.secondDivBorder}>
            <label className={Styles.voucherLabel}>Prop Shot</label>
            <p className={`mb-0 ${Styles.voucherDetailText}`} />
          </div>
        </div>

        <div className={`${Styles.voucherFirstCol}`}>
          <div className={`${Styles.secondDivBorder} h-100`}>
            <label className={Styles.voucherLabel}>Phone</label>
            <p className="mb-0">{item?.jobId.showId.productionPhone}</p>
          </div>
        </div>
        <div className={Styles.voucherSecondCol}>
          <label className={Styles.voucherLabel}>Travel To</label>
          <div className="d-flex">
            <div className="w-50">
              <label className={Styles.voucherLabel}>Start</label>
              <p className="mb-0" />
            </div>
            <div className="w-50">
              <label className={Styles.voucherLabel}>Finish</label>
              <p className="mb-0" />
            </div>
          </div>
        </div>
        <div className={Styles.voucherThirdCol}>
          <div className={Styles.thirdColPadd}>
            <label className={Styles.voucherLabel}>For Engager’s Use</label>
            <p className="mb-0" />
          </div>
          <div className={`${Styles.secondDivBorder}`} style={{ borderTop: '0' }}>
            <label className={Styles.voucherLabel}>8 hrs</label>
            <p className={`mb-0 ${Styles.voucherDetailText}`} />
          </div>
        </div>

        <div className={`${Styles.voucherFirstCol}`}>
          <div className={Styles.secondDivBorder}>
            <label className={Styles.voucherLabel}>S.I.N.#</label>
            <p>{item.sin}</p>
          </div>
          <div className={Styles.secondDivBorder}>
            <label className={Styles.voucherLabel}>Email</label>
            <p className="mb-0">{item.performer.email}</p>
          </div>
        </div>
        <div className={Styles.voucherSecondCol}>
          <label className={Styles.voucherLabel}>Non-Deductible First meal</label>
          <div className="d-flex">
            <div className="w-50">
              <label className={Styles.voucherLabel}>Start</label>
              <p className="mb-0" />
            </div>
            <div className="w-50">
              <label className={Styles.voucherLabel}>Finish</label>
              <p className="mb-0" />
            </div>
          </div>
        </div>
        <div className={Styles.voucherThirdCol}>
          <div className={Styles.thirdColPadd}>
            <label className={Styles.voucherLabel}>.25 MU</label>
            <p className="mb-0" />
          </div>
          <div className={Styles.secondDivBorder}>
            <label className={Styles.voucherLabel}>(OT 1.5)</label>
            <p className={`mb-0 ${Styles.voucherDetailText}`} />
          </div>
        </div>

        <div className={`${Styles.voucherFirstCol}`}>
          <div className={`d-flex w-100 ${Styles.secondDivBorder}`}>
            <div className="w-50" style={{ paddingTop: '6px', paddingBottom: '6px' }}>
              <label className={Styles.voucherLabel}>GST #</label>
              <p>{item.gst}</p>
            </div>
            <div
              style={{
                borderLeft: '1px solid #E0E0E0',
                paddingLeft: '10px',
                paddingTop: '6px',
                paddingBottom: '6px',
              }}
            >
              <label className={Styles.voucherLabel}>QST/ HST #</label>
              <p className="mb-0" />
            </div>
          </div>
          <div className={Styles.secondDivBorder}>
            <label className={Styles.voucherLabel}>Minor’s only - Date of Birth</label>
            <p className="mb-0" />
          </div>
        </div>
        <div className={Styles.voucherSecondCol}>
          <label className={Styles.voucherLabel}>Make-up/ Wardrobe call</label>
          <div className="d-flex">
            <div className="w-50">
              <label className={Styles.voucherLabel}>Start</label>
              <p className="mb-0" />
            </div>
            <div className="w-50">
              <label className={Styles.voucherLabel}>Finish</label>
              <p className="mb-0" />
            </div>
          </div>
        </div>
        <div className={Styles.voucherThirdCol}>
          <div className={Styles.thirdColPadd}>
            <label className={Styles.voucherLabel}>(OT 2 )</label>
            <p className="mb-0" />
          </div>
        </div>

        <div className={`${Styles.voucherFirstCol}`}>
          <div className={`${Styles.secondDivBorder}`}>
            <label className={Styles.voucherLabel}>Production Title</label>
            <p className="mb-0">{item?.showId.title}</p>
          </div>
          <div className={`${Styles.secondDivBorder}`}>
            <label className={Styles.voucherLabel} />
            <p className="mb-0" />
          </div>
        </div>
        <div className={Styles.voucherSecondCol}>
          <label className={Styles.voucherLabel}>Set Call</label>
          <p className="mb-0" />
        </div>
        <div className={Styles.voucherThirdCol}>
          <div className={Styles.thirdColPadd}>
            <label className={Styles.voucherLabel}>MP 1</label>
            <p className="mb-0" />
          </div>
        </div>

        <div className={`${Styles.voucherFirstCol}`}>
          <div className={`${Styles.secondDivBorder}`}>
            <label className={Styles.voucherLabel}>Episode Title and Number</label>
            <p className="mb-0" />
          </div>
        </div>
        <div className={Styles.voucherSecondCol}>
          <label className={Styles.voucherLabel}>Substantial snack provided</label>
          <div className="d-flex">
            <div className="w-50">
              <div className="colum">
                <input type="checkbox" id="yess" name="yess" />
                <label htmlFor="yess" className={Styles.customLabel}>
                  Full Member:
                </label>
              </div>
            </div>
            <div className="w-50">
              <div className="colum">
                <input type="checkbox" id="noo" name="noo" />
                <label htmlFor="noo" className={Styles.customLabel}>
                  AABP
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.voucherThirdCol}>
          <div className={Styles.thirdColPadd}>
            <label className={Styles.voucherLabel}>MP 2</label>
            <p className="mb-0" />
          </div>
        </div>

        <div className={`${Styles.voucherFirstCol}`}>
          <div className={`${Styles.secondDivBorder}`}>
            <label className={Styles.voucherLabel}>Production Company</label>
            <p className="mb-0" />
          </div>
        </div>
        <div className={Styles.voucherSecondCol}>
          <div className="d-flex">
            <div className="w-50">
              <div>
                <label className={Styles.voucherLabel}>1st meal</label>
              </div>
            </div>
            <div className="colum">
              <label htmlFor="Penalty" className={Styles.customLabel}>
                Penalty
              </label>
              <input type="checkbox" id="Penalty" name="Penalty" />
            </div>
          </div>
          <div>
            <div className="d-flex w-100">
              <div className="w-50">
                <label className={Styles.voucherLabel}>Start</label>
                <p className="mb-0">{item?.lunch}</p>
              </div>
              <div className="w-50">
                <label className={Styles.voucherLabel}>Finish</label>
                <p className="mb-0">{lunch}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.voucherThirdCol}>
          <div className={`${Styles.secondDivBorder}`}>
            <label className={Styles.voucherLabel}>Travel</label>
            <p className="mb-0">{item?.mileage}</p>
          </div>
          <div className={`${Styles.secondDivBorder}`}>
            <label className={Styles.voucherLabel}>Milage</label>
            <p className="mb-0">{item?.mileageType}</p>
          </div>
        </div>

        <div className={`${Styles.voucherFirstCol}`}>
          <div style={{ padding: '6px' }}>
            <label className={Styles.voucherLabel}>Performer’s Signature</label>
            <p className={`mb-0 text-center ${Styles.performerSignature}`}>{item?.name || item.performerName}</p>
          </div>
        </div>
        <div className={Styles.voucherSecondCol}>
          <div className="d-flex">
            <div className="w-50">
              <div>
                <label className={Styles.voucherLabel}>2nd meal</label>
                <p className="mb-0" />
              </div>
            </div>
            <div className="colum">
              <label htmlFor="Penalty" className={Styles.customLabel}>
                Penalty
              </label>
              <input type="checkbox" id="Penalty" name="Penalty" />
            </div>
          </div>
          <div>
            <div className="d-flex w-100">
              <div className="w-50">
                <label className={Styles.voucherLabel}>Start</label>
                <p className="mb-0" />
              </div>
              <div className="w-50">
                <label className={Styles.voucherLabel}>Finish</label>
                <p className="mb-0" />
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.voucherThirdCol}>
          <div className={`${Styles.secondDivBorder}`}>
            <label className={Styles.voucherLabel}>Turnaround</label>
            <p className="mb-0" />
          </div>
          <div className={`${Styles.secondDivBorder}`}>
            <label className={Styles.voucherLabel}>Wardrobe Fitting **</label>
            <p className="mb-0" />
          </div>
        </div>
        <div className={`${Styles.pageBreakAfter}`} />
        <div className={Styles.pageSpace} />
        <div className={`${Styles.voucherFirstCol}`}>
          <div style={{ padding: '6px' }}>
            <label className={Styles.voucherLabel}>Producer’s Representative</label>
            <p className={`text-center ${Styles.performerSignature}`}>{`${producerName || ''}`}</p>
          </div>
        </div>
        <div className={Styles.voucherSecondCol}>
          <label className={Styles.voucherLabel}>Wrap Time</label>
          <p className="mb-0">{item?.wrapTime}</p>
        </div>
        <div className={Styles.voucherThirdCol}>
          <div className={`${Styles.secondDivBorder}`}>
            <label className={Styles.voucherLabel}>Misc</label>
            <p className="mb-0" />
          </div>
          <div className={`${Styles.secondDivBorder}`}>
            <label className={Styles.voucherLabel}>Gross</label>
            <p className="mb-0" />
          </div>
        </div>

        <div className={`${Styles.voucherFirstCol}`}>
          <div className="d-flex w-100" style={{ padding: '6px' }}>
            <div className="w-50">
              <div className="colum">
                <label htmlFor="yess" className={Styles.customLabel}>
                  Agree:
                </label>
                <input type="checkbox" id="yess" name="yess" />
              </div>
            </div>
            <div className="w-50">
              <div className="colum">
                <label htmlFor="yess" className={Styles.customLabel}>
                  Disagree:
                </label>
                <input type="checkbox" id="yess" name="yess" />
              </div>
            </div>
          </div>
          <div className={`${Styles.secondDivBorder}`} style={{ borderBottom: '0' }}>
            <label className={Styles.voucherLabel}>Comments</label>
            <p className="mb-0" />
          </div>
        </div>
        <div className={Styles.voucherSecondCol}>
          <label className={Styles.voucherLabel}>Travel from</label>
          <div className="d-flex w-100">
            <div className="w-50">
              <label className={Styles.voucherLabel}>Start</label>
              <p className="mb-0" />
            </div>
            <div className="w-50">
              <label className={Styles.voucherLabel}>Finish</label>
              <p className="mb-0" />
            </div>
          </div>
        </div>
        <div className={Styles.voucherThirdCol}>
          <div className={`${Styles.secondDivBorder}`}>
            <label className={Styles.voucherLabel}>Full Members Only</label>
            <p className="mb-0" />
          </div>
          <div className={`${Styles.secondDivBorder}`}>
            <label className={Styles.voucherLabel}>Dues ded. 2.25%</label>
            <p className="mb-0" />
          </div>
        </div>

        <div className={`${Styles.voucherFirstCol}`}>
          <div style={{ borderBottom: '0' }}>
            <label className={Styles.voucherLabel} />
            <p className="mb-0" />
          </div>
        </div>
        <div className={Styles.voucherSecondCol}>
          <div className="d-flex w-100">
            <div className="w-50">
              <label className={Styles.voucherLabel}>Milage</label>
              <p className="mb-0" />
            </div>
            <div className="w-50">
              <input
                placeholder={item?.mileageType}
                className={`${Styles.invoiceInput} w-50`}
                type="text"
                id="kms"
                name="kms"
              />
              <label className={Styles.voucherLabel}>Kms</label>
            </div>
          </div>
        </div>
        <div className={Styles.voucherThirdCol}>
          <div className={`${Styles.secondDivBorder}`}>
            <label className={Styles.voucherLabel}>Less I & R</label>
            <p className="mb-0" />
          </div>
        </div>

        <div className={`${Styles.voucherFirstCol}`}>
          <div style={{ borderBottom: '0' }}>
            <label className={Styles.voucherLabel} />
            <p className="mb-0" />
          </div>
        </div>
        <div className={Styles.voucherSecondCol}>
          <div className="d-flex w-100">
            <div className="w-50">
              <label className={Styles.voucherLabel}>Turnaround</label>
              <p className="mb-0" />
            </div>
            <div className="w-50">
              <label htmlFor="penalty--1" className={Styles.customLabel}>
                Penalty
              </label>
              <input className={Styles.invoiceInpu} type="checkbox" id="penalty--1" name="penalty--1" />
            </div>
          </div>
        </div>
        <div className={Styles.voucherThirdCol}>
          <div className={`${Styles.secondDivBorder}`}>
            <label className={Styles.voucherLabel}>Other Performer’s and Permitees</label>
            <p className="mb-0" />
          </div>
        </div>

        <div className={`${Styles.voucherFirstCol}`}>
          <div style={{ borderBottom: '0' }}>
            <label className={Styles.voucherLabel} />
            <p className="mb-0" />
          </div>
        </div>
        <div className={Styles.voucherSecondCol}>
          <label className={Styles.voucherLabel} />
          <p className="mb-0" />
        </div>
        <div className={Styles.voucherThirdCol}>
          <div className={`${Styles.secondDivBorder}`}>
            <label className={Styles.voucherLabel}>Less Equalisation Deduction</label>
            <p className="mb-0" />
          </div>
        </div>

        <div className={`${Styles.voucherFirstCol} ${Styles.voucherCheckBoxCol}`}>
          <div style={{ minHeight: '50%' }} className="d-flex w-100">
            <div
              className="w-50"
              style={{
                paddingTop: '6px',
                paddingBottom: '6px',
                paddingLeft: '6px',
                paddingRight: '6px',
              }}
            >
              <div style={{ borderBottom: '0' }}>
                <label className={Styles.voucherLabel}>Speciality Wardrobe # of Changes</label>
                <p className="mb-0" />
              </div>
            </div>
            <div
              style={{
                borderLeft: '1px solid #E0E0E0',
                paddingLeft: '10px',
                paddingTop: '6px',
                paddingBottom: '6px',
              }}
            >
              <div>
                <label className={Styles.voucherLabel}>Regular Wardrobe # of Changes</label>
                <p className="mb-0" />
              </div>
            </div>
          </div>
          <div style={{ minHeight: '50%', borderTop: '1px solid #E0E0E0' }} className="d-flex w-100">
            <div
              className="w-50"
              style={{ paddingTop: '6px', paddingBottom: '6px', paddingLeft: '6px', paddingRight: '6px' }}
            >
              <div>
                <label className={Styles.voucherLabel}>Speciality Wardrobe # of Changes</label>
                <p className="mb-0" />
              </div>
            </div>
            <div
              style={{
                borderLeft: '1px solid #E0E0E0',
                minHeight: '60px',
              }}
            >
              <div style={{ paddingTop: '6px', paddingBottom: '6px', paddingLeft: '6px', paddingRight: '6px' }}>
                <label className={Styles.voucherLabel}>Regular Wardrobe # of Changes</label>
                <p className="mb-0" />
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.voucherSecondCol}>
          <label className={Styles.voucherLabel}>Date of Wardrobe fitting **</label>
          <div className="d-flex w-100 flex-wrap">
            <label className={Styles.voucherLabel}>Date </label>
            <input
              className={`${Styles.invoiceInput} ${Styles.maxWidth50}`}
              type="text"
              id="date"
              name="date"
              placeholder={moment(new Date()).format('DD MMM YYYY')}
            />
          </div>

          <div className="d-flex mt-4">
            <div className="w-50">
              <label className={Styles.voucherLabel}>Start </label>
              <p className="mb-0" />
            </div>
            <div className="w-50">
              <label className={Styles.voucherLabel}>Finish </label>
              <p className="mb-0" />
            </div>
          </div>
        </div>
        <div className={Styles.voucherThirdCol}>
          <div className={Styles.thirdColPadd}>
            <label className={Styles.voucherLabel}>Net Amount Due</label>
            <p className="mb-0">{`$${totalAmount || 0.0}`}</p>
          </div>
          <div className={Styles.secondDivBorder}>
            <label className={Styles.voucherLabel} />
            <p className={`mb-0 ${Styles.voucherDetailText}`} />
          </div>
          <div className={Styles.secondDivBorder}>
            <label className={Styles.voucherLabel}>Pd when Wardrobe occurs on a previous day</label>
            <p className={`mb-0 ${Styles.voucherDetailText}`} />
          </div>
        </div>
      </div>
      <div
        className={`${Styles.bottomcontainer}`}
        style={{
          display: 'flex',
          width: '100%',
          marginTop: '30px',
          justifyContent: 'space-around',
          marginBottom: '50px',
        }}
      >
        <div className="col">
          <p className={Styles.paragraph}>
            Overtime 1.5x hours:&nbsp;
            {item?.extraTimeRates?.oneHalfRate?.totalHrs}
          </p>
          <p className={Styles.paragraph}>
            Overtime 1.5x pay:&nbsp;
            {item?.extraTimeRates?.oneHalfRate?.totalRate}
          </p>
          <br />
          <p className={Styles.paragraph}>
            Overtime 2.5x hours:&nbsp;
            {item?.extraTimeRates?.twoHalfRate?.totalHrs}
          </p>
          <p className={Styles.paragraph}>
            Overtime 2.5x pay:
            {item?.extraTimeRates?.twoHalfRate?.totalRate}
          </p>
        </div>
        <div className="col">
          <p className={Styles.paragraph}>
            Overtime 2.0x hours:&nbsp;
            {item?.extraTimeRates?.twoXRate?.totalHrs}
          </p>
          <p className={Styles.paragraph}>
            Overtime 2.0x pay:
            {item?.extraTimeRates?.twoXRate?.totalRate}
          </p>
          <br />

          <p className={Styles.paragraph}>
            Overtime 3x hours:&nbsp;
            {item?.extraTimeRates?.threeHalfRate?.totalHrs}
          </p>
          <p className={Styles.paragraph}>
            Overtime 3x pay:
            {item?.extraTimeRates?.threeHalfRate?.totalRate}
          </p>
          <p className={Styles.paragraph} />
        </div>
        <div className="col">
          <p className={Styles.paragraph}>MPV1:</p>
          <p className={Styles.paragraph}>MPV2:</p>
          <p className={Styles.paragraph} />
        </div>
        <div className="col">
          <p className={Styles.paragraph}>
            Travel (distance) : &nbsp;
            {` $${item?.mileageType || 0.0}`}
          </p>
          <p className={Styles.paragraph}>
            Base rate:
            {` $${item?.voucherRate || 0.0}`}
          </p>
        </div>
      </div>
      <p>
        This employment is subject to all the terms of the Collective Bargaining Agreement between the Union of B.C.
        Performers and the Producers.
      </p>
      <p>
        Ihereby give and grant to the Company named above hereof all rights of every kind and character whatsoever in
        and to all work heretofore done, and all poses, acts, plays, and appearances heretofore made by me for you and
        in all and to all of the results and proceeds of my services heretofore rendered for you, as well as in and to
        the right to use my name, likeness, and photographs, whether still or moving for commercial purposes. I further
        give and grant the said company the right to reproduce in any manner whatsoever any recordings heretofore made
        by said company of my voice and all instrumental, musical, or other sound effects produced by me. I further
        agree that in the event of a retake of any of all or any of the scenes in which I participate, of if any
        additional scenes are required (whether originally contemplated or not) I will return to work and render my
        services in such scenes at the same basic rate of compensation as that paid me for the original taking.
      </p>
      <p>
        I further authorize the producer to make such deductions as are required pursuant to the Collecting Agreement
        and remit such deductions with the appropriate employer contributions to the Union for disposition, in
        accordance with the Collective Agreement or the Constitution and By-laws of the Union.
      </p>
    </div>
  );

  const showVoucher = useMemo(() => {
    if (!item?.voucherType) return getFullUnionData();
    if (item?.voucherType === 'Full Union') return getFullUnionData();
    if (item?.voucherType === 'Non Union') return getNonUnionData();
    return getApprenticeData();
  }, [item]);

  return (
    <>
      <div id="invoice" ref={invoiceRef} className={`${Styles.invoiceWrapper}`} style={{ backgroundColor: 'white' }}>
        <div id="wrapper">
          <div
            className={`pdf-container ${Styles.pdfContainer}`}
            style={{
              paddingTop: '50px',
              backgroundColor: '#fff',
              paddingLeft: '40px',
              paddingRight: '40px',
            }}
          >
            {showVoucher}
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoicePDF;
