import React, { FunctionComponent, useEffect, useState } from 'react';
import createSvg from 'assets/svg/create.svg';
import Input from 'components/controls/input/input';
import TextEditor from 'components/controls/textEditor/TextEditor';
import Button from 'components/controls/button/button';
import Style from 'components/CreateShow/CreateShow.module.css';
import EmailStyle from 'components/castingDirector/PopOvers/EmailDetail/EmailDetail.module.css';
import saveSvg from 'assets/svg/save.svg';
import ModalHeader from 'components/Modal/Header';
import { connect } from 'react-redux';
import { saveEmailTemplateBegin } from 'store/actions/show.actions';
import { getSelectedShowId } from 'store/selector/show.selector';
import { Show } from 'modules/Show/show.types';
import showApi from 'store/services/show.services';
import { EmailTemplate } from 'modules/general/general.type';
import FileUploader from 'components/controls/fileUploader/FileUploader';

interface CreateEmailTemplateProps {
  emailTemplate?: EmailTemplate;
  closeDialog: Function;
  showId: string;
  saveEmailTemplate: Function;
  createdFrom: string;
  type?: string;
}
const CreateEmailTemplate: FunctionComponent<CreateEmailTemplateProps> = (props: CreateEmailTemplateProps) => {
  const { closeDialog, showId, saveEmailTemplate, createdFrom, emailTemplate, type } = props;
  const [name, setName] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [additionalInfo, setAdditionalInfo] = useState<string>('');
  const [disable, setDisable] = useState<boolean>(false);
  const [files, setFiles] = useState<string[]>([]);
  const [deleteFiles, setDeleteFiles] = useState<string[]>([]);

  useEffect(() => {
    if (emailTemplate && emailTemplate.id !== '-1') {
      setName(emailTemplate.title);
      setSubject(emailTemplate.subject);
      setAdditionalInfo(emailTemplate.templateHtml);
      setFiles(emailTemplate.attachements);
    }
    return () => {
      console.log('component unmount');
      handleCloseFiles();
    };
  }, []);

  useEffect(() => {
    if (type !== 'message') setDisable(name.length === 0 || subject.length === 0 || additionalInfo.length === 0);
    if (type === 'message') setDisable(name.length === 0 || additionalInfo.length === 0);
  }, [name, subject, additionalInfo]);

  const handleClick = () => {
    handleCloseFiles();
  };

  const handleCancel = () => {
    closeDialog(false);
  };

  const handleSave = () => {
    if (emailTemplate && emailTemplate.id !== '-1') {
      saveEmailTemplate({
        templateId: emailTemplate.id,
        templateText: additionalInfo,
        name,
        showId,
        subject: type ? 'message' : subject,
        createdFrom,
        attachements: files,
        deleteAttachment: deleteFiles,
        templateType: type || 'Both',
      });
    } else {
      saveEmailTemplate({
        templateText: additionalInfo,
        name,
        showId,
        subject: type ? 'message' : subject,
        createdFrom,
        attachements: files,
        templateType: type || 'Both',
      });
    }
    setFiles([]);
    closeDialog(false);
  };

  const handleFiles = (item: any) => {
    setFiles(item);
    console.log(item);
  };

  const handleCloseFiles = () => {
    if (files.length > 0 && !emailTemplate) fileRemove(files);
    closeDialog(false);
  };

  async function fileRemove(d: string[]) {
    showApi
      .removeShowFile({ fileName: d })
      .then((res) => {
        console.log(res);
        setFiles([]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const handleDeleteUpdateFile = (item: string) => {
    const prevDeleteFiles = deleteFiles || [];
    prevDeleteFiles.push(item);
    setDeleteFiles([...prevDeleteFiles]);
  };

  const getTitle = () => {
    if (type && type === 'message') {
      return emailTemplate ? 'Update request' : 'create new request';
    }
    return emailTemplate ? 'Update template' : 'Create new template';
  };

  return (
    <div className={Style.bgLayer}>
      <div className={Style.ContentWrapper}>
        <ModalHeader title={getTitle()} handleClick={handleClick} icon={createSvg} />
        <div className={`modal-body ${Style.modalBody}`}>
          <div className="row">
            <div className="col-sm-12">
              <Input
                placeholder={`${type && type === 'message' ? 'Request' : 'Template'} name`}
                value={name}
                handleChange={setName}
                label=""
                cssClass={EmailStyle.input}
              />
              {!type && type !== 'message' && (
                <Input
                  placeholder="Template subject"
                  value={subject}
                  handleChange={setSubject}
                  label=""
                  cssClass={EmailStyle.input}
                />
              )}
              <TextEditor
                text={emailTemplate ? emailTemplate.templateHtml : additionalInfo}
                setText={setAdditionalInfo}
              />
            </div>
          </div>
          {createdFrom === 'matrix' ? (
            <div className="row">
              <div className="col-sm-12">
                <label>Attachements</label>
                <FileUploader
                  deleteUpdateFile={handleDeleteUpdateFile}
                  update={emailTemplate && true}
                  handlefileUploader={handleFiles}
                  attachements={emailTemplate && emailTemplate.attachements}
                  paths={[]}
                />
              </div>
            </div>
          ) : null}
          <div className="row text-right">
            <div className={`col-sm-12 ${EmailStyle.buttonWrapper}`}>
              <Button label="Cancel" clickHandler={handleCancel} cssClass={EmailStyle.cancelButton} />
              <Button
                label={emailTemplate && emailTemplate.id !== '-1' ? 'update' : 'save'}
                clickHandler={handleSave}
                icon={saveSvg}
                cssClass={EmailStyle.saveButton}
                isDisabled={disable}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateTopProps = (state: Show) => {
  const showId: string = getSelectedShowId(state);
  return { showId };
};

const mapDispatchToProps = {
  saveEmailTemplate: saveEmailTemplateBegin,
};

export default connect(mapStateTopProps, mapDispatchToProps)(CreateEmailTemplate);
