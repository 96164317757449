import { connect } from 'react-redux';
import { setSelectedTab } from 'store/actions/auth.actions';
import HomeTabs from 'components/TopNavBar/Tabs/HomeTabs';
import { getUser, selectedTab } from 'store/selector/auth.selector';
import { User } from 'modules/user/types';
import { getSettingRequest } from 'store/actions/general.actions';
import { setDatesForFitler } from 'store/actions/show.actions';

const mapStateToProps = (state: User) => {
  const defaultTab = selectedTab(state);
  const user = getUser(state);
  return {
    defaultTab,
    user,
  };
};

const mapDispatchToProps = {
  setSelectedTab,
  getSettingRequest,
  setDatesForFitler,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeTabs);
