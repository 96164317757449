import React, { FunctionComponent, useEffect, useState } from 'react';
import Header from 'components/topHeader/topHeader';
import signupCatStyle from 'components/singupCategory/signupCategory.module.css';
import buttonStyle from 'components/controls/button/button.module.css';
import InputField from 'components/controls/input/input';
import LoginStyle from 'views/login/login.module.css';
import Button from 'components/controls/button/button';
import { useHistory, useLocation, useParams } from 'react-router';
import TextArea from 'components/controls/textArea/textArea';
import CheckBox from 'components/controls/checkbox/checkBox';
import countries from 'helpers/data/countries';
import ItemLink from 'components/controls/link/ItemLink';
import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';
import { checkEmail } from 'utils/FormValidation';
import SignupStyle from 'views/signup/signup.module.css';
import { getRoleId } from 'helpers/utils';
import { RoleCode } from 'modules/user/types';
import DropDownSearch from 'components/controls/select/DropDownSearch';
import PhoneNoInput from 'components/controls/phone/PhoneNoInput';

interface SingupProps {
  isLoading: boolean;
  signupStep: number;
  signupError: string;
  registrationRequest: Function;
}

const Singup: FunctionComponent<SingupProps> = (props) => {
  const { isLoading, signupStep, signupError, registrationRequest } = props;

  const history = useHistory();
  const location = useLocation();
  const getEmailFromQuery = () => {
    let emailValue = '';
    if (location.search) {
      const queryString = location.search.split('?');
      if (queryString.length > 0) {
        const queryData = queryString[1];
        const queryParams = queryData.split('&');
        if (queryParams.length > 0) {
          queryParams.forEach((s: string) => {
            if (s.includes('email')) {
              const [, value] = s.split('=');
              if (value) {
                emailValue = value;
              }
            }
          });
        }
      }
    }
    return emailValue;
  };
  const { type } = useParams<{ type: string }>();
  console.log('type', type);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disable, setDisable] = useState(true);
  const [passwordNotmatch, setPasswordNotmatched] = useState('');
  const [aboutUs, setAboutUs] = useState('');
  const [email, setEmail] = useState(getEmailFromQuery());
  const [confirmEmail, setConfirmEmail] = useState(getEmailFromQuery());
  const [country, setCountry] = useState('');
  const [accept, setAccept] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [confirmEmailError, setConfirmEmailError] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [phoneError, setPhoneError] = useState<string>('');
  const [requiredField, setRequiredField] = useState<string[]>([]);
  const [checkBox, setCheckBox] = useState<string>('');
  const [disableRegistration, setDisableRegistration] = useState<boolean>(false);

  useEffect(() => {
    if (signupStep === 2) {
      history.push(`/profile/${type}`);
    }
    validateInput();
  });

  useEffect(() => {
    if (!isLoading) setDisableRegistration(false);
  }, [isLoading]);

  const handleLogin = () => {
    history.push('/login');
  };

  const handleBlur = (event: React.FormEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    const error = checkEmail(val);
    setEmailError(error);
    if (error.length === 0) removeFromErrorList('email');
    validateInput();
  };

  const removeFromErrorList = (val: string) => {
    if (!val) return;
    setRequiredField(requiredField.filter((x: string) => x.toLowerCase() !== val.toLowerCase()));
  };

  const handleConfirmBlur = (event: React.FormEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    let error = checkEmail(val);

    if (error.length === 0 && email !== val) {
      error = 'confirm email did not match';
    }

    if (error.length === 0) {
      setConfirmEmailError('');
      removeFromErrorList('confirmEmail');
    } else {
      setConfirmEmailError(error);
    }
    validateInput();
  };

  const handlePasswordConfirmation = (event: React.FormEvent<HTMLInputElement>) => {
    if (password.length > 0 && confirmPassword.length > 0 && password !== confirmPassword) {
      setPasswordNotmatched('Password not match');
    } else {
      removeFromErrorList(event.currentTarget.name);
      setPasswordNotmatched('');
    }
  };

  const handleGeneralBlur = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) removeFromErrorList(event.currentTarget.name);
  };

  const textAreaChangeHandler = (event: React.FormEvent<HTMLTextAreaElement>, stateFun: Function) => {
    stateFun(event.currentTarget.value);
  };

  const validateInput = () => {
    const emailMsg = checkEmail(email);
    const isValidInput =
      emailMsg.length === 0 &&
      emailError.length === 0 &&
      confirmEmailError.length === 0 &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      password.length > 0 &&
      password === confirmPassword &&
      confirmPassword.length > 0 &&
      accept;

    setDisable(!isValidInput);
  };

  const handleSignup = () => {
    const errors: string[] = [];
    if (confirmEmail !== email) {
      setEmailError('Email does not match');
      errors.push('email');
    }
    if (firstName.length === 0) errors.push('firstName');
    if (lastName.length === 0) errors.push('lastName');
    if (password.length === 0) errors.push('password');
    if (confirmPassword.length === 0) errors.push('confirmPassword');
    if (email.length === 0) errors.push('email');
    if (confirmEmail.length === 0) errors.push('confirmEmail');
    if (!accept) setCheckBox('unChecked');
    if (errors.length > 0) {
      setRequiredField(errors);
      return;
    }

    if (disable) {
      return;
    }
    if (!accept) return;

    if (disableRegistration) return;
    console.log('type', type);
    registrationRequest({
      name: firstName,
      firstName,
      lastName,
      email,
      phoneNo,
      country,
      password,
      aboutUs,
      role: getRoleId(type === 'CASTING' ? 'DIRECTOR' : (type as RoleCode)),
      isCompleted: false,
    });
    setDisableRegistration(true);
  };

  const isInError = (fieldName: string) => (requiredField.includes(fieldName) ? ' border-danger ' : '');

  return (
    <div className={signupCatStyle.signupWrapper}>
      <Header />
      <div className={signupCatStyle.backgroundWrapper}>
        <div className={signupCatStyle.bgInnerContent}>
          <h1>Sign up</h1>
          <p className={signupCatStyle.afterHeading}>Let’s Sign up first to enter into CastingPax</p>
        </div>
        <div className={`${SignupStyle.signUpWrapper} ${type === 'PERFORMER' && SignupStyle.signupPerformerWrapper}`}>
          <TextErrorMessage message={signupError} />
          <p className={SignupStyle.noTextDesktop}>Let’s Sign up first to enter into CastingPax</p>
          <div className="row">
            <div className="col-sm-6">
              <InputField
                name="firstName"
                label="First Name"
                handleChange={setFirstName}
                cssClass={isInError('firstName')}
                blurHandler={handleGeneralBlur}
              />
            </div>
            <div className="col-sm-6">
              <InputField
                name="lastName"
                label="Last Name"
                handleChange={setLastName}
                blurHandler={handleGeneralBlur}
                cssClass={isInError('lastName')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <InputField
                value={email}
                label="Email"
                cssClass={isInError('email')}
                errorMessage={emailError}
                blurHandler={handleBlur}
                handleChange={setEmail}
              />
            </div>
            <div className="col-sm-6">
              <InputField
                value={confirmEmail}
                cssClass={confirmEmailError || isInError('confirmEmail') ? ' border-danger ' : ''}
                label="Confirm email"
                errorMessage={confirmEmailError}
                blurHandler={handleConfirmBlur}
                handleChange={setConfirmEmail}
              />
            </div>
          </div>
          <div className="row country-dropdown-utils">
            <div className="col-sm-6 profileCreation">
              <PhoneNoInput handleChange={setPhoneNo} value={phoneNo} error={setPhoneError} />
              <TextErrorMessage message={phoneError} />
            </div>
            <div className="col-sm-6 profileCreation">
              <DropDownSearch
                cssClass="signup-country-dropdown"
                data={countries}
                value={country}
                defaultValue="Select Country"
                handleSelect={setCountry}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <InputField
                name="password"
                label="Password"
                type="password"
                cssClass={passwordNotmatch || isInError('password') ? ' border-danger ' : ''}
                blurHandler={handlePasswordConfirmation}
                handleChange={setPassword}
                maxLength={6}
              />
            </div>
            <div className="col-sm-6">
              <InputField
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                cssClass={passwordNotmatch || isInError('confirmPassword') ? ' border-danger ' : ''}
                errorMessage={passwordNotmatch}
                blurHandler={handlePasswordConfirmation}
                handleChange={setConfirmPassword}
                maxLength={6}
              />
            </div>
          </div>
          <div className="row">
            {!location.pathname.includes('PERFORMER') && (
              <div className="col-sm-12">
                <TextArea
                  label="Tell us about yourself"
                  placeHolder="Hello my name..."
                  blurHandler={(e: React.FormEvent<HTMLTextAreaElement>) => textAreaChangeHandler(e, setAboutUs)}
                />
              </div>
            )}

            <div className="col-sm-12 text-left d-flex flex-wrap">
              <CheckBox cssClass={checkBox} label="I agree to CastingPax" handleChecked={setAccept} id="signup" />
              &nbsp;
              <ItemLink label="Terms And Conditions," path="/terms" isNewTab>
                Terms And Conditions
              </ItemLink>
              &nbsp;
              <ItemLink label="Cookie" path="/cookies" isNewTab>
                Cookie
              </ItemLink>
              &nbsp;
              <label
                className="position-relative"
                style={{ color: '#696974', fontSize: '14px', marginBottom: '0', fontWeight: 'normal', top: '4px' }}
              >
                and
              </label>
              &nbsp;
              <ItemLink label="Privacy Policy" path="/privacy" isNewTab>
                Privacy Policy
              </ItemLink>
            </div>
          </div>
          <div className={`${buttonStyle.loginBtnWrapper} ${LoginStyle.mT20}`}>
            <div>
              <Button label="Login" clickHandler={handleLogin} />
            </div>
            <div>
              <Button label="Signup" cssClass=" login-btn w-100" clickHandler={handleSignup} showLoading={isLoading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Singup;
