import React, { FunctionComponent } from 'react';
import { Episode, Show, ShowWorkingDay } from 'modules/Show/show.types';
import Episodes from 'components/ShowLeftNavigation/Episodes/Episodes';
import LeftNavStyle from 'components/ShowLeftNavigation/ShowLeftNav.module.css';
import FilmDays from 'components/ShowLeftNavigation/Film/FilmDays';
import Loader from 'components/controls/Loader/Loader';
import pencilSvg from 'assets/svg/edit.svg';
import { User } from 'modules/user/types';
import { ROLE_COORDINATOR, ROLE_DIRECTOR, ROLE_PRODUCTION } from 'store/castingPax.constants';

interface ShowLeftNavProps {
  selectedShow: Show;
  user: User;
  editShow: Function;
  loading: boolean;
}
const ShowLeftNavigation: FunctionComponent<ShowLeftNavProps> = (props: ShowLeftNavProps) => {
  const { selectedShow, user, editShow, loading } = props;

  const handleEditShow = () => {
    editShow(selectedShow);
  };

  const isAuthroizedToEdit = () => {
    if (user.role === ROLE_DIRECTOR) return true;
    if (
      user.role === ROLE_PRODUCTION &&
      selectedShow.users &&
      selectedShow.users.filter((t: User) => t.id === user.id).length > 0
    ) {
      return true;
    }
    return false;
  };

  const showNavigationData = () => {
    if (user.role === ROLE_COORDINATOR) {
      if (selectedShow.episodes && selectedShow.episodes.length > 0) {
        return selectedShow.episodes
          ?.filter((e: Episode) => {
            const obj = e;
            const filterDay = e.episodeDays.filter((wd: ShowWorkingDay) => wd.bgCoordinator?.includes(user.id));
            if (filterDay.length > 0) {
              obj.episodeDays = filterDay;
              return true;
            }
            return false;
          })
          .map((episode) => <Episodes key={episode.id} episode={episode} />);
      }
      if (selectedShow.showWorkingDays) {
        return selectedShow.showWorkingDays
          .filter((wd: ShowWorkingDay) => wd.bgCoordinator?.includes(user.id))
          .map((episodeDay) => <FilmDays key={episodeDay.id} workingDay={episodeDay} />);
      }
      return '';
    }
    if (selectedShow.episodes && selectedShow.episodes.length > 0) {
      return selectedShow.episodes?.map((episode) => <Episodes key={episode.id} episode={episode} />);
    }
    if (selectedShow.showWorkingDays) {
      return selectedShow.showWorkingDays.map((episodeDay) => <FilmDays key={episodeDay.id} workingDay={episodeDay} />);
    }
    return '';
  };

  return (
    <div className={LeftNavStyle.listCard}>
      <div className="d-flex justify-content-between align-items-center mb-4 px-3">
        <p className={`mb-0 ${LeftNavStyle.listTitle}`}>
          Navigate
          {loading && <Loader isSmallView />}
        </p>
        {isAuthroizedToEdit() && (
          <div onClick={handleEditShow} onKeyUp={handleEditShow} role="button" tabIndex={0}>
            <img src={pencilSvg} alt="" className={LeftNavStyle.pencilIcon} />
          </div>
        )}
      </div>
      <div className={`customScrollBar ${LeftNavStyle.mainListItemWrapper}`}>{showNavigationData()}</div>
    </div>
  );
};

export default ShowLeftNavigation;
