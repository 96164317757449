import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';
import { authenticatedUser } from 'store/actions/auth.actions';
import store from 'store/index';
// import store from 'store/redux-toolkit/store';
import { ThemeProvider } from 'styled-components';
import voucherTheme from 'views/Payroll/utils/theme/themes';
import { LicenseManager } from '@ag-grid-enterprise/core';
import App from './App';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

LicenseManager.setLicenseKey(process.env.REACT_APP_LICENSE_KEY || '');

const user = getItemFromLocalStorage('user');
if (user) {
  store.dispatch(authenticatedUser(user));
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={voucherTheme}>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
