import React, { FunctionComponent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Style from 'components/bgPerformer/ExpandedSearch/Search.module.css';
import DateItem from 'components/dateItem/dateItem';
import { ShowJob } from 'modules/Show/show.types';
import { connect, useSelector } from 'react-redux';
import { getLocations, getScreenWidth } from 'store/selector/general.selector';
import { ScreenSize } from 'modules/general/general.type';
import { SCREEN_SIZE_MOBILE } from 'store/castingPax.constants';
import BookingButton from 'components/bgPerformer/BookingButton/BookingButton';
import { getLength } from 'helpers/utils';

interface SearchResultItemProps {
  job: ShowJob;
  isApplied: boolean;
}
const SearchResultItem: FunctionComponent<SearchResultItemProps> = (props: SearchResultItemProps) => {
  const { job, isApplied } = props;
  const [isExpand, setIsExpand] = useState(false);
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);
  const locations = useSelector(getLocations);

  const getLocationInfo = (location: any) => {
    if (location === 'object') {
      return location.label;
    }
    if (locations.length > 0) {
      const locs = locations.filter((p: any) => p.value === location);
      if (locs.length > 0) return locs[0].label;
    }
    return '';
  };

  let wrapperCss;
  if (isExpand) {
    wrapperCss = Style.searchPersonalInfoExpandWrapper;
  } else {
    wrapperCss = Style.searchPersonalInfoWrapper;
  }

  if (currentScreenSize === SCREEN_SIZE_MOBILE) {
    return (
      <div className={`${wrapperCss}`}>
        <div className={`d-flex align-items-center justify-content-between ${Style.searchResultSection}`}>
          <div>
            <p className={`mb-0 ${Style.searchPersonalInfoDesignation}`}>{job.title}</p>
          </div>
          <div className="serachResutlComp">
            {isApplied ? (
              <>
                <p className={isApplied ? 'mb-0 laterText laterTextNoBg' : ''} />
                {/* <Button label={getbuttonLabel()} clickHandler={submitHandle} type="customCancelBtn" /> */}
              </>
            ) : (
              <>
                <p className="mb-0 laterText"> Later</p>
                {/* <Button label="Submit" clickHandler={submitHandle} type="customCancelBtn" /> */}
              </>
            )}

            <FontAwesomeIcon
              className="cursor-pointer"
              icon={isExpand ? faChevronDown : faChevronRight}
              onClick={() => setIsExpand(!isExpand)}
            />
          </div>
        </div>
        {isExpand && (
          <div className={`row ${Style.generalNotesWrapper}`}>
            <div className="col-sm-9">
              <div className="mb-3">
                <p className={`mb-0 ${Style.searchPersonalInfoDate}`}>{job.showId.title}</p>
              </div>
              <div className="mb-3">
                <DateItem dates={job.dates} cssClass="justify-content-center" />
              </div>
              <div className="mb-4">
                <p className={`mb-0 ${Style.searchPersonalInfoDate}`}>
                  <b>
                    {getLength(job.pendingJobs)}
                    &nbsp;
                  </b>
                  Submitted
                  <span className="mb-0"> &nbsp; | &nbsp;</span>
                  <b>
                    {getLength(job.bookedJobs)}
                    &nbsp;
                  </b>
                  Booked
                </p>
              </div>
              <div className="d-flex align-items-center mb-2">
                <svg width="15" height="15" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.625 9.29297V1.9375C12.625 1.22656 12.0234 0.625 11.3125 0.625H1.6875C0.949219 0.625
                  0.375 1.22656 0.375 1.9375V11.5625C0.375 12.3008 0.949219 12.875 1.6875 12.875H9.01562C9.31641 12.875
                  9.72656 12.7109 9.94531 12.4922L12.2148 10.2227C12.4336 10.0039 12.625 9.59375 12.625 9.29297ZM9.125
                  11.4805V9.375H11.2031L9.125 11.4805ZM11.3125 1.9375V8.0625H8.46875C8.08594 8.0625 7.8125 8.36328
                  7.8125 8.71875V11.5625H1.6875V1.9375H11.3125Z"
                    fill="#4B4D67"
                  />
                </svg>
                <p className={`mb-0 ml-2 ${Style.generalNotesTitle}`}>General Notes</p>
              </div>
              <p className={Style.generalNotesDecsription}>{job.remarks}</p>
            </div>
            <div className="col-sm-3">
              <div className="mb-4">
                <div className="d-flex align-items-center mb-2">
                  <svg width="15" height="15" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.625 9.29297V1.9375C12.625 1.22656 12.0234 0.625 11.3125 0.625H1.6875C0.949219 0.625
                  0.375 1.22656 0.375 1.9375V11.5625C0.375 12.3008 0.949219 12.875 1.6875 12.875H9.01562C9.31641 12.875
                  9.72656 12.7109 9.94531 12.4922L12.2148 10.2227C12.4336 10.0039 12.625 9.59375 12.625 9.29297ZM9.125
                  11.4805V9.375H11.2031L9.125 11.4805ZM11.3125 1.9375V8.0625H8.46875C8.08594 8.0625 7.8125 8.36328
                  7.8125 8.71875V11.5625H1.6875V1.9375H11.3125Z"
                      fill="#4B4D67"
                    />
                  </svg>
                  <p className={`mb-0 ml-2 ${Style.generalNotesTitle}`}>Costume Notes:</p>
                </div>
                <p className={`mb-0 ${Style.generalNotesDecsription}`}>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.
                </p>
              </div>
              <div className="d-flex mb-4">
                <div className="d-flex align-items-center">
                  <svg width="15" height="15" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.4727 8.19531L10.0547 0.777344C9.77344 0.496094 9.24609 0.25 8.85938 0.25H1.6875C0.738281
                  0.25 0 1.02344 0 1.9375V9.14453C0 9.53125 0.210938 10.0586 0.492188 10.3398L7.91016 17.7578C8.57812
                  18.4258 9.66797 18.4258 10.3008 17.7578L17.4727 10.5859C18.1406 9.95312 18.1406 8.86328 17.4727
                  8.19531ZM9.10547 16.5625L1.6875 9.14453V1.9375H8.85938L16.3125 9.39062L9.10547
                  16.5625ZM6.75 5.3125C6.75 4.39844 5.97656 3.625 5.0625 3.625C4.11328 3.625 3.375
                  4.39844 3.375 5.3125C3.375 6.26172 4.11328 7 5.0625 7C5.97656 7 6.75 6.26172 6.75 5.3125Z"
                      fill="#4B4D67"
                    />
                  </svg>
                  <p className={`mb-0 ml-2 ${Style.generalNotesTitle}`}>Category : </p>
                </div>
                <p className={`mb-0 ${Style.generalNotesDecsription}`}>
                  &nbsp;
                  {job.category}
                </p>
              </div>
              <div className="d-flex mb-4">
                <div className="d-flex align-items-center">
                  <svg width="15" height="15" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.5 0.5C3.35938 0.5 0 3.89844 0 8C0 11.0078 0.976562 11.9062 6.71875 20.1484C6.91406 20.3828
                  7.1875 20.5 7.5 20.5C7.77344 20.5 8.04688 20.3828 8.24219 20.1484C13.9844 11.9062 15 11.0078 15 8C15
                  3.89844 11.6016 0.5 7.5 0.5ZM7.5 17.9609C6.91406 17.1406 6.40625 16.3984 5.9375 15.7734C2.26562
                  10.5391 1.875 9.91406 1.875 8C1.875 4.91406 4.375 2.375 7.5 2.375C10.5859 2.375 13.125 4.91406 13.125
                  8C13.125 9.91406 12.6953 10.5391 9.02344 15.7734C8.55469 16.3984 8.04688 17.1406 7.5 17.9609ZM5.3125
                  7.375C5.82031 7.375 6.25 6.98438 6.25 6.4375C6.25 5.92969 5.82031 5.5 5.3125 5.5C4.76562 5.5 4.375
                  5.92969 4.375 6.4375C4.375 6.98438 4.76562 7.375 5.3125 7.375ZM9.6875 7.375C10.1953 7.375 10.625
                  6.98438 10.625 6.4375C10.625 5.92969 10.1953 5.5 9.6875 5.5C9.14062 5.5 8.75 5.92969 8.75 6.4375C8.75
                  6.98438 9.14062 7.375 9.6875 7.375ZM9.29688 9.40625C8.35938 10.4219 6.60156 10.4219 5.70312
                  9.40625C5.35156 9.01562 4.76562 8.97656 4.375 9.32812C3.98438 9.64062 3.94531 10.2656 4.29688
                  10.6172C5.07812 11.5547 6.25 12.0625 7.5 12.0625C8.71094 12.0625 9.88281 11.5547 10.7031
                  10.6172C11.0156 10.2656 10.9766 9.64062 10.5859 9.32812C10.1953 8.97656 9.60938 9.01562 9.29688
                  9.40625Z"
                      fill="#4B4D67"
                    />
                  </svg>
                  <p className={`mb-0 ml-2 ${Style.generalNotesTitle}`}>Location : </p>
                </div>
                <p className={`mb-0 ${Style.generalNotesDecsription}`}>
                  &nbsp;
                  {getLocationInfo(job.location)}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  return (
    <div className={`${wrapperCss}`}>
      <div className={`d-flex align-items-center justify-content-between ${Style.searchResultSection}`}>
        <div>
          <p className={`mb-0 ${Style.searchPersonalInfoDesignation}`}>{job.title}</p>
        </div>
        <div>
          <p className={`mb-0 ${Style.searchPersonalInfoDate}`}>{job.showId.title}</p>
        </div>
        <div>
          <DateItem dates={job.dates} cssClass="justify-content-center column-gap-10 row-gap-10" />
        </div>
        <div>
          <p className={`mb-0 ${Style.searchPersonalInfoDate}`}>
            <b>
              {getLength(job.pendingJobs)}
              &nbsp;
            </b>
            Submitted
            <span className="mb-0"> &nbsp; | &nbsp;</span>
            <b>
              {getLength(job.bookedJobs)}
              &nbsp;
            </b>
            Booked
          </p>
        </div>
        <div className="serachResutlComp">
          <p className="mb-0" />
          <BookingButton type="customCancelBtn" job={job.id} jobDates={job.dates} />
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={isExpand ? faChevronDown : faChevronRight}
            onClick={() => setIsExpand(!isExpand)}
          />
        </div>
      </div>
      {isExpand && (
        <div className={`row ${Style.generalNotesWrapper}`}>
          <div className="col-sm-9">
            <div className="d-flex align-items-center mb-2">
              <svg width="15" height="15" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.625 9.29297V1.9375C12.625 1.22656 12.0234 0.625 11.3125 0.625H1.6875C0.949219 0.625
                  0.375 1.22656 0.375 1.9375V11.5625C0.375 12.3008 0.949219 12.875 1.6875 12.875H9.01562C9.31641 12.875
                  9.72656 12.7109 9.94531 12.4922L12.2148 10.2227C12.4336 10.0039 12.625 9.59375 12.625 9.29297ZM9.125
                  11.4805V9.375H11.2031L9.125 11.4805ZM11.3125 1.9375V8.0625H8.46875C8.08594 8.0625 7.8125 8.36328
                  7.8125 8.71875V11.5625H1.6875V1.9375H11.3125Z"
                  fill="#4B4D67"
                />
              </svg>
              <p className={`mb-0 ml-2 ${Style.generalNotesTitle}`}>General Notes</p>
            </div>
            <p className={Style.generalNotesDecsription}>{job.remarks}</p>
          </div>
          <div className="col-sm-3">
            <div className="mb-4">
              <div className="d-flex align-items-center mb-2">
                <svg width="15" height="15" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.625 9.29297V1.9375C12.625 1.22656 12.0234 0.625 11.3125 0.625H1.6875C0.949219 0.625
                  0.375 1.22656 0.375 1.9375V11.5625C0.375 12.3008 0.949219 12.875 1.6875 12.875H9.01562C9.31641 12.875
                  9.72656 12.7109 9.94531 12.4922L12.2148 10.2227C12.4336 10.0039 12.625 9.59375 12.625 9.29297ZM9.125
                  11.4805V9.375H11.2031L9.125 11.4805ZM11.3125 1.9375V8.0625H8.46875C8.08594 8.0625 7.8125 8.36328
                  7.8125 8.71875V11.5625H1.6875V1.9375H11.3125Z"
                    fill="#4B4D67"
                  />
                </svg>
                <p className={`mb-0 ml-2 ${Style.generalNotesTitle}`}>Costume Notes:</p>
              </div>
              <p className={`mb-0 ${Style.generalNotesDecsription}`}>{job.costumeNote}</p>
            </div>
            <div className="d-flex mb-4">
              <div className="d-flex align-items-center">
                <svg width="15" height="15" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.4727 8.19531L10.0547 0.777344C9.77344 0.496094 9.24609 0.25 8.85938 0.25H1.6875C0.738281
                  0.25 0 1.02344 0 1.9375V9.14453C0 9.53125 0.210938 10.0586 0.492188 10.3398L7.91016 17.7578C8.57812
                  18.4258 9.66797 18.4258 10.3008 17.7578L17.4727 10.5859C18.1406 9.95312 18.1406 8.86328 17.4727
                  8.19531ZM9.10547 16.5625L1.6875 9.14453V1.9375H8.85938L16.3125 9.39062L9.10547
                  16.5625ZM6.75 5.3125C6.75 4.39844 5.97656 3.625 5.0625 3.625C4.11328 3.625 3.375
                  4.39844 3.375 5.3125C3.375 6.26172 4.11328 7 5.0625 7C5.97656 7 6.75 6.26172 6.75 5.3125Z"
                    fill="#4B4D67"
                  />
                </svg>
                <p className={`mb-0 ml-2 ${Style.generalNotesTitle}`}>Category : </p>
              </div>
              <p className={`mb-0 ${Style.generalNotesDecsription}`}>
                &nbsp;
                {job.category}
              </p>
            </div>
            <div className="d-flex mb-4">
              <div className="d-flex align-items-center">
                <svg width="15" height="15" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.5 0.5C3.35938 0.5 0 3.89844 0 8C0 11.0078 0.976562 11.9062 6.71875 20.1484C6.91406 20.3828
                  7.1875 20.5 7.5 20.5C7.77344 20.5 8.04688 20.3828 8.24219 20.1484C13.9844 11.9062 15 11.0078 15 8C15
                  3.89844 11.6016 0.5 7.5 0.5ZM7.5 17.9609C6.91406 17.1406 6.40625 16.3984 5.9375 15.7734C2.26562
                  10.5391 1.875 9.91406 1.875 8C1.875 4.91406 4.375 2.375 7.5 2.375C10.5859 2.375 13.125 4.91406 13.125
                  8C13.125 9.91406 12.6953 10.5391 9.02344 15.7734C8.55469 16.3984 8.04688 17.1406 7.5 17.9609ZM5.3125
                  7.375C5.82031 7.375 6.25 6.98438 6.25 6.4375C6.25 5.92969 5.82031 5.5 5.3125 5.5C4.76562 5.5 4.375
                  5.92969 4.375 6.4375C4.375 6.98438 4.76562 7.375 5.3125 7.375ZM9.6875 7.375C10.1953 7.375 10.625
                  6.98438 10.625 6.4375C10.625 5.92969 10.1953 5.5 9.6875 5.5C9.14062 5.5 8.75 5.92969 8.75 6.4375C8.75
                  6.98438 9.14062 7.375 9.6875 7.375ZM9.29688 9.40625C8.35938 10.4219 6.60156 10.4219 5.70312
                  9.40625C5.35156 9.01562 4.76562 8.97656 4.375 9.32812C3.98438 9.64062 3.94531 10.2656 4.29688
                  10.6172C5.07812 11.5547 6.25 12.0625 7.5 12.0625C8.71094 12.0625 9.88281 11.5547 10.7031
                  10.6172C11.0156 10.2656 10.9766 9.64062 10.5859 9.32812C10.1953 8.97656 9.60938 9.01562 9.29688
                  9.40625Z"
                    fill="#4B4D67"
                  />
                </svg>
                <p className={`mb-0 ml-2 ${Style.generalNotesTitle}`}>Location : </p>
              </div>
              <p className={`mb-0 ${Style.generalNotesDecsription}`}>
                &nbsp;
                {getLocationInfo(job.location)}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default connect(null, null)(SearchResultItem);
