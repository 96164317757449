import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getpayRollUser } from 'store/selector/show.selector';
import { getRateTitle, getRateValues, showLoading } from 'store/selector/rate.selector';
import {
  createRateValuesBegin,
  updateRateValuesBegin,
  viewRateValuesBegin,
  updateRateBegin,
} from 'store/actions/payroll.actions';
import { connect } from 'react-redux';
import { rateValues } from 'helpers/utils';
import RateCardControls from './RateCardControls';
import RateCardItemItem from './RateCardItemNew';

interface Props {
  onClickHandler?: any;
  rateValId: string;
  createRateValues: Function;
  updateRateValues: Function;
  getValuesBegin: Function;
  updateRate: Function;
  getValues: any;
  loading: boolean;
  isEditAble: boolean;
  rateTitle?: string;
}

const RateCard: React.FC<Props> = (props) => {
  const {
    onClickHandler,
    rateValId,
    createRateValues,
    updateRateValues,
    getValues,
    getValuesBegin,
    loading,
    isEditAble,
    updateRate,
    rateTitle,
  } = props;
  const [readonly, setreadonly] = useState(true);
  const [rateError, setRateError] = useState('');
  const [rates, setRates] = useState(getValues);
  const editHandlers = () => {
    setreadonly(false);
  };
  const userId = JSON.parse(localStorage.getItem('user') as string);
  const handleSave = (e: any) => {
    if (rates.rateName !== '') {
      setRateError('');
      rates.rateId = rateValId;
      if (Object.keys(getValues).length > 0) {
        updateRateValues({ rates });
      } else {
        createRateValues({ rates });
      }
      handleChangeRateName(rates);
    } else {
      setRateError('Field is required');
    }
  };
  const handleChangeRateName = (rateObj: object) => {
    const rate = JSON.parse(JSON.stringify(rateObj));
    updateRate({ id: rate.rateId, userId: userId.id, title: rate.rateName });
  };
  useEffect(() => {
    if (rateValId) {
      getValuesBegin({ rateId: rateValId });
    }
  }, [rateValId]);
  useEffect(() => {
    if (Object.keys(getValues).length > 0) {
      setRates({ ...rates, ...getValues });
    } else {
      setRates({ ...rates, ...rateValues });
    }
  }, [getValues]);
  return (
    <StyledCardContainer>
      {/* <RateCardItems
      setRates={setRates}
      rates={rates}
      rateId={rateValId}
      readonly={readonly}
      loading={loading} />
      */}
      <RateCardItemItem
        rateError={rateError}
        setRates={setRates}
        rates={rates}
        rateId={rateValId}
        readonly={readonly}
        loading={loading}
      />
      <RateCardControls
        onClickHandler={onClickHandler}
        editHandlers={editHandlers}
        handleSave={handleSave}
        isEditAble={isEditAble}
      />
    </StyledCardContainer>
  );
};

const mapStateToProps = (state: any) => {
  const payRollUsers: boolean = getpayRollUser(state);
  const getValues = getRateValues(state);
  const loading = showLoading(state);
  const rateTitle = getRateTitle(state);
  return { payRollUsers, getValues, loading, rateTitle };
};

const mapDispatchToProps = {
  createRateValues: createRateValuesBegin,
  updateRateValues: updateRateValuesBegin,
  getValuesBegin: viewRateValuesBegin,
  updateRate: updateRateBegin,
};

export default connect(mapStateToProps, mapDispatchToProps)(RateCard);

const StyledCardContainer = styled.div`
  display: flex;
  flex: 1;
  border-radius: 8px;
  padding: 30px;
  justify-content: space-between;
  background: white;
  @media only screen and (max-width: 1199px) {
    padding: 20px 12px;
  }
  @media only screen and (max-width: 991px) {
    flex-direction: column-reverse;
  }
`;
