import React, { FunctionComponent } from 'react';
import { User } from 'modules/user/types';
import { getName } from 'helpers/utils';
import styled from 'styled-components';

interface ListItemProps {
  item: User;
  handleClick: (email: string) => void;
}
const SearchItem: FunctionComponent<ListItemProps> = (props: ListItemProps) => {
  const { item, handleClick } = props;

  return (
    <>
      <CustomItem onClick={() => handleClick(item.email)}>
        {/* <img src={getImageThumbnail(item.profilePicUrl)} alt="" /> */}
        <Name>{getName(item)}</Name>
        {/* <p>{item.email}</p> */}
      </CustomItem>
    </>
  );
};
export default SearchItem;

const CustomItem = styled.div`
  padding-left: 10px;
  cursor: pointer;
  background: #f2f2f2;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  &:hover {
    background: #e4e1e1;
  }
`;

const Name = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 5px;
  color: #333;
`;
