export const hoursOptionsData = [
  {
    value: '5',
    label: '5',
  },
  {
    value: '6',
    label: '6',
  },
  {
    value: '7',
    label: '7',
  },
  {
    value: '8',
    label: '8',
  },
  {
    value: '9',
    label: '9',
  },
  {
    value: '10',
    label: '10',
  },
];
export const templatesContent = [
  {
    id: '1',
    value: 'UBCP',
    label: 'UBCP',
    name: 'UBCP',
  },
  {
    id: '2',
    value: 'ACTRA',
    label: 'ACTRA',
    name: 'ACTRA',
  },
  {
    id: '3',
    value: 'NON UNION',
    label: 'NON UNION',
    name: 'NON UNION',
  },
];

export const lunchOptions = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '30',
    label: '30',
  },
  {
    value: '1h',
    label: '1h',
  },
  {
    value: 'Paid Lunch',
    label: 'Paid Lunch',
  },
];

export const categoriesOptions = [
  {
    value: 'GENERAL B.P',
    label: 'GENERAL B.P',
    name: 'GENERAL B.P',
  },
  {
    value: 'STAND-IN',
    label: 'STAND-IN',
    name: 'STAND-IN',
  },
  {
    value: 'PHOTO-DOUBLE',
    label: 'PHOTO-DOUBLE',
    name: 'PHOTO-DOUBLE',
  },
  {
    value: 'SPECIAL ABILITY',
    label: 'SPECIAL ABILITY',
    name: 'SPECIAL ABILITY',
  },
  {
    value: 'WARDROBE CALL',
    label: 'WARDROBE CALL',
    name: 'WARDROBE CALL',
  },
  {
    value: 'PHOTO SESSION',
    label: 'PHOTO SESSION',
    name: 'PHOTO SESSION',
  },
  {
    value: 'OTHERS',
    label: 'OTHERS',
    name: 'OTHERS',
  },
];
