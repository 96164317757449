import React, { FunctionComponent, useMemo } from 'react';
// import editSvg from 'assets/svg/edit.svg';
import exportSvg from 'assets/svg/export.svg';
import bgPerformerSvg from 'assets/svg/bg-peroformer-small.svg';
import coordinatorSvg from 'assets/svg/cordinator.svg';
import Style from 'components/Producer/Matrix/MatrixItem/MatrixItem.module.css';
import { MatrixType, ShowMatrixStats } from 'modules/Show/show.types';
import { formateDate, matrixRedirect } from 'helpers/utils';
import moment from 'moment';

interface ProducerMatrixItemProps {
  workingDay: MatrixType;
  stats: ShowMatrixStats[];
}

const ProducerMatrixItem: FunctionComponent<ProducerMatrixItemProps> = (props: ProducerMatrixItemProps) => {
  const { stats, workingDay } = props;

  const getPerformerCount = useMemo(() => {
    if (stats.length > 0) {
      const index = stats.findIndex((w: any) => w.id === workingDay.id);
      if (index !== -1) return stats[index].count;
    }
    return 0;
  }, []);

  const handleNewTabClick = (e: any) => {
    e.stopPropagation();
    matrixRedirect.save({
      showId: workingDay.showId.id,
      id: workingDay.id,
      date: `${moment(workingDay.date).format('YYYY-MM-DD')} 00:00:00`,
    });
  };

  return (
    <div className={`${Style.matrixItemWrapper}`}>
      <div className={`${Style.matrixItemInner}`}>
        <div className={`d-flex align-items-center ${Style.matrixFirstColumn}`}>
          <p className={`mb-0 ${Style.matrixItemTitle}`}>
            {`${formateDate(workingDay.date, 'MMM DD YYYY')} ${workingDay.title}`}
          </p>
          {/* <div onClick={() => setIsEdit(!isEdit)} onKeyUp={() => setIsEdit(!isEdit)} role="button" tabIndex={0}> */}
          {/*  <img src={editSvg} alt="" className={Style.editIcon} /> */}
          {/* </div> */}
        </div>
        <div className={`d-flex align-items-center ${Style.matrixFirstColumn}`}>
          <img src={coordinatorSvg} alt="" className={Style.coordinatorIcon} />
          <div className="d-flex justify-content-between align-items-center">
            <p className={`mb-0 ${Style.matrixItemTitle}`}>{` ${workingDay.bgCoordinator.length} Coordinator`}</p>
          </div>
        </div>
        <div className={`d-flex align-items-center ${Style.matrixFirstColumn}`}>
          <img src={bgPerformerSvg} alt="" className={Style.bgPerformerIcon} />
          <div className="d-flex justify-content-between align-items-center">
            <p className={`mb-0 ${Style.matrixItemTitle}`}>{`${getPerformerCount} background Performer`}</p>
          </div>
        </div>
        <div className={Style.exported}>
          <a href="/production/matrix" target="_blank" onClick={handleNewTabClick}>
            <img src={exportSvg} alt="" className={Style.exportIcon} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProducerMatrixItem;
