import React, { FunctionComponent, useEffect, useState } from 'react';
import { MatrixRedirect, Show, ShowList } from 'modules/Show/show.types';
import ShowDetail from 'components/Producer/Matrix/Details/index';

interface ProducerMatrixProps {
  showList: ShowList;
  isUpdateStats: number;
  fetchUserCount: Function;
  showId: string;
  dates: string[];
  getMatrixDataBegin: Function;
  redirectedData: MatrixRedirect;
}
const ProducerMatrix: FunctionComponent<ProducerMatrixProps> = (props: ProducerMatrixProps) => {
  const { showList, fetchUserCount, isUpdateStats, showId, dates, getMatrixDataBegin, redirectedData } = props;
  const [redirectData, setRedirectData] = useState<any>();

  const limit: number = 6;

  useEffect(() => {
    loadData(1);
  }, [dates, showId]);

  useEffect(() => {
    if (redirectedData !== null) {
      setRedirectData(redirectedData);
    }
    return () => {
      setRedirectData('');
    };
  }, []);

  const loadData = (page: number) => {
    if (showId) getMatrixDataBegin({ date: dates, showId, limit, page });
  };

  useEffect(() => {
    if (showList.length > 0) fetchUserCount({ ids: showList.map((s: Show) => s.id) });
  }, [isUpdateStats]);

  const loadRedirectData = () => {
    if (redirectData && showList) {
      const obj: any = showList.filter((s: Show) => s.id === redirectData.showId);
      return obj.map((o: Show) => <ShowDetail key={o.id} show={o} team={o.team} matrix={o.matrix} />);
    }
    return <> </>;
  };

  return (
    <>
      {loadRedirectData()}
      {showList &&
        !redirectData &&
        showList.map((item: Show) => <ShowDetail key={item.id} show={item} team={item.team} matrix={item.matrix} />)}
    </>
  );
};

export default ProducerMatrix;
