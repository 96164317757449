import React, { FunctionComponent, useState } from 'react';
import LeftNavStyle from 'components/ShowLeftNavigation/ShowLeftNav.module.css';
import { Episode, ShowWorkingDay } from 'modules/Show/show.types';
import EpisodeDays from 'components/ShowLeftNavigation/Episodes/EpisodeDays';
import upArrowSvg from 'assets/svg/up-arrow.svg';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import { connect } from 'react-redux';
import { setDatesForFitler } from 'store/actions/show.actions';

interface EpisodesProps {
  episode: Episode;
  setFilterDates: Function;
}

const Episodes: FunctionComponent<EpisodesProps> = (props: EpisodesProps) => {
  const { episode, setFilterDates } = props;
  const { episodeDays } = episode;

  const [toggleEpisodesDays, setToggleEpisodesDays] = useState(false);
  const handleClick = (e: any) => {
    e.stopPropagation();
    setToggleEpisodesDays(!toggleEpisodesDays);
  };

  const handleEpisodeClick = () => {
    setFilterDates(episode.episodeDays.map((x: ShowWorkingDay) => x.date));
  };

  return (
    <>
      <div className={`mb-2 ${LeftNavStyle.contentBorderBottom}`}>
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={handleEpisodeClick}
          onKeyPress={handleEpisodeClick}
          role="button"
          tabIndex={0}
        >
          <p className={LeftNavStyle.showTitle}>{episode.title}</p>
          <span className={LeftNavStyle.angleDown}>
            <div onClick={handleClick} onKeyUp={handleClick} role="button" tabIndex={0}>
              <img src={toggleEpisodesDays ? upArrowSvg : downArrowSvg} alt="" className="ml-2" />
            </div>
          </span>
        </div>
        {toggleEpisodesDays && (
          <div className="d-flex justify-content-start flex-column align-items-center">
            {episodeDays?.map((episodeDay) => (
              <EpisodeDays
                key={episodeDay.id}
                episodeDays={episodeDay}
                setFilterDates={setFilterDates}
                showDates={[]}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  setFilterDates: setDatesForFitler,
};

export default connect(null, mapDispatchToProps)(Episodes);
