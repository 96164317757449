import React from 'react';
import Styled from 'styled-components';
import { Link } from 'react-router-dom';
// import FooterLogo from 'assets/images/FooterLogo.svg';

const Footer = () => (
  <FooterContainer>
    {/* <LogoImage src={FooterLogo} /> */}
    <CastingText>
      Casting
      <span>PAX</span>
    </CastingText>
    <TextContainer>
      <StyledLink to="/privacy">Privacy & Terms</StyledLink>
    </TextContainer>
  </FooterContainer>
);

const FooterContainer = Styled.div`
display: flex;
width: 100% !important;
margin: 0 auto;
max-width: 1600px;
// min-width: 1080px;
height: 100px;
justify-content: space-between;
align-items: end;
@media only screen and (max-width: 1199px) {
  // min-width: 900px;

}
@media only screen and (max-width: 991px) {
  // min-width: 645px;

}
`;

const CastingText = Styled.p`
font-family: Montserrat,sans-serif;
height: 29px;
font-family: Poppins;
font-style: normal;
font-weight: 600;
color: #49D360;
font-size: 24px;
line-height: 36px;
display: flex;

span {
color: #000;
font-weight: 600;
}
`;

const TextContainer = Styled.p`
display: flex;
color:#4F4F4F;
line-height: 15px;
font-family: Montserrat;
font-style: normal;
font-weight: 400;
font-size: 12px;
margin-top: auto;
`;

const StyledLink = Styled(Link)`
text-decoration: none;
color: #4F4F4F !important;
&:hover {
  text-decoration: none;
  }
`;

export default Footer;
