import { MatrixType } from 'modules/Show/show.types';
import React, { FunctionComponent } from 'react';
import Image from 'assets/images/image1.png';
import bgPerformerSvg from 'assets/svg/bg-peroformer-small.svg';
import coordinatorSvg from 'assets/svg/cordinator.svg';
import upArrowSvg from 'assets/svg/up-arrow.svg';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import exportSvg from 'assets/svg/export.svg';
import { parseDateInUTCFormat, matrixRedirect } from 'helpers/utils';
import moment from 'moment';
import {
  SCREEN_SIZE_LAPTOP,
  SCREEN_SIZE_MEDIUM_TABLET,
  SCREEN_SIZE_MOBILE,
  SCREEN_SIZE_TABLET,
} from 'store/castingPax.constants';
import { ScreenSize } from 'modules/general/general.type';
import { useSelector } from 'react-redux';
import { getPaths, getScreenWidth } from 'store/selector/general.selector';
import { getSelectedshowimage } from 'store/selector/show.selector';
import Style from './Header.module.css';

interface HeaderProps {
  item: MatrixType;
  bgCount: number;
  setIsExpanded: Function;
  isExpanded: boolean;
}

const Header: FunctionComponent<HeaderProps> = (props: HeaderProps) => {
  const { item, bgCount, setIsExpanded, isExpanded } = props;
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);
  const path: any = useSelector(getPaths);
  const imageFile: string = useSelector(getSelectedshowimage);

  const handleNewTabClick = (e: any) => {
    e.stopPropagation();
    matrixRedirect.save({
      showId: item.showId.id,
      id: item.id,
      date: `${moment(item.date).format('YYYY-MM-DD')} 00:00:00`,
    });
  };
  const getPerformerCount = () => {
    if (item.performers && item.performers.length !== 0) return item.performers.length;
    return 0;
  };

  if (
    currentScreenSize === SCREEN_SIZE_MOBILE ||
    currentScreenSize === SCREEN_SIZE_TABLET ||
    currentScreenSize === SCREEN_SIZE_MEDIUM_TABLET ||
    currentScreenSize === SCREEN_SIZE_LAPTOP
  ) {
    return (
      <div className={`${Style.mainWrapper}`} id="mainMatrix">
        <div
          className="matrixWaterFallWrapper"
          onKeyUp={() => setIsExpanded(!isExpanded)}
          onClick={() => setIsExpanded(!isExpanded)}
          role="button"
          tabIndex={0}
        >
          <div className={Style.imageWrapper}>
            <img src={item && path && imageFile ? `${path.showImage}${imageFile}` : Image} alt="imageWrapper" />
          </div>
          <div className={Style.titleDivWrapper}>
            <p className={`mb-0 ${Style.showTitle}`}>{item.showId.title}</p>
            <div className={Style.performerCoordinationWrapper}>
              <div className={`d-flex align-items-center ${Style.coordinatorSection}`}>
                <img src={coordinatorSvg} alt="" />
                <p className="mb-0">{`${item.bgCoordinator.length} Coordinator`}</p>
              </div>
              <div className={`d-flex align-items-center ${Style.performerSection}`}>
                <img src={bgPerformerSvg} alt="" />
                <p className="mb-0">{`${getPerformerCount()} background Performer `}</p>
              </div>
            </div>
          </div>
          <div className={`d-flex justify-content-between align-items-center ${Style.matrixActionsWrapper}`}>
            <a href="/casting/home" target="_blank" onClick={handleNewTabClick}>
              <img src={exportSvg} alt="" className={Style.exportIcon} />
            </a>
            <div>
              <img src={isExpanded ? upArrowSvg : downArrowSvg} alt="" className={Style.arrowIcon} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className="matrixWaterFallWrapper"
      onKeyUp={() => setIsExpanded(!isExpanded)}
      onClick={() => setIsExpanded(!isExpanded)}
      role="button"
      tabIndex={0}
    >
      <div className="matrixWaterFallLeft">
        <div className={Style.imageWrapper}>
          <img src={item && imageFile ? `${path.showImage}${imageFile}` : Image} alt="imageWrapper" />
        </div>
        <div className={Style.titleWrapper}>
          <p className={`mb-0 ${Style.showTitle} ${Style.showTitlcus}`} title={item.showId.title}>
            {item.showId.title}
          </p>
          <div className={`d-flex justify-content-between align-items-center ${Style.filmsShootingWrapper}`}>
            {item.episodeId && (
              <div className={Style.greyWrapper}>
                <p className="mb-0">{item.episodeId.title}</p>
              </div>
            )}
            <div className={Style.greenWrapper}>
              <p className="mb-0">{item.showId.showType}</p>
              <span className="mb-0">{item.title}</span>
            </div>
            <div className={Style.blueWrapper}>
              <p className="mb-0">Shoot on</p>
              <span className="mb-0">{parseDateInUTCFormat(item.date, 'MMM DD')}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="matrixWaterFallRight">
        <div className={Style.performerCoordinationWrapper}>
          <div className={`d-flex align-items-center ${Style.coordinatorSection}`}>
            <img src={coordinatorSvg} alt="" />
            <p className="mb-0">{`${item.bgCoordinator.length} Coordinator`}</p>
          </div>
          <div className={`d-flex align-items-center ${Style.performerSection}`}>
            <img src={bgPerformerSvg} alt="" />
            <p className="mb-0">{`${bgCount} background Performer `}</p>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <a href="/casting/home" target="_blank" onClick={handleNewTabClick}>
            <img src={exportSvg} alt="" className={Style.exportIcon} />
          </a>
          <div>
            <img src={isExpanded ? upArrowSvg : downArrowSvg} alt="" className={Style.arrowIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
