import React, { FunctionComponent, useState } from 'react';
import Style from 'components/bgPerformer/CastingRequest/CastingRequest.module.css';
import { saveBookingData } from 'helpers/utils';
import exportSvg from 'assets/svg/export.svg';
import checkSvg from 'assets/svg/check.svg';
import closeSvg from 'assets/svg/close.svg';
import { BookingRequest } from 'modules/jobs/types';
import { useSelector } from 'react-redux';
import { getScreenWidth } from 'store/selector/general.selector';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import upArrowSvg from 'assets/svg/up-arrow.svg';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import DateItem from 'components/dateItem/dateItem';
import Button from 'components/controls/button/button';
import PopoverStickOnHover from 'components/controls/Popover/PopoverStick';
import { Link } from 'react-router-dom';
import { ScreenSize } from 'modules/general/general.type';
import { SCREEN_SIZE_MOBILE } from 'store/castingPax.constants';

interface RequestItemProps {
  job: BookingRequest;
  handleConfirm: (e: React.FormEvent<HTMLButtonElement>) => void;
  handleReject: (e: React.FormEvent<HTMLButtonElement>) => void;
  handleDetail: Function;
}
const RequestItem: FunctionComponent<RequestItemProps> = (props) => {
  const { job, handleConfirm, handleReject, handleDetail } = props;
  const [isOpened, setIsOpened] = useState(false);
  const fullDetailHandle = () => console.log('Click');
  const arrowDownClicked = () => {
    setIsOpened(!isOpened);
  };
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);

  const handleContextClick = () => {
    saveBookingData.save(job);
  };

  const handleLinkClick = (event: any) => {
    event.preventDefault();
  };

  if (currentScreenSize === SCREEN_SIZE_MOBILE) {
    return (
      <>
        <tr>
          <td>
            <p className={`mb-0 ${Style.requestTitle}`}>{job.jobId.title}</p>
          </td>
          <td>
            <button type="button" className={`btn btn-outline-success ${Style.confirmButton}`} onClick={handleConfirm}>
              <img src={checkSvg} alt="" />
            </button>
            <button type="button" className={`btn btn-outline-danger ${Style.rejectButton}`} onClick={handleReject}>
              <img src={closeSvg} alt="" />
            </button>
          </td>
          <td>
            <div onClick={arrowDownClicked} onKeyPress={arrowDownClicked} role="button" tabIndex={0}>
              <img src={isOpened ? upArrowSvg : downArrowSvg} alt="arrow " />
            </div>
          </td>
        </tr>
        <div className={Style.showRequestDetailMobile}>
          {isOpened && (
            <div className={Style.itemDetailPopoverWrapper}>
              <div className={Style.itemDetailPopoverHeader}>
                <p className="mb-0">Show Name</p>
              </div>
              <div className={Style.itemDetailPopoverBody}>
                <div className={Style.popoverLocationWrapper}>
                  <p className={`mb-2 ${Style.popoverLocationTitle}`}>Location</p>
                  <p className={`mb-0 ${Style.popoverLocationDesc}`}>
                    {job.jobId.location ? job.jobId.location.label : ''}
                  </p>
                </div>
                <div className={Style.popoverLocationWrapper}>
                  <p className={`mb-2 ${Style.popoverLocationTitle}`}>Description</p>
                  <p className={`mb-3 ${Style.popoverLocationDesc}`}>N/A</p>
                </div>
                <div className="mb-3">
                  <DateItem dates={job.dates} cssClass="justify-content-center" />
                </div>
                <div className="text-center">
                  <Button
                    label="Full Detail"
                    clickHandler={fullDetailHandle}
                    rightIcon={faExternalLinkAlt}
                    cssClass={Style.popoverDetailBtn}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <tr className="pt-1">
        <td>
          <div className="d-flex text-left">
            <PopoverStickOnHover item={job.jobId} status="call" fullDetailHandle={fullDetailHandle}>
              <Link
                className={`mb-0 d-block text-decoration-none ${Style.castingCallTitle}`}
                to="/performer/home"
                onClick={handleLinkClick}
                onContextMenu={handleContextClick}
              >
                <p className={`mb-0 ${Style.requestTitle}`}>{job.jobId.title}</p>
              </Link>
            </PopoverStickOnHover>
            <div
              onClick={() => handleDetail(job.jobId, job)}
              onKeyUp={() => handleDetail(job.jobId, job)}
              tabIndex={0}
              role="button"
            >
              <img src={exportSvg} className="ml-4" alt="" />
            </div>
          </div>
        </td>
        <td>
          <p className={`mb-0 ${Style.requestTitle}`}>{job.jobId.location ? job.jobId.location.label : ''}</p>
        </td>
        <td>
          <DateItem dates={job.dates} datesToShow={1} cssClass="justify-content-center column-gap-10" />
        </td>
        <td>
          <button type="button" className={`btn btn-outline-success ${Style.confirmButton}`} onClick={handleConfirm}>
            Confirm
          </button>
          <button type="button" className={`btn btn-outline-danger ml-3 ${Style.rejectButton}`} onClick={handleReject}>
            Reject
          </button>
        </td>
      </tr>
    </>
  );
};

export default RequestItem;
