import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Style from 'components/CreateShow/Detail/UserItem/Item.module.css';

interface InfoProps {
  icon: IconProp;
  title?: string;
}
const Info: FunctionComponent<InfoProps> = (props: InfoProps) => {
  const { title, icon } = props;
  return (
    <div className={`d-flex ${Style.Info}`}>
      <FontAwesomeIcon icon={icon} />
      <p className="mb-0">{title}</p>
    </div>
  );
};

export default Info;
