import React, { FunctionComponent, useEffect, useState } from 'react';
import cardDownSvg from 'assets/svg/down-arrow.svg';
import Style from 'components/bgPerformer/rental/Rental.module.css';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import { RentalOption } from 'modules/general/general.type';
import { RentalImage } from 'modules/user/types';
import DeleteImage from 'assets/images/delete.png';
import { capitalizeLetter } from 'helpers/utils';
import Loader from 'components/controls/Loader/Loader';
import checkSvg from 'assets/svg/only-check.svg';

interface RentalItemProps {
  item: RentalImage;
  rentalOpt: RentalOption[];
  selectedId: string;
  userRental: string[];
  handleDelete: Function;
  handleChangeType: Function;
  loader?: boolean;
  loaderType: string;
}
const RentalItem: FunctionComponent<RentalItemProps> = (props: RentalItemProps) => {
  const { rentalOpt, item, handleDelete, loader, selectedId, userRental, handleChangeType, loaderType } = props;
  const [selectedType, setSelectedType] = useState<string>('');

  useEffect(() => {
    if (!loader && !selectedId) setSelectedType('');
  }, [loader]);

  const isExistRental = (type: string) => {
    if (type === item.rentalType) return '';
    if (userRental.includes(type)) return Style.disableRental;
    return 'cursor-pointer';
  };

  const handleChange = (type: string) => {
    if (item.rentalType === type || userRental.includes(type)) return;
    handleChangeType(item.id, type);
    setSelectedType(type);
  };

  const rentalListing = () => (
    <ul className={`list-unstyled mb-0 ${Style.listingWrapper}`}>
      {rentalOpt.map((opt: RentalOption, index: number) => (
        <>
          <li
            key={index}
            className={isExistRental(opt.label)}
            role="presentation"
            onClick={() => handleChange(opt.label)}
            onKeyPress={() => handleChange(opt.label)}
          >
            <div className="d-flex justify-content-between">
              {capitalizeLetter(opt.label)}
              {item.rentalType === opt.label && <img src={checkSvg} alt="rental type" className={Style.checkIcon} />}
              {loader && selectedType === opt.label && loaderType === 'changeType' && <Loader isSmallView />}
            </div>
          </li>
        </>
      ))}
    </ul>
  );

  return (
    <figure>
      {loader && selectedId === item.id && loaderType === 'deleteType' ? (
        <Loader cssClass={`${Style.delete}`} isSmallView />
      ) : (
        <img
          src={DeleteImage}
          alt=""
          className={`${Style.delete} cursor-pointer`}
          onClick={() => handleDelete(item.id)}
          onKeyPress={() => handleDelete(item.id)}
          role="presentation"
        />
      )}
      <img src={item.image} alt="card images" />
      <figcaption className="d-flex align-items-center justify-content-between">
        <p>{capitalizeLetter(item.rentalType)}</p>
        <PopoverContainer itemComponent={rentalListing}>
          <div className="cursor-pointer">
            <img src={cardDownSvg} alt="arrow down" />
          </div>
        </PopoverContainer>
      </figcaption>
    </figure>
  );
};

export default RentalItem;
