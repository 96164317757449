import React, { FunctionComponent, useState } from 'react';
import ModalHeader from 'components/Modal/Header';
import Style from 'components/bgPerformer/Images/Image.module.css';
import DropZone from 'components/controls/dropzone/Dropzone';
import createSvg from 'assets/svg/create.svg';
import Button from 'components/controls/button/button';
import uploadSvg from 'assets/svg/upload.svg';
import DashboardInput from 'components/controls/input/dashboardInput/dashboardInput';
import DropDownSearch from 'components/controls/select/DropDownSearch';
import { SelectOptionType, SelectOptionTypeList } from 'modules/PerformerList/list.types';

interface UploadImageProps {
  type: string;
  closeDialog: Function;
  fileUploadHandler: Function;
  fileLoader?: boolean;
  isMultiple?: boolean;
  selectOptions?: SelectOptionTypeList;
}
const UploadImage: FunctionComponent<UploadImageProps> = (props: UploadImageProps) => {
  const { closeDialog, fileUploadHandler, type, fileLoader, isMultiple, selectOptions } = props;
  const [file, setFile] = useState<any>();
  const [albumName, setAlbumName] = useState<string>();
  const [rentalType, setRentalType] = useState<SelectOptionType>();
  const loading = fileLoader || false;

  const getDialogTitle = () => {
    if (type === 'pdf') {
      return 'Add New File';
    }
    if (type === 'album') {
      return 'New Album';
    }
    return 'Add New Image';
  };

  const handleFile = (uploadFile: any) => {
    console.log('handle file', uploadFile);
    if (type === 'pdf') {
      fileUploadHandler(uploadFile);
      return;
    }
    if (type === 'rental') {
      setFile(uploadFile);
      return;
    }
    const files = uploadFile.filter((u: any) => u.status === 'pending');
    if (type === 'album') {
      setFile(files);
      return;
    }
    fileUploadHandler(files);
  };

  const handleClick = () => {
    if (type === 'album' && file && albumName) {
      fileUploadHandler(file, albumName);
    }
    if (type === 'rental' && file && rentalType && rentalType.label) {
      fileUploadHandler(file, rentalType);
    }
  };

  const checkFile = () => {
    if (type === 'album') return !file || !albumName;
    if (type === 'rental') return !file || !rentalType?.label;
    return false;
  };

  return (
    <>
      <div className={Style.ContentWrapper}>
        <ModalHeader
          title={getDialogTitle()}
          handleClick={() => {
            closeDialog(false);
          }}
          icon={createSvg}
        />
        <div className="p-3 modal-body">
          <div className={`${Style.modalBody}`}>
            {type === 'album' && <DashboardInput placeholder="Album Name" handleChange={setAlbumName} />}
            {type === 'rental' && selectOptions && (
              <DropDownSearch
                value={rentalType ? rentalType.label : 'select'}
                data={selectOptions}
                storeObject
                defaultValue="select"
                handleSelect={setRentalType}
              />
            )}

            <DropZone
              isMultiple={isMultiple || false}
              closeDialog={closeDialog}
              loader={loading}
              fileUploadHandler={handleFile}
              type={type}
            />

            {(type === 'album' || type === 'rental') && (
              <Button
                isDisabled={checkFile()}
                showLoading={loading}
                icon={uploadSvg}
                cssClass="addNewImage mx-auto"
                label="Upload"
                type="primary"
                clickHandler={handleClick}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={Style.bgLayer}
        onClick={() => closeDialog()}
        onKeyPress={() => closeDialog()}
        role="button"
        tabIndex={0}
      />
    </>
  );
};

export default UploadImage;
