import React, { FunctionComponent, useEffect } from 'react';
import Style from 'views/landing/landing.module.css';
import logoSvg from 'landingAssets/svg/logo.svg';
import bannerImage from 'landingAssets/images/banner-image.png';
import atlassianImage from 'landingAssets/images/atlassian.png';
import slackImage from 'landingAssets/images/slack.png';
import dropboxImage from 'landingAssets/images/dropbox.png';
import shopifyImage from 'landingAssets/images/shopify.png';
import googleImage from 'landingAssets/images/google.png';
import hasstleImage from 'landingAssets/images/hasstle.png';
import costConfirmImage from 'landingAssets/images/cost-confirm.png';
import castingImage from 'landingAssets/images/casting.png';
import dollarImage from 'landingAssets/svg/dollar.svg';
import visualsImage from 'landingAssets/svg/visuals.svg';
import budgetImage from 'landingAssets/svg/budget.svg';
import infoImage from 'landingAssets/svg/info.svg';
import WOW from 'wowjs';
import { Accordion } from 'react-bootstrap';
import AccordionItem from 'views/landing/accordionItem/AccordionItem';
import { Link } from 'react-router-dom';

const Features: FunctionComponent = () => {
  const accordionCardItem = [
    {
      title: 'Performer Matrix',
      description: `Through our matrix, easily visualize the extras break down through our matrix where you
       can simply adjust categories, rates, call times and leave notes for BG Coordinators`,
      eventKey: '0',
    },
    {
      title: 'Message Management  ',
      description: `Email and Text notifications to let performers know they are requested to work + 
      call time email links. Through automated fields, casting teams can input adjustable fields that
       will adjust to the performer's details set in the matrix so that casting only needs to 
       send 1 call time email to all.`,
      eventKey: '1',
    },
    {
      title: 'Roles & Bookings',
      description: `Control is key with our service,
        once a role has been posted, performers can submit to jobs and casting can request performers.`,
      eventKey: '2',
    },
  ];
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <div className={Style.bodyWrapper}>
      <header className={Style.navBg}>
        <div className={`position-relative ${Style.container}`}>
          <nav className="d-flex justify-content-between align-items-center">
            <Link to="/" className="d-inline-block">
              <img src={logoSvg} alt="logo" className={`${Style.logoImg}`} />
            </Link>
            <ul className="m-0 list-unstyled d-flex align-items-center">
              <li className="nav-item">
                <Link className={`nav-link py-0 text-white ${Style.fontSixteen}`} to="/features">
                  Features
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link py-0 text-white ${Style.fontSixteen}`} to="/">
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link py-0 text-white ${Style.fontSixteen}`} to="/">
                  Support
                </Link>
              </li>
              <div className={Style.listingBtn}>
                <li className="nav-item">
                  <a
                    className={`nav-link ${Style.fontSixteen} btn ${Style.signupBtn} text-white`}
                    href="/signupcategory"
                  >
                    Sign Up
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${Style.fontSixteen} btn mr-0
                      ${Style.signupBtn} ${Style.signupBtnFilled} text-white`}
                    href="/login"
                  >
                    Sign In
                  </a>
                </li>
              </div>
            </ul>
          </nav>
        </div>
      </header>

      {/*  Banner Section */}

      <section className={Style.bannerWrapper}>
        <div className={Style.container}>
          <div className="row">
            <div className={`col-md-6 ${Style.bannerPaddingTop}`}>
              <div className={`${Style.bannerHeading} wow fadeInUp`} data-wow-duration="1s" data-wow-delay="0.5s">
                <span>BG Casting </span>
                <br />
                Made Simple
              </div>
              <p
                className={`text-white mt-4 wow fadeInUp ${Style.paragraphText} ${Style.bannerDescriptionWidth}`}
                data-wow-duration="1s"
                data-wow-delay="0.8s"
              >
                One service to manage, book and sign in Background performers. CastingPAX focuses on automating timely
                tasks and focuses on clear communication for the 21st century.
              </p>
            </div>
            <div className="col-md-6" />
          </div>
        </div>
      </section>
      <div className={Style.bannerImage}>
        <img src={bannerImage} alt="performer" className="" />
      </div>
      <section
        className={`text-center wow zoomIn ${Style.customContainer} ${Style.happyClientsWrapper}`}
        data-wow-duration="1s"
        data-wow-delay="0.8s"
      >
        <h4>Partnerships </h4>
        <p>Trusted by industry leaders</p>
      </section>
      <section className={Style.customContainer}>
        <div className={Style.clientsLogo}>
          <img
            src={atlassianImage}
            alt="logo"
            className="img-fluid wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          />
          <img
            src={slackImage}
            alt="logo"
            className="img-fluid wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="1s"
          />
          <img
            src={dropboxImage}
            alt="logo"
            className="img-fluid wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="1.5s"
          />
          <img
            src={shopifyImage}
            alt="logo"
            className="img-fluid wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="2s"
          />
          <img
            src={googleImage}
            alt="logo"
            className="img-fluid wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="2.5s"
          />
        </div>
      </section>
      <section className={`${Style.container} ${Style.pt200}`}>
        <div className="row">
          <div className="col-md-6">
            <img src={hasstleImage} alt="hasstle" className="img-fluid" />
          </div>
          <div className={`col-md-6 ${Style.noAgentsWrapper}`}>
            <h3
              className={`wow fadeInUp ${Style.noAgentsHeading} ${Style.h3}`}
              data-wow-duration="1s"
              data-wow-delay="0.6s"
            >
              No Agents
              <br />
              No Hassle
            </h3>
            <p
              className={`mb-0 wow fadeInUp ${Style.pgText} ${Style.fontSixteen}`}
              data-wow-duration="1s"
              data-wow-delay="0.8s"
            >
              Become your own agent and control your own schedule. Connect directly with Casting agents and submit for
              show quick and fast.
            </p>
          </div>
        </div>
      </section>
      <section className={`${Style.pt200} ${Style.costConfirmWrapper}`}>
        <div className={Style.container}>
          <div className="row">
            <div className="col-md-6">
              <h3
                className={`text-uppercase wow fadeInUp ${Style.h3} ${Style.noAgentsHeading}`}
                data-wow-duration="1s"
                data-wow-delay="0.6s"
              >
                Automating the Casting Process
              </h3>
              <p
                className={`mb-0 wow fadeInUp ${Style.pgText} ${Style.fontSixteen}`}
                data-wow-duration="1s"
                data-wow-delay="0.8s"
              >
                The key casting process has gotten much easier, organized and adaptive with CastingPAX. Through our
                features, we simplify tasks that would of once required a team:
              </p>
              <div className="costConfirmSection">
                <Accordion>
                  {accordionCardItem.map((item) => (
                    <AccordionItem title={item.title} description={item.description} eventKey={item.eventKey} />
                  ))}
                </Accordion>
              </div>
            </div>
            <div className="col-md-6">
              <img src={costConfirmImage} alt="cost img" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <section className={Style.confirmRequestWrapper}>
        <div className={Style.container}>
          <div className="row">
            <div className="col-md-6">
              <img src={castingImage} alt="go-fast-img" className="img-fluid" />
            </div>
            <div className={`col-md-6 ${Style.goFastText}`}>
              <h3 className={`${Style.h3} wow fadeInUp`} data-wow-duration="1s" data-wow-delay="0.6s">
                Reduce Work - Go Fast
              </h3>
              <p
                className={`mt30 text-black wow fadeInUp ${Style.fontSixteen} ${Style.pgText}`}
                data-wow-duration="1s"
                data-wow-delay="0.8s"
              >
                With CastingPAX, Call time emails, Extra breakdowns, bg coordinator management, rate and category
                planning are all made simplified and so much more.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={Style.pt100}>
        <h3 className={`text-center w-100 wow zoomIn ${Style.h3}`} data-wow-duration="1s" data-wow-delay="0.6s">
          Cost Benefits of CastingPAX
        </h3>
        <div className={Style.cardBox}>
          <div
            className={`${Style.boxInner} ${Style.grayBox} wow fadeInUp`}
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <div className={`${Style.logoWrapper} ${Style.grayBox}`}>
              <img src={dollarImage} alt="cost-img" />
            </div>
            <span className={`mb-0 ${Style.description}`}>Low Cost</span>
          </div>
          <div
            className={`${Style.boxInner} ${Style.greenBox} wow fadeInUp`}
            data-wow-duration="1s"
            data-wow-delay="1s"
          >
            <div className={`${Style.logoWrapper} ${Style.greenBox}`}>
              <img src={infoImage} alt="cost-img" />
            </div>
            <span className={`mb-0 ${Style.description}`}>work reduction</span>
          </div>
          <div
            className={`${Style.boxInner} ${Style.blueBox} wow fadeInUp`}
            data-wow-duration="1s"
            data-wow-delay="1.5s"
          >
            <div className={`${Style.logoWrapper} ${Style.blueBox}`}>
              <img src={budgetImage} alt="cost-img" />
            </div>
            <span className={`mb-0 ${Style.description}`}>eco friendly e-vouchers</span>
          </div>
          <div className={`${Style.boxInner} ${Style.redBox} wow fadeInUp`} data-wow-duration="1s" data-wow-delay="2s">
            <div className={`${Style.logoWrapper} ${Style.redBox}`}>
              <img src={visualsImage} alt="cost-img" />
            </div>
            <span className={`mb-0 ${Style.description}`}>Automated tasks & tools</span>
          </div>
        </div>
      </section>
      <footer className={Style.mt100}>
        <section className={Style.container}>
          <div className="row">
            <div className={`col-lg-12 ${Style.pt50}`}>
              <a href="/" className="d-inline-block">
                <img src={logoSvg} alt="logo" className={`${Style.logoImg}`} />
              </a>
            </div>
            <div className={`col-lg-4 ${Style.pt50}`}>
              <div className="pb-3">
                <a href="tel:+1 (2345) 678-90-12" className="text-decoration-none text-white font-twenty">
                  604.833.2265
                </a>
              </div>
              <div className="pb-3">
                <a
                  href="mailto:support@personal.com"
                  className={`text-decoration-none text-white ${Style.fontSixteen}`}
                >
                  support@castingpax.com
                </a>
              </div>
            </div>
            {/* <div className={`col-lg-2 ${Style.pt50}`}> */}
            {/*  <div /> */}
            {/*  <div className="pb-3"> */}
            {/*    <a href="/" className="text-decoration-none text-white font-twenty"> */}
            {/*      About */}
            {/*    </a> */}
            {/*  </div> */}
            {/*  <div className="pb-3"> */}
            {/*    <a href="/" className={`text-decoration-none text-white ${Style.fontSixteen}`}> */}
            {/*      Profile */}
            {/*    </a> */}
            {/*  </div> */}
            {/* </div> */}
            <div className={`col-lg-3 ${Style.pt50}`}>
              <div className="pb-3">
                <a href="/" className="text-decoration-none text-white font-twenty">
                  Features
                </a>
              </div>
              <div className="pb-3">
                <a href="/" className="text-decoration-none text-white font-twenty">
                  Blog
                </a>
              </div>
              <div className="pb-3">
                <a href="/" className="text-decoration-none text-white font-twenty">
                  Pricing
                </a>
              </div>
              <div className="pb-3">
                <a href="/" className={`text-decoration-none text-white ${Style.fontSixteen} `}>
                  Contact
                </a>
              </div>
            </div>
            <div className={`col-lg-5 ${Style.pt50}`}>
              <form>
                <div className={`form-group d-flex ${Style.featureFormWrapper}`}>
                  <input
                    type="email"
                    placeholder="Simplify Background casting now!"
                    className={`${Style.textBlack} form-control
                ${Style.fontSixteen} ${Style.inputStyle} `}
                  />
                  <button
                    type="button"
                    className={`btn ${Style.signupBtnFilled} text-white ${Style.fontSixteen}
                ${Style.subscribeBtn}`}
                  >
                    Sign Up
                  </button>
                </div>
              </form>
            </div>
          </div>
          <hr className={`w-100 ${Style.horizontalLine}`} />
          <div className="footer-bottom d-flex justify-content-between align-items-center">
            <div className={`d-flex align-items-center ${Style.columnGap10}`}>
              <a href="facebook" className={`text-decoration-none ${Style.iconWrapper}`}>
                <i className="fab fa-facebook" />
              </a>
              <a href="facebook" className={`text-decoration-none ${Style.iconWrapper}`}>
                <i className="fab fa-twitter" />
              </a>
              <a href="facebook" className={`text-decoration-none ${Style.iconWrapper}`}>
                <i className="fab fa-instagram" />
              </a>
            </div>
            <p className={`mb-0 ${Style.reservedText} ${Style.fontSixteen} font-weight-normal`}>
              © 2022 &nbsp;
              <a href="/" className={`${Style.reservedText} text-decoration-none`}>
                BCPAX productions Services ltd. &nbsp;
              </a>
              All rights reserved.
            </p>
          </div>
        </section>
      </footer>
    </div>
  );
};

export default Features;
