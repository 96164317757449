import { IIDS } from 'modules/params/param.type';
import { apiCall } from 'store/services/service';
import { URL_NOTIFICATIONS, URL_PROFILE_SETTING, URL_NOTIFICATIONS_READ } from 'store/services/URL';

export const generalApi = {
  fetchGeneralSetting() {
    return apiCall.get({ url: URL_PROFILE_SETTING, isAuthToken: false, params: {} });
  },

  fetchNotifications() {
    return apiCall.get({ url: URL_NOTIFICATIONS, isAuthToken: false, params: {} });
  },
  markReadNotification(params: IIDS) {
    return apiCall.post({ url: URL_NOTIFICATIONS_READ, isAuthToken: false, params });
  },
};

export default generalApi;
