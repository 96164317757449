import React, { FunctionComponent } from 'react';
// import Style from 'containers/MainContainer/MainContainer.module.css';

interface MainContainerProps {
  cssClass?: string;
}
const MainContainer: FunctionComponent<MainContainerProps> = (props) => {
  const { children, cssClass } = props;
  return (
    <>
      <div className={cssClass}>{children}</div>
    </>
  );
};

export default MainContainer;
