/* eslint-disable import/prefer-default-export */
// import UserIcon from 'assets/images/user.png';
import voucherIcon from 'assets/images/voucherIcon.svg';
// import DollarIcon from 'assets/images/dollarIcon.svg';
import VoucherManagementDashboard from '../VoucherManagement/VoucherManagementPage.component';
import { SideNavDataInterface } from '../Interface';
import MainDashboardIcon from '../../../assets/images/guage.svg';
import VoucherManageMentIcon from '../../../assets/svg/sheet-folded.svg';
import UserManagementIcon from '../../../assets/images/userNav.svg';
import RateManageMentIcon from '../../../assets/svg/dollar-circle.svg';

const VoucherManagementSideNavItemData: SideNavDataInterface = {
  // SideNavMenuItems: [
  //   {
  //     title: 'Dashboard',
  //     icon: DashboardIcon,
  //     link: '/payroll/home',
  //   },
  //   {
  //     title: 'Voucher Management',
  //     icon: voucherIcon,
  //     link: '/payroll/voucher-management',
  //   },
  //   {
  //     title: 'User Management',
  //     icon: UserIcon,
  //     link: '/payroll/user-management',
  //   },
  //   {
  //     title: 'Rate Management',
  //     icon: DollarIcon,
  //     link: '/payroll/rate-management',
  //   },
  // ],
  SideNavMenuItems: [
    {
      icon: MainDashboardIcon,
      title: 'Dashboard',
      link: '/payroll/home',
    },
    {
      icon: VoucherManageMentIcon,
      title: 'Voucher Management',
      link: '/payroll/voucher-management',
    },
    {
      icon: UserManagementIcon,
      title: 'User Management',
      link: '/payroll/user-management',
    },
    {
      icon: RateManageMentIcon,
      title: 'Rate Management',
      link: '/payroll/rate-management',
    },
  ],
  Component: VoucherManagementDashboard,
};

export { VoucherManagementSideNavItemData };
