import { connect } from 'react-redux';
import CastingRequest from 'components/bgPerformer/CastingRequest/CastingRequest';
import { getBookingRequest, getCastingRequest, getCastingRequestLoading } from 'store/selector/job.selector';
import { Show } from 'modules/Show/show.types';
import { acceptCastingRequestBegin, castingRequestBegin, openJobDetails } from 'store/actions/job.actions';

const mapStateToProps = (state: Show) => {
  const castingRequest = getCastingRequest(state);
  const loading = getCastingRequestLoading(state);
  const allBookings = getBookingRequest(state);
  return { castingRequest, loading, allBookings };
};

const mapDispatchToProps = {
  castingRequestBegin,
  openJobDetails,
  acceptCastingRequestBegin,
};

export default connect(mapStateToProps, mapDispatchToProps)(CastingRequest);
