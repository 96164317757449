import styled from 'styled-components';

interface Props {
  borderRadiusRight?: boolean;
}

const StyledMenuTab = styled.div<Props>`
  height: 60px;
  width: 171px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: ${({ borderRadiusRight }) => (borderRadiusRight ? '0% 8px 8px 0%;' : 'none')};
  background: ${({ theme }) => theme.colors.white};

  button {
    width: 15px;
    height: 50px;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 50px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.secondaryColor};
  }
`;

export default StyledMenuTab;
