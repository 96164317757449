import React, { FunctionComponent, useRef, useState } from 'react';
import imageIcon from 'assets/svg/image_icon.svg';
import pdfIcon from 'assets/svg/pdf_icon.svg';
import downloadarrow from 'assets/svg/download-arrow.svg';
import excelIcon from 'assets/svg/xls.svg';
import docIcon from 'assets/svg/doc_icon.svg';
import deleteIcon from 'assets/svg/close.svg';
import showApi from 'store/services/show.services';
import InputStyle from 'components/controls/fileInput/imageInput.module.css';
import Button from 'components/controls/button/button';
import Loader from 'components/controls/Loader/Loader';
import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';
import { getPaths } from 'store/selector/general.selector';
import { Show } from 'modules/Show/show.types';
import { connect } from 'react-redux';

interface FileUploaderProps {
  deleteUpdateFile?: Function;
  update?: boolean;
  handlefileUploader: Function;
  attachements?: string[];
  paths?: any;
  multiple?: boolean;
}

const FileUploader: FunctionComponent<FileUploaderProps> = (props) => {
  const { handlefileUploader, attachements, update, deleteUpdateFile, paths, multiple } = props;
  const inputFile = useRef<HTMLInputElement>(null);
  const [currentFileName, setCurrentFileName] = useState<string>('');
  const [fileName, setFileName] = useState<string[]>(attachements || []);
  const [loader, setLoader] = useState<boolean>(false);
  const [currentDeleteFile, setCurrentDeleteFile] = useState<number>();
  const [fileError, setFileError] = useState('');

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileError('');
    const selectedFiles = e.target.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const formData = new FormData();

      for (let i = 0; i < selectedFiles.length; i += 1) {
        formData.append('files', selectedFiles[i]);
      }

      e.target.value = '';
      fileUpload(formData);
    }
  };

  async function fileUpload(d: any) {
    setLoader(true);
    await showApi
      .uploadShowFile({ params: d })
      .then((res) => {
        if (res[0]) {
          fileName.push(res[0]);
          setFileName([...fileName]);
          setCurrentFileName('');
          setLoader(false);
          handlefileUploader([...fileName]);
        }
      })
      .catch((err) => {
        setFileError(err.message);
        setLoader(false);
        setCurrentFileName('');
      });
  }

  async function fileRemove(d: string[], index: number) {
    setFileError('');
    await showApi
      .removeShowFile({ fileName: d })
      .then((res) => {
        if (res.data) {
          const tmp: string[] = fileName;
          tmp.splice(index, 1);
          setFileName([...tmp]);
          setCurrentDeleteFile(-1);
          handlefileUploader([...tmp]);
        }
      })
      .catch((err) => {
        setFileError(err.message);
        setCurrentDeleteFile(-1);
      });
  }

  const changeIcon = (f: string) => {
    const type: any = /[.]/.exec(f) ? /[^.]+$/.exec(f) : undefined;

    if (type[0] === 'png' || type[0] === 'jpg' || type[0] === 'jpeg') return imageIcon;
    if (type[0] === 'pdf') return pdfIcon;
    if (type[0] === 'xls' || type[0] === 'xlsx') return excelIcon;
    if (type[0] === 'doc' || type[0] === 'docx') return docIcon;
    return '';
  };

  const handleDelete = (index: number) => {
    setCurrentDeleteFile(index);
    if (!fileName && !update) return;
    console.log('index', index);
    const tmp: string[] = fileName;
    const obj: any = tmp.filter((f: string) => f === tmp[index]);
    if (update) {
      if (deleteUpdateFile) {
        tmp.splice(index, 1);
        setFileName([...tmp]);
        deleteUpdateFile(obj[0]);
        setCurrentDeleteFile(-1);
      }
    } else fileRemove(obj, index);
  };

  const splitFileName = (f: string) => {
    let str = f;
    let index = str.indexOf('-');
    str = str.substring(index + 1, str.length);
    index = str.indexOf('-');
    str = str.substring(index + 1, str.length);
    return str;
  };

  const path = () => {
    if (paths) {
      return paths.templateAttachements;
    }
    return '';
  };

  return (
    <>
      <div className={InputStyle.fileInputWrapper}>
        <div className="d-flex align-items-center">
          <Button
            showLoading={loader}
            label="Choose File"
            clickHandler={() => {
              if (inputFile.current) {
                inputFile.current.click();
              }
            }}
          />
          {fileError ? (
            <TextErrorMessage message={fileError} />
          ) : (
            <span className="ml-2">{currentFileName.length > 0 ? currentFileName : 'No file choosen'}</span>
          )}
        </div>
        <input
          ref={inputFile}
          type="file"
          onChange={onFileChange}
          className={InputStyle.displayNone}
          multiple
          accept=".pdf, .doc, .docx, .xls, .xlsx"
        />
      </div>
      <div className="d-flex justify-content-start flex-wrap">
        {fileName[0] &&
          fileName.map((f: string, index: number) => (
            <div key={index} className={InputStyle.previewFiles}>
              {currentDeleteFile === index && <Loader isSmallView />}
              <img src={changeIcon(f)} alt="typeicon" style={{ width: '20px' }} />
              <div className={InputStyle.imageName}>{splitFileName(f)}</div>
              <a href={`${path()}${f}`} download>
                <img src={downloadarrow} alt="downloadFile" />
              </a>
              <img
                src={deleteIcon}
                alt="deleteimage"
                onClick={() => handleDelete(index)}
                onKeyPress={() => handleDelete(index)}
                className={InputStyle.imagePreview}
                role="presentation"
              />
            </div>
          ))}
      </div>
    </>
  );
};

const mapStateToProps = (state: Show) => {
  const paths = getPaths(state);
  return {
    paths,
  };
};

export default connect(mapStateToProps, null)(FileUploader);
