import { connect } from 'react-redux';
import { Show } from 'modules/Show/show.types';
import {
  getShowListingRequest,
  setDatesForFitler,
  clearMatrixRedirected,
  setSelectShow,
  showDetailBegin,
  showEditRequest,
  setShowRedirect,
} from 'store/actions/show.actions';
import {
  getSelectedShowId,
  getShowForEdit,
  getShowDetail,
  getShowLists,
  showLoading,
  redirectedMatrix,
  getShowToOpen,
} from 'store/selector/show.selector';
import ShowTopNavigation from 'components/ShowTopNavigation/ShowTopNavigation';
import { getUser } from 'store/selector/auth.selector';

const mapStateToProps = (state: Show) => {
  const showList = getShowLists(state);
  const selectedShowId = getSelectedShowId(state);
  const showEdit = getShowForEdit(state);
  const showDetail = getShowDetail(state);
  const loading = showLoading(state);
  const user = getUser(state);
  const redirectedMatrixInfo = redirectedMatrix(state);
  const showToOpen = getShowToOpen(state);
  return {
    showList,
    selectedShowId,
    showEdit,
    showDetail,
    loading,
    user,
    redirectedMatrixInfo,
    showToOpen,
  };
};

const mapDispatchToProps = {
  setSelectedShow: setSelectShow,
  getShowListing: getShowListingRequest,
  showEditRequest,
  showDetailBegin,
  clearMatrixRedirected,
  setDatesForFitler,
  setShowRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowTopNavigation);
