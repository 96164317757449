import React from 'react';
import styled from 'styled-components';
import logo from 'assets/svg/save-green.svg';

interface Props {}

const SaveButton: React.FC<Props> = () => (
  <StyledSaveButton>
    <img src={logo} alt="" />
  </StyledSaveButton>
);

export default SaveButton;

const StyledSaveButton = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  background: #49d36019;
  & > img {
    height: 18px;
    width: 18px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
