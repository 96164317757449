import React from 'react';
import Styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Search = () => (
  <Container>
    <StyledSearch icon={faSearch} />
    <SearcInput placeholder="Show/Producer" type="text" />
  </Container>
);

const Container = Styled.div`
display:flex;
width:100%;
height:50px;
padding:10px;
align-items:center;
border-bottom:1px solid #e6e6e6;
`;

// const SearchContainer = Styled.div`
// width: 300px;
// display: flex;
// height: 40px;
// flex-direction: row;
// align-items: center;
// border: .5px solid #ccc;
// border-radius: 25px;
// padding: 15px;
//  `;

const StyledSearch = Styled(FontAwesomeIcon)`
color: #828282;
font-weight: 300    ;
`;
const SearcInput = Styled.input`
width: 100%;
border-radius: 10px;
padding: 2px;
border: 0px;
color: #828282;
margin-left: 10px;
font-family: 'Font Awesome 5 Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 14px;
&:focus {
border: none;
outline: none;
}
`;

export default Search;
