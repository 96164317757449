import React, { FunctionComponent, useEffect, useState } from 'react';
import { IPersonalNote } from 'modules/params/param.type';
import PerformerProfileStyle from 'components/PerformerProfile/PerformerProfile.module.css';
import { Tab, Nav } from 'react-bootstrap';
import DashboardTextarea from 'components/controls/textArea/dashbaordTextarea/dashboardTextarea';
import api from 'store/services/myPerformer.services';
import { useDispatch, useSelector } from 'react-redux';
import { setPerformerPersonalNote } from 'store/actions/myPerformer.actions';
import { getUser } from 'store/selector/auth.selector';

interface MessageSectionProps {
  performerId?: string;
  setNote: Function;
  personalNote: string;
}
const MessageSection: FunctionComponent<MessageSectionProps> = (props) => {
  const { performerId, setNote, personalNote } = props;
  const [directorNote, setDirectorNote] = useState<string>(personalNote);
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  useEffect(() => {
    const timeOutId = setTimeout(() => savePersonalNote(directorNote), 500);
    return () => clearTimeout(timeOutId);
  }, [directorNote]);

  const savePersonalNote = (text: string) => {
    if (text === personalNote) return;

    const params: IPersonalNote = {
      userId: performerId || '',
      note: text,
      directorId: user.id,
    };
    api.savePersonalNote(params).catch((err) => err);
    dispatch(setPerformerPersonalNote(params));
  };

  const textAreaChangeHandler = (event: React.FormEvent<HTMLTextAreaElement>, stateFun: Function) => {
    stateFun(event.currentTarget.value);
  };

  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="row fullProfileDetailsWrapper">
          <div className={`col-sm-12 ${PerformerProfileStyle.pillsWrapper}`}>
            <Nav variant="pills" className={PerformerProfileStyle.navPills}>
              <Nav.Item className={PerformerProfileStyle.navItem}>
                <Nav.Link
                  className={`${PerformerProfileStyle.navLink}  ${PerformerProfileStyle.navLinkFirstItem}`}
                  eventKey="first"
                >
                  Message
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={PerformerProfileStyle.navItem}>
                <Nav.Link
                  className={`${PerformerProfileStyle.navLink} ${PerformerProfileStyle.navLinkSecondItem}`}
                  eventKey="second"
                >
                  Note
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className={`col-sm-12 ${PerformerProfileStyle.tabContentWrapper}`}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <DashboardTextarea
                  cssClass="mb-0"
                  inputChangeHandler={(e: React.FormEvent<HTMLTextAreaElement>) => textAreaChangeHandler(e, setNote)}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <DashboardTextarea
                  cssClass="mb-0"
                  value={directorNote}
                  inputChangeHandler={(event: React.FormEvent<HTMLTextAreaElement>) => {
                    setDirectorNote(event.currentTarget.value);
                  }}
                />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </>
  );
};

export default MessageSection;
