import { fromJS } from 'immutable';
import { BookingRequest, CalendarItem, JobSearchFilterItem } from 'modules/jobs/types';
import { JobBookingIds, ShowDayJob, ShowJob } from 'modules/Show/show.types';
import ACTIONS, {
  JOB_STATUS_BOOKED,
  JOB_STATUS_CANCELLED,
  JOB_STATUS_PENDING,
  SHOW_JOB_STATUS,
} from 'store/castingPax.constants';

const initialState = fromJS({
  castingRequest: {},
  booking: {},
  selectedJob: {},
  calls: {},
  updatedNews: {},
  loading: false,
  searchJobs: {},
  availableDates: {},
  selectedBooking: '',
  jobSearchFilter: [],
  workedDays: {},
  requestLoading: false,
});

export default function jobReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.FETCH_CASTING_REQUEST.PENDING: {
      return state.setIn(['castingRequest', 'loading'], action.status === 'LOADING');
    }
    case ACTIONS.FETCH_CASTING_REQUEST.SUCCESS: {
      return state
        .setIn(['castingRequest', 'loading'], false)
        .setIn(['castingRequest', 'error'], '')
        .setIn(['castingRequest', 'data'], action.data);
    }
    case ACTIONS.FETCH_CASTING_REQUEST.ERROR: {
      return state.setIn(['castingRequest', 'loading'], false).setIn(['castingRequest', 'error'], '');
    }
    case ACTIONS.FETCH_BOOKING_REQUEST.PENDING: {
      return state.setIn(['booking', 'loading'], action.status === 'LOADING');
    }
    case ACTIONS.FETCH_BOOKING_REQUEST.SUCCESS: {
      return state
        .setIn(['booking', 'loading'], false)
        .setIn(['booking', 'error'], '')
        .setIn(['booking', 'data'], [...action.data]);
    }
    case ACTIONS.FETCH_BOOKING_REQUEST.ERROR: {
      return state.setIn(['booking', 'loading'], false).setIn(['booking', 'error'], '');
    }
    case ACTIONS.FETCH_CALENDAR_REQUEST.PENDING: {
      return state.setIn(['availableDates', 'loading'], action.status === 'LOADING');
    }
    case ACTIONS.FETCH_CALENDAR_REQUEST.SUCCESS: {
      return state
        .setIn(['availableDates', 'loading'], false)
        .setIn(['availableDates', 'error'], '')
        .setIn(['availableDates', 'data'], action.data);
    }
    case ACTIONS.FETCH_CALENDAR_REQUEST.ERROR: {
      return state.setIn(['availableDates', 'loading'], false).setIn(['availableDates', 'error'], '');
    }
    case ACTIONS.SET_JOB_FOR_VIEW: {
      return state.set('selectedJob', { ...action.data }).set('selectedBooking', action.bookingData);
    }
    case ACTIONS.CLOSE_JOB_FOR_VIEW: {
      return state.set('selectedJob', null).set('selectedBooking', null);
    }
    case ACTIONS.FETCH_CASTING_CALL_REQUEST.PENDING: {
      if (action.data === 1) {
        return state
          .setIn(['calls', 'loading'], action.status === 'LOADING')
          .setIn(['calls', 'data'], [])
          .setIn(['pagination', {}]);
      }
      return state.setIn(['calls', 'loading'], action.status === 'LOADING');
    }
    case ACTIONS.FETCH_CASTING_CALL_REQUEST.SUCCESS: {
      const prev = state.getIn(['calls', 'data']) || [];
      return state
        .setIn(['calls', 'loading'], false)
        .setIn(['calls', 'error'], '')
        .setIn(['calls', 'pagination'], action.pagination)
        .setIn(
          ['calls', 'data'],
          prev.length === 0 || action.pagination.page === 1 ? action.data : [...prev, ...action.data],
        );
    }
    case ACTIONS.FETCH_CASTING_CALL_REQUEST.ERROR: {
      return state.setIn(['calls', 'loading'], false).setIn(['calls', 'error'], '');
    }
    case ACTIONS.FETCH_CASTING_UPDATE_NEWS.PENDING: {
      return state.setIn(['updatedNews', 'loading'], action.status === 'LOADING');
    }
    case ACTIONS.FETCH_CASTING_UPDATE_NEWS.SUCCESS: {
      return state
        .setIn(['updatedNews', 'loading'], false)
        .setIn(['updatedNews', 'error'], '')
        .setIn(['updatedNews', 'data'], action.data);
    }
    case ACTIONS.FETCH_CASTING_UPDATE_NEWS.ERROR: {
      return state.setIn(['updatedNews', 'loading'], false).setIn(['updatedNews', 'error'], '');
    }

    case ACTIONS.CALL_JOB_REQUEST.PENDING: {
      return state.set('loading', true);
    }
    case ACTIONS.CALL_JOB_REQUEST.ERROR: {
      return state.set('loading', false);
    }
    case ACTIONS.SET_CANCEL_BOOKING.PENDING: {
      return state.set('requestLoading', true);
    }
    case ACTIONS.SET_CANCEL_BOOKING.ERROR: {
      return state.set('requestLoading', false);
    }
    case ACTIONS.SET_CANCEL_BOOKING_LOCALLY:
    case ACTIONS.SET_CANCEL_BOOKING.SUCCESS: {
      const prev = state.getIn(['booking', 'data']) || [];
      const castingReq = state.getIn(['castingRequest', 'data']) || [];

      if (prev && prev.length > 0) {
        if (action.data.id) {
          const index = prev.findIndex((j: BookingRequest) => j.id === action.data.id);
          if (index !== -1) prev.splice(index, 1);
        }
        if (action.data.jobId) {
          const index = prev.findIndex((j: BookingRequest) => j.jobId.id === action.data.jobId);
          if (index !== -1) prev.splice(index, 1);
        }
      }

      if (castingReq && castingReq.length > 0) {
        if (action.data.id) {
          const index = castingReq.findIndex((j: BookingRequest) => j.id === action.data.id);
          if (index !== -1) castingReq.splice(index, 1);
        }
        if (action.data.jobId) {
          const index = castingReq.findIndex((j: BookingRequest) => j.jobId.id === action.data.jobId);
          if (index !== -1) castingReq.splice(index, 1);
        }
      }
      return state.setIn(['booking', 'data'], [...prev]).setIn(['castingRequest', 'data'], [...castingReq]);
    }
    case ACTIONS.SUBMIT_JOB_REQUEST.PENDING: {
      return state.set('requestLoading', true);
    }
    case ACTIONS.SUBMIT_JOB_REQUEST.ERROR: {
      return state.set('requestLoading', false);
    }
    case ACTIONS.SUBMIT_JOB_REQUEST.SUCCESS: {
      const prevBooking = state.getIn(['booking', 'data']) || [];
      const index = prevBooking.findIndex((x: BookingRequest) => x.jobId.id === action.data.jobId.id);
      if (index !== -1) {
        prevBooking.splice(index, 1);
      }
      return state.setIn(['booking', 'data'], [...prevBooking, action.data]).set('requestLoading', false);
    }
    case ACTIONS.SUBMIT_JOB_REQUEST_LOCALLY: {
      const prevBooking = state.getIn(['booking', 'data']) || [];
      const index = prevBooking.findIndex((x: BookingRequest) => x.jobId.id === action.data);
      if (index !== -1) {
        prevBooking.splice(index, 1);
      }
      const castingCall = state.getIn(['calls', 'data']) || [];
      const pos = castingCall.findIndex((x: ShowDayJob) => x.id === action.data);
      if (pos !== -1) {
        const callObj = castingCall[pos];
        return state
          .setIn(
            ['booking', 'data'],
            [
              ...prevBooking,
              {
                id: Math.random().toString(),
                dates: callObj.dates,
                lastDate: callObj.date,
                jobId: callObj,
                status: JOB_STATUS_PENDING,
              },
            ],
          )
          .set('selectedJob', null)
          .set('selectedBooking', null);
      }
      return state;
    }
    case ACTIONS.SET_ACCEPT_CASTING_REQUEST_LOCALLY: {
      const prev = state.getIn(['castingRequest', 'data']) || [];
      const prevBooking = state.getIn(['booking', 'data']) || [];

      const prevCastingRequest = prev.filter((x: BookingRequest) => x.id !== action.data.id);

      if (prevBooking && prevBooking.length > 0) {
        const pos = prevBooking.findIndex((x: BookingRequest) => x.id === action.data.id);
        if (pos !== -1) {
          if (action.data.status === JOB_STATUS_CANCELLED || action.data.token) {
            prevBooking.splice(pos, 1);
          } else if (action.data.status === JOB_STATUS_BOOKED) {
            prevBooking[pos].status = JOB_STATUS_BOOKED;
          }
        }
      }

      return state
        .setIn(['booking', 'data'], [...prevBooking])
        .setIn(['castingRequest', 'data'], [...prevCastingRequest])
        .set('selectedJob', null)
        .set('selectedBooking', null);
    }
    case ACTIONS.SET_ACCEPT_CASTING_REQUEST.PENDING: {
      return state.set('requestLoading', true);
    }
    case ACTIONS.SET_ACCEPT_CASTING_REQUEST.ERROR: {
      return state.set('requestLoading', false);
    }
    case ACTIONS.SET_ACCEPT_CASTING_REQUEST.SUCCESS: {
      let prev = state.getIn(['castingRequest', 'data']) || [];
      let prevBooking = state.getIn(['booking', 'data']) || [];

      prev = prev.filter((x: BookingRequest) => x.id !== action.data.id);

      if (prevBooking && prevBooking.length > 0) {
        const pos = prevBooking.findIndex((x: BookingRequest) => x.id === action.data.id);
        if (pos !== -1) {
          if (action.data.status === JOB_STATUS_CANCELLED || action.data.token) {
            prevBooking.splice(pos, 1, prevBooking[pos]);
          } else if (action.data.status === JOB_STATUS_BOOKED) {
            prevBooking[pos].status = JOB_STATUS_BOOKED;
          }
        }
      }
      if (action.data.cancelJobIds) {
        const cancelIds = action.data.cancelJobIds;
        if (prevBooking && prevBooking.length > 0) {
          prevBooking = prevBooking.filter((x: BookingRequest) => !cancelIds.includes(x.id));
        }
        prev = prev.filter((x: BookingRequest) => !cancelIds.includes(x.id));
      }

      return state
        .setIn(['booking', 'data'], [...prevBooking])
        .setIn(['castingRequest', 'data'], [...prev])
        .set('selectedJob', null)
        .set('selectedBooking', null)
        .set('requestLoading', false);
    }

    case ACTIONS.CALL_JOB_REQUEST.SUCCESS: {
      return state.set('loading', false);
    }
    case ACTIONS.ADD_NEW_BOOKING: {
      const prev = state.getIn(['booking', 'data']) || [];
      const newJob = action.data;
      const index = prev.findIndex((x: BookingRequest) => x.id === newJob.id);
      if (index === -1) return state.setIn(['booking', 'data'], [...prev, action.data]);
      return state.setIn(['booking', 'data'], [...prev]);
    }
    case ACTIONS.ADD_NEW_CASTING_REQUEST: {
      const prevBooking = state.getIn(['booking', 'data']) || [];
      const prevCasting = state.getIn(['castingRequest', 'data']) || [];
      const newJob = action.data;
      const filterCasting = prevCasting.filter((x: BookingRequest) => x.id !== newJob.id);
      const filterBookings = prevBooking.filter((x: BookingRequest) => x.id !== newJob.id);
      return state
        .setIn(['castingRequest', 'data'], [...filterCasting, newJob])
        .setIn(['booking', 'data'], [...filterBookings, newJob]);
    }
    case ACTIONS.ADD_NEW_CASTING_CALL: {
      const prev = state.getIn(['calls', 'data']) || [];
      const newJob = action.data;
      const index = prev.findIndex((x: ShowDayJob) => x.id === newJob.id);
      if (index === -1) return state.setIn(['calls', 'data'], [...prev, action.data]);
      return state.setIn(['calls', 'data'], [...prev]);
    }
    case ACTIONS.ADD_NEW_JOB_UPDATE_MESSAGE: {
      const prevBooking = state.getIn(['booking', 'data']) || [];
      if (prevBooking && prevBooking.length > 0) {
        const index = prevBooking.findIndex((x: BookingRequest) => x.id === action.data.id);
        if (index !== -1) {
          prevBooking.splice(index, 1);
        }
      }
      const prev = state.getIn(['updatedNews', 'data']) || [];
      if (prev && prev.length > 0) {
        const index = prev.findIndex((j: BookingRequest) => j.id === action.data.id);
        if (index !== -1) prev.splice(index, 1);
      }
      const jobData: ShowJob = action.data.jobId;
      if (jobData.status === SHOW_JOB_STATUS.INVITE && action.data.status === JOB_STATUS_PENDING) {
        return state.setIn(['updatedNews', 'data'], [...prev]).setIn(['booking', 'data'], [...prevBooking]);
      }
      return state
        .setIn(['updatedNews', 'data'], [...prev, action.data])
        .setIn(['booking', 'data'], [...prevBooking, action.data]);
    }

    case ACTIONS.DELETE_JOB_MESSAGE: {
      const prevBooking = state.getIn(['booking', 'data']) || [];
      if (prevBooking && prevBooking.length > 0) {
        const index = prevBooking.findIndex((x: BookingRequest) => x.jobId.id === action.data.id);
        if (index !== -1) {
          prevBooking.splice(index, 1);
        }
      }
      return state.setIn(['booking', 'data'], [...prevBooking]);
    }

    case ACTIONS.CLOSE_JOB_MESSAGE: {
      const prevBooking = state.getIn(['booking', 'data']) || [];
      if (prevBooking && prevBooking.length > 0) {
        const index = prevBooking.findIndex((x: BookingRequest) => x.jobId.id === action.data.jobId.id);
        if (index !== -1) {
          prevBooking.splice(index, 1);
        }
      }
      if (action.data.status === JOB_STATUS_PENDING) {
        return state.setIn(['booking', 'data'], [...prevBooking]);
      }
      return state.setIn(['booking', 'data'], [...prevBooking, action.data]);
    }

    case ACTIONS.SET_JOB_UPDATE_STATUS_READ.SUCCESS: {
      const prev = state.getIn(['updatedNews', 'data']);
      const index = prev.findIndex((j: BookingRequest) => j.id === action.data.id);
      if (index !== -1) {
        const obj = prev[index];
        obj.isUpdateRead = true;
        prev.splice(index, 1, { ...obj });
      }
      return state.setIn(['updatedNews', 'data'], [...prev]);
    }

    case ACTIONS.SET_SEARCH_JOBS.PENDING: {
      return state.setIn(['searchJobs', 'loading'], action.status === 'LOADING');
    }
    case ACTIONS.SET_SEARCH_JOBS.SUCCESS: {
      return state
        .setIn(['searchJobs', 'loading'], false)
        .setIn(['searchJobs', 'error'], '')
        .setIn(['searchJobs', 'sort'], 1)
        .setIn(['searchJobs', 'pagination'], action.pagination)
        .setIn(['searchJobs', 'data'], action.data);
    }
    case ACTIONS.SET_SEARCH_JOBS.ERROR: {
      return state.setIn(['searchJobs', 'loading'], false).setIn(['searchJobs', 'error'], '');
    }
    case ACTIONS.SORT_JOB_RESULT_ORDER: {
      const prevVal: number = state.getIn(['searchJobs', 'sort']) || 1;
      if (action.data !== prevVal) return state.setIn(['searchJobs', 'sort'], action.data);
      return state;
    }

    case ACTIONS.UPDATE_CALENDAR_STATUS_LOCAL: {
      const prev = state.getIn(['availableDates', 'data'] || []);
      const obj: CalendarItem = {
        id: Math.random().toString(),
        workDate: action.data.workDate,
        workStatus: action.data.workStatus,
      };

      const filterRecord = prev ? prev.filter((x: CalendarItem) => x.workDate !== action.data.workDate) : [];
      return state.setIn(['availableDates', 'data'], [...filterRecord, obj]);
    }
    case ACTIONS.FETCH_JOB_SEARCH_FILTER.SUCCESS: {
      return state.set('jobSearchFilter', action.data);
    }
    case ACTIONS.UPDATE_JOB_SEARCH_FILTER_LIST: {
      const prev = state.get('jobSearchFilter') || [];
      const index = prev.findIndex((x: JobSearchFilterItem) => x.id === action.data.id);
      if (index !== -1) {
        prev[index].isChecked = !prev[index].isChecked;
        return state.set('jobSearchFilter', [...prev]);
      }

      return state.set('jobSearchFilter', [...prev, action.data]);
    }
    case ACTIONS.JOB_FOR_VIEW_FROM_NOTIFICATIONS: {
      const bookings = state.getIn(['booking', 'data']) || [];
      const index = bookings.findIndex((x: BookingRequest) => x.jobId.id === action.data);
      if (index !== -1) {
        return state.set('selectedJob', { ...bookings[index].jobId }).set('selectedBooking', bookings[index]);
      }

      const calls = state.getIn(['calls', 'data']) || [];
      const pos = calls.findIndex((x: ShowDayJob) => x.id === action.data);
      if (pos !== -1) {
        return state.set('selectedJob', { ...calls[pos] }).set('selectedBooking', {});
      }

      const castingRequest = state.getIn(['castingRequest', 'data']) || [];
      const posx = castingRequest.findIndex((x: BookingRequest) => x.jobId.id === action.data);
      if (posx !== -1) {
        return state.set('selectedJob', { ...castingRequest[posx].jobId }).set('selectedBooking', castingRequest[posx]);
      }

      return state;
    }
    case ACTIONS.UPDATE_BOOKING_STATS: {
      const prev = state.getIn(['searchJobs', 'data']) || [];
      const index = prev.findIndex((x: ShowDayJob) => x.id === action.data.jobId);
      if (index !== -1) {
        const oldData = prev[index];
        const bookedJobs = oldData.bookedJobs || [];
        const pendingJobs = oldData.pendingJobs || [];

        if (action.data.status === 'cancel') {
          oldData.bookedJobs = bookedJobs.filter((x: JobBookingIds) => x.performerId !== action.data.performerId);
          oldData.pendingJobs = pendingJobs.filter((x: JobBookingIds) => x.performerId !== action.data.performerId);
        } else if (action.data.status === 'apply') {
          const filterData = pendingJobs.filter((x: JobBookingIds) => x.performerId !== action.data.performerId);
          filterData.push({ performerId: action.data.performerId });
          oldData.pendingJobs = filterData;
        } else if (action.data.status === 'booked') {
          const filterData = bookedJobs.filter((x: JobBookingIds) => x.performerId !== action.data.performerId);
          filterData.push({ performerId: action.data.performerId });
          oldData.bookedJobs = filterData;
        }

        prev.splice(index, 1, { ...oldData });
        return state.setIn(['searchJobs', 'data'], [...prev]);
      }
      return state;
    }
    case ACTIONS.REMOVE_JOB_FROM_BOOKING: {
      const booking = state.getIn(['booking', 'data']) || [];
      const pos = booking.findIndex((x: BookingRequest) => x.id === action.data.id);
      if (pos !== -1) {
        if (booking[pos].dates.length === 1) {
          booking.splice(pos, 1);
          return state.setIn(['booking', 'data'], [...booking]);
        }
        const prevBooking = booking[pos];
        prevBooking.dates = prevBooking.dates.filter((x: string) => !x.includes(action.data.lastDate));
        booking.splice(pos, 1, prevBooking);
        return state.setIn(['booking', 'data'], [...booking]);
      }
      return state;
    }
    case ACTIONS.FETCH_PERFORMER_WORKED_DAYS.SUCCESS: {
      return state.setIn(['workedDays', 'data'], { data: action.data, pagination: action.pagination });
    }
    default: {
      return state;
    }
  }
}
