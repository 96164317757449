import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import LeftNavStyle from 'components/ShowLeftNavigation/ShowLeftNav.module.css';
import { Show, ShowWorkingDay } from 'modules/Show/show.types';
import { getShowSelectedDates } from 'store/selector/show.selector';

interface EpisodeDaysProps {
  episodeDays: ShowWorkingDay;
  showDates: string[];
  setFilterDates: Function;
}

const EpisodeDays: FunctionComponent<EpisodeDaysProps> = (props: EpisodeDaysProps) => {
  const { episodeDays, showDates, setFilterDates } = props;

  const handleClick = () => {
    setFilterDates([episodeDays.date]);
  };

  const isSelectedDate = (): string => {
    if (showDates && showDates.length === 1 && showDates[0] === episodeDays.date) return LeftNavStyle.selectedDate;
    return '';
  };

  return (
    <>
      <div className={`pl-3 pt-2 d-flex justify-content-between w-100 ${LeftNavStyle.secondChildContent}`}>
        <div className="w-100" onClick={handleClick} onKeyPress={handleClick} role="button" tabIndex={0}>
          <p className={`${LeftNavStyle.showTitle} ${isSelectedDate()}`}>{episodeDays.title}</p>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: Show) => {
  const showDates = getShowSelectedDates(state);
  return { showDates };
};

export default connect(mapStateToProps, null)(EpisodeDays);
