import React, { FunctionComponent } from 'react';
import { BGPerformer } from 'modules/user/types';
import Style from 'views/bgperformer/profile/ProfileEdit/ProfileEdit.module.css';
import { UNIT_FORMAT } from 'store/castingPax.constants';
import { formatInUnit } from 'helpers/utils';

interface SizeCardViewProps {
  user: BGPerformer | undefined;
  handleClose: Function;
}
const SizeCardView: FunctionComponent<SizeCardViewProps> = (props: SizeCardViewProps) => {
  const { user, handleClose } = props;

  if (!user) {
    handleClose();
    return <></>;
  }

  return (
    <>
      <div className={`${Style.formWrapper}`}>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Hair color</span>
          </div>
          <div className="col-sm-7">{user.hair}</div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Eye color</span>
          </div>
          <div className="col-sm-7">{user.eyeColor}</div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Chest</span>
          </div>
          <div className="col-sm-7">{formatInUnit(user.chest, UNIT_FORMAT.INCH)}</div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Waist</span>
          </div>
          <div className="col-sm-7">{formatInUnit(user.waist, UNIT_FORMAT.INCH)}</div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Neck</span>
          </div>
          <div className="col-sm-7">{formatInUnit(user.neck, UNIT_FORMAT.INCH)}</div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Jacket</span>
          </div>
          <div className="col-sm-7">{user.jacket}</div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Inseam</span>
          </div>
          <div className="col-sm-7">{formatInUnit(user.inseam, UNIT_FORMAT.INCH)}</div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Sleeve</span>
          </div>
          <div className="col-sm-7">{formatInUnit(user.sleeve, UNIT_FORMAT.INCH)}</div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Shoe</span>
          </div>
          <div className="col-sm-7">{user.shoe}</div>
        </div>
      </div>
    </>
  );
};

export default SizeCardView;
