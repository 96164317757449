import React, { FunctionComponent, useState } from 'react';
import Style from 'components/controls/MultiSelectList/MultiSelectList.module.css';
import { SkillCategory } from 'modules/general/general.type';
import arrowDownSvg from 'assets/svg/down-arrow.svg';
import upArrow from 'assets/svg/up-arrow.svg';

interface SkillItemProps {
  skill: SkillCategory;
  handleOnChange: Function;
}
const SkillItem: FunctionComponent<SkillItemProps> = (props) => {
  const { skill, handleOnChange } = props;
  const [expand, setExpand] = useState<boolean>(false);

  const handleExpandClick = () => {
    const prevState = expand;
    setExpand(!prevState);
  };

  return (
    <li className="position-relative">
      {skill.subCategories && skill.subCategories.length > 0 && (
        <div onClick={handleExpandClick} onKeyPress={handleExpandClick} role="button" tabIndex={0}>
          <img src={expand ? upArrow : arrowDownSvg} alt="arrow-down" className={Style.skilldropDownArrow} />
        </div>
      )}
      <div className="custom-checkbox-wrapper form-group mb-1">
        <input
          type="checkbox"
          id={skill.id}
          checked={skill.isChecked || false}
          onChange={() => {
            handleOnChange(skill, null);
          }}
        />
        <label className="mb-0" htmlFor={skill.id}>
          {skill.title}
        </label>
      </div>
      {skill.subCategories &&
        expand &&
        skill.subCategories.map((subSkill: SkillCategory) => (
          <ul className="list-unstyled pl-4" key={subSkill.id}>
            <li>
              <div className="custom-checkbox-wrapper form-group mb-1">
                <input
                  type="checkbox"
                  id={subSkill.id}
                  checked={subSkill.isChecked || false}
                  onChange={() => {
                    handleOnChange(skill, subSkill);
                  }}
                />
                <label className="mb-0" htmlFor={subSkill.id}>
                  {subSkill.title}
                </label>
              </div>
            </li>
          </ul>
        ))}
    </li>
  );
};

export default SkillItem;
