import { connect } from 'react-redux';
import { updateProfileBegin, uploadProfileImageBegin } from 'store/actions/auth.actions';
import ProfileEdit from 'views/profileMenu/profile/ProfileEdit/ProfileEdit';
import { getSettingRequest } from 'store/actions/general.actions';
import { User } from 'modules/user/types';
import { isSignUpCompleted, loginError, loginInProgress } from 'store/selector/auth.selector';

const mapStateToProps = (state: User) => {
  const isLoading = loginInProgress(state);
  const authError = loginError(state);
  const isCompleted = isSignUpCompleted(state);

  return {
    isLoading,
    authError,
    isCompleted,
  };
};

const mapDispatchToProps = {
  updateProfileBegin,
  getSettingRequest,
  uploadProfileImageBegin,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);
