import { connect } from 'react-redux';
import { User } from 'modules/user/types';
import { selectedTab } from 'store/selector/auth.selector';
import Landing from 'views/bgperformer/landing/Landing';
import { bookingRequestBegin, calendarDatesBegin, openJobDetails } from 'store/actions/job.actions';

const mapStateToProps = (state: User) => {
  const selcetedHomeTab = selectedTab(state);

  return {
    selcetedHomeTab,
  };
};

const mapDispatchToProps = {
  bookingRequestBegin,
  calendarDatesBegin,
  openJobDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
