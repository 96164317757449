import React from 'react';
import styled from 'styled-components';

interface Props {
  label?: string;
  message?: string;
  width?: number;
  height?: number;
  open?: boolean;
}

interface PopWrapperProp {
  width?: number;
  height?: number;
  open?: boolean;
  withLabel?: boolean;
}

const InfoPopup: React.FC<Props> = (props) => {
  const { label, message, height, width, open } = props;
  return (
    <PopWrapper width={width} height={height} open={open}>
      {label && <Label>{label}</Label>}
      <Paragraph>{message}</Paragraph>
    </PopWrapper>
  );
};

export default InfoPopup;
const PopWrapper = styled.div<PopWrapperProp>`
  position: fixed;
  margin-left: 25px;
  top: -12px;
  height: ${({ height }) => `${height}px`};
  padding: 11px 22px;
  width: ${({ width }) => `${width}px`};
  border-radius: 10px;
  background: white;
  border: 2px solid #e4f0fc;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column;
  z-index: 99999;

  &::after {
    content: '';
    position: absolute;
    height: 14px;
    width: 14px;
    left: -7px;
    top: 8px;
    background: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.lightBlue};
    border-right: transparent;
    border-top: transparent;
    transform: rotate(45deg);
  }

  & > h3 {
    font-size: 14px;
  }
`;

const Label = styled.h3`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  border-bottom: 1px solid #e4f0fc;
  /* identical to box height */

  color: #252427;
`;

const Paragraph = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Grey */

  color: #828282;
`;
