/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import Styled from 'styled-components';
import CreateShow from 'components/CreateShow/index';
import RateManagement from 'views/Payroll/rate-management/RateManagement';
import { Show } from 'modules/Show/show.types';
import { retry } from '@reduxjs/toolkit/query';
import { useHistory } from 'react-router';
import { TopButtonData } from './TopButtonsData';

interface ButtonTextProps {
  color?: string;
  background?: string;
}

const TopButtons = () => {
  const [createShow, setCreateShow] = useState(false);
  const [showDetail, setShowDetail] = useState<Show>({} as Show);
  const history = useHistory();
  const toggleCreateShow = () => {
    setCreateShow(!createShow);
  };

  const handleClick = (type: string) => {
    switch (type) {
      case 'Add Show':
        return toggleCreateShow();
      case 'Rate Management':
        return history.push('/payroll/rate-management');
      case 'User Management':
        return history.push('/payroll/user-management');
      case 'Voucher Management':
        return history.push('/payroll/voucher-management');
      default:
        return '';
    }
  };
  return (
    <TopButtonContaianer className="">
      {TopButtonData.map((item) => (
        <NavButton className="col-lg-33" background={item.background} onClick={() => handleClick(item.title)}>
          <ButtonLabel>
            <IconImage src={item.icon} />
            <TextContainer color={item.color}>{item.title}</TextContainer>
          </ButtonLabel>
        </NavButton>
      ))}
      {createShow && <CreateShow closeDialog={toggleCreateShow} />}
    </TopButtonContaianer>
  );
};
const TopButtonContaianer = Styled.div`
display: flex;
align-items: center;
// width: 100%;
// justify-content: space-around;
flex-wrap: wrap;

`;

const NavButton = Styled.button`
margin-bottom: 25px;
margin-right: 20px;
// display: flex;
border: none;
// align-items: center;
line-height: 24px;
background: ${(props: ButtonTextProps) => (props.background ? props.background : '#fff')} !important;
border-radius: 8px;
color: ${(props: ButtonTextProps) => (props.color ? props.color : '#fff')};
// flex: 4;
&:last-of-type {
  margin-right: 0;
}

@media only screen and (max-width: 1199px) {
  margin-right: 15px;


  &:last-child,
  &:nth-last-child(3) {
    margin-right: 0;
  }

}


`;

const ButtonLabel = Styled.div`
  display: flex;
  padding: 20px 30px;
  align-items: center;
  height: 70px;
`;

const TextContainer = Styled.p`
  margin: 0;
  font-weight: 400;
  /* font-size: calc(0.2em + 1vmin) */
  font-size: 12.5px;
  font-family: 'Montserrat';
  font-style: normal;
  padding-left: 16px;
  opacity: 1;
  color: ${(props: ButtonTextProps) => (props.color ? props.color : '#fff')} !important;
  @media (min-width: 1340px) {
    font-size: calc(12.5px + 6.5 * ((100vw - 1340px) / 260));
  }
  /* @media (min-width: 1280px) {
    font-size: calc(12px + 6 * ((100vw - 1280px) / 320));
  } */

  @media (min-width: 1600px) {
    font-size: 20px;
  }
`;

const IconImage = Styled.img`
  height:30px;
  width:30px;
`;

export default TopButtons;
