/* eslint-disable import/prefer-default-export */

// import UserIcon from 'assets/images/user.png';
import UserManagementIcon from 'assets/images/userNav.svg';
import VoucherManageMentIcon from 'assets/svg/sheet-folded.svg';
import RateManageMentIcon from 'assets/svg/dollar-circle.svg';
import MainDashboardIcon from 'assets/images/guage.svg';
import { SideNavDataInterface } from '../Interface';
import UserManagementDashboard from '../UserManagement/UserManagementPage.component';

const UserManagementSideNavItemData: SideNavDataInterface = {
  SideNavMenuItems: [
    {
      icon: MainDashboardIcon,
      title: 'Dashboard',
      link: '/payroll/home',
    },
    {
      icon: VoucherManageMentIcon,
      title: 'Voucher Management',
      link: '/payroll/voucher-management',
    },
    {
      icon: UserManagementIcon,
      title: 'User Management',
      link: '/payroll/user-management',
    },
    {
      icon: RateManageMentIcon,
      title: 'Rate Management',
      link: '/payroll/rate-management',
    },
  ],
  Component: UserManagementDashboard,
};

export { UserManagementSideNavItemData };
