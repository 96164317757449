import React, { FunctionComponent, useEffect, useState } from 'react';
import { User } from 'modules/user/types';
import Style from 'views/bgperformer/profile/Profile.module.css';
import Modal from 'components/controls/Modal/Modal';
import { isValidFile } from 'helpers/utils';
import { IProfileFileParam, IRemoveResume } from 'modules/params/param.type';
import Dialog from 'components/bgPerformer/Images/Dialog';
import ProfileEdit from 'views/profileMenu/profile/ProfileEdit';
import TopNavBar from 'components/TopNavBar/TopNavBar';
import BasicInfo from './basicInfo/BasicInfo';

interface ProfileProps {
  profileData: User;
  user: User;
  performerProfileBegin?: Function;
  uploadProfileImageBegin: Function;
  setSelectedTab: Function;
  deleteResumeBegin: Function;
}
const UserProfile: FunctionComponent<ProfileProps> = (props: ProfileProps) => {
  const { profileData, performerProfileBegin, user, uploadProfileImageBegin, setSelectedTab, deleteResumeBegin } =
    props;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [imageDialog, setImageDialog] = useState<boolean>(false);
  const [resumeKey, setResumeKey] = useState<string>('');

  useEffect(() => {
    setSelectedTab('9');
  }, []);

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  useEffect(() => {
    if (profileData === undefined && performerProfileBegin) performerProfileBegin({ userId: user.id });
  }, [profileData]);

  const getProfile = () => <ProfileEdit closeModal={handleEdit} user={user} />;

  const uploadResume = (file: File) => {
    console.log('Upload', file);
    if (file) {
      const message = isValidFile(file, 'pdf');
      if (message.length > 0) {
        return;
      }
      const formData = new FormData();
      formData.append(resumeKey, file);

      const params: IProfileFileParam = {
        params: formData,
        updateCall: true,
      };
      uploadProfileImageBegin(params);
      setImageDialog(false);
    }
  };

  const getResumeKey = () => {
    if (profileData && profileData.resumeUrl1) {
      const arr = profileData.resumeUrl1.split('/').pop();
      if (arr !== 'null' && arr !== undefined) {
        return 'resumeUrl2';
      }
    }
    return 'resumeUrl1';
  };

  const handleResumeUpload = () => {
    setResumeKey(getResumeKey());
    setImageDialog(true);
  };

  const handleEditResume = (type: string, action: string) => {
    setResumeKey(type);
    if (action === 'edit') {
      setImageDialog(true);
    } else {
      const params: IRemoveResume = type === 'resumeUrl1' ? { resumeUrl1: 'remove' } : { resumeUrl2: 'remove' };
      deleteResumeBegin(params);
    }
  };

  return (
    <>
      <TopNavBar />
      <div className={`${Style.MainWrapper}`}>
        <div className={`${Style.BasicInfoWrapper}`}>
          <BasicInfo
            handleEditResume={handleEditResume}
            profile={profileData || user}
            handleEdit={handleEdit}
            handleResumeUpload={handleResumeUpload}
          />
        </div>
        {isEdit && <Modal title="Edit Profile" body={getProfile} closeModal={handleEdit} />}
        {imageDialog && <Dialog closeDialog={setImageDialog} fileUploadHandler={uploadResume} type="pdf" />}
      </div>
    </>
  );
};

export default UserProfile;
