/* eslint-disable react/destructuring-assignment */
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface InfoRowItemProps {
  title: string;
  text: string;
}

const InfoRowItem: FunctionComponent<InfoRowItemProps> = (props: InfoRowItemProps) => (
  <RowContainer>
    <RowTopText>{props.title}</RowTopText>
    <RowBottomText>{props.text}</RowBottomText>
  </RowContainer>
);

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  justify-content: center;
  height: 30px;
  width: 150px;
  margin-right: 52px;
  padding-bottom: 40.6px;
  padding-top: 35px;
`;

const RowTopText = styled.p`
  font-family: 'Montserrat';
  padding: 0px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  height: 20px;
  color: #828282;
  width: 68px;
  margin-bottom: 0px !important;
  font-feature-settings: 'tnum' on, 'lnum' on;
`;
const RowBottomText = styled.p`
  margin-bottom: 0px !important;
  height: 20px;
`;

export default InfoRowItem;
