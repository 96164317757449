import moment from 'moment-timezone';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Show, ShowTopCalendarTypeList, DateJobRequestParams, ShowTopCalendarType } from 'modules/Show/show.types';
import SliderWrapper from 'components/controls/Slider/SliderWrapper';
import CalendarItem from 'components/calendar/CalendarItem/CalendarItem';
import { directorDays } from 'components/controls/Slider/SliderResponsiveness';

interface TopBarCalendarProps {
  showCalendarDates: ShowTopCalendarTypeList;
  selectedShow?: Show;
  setShowDate?: Function;
  clearSelectedJobListing: Function;
  selectedShowDate: string;
  selectedWorkingDayId: string;
  recallRequest: boolean;
}
const TopBarCalendar: FunctionComponent<TopBarCalendarProps> = (props) => {
  const {
    selectedShow,
    setShowDate,
    showCalendarDates,
    clearSelectedJobListing,
    selectedShowDate,
    selectedWorkingDayId,
    recallRequest,
  } = props;
  console.log('showCalendarDates', showCalendarDates);
  const [selectedDate, setSelectedDate] = useState(selectedShowDate);
  const [dayId, setDayId] = useState<string>(
    showCalendarDates.length > 0 ? selectedWorkingDayId || showCalendarDates[0].id : '',
  );
  const [showId, setShowId] = useState<string>(selectedShow ? selectedShow.id : '');

  useEffect(() => {
    if (recallRequest) {
      const date = selectedShowDate || showCalendarDates[0].date;
      loadData(date, showCalendarDates[0].id);
    }
  }, [recallRequest]);

  useEffect(() => {
    if (selectedShow && showId !== selectedShow.id) {
      if (showCalendarDates && showCalendarDates.length > 0) {
        const date = selectedShowDate || showCalendarDates[0].date;
        loadData(date, showCalendarDates[0].id);
        setDayId(showCalendarDates[0].id);
        setSelectedDate(date);
      }
      setShowId(selectedShow.id);
    }
  }, [selectedShow, showCalendarDates]);

  const handleClick = (item: ShowTopCalendarType) => {
    if (item.isEmpty) return;
    setDayId(item.id);
    setSelectedDate(item.date);
    loadData(item.date, item.id);
  };

  const loadData = (date: string, workingDayId: string) => {
    if (selectedShow) {
      const dateValue = date.split(' ')[0];
      const requestParams: DateJobRequestParams = {
        date: dateValue,
        showId: selectedShow.id,
        workingDayId,
      };

      if (setShowDate) setShowDate(requestParams);
    }
    if (!selectedDate) {
      clearSelectedJobListing();
    }
  };

  if (showCalendarDates.length > 0) {
    return (
      <>
        <SliderWrapper sliderSettings={directorDays} slideToShow={10}>
          {showCalendarDates.map((item: ShowTopCalendarType) => (
            <CalendarItem
              key={item.id}
              isSelected={dayId === item.id}
              title={item.title}
              dayNo={item.dayTitle}
              handleClick={() => {
                handleClick(item);
              }}
            />
          ))}
        </SliderWrapper>
      </>
    );
  }

  const calendarDates = () => {
    const date = moment();
    const arr: ShowTopCalendarTypeList = [];
    let i = 0;

    for (i = 1; i < 31; i += 1) {
      const obj: ShowTopCalendarType = {
        id: Math.random().toString(),
        date: date.format('YYYY-MM-DD'),
        title: '',
        dayTitle: moment(date).format('MMM DD'),
        workingDayId: '',
        isEmpty: true,
      };

      arr.push(obj);
      date.add(1, 'days');
    }
    return arr;
  };

  return (
    <>
      <SliderWrapper sliderSettings={directorDays} slideToShow={10}>
        {calendarDates().map((item) => (
          <CalendarItem
            key={item.id}
            isSelected={false}
            title={item.title}
            dayNo={item.dayTitle}
            handleClick={() => {
              handleClick(item);
            }}
          />
        ))}
      </SliderWrapper>
    </>
  );
};

export default TopBarCalendar;
