import React, { FunctionComponent } from 'react';
import dotsImage from 'assets/images/dots.png';
import Style from 'components/dateItem/dateItem.module.css';
import { formateDate } from 'helpers/utils';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import DatePopup from 'components/bgPerformer/ExpandedSearch/DatePopup/DatePopup';

interface DateItemProps {
  dates: string[];
  cssClass?: string;
  datesToShow?: number;
  newDates?: string[];
}
const DateItem: FunctionComponent<DateItemProps> = (props: DateItemProps) => {
  const { dates, cssClass, datesToShow, newDates } = props;
  const max = datesToShow || 2;

  const itemComponent = () => <DatePopup newDates={newDates} start={max} dates={dates} cssClass={cssClass} />;

  const checkDateChange = (date: string) => {
    if (newDates === undefined) return '';
    if (!newDates.includes(date)) return '';
    return Style.redBorder;
  };

  return (
    <>
      <div className={`d-flex flex-wrap justify-content-start align-items-center ${cssClass}`}>
        {dates.map((x: string, index: number) => {
          if (index < max) {
            return (
              <div className={`${Style.dateBoxWrapper} ${checkDateChange(x)}`} key={index}>
                <p className="mb-0">{formateDate(x || '', 'MMM')}</p>
                <h6 className="mb-0">{formateDate(x || '', 'DD')}</h6>
              </div>
            );
          }
          return '';
        })}

        {dates.length > max && (
          <PopoverContainer itemComponent={itemComponent}>
            <div className={Style.dateBoxWrapper}>
              <img src={dotsImage} alt="dotImage" />
            </div>
          </PopoverContainer>
        )}
      </div>
    </>
  );
};

export default DateItem;
