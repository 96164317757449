import React, { FunctionComponent } from 'react';
import { Table } from 'react-bootstrap';
import HistoryItem from 'components/ProfileMenu/subscription/HistoryItem';
import PaginationComponent from 'components/controls/pagination/Pagination';
import { Pagination } from 'modules/jobs/types';
import { useDispatch } from 'react-redux';
import { subscriptionHistoryBegin } from 'store/actions/auth.actions';
import Loader from 'components/controls/Loader/Loader';
import printSvg from 'assets/svg/print.svg';
import Style from 'components/ProfileMenu/subscription/style.module.css';

interface IHistoryProps {
  data: any;
  pagination: Pagination | undefined;
  loading: boolean;
}
const SubscriptionHistory: FunctionComponent<IHistoryProps> = (props: IHistoryProps) => {
  const { loading, data, pagination } = props;

  const dispatch = useDispatch();

  const handlePages = (updatePage: any) => {
    loadData(updatePage.selected + 1);
  };

  const loadData = (pageNumber: number) => dispatch(subscriptionHistoryBegin({ page: pageNumber, limit: 10 }));
  const handlePrint = () => window.print();
  return (
    <>
      <div
        className={`mr-2 ${Style.historyPrintBtn}`}
        role="button"
        onClick={handlePrint}
        onKeyPress={handlePrint}
        tabIndex={0}
      >
        <img src={printSvg} alt="" />
      </div>
      <Table striped>
        <thead>
          <tr>
            <th>Category</th>
            <th>Subscription Plan</th>
            <th>Amount</th>
            <th>Date</th>
          </tr>
        </thead>
        {loading ? (
          <Loader isSmallView />
        ) : (
          <tbody>{data && data.map((item: any, index: number) => <HistoryItem key={index} item={item} />)}</tbody>
        )}
      </Table>
      <br />
      {pagination && data.length > 0 && pagination.totalPages > 1 && (
        <PaginationComponent
          page={pagination.page}
          totalPages={pagination.totalPages > 0 ? pagination.totalPages : 1}
          handlePagination={handlePages}
        />
      )}
    </>
  );
};

export default SubscriptionHistory;
