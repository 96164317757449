import styled from 'styled-components';

const TabContainer = styled.div`
  display: flex;
  border-radius: 8px;
  border: 1px solid #efe9f4;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  @media only screen and (max-width: 1199px) {
    flex-wrap: wrap;
  }
`;

export default TabContainer;
