import Header from 'components/topHeader/topHeader';
import React, { FunctionComponent } from 'react';

const Cookies: FunctionComponent = () => (
  <>
    <Header />
    <div style={{ marginLeft: '15%', marginRight: '15%' }}>
      <br />
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '20pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Cookies
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          BCPAX production services ltd. (operating CastingPAX.com services / site)&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;(“we” or “us” or “our”) may use cookies, web beacons, tracking pixels, and other tracking technologies
          when you visit our website CastingPAX.com, including any other media form, media channel, mobile website, or
          mobile&nbsp;
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          service
        </span>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;related or connected thereto (collectively, the “Site”) to help customize the Site and improve your
          experience.&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We reserve the right to make changes to this Cookie Policy at any time and for any reason. We will alert you
          about any changes by updating the “Last Updated” date of this Cookie Policy. Any changes or modifications will
          be effective immediately upon posting the updated Cookie Policy on the Site, and you waive the right to
          receive specific notice of each such change or modification.&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You are encouraged to periodically review this Cookie Policy to stay informed of updates. You will be deemed
          to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised
          Cookie Policy by your continued use of the Site after the date such revised Cookie Policy is posted.&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '2pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          USE OF COOKIES
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '2pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          A “cookie” is a string of information which assigns you a unique identifier that we store on your computer.
          Your browser then provides that unique identifier to use each time you submit a query to the Site. We use
          cookies on the Site to, among other things, keep track of services you have used, record registration
          information, record your user preferences, keep you logged into the Site, facilitate purchase procedures, and
          track the pages you visit. Cookies help us understand how the Site is being used and improve your user
          experience.&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '2pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          TYPES OF COOKIES
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          The following types of cookies may be used when you visit the Site:
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Advertising Cookies -&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Advertising cookies are placed on your computer by advertisers and ad servers in order to display
          advertisements that are most likely to be of interest to you. These cookies allow advertisers and ad servers
          to gather information about your visits to the Site and other websites, alternate the ads sent to a specific
          computer, and track how often an ad has been viewed and by whom. These cookies are linked to a computer and do
          not gather any personal information about you.&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Analytics Cookies -&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Analytics cookies monitor how users reached the Site, and how they interact with and move around once on the
          Site. These cookies let us know what features on the Site are working the best and what features on the Site
          can be improved.&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Our Cookies -&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Our cookies are “first-party cookies”, and can be either permanent or temporary. These are necessary cookies,
          without which the Site wont work properly or be able to provide certain features and functionalities. Some of
          these may be manually disabled in your browser, but may affect the functionality of the Site.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Personalization Cookies -&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Personalization cookies are used to recognize repeat visitors to the Site. We use these cookies to record your
          browsing history, the pages you have visited, and your settings and preferences each time you visit the
          Site.&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Security Cookies -&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Security cookies help identify and prevent security risks. We use these cookies to authenticate users and
          protect user data from unauthorized parties.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Site Management Cookies -&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Site management cookies are used to maintain your identity or session on the Site so that you are not logged
          off unexpectedly, and any information you enter is retained from page to page. These cookies cannot be turned
          off individually, but you can disable all cookies in your browser.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Third-Party Cookies -&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Third-party cookies may be place on your computer when you visit the Site by companies that run certain
          services we offer. These cookies allow the third parties to gather and track certain information about you.
          These cookies can be manually disabled in your browser.&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '2pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          CONTROL OF COOKIES
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Most browsers are set to accept cookies by default. However, you can remove or reject cookies in your
          browser’s settings. Please be aware that such action could affect the availability and functionality of the
          Site.&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          For more information on how to control cookies, check your browser or device’s settings for how you can
          control or reject cookies, or visit the following links:
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <a href="https://support.apple.com/kb/ph19214?locale=en_US" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Apple Safari
          </span>
        </a>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <a
          href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
          style={{ textDecoration: 'none' }}
        >
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Google Chrome
          </span>
        </a>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <a
          href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
          style={{ textDecoration: 'none' }}
        >
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Microsoft Edge
          </span>
        </a>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <a
          href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
          style={{ textDecoration: 'none' }}
        >
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Microsoft Internet Explorer
          </span>
        </a>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <a
          href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
          style={{ textDecoration: 'none' }}
        >
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Mozilla Firefox
          </span>
        </a>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <a href="http://www.opera.com/help/tutorials/security/cookies/" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Opera
          </span>
        </a>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <a
          href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1"
          style={{ textDecoration: 'none' }}
        >
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Android (Chrome)
          </span>
        </a>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <a
          href="https://help.blackberry.com/en/blackberry-classic/10.3.1/help/mwa1334238823957.html"
          style={{ textDecoration: 'none' }}
        >
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Blackberry
          </span>
        </a>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <a
          href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS&hl=en&oco=1"
          style={{ textDecoration: 'none' }}
        >
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Iphone or Ipad (Chrome)
          </span>
        </a>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <a
          href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1"
          style={{ textDecoration: 'none' }}
        >
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Iphone or Ipad (Safari)
          </span>
        </a>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          In addition, you may opt-out of some third-party cookies through the&nbsp;
        </span>
        <a href="http://optout.networkadvertising.org/#!/" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Network Advertising Initiative’s Opt-Out Tool
          </span>
        </a>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          .
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '2pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          OTHER TRACKING TECHNOLOGIES
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          In addition to cookies, we may use web beacons, pixel tags, and other tracking technologies on the Site to
          help customize the Site and improve your experience. A “web beacon” or “pixel tag” is tiny object or image
          embedded in a web page or email. They are used to track the number of users who have visited particular pages
          and viewed emails, and acquire other statistical data. They collect only a limited set of data, such as a
          cookie number, time and date of page or email view, and a description of the page or email on which they
          reside. Web beacons and pixel tags cannot be declined. However, you can limit their use by controlling the
          cookies that interact with them.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '2pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          PRIVACY POLICY
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          For more information about how we use information collected by cookies and other tracking technologies, please
          refer to our Privacy Policy&nbsp;
        </span>
        <a href="http://www.castingpax.com/policy" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#1155cc',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            www.castingpax.com/policy
          </span>
        </a>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#434343',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;posted on the Site. This Cookie Policy is part of and is incorporated into our Privacy Policy. By using
          the Site, you agree to be bound by this Cookie Policy and our Privacy Policy.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.9636363636363636', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
      </p>
      <h2 dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '11pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#323436',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          CONTACT INFORMATION
        </span>
      </h2>
      <p>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          If you have any questions about this Agreement, please contact us at&nbsp;
        </span>
        <a href="http://www.excellingpayroll.com/contact-us" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#111111',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            support@castingpax.com
          </span>
        </a>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          .
        </span>
      </p>
    </div>
  </>
);

export default Cookies;
