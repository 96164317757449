/* eslint-disable import/prefer-default-export */
import UserManagementIcon from 'assets/images/userNav.svg';
import VoucherManagementIcon from 'assets/svg/sheet-folded.svg';
import RateManagementIcon from 'assets/images/dollarIcon.svg';
import MainDashboardIcon from 'assets/images/guage.svg';
import MainDashboard from '../PayrollDashboard/PayrollPage.component';
import { SideNavDataInterface } from '../Interface';

const MainNavItemData: SideNavDataInterface = {
  SideNavMenuItems: [
    {
      icon: MainDashboardIcon,
      title: 'Dashboard',
      link: '/payroll/home',
    },
    {
      icon: VoucherManagementIcon,
      title: 'Voucher Management',
      link: '/payroll/voucher-management',
    },
    {
      icon: UserManagementIcon,
      title: 'User Management',
      link: '/payroll/user-management',
    },
    {
      icon: RateManagementIcon,
      title: 'Rate Management',
      link: '/payroll/rate-management',
    },
  ],
  Component: MainDashboard,
};

export { MainNavItemData };
