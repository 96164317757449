import React, { FunctionComponent, useEffect, useState } from 'react';
import Style from 'components/castingDirector/PopOvers/CallDetail/CallDetail.module.css';
import Button from 'components/controls/button/button';
// import shareSvg from 'assets/svg/share.svg';
import downloadSvg from 'assets/svg/download-arrow.svg';
import finalCallSvg from 'assets/svg/final-call.svg';
import { connect, useDispatch } from 'react-redux';
import { IProfileFileParam } from 'modules/params/param.type';
import {
  removeCallSheetBegin,
  resetCounterCallSheet,
  sendCallSheetBegin,
  uploadCallSheetBegin,
} from 'store/actions/show.actions';
// import { isValidFile } from 'helpers/utils';
import { callSheet, callSheetList, MatrixType } from 'modules/Show/show.types';
import {
  getCallSheetCounter,
  getCallSheetDeleteLoading,
  getCallSheetLoading,
  getSendCallSheetLoading,
} from 'store/selector/show.selector';
import Loader from 'components/controls/Loader/Loader';
import { showToast } from 'store/actions/general.actions';
import { TOAST_IDS, TOAST_TYPE } from 'store/castingPax.constants';
import Dialog from 'components/bgPerformer/Images/Dialog';
import deleteSvg from 'assets/svg/delete.svg';

interface MatrixCallDetailProps {
  handleEmailDialog?: Function;
  workingDay: MatrixType;
  uploadCallSheet: Function;
  isLoading: boolean;
  sendCallTimeBegin: Function;
  callTimeLoading: string;
  removeCallSheet: Function;
  isDeleteLoading: boolean;
  counter: number;
  resetCounter: Function;
}
const MatrixCallDetail: FunctionComponent<MatrixCallDetailProps> = (props: MatrixCallDetailProps) => {
  const {
    handleEmailDialog,
    workingDay,
    uploadCallSheet,
    isLoading,
    removeCallSheet,
    sendCallTimeBegin,
    callTimeLoading,
    isDeleteLoading,
    counter,
    resetCounter,
  } = props;
  // const inputFile = useRef<HTMLInputElement>(null);
  const [fileError, setFileError] = useState<string>('');
  const [finalCallSheet, setFinalCallSheet] = useState<callSheetList>([]);
  const [prelimCallSheet, setPrelimCallSheet] = useState<callSheetList>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [uploadingFiles, setUploadingFiles] = useState<any>([]);
  const [workingDayStatus, setWorkingDayStatus] = useState<string>(workingDay.status || 'prelim');

  const dispatch = useDispatch();

  useEffect(() => {
    const data = workingDay.callSheetUrl ? JSON.parse(workingDay.callSheetUrl) : [];
    setFinalCallSheet(data.filter((x: callSheet) => x.type === 'final'));
    setPrelimCallSheet(data.filter((x: callSheet) => x.type === 'prelim'));
  }, [workingDay.callSheetUrl]);

  useEffect(() => {
    if (!isDeleteLoading && selectedFile !== '') setSelectedFile('');
  }, [isDeleteLoading]);

  useEffect(() => {
    if (counter && counter > 0 && uploadingFiles.length > 1 && counter < uploadingFiles.length) {
      sendCallSheet(uploadingFiles[counter]);
      return;
    }
    resetCounter();
  }, [counter]);

  const handleMakeFinal = () => {
    if (finalCallSheet.length === 0) {
      setWorkingDayStatus('final');
      dispatch(
        showToast({
          id: TOAST_IDS.CALL_SHEET_FILE_ERROR,
          title: 'Attach Attachment For Call Sheet',
          type: TOAST_TYPE.ERROR,
        }),
      );
      return;
    }
    if (handleEmailDialog) handleEmailDialog('final');
    setWorkingDayStatus('final');
    sendEmail('final');
  };

  const handlePrelimCall = () => {
    if (prelimCallSheet.length === 0) {
      setWorkingDayStatus('prelim');
      dispatch(
        showToast({
          id: TOAST_IDS.CALL_SHEET_FILE_ERROR,
          title: 'Attach Attachment For Call Sheet',
          type: TOAST_TYPE.ERROR,
        }),
      );
      return;
    }
    setWorkingDayStatus('prelim');
    sendEmail('prelim');
  };

  const sendEmail = (type: string) => {
    if (workingDay.prelimEmailTemplateId === null) {
      dispatch(
        showToast({
          id: TOAST_IDS.CREATE_EMAIL_TEMPLATE,
          title: 'Create or Attach Email Template For Call Sheet',
          type: TOAST_TYPE.ERROR,
        }),
      );
      return;
    }
    sendCallTimeBegin({
      workingDayId: workingDay.id,
      templateId: workingDay.prelimEmailTemplateId,
      type,
    });
  };

  const handleChange = (event: any) => {
    setOpenDialog(false);
    setUploadingFiles(event);
    if (event && event.length > 0) {
      const file: File = event[0];
      if (file) sendCallSheet(file);
    }
  };

  const sendCallSheet = (file: File) => {
    const formData = new FormData();
    formData.append('callSheet', file);
    formData.append('id', workingDay.id);
    formData.append('callSheetType', workingDayStatus || '');
    const params: IProfileFileParam = {
      params: formData,
    };
    uploadCallSheet(params, workingDay.id);
    setFileError('');
  };

  const handleDelete = (file: string) => {
    setSelectedFile(file);
    removeCallSheet({ id: workingDay.id, fileName: file });
  };

  const openFileInput = () => setOpenDialog(true);

  console.log('File Error', fileError);

  const callSheetStatus = (status: string) => {
    if (!workingDayStatus) return 'notActive';
    if (workingDayStatus === status) return '';
    return 'notActive';
  };

  const getBgColorClass = (index: number) => {
    if (index % 3 === 1) return Style.bgLightBlue;
    if (index % 3 === 2) return Style.bgLightDanger;
    return Style.bgLightSuccess;
  };

  const renderCallSheetFiles = () => {
    let data: callSheetList = [];
    if (workingDayStatus === 'final') data = finalCallSheet || [];
    if (workingDayStatus === 'prelim') data = prelimCallSheet || [];
    if (data && data.length > 0) {
      return data.map(
        (sheet: any, index: number) =>
          sheet.type === workingDayStatus && (
            <div className="px-3">
              <div
                className={`d-flex justify-content-between align-items-center px-3 mb-2 
              ${getBgColorClass(index + 1)}`}
              >
                <p className={`mb-0 ${Style.callTitle}`}>{`${sheet.fileName}`}</p>
                <div className="d-flex">
                  <a className="cursor-pointer" href={sheet.callSheetUrl} download rel="noreferrer" target="_blank">
                    <img src={downloadSvg} alt="" className={Style.shareIcon} />
                  </a>
                  <div
                    className="ml-2"
                    role="button"
                    tabIndex={0}
                    onClick={() => handleDelete(sheet.file)}
                    onKeyPress={() => handleDelete(sheet.file)}
                  >
                    <img style={{ width: '15px' }} src={deleteSvg} alt="" />
                    {isDeleteLoading && selectedFile === sheet.file && <Loader isSmallView />}
                  </div>
                </div>
              </div>
            </div>
          ),
      );
    }
    return '';
  };

  return (
    <>
      <div className={Style.mainWrapper}>
        <div className={`d-flex justify-content-between align-items-center prelimBtnWrapper ${Style.callWrapper}`}>
          <div className="mb-0">
            <Button
              clickHandler={handlePrelimCall}
              showLoading={callTimeLoading === 'prelim'}
              label="Prelim Call"
              cssClass={`${Style.prelimBtn} ${callSheetStatus('prelim')}`}
            />
          </div>
          <div className={`mb-0 ${Style.makeFinalWrapper}`}>
            {/* {workingDay.callSheetUrl && (
              <a href={workingDay.callSheetUrl} download>
                <img src={downloadSvg} alt="" className={Style.shareIcon} />
              </a>
            )} */}
            <Button
              clickHandler={handleMakeFinal}
              showLoading={callTimeLoading === 'final'}
              label="Final Call"
              cssClass={`${Style.makeFinalButton} ${callSheetStatus('final')}`}
            />
          </div>
        </div>
        <div className={`d-flex justify-content-between align-items-center ${Style.finalCallWrapper}`}>
          <p className={`mb-0 ${Style.callTitle}`}>
            {workingDayStatus === 'final' ? 'Final ' : 'Prelim '}
            call
          </p>
          <div className="d-flex">
            <div role="button" tabIndex={0} onClick={openFileInput} onKeyPress={openFileInput}>
              <div>{isLoading ? <Loader isSmallView /> : <img src={finalCallSvg} alt="" />}</div>
            </div>
            {/* {workingDay.callSheetUrl && <img src={shareSvg} alt="" className={Style.shareIcon} />} */}
          </div>
        </div>
        <div>{renderCallSheetFiles()}</div>
      </div>
      {openDialog && (
        <Dialog
          fileLoader={isLoading}
          closeDialog={() => setOpenDialog(false)}
          fileUploadHandler={handleChange}
          type="pdf"
          isMultiple
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  const isLoading: boolean = getCallSheetLoading(state);
  const callTimeLoading: string = getSendCallSheetLoading(state);
  const isDeleteLoading: boolean = getCallSheetDeleteLoading(state);
  const counter: number = getCallSheetCounter(state);
  return { isLoading, callTimeLoading, isDeleteLoading, counter };
};

const mapDispatchToProps = {
  uploadCallSheet: uploadCallSheetBegin,
  sendCallTimeBegin: sendCallSheetBegin,
  removeCallSheet: removeCallSheetBegin,
  resetCounter: resetCounterCallSheet,
};

export default connect(mapStateToProps, mapDispatchToProps)(MatrixCallDetail);
