import { connect } from 'react-redux';
import { Show } from 'modules/Show/show.types';
import { getShowListingRequest } from 'store/actions/show.actions';
import { getShowLists } from 'store/selector/show.selector';
// import ShowTopNavigation from 'components/ShowTopNavigation/ShowTopNavigation';
import InprogressComponent from './InprogressComponent';

const mapStateToProps = (state: Show) => {
  const showList = getShowLists(state);
  return {
    showList,
  };
};

const mapDispatchToProps = {
  getShowListing: getShowListingRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(InprogressComponent);
