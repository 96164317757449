import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchPerformerIamgesBegin,
  fetchUserJobStatusBegin,
  setPerformerForView,
} from 'store/actions/myPerformer.actions';
import ProfileInfo from 'components/PerformerProfile/ProfileInfo';
import { User } from 'modules/user/types';
import PerformerProfileStyle from 'components/PerformerProfile/PerformerProfile.module.css';
import MyPerformerListStyle from 'components/MyPerformerList/MyPerformerList.module.css';
import exportSvg from 'assets/svg/export.svg';
import { formatHeight, formatWeight, getAge, getName, getUnionClass, getUnionValue } from 'helpers/utils';

interface ItemDetailProps {
  item: User;
}
const ItemDetail: FunctionComponent<ItemDetailProps> = (props: ItemDetailProps) => {
  const { item } = props;
  const dispatch = useDispatch();

  const handleClick = () => {
    document.body.click();
    dispatch(fetchUserJobStatusBegin({ id: item.id }));
    dispatch(fetchPerformerIamgesBegin({ id: item.id }));
    dispatch(setPerformerForView(item));
  };
  return (
    <>
      <div className={`${PerformerProfileStyle.profileCard} ${PerformerProfileStyle.popup}`}>
        <div className={PerformerProfileStyle.performerId}>
          <p className="mb-0 mt-0">
            <span className={`text-white ${PerformerProfileStyle.afterTitle} ${getUnionClass(item)}`}>
              {getUnionValue(item)}
            </span>
          </p>
        </div>
        <div className={`mb-2 pt-3 position-relative ${MyPerformerListStyle.shortDetailsWrapper}`}>
          <div
            className={MyPerformerListStyle.clickArrow}
            onClick={handleClick}
            role="button"
            tabIndex={0}
            onKeyPress={handleClick}
          >
            <img src={exportSvg} alt="" />
          </div>
          <img src={item.profilePicUrl} alt="" className={MyPerformerListStyle.profileImage} />
          <div className="pt-2 ml-3">
            <p className={PerformerProfileStyle.profileName}>{getName(item)}</p>
          </div>
        </div>
        <div className={`row ${PerformerProfileStyle.profileDetails}`}>
          <div className="col-sm-4">
            <ProfileInfo label="Sex" value={item.bgPerformer?.gender} title={item.bgPerformer?.gender} />
          </div>
          <div className="col-sm-4">
            <ProfileInfo
              label="Age"
              value={getAge(item.bgPerformer?.dateOfBirth)}
              title={getAge(item.bgPerformer?.dateOfBirth)}
            />
          </div>
          <div className="col-sm-4">
            <ProfileInfo label="Eye color" value={item.bgPerformer?.eyeColor} title={item.bgPerformer?.eyeColor} />
          </div>
          <div className="col-sm-4">
            <ProfileInfo
              label="Weight"
              value={formatWeight(item.bgPerformer?.weight)}
              title={formatWeight(item.bgPerformer?.weight)}
            />
          </div>
          <div className="col-sm-4">
            <ProfileInfo
              label="Height"
              value={formatHeight(item.bgPerformer?.height || '')}
              title={formatHeight(item.bgPerformer?.height || '')}
            />
          </div>
          <div className="col-sm-4">
            <ProfileInfo label="Hair" value={item.bgPerformer?.hair} title={item.bgPerformer?.hair} />
          </div>
        </div>
      </div>
    </>
  );
};
export default ItemDetail;
