import React, { FunctionComponent, useState } from 'react';
import Styled from 'styled-components';
// import PayrollNav from 'components/Payroll/Nav/PayrollNav';
import TopNavBar from 'components/TopNavBar/TopNavBar';
import SideNav from './PayrollSideNav';
import { MainNavItemData } from '../Data/PayrollDashboardData';

const Payroll: FunctionComponent = () => {
  const [showSideNav, setShowSideNav] = useState(true);
  return (
    <PayrollContainer>
      {/* <PayrollNav setShowSideNav={setShowSideNav} showSideNav={showSideNav} /> */}
      <TopNavBar setShowSideNav={setShowSideNav} showSideNav={showSideNav} />
      <SideNav showNavIcon sideNavData={MainNavItemData} showSideNav={showSideNav} />
    </PayrollContainer>
  );
};

const PayrollContainer = Styled.div`
  // min-width: 1080px;
  background-color: #e5e5e5;
  margin: 0 auto;
  & div::-webkit-scrollbar {
    width: 5px;
    background-color: #f2f2f2;
  }
  & div::-webkit-scrollbar:hover {
    background: #555;
  }

  & div::-webkit-scrollbar-thumb:vertical {
    background: #878787;
  }

  & div::-webkit-scrollbar-thumb:vertical:active {
    background: rgba(0,0,0,0.61);
  }
`;
export default Payroll;
