/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import styled from 'styled-components';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import InvoicePDF from 'components/Payroll/VoucherManagement/Invoice/index';

// import { UserGridInterface } from './interface/UserGridInterface';
import StatusCellRenderer from './components/StatusCellRenderer';
import CheckControls from './components/CheckControls';
import NameCellRenderer from './components/NameCellRenderer';
import CreateVoucherRenderer from './components/CreateVoucherRenderer';
import VoucherInfoRenderer from './components/VoucherInfoRenderer';
import TaxFilesRenderer from './components/TaxFilesRenderer';
import Loader from '../../../controls/Loader/Loader';

import '../../styles/Aggrid/grid.style.css';

interface Props {
  matrixData: any;
  search: any;
  sort?: any;
}

const UserGrid: React.FC<Props> = (props) => {
  const { matrixData, search, sort } = props;
  const [performers, setPerformers]: any = useState([]);
  const [selectedVoucher, setSelectedVoucher]: any = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [payrollAction, setPayrollAction] = useState('');

  useEffect(() => {
    if (Array(matrixData) && matrixData.length > 0) {
      if (performers.length === 0) {
        for (let item = 0; item < matrixData.length; item++) {
          if (matrixData[0]?.performers) {
            const result = loadPerformer(matrixData[0]?.performers);
            setPerformers([...result]);
          }
        }
      }
    }
    if (matrixData.length === 0 && performers.length > 0) {
      setPerformers([]);
    }
  }, [matrixData, performers]);

  useEffect(() => {
    console.log(performers);
  }, [performers]);

  const loadPerformer = (performer: any) => performer?.filter((per: any) => per.performerName !== null);
  const [, setGridApi] = useState();

  useEffect(() => {
    if (search !== '' || sort === 'asc') {
      const filteredHobbies = matrixData.flatMap(
        (performance: any) =>
          performance?.performers &&
          performance.performers.filter(
            (show: any) =>
              show.performerName !== null && show.performerName.toLowerCase().includes(search.toLowerCase()),
          ),
      );
      let sortedPerformers = filteredHobbies;
      if (sort === 'asc') {
        if (filteredHobbies && filteredHobbies.length > 0) {
          sortedPerformers = filteredHobbies.sort((a: any, b: any) => a.performerName.localeCompare(b.performerName));
        }
      }
      setPerformers([...sortedPerformers]);
    } else {
      // Reset performers state if search is empty and sort is not 'asc'
      setPerformers([]);
    }
  }, [search, sort]);

  const getDownloadedItem = (params: any) => {
    if (params.voucher?.data) {
      setSelectedVoucher(params.voucher);
      if (payrollAction === 'download') {
        setIsLoading(true);
      }
    } else {
      setSelectedVoucher({});
    }
  };

  const gridData = {
    columnDefs: [
      {
        headerName: '',
        field: 'status',
        width: 26,
        cellRendererFramework: StatusCellRenderer,
      },
      {
        headerName: '',
        field: 'check controls',
        width: 61,
        cellRendererFramework: CheckControls,
      },
      {
        headerName: 'Name',
        field: 'performerName',
        width: 300,
        cellRendererFramework: NameCellRenderer,
        headerTooltip: 'Name',
      },
      {
        headerName: 'Create Voucher',
        field: 'name',
        width: 135,
        cellRendererFramework: CreateVoucherRenderer,
        headerTooltip: 'Create Voucher',
      },
      {
        headerName: 'Tax Files',
        field: 'name',
        width: 605,
        cellRendererFramework: TaxFilesRenderer,
        headerTooltip: 'Tax Files',
      },
    ],
    rowData: performers,
  };

  const onGridReady = (params: any) => {
    setGridApi(params);
    params.api.sizeColumnsToFit();
  };

  const onCompleteDownLoad = () => {
    setPayrollAction('');
    setSelectedVoucher({});
  };

  return (
    <>
      <AgGridWrapper className="ag-theme-alpine voucher-user-table custom-user-table">
        <AgGridReact
          modules={AllCommunityModules}
          rowData={gridData.rowData}
          columnDefs={gridData.columnDefs}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          defaultColDef={{ filter: true }}
          containerStyle={{
            flex: '1',
            width: '100%',
          }}
        />
      </AgGridWrapper>

      <>
        {isLoading && <ShowLoader>{isLoading && <Loader isSmallView />}</ShowLoader>}
        {selectedVoucher && selectedVoucher.data && (
          <div className="w-100">
            {selectedVoucher.data && selectedVoucher.data.signInTime != null && (
              <InvoicePDF
                item={selectedVoucher.data || {}}
                isLoading={setIsLoading}
                onCompleteDownLoad={onCompleteDownLoad}
                isDownload={payrollAction === 'download'}
              />
            )}
          </div>
        )}
      </>
    </>
  );
};

const AgGridWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  min-height: 50vh;
  margin-top: 30px;
  font-size: 15px;
  color: #aaa;
  padding: 0;
  font-family: 'Montserrat';
  font-style: normal;
`;

const ShowLoader = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export default UserGrid;
