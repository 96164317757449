/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useState } from 'react';
import Styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SendNoteImage from 'assets/images/newSendImage.svg';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ReviewNoteItem from './NotesToReviewItem';

interface PaginationButtonStatus {
  active: boolean;
}
interface Props {
  noteItems?: any;
}

const ReviewNotesComponent: FunctionComponent<Props> = (props) => {
  const { noteItems } = props;
  const [showSideNav, setShowSideNav] = useState(false);
  // const noteItems = [
  //   { date: 'Aug 20', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
  //   { date: 'Aug 20', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
  //   { date: 'Aug 22', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
  //   { date: 'Sep 1', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
  //   { date: 'Sep 2', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
  //   { date: 'Sep 9', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
  //   { date: 'Sep 9', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
  //   { date: 'Sep 9', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
  //   { date: 'Sep 9', title: 'Co-Actor (short description of role)', summary: faker.lorem.sentence(35) },
  // ];
  return (
    <ErrorNoteContainer>
      <NoteHeader>
        <LogoImage src={SendNoteImage} />
        <NoteHeaderText>Notes to Review</NoteHeaderText>
      </NoteHeader>
      <BottomSectionContainer>
        <NoteItemContainer>
          {noteItems.map((item: any) => (
            <ReviewNoteItem date={item.date} summary={item.summary} title={item.title} />
          ))}
        </NoteItemContainer>
      </BottomSectionContainer>
    </ErrorNoteContainer>
  );
};

const ErrorNoteContainer = Styled.div`
display: flex;
height: 460px;
width: 49%;
border-radius: 10px;
flex-direction: column;
border-radius: 12px;
// @media only screen and (max-width: 1199px) {
//   height: 477px;
// }
@media only screen and (max-width: 1199px) {
  width: 100%;
  margin-top: 15px;
}
`;

const HeaderContainer = Styled.div`
display: flex;
width: 50%;
height: 55px;
padding: 19px;
margin-bottom: -10px;
align-items: center;
margin-left:auto;
border-radius: 12px;
margin-right:auto;
margin-bottom: 5px;
justify-content: center;
padding-left: 11px !important;
`;

const InBudgetContainer = Styled.div`
display: flex;
justify-content: flex-start;
width: 100%;
height: 100%;
margin-left: 0px;
font-size: 12px;
margin-bottom: 5px;
font-weight: 500;
font-family: 'Montserrat';
&:before {
    content: ' ';
    height: 10px;
    width: 10px;
    background: #49D360;
    margin-top:3px;
    margin-right:10px;
    border-radius: 100%;
}
`;

const PastBudgetContainer = Styled.div`
display: flex;
justify-content: flex-start;
width: 100%;
height: 100%;
margin-left: 0px;
font-size: 12px;
font-weight: 500;
font-family: 'Montserrat';
&:before {
    content: ' ';
    height: 10px;
    width: 10px;
    background: #EB5757;
    margin-top:3px;
    margin-right:10px;
    border-radius: 100%;
}
`;

const NoteContainer = Styled.div`
width: 100%;
border-radius: 12px;
`;

const NoteHeader = Styled.div`
  display: flex;
  background: #fff;
  border-bottom: 1.5px solid #F2F2F2;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  align-items: center;
  padding: 14px 28px;
`;
const StyledChevronLeft = Styled(FontAwesomeIcon)`
margin-top:6px;
color: black;
margin-top: auto;
`;
const StyledChevronRight = Styled(FontAwesomeIcon)`
margin-top:6px;
color: black;
margin-top: auto;
`;

const NoteHeaderText = Styled.p`
margin: 0;
padding-left: 8px;
font-family: 'Montserrat';
`;

const LogoImage = Styled.img`
  height:15px;
  width:15px;
`;

const BottomSectionContainer = Styled.div`
background: #fff;
height:100%;
border-bottom-right-radius: 12px;
border-bottom-left-radius: 12px;
overflow-y: scroll;
`;

const NoteItemContainer = Styled.div`

`;

const PaginationContainer = Styled.div`
display: flex;
justify-content: flex-end;
width: 100%;
padding-top: 10px;
padding-bottom: 10px;
padding-right: 10px;
height: 70px;
margin-right: 40px;
`;
const PaginationButton = Styled.button`
width:25px;
height:25px;
color: black !important;
margin-top: auto;
margin-left:5px;
margin-right: 5px;
font-family: 'Roboto';
font-size: 10px;
border-radius: 7px;
border: none;
background:  ${(props: PaginationButtonStatus) => (props.active ? '#49D360 !important' : '#F2F2F2 !important')} ;
`;

export default ReviewNotesComponent;
