import React from 'react';
import styled from 'styled-components';
import ChartItemBox from './ChartItemBox';
import ChartItemText from './ChartItemText';
import IshowData from '../interface/showData.interface';

interface Props {
  showData?: IshowData;
  maxValue?: number;
  enableTextItem?: boolean;
}

const ChartItemInfoBox: React.FC<Props> = (props) => {
  const { showData, maxValue, enableTextItem } = props;

  const isBudgetExceeded = Number(showData?.showBudget.expense) > Number(showData?.showBudget.budget);

  return (
    <ChartItemWrapper>
      <ChartItemBox maxValue={maxValue} show={showData} enableTextItem={enableTextItem} />
      {enableTextItem ? (
        <ChartItemText text={`${showData?.season}. ${showData?.episode}`} budgetExceeded={isBudgetExceeded} />
      ) : (
        <></>
      )}
    </ChartItemWrapper>
  );
};

export default ChartItemInfoBox;

const ChartItemWrapper = styled.div`
  width: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;
