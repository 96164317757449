import { BookingRequest, BookingRequestList, CalendarItem, CalendarList, PerformerJobs } from 'modules/jobs/types';
import { Episode, Show, ShowDayJob, ShowStats, ShowWorkingDay } from 'modules/Show/show.types';
import { RoleCode, BookingStatus, User, BookingDateList, BookingDate } from 'modules/user/types';
import moment from 'moment';
import {
  CALENDAR_STATUS_NOT_AVAILABLE,
  JOB_STATUS_BOOKED,
  JOB_STATUS_PENDING,
  JOB_STATUS_REQUESTED,
  PERMISSION_LEVEL_AC,
  PERMISSION_LEVEL_AD,
  PERMISSION_LEVEL_DC,
  PERMISSION_LEVEL_NA,
  PERMISSION_LEVEL_PD,
  ProfileKey,
  ROLE_COORDINATOR,
  ROLE_DIRECTOR,
  ROLE_PERFORMER,
  ROLE_PRODUCTION,
  ROLE_PAYROLL,
} from 'store/castingPax.constants';

export const getRoletitle = (role: RoleCode) => {
  switch (role) {
    case ROLE_PERFORMER:
      return 'BG Performer';
    case ROLE_COORDINATOR:
      return 'BG Coordinator';
    case ROLE_DIRECTOR:
      return 'Casting Director';
    case ROLE_PRODUCTION:
      return 'Production Director';
    case ROLE_PAYROLL:
      return 'Payroll';
    default:
      return '';
  }
};

export const getRoleId = (role: RoleCode) => {
  switch (role) {
    case ROLE_PERFORMER:
      return '60671874f9d2b991014963fa';
    case ROLE_COORDINATOR:
      return '6067185ff9d2b991014963f9';
    case ROLE_DIRECTOR:
      return '60671838f9d2b991014963f8';
    case ROLE_PRODUCTION:
      return '606717f8f9d2b991014963f7';
    case ROLE_PAYROLL:
      return '62d695b477cc9b1e19827c19';
    case 'ACCOUNT':
      return '6113cbe47b67b34e11060393';
    case 'ASSISTANT DIRECTOR':
      return '6113cc467b67b34e11060394';
    default:
      return '-1';
  }
};

export const getHomePage = (type: RoleCode) => {
  switch (type) {
    case ROLE_PERFORMER:
      return '/performer/home';
    case ROLE_DIRECTOR:
      return '/casting/home';
    case ROLE_PRODUCTION:
      return '/production/home';
    case ROLE_COORDINATOR:
      return '/coordinator/home';
    case ROLE_PAYROLL:
      return '/payroll/home';
    default:
      return '/casting/home';
  }
};

export const getBookingStatusColor = (status: BookingStatus) => {
  switch (status) {
    case 'requested':
    case 'pending':
      return '#f2994a';
    case 'notavailable':
      return '#333333';
    case 'booked':
      return '#eb5757';
    case 'available':
      return '#49D360';
    case 'no':
      return '#F2F2F2';
    default:
      return '#fff';
  }
};

export const getStatusTitle = (status: number) => {
  switch (status) {
    case 1:
      return 'available';
    case 2:
      return 'notavailable';
    case 3:
      return 'pending';
    case 4:
      return 'booked';
    default:
      return 'no';
  }
};

export const getStatusNumebr = (status: string) => {
  switch (status) {
    case 'available':
      return 2;
    case 'notavailable':
      return 0;
    default:
      return 1;
  }
};

export const getDateInUTC = (date: string) => {
  if (date === null || date === undefined) return '';
  return moment.utc(date).startOf('day').toISOString();
};

export const parseShowDatesIntoUTC = (data: Show) => {
  if (data) {
    if (data.episodes) {
      data.episodes.map((e: Episode) => {
        const obj = e;
        if (obj.episodeDays) {
          obj.episodeDays.map((d: ShowWorkingDay) => {
            const dObj = d;
            dObj.date = moment.utc(d.date).format('YYYY-MM-DD HH:mm:ss');
            return dObj;
          });
        }
        return obj;
      });
    }

    if (data.showWorkingDays) {
      data.showWorkingDays.map((d: ShowWorkingDay) => {
        const dObj = d;
        dObj.date = moment.utc(dObj.date).format('YYYY-MM-DD HH:mm:ss');
        return dObj;
      });
    }
  }
  return data;
};

export const formateMonthDate = (date: string) => {
  if (date === null || date === undefined) return '';
  return moment(date).format('MMMM DD');
};

export const skipJobPastDates = (dates: string[]) => {
  const ttl: number = dates.length;
  let date: string = '';
  let inc: number = 0;
  const currentDate = moment().utc().startOf('day');

  while (inc < ttl && date.length === 0) {
    const jobDate = moment.utc(dates[inc]).startOf('day');
    if (jobDate.isSameOrAfter(currentDate)) {
      date = jobDate.format('MMMM DD');
    }
    inc += 1;
  }
  return date.length === 0 ? formateMonthDate(dates[0]) : date;
};

export const formatJobDates = (dates: string[]) => {
  const ttl: number = dates.length;
  let date: string = '';
  let inc: number = 0;
  const currentDate = moment().utc().startOf('day');

  while (inc < ttl) {
    const jobDate = moment.utc(dates[inc]).startOf('day');
    if (jobDate.isSameOrAfter(currentDate)) {
      if (date.length === 0) {
        date = jobDate.format('MMM DD');
      } else {
        date = `${date}, ${jobDate.format('DD')}`;
      }
    }
    inc += 1;
  }
  return date.length === 0 ? formateMonthDate(dates[0]) : date;
};

export const getInUTCFormat = (date: string) => {
  if (date === null || date === undefined) return '';
  return moment.utc(date).startOf('day').toISOString();
};

export const parseDateInUTCFormat = (date: string, format: string) => {
  if (date === null || date === undefined) return '';
  return moment.utc(date).startOf('day').format(format);
};

export const formateDate = (date: string, format: string) => {
  if (date === null || date === undefined) return '';
  return moment(date).format(format);
};

export const formateJobDate = (dates: string[], format: string) => {
  if (dates.length === 1) return moment(dates[0]).format(format);
  return `${moment(dates[0]).format(format)}...`;
};

export const removeWhiteSpaces = (val: string) => val.replaceAll(/\s/g, '');

export const isValidFile = (file: File, type: string) => {
  const fileName = file.name;

  const exts = type === 'image' ? ['png', 'jpg', 'jpeg', 'gif'] : ['pdf', 'doc', 'docx'];

  if (fileName) {
    let getExt = fileName.split('.');
    getExt = getExt.reverse();

    if (!exts.includes(getExt[0].toLowerCase())) {
      return type === 'image' ? 'only image files are allowed' : 'only pdf files are allowed';
    }

    if (file.size / 1024 / 1024 > 2) {
      return 'max. 2MB file size allow';
    }

    return '';
  }
  return '';
};

export const getCurrentDate = () => `${moment().format('YYYY-MM-DD')} 00:00:00`;

export const isActiveDate = (d: string) => {
  const currentDate = `${moment().format('YYYY-MM-DD')} 00:00:00`;
  return moment(d).isSameOrAfter(currentDate, 'day');
};

export const isPastDate = (d: string) => {
  const currentDate = `${moment().format('YYYY-MM-DD')} 00:00:00`;
  return moment(d).isBefore(currentDate, 'day');
};

export const getFormattedCurrentDate = (format: string) => moment().format(format);

export const getMinDate = (format: string) => moment().format(format);
export const getMaxDate = (format: string) => moment().add(90, 'days').format(format);

export const getAge = (dob?: string): string => {
  if (dob) {
    const age = moment().diff(moment(dob), 'years').toString();
    return `${age} years`;
  }
  return '';
};

export const performerDetailRedirect = {
  save: (obj: any) => localStorage.setItem('performerDetail', JSON.stringify(obj)),
  get: () => {
    const obj = localStorage.getItem('performerDetail') || null;
    if (obj) return JSON.parse(obj);
    return null;
  },
  clear: () => localStorage.removeItem('performerDetail'),
};

export const performerIdInLC = {
  save: (id: string) => localStorage.setItem('performerId', id),
  get: () => localStorage.getItem('performerId') || '',
  clear: () => localStorage.removeItem('performerId'),
};

export const performerIdInSession = {
  save: (id: string) => sessionStorage.setItem('performerId', id),
  get: () => sessionStorage.getItem('performerId') || '',
};

export const getName = (obj: User) => `${obj.firstName} ${obj.lastName}`;

export const getCalendarDates = (
  list: BookingRequestList,
  calendar: CalendarList,
  job: PerformerJobs[] | undefined,
) => {
  const date = moment();
  const arr: BookingDateList = [];
  for (let i = 0; i < 28; i += 1) {
    arr.push({
      id: i.toString(),
      month: date.format('MMM'),
      day: date.format('DD'),
      date: date.format('YYYY-MM-DD'),
      type: '',
    });
    date.add(1, 'days');
  }

  calendar.forEach((c: CalendarItem) => {
    const index = arr.findIndex((x) => c.workDate.includes(x.date));
    if (index !== -1) {
      arr[index].type = getStatusTitle(c.workStatus) || JOB_STATUS_PENDING;
    }
  });

  list.map((item: BookingRequest) => {
    item.jobId.dates.map((d: string) => {
      const index = arr.findIndex((x) => d.includes(x.date));
      if (index !== -1) {
        arr[index].type = item.status || JOB_STATUS_PENDING;
      }
      return d;
    });
    return item;
  });

  if (job) {
    job.forEach((b: PerformerJobs) => {
      b.dates.forEach((d: string) => {
        const index = arr.findIndex((x: BookingDate) => d.includes(x.date));
        if (index !== -1 && arr[index].type !== JOB_STATUS_BOOKED) {
          if (b.status === JOB_STATUS_BOOKED) {
            arr[index].type = JOB_STATUS_BOOKED;
          } else if (arr[index].type !== CALENDAR_STATUS_NOT_AVAILABLE) {
            arr[index].type = b.status === JOB_STATUS_REQUESTED ? JOB_STATUS_PENDING : b.status;
          }
        }
      });
    });
  }

  return arr;
};

export const formatHeight = (height: string) => {
  if (!height) return '';
  const arr = height.split('');
  if (arr.length > 1) {
    const start = arr[0];
    arr.splice(0, 1);
    const end = arr.join('');
    return `${start}'${end}"`;
  }
  return '';
};

export const formatWeight = (weight?: string) => {
  if (!weight) return '';
  return `${weight} lbs`;
};

export const formatInUnit = (value: string | undefined, type: string) => {
  if (!value) return '';
  const typeText = /^[0-9]?[a-zA-Z]+$/.test(value) ? '' : type;
  return `${value}${typeText}`;
};

export const saveUserOnLocalStorage = (user: User) => {
  localStorage.setItem(ProfileKey, JSON.stringify(user));
};

export const getFileName = (file: string) => {
  if (file) {
    return file.split('/').pop();
  }
  return 'file';
};

export const redirectPath = {
  save: (path: string) => localStorage.setItem('redirect', path),
  get: () => localStorage.getItem('redirect') || null,
  clear: () => localStorage.removeItem('redirect'),
};

export const openShowInNewTab = {
  save: (path: string) => localStorage.setItem('selectedShow', path),
  get: () => localStorage.getItem('selectedShow') || null,
  clear: () => localStorage.removeItem('selectedShow'),
};

export const tabID = {
  save: () => localStorage.setItem('tabID', Math.random().toString()),
  get: () => localStorage.getItem('redirect') || null,
};

export const selectedTabState = {
  save: (path: string) => sessionStorage.setItem('currentTab', path),
  get: () => sessionStorage.getItem('currentTab') || '1',
};

export const matrixRedirect = {
  save: (obj: any) => localStorage.setItem('redirectMatrix', JSON.stringify(obj)),
  get: () => {
    const obj = localStorage.getItem('redirectMatrix') || null;
    if (obj) return JSON.parse(obj);
    return null;
  },
  clear: () => localStorage.removeItem('redirectMatrix'),
};

export const saveJobKeyInLS = {
  save: (path: ShowDayJob) => localStorage.setItem('jobId', JSON.stringify(path)),
  get: () => {
    const data = localStorage.getItem('jobId') || null;
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },
  clear: () => localStorage.removeItem('jobId'),
};

export const saveBookingData = {
  save: (path: BookingRequest) => localStorage.setItem('bookingId', JSON.stringify(path)),
  get: () => {
    const data = localStorage.getItem('bookingId') || null;
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },
  clear: () => localStorage.removeItem('bookingId'),
};

export const getUnionValue = (profile: User) => {
  if (!profile.unionNo) {
    return 'Non-union';
  }
  if (profile.unionNo && profile.unionStatus) {
    return `#${profile.unionStatus.short}${profile.unionNo || ''}`;
  }
  return '';
};

export const getUnionClass = (profile: User) => {
  if (!profile.unionNo) {
    return 'union-grey';
  }
  if (profile.unionStatus?.short === 'GE') {
    return 'union-green';
  }

  if (profile.unionStatus?.short === 'AM') {
    return 'union-blue';
  }

  if (profile.unionStatus?.short === 'EX') {
    return 'union-yellow';
  }
  return 'union-grey';
};

export const defaultPagination = () => ({
  limit: 10,
  nextPage: 1,
  page: 1,
  prevPage: -1,
  totalPages: 1,
});

export const getRoleFromTitle = (role: string) => {
  switch (role) {
    case '60671874f9d2b991014963fa':
    case 'PERFORMER':
      return 'BG Performer';
    case '6067185ff9d2b991014963f9':
    case 'COORDINATOR':
      return 'BG Coordinator';
    case '60671838f9d2b991014963f8':
    case 'DIRECTOR':
      return 'Casting Director';
    case '606717f8f9d2b991014963f7':
    case 'PRODUCTION':
      return 'Production Director';
    case '6113cbe47b67b34e11060393':
    case 'ACCOUNT':
      return 'Accountant';
    case '6113cc467b67b34e11060394':
    case 'ASSISTANT DIRECTOR':
      return 'Assistant Director';
    case '62d695b477cc9b1e19827c19':
      return 'Payroll';
    default:
      return role;
  }
};

export const getPermissionLevel = (role: string): number => {
  switch (role) {
    case '60671874f9d2b991014963fa':
    case 'PERFORMER':
      return PERMISSION_LEVEL_NA;
    case '6067185ff9d2b991014963f9':
    case 'COORDINATOR':
      return PERMISSION_LEVEL_NA;
    case '60671838f9d2b991014963f8':
    case 'DIRECTOR':
      return PERMISSION_LEVEL_DC;
    case '606717f8f9d2b991014963f7':
    case 'PRODUCTION':
      return PERMISSION_LEVEL_PD;
    case '6113cbe47b67b34e11060393':
    case 'ACCOUNT':
      return PERMISSION_LEVEL_AC;
    case '6113cc467b67b34e11060394':
    case 'ASSISTANT DIRECTOR':
      return PERMISSION_LEVEL_AD;
    default:
      return PERMISSION_LEVEL_NA;
  }
};

export const getJobSearchActiveFilter = () => [
  { id: 1, label: 'Recently Calls', value: 'Recently Call' },
  { id: 2, label: 'Crowd Calls', value: 'Crowd Call' },
  { id: 3, label: 'Closing Soon', value: 'Closing Soon' },
];

export const clearUnReadNotificationList = () => {
  localStorage.removeItem('unreadNotificationList');
};

export const getImageThumbnail = (url?: string) => {
  if (!url) return '';
  const fileArr = url.split('/');
  if (fileArr.length > 1) {
    const fileName = fileArr[fileArr.length - 1].split('.')[0];
    return `${fileArr.splice(0, fileArr.length - 1).join('/')}/${fileName}-thumbnail.png`;
  }
  return url;
};

export const getEmptyShowStats = (): ShowStats => {
  const stats = {
    users: 0,
  };
  return stats;
};

export const currencyFormat = (value: any) => (value ? `$${value}` : '$0');

export const checkShortWord = (text: string, short: string) => {
  const reg = /SAE.*|PD.*|STN.*|BKG.*|FU.*|AM.*|EX.*/;
  const isValid = reg.test(text);
  if (isValid) {
    const newData = text.replace(/SAE|PD|STN|BKG|FU|AM|EX/g, short);
    return newData;
  }
  return `${short}-${text}`;
};

export const getPaginationObject = (page: number, countTotal: number, limit: number) => {
  const totalPages = Math.ceil(countTotal / limit);
  let nextPage = 0;
  const prevPage = -1;

  if (page * limit < countTotal) {
    nextPage = +page + 1;
  } else if (page * limit === countTotal) {
    nextPage = 0;
  } else if (page * limit > countTotal) {
    nextPage = 0;
  }

  return {
    page,
    limit,
    prevPage,
    nextPage,
    totalPages: totalPages === 0 ? 1 : totalPages,
  };
};

export const getLength = (item: any) => (item ? item.length : 0);

export const capitalizeLetter = (value: any) => {
  if (!value) return '';
  const val = value.trim();
  return `${val.substring(0, 1).toUpperCase()}${val.substring(1).toLowerCase()}`;
};

export const randomNumberInRange = (min: number, max: number) => Math.ceil(Math.random() * (max - min) + min);

export const getTimeDifference = (startTime: any, endTime: any) => {
  const first = startTime.split(':');
  const second = endTime.split(':');
  const signInTime = first[0] < 10 ? `0${first[0]}:${first[1]}` : startTime;
  const wrapTime = second[0] < 10 ? `0${second[0]}:${second[1]}` : endTime;
  const datetime1: any = new Date(`1970-01-01T${signInTime}Z`);
  const datetime2: any = new Date(`1970-01-01T${wrapTime}Z`);
  const diff = datetime2 - datetime1;
  const diffInHours = diff / 1000 / 60 / 60;
  const diffInMinutes = (diffInHours - Math.floor(diffInHours)) * 60;
  // const diffInSeconds = (diffInMinutes - Math.floor(diffInMinutes)) * 60;
  const hours = Math.floor(diffInHours).toString().padStart(2, '0');
  const minutes = Math.floor(diffInMinutes).toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const rateValues = {
  rateId: '',
  rateName: '',
  template: '',
  minimumHours: '',
  hourlyRate: '',
  isOverTime: false,
  overTime: [],
  isRateBumps: false,
  rateBumpsPercentage: '',
  rateBumpsDollars: '',
  lunchOption: '',
  mpPercentage: '',
  mpDollars: '',
  mpHours: '',
  mpGraceTime: '',
  isCostume: false,
  costumeRate: '',
  rental: '',
  isCustomRental: false,
  customRental: '',
  isTravel: false,
  travelDistance: '',
  travelTime: '',
  categories: '',
};

export const UNION_TYPE = ['Full Union', 'Non Union', 'Apprentice Union', 'UBCP Extra'];

export function formatTime(time: any) {
  // Split the time into hours and minutes

  if (time) {
    // Split the time into hours and minutes
    let [hours, minutes] = time.split(':').map(Number);

    // Find the nearest lower multiple of 6
    const lowerMultipleOf6 = Math.floor(minutes / 6) * 6;
    const upperMultipleOf6 = lowerMultipleOf6 + 6;

    // Check if we should round up or down based on the 3-minute rule
    if (minutes - lowerMultipleOf6 >= 3) {
      minutes = upperMultipleOf6;
    } else {
      minutes = lowerMultipleOf6;
    }

    // If minutes reach 60, increment the hour and reset minutes to 0
    if (minutes === 60) {
      minutes = 0;
      hours += 1;
    }

    // Ensure hours and minutes are two digits
    hours = hours.toString().padStart(2, '0');
    minutes = minutes.toString().padStart(2, '0');

    // Return the formatted time without colon
    return `${hours}${minutes}`;
  }

  return '';
}

export function formatCombinedTime(timeRange: any) {
  if (timeRange) {
    // Split the range into start and end times
    const [startTime, endTime] = timeRange.split('-');

    // Use the previously defined function to format both times
    const formattedStartTime = formatTime(startTime);
    const formattedEndTime = formatTime(endTime);

    // Return the formatted time range
    return `${formattedStartTime}-${formattedEndTime}`;
  }

  return '';
}
