import { connect } from 'react-redux';
import { Show } from 'modules/Show/show.types';
import {
  getPerformerSearchResult,
  perforemerViewMode,
  performerSearchLoading,
  performerSearchPagination,
} from 'store/selector/show.selector';
import BgSearchResultContainer from 'containers/PerformerSearchResultContainer/BgSearchResultContainer';
import { loadMoreBgPerformer } from 'store/actions/show.actions';
import { UserList } from 'modules/user/types';

const mapStateToProps = (state: Show) => {
  const isLoading: boolean = performerSearchLoading(state);
  const performerResult: UserList = getPerformerSearchResult(state);
  const isFullView = perforemerViewMode(state);
  const pagination = performerSearchPagination(state);

  return { performerResult, isFullView, isLoading, pagination };
};

const mapDispatchToProps = {
  loadMoreBgPerformer,
};

export default connect(mapStateToProps, mapDispatchToProps)(BgSearchResultContainer);
