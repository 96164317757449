import React, { FunctionComponent, useEffect, useState } from 'react';
import { OptionList, Options, RateOptionList, SkillCategoryList } from 'modules/general/general.type';
import Select, { components } from 'react-select';
import Professional from 'assets/images/diamond.png';
import Advanced from 'assets/images/star.png';
import Average from 'assets/images/wave.png';
import AboveAverage from 'assets/images/meter.png';
import { SelectOptionType, SelectOptionTypeList } from 'modules/PerformerList/list.types';

interface DashboardSelectBoxProps {
  sort?: boolean;
  data?: any;
  label?: string;
  value?: string;
  defaultValue?: string;
  handleSelect?: Function;
  optionData?: OptionList;
  locationData?: OptionList;
  rateData?: RateOptionList;
  skills?: SkillCategoryList;
  unionData?: OptionList;
  optionType?: string;
  isMultiSelect?: boolean;
  cssClass?: string;
  profileFieldMargin?: string;
  showIcon?: boolean;
  selectedObj?: any;
  menuOpen?: boolean;
  requiredField?: boolean;
  storeObject?: boolean;
  showAllLabel?: boolean;
  emailTemplateList?: SelectOptionTypeList;
  rolesDataOptions?: SelectOptionType[];
}
const DropDownSearch: FunctionComponent<DashboardSelectBoxProps> = (props: DashboardSelectBoxProps) => {
  const {
    data,
    handleSelect,
    label,
    cssClass,
    value,
    defaultValue,
    optionData,
    optionType,
    unionData,
    skills,
    locationData,
    rateData,
    isMultiSelect,
    showIcon,
    selectedObj,
    menuOpen,
    storeObject,
    profileFieldMargin,
    showAllLabel,
    emailTemplateList,
    rolesDataOptions,
    sort,
    requiredField,
  } = props;

  const setValue = () => {
    if (storeObject && selectedObj) {
      return [...selectedObj];
    }
    if (isMultiSelect) {
      if (value !== undefined && value.length > 0) {
        const arr = value.split(',').map((x, index) => ({ id: index.toString(), value: x, label: x }));
        return [...arr];
      }
      return [];
    }
    if (selectedObj) return selectedObj;
    if (value && value !== undefined && value.length > 0) return { value, label: value };
    if (defaultValue !== undefined && defaultValue.length > 0) return { value: defaultValue, label: defaultValue };

    return [];
  };

  const [selectedValue, setSelectedValue] = useState(setValue());
  const [selectedData, setSelectedData] = useState<any>(data);

  useEffect(() => {
    setSelectedData(data);
  }, [data]);

  useEffect(() => {
    setSelectedValue(setValue());
  }, [value, selectedObj]);

  const onChangeValue = (val: any) => {
    if (handleSelect) {
      if (storeObject) {
        handleSelect(val);
      } else if (isMultiSelect) {
        const values = val.map((x: any) => x.value);
        handleSelect(values.join(','));
      } else {
        handleSelect(val.value);
      }
    }
  };

  const loadOptions = () => {
    if (selectedData) {
      return selectedData;
    }

    if (optionData && optionType) {
      if (optionType === 'location') {
        return locationData;
      }
      if (optionType === 'union') {
        return unionData;
      }
      if (optionType === 'skills') {
        return skills;
      }
      if (optionType === 'emailTemplate') {
        return emailTemplateList;
      }
      if (optionType === 'roles') {
        return rolesDataOptions;
      }
      if (optionType === 'rates') {
        return rateData;
      }

      return optionData.filter((x: Options) => x.type === optionType);
    }

    return [];
  };

  const getIcon = (type: string) => {
    switch (type) {
      case 'professional':
        return Professional;
      case 'expert':
        return Advanced;
      case 'average':
        return Average;
      case 'aboveaverage':
        return AboveAverage;
      default:
        return Professional;
    }
  };

  const ValueContainer = (containerProps: any) => {
    const { children } = containerProps;
    if (children[0]) {
      const { length } = children[0];
      const tmpChildren = [...children];
      if (length >= 2) {
        tmpChildren[0] = `${length} selected`;
      }
      return <components.ValueContainer {...containerProps}>{tmpChildren}</components.ValueContainer>;
    }
    return <components.ValueContainer {...containerProps}>{children}</components.ValueContainer>;
  };

  if (showIcon) {
    const { Option } = components;
    const CustomSelectOption = (cprops: any) => (
      <Option {...cprops}>
        <img src={getIcon(cprops.data.icon)} alt="" />
        {cprops.data.label}
      </Option>
    );

    const CustomSelectValue = (csprops: any) => (
      <div>
        {csprops.data.label && <img src={getIcon(csprops.data.icon || csprops.data.value)} alt="" />}
        {csprops.data.label}
      </div>
    );

    return (
      <>
        <div className={`form-group ${profileFieldMargin}`}>
          <span className={cssClass}>{label}</span>
          <Select
            value={setValue()}
            onChange={onChangeValue}
            options={loadOptions()}
            menuIsOpen={menuOpen}
            components={{ Option: CustomSelectOption, SingleValue: CustomSelectValue }}
          />
        </div>
      </>
    );
  }

  const handleSort = (val: any) => {
    if (!sort) return;
    if (val.length === 1) {
      const result = data.filter((i: any) => {
        const split = i.label.split("'");
        if (split[0] === val) return i;
        return null;
      });
      setSelectedData(result);
    } else setSelectedData(data);
  };

  const setSelectedComponent = () => {
    if (isMultiSelect && showAllLabel) {
      return (
        <Select
          onChange={onChangeValue}
          options={loadOptions()}
          isMulti
          isClearable
          value={selectedValue}
          name={optionType}
          hideSelectedOptions
        />
      );
    }
    if (isMultiSelect) {
      return (
        <Select
          onChange={onChangeValue}
          options={loadOptions()}
          isMulti
          isClearable
          value={selectedValue}
          name={optionType}
          hideSelectedOptions
          components={{ ValueContainer }}
        />
      );
    }
    if (sort) {
      return (
        <Select
          onInputChange={handleSort}
          name={optionType}
          value={setValue()}
          onChange={onChangeValue}
          options={loadOptions()}
        />
      );
    }
    return (
      <Select
        className={value ? 'selected' : ''}
        name={optionType}
        value={setValue()}
        onChange={onChangeValue}
        options={loadOptions()}
      />
    );
  };

  return (
    <>
      <div className={`form-group ${profileFieldMargin} ${cssClass}`}>
        {label && <span className={`position-relative ${cssClass} ${requiredField && 'requiredField'}`}>{label}</span>}
        {setSelectedComponent()}
      </div>
    </>
  );
};
export default DropDownSearch;
