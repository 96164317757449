/* eslint-disable react/destructuring-assignment */
import React, { FunctionComponent } from 'react';

import Styled from 'styled-components';

interface IndicatorProps {
  text?: string;
  color?: string;
  marginLeft?: string;
}

const IndicatorComponent: FunctionComponent<IndicatorProps> = (props: IndicatorProps) => (
  <IndicatorContainer marginLeft={props.marginLeft}>
    <Indicator color={props.color} />
    <IndicatorTextContainer>{props.text ? props.text : ''}</IndicatorTextContainer>
  </IndicatorContainer>
);

const IndicatorContainer = Styled.div`
display: flex;
align-items: center;
height: 100%;
margin-left: ${(props: IndicatorProps) => (props.marginLeft ? props.marginLeft : '0px')};
`;

const Indicator = Styled.span<{ color: string | undefined }>`
  margin: 0;
  height: 15px;
  width: 15px;
  background-color: #${({ color }) => color || 'F2994A'};
  border-radius: 50%;
  display: inline-block;
`;
const IndicatorTextContainer = Styled.p`
  color: #828282;
  margin: 0;
  padding-left: 8px;
  font-size: 13px;
  font-family: 'Montserrat';
  font-weight: 500;
  @media (min-width: 1340px) {
    font-size: calc(13px + 1 * ((100vw - 1280px) / 320));
  }
  @media (min-width: 1600px) {
    font-size: 14px;
  }  

`;

export default IndicatorComponent;
