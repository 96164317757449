import React, { FunctionComponent, useEffect, useState } from 'react';
import sortSvg from 'assets/svg/sort.svg';
import editSvg from 'assets/svg/edit.svg';
import exclamationSvg from 'assets/svg/exclamation-mark.svg';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import upArrowSvg from 'assets/svg/up-arrow.svg';
import copySvg from 'assets/svg/copy.svg';
import deleteSvg from 'assets/svg/delete.svg';
import prelimSvg from 'assets/svg/prelimSvg.svg';
import finalSvg from 'assets/svg/finalSvg.svg';
import Style from 'components/castingDirector/MatrixItem/MatrixItem.module.css';
import { MatrixAtoms } from 'modules/Show/show.types';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import { GridApi } from '@ag-grid-community/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import MatrixItemTitle from 'components/castingDirector/MatrixItem/MatrixItemTitle';
import showApi from 'store/services/show.services';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';
import { debounce } from 'lodash';
import PerformeRedirectLink from 'components/castingDirector/MatrixItem/PerformerRedirectLink';
import { useSelector } from 'react-redux';
import { getRates } from 'store/selector/general.selector';
import PerformerVoucher from 'components/castingDirector/MatrixItem/PerformerVoucher';
import { UNION_TYPE } from 'helpers/utils';

interface MatrixItemProps {
  atoms: MatrixAtoms;
  grid?: GridApi;
  gridDrop: Function;
  gridDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  index: number;
  atomsMenu: Function;
  handleRemoveLeftMenu: Function;
  handleUpdateAtomsTitle: Function;
  sendCallToSpecificBg: Function;
  updateUnionRate: Function;
  removeDeletedPerformer: Function;
  copyFromAbove: Function;
  handleCopy: Function;
  handlePaste: Function;
  updateGridInstance: Function;
  updateGridDataOnServer: Function;
  checkCallTimeFromAbove: Function;
  rateList: any;
}

const MatrixItem: FunctionComponent<MatrixItemProps> = (props: MatrixItemProps) => {
  const {
    atoms,
    index,
    grid,
    gridDrop,
    gridDragOver,
    atomsMenu,
    handleRemoveLeftMenu,
    handleUpdateAtomsTitle,
    sendCallToSpecificBg,
    updateUnionRate,
    removeDeletedPerformer,
    copyFromAbove,
    handleCopy,
    handlePaste,
    updateGridInstance,
    updateGridDataOnServer,
    checkCallTimeFromAbove,
    rateList,
  } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const rates = useSelector(getRates);

  useEffect(() => {
    if (atoms.isUpdate > 0) {
      if (grid) {
        grid.refreshCells({
          force: true,
        });
      }
    }
  }, [atoms.isUpdate]);

  const onGridReady = (params: any) => {
    const { api } = params;
    api.position = index;
    api.hideOverlay();
    api.sizeColumnsToFit();
    updateGridInstance(index, api);
  };

  const disableCopyOption = () => {
    const copiedPerformer = getItemFromLocalStorage('copyItem');
    return !copiedPerformer;
  };

  const getContextMenuItems = (params: any) => {
    document.body.click();
    const result: any = [
      {
        name: 'Copy',
        action: () => {
          handleCopy(index, params);
        },
        icon: `<img src=${copySvg} alt="copy icon" />`,
      },
      {
        name: 'Paste',
        action: () => {
          handlePaste(index, params);
        },
        disabled: disableCopyOption(),
        icon: `<img src=${copySvg} alt="copy icon" />`,
      },
      {
        name: 'Remove performer',
        shortcut: 'Cltr + D',
        action: () => {
          handleRemoveLeftMenu(index, params);
        },
        icon: `<img src=${deleteSvg} />`,
      },
      'separator',
    ];

    const secondPart: any = [
      'separator',
      {
        name: 'copy to from above',
        action: () => {
          copyFromAbove(index, params);
        },
      },
      'separator',
      {
        name: 'Send call sheet',
        action: () => {
          sendCallToSpecificBg(index, params);
        },
      },
      'separator',
    ];

    rates.map((opt: any) =>
      result.push({
        name: `set rate to ${opt.title}`,
        action: () => {
          updateUnionRate(index, params, opt.code);
        },
      }),
    );

    return result.concat(atomsMenu(index, params)).concat(secondPart);
  };

  const handleUpdateTitle = (val: string) => {
    setIsEdit(false);
    if (val) {
      handleUpdateAtomsTitle(index, val);
    }
  };

  const handleDeleteCell = (e: any) => {
    const deleteView = async () => {
      try {
        const result = await showApi.deleteMatrixItemRead({ id: e.data.id, showId: e.data.showId || atoms.showId });
        if (result || result === 'true') {
          removeDeletedPerformer(index, e.data.id);
          const tmpObj = e.data;
          tmpObj.performerId = '';
          tmpObj.performerName = '';
          tmpObj.isDelete = false;
          tmpObj.callTime = '';
          tmpObj.deleteReason = '';
          tmpObj.status = 'pending';
          if (e.api) {
            const transaction = {
              remove: [e.data],
              add: [tmpObj],
            };
            e.api.applyTransaction(transaction);
          }
        }
      } catch {
        console.log('Error');
      }
    };
    const f = () => {
      if (!e.data.performerId) return <span className={`${Style.status} ${Style.Empty}`} />;
      if (e.value === 'requested') return <span className={`${Style.status} ${Style.statusPending}`} />;
      if (
        (e.data.callSheetType === 'prelim' && !e.data.prelimConfirmTime) ||
        (e.data.callSheetType === 'final' && !e.data.finalConfirmTime)
      ) {
        return <span className={`${Style.status} ${Style.statusEmailPending}`} />;
      }
      if (e.data.callSheetType === 'final' && e.data.finalConfirmTime) {
        return <img src={finalSvg} alt="final call" className={Style.prelimAndFinalCall} />;
      }
      if (e.data.prelimConfirmTime && e.data.callSheetType === 'prelim') {
        return <img src={prelimSvg} alt="prelim call" className={Style.prelimAndFinalCall} />;
      }
      return <span className={`${Style.status} ${Style.Empty}`} />;
    };
    const info = (
      <div>
        <h4 className={`px-2 ${Style.infoTitle}`}>Info</h4>
        <p className="mb-0 px-2">Performer not available</p>
      </div>
    );
    return (
      <span>
        {e.data.isDelete ? (
          <span onClick={deleteView} onKeyPress={deleteView} role="button" tabIndex={0}>
            <PopoverContainer
              cssClass={Style.bookingMessage}
              itemComponent={() => {}}
              toolTip={e.data.deleteReason || info}
            >
              <img src={exclamationSvg} alt="" className={Style.exclamationMark} />
            </PopoverContainer>
          </span>
        ) : (
          <>{f()}</>
        )}
      </span>
    );
  };

  const setTitleLayout = () => {
    if (isEdit) {
      return <MatrixItemTitle title={atoms.title} handleUpdateTitle={handleUpdateTitle} />;
    }

    return (
      <>
        <div className={`d-flex justify-content-between align-items-center ${Style.sortingWrapper}`}>
          <img src={sortSvg} alt="sort icon" className={`mr-3 ${Style.sortImage}`} />
          <p className="mb-0">{atoms.title}</p>
        </div>
        <div className={Style.actionsWrapper}>
          <div onClick={() => setIsEdit(true)} onKeyUp={() => setIsEdit(true)} role="button" tabIndex={0}>
            <img src={editSvg} alt="edit icon" className={Style.editIcon} />
          </div>
        </div>
      </>
    );
  };
  const pasteData: string[] = [];
  let gridIndex: number = -1;

  const updateData = debounce(() => processData(), 500);
  const processData = () => {
    updateGridDataOnServer(pasteData, gridIndex);
    pasteData.splice(0, pasteData.length);
    gridIndex = -1;
  };

  const onCellValueChanged = (params: any) => {
    gridIndex = params.api.position;
    if (pasteData.indexOf(params.node.id) === -1) pasteData.push(params.node.id);
    updateData();
  };

  const getRowClass = (params: any) => {
    if (params.data.status === 'requested' || params.data.isDelete) {
      return true;
    }
    return false;
  };

  const rowClassRule = {
    'ag-grid-text-grey': getRowClass,
  };
  const handleCellIconClick = (item: any, isCheck: boolean) => {
    console.log('here in icon');
    const data = item;
    if (data.colDef.field === 'isAllowancesEnable') {
      data.data.isAllowancesEnable = isCheck;
      gridIndex = item.api.position;
      if (pasteData.indexOf(item.node.id) === -1) pasteData.push(item.node.id);
      updateData();
    }
  };
  const customRenderCell = (e: any) => <PerformerVoucher params={e} handleCellIconClick={handleCellIconClick} />;

  const handleTime = (params: any) => {
    const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(params.newValue);
    const data = params;
    if (params.newValue === '') {
      data.data.callTime = null;
      return true;
    }
    if (isValid) {
      const isCheck = checkCallTimeFromAbove(params);
      if (isCheck) return false;

      data.data.callTime = params.newValue;
      return true;
    }
    data.data.callTime = params.oldValue;
    return false;
  };

  const viewPerformerDetails = (e: any) => (
    <PerformeRedirectLink performerId={e.data.performerId} performerName={e.data.performerName} />
  );

  const paramsRenderer = (pro: any) => {
    const template = pro.data.jobId.rate?.values && pro.data.bookingId ? pro.data.jobId.rate.values.template : '';
    return (
      <div>
        <p className="mb-0">{template}</p>
      </div>
    );
  };

  const columnsWithDefaults = [
    { field: 'status', width: 20, cellRenderer: 'handleDeleteCell', headerName: '' },
    {
      field: 'performerName',
      width: 190,
      dndSource: true,
      sortable: true,
      cellRenderer: 'viewPerformerDetails',
      checkboxSelection: true,
      headerName: 'Name',
      headerTooltip: 'Name',
    },
    {
      field: 'callTime',
      width: 50,
      valueSetter: handleTime,
      sortable: true,
      editable: true,
      headerTooltip: 'callTime',
    },
    {
      field: 'voucherType',
      sortable: true,
      editable: true,
      width: 150,
      headerName: 'Select Voucher Type',
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: rateList?.map((item: any) => item?.title),
        allowTyping: true,
        filterList: true,
        highlightMatch: true,
        valueListMaxHeight: 220,
      },
    },
    { field: 'unionNo', width: 70, editable: true, sortable: true, headerName: 'UnionNo', headerTooltip: 'unionNo' },
    { field: 'rate', width: 80, editable: true, headerName: 'Rate', sortable: true, headerTooltip: 'rate' },
    { field: 'role', width: 120, editable: true, sortable: true, headerTooltip: 'role' },
    { field: 'note', editable: true, headerTooltip: 'rate' },
  ];
  const [columns] = useState<any>(columnsWithDefaults);
  return (
    <div className={`${Style.matrixItemWrapper}`}>
      <div className={`d-flex justify-content-between align-items-center ${Style.matrixItemInner}`}>
        <div className={`d-flex justify-content-between align-items-center ${Style.setLayoutWrapper}`}>
          {setTitleLayout()}
        </div>
        <div
          onClick={() => setIsExpanded(!isExpanded)}
          onKeyUp={() => setIsExpanded(!isExpanded)}
          role="button"
          tabIndex={0}
        >
          <img src={isExpanded ? upArrowSvg : downArrowSvg} alt="" className={Style.matrixItemDownArrow} />
        </div>
      </div>
      {isExpanded && (
        <div
          className="inner-col w-100 ag-theme-alpine"
          style={{ width: '100%' }}
          onDrop={(e) => gridDrop(index, e)}
          onDragOver={gridDragOver}
        >
          <AgGridReact
            modules={[MenuModule, ClientSideRowModelModule, RangeSelectionModule, ExcelExportModule, RichSelectModule]}
            frameworkComponents={{
              handleDeleteCell,
              viewPerformerDetails,
              customRenderCell,
            }}
            getRowNodeId={(data) => data.id}
            domLayout="autoHeight"
            rowHeight={30}
            headerHeight={40}
            rowData={atoms.performers}
            rowSelection="multiple"
            enableMultiRowDragging
            rowDragManaged
            animateRows
            enableRangeSelection
            suppressMultiRangeSelection
            enableFillHandle
            suppressContextMenu={false}
            suppressRowClickSelection
            undoRedoCellEditing
            undoRedoCellEditingLimit={1}
            defaultColDef={{
              flex: 1,
              minWidth: 15,
              resizable: false,
              headerComponentParams: { menuIcon: 'fa-bars' },
            }}
            onGridReady={(params) => onGridReady(params)}
            getContextMenuItems={getContextMenuItems}
            popupParent={document.body}
            rowClassRules={rowClassRule}
            onCellValueChanged={onCellValueChanged}
          >
            {columns.map((column: any) => (
              <AgGridColumn {...column} key={column.field} />
            ))}
          </AgGridReact>
        </div>
      )}
    </div>
  );
};

export default MatrixItem;
