/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form } from 'react-bootstrap';
import Styled from 'styled-components';
import CreateIcon from 'assets/images/plusIcon.svg';
import CloseIcon from 'assets/images/closeButton.svg';
import { faFileAlt, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createUserVoucherBegin } from 'store/actions/performer.actions';
import { getShowLists } from 'store/selector/show.selector';
import DatPicker from '../../../date-picker/DatePicker';
import { Show, ShowList } from '../../../../../modules/Show/show.types';

interface ModalInterface {
  onHide: () => void;
  show: boolean;
  showList?: ShowList;
  createUserVoucher?: any;
  performerId?: string;
}

interface IconInterface {
  color?: string;
  marginTop?: string;
  marginRight?: string;
  fontSize?: string;
}

interface DateSelectInterface {
  showDate: boolean;
}

const CreateVoucherModal = (props: ModalInterface) => {
  const { onHide, showList, performerId, createUserVoucher } = props;
  const [voucher, setVoucher] = useState({ date: '', voucherInfo: performerId, showId: '' });
  console.log(showList);
  const [showDate, setShowDate] = useState(false);
  const [calendarDate, setCalendarDate] = useState('');
  const toggleDate = () => {
    setShowDate(!showDate);
  };
  const onChangeFilter = (value: any, attribute: string) => {
    setVoucher({ ...voucher, [attribute]: value });
    toggleDate();
  };
  const createVoucherHandle = () => {
    createUserVoucher(voucher);
  };
  return (
    <ModalCOntainer {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <HeaderConainer>
          <LeftContainer>
            <StyledDocument icon={faFileAlt} />
            <p id="contained-modal-title-vcenter">Create Voucher</p>
          </LeftContainer>
          <StyledCloseButton>
            <ButtonImage onClick={onHide} src={CloseIcon} />
          </StyledCloseButton>
        </HeaderConainer>
        {/* <CloseButton /> */}
      </Modal.Header>
      <Modal.Body>
        <p>
          Select Show
          <Form.Control
            as="select"
            aria-label="Default select example"
            onChange={(e) => onChangeFilter(e.target.value, 'showId')}
          >
            <option>-- select --</option>
            {showList && showList.map((item) => <option value={item.id}>{item.title}</option>)}
          </Form.Control>
        </p>
        <p>
          Select Date
          <OptionContainer>
            <ControlledButton onClick={toggleDate}>
              {calendarDate || '-- select  -- '}

              {showDate ? (
                <StyledDocument marginTop="0" marginRight="0" color="#495057" icon={faChevronUp} fontSize="10" />
              ) : (
                <StyledDocument marginTop="0" marginRight="0" color="#495057" icon={faChevronDown} fontSize="10" />
              )}
            </ControlledButton>
          </OptionContainer>
        </p>
        <DateContainer showDate={showDate}>
          <DatPicker open={showDate} setDate={setCalendarDate} onChangeDate={onChangeFilter} />
        </DateContainer>
      </Modal.Body>
      <StyledModalFooter>
        <StyledButton onClick={onHide}>
          <ButtonImage src={CreateIcon} onClick={createVoucherHandle} />
          Create
        </StyledButton>
      </StyledModalFooter>
    </ModalCOntainer>
  );
};
const ModalCOntainer = Styled(Modal)`
width: 600px;
position: absolute;
left: 30%;
border-radius: 15px;
`;
const StyledButton = Styled.button`
border-radius: 10px;
padding: 10px;
`;

const ButtonImage = Styled.img`
  height: 15px;
  width: 15px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
`;

const OptionContainer = Styled.div`
// y
`;

const DateContainer = Styled.div`
margin-top: 20px;
z-index: 1000;

display: ${(props: DateSelectInterface) => (props.showDate ? 'block' : 'none')};
`;

const ControlledButton = Styled.p`
width : 100%;
padding-top: .405rem;
padding-bottom: .405rem;
padding-right: .395rem;
padding-left: .75rem;
border-radius: 5px;
border: 1px solid #ced4da;
color: #495057 !important;
display: flex;
justify-content: space-between;
background: #fff !important;
align-items: center;
`;

const StyledCloseButton = Styled.div`
margin-top:"20px"
`;

const HeaderConainer = Styled.div`
display: flex;
justify-content: space-between;
width: 100%;
`;

const StyledModalFooter = Styled(Modal.Footer)`
border-top: 0px!important;
`;

const StyledDocument = Styled(FontAwesomeIcon)<IconInterface>`
color: ${({ color }) => (color ? `${color}` : '#828282')} ;
margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '25px')};
margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : '10px')};
font-size:  ${({ fontSize }) => (fontSize ? `${fontSize}px` : '')}
`;
const LeftContainer = Styled.div`
width: 50%;
display: flex;
flex-direction: row;
`;

const mapStateToProps = (state: Show) => {
  const showList = getShowLists(state);
  return { showList };
};
const mapDispatchToProps = {
  createUserVoucher: createUserVoucherBegin,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateVoucherModal);
