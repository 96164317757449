import React, { FunctionComponent, useState } from 'react';
import userSvg from 'assets/svg/profile.svg';
import Style from 'components/TopNavBar/TopNavBar.module.css';

interface ImageViewProps {
  src: string;
  alt: string;
  cssClass: string;
}

const ProfileImageView: FunctionComponent<ImageViewProps> = (props: ImageViewProps) => {
  const { src, alt, cssClass } = props;
  const [hasError, setHasError] = useState(false);

  // Handler for image load error
  const handleError = () => {
    setHasError(true);
  };

  return !hasError ? (
    <img src={src} alt={alt} onError={handleError} className={cssClass} role="presentation" />
  ) : (
    <img src={userSvg} alt="" className={Style.userAvatarPicture} />
  );
};

export default ProfileImageView;
