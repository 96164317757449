import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'store/selector/auth.selector';

const HomePageLink = () => {
  const user = useSelector(getUser);
  const userType = () => {
    if (user.role === 'DIRECTOR') return HomeLinkName('CASTING');
    if (user.role === 'PRODUCTION') return HomeLinkName('PRODUCTION');
    if (user.role === 'COORDINATOR') return HomeLinkName('COORDINATOR');
    if (user.role === 'PAYROLL') return HomeLinkName('PAYROLL');
    return HomeLinkName('PERFORMER');
  };

  const HomeLinkName = (type: String) => (
    <span className="mb-0 userType">
      <a href="/" target="_self">
        {type}
      </a>
    </span>
  );
  return (
    <>
      <h1>
        <a href="/" target="_self">
          <span className="mb-0">Casting</span>
          PAX
        </a>
        {userType()}
      </h1>
    </>
  );
};
export default HomePageLink;
