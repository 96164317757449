import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { Invitation } from 'modules/Show/show.types';
import Style from 'components/CreateShow/InvitationView/InvitationView.module.css';

interface InvitationViewProps {
  invitation: Invitation;
  handleClick: (e: React.FormEvent<HTMLSpanElement>) => void;
}
const InvitationView: FunctionComponent<InvitationViewProps> = (props) => {
  const { invitation, handleClick } = props;
  return (
    <div className={Style.userWrapper}>
      <div className={Style.emailTitle}>
        <p>{invitation.email}</p>
      </div>
      <span className="mb-0" role="button" tabIndex={0} onClick={handleClick} onKeyUp={handleClick}>
        <FontAwesomeIcon icon={faTimes} />
      </span>
    </div>
  );
};

export default InvitationView;
