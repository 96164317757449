import React from 'react';
import styled from 'styled-components';

interface Props {}

const SharedDropdown: React.FC<Props> = () => <StyledDiv>List here</StyledDiv>;

export default SharedDropdown;

const StyledDiv = styled.div`
  background: gray;
  width: 100%;
  height: 40px;
  margin-top: 5px;
`;
