import React, { FunctionComponent } from 'react';
import Style from 'components/bgPerformer/Resume/Resume.module.css';
import closeSvg from 'assets/svg/close.svg';
import { User } from 'modules/user/types';
import { getFileName } from 'helpers/utils';

interface ResumeProps {
  user: User;
  handleEditResume: Function;
}

const Resume: FunctionComponent<ResumeProps> = (props) => {
  const { user, handleEditResume } = props;
  const fileName1 = getFileName(user.resumeUrl1 || '');
  const fileName2 = getFileName(user.resumeUrl2 || '');
  if (!user) {
    return (
      <>
        <div className={Style.resumeHeading}> Resume </div>
      </>
    );
  }
  const handleCheckResume = (type: string) => {
    if (type === 'resume1') {
      if (user.resumeUrl1 !== undefined && fileName1 !== 'null' && fileName1 !== 'file') return true;
    } else if (type === 'resume2') {
      if (user.resumeUrl2 !== undefined && fileName2 !== 'null' && fileName2 !== 'file') return true;
    }
    return false;
  };
  return (
    <>
      {handleCheckResume('resume1') && (
        <div className={Style.resumeHeading}>
          <a href={user.resumeUrl1} target="_blank" rel="noreferrer">
            {getFileName(user.resumeUrl1 || '')}
          </a>
          <div
            onClick={() => {
              handleEditResume('resumeUrl1', 'del');
            }}
            role="presentation"
          >
            <img src={closeSvg} className={Style.deleteIcon} alt="deleteSvg" />
          </div>
        </div>
      )}
      {user.resumeUrl2 && handleCheckResume('resume2') && (
        <div className={Style.resumeHeading}>
          <a href={user.resumeUrl2} target="_blank" rel="noreferrer">
            {getFileName(user.resumeUrl2 || '')}
          </a>
          <div
            onClick={() => {
              handleEditResume('resumeUrl2', 'del');
            }}
            role="presentation"
          >
            <img src={closeSvg} className={Style.deleteIcon} alt="deleteSvg" />
          </div>
        </div>
      )}
    </>
  );
};
export default Resume;
