import React, { FunctionComponent } from 'react';
import Style from 'components/castingDirector/PopOvers/StatusDetail/Status.module.css';
import { setMatrixStatusBegin } from 'store/actions/show.actions';
import { connect } from 'react-redux';
import prelimSvg from 'assets/svg/prelimSvg.svg';
import finalSvg from 'assets/svg/finalSvg.svg';
import exclamationSvg from 'assets/svg/exclamation-mark.svg';

interface MatrixStatusProps {
  workingDayId: string;
  // status?: string;
  // updateStatus?: Function;
}

const MatrixStatusDetail: FunctionComponent<MatrixStatusProps> = (props: MatrixStatusProps) => {
  const { workingDayId } = props;
  console.log(workingDayId);
  // const statusOption: string[] = ['Pending', 'Live', 'Prelim', 'Final', 'Completed', 'Cancelled'];
  // const [selectedStatus, setSelectedStatus] = useState<string>(status || 'Pending');

  // const handleClick = (type: string) => {
  //   setSelectedStatus(type);
  //   if(updateStatus) updateStatus({ workingDayId, status: type });
  // };

  // return (
  //   <div className={Style.mainWrapper}>
  //     {statusOption.map((s: string, index: number) => (
  //       <div
  //         key={index}
  //         className={`d-flex justify-content-between align-items-center ${Style.itemWrapper}`}
  //         onClick={() => handleClick(s)}
  //         onKeyPress={() => handleClick(s)}
  //         tabIndex={0}
  //         role="button"
  //       >
  //         <p className="mb-0">{s}</p>
  //         {selectedStatus === s && <img src={checkSvg} alt="" className={Style.checkIcon} />}
  //       </div>
  //     ))}
  //   </div>
  // );
  return (
    <>
      <div className={Style.mainWrapper}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="mb-0">
            <div className={`d-flex align-items-center ${Style.itemWrapper}`}>
              <div>
                <img className={Style.statusImg} src={prelimSvg} alt="Status Icon" />
              </div>
              <p className={`mb-0 ${Style.statusHeading}`}>Prelim Email Confirmation</p>
            </div>
            <div className={`d-flex align-items-center ${Style.itemWrapper}`}>
              <div>
                <img className={Style.statusImg} src={finalSvg} alt="Status Icon" />
              </div>
              <p className={`mb-0 ${Style.statusHeading}`}>Final Email Confirmation</p>
            </div>
            <div className={`d-flex align-items-center ${Style.itemWrapper}`}>
              <div>
                <span className={`${Style.status} ${Style.statusEmailPending}`} />
              </div>
              <p className={`mb-0 ${Style.statusHeading}`}>Email Pending Confirmation</p>
            </div>
            <div className={`d-flex align-items-center ${Style.itemWrapper}`}>
              <div>
                <span className={`${Style.status} ${Style.statusPending}`} />
              </div>
              <p className={`mb-0 ${Style.statusHeading}`}>Pending Confirmation</p>
            </div>
            <div className={`d-flex align-items-center ${Style.itemWrapper}`}>
              <div>
                <img className={`${Style.exclamationMark}`} src={exclamationSvg} alt="Status Icon" />
              </div>
              <p className={`mb-0 ${Style.statusHeading}`}>Status Change</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  updateStatus: setMatrixStatusBegin,
};

export default connect(null, mapDispatchToProps)(MatrixStatusDetail);
