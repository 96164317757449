import { fromJS } from 'immutable';
import { PerformerJobs } from 'modules/jobs/types';
import { PerformerNote } from 'modules/PerformerList/list.types';
import { User } from 'modules/user/types';
import ACTIONS from 'store/castingPax.constants';

const initialState = fromJS({
  performer: { data: [], loading: false, error: '' },
  loading: false,
  error: '',
  selectedPerformer: {},
  performerNotes: { data: [], loading: false, error: '' },
});

export default function myPerformerReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.FETCH_MY_PERFORMER_LIST.PENDING: {
      return state.setIn(['performer', 'loading'], action.status === 'LOADING');
    }
    case ACTIONS.FETCH_MY_PERFORMER_LIST.SUCCESS: {
      return state
        .setIn(['performer', 'loading'], false)
        .setIn(['performer', 'error'], '')
        .setIn(['performer', 'data'], action.data);
    }
    case ACTIONS.FETCH_MY_PERFORMER_LIST.ERROR: {
      return state.setIn(['performer', 'loading'], false).set(['performer', 'error'], action.error);
    }
    case ACTIONS.SET_SELECTED_PERFORMER_FOR_VIEW: {
      return state.set('selectedPerformer', action.data);
    }
    case ACTIONS.FETCH_PERFORMER_DETAIL.SUCCESS: {
      return state.set('selectedPerformer', action.data);
    }
    case ACTIONS.FETCH_MY_PERFORMER_IMAGES.SUCCESS: {
      const prev: User = state.get('selectedPerformer') || {};
      if ('id' in prev && prev.bgPerformer) {
        prev.bgPerformer.images = [...action.data];
        return state.set('selectedPerformer', { ...prev });
      }
      return state;
    }
    case ACTIONS.SET_PERFORMER_UPDATED_DETAILS_IN_LIST: {
      const prev = state.get('selectedPerformer') || {};
      if ('id' in prev && action.data.length > 0 && action.data[0].data.length > 0) {
        return state.set('selectedPerformer', action.data[0].data[0]);
      }
      return state;
    }
    case ACTIONS.UPDATE_SELECTED_PERFORMER_CALENDAR: {
      const prev: User = state.get('selectedPerformer') || {};
      if ('id' in prev) {
        const jobs: PerformerJobs[] = prev.job || [];
        const index: number = jobs.findIndex((p: PerformerJobs) => p.id === action.data.id);
        if (index !== -1) {
          jobs.splice(index, 1);
        }
        prev.job = [...jobs, action.data];
        return state.set('selectedPerformer', { ...prev });
      }
      return state;
    }
    case ACTIONS.SET_CREATE_MY_PERFORMER_LIST.PENDING: {
      return state.set('loading', true);
    }
    case ACTIONS.SET_CREATE_MY_PERFORMER_LIST.SUCCESS: {
      const prevList = state.getIn(['performer', 'data']);
      return state
        .set('loading', false)
        .set('error', '')
        .setIn(['performer', 'data'], [...prevList, action.data]);
    }
    case ACTIONS.SET_CREATE_MY_PERFORMER_LIST.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.FETCH_PERFORMER_PERSONAL_NOTE.SUCCESS: {
      return state.set('loading', false).set('error', '').setIn(['performerNotes', 'data'], action.data);
    }
    case ACTIONS.SET_PERFORMER_PERSONAL_NOTE: {
      const prevNotes = state.getIn(['performerNotes', 'data']) || [];
      const index = prevNotes.findIndex((x: PerformerNote) => x.userId === action.data.userId);
      if (index !== -1) {
        prevNotes[index].note = action.data.note;
        return state.setIn(['performerNotes', 'data'], [...prevNotes]);
      }
      const obj: PerformerNote = {
        id: Math.random.toString(),
        userId: action.data.userId,
        note: action.data.note,
        directorId: action.data.directorId,
      };
      return state.setIn(['performerNotes', 'data'], [...prevNotes, obj]);
    }
    case ACTIONS.FETCH_PERFORMER_BOOKINGS.SUCCESS: {
      const prev: User = state.get('selectedPerformer') || {};
      if ('id' in prev) {
        if (prev.bgPerformer && prev.bgPerformer.bookingDates) prev.bgPerformer.bookingDates = [...action.data];
        return state.set('selectedPerformer', { ...prev });
      }
      return state.set('selectedPerformer', {});
    }
    case ACTIONS.FETCH_USER_JOB_STATUS.SUCCESS: {
      const prev: User = state.get('selectedPerformer') || {};
      if ('id' in prev && action.data) {
        prev.job = [...action.data];
        return state.set('selectedPerformer', { ...prev });
      }
      return state;
    }
    default: {
      return state;
    }
  }
}
