import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PlaceholderImage from 'assets/svg/cloud.svg';
import Style from 'components/bgPerformer/Images/Image.module.css';
import Loader from 'components/controls/Loader/Loader';
import { fileUploadingProgress } from 'store/selector/auth.selector';
import { useSelector } from 'react-redux';
import axios from 'store/services/axios';
import { BASE_URL } from 'store/services/URL';

interface DropZoneProps {
  type: string;
  fileUploadHandler: Function;
  loader: boolean;
  closeDialog: Function;
  isMultiple?: Boolean;
  showFileName?: any;
  setProgress: Function;
  docsRequest?: any;
  removeFileList: Function;
}

const DropZone: FunctionComponent<DropZoneProps> = (props: DropZoneProps) => {
  const {
    fileUploadHandler,
    setProgress,
    type,
    loader,
    closeDialog,
    isMultiple,
    showFileName,
    docsRequest,
    removeFileList,
  } = props;
  const [file, setFile] = useState<File[]>([]);
  const showFileUploadingProgress = useSelector(fileUploadingProgress);

  useEffect(() => {
    console.log('');
    return () => closeDialog();
  }, []);

  const onDrop = (acceptedFiles: any) => {
    // if (type === 'pdf') {
    //   if (isMultiple && acceptedFiles && acceptedFiles[0]) {
    //     const tmpfile: File[] = file;
    //     for (let i = 0; i < acceptedFiles.length; i += 1) {
    //       tmpfile.push(acceptedFiles[i]);
    //     }
    //     setFile([...tmpfile]);
    //     fileUploadHandler(tmpfile);
    //     return;
    //   }
    // }
    // if (acceptedFiles && acceptedFiles[0] && isMultiple) {
    //   const tmpfile: File[] = [...file];
    //   for (let i = 0; i < acceptedFiles.length; i += 1) {
    //     tmpfile.push(acceptedFiles[i]);
    //   }
    //   setFile([...tmpfile]);
    //   fileUploadHandler(tmpfile);
    // } else if (acceptedFiles && acceptedFiles[0]) {
    //   setFile([acceptedFiles[0]]);
    //   fileUploadHandler(acceptedFiles[0]);
    // }
    if (acceptedFiles.length === 0) {
      return;
    }
    showFileName(acceptedFiles);
    // if (type === 'pdf') {
    //   if (isMultiple && acceptedFiles && acceptedFiles[0]) {
    //     const tmpFile: any = [...file];
    //     for (let i = 0; i < acceptedFiles.length; i += 1) {
    //       tmpFile.push(acceptedFiles[i]);
    //     }
    //     setFile([...tmpFile]);
    //     fileUploadHandler([...tmpFile]);
    //   } else if (acceptedFiles && acceptedFiles[0]) {
    //     setFile([acceptedFiles[0]]);
    //     fileUploadHandler(acceptedFiles[0]);
    //   }
    //   return;
    // }
    if (acceptedFiles && acceptedFiles[0]) {
      const tmpFile: any = [];
      for (let i = 0; i < acceptedFiles.length; i += 1) {
        tmpFile.push({ file: acceptedFiles[i], status: 'pending', id: Math.ceil(Math.random() * 101.7) });
      }
      setFile([...tmpFile]);
      const axiosInstance = axios.create({
        baseURL: BASE_URL,
      });
      const formData = new FormData();
      formData.append('files', acceptedFiles[0]);
      const token = localStorage.getItem('accessToken');
      axiosInstance
        .post('/profile/upload_files', formData, {
          headers: {
            'X-Auth-Token': token ?? 'A',
            'Content-Type': 'application/json',
          },
          onUploadProgress: (data) => {
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then(() => {
          docsRequest();
          removeFileList([]);
        });
      // for (let i = 0; i < event.target.files.length; i++) {
      //   formData.append('files', event.target.files[i]);
      // }

      // dispatch(performerUploadFiles(formData));
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: type === 'pdf' ? 'application/pdf' : 'image/*',
  });

  const onImageChange = (event: any) => {
    if (type === 'pdf') {
      if (event.target.files && event.target.files[0] && isMultiple) {
        const tmpFile: any = [...file];
        for (let i = 0; i < event.target.files.length; i += 1) {
          tmpFile.push(event.target.files[i]);
        }
        setFile([...tmpFile]);
        fileUploadHandler([...tmpFile]);
      } else if (event.target.files && event.target.files[0]) {
        setFile([event.target.files[0]]);
        fileUploadHandler(event.target.files[0]);
      }
      return;
    }
    if (event.target.files && event.target.files[0]) {
      const tmpFile: any = [];
      for (let i = 0; i < event.target.files.length; i += 1) {
        tmpFile.push({ file: event.target.files[i], status: 'pending', id: Math.ceil(Math.random() * 101.7) });
      }
      setFile([...tmpFile]);
      const axiosInstance = axios.create({
        baseURL: BASE_URL,
      });
      const formData = new FormData();
      formData.append('files', event.target.files[0]);
      const token = localStorage.getItem('accessToken');
      axiosInstance
        .post('/profile/upload_files', formData, {
          headers: {
            'X-Auth-Token': token ?? 'A',
            'Content-Type': 'application/json',
          },
          onUploadProgress: (data) => {
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then(() => {
          docsRequest();
          removeFileList([]);
        });
      // for (let i = 0; i < event.target.files.length; i++) {
      //   formData.append('files', event.target.files[i]);
      // }

      // dispatch(performerUploadFiles(formData));
    }
  };

  return (
    <div className={Style.imageItemDialogWrapper} {...getRootProps()}>
      <input {...getInputProps()} onChange={onImageChange} accept="application/pdf" />
      {loader && type !== 'pdf' && <Loader isSmallView />}
      {showFileUploadingProgress && type === 'pdf' && <Loader isSmallView />}
      {type === 'pdf' ? (
        <>
          <img src={PlaceholderImage} alt="" className={`${Style.popupImage}`} />
          <p className={`mb-0 ${Style.popupTitle}`}>Drop the files here ...</p>
        </>
      ) : (
        <>
          <img style={{ padding: '25px 0 0' }} src={PlaceholderImage} alt="" className={`${Style.popupImage}`} />
          <p style={{ padding: '25px 0 15px 0' }} className={`mb-0 ${Style.popupTitle}`}>
            <span className={`mb-0 pl-2 ${Style.chooseFileText}`}> Choose your file</span>
            <br />
            {/* <span className={`mb-0 mt-0 pl-2 ${Style.fileSizeText}`}> min file 250px*250px</span> */}
          </p>
        </>
      )}
    </div>
  );
};

export default DropZone;
