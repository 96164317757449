import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  MatrixChartData,
  MatrixChartStats,
  MatrixObject,
  Show,
  TeamMember,
  TeamObject,
  UserCount,
} from 'modules/Show/show.types';
import Style from 'components/Producer/Matrix/Details/ShowDetail.module.css';
import budgetSvg from 'assets/svg/budget.svg';
import bookingStatusSvg from 'assets/svg/booking-status.svg';
import billingCostSvg from 'assets/svg/billing-cost.svg';
import dailyCostSvg from 'assets/svg/daily-cost.svg';
import Item from 'components/Producer/Matrix/Items/Items';
import UserListing from 'components/Producer/Matrix/UserManagementItem/UserListing';
import MatrixListing from 'components/Producer/Matrix/MatrixItem/MatrixListing';
import Modal from 'components/controls/Modal/Modal';
import CreateBudget from 'components/Producer/Matrix/Budget/CreateBudget';
import Chart from 'components/Producer/Matrix/Chart/Chart';
import { User } from 'modules/user/types';
import {
  LOADER_STATUS,
  PERMISSION_LEVEL_AC,
  PERMISSION_LEVEL_NA,
  PERMISSION_LEVEL_PD,
} from 'store/castingPax.constants';
import { getPermissionLevel } from 'helpers/utils';
import Header from 'views/producer/header/Header';
import DailyCostInvoices from 'components/Producer/Matrix/Invoices/DailyCostInvoices';
import html2canvas from 'html2canvas';
import showApi from 'store/services/show.services';
import moment from 'moment';
import { IDownloadInvoicesPdf } from 'modules/params/param.type';
import Jspdf from 'jspdf';

interface ShowDetailProps {
  user: User;
  show: Show;
  team: TeamObject;
  matrix: MatrixObject;
  chartData: any;
  showUserCount: UserCount[];
  showPermissions: TeamMember[];
  fetchStats: Function;
  clearStatsData: Function;
  fetchDailyCost: Function;
}
const ShowDetail: FunctionComponent<ShowDetailProps> = (props: ShowDetailProps) => {
  const {
    user,
    show,
    team,
    matrix,
    chartData,
    showUserCount,
    fetchStats,
    showPermissions,
    clearStatsData,
    fetchDailyCost,
  } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showChart, setShowChart] = useState<boolean>(false);
  const [chartType, setChartType] = useState<string>('');
  const [showDailyCost, setShowDailyCost] = useState<boolean>(false);
  const [addBudgetModal, setAddBudgetModal] = useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<number>(LOADER_STATUS.NA);
  const [loaderType, setLoaderType] = useState<string>('');
  const printRef = React.useRef();

  useEffect(() => {
    if (chartType === 'dailyCost') {
      fetchDailyCost({ limit: 10, page: 1, showId: show.id });
      setShowDailyCost(true);
      setChartType('');
      return;
    }
    if (chartType) {
      fetchStats({ id: show.id, type: chartType });
      setShowChart(true);
    }
  }, [chartType]);

  const handleAddBudget = () => {
    setAddBudgetModal(true);
    handleCloseChart();
  };

  const bookingChartData = useMemo(() => {
    const requiredData: number[] = [];
    const actualData: number[] = [];
    const color: string[] = [];
    let label: string[] = [];
    if (Object.keys(chartData).length > 0) {
      chartData.data.forEach((c: MatrixChartData) => {
        const pos: number = chartData.stats.findIndex((st: MatrixChartStats) => st.id === c.id);
        if (pos === -1) {
          requiredData.push(0);
          actualData.push(0);
          color.push('');
        } else {
          actualData.push(chartData.stats[pos].actualData);
          requiredData.push(chartType === 'budget' ? c.budget : chartData.stats[pos].count);
          color.push(chartData.stats[pos].color);
        }
      });
      label = chartData.data.map((x: any) => x.title);
    }

    const data = {
      labels: label,
      datasets: [
        {
          label: chartType === 'budget' ? 'Budget' : 'Required',
          stack: '2',
          order: 2,
          backgroundColor: chartType === 'budget' ? '#49D360' : '#E0E0E0',
          borderColor: chartType === 'budget' ? '#49D360' : '#E0E0E0',
          data: requiredData,
        },
        {
          label: chartType === 'budget' ? 'Total spend' : 'Booked',
          stack: '1',
          order: 1,
          backgroundColor: color,
          borderColor: 'rgb(75, 192, 192)',
          data: actualData,
        },
      ],
    };
    return data;
  }, [chartData]);

  const ttlUsers = useMemo(() => {
    const res = showUserCount.filter((x: UserCount) => x.id === show.id);
    if (res.length > 0) {
      return res[0].users;
    }
    return 0;
  }, [showUserCount]);

  const permissionLevel = useMemo(() => {
    if (show.createdBy === user.id) return PERMISSION_LEVEL_PD;

    const res = showPermissions.filter((x: TeamMember) => x.showId === show.id);

    if (res.length > 0) {
      return getPermissionLevel(res[0].showRole);
    }
    return PERMISSION_LEVEL_NA;
  }, [showPermissions]);

  const handleEditBudget = () => console.log('Edit');

  const handleCloseChart = () => {
    setChartType('');
    setShowChart(false);
    clearStatsData();
  };

  const handleDownload = (exportType: string, type: string, startDate: string, endDate: string) => {
    setLoaderType(exportType);
    if (exportType === 'jpeg') handleDownloadImage();
    if (exportType === 'pdf') handleDownloadPdf(type, startDate, endDate);
  };

  const handleDownloadPdf = (type: string, startDate: string, endDate: string) => {
    if (type === 'invoice') {
      let params: IDownloadInvoicesPdf;
      if (startDate && endDate) {
        params = {
          startDate,
          endDate,
          showId: show.id,
        };
      } else {
        params = {
          startDate: moment().subtract(30, 'days').format('YYYY-MM-DD 00:00:00'),
          endDate: moment().format('YYYY-MM-DD 00:00:00'),
          showId: show.id,
        };
      }
      setExportLoading(LOADER_STATUS.PENDING);
      showApi
        .downloadInvoicesPdf(params)
        .then((res) => {
          setExportLoading(LOADER_STATUS.COMPLETED);
          if (res) window.open(res, '_blank');
        })
        .catch((err) => {
          console.log(err);
          setExportLoading(LOADER_STATUS.COMPLETED);
        });
    }
    if (type === 'chart') {
      setExportLoading(LOADER_STATUS.PENDING);
      const element: any = printRef.current;
      html2canvas(element).then((canvas) => {
        const imgWidth = 208;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const imgData = canvas.toDataURL('img/png');
        const pdf = new Jspdf('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('download.pdf');
        setExportLoading(LOADER_STATUS.COMPLETED);
      });
    }
  };

  const handleDownloadImage = async () => {
    setExportLoading(LOADER_STATUS.PENDING);
    const element: any = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = data;
      link.download = 'image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setExportLoading(LOADER_STATUS.COMPLETED);
    } else {
      setExportLoading(LOADER_STATUS.COMPLETED);
      window.open(data);
    }
  };

  const handlePrint = () => window.print();

  const handleCloseAddBudget = () => setAddBudgetModal(false);

  const handleCloseDailyCost = () => setShowDailyCost(false);

  const getChartBody = () => (
    <Chart
      printRef={printRef}
      data={bookingChartData}
      handleAdd={handleAddBudget}
      handleEdit={handleEditBudget}
      handleExportDownload={handleDownload}
      handlePrint={handlePrint}
      exportLoading={exportLoading}
      loaderType={loaderType}
    />
  );

  const getAddBudgetComponent = () => <CreateBudget currentShowId={show.id} closeModal={handleCloseAddBudget} />;

  const getDailyCostComponent = () => (
    <DailyCostInvoices
      printRef={printRef}
      handlePrint={handlePrint}
      exportLoading={exportLoading}
      loaderType={loaderType}
      handleDownload={handleDownload}
      id={show.id}
    />
  );

  const getChartTitle = useMemo(() => {
    switch (chartType) {
      case 'budget':
        return 'Budget';
      case 'booking':
        return 'Booking Stats';
      case 'cost':
        return 'Cost';
      default:
        return 'Daily Cost';
    }
  }, [chartType]);

  const getChartIcon = useMemo(() => {
    switch (chartType) {
      case 'budget':
        return budgetSvg;
      case 'booking':
        return 'Booking Stats';
      default:
        return 'Daily Cost';
    }
  }, [chartType]);

  return (
    <div className={Style.mainWrapper}>
      <Header ttlUsers={ttlUsers} show={show} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      {isExpanded && (
        <div className={Style.innerInfoWrapper}>
          {(permissionLevel === PERMISSION_LEVEL_PD || permissionLevel === PERMISSION_LEVEL_AC) && (
            <div className={`d-flex flex-wrap ${Style.budgetCostSectionWrapper}`}>
              <Item
                title="Budget"
                icon={budgetSvg}
                cssClass={Style.budgetSection}
                handleClick={() => setChartType('budget')}
              />
              <Item
                title="Booking Statistics"
                icon={bookingStatusSvg}
                cssClass={Style.bookingSection}
                handleClick={() => setChartType('booking')}
              />
              <Item
                title="Daily cost for BG"
                icon={billingCostSvg}
                cssClass={Style.billingSection}
                handleClick={() => setChartType('cost')}
              />
              <Item
                title="Casting Billing"
                icon={dailyCostSvg}
                cssClass={Style.costSection}
                handleClick={() => setChartType('dailyCost')}
              />
            </div>
          )}
          {permissionLevel === PERMISSION_LEVEL_PD && (
            <>
              <div className={Style.matrixHeading}>
                <p className="mb-0">User Management</p>
              </div>
              <div className={Style.matrixItemWrapper}>
                <UserListing show={show} team={team} />
              </div>
            </>
          )}
          {permissionLevel !== PERMISSION_LEVEL_NA && (
            <>
              <div className={Style.matrixHeading}>
                <p className="mb-0">Matrix</p>
              </div>
              <div className={Style.matrixItemWrapper}>
                <MatrixListing show={show} matrix={matrix} />
              </div>
            </>
          )}
          {permissionLevel === PERMISSION_LEVEL_PD && (
            <>
              <div className={Style.binderHeading}>
                <p className="mb-0">Binder</p>
              </div>
              {/* <div className={Style.binderWrapper}>
                <BinderItem />
              </div> */}
            </>
          )}
        </div>
      )}
      {showChart && (
        <Modal
          title={getChartTitle}
          closeModal={handleCloseChart}
          icon={getChartIcon}
          body={getChartBody}
          cssClass={Style.BudgetBody}
        />
      )}
      {addBudgetModal && (
        <Modal
          title={show.title}
          closeModal={handleCloseAddBudget}
          icon={budgetSvg}
          body={getAddBudgetComponent}
          cssClass={Style.BudgetBody}
        />
      )}
      {showDailyCost && (
        <Modal
          title="Daily Cost"
          closeModal={handleCloseDailyCost}
          icon={budgetSvg}
          body={getDailyCostComponent}
          cssClass={Style.DailyCostBody}
        />
      )}
    </div>
  );
};

export default ShowDetail;
