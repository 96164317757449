import React, { FunctionComponent, useEffect, useState } from 'react';
import createSvg from 'assets/svg/create.svg';
import Input from 'components/controls/input/input';
import TextEditor from 'components/controls/textEditor/TextEditor';
import Button from 'components/controls/button/button';
import Style from 'components/CreateShow/CreateShow.module.css';
import EmailStyle from 'components/castingDirector/PopOvers/EmailDetail/EmailDetail.module.css';
import saveSvg from 'assets/svg/save.svg';
import ModalHeader from 'components/Modal/Header';
import { useDispatch, useSelector } from 'react-redux';
import { saveEmailTemplateBegin } from 'store/actions/show.actions';
import { getSelectedShowId } from 'store/selector/show.selector';

interface SendEmailProps {
  closeDialog: Function;
}
const SendEmail: FunctionComponent<SendEmailProps> = (props: SendEmailProps) => {
  const { closeDialog } = props;
  const [name, setName] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [additionalInfo, setAdditionalInfo] = useState<string>('');
  const [disable, setDisable] = useState<boolean>(false);
  const showId = useSelector(getSelectedShowId);

  const dispatch = useDispatch();

  useEffect(() => {
    setDisable(name.length === 0 || additionalInfo.length === 0);
  }, [name, additionalInfo]);

  const handleClick = () => {
    closeDialog(false);
  };
  const handleCancel = () => {
    closeDialog(false);
  };
  const handleSave = () => {
    dispatch(saveEmailTemplateBegin({ templateText: additionalInfo, name, subject, showId }));
    closeDialog(false);
  };
  return (
    <div className={Style.bgLayer}>
      <div className={Style.ContentWrapper}>
        <ModalHeader title="Create new template" handleClick={handleClick} icon={createSvg} />
        <div className={`modal-body ${Style.modalBody}`}>
          <div className="row">
            <div className="col-sm-12">
              <Input placeholder="Template name" handleChange={setName} label="" cssClass={EmailStyle.input} />
              <Input placeholder="Template Subject" handleChange={setSubject} label="" cssClass={EmailStyle.input} />
              <TextEditor text={additionalInfo} setText={setAdditionalInfo} />
            </div>
          </div>
          <div className="row text-right">
            <div className={`col-sm-12 ${EmailStyle.buttonWrapper}`}>
              <Button label="Cancel" clickHandler={handleCancel} cssClass={EmailStyle.cancelButton} />
              <Button
                label="Save"
                clickHandler={handleSave}
                icon={saveSvg}
                cssClass={EmailStyle.saveButton}
                isDisabled={disable}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendEmail;
