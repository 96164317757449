import React, { FunctionComponent, useEffect, useRef, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import imagePreviewIcon from 'assets/images/imagePreviewIcon.svg';
import imageUploadIcon from 'assets/images/imageUploadIcon.svg';
import ImageStyle from 'components/controls/fileInput/imageInput.module.css';
import addNewImage from 'assets/images/addNewImage.png';
import reload from 'assets/svg/reload.svg';
import { isValidFile } from 'helpers/utils';
import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';
import { Show } from 'modules/Show/show.types';
import { getPaths } from 'store/selector/general.selector';
import { connect } from 'react-redux';
import { IMAGE_PATH_TYPE } from 'store/castingPax.constants';
import Loader from 'components/controls/Loader/Loader';

interface InputProps {
  label?: string;
  handelFile?: Function;
  userId?: string;
  profilePic?: string;
  updateCall?: boolean;
  showImage?: boolean;
  paths?: any;
  currentImage?: string;
  handleRemoveFile?: Function;
  type?: string;
  loading?: boolean;
  error?: string;
  retry?: boolean;
}

const ImageInput: FunctionComponent<InputProps> = (props: InputProps) => {
  const {
    label,
    handelFile,
    userId,
    profilePic,
    updateCall,
    showImage,
    paths,
    currentImage,
    handleRemoveFile,
    type,
    loading,
    error,
    retry,
  } = props;

  const [imagePreview, setImagePreview] = useState(profilePic || '');
  const [newFileName, setNewFileName] = useState<string>();
  const inputFile = useRef<HTMLInputElement>(null);
  const [fileError, setFileError] = useState<string>('');
  const [fileParam, setFileParams] = useState<any>(null);

  useEffect(() => {
    if (error !== null && error !== undefined) setFileError(error);
  }, [error]);

  const handleFileProcessing = async (file: File) => {
    if (userId !== '' && handleRemoveFile && currentImage) handleRemoveFile();
    const message = isValidFile(file, 'image');
    if (message.length > 0) {
      setFileError(message);
      return;
    }
    let params: any;
    const formData = new FormData();
    if (userId) {
      if (showImage) {
        formData.append('id', userId);
        formData.append('file', file);
        params = formData;
      } else {
        formData.append('userId', userId);
        formData.append('profilePicUrl', file);
        params = {
          params: formData,
          updateCall,
        };
      }
    } else {
      formData.append('file', file);
      params = formData;
    }
    setFileParams(params);
    handelFile?.(params);
    setNewFileName(file.name);
    setFileError('');
    setImagePreview(URL.createObjectURL(file));
  };

  const handleImageChange = async (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];
      if (file) {
        await handleFileProcessing(file);
      }
    }
  };

  const handleFile = async (files: FileList | File[]) => {
    const file = files[0];
    if (file) {
      await handleFileProcessing(file);
    }
  };

  const handleFileClick = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const fileName = () => {
    if (!type || newFileName) return imagePreview;
    if (type === IMAGE_PATH_TYPE.SHOW) {
      return `${paths.showImage}${imagePreview}`;
    }
    return imagePreview;
  };

  const handleRetry = (event: any) => {
    event.stopPropagation();
    if (fileParam && handelFile) {
      handelFile(fileParam);
    }
  };

  const showButton = () => {
    if (retry) {
      return (
        <div onClick={handleRetry} onKeyPress={handleRetry} role="button" tabIndex={0}>
          <img src={reload} alt="retry" />
        </div>
      );
    }
    if (loading) {
      return (
        <span className="ml-2 mt-1 text-white">
          <Loader isSmallView />
        </span>
      );
    }
    return <img src={imageUploadIcon} alt={label} />;
  };

  const isValidImageHeightWidth = (file: File) =>
    new Promise<boolean>((resolve) => {
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        if (img.width >= 180 && img.width <= 250 && img.height >= 180 && img.height <= 250) {
          resolve(true);
        }
        resolve(false);
      };
      img.onerror = () => resolve(false);
    });

  const onImageError = (e: any) => {
    const placeholderImage = addNewImage;
    e.target.src = placeholderImage;
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      handleFile(acceptedFiles);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/*',
  });

  return (
    <div className={ImageStyle.imgContainer} {...getRootProps()}>
      {imagePreview.length === 0 && (
        <img className={ImageStyle.imgPlaceHolder} alt={label} src={imagePreviewIcon} onError={onImageError} />
      )}
      <div
        className={ImageStyle.imageUploadIcon}
        onClick={handleFileClick}
        onKeyPress={handleFileClick}
        role="button"
        tabIndex={0}
      >
        {showButton()}
      </div>
      {imagePreview && <img src={fileName()} alt={label} className={ImageStyle.imgView} />}
      {fileError && <TextErrorMessage message={fileError} />}
      <input
        {...getInputProps()}
        type="file"
        ref={inputFile}
        accept="image/*"
        className={ImageStyle.displayNone}
        onChange={handleImageChange}
        placeholder={label}
      />
    </div>
  );
};

const mapStateToProps = (state: Show) => {
  const paths = getPaths(state);
  return {
    paths,
  };
};

export default connect(mapStateToProps, null)(ImageInput);
