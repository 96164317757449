import React, { FunctionComponent, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { IAddPerformerInMyList, ISendJobRequestParam } from 'modules/params/param.type';
import { ShowDayJob } from 'modules/Show/show.types';
import { BookingDateList, User } from 'modules/user/types';
import MultiSelectList from 'components/controls/MultiSelectList/MultiSelectList';
import PerformerProfileStyle from 'components/PerformerProfile/PerformerProfile.module.css';
import MessageSection from 'components/PerformerProfile/MessageSection/MessageSection';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import {
  SelectOptionTypeList,
  SelectOptionType,
  PerformerNoteList,
  PerformerNote,
} from 'modules/PerformerList/list.types';
import sendSvg from 'assets/svg/mail.svg';
import calendarWhiteSvg from 'assets/svg/calendar-white.svg';
import listSvg from 'assets/svg/list.svg';
import deniedSvg from 'assets/svg/denied.svg';
import notesWhiteSvg from 'assets/svg/Notes-white.svg';
import messageSvg from 'assets/svg/message.svg';
import ShowSelection from 'components/PerformerProfile/ShowSelection';
import Loader from 'components/controls/Loader/Loader';
import { PerformerJobs } from 'modules/jobs/types';
import {
  JOB_CANCEL_SLUG,
  JOB_STATUS_BOOKED,
  JOB_STATUS_CANCELLED,
  JOB_STATUS_PENDING,
  JOB_STATUS_REQUESTED,
} from 'store/castingPax.constants';
import Modal from 'components/controls/Modal/Modal';
import ConfirmationModal from 'components/bgPerformer/BookingButton/ConfirmationModal';
import { jobRequestFromCDBegin } from 'store/actions/job.actions';
import { useDispatch } from 'react-redux';

interface ButtonSectionProps {
  performer: User;
  selectedJob: ShowDayJob;
  myPerformerList: SelectOptionTypeList;
  callAddPerformerInFavList: Function;
  performerNotes: PerformerNoteList;
  showMessageSection: boolean;
  getMyPerformerListRequest: Function;
  calendarDates?: BookingDateList;
  acceptJobRequestBegin: Function;
  cancelJobRequestBegin: Function;
  requestInprogress: string;
  labelType?: string;
  performerTab: string;
}
const ButtonSection: FunctionComponent<ButtonSectionProps> = (props) => {
  const {
    performer,
    selectedJob,
    myPerformerList,
    callAddPerformerInFavList,
    performerNotes,
    showMessageSection,
    getMyPerformerListRequest,
    calendarDates,
    acceptJobRequestBegin,
    cancelJobRequestBegin,
    requestInprogress,
    labelType,
    performerTab,
  } = props;
  const [note, setNote] = useState<string>('');
  const [myListIds, setMyListIds] = useState<string>('-1');
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
  const [jobRequestParams, setJobRequestParams] = useState<ISendJobRequestParam>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!myPerformerList.length) {
      getMyPerformerListRequest();
    }
  }, []);

  useLayoutEffect(() => {
    if (performer && performer.id && myListIds !== '-1') {
      const params: IAddPerformerInMyList = {
        userId: performer.id,
        selectedIds: myListIds ? myListIds.split(',') : [],
        listIds: myPerformerList.map((p: SelectOptionType) => p.value),
      };
      callAddPerformerInFavList(params);
    }
  }, [myListIds]);

  const memoPerformerlist = useMemo(() => {
    const newList = myPerformerList.map((item: SelectOptionType) => {
      if (item.ids) {
        const tmp = item;
        tmp.isChecked = item.ids.findIndex((x: string) => x === performer.id) !== -1;
        return { ...tmp };
      }
      return item;
    });
    return [...newList];
  }, [myPerformerList]);

  const getPersonalNote = () => {
    if (performerNotes) {
      const index = performerNotes.findIndex((p: PerformerNote) => p.userId === performer.id);

      if (index !== -1) {
        return performerNotes[index].note;
      }
    }
    return '';
  };

  const handleCancelJob = () => {
    if (getAppliedJob) {
      cancelJobRequestBegin({
        id: getAppliedJob.id,
        userId: performer.id,
      });
    }
  };

  const handleSubmit = () => {
    if (jobRequestParams && jobRequestParams.jobId) dispatch(jobRequestFromCDBegin(jobRequestParams));
    setIsConfirmation(false);
  };

  const ModalBody = () => (
    <ConfirmationModal handleSubmit={handleSubmit} handleClose={() => setIsConfirmation(false)} />
  );

  const handleAcceptJob = () => {
    if (getAppliedJob) {
      acceptJobRequestBegin({
        id: getAppliedJob.id,
        userId: performer.id,
      });
    }
  };

  const dropDownSearchComponent = () => <MultiSelectList list={memoPerformerlist} setSelectedIds={setMyListIds} />;

  const getButtonClass = (jobStatus: string) => {
    let css: string = `${PerformerProfileStyle.profileButton} ${PerformerProfileStyle.profileRequestButton} `;
    if (jobStatus === JOB_STATUS_CANCELLED) {
      css += PerformerProfileStyle.bgGreyCancel;
      return css;
    }
    css += jobStatus === JOB_STATUS_PENDING ? PerformerProfileStyle.bgGreen : PerformerProfileStyle.bgRed;
    return css;
  };

  const getAppliedJob = useMemo(() => {
    if (!performer.job || performer.job.length === 0) {
      return null;
    }

    if (selectedJob && performerTab !== '-1') {
      const index: number = performer.job.findIndex(
        // (b: PerformerJobs) => b.jobId === selectedJob.id && b.status !== JOB_STATUS_CANCELLED,
        (b: PerformerJobs) => b.jobId === selectedJob.id,
      );

      if (index !== -1) return performer.job[index];
    }
    return null;
  }, [performer.job, selectedJob]);

  const requestMessageButton = () => {
    if (getAppliedJob) {
      if (getAppliedJob.status === JOB_STATUS_PENDING) {
        return (
          <button type="button" className={getButtonClass(JOB_STATUS_PENDING)} onClick={handleAcceptJob}>
            <img src={calendarWhiteSvg} alt="" />
            <span className={`mb-0 ${PerformerProfileStyle.profileButtonText}`}>
              {/* Accept */}
              Confirm booking
            </span>
          </button>
        );
      }
      if (getAppliedJob.status === JOB_STATUS_BOOKED) {
        return (
          <button type="button" className={getButtonClass(JOB_STATUS_BOOKED)} onClick={handleCancelJob}>
            <img src={getAppliedJob.status === JOB_STATUS_BOOKED ? calendarWhiteSvg : sendSvg} alt="" />
            <span className={`mb-0 ${PerformerProfileStyle.profileButtonText}`}>
              {getAppliedJob.status === JOB_STATUS_BOOKED ? 'Cancel request' : 'Cancel'}
            </span>
          </button>
        );
      }
      if (getAppliedJob.status === JOB_STATUS_CANCELLED && getAppliedJob.requestCancelSlug === JOB_CANCEL_SLUG.JCBP) {
        return (
          <button type="button" className={getButtonClass(JOB_STATUS_CANCELLED)} style={{ pointerEvents: 'none' }}>
            <img src={deniedSvg} alt="" className={PerformerProfileStyle.deniedImg} />
            <span className={`mb-0 ${PerformerProfileStyle.profileButtonText}`} style={{ color: '#EB5757' }}>
              Accept Denied
            </span>
          </button>
        );
      }
      if (getAppliedJob.status === JOB_STATUS_REQUESTED) return <></>;
    }
    return (
      <PopoverContainer itemComponent={showSelectionComponent}>
        <button
          type="button"
          className={`${PerformerProfileStyle.profileButton} ${PerformerProfileStyle.profileRequestButton}`}
        >
          <img src={labelType ? calendarWhiteSvg : sendSvg} alt="" />
          <span className={`mb-0 ${PerformerProfileStyle.profileButtonText}`}>{labelType ? 'Book' : 'Request'}</span>
        </button>
      </PopoverContainer>
    );
  };

  const getTitle = () => {
    const status: string = (getAppliedJob && getAppliedJob.status) || '';
    const isCancel: boolean = (getAppliedJob && getAppliedJob.requestCancelSlug === JOB_CANCEL_SLUG.JCBP) || false;
    if (status === JOB_STATUS_BOOKED || (status === JOB_STATUS_CANCELLED && isCancel)) return 'New Request';
    return 'Text Request';
  };

  const requestEmailButton = () => {
    if (getAppliedJob && getAppliedJob.status !== JOB_STATUS_BOOKED && getAppliedJob.status !== JOB_STATUS_CANCELLED) {
      return (
        <button type="button" className={getButtonClass(JOB_STATUS_BOOKED)} onClick={handleCancelJob}>
          <img src={sendSvg} alt="" />
          <span className={`mb-0 ${PerformerProfileStyle.profileButtonText}`}>Cancel</span>
        </button>
      );
    }

    return (
      <PopoverContainer itemComponent={showSelectionComponent}>
        <button
          type="button"
          className={`${PerformerProfileStyle.profileButton} ${PerformerProfileStyle.profileTextRequestButton}`}
        >
          <img src={messageSvg} alt="" />
          <span className={`mb-0 ${PerformerProfileStyle.profileButtonText}`}>{labelType ? 'Text' : getTitle()}</span>
        </button>
      </PopoverContainer>
    );
  };

  const submitBlackDay = (params: ISendJobRequestParam, isConfirm: boolean) => {
    setIsConfirmation(isConfirm);
    setJobRequestParams(params);
  };

  const showSelectionComponent = () => (
    <ShowSelection submitBlackDay={submitBlackDay} calendarDates={calendarDates} performer={performer} note={note} />
  );

  return (
    <>
      {showMessageSection && (
        <MessageSection
          performerId={performer ? performer.id : ''}
          setNote={setNote}
          personalNote={getPersonalNote()}
        />
      )}
      <div
        className={`d-flex justify-content-between align-items-center ${PerformerProfileStyle.profileButtonsWrapper}`}
      >
        {requestInprogress === performer.id && (
          <div>
            <Loader isSmallView />
          </div>
        )}
        {requestMessageButton()}
        {requestEmailButton()}
        <PopoverContainer
          cssClass={`addToListPopover ${PerformerProfileStyle.addToListPopover}`}
          itemComponent={dropDownSearchComponent}
          placement="bottom"
        >
          <button
            type="button"
            className={`${PerformerProfileStyle.profileButton} ${PerformerProfileStyle.profileAddListButton}`}
          >
            <img src={labelType ? notesWhiteSvg : listSvg} alt="" className="" />
            <span className={`mb-0 ${PerformerProfileStyle.profileButtonText}`}>
              {labelType ? 'Notes' : 'Add to list'}
            </span>
          </button>
        </PopoverContainer>
      </div>
      {isConfirmation && (
        <Modal title="Confirmation Alert!" body={ModalBody} closeModal={() => setIsConfirmation(false)} />
      )}
    </>
  );
};

export default ButtonSection;
