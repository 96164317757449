/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import Styled from 'styled-components';
// import TopNavBar from './PayrollNav';
import TopNavBar from 'components/TopNavBar/TopNavBar';
import SideNav from './PayrollSideNav';
import { RateManagementSideNavItemData } from '../Data/RateManagementDashboardData';

interface MaindashboardProps {
  setSelectedIndex: (index: number) => void;
}

const RateManagementDashboard = () => {
  const [showSideNav, setShowSideNav] = useState(true);

  return (
    <DashboardContainer>
      <TopNavBar showSideNav={showSideNav} setShowSideNav={setShowSideNav} />
      <SideNav showNavIcon sideNavData={RateManagementSideNavItemData} showSideNav={showSideNav} />
    </DashboardContainer>
  );
};

const DashboardContainer = Styled.div`
display: flex;
flex-direction: column;
width: 100%;
margin: 0 auto;
// min-width: 1080px;
// background-color: red;
@media only screen and (max-width: 991px) {
  // min-width: 767px;
}
`;
export default RateManagementDashboard;
