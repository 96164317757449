import { SelectOptionType, SelectOptionTypeList } from 'modules/PerformerList/list.types';

export const ShowTypesOption = [
  { name: 'Film', id: 'Film' },
  { name: 'Series', id: 'Series' },
];

export const NumberOptions = (min: number, max: number, unit?: string) => {
  const arr: SelectOptionTypeList = [];
  for (let i = min; i <= max; i += 1) {
    arr.push({ value: i.toString(), label: unit ? `${i} ${unit}` : i.toString() });
  }
  return [...arr];
};

export const getHeightOptions = () => {
  const arr: SelectOptionTypeList = [];
  for (let i = 3; i <= 6; i += 1) {
    for (let j = 0; j < 12; j += 1) {
      arr.push({ value: `${i}${j}`, label: `${i}'${j}"` });
    }
  }
  return [...arr];
};

export const getWeightOption = () => {
  const arr: SelectOptionTypeList = [];
  for (let i = 20; i <= 400; i += 1) {
    arr.push({ value: i.toString(), label: `${i.toString()} lbs` });
  }
  return [...arr];
};

export const getWasitOption = () => {
  const arr: SelectOptionTypeList = [];
  arr.push({ value: 'N/A', label: 'N/A' });
  for (let i = 10; i <= 50; i += 1) {
    arr.push({ value: i.toString(), label: `${i.toString()}"` });
  }
  return [...arr];
};

export const getNeckOptions = () => {
  const arr: SelectOptionTypeList = [];
  arr.push({ value: 'N/A', label: 'N/A' });
  arr.push({ value: 'S', label: 'S' });
  arr.push({ value: 'L', label: 'L' });
  arr.push({ value: 'XL', label: 'XL' });
  arr.push({ value: '2XL', label: '2XL' });
  arr.push({ value: '3XL', label: '3XL' });
  for (let i = 10; i <= 25; i += 0.5) {
    arr.push({ value: i.toString(), label: `${i.toString()}"` });
  }
  return [...arr];
};

export const getJacketSizeOptions = () => {
  const arr: SelectOptionTypeList = [];
  const size = ['S', 'M', 'L'];
  for (let i = 30; i <= 60; i += 1) {
    for (let j = 0; j < size.length; j += 1) {
      arr.push({ value: `${i}${size[j]}`, label: `${i}${size[j]}` });
    }
  }
  return [...arr];
};

export const getInseamOptions = () => {
  const arr: SelectOptionTypeList = [];
  for (let i = 16; i <= 40; i += 0.5) {
    arr.push({ value: i.toString(), label: `${i.toString()}"` });
  }
  return [...arr];
};

export const getSleeveOptions = () => {
  const arr: SelectOptionTypeList = [];
  for (let i = 12; i <= 38; i += 0.5) {
    arr.push({ value: i.toString(), label: `${i.toString()}"` });
  }
  return [...arr];
};

export const roleOptions = (): SelectOptionType[] => [
  { value: '60671838f9d2b991014963f8', label: 'Casting Director' },
  { value: '606717f8f9d2b991014963f7', label: 'Production Director' },
  { value: '6067185ff9d2b991014963f9', label: 'BG Coordinator' },
  { value: '60671874f9d2b991014963fa', label: 'BG Performer' },
  { value: '6113cbe47b67b34e11060393', label: 'Accountant' },
  { value: '6113cc467b67b34e11060394', label: 'Assistant Director' },
  { value: '62d7fe87bbaec958ec06e3a4', label: 'Payroll' },
];

export const rolesData = (roles: string[]): SelectOptionType[] => {
  const list: SelectOptionType[] = [
    { value: '60671838f9d2b991014963f8', label: 'Casting Director', isChecked: false },
    { value: '606717f8f9d2b991014963f7', label: 'Production Director', isChecked: false },
    { value: '6067185ff9d2b991014963f9', label: 'BG Coordinator', isChecked: false },
    { value: '60671874f9d2b991014963fa', label: 'BG Performer', isChecked: false },
    { value: '62d7fe87bbaec958ec06e3a4', label: 'Payroll', isChecked: false },
  ];

  list.map((x: SelectOptionType) => {
    const obj = x;
    if (roles.includes(obj.value)) obj.isChecked = true;
    return obj;
  });
  return list;
};

export const EmptyRoleData = {
  id: '-1',
  workingDayId: '',
  title: '',
  description: '',
  date: '',
  location: '',
  height: '',
  ethinicity: '',
  clothingOption: '',
  bodyRequirements: '',
  remarks: '',
  additionalInfo: '',
  createdBy: '',
  status: '',
  isActive: true,
  noOfPerformerRequired: 1,
  dates: '',
};

export const tagShowRoles = (): SelectOptionType[] => [
  { value: '60671838f9d2b991014963f8', label: 'Casting Director' },
  { value: '606717f8f9d2b991014963f7', label: 'Production Director' },
  { value: '6113cbe47b67b34e11060393', label: 'Accountant' },
  { value: '6113cc467b67b34e11060394', label: 'Assistant Director' },
  { value: '62d695b477cc9b1e19827c19', label: 'Payroll' },
];
