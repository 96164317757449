import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fullViewImage } from 'store/selector/performer.selector';
import { openFullViewImage } from 'store/actions/performer.actions';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';

const FullImageView: FunctionComponent = () => {
  const image = useSelector(fullViewImage);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(openFullViewImage(''));
  };

  if (image) {
    return (
      <>
        <Lightbox image={image} allowRotate={false} onClose={handleClick} />
      </>
    );
  }

  return <></>;
};

export default FullImageView;
