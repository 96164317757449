import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Style from 'components/castingDirector/Matrix.module.css';
import reloadSvg from 'assets/svg/reload_secondary.svg';
import { MatrixAtoms, MatrixType, PerformerItem, Show } from 'modules/Show/show.types';
import { fetchMatrixPerformerBegin } from 'store/actions/show.actions';
import { GridApi, RowNode } from '@ag-grid-community/core';
import MatrixItem from 'components/Coordinator/Matrix/Item/MatrixItem';
import { IUpdateMatrixTime } from 'modules/params/param.type';
import showApi from 'store/services/show.services';
import moment from 'moment';
import { getUser } from 'store/selector/auth.selector';
import { User } from 'modules/user/types';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';
import { toast } from 'react-toastify';
import Header from 'components/Matrix/Header';
import Modal from 'components/controls/Modal/Modal';
import ConfirmationModalBody from 'components/Coordinator/Matrix/Details/ConfirmationModalBody';
import { ScreenSize } from 'modules/general/general.type';
import { getRates, getScreenWidth } from 'store/selector/general.selector';
import { JOB_STATUS_BOOKED, SCREEN_SIZE_DESKTOP, TOAST_IDS, TOAST_TYPE } from 'store/castingPax.constants';
import Button from 'components/controls/button/button';
import { isMatrixPerformerLoading } from 'store/selector/show.selector';
import { showToast } from 'store/actions/general.actions';

interface MatrixDetailProps {
  dayItem: MatrixType;
  user: User;
  fetchMatrixPerformer: Function;
  currentScreenSize: ScreenSize;
  isPerformerLoading: boolean;
  matrixUpdateTiming: Function;
  showToastMessage: Function;
  rateList: any;
}

const MatrixDetail: FunctionComponent<MatrixDetailProps> = (props: MatrixDetailProps) => {
  const {
    dayItem,
    user,
    fetchMatrixPerformer,
    currentScreenSize,
    isPerformerLoading,
    matrixUpdateTiming,
    showToastMessage,
    rateList,
  } = props;
  const [item, setItem] = useState<MatrixType>(dayItem);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [selectedGridIndex, setSelectedGridIndex] = useState<any>();

  useEffect(() => {
    setItem(dayItem);
    if (!item.performers || item.performers.length === 0) {
      loadPerformerData();
    }
  }, [dayItem]);

  useEffect(() => {
    setItem(dayItem);
  }, [dayItem.atoms, dayItem.performers]);

  const getSelectedPerformer = (sourceIndex: number, params: any) => {
    let data: PerformerItem[] = handleRowSelectionChange(sourceIndex);

    if (data.length === 0) {
      data.push(params.node.data);
    } else {
      const selectedIndex = data.findIndex((x: PerformerItem) => x.id === params.node.data.id);
      if (selectedIndex === -1) {
        data = [];
        data.push(params.node.data);
      }
    }
    return data;
  };

  const getGridApi = (index: number) => {
    if (item.atoms) {
      return item.atoms[index].grid;
    }
    return null;
  };

  const itemAtoms = useMemo(() => {
    const { atoms } = item;
    if (atoms) {
      return atoms.map((x: MatrixAtoms) => {
        if (item.performers !== undefined && item.performers.length > 0) {
          const obj = x;
          obj.performers = item.performers.filter(
            (p: PerformerItem) => p.atoms === x.id && p.status === JOB_STATUS_BOOKED,
          );
          return obj;
        }
        return x;
      });
    }
    return [];
  }, [item, item.performers, item.atoms]);

  const handleRowSelectionChange = (index: number) => {
    if (!item.atoms) return [];

    const api = item.atoms[index].grid;
    if (api) {
      const selectedNodes: any = api.getSelectedNodes();
      const cellRanges: any = api.getCellRanges();
      console.log('RANGE_SELETION', selectedNodes, cellRanges);
      if (selectedNodes && selectedNodes.length > 0) {
        return selectedNodes.map((node: RowNode) => node.data);
      }
      if (cellRanges && cellRanges.length > 0) {
        const cellRange: any = cellRanges[0];
        const selectedColumns: string[] = cellRange.columns.map((c: any) => c.colId);
        const data: PerformerItem[] = [];
        const atomsPerformers: PerformerItem[] = item.atoms[index].performers || [];
        const start: number = cellRange.startRow.rowIndex;
        const end: number = cellRange.endRow.rowIndex;
        const ttlLoop: number = end <= atomsPerformers.length ? end : atomsPerformers.length;
        for (let i = start; i <= ttlLoop; i += 1) {
          const obj = atomsPerformers[i];
          obj.columns = selectedColumns;
          data.push(obj);
        }
        return data;
      }
      api.deselectAll();
    }
    return [];
  };

  const updateUnionRate = (index: number, params: any, code: string) => {
    if (matrixIsLocked()) return;

    const tmp = item;
    const data: PerformerItem[] = getSelectedPerformer(index, params);
    const updateData: IUpdateMatrixTime[] = data.map((x: PerformerItem) => {
      const obj = x;
      if (tmp && tmp.atoms) {
        const pos = tmp.atoms[index].performers.findIndex((p: PerformerItem) => p.id === x.id);
        if (pos !== -1) {
          tmp.atoms[index].performers.splice(pos, 1, obj);
        }
      }
      obj.rate = code;
      return obj;
    });
    const api = getGridApi(index);
    if (api) {
      const transaction = {
        update: updateData,
      };
      api.applyTransaction(transaction);
    }

    matrixUpdateTiming(updateData);

    setItem(tmp);
  };

  const handleCopy = (index: number, params: any) => {
    const list: PerformerItem[] = getSelectedPerformer(index, params);
    const copyObject: MatrixType = {
      id: item.id,
      title: item.title,
      performers: [],
      bgCoordinator: [],
      type: item.type,
      showId: item.showId,
      date: `${moment(item.date).format('YYYY-MM-DD')} 00:00:00`,
    };
    localStorage.setItem('copyItem', JSON.stringify(list));
    localStorage.setItem('copyMatrix', JSON.stringify(copyObject));
  };

  const matrixIsLocked = () => {
    if (moment(item.date).isBefore(moment().add(-1, 'days'), 'day')) {
      toast('Previous matrix data can not changed');
      return true;
    }
    return false;
  };

  const handlePaste = (index: number, params: any) => {
    if (matrixIsLocked()) return;

    const copiedPerformer: PerformerItem[] = getItemFromLocalStorage('copyItem');
    if (copiedPerformer.length === 0 || !item.atoms || item.atoms[index].performers.length === 0) return;

    const currentAtoms: MatrixAtoms = item.atoms[index];
    const isSameMatrix: boolean = item.id === copiedPerformer[0].workingDayId;
    const isPartailCopy: boolean =
      (copiedPerformer[0].columns &&
        copiedPerformer[0].columns.length > 0 &&
        copiedPerformer[0].columns[0] !== 'performerName') ||
      false;
    if (isSameMatrix && !isPartailCopy) {
      toast('Copy not allowed in same matrix');
      return;
    }

    const workingDayPerformer: PerformerItem[] = item.atoms[index].performers;
    workingDayPerformer.forEach((wp: PerformerItem) => {
      const cpIndex: number = copiedPerformer.findIndex((x: PerformerItem) => x.performerId === wp.performerId);
      if (cpIndex !== -1 && !copiedPerformer[cpIndex].columns) copiedPerformer.splice(cpIndex, 1);
    });

    if (copiedPerformer.length === 0) {
      toast('Copied Performer is already in Matrix');
      return;
    }

    const updatedList: PerformerItem[] = [];
    const currentIndex: number = params.node.rowIndex;
    const copiedPerformerCount: number = copiedPerformer.length;
    const ttlAtomsRecord: number = currentAtoms.performers.length;
    const ttlLoop =
      ttlAtomsRecord - currentIndex > copiedPerformerCount ? currentIndex + copiedPerformerCount : ttlAtomsRecord;
    let isNotCallTimeCopy: boolean = true;

    if (copiedPerformer[0].columns) {
      if (
        copiedPerformer[0].columns.length === 1 &&
        params.column.colId === 'signInTime' &&
        copiedPerformer[0].columns[0] === 'callTime'
      ) {
        isNotCallTimeCopy = false;
      }
      if (params.column.colId !== copiedPerformer[0].columns[0] && isNotCallTimeCopy) {
        toast('Copy and paste column index not match');
        return;
      }
    }

    let inc = 0;
    for (let i = currentIndex; i < ttlLoop; i += 1) {
      const toData: PerformerItem = currentAtoms.performers[i];
      const fromData: PerformerItem = copiedPerformer[inc];
      const columnToCopy: string[] = fromData.columns || [];

      if (!toData.isDelete && toData.status === JOB_STATUS_BOOKED) {
        if (columnToCopy.length > 0) {
          columnToCopy.forEach((c: string) => {
            switch (c) {
              case 'signInTime':
                toData.signInTime = fromData.signInTime;
                break;
              case 'wrapTime':
                toData.wrapTime = fromData.wrapTime;
                break;
              case 'lunch':
                toData.lunch = fromData.lunch;
                break;
              case 'rental':
                toData.rental = fromData.rental;
                break;
              case 'costume':
                toData.costume = fromData.costume;
                break;
              case 'callTime':
                if (isNotCallTimeCopy) toData.callTime = fromData.callTime;
                else toData.signInTime = fromData.callTime;
                break;
              case 'unionNo':
                toData.unionNo = fromData.unionNo;
                break;
              case 'rate':
                toData.rate = fromData.rate;
                break;
              case 'NDB':
                toData.NDB = fromData.NDB;
                break;
              case 'workingDayId':
                toData.workingDayId = fromData.workingDayId;
                break;
              default:
                break;
            }
          });
          updatedList.push(toData);
        }
      }
      inc += 1;
    }

    if (updatedList.length > 0) {
      localStorage.removeItem('copyItem');
      localStorage.removeItem('copyMatrix');

      if (currentAtoms.grid) {
        currentAtoms.isUpdate += 1;
        const transaction = {
          update: updatedList,
        };
        currentAtoms.grid.applyTransaction(transaction);
        const obj: MatrixType = item;
        obj.atoms?.splice(index, 1, { ...currentAtoms });
        setItem(obj);
      }

      const updateDataList: IUpdateMatrixTime[] = updatedList.map((data: PerformerItem) => {
        const obj: IUpdateMatrixTime = {
          id: data.id,
          costume: data.costume,
          lunch: data.lunch,
          callTime: data.callTime,
          signInTime: data.signInTime,
          wrapTime: data.wrapTime,
          rental: data.rental,
          unionNo: data.unionNo,
          rate: data.rate,
          isRental: data.isRental,
          NDB: data.NDB,
          workingDayId: data.workingDayId,
          isAllowancesEnable: data.isAllowancesEnable,
        };
        return obj;
      });
      if (updateDataList.length > 0) {
        showApi.updateMatrixTiming({ data: updateDataList }).catch((err) => console.log(err));
      }
    } else {
      toast('Not able to paste the data');
    }
  };

  const updateGridDataOnServer = (changeIds: string[], pos: number) => {
    if (changeIds.length === 0) return;

    const grid = getGridApi(pos);
    if (!grid) return;

    const performers: PerformerItem[] = dayItem.performers.filter(
      (p: PerformerItem) => p.isDataUpdate === false && !changeIds.includes(p.id),
    );
    const updateList: IUpdateMatrixTime[] = [];

    if (performers.length > 0) {
      performers.forEach((m: PerformerItem) => {
        updateList.push({
          id: m.id,
          costume: m.costume,
          lunch: m.lunch,
          callTime: m.callTime,
          signInTime: m.signInTime,
          wrapTime: m.wrapTime,
          rental: m.rental,
          unionNo: m.unionNo,
          rate: m.rate,
          isRental: m.isRental,
          mileage: m.mileage,
          mileageType: m.mileageType,
          NDB: m.NDB,
          workingDayId: m.workingDayId,
          voucherType: m.voucherType,
          isAllowancesEnable: m.isAllowancesEnable,
        });
      });
    }

    const changePerformers: PerformerItem[] = [];
    changeIds.forEach((id: string) => {
      const node: any = grid.getRowNode(id);
      if (node) {
        const { data } = node;
        data.isDataUpdate = false;
        updateList.push({
          id: data.id,
          costume: data.costume,
          lunch: data.lunch,
          callTime: data.callTime,
          signInTime: data.signInTime,
          wrapTime: data.wrapTime,
          rental: data.rental,
          unionNo: data.unionNo,
          rate: data.rate,
          isRental: data.isRental,
          mileage: data.mileage,
          mileageType: data.mileageType,
          NDB: data.NDB,
          workingDayId: data.workingDayId,
          isAllowancesEnable: data.isAllowancesEnable,
          voucherType: data.voucherType,
        });
        const res = data;
        res.mileage = data.mileage;
        changePerformers.push({ ...res });
      }
    });

    console.log('changeperformers', changePerformers);
    if (grid) {
      grid.refreshCells({
        force: true,
      });
    }

    if (updateList.length > 0) {
      matrixUpdateTiming(updateList);
    }
  };

  const updateGridInstance = (index: number, grid: GridApi) => {
    const prevItem = item;
    grid.setFillHandleDirection('y');
    if (prevItem.atoms) {
      const prevAtoms = prevItem.atoms[index];
      prevAtoms.grid = grid;
      prevItem.atoms.splice(index, 1, { ...prevAtoms });
    }

    setItem(prevItem);
  };

  const getPerformerCount = () => {
    if (item.performers && item.performers.length !== 0) return item.performers.length;
    return 0;
  };

  const handleUpdateModal = (result: string) => {
    if (result) {
      const data: PerformerItem[] = item.performers;
      const updateData = data.map((x: PerformerItem) => {
        const obj = x;
        obj.signInTime = obj.callTime;
        return obj;
      });
      const api = getGridApi(selectedGridIndex);
      if (api) {
        const transaction = {
          update: updateData,
        };
        api.applyTransaction(transaction);
      }
      showApi.updateMatrixItem({ data: updateData }).catch((err) => console.log(err));
    }
  };

  const updateLunchTime = (index: number, text: string) => {
    const data: PerformerItem[] = handleRowSelectionChange(index);
    if (data.length === 0) {
      console.log('no data selected');
    } else {
      const updateData = data.map((x: PerformerItem) => {
        const obj = x;
        if (!obj.lunch) obj.lunch = [];
        obj.lunch.push(text);
        return obj;
      });
      const api = getGridApi(index);
      if (api) {
        const transaction = {
          update: updateData,
        };
        api.applyTransaction(transaction);
      }
      showApi.updateMatrixTiming({ data: updateData }).catch((err) => console.log(err));
    }
    return '';
  };

  const updateSignInTime = (index: number, text: string) => {
    const data: PerformerItem[] = handleRowSelectionChange(index);
    if (data.length === 0) {
      setSelectedGridIndex(index);
      setIsShow(true);
    } else {
      const updateData = data.map((x: PerformerItem) => {
        const obj = x;
        obj.signInTime = text;
        return obj;
      });
      const api = getGridApi(index);
      if (api) {
        const transaction = {
          update: updateData,
        };
        api.applyTransaction(transaction);
      }
      showApi.updateMatrixTiming({ data: updateData }).catch((err) => console.log(err));
    }
  };

  const updateCostumeTime = (index: number, text: string) => {
    const data: PerformerItem[] = handleRowSelectionChange(index);
    if (data.length === 0) {
      console.log('no data selected');
    } else {
      const updateData = data.map((x: PerformerItem) => {
        const obj = x;
        obj.costume = text;
        return obj;
      });
      const api = getGridApi(index);
      if (api) {
        const transaction = {
          update: updateData,
        };
        api.applyTransaction(transaction);
      }
      showApi.updateMatrixTiming({ data: updateData }).catch((err) => console.log(err));
    }
  };

  const updateWrapTime = (index: number, text: string) => {
    const data: PerformerItem[] = handleRowSelectionChange(index);
    if (data.length === 0) {
      console.log('no selected data');
    } else {
      const updateData = data.map((x: PerformerItem) => {
        const obj = x;
        obj.wrapTime = text;
        return obj;
      });
      const api = getGridApi(index);
      if (api) {
        const transaction = {
          update: updateData,
        };
        api.applyTransaction(transaction);
      }
      showApi.updateMatrixTiming({ data: updateData }).catch((err) => console.log(err));
    }
  };

  const ModalBody = () => (
    <ConfirmationModalBody
      isShow={setIsShow}
      handleClick={handleUpdateModal}
      description="Are You Sure to Sign In All?"
    />
  );

  const closeModal = () => {
    setSelectedGridIndex('');
    setIsShow(false);
  };

  const loadPerformerData = () => fetchMatrixPerformer({ workingDayId: item.id, id: user.id });

  const handleRefresh = () => loadPerformerData();

  const handleCalculateWages = () => {
    if (!item.performers || item.performers.length === 0) return;
    showApi
      .calculateWages({ id: item.id })
      .then(() => {
        console.log('Messsge');
        showToastMessage({
          id: TOAST_IDS.GENERAL_SUCCESS_MESSAGE,
          title: 'Calculation updated!!!',
          type: TOAST_TYPE.SUCCESS,
        });
      })
      .catch((err) => console.log('Error', err));
  };

  return (
    <>
      <div className={`${Style.mainWrapper}`} id="mainMatrix">
        <Header item={item} bgCount={getPerformerCount()} setIsExpanded={setIsExpanded} isExpanded={isExpanded} />
        {isExpanded && (
          <>
            <div className={Style.extraMatrixWrapper}>
              <p className="mb-0">Extra Matrix</p>
              <div>
                <Button
                  showLoading={isPerformerLoading}
                  cssClass={`matrixRefreshBtn matrixRefreshBtn-m0 ${Style.refreshBtn}`}
                  clickHandler={handleRefresh}
                  icon={reloadSvg}
                />
                <Button clickHandler={handleCalculateWages} label="Update Calculation" />
              </div>
            </div>
            <div className={Style.extraMatrixItemWrapper}>
              {itemAtoms.map((atom: MatrixAtoms, index: number) => (
                <MatrixItem
                  key={atom.id}
                  atoms={atom}
                  grid={atom.grid}
                  index={index}
                  updateUnionRate={updateUnionRate}
                  handleCopy={handleCopy}
                  handlePaste={handlePaste}
                  updateGridInstance={updateGridInstance}
                  updateGridDataOnServer={updateGridDataOnServer}
                  updateSignInTime={updateSignInTime}
                  updateLunchTime={updateLunchTime}
                  updateCostumeTime={updateCostumeTime}
                  updateWrapTime={updateWrapTime}
                  isMatrixExpanded={currentScreenSize === SCREEN_SIZE_DESKTOP}
                  rateList={rateList}
                />
              ))}
            </div>
          </>
        )}
      </div>
      {isShow && <Modal title="Are You Sure!" body={ModalBody} closeModal={() => closeModal()} />}
    </>
  );
};

const mapStateToProps = (state: Show) => {
  const user: User = getUser(state);
  const currentScreenSize: ScreenSize = getScreenWidth(state);
  const isPerformerLoading: boolean = isMatrixPerformerLoading(state);
  const rateList = getRates(state);

  return { user, currentScreenSize, isPerformerLoading, rateList };
};

const mapDispatchToProps = {
  fetchMatrixPerformer: fetchMatrixPerformerBegin,
  showToastMessage: showToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(MatrixDetail);
