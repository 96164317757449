/* eslint-disable react/destructuring-assignment */
import React, { FunctionComponent } from 'react';
import Styled from 'styled-components';
// import DownloadIcon from 'assets/images/downloadIcon.svg';
import DownloadIconRed from 'assets/images/downloadIconRed.png';
import DownloadIconBlue from 'assets/images/downloadBlue.png';
import DownloadIconGreen from 'assets/images/downloadIconGreen.png';

interface AttachedFileInterface {
  background: string;
  number: number;
  color: string;
}

interface IconPropsInterface {
  background: string;
}

const AttachedFileIcon: FunctionComponent<AttachedFileInterface> = (props: AttachedFileInterface) => {
  const getIcon = (number: number) => {
    switch (number) {
      case 1:
        return DownloadIconBlue;
      case 2:
        return DownloadIconRed;
      case 3:
        return DownloadIconGreen;
      default:
        return DownloadIconBlue;
    }
  };
  return (
    <AttachedFile background={props.background}>
      <FileNumber color={props.color}>{`#${props.number}`}</FileNumber>
      <StyledDownloadIcons src={getIcon(props.number)} />
    </AttachedFile>
  );
};

const AttachedFile = Styled.div`
display:flex;
justify-content:space-between;
padding-right:12px;
padding-left:12px;
padding-top:5px;
paddin-bottom:15px;
height:35px;
width: 200px;
border-radius: 5px;
margin-right:15px;
background: ${(props: IconPropsInterface) => (props.background ? props.background : 'grey')} ;
`;

const StyledDownloadIcons = Styled.img`
height:15px;
margin-top: 4px;
width:15px;
`;

const FileNumber = Styled.p`
  color: ${({ color }) => color};
`;

export default AttachedFileIcon;
