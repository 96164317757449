import React, { FunctionComponent } from 'react';
import Style from 'components/PerformerProfile/DatePopup/DatePopup.module.css';
import { BookingDate, BookingDateList } from 'modules/user/types';
import ProfileCalender from 'components/ProfileCalender/ProfileCalender';

interface DatePopupProps {
  userId: string;
  dates: BookingDateList;
  startKey: number;
}
const DatePopup: FunctionComponent<DatePopupProps> = (props: DatePopupProps) => {
  const { userId, dates, startKey } = props;
  return (
    <div className={`d-flex ${Style.dateBoxPopupWrapper}`}>
      {dates.map((date: BookingDate, index: number) => {
        if (index > startKey) {
          return <ProfileCalender userId={userId} cssClass={Style.dateBoxCalendar} key={date.id} date={date} />;
        }
        return '';
      })}
    </div>
  );
};

export default DatePopup;
