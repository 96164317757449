import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

interface Props {}

const AddControl: React.FC<Props> = () => (
  <Container>
    <EditIcon icon={faPlus} />
  </Container>
);

export default AddControl;

const Container = styled.div`
  width: 37.75px;
  height: 38.39px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(242, 201, 76, 0.1);
  border-radius: 14px;
  cursor: pointer;
`;
const EditIcon = styled(FontAwesomeIcon)`
  color: #f2c94c;
`;
