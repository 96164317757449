import React, { FunctionComponent } from 'react';
import Style from 'components/controls/Modal/Modal.module.css';

const ModalBody: FunctionComponent = (props: any) => {
  const { children } = props;
  return (
    <>
      <div className={`customScrollBar ${Style.modalBodyWrapper}`}>{children}</div>
    </>
  );
};

export default ModalBody;
