import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import locationImage from 'assets/images/location.png';
import tagImage from 'assets/images/tag.png';
import roleSvg from 'assets/svg/role.svg';
import infoSvg from 'assets/svg/info.svg';
import calendar2Svg from 'assets/svg/calendar-2.svg';
import mailSvg from 'assets/svg/mail.svg';
import saveSvg from 'assets/svg/save.svg';
import cancelSvg from 'assets/svg/cancel.svg';
import ModalHeader from 'components/Modal/Header/index';
import Style from 'components/bgPerformer/Booking/Booking.module.css';
import ProfileInfo from 'components/PerformerProfile/ProfileInfo';
import Button from 'components/controls/button/button';
import { ShowDayJob } from 'modules/Show/show.types';
import DateItem from 'components/dateItem/dateItem';
import { formatHeight, saveUserOnLocalStorage } from 'helpers/utils';
import {
  JOB_STATUS_BOOKED,
  JOB_STATUS_CANCELLED,
  JOB_STATUS_NEW,
  JOB_STATUS_PENDING,
  JOB_STATUS_REQUESTED,
} from 'store/castingPax.constants';
import { BookingRequest } from 'modules/jobs/types';
import moment from 'moment';
import BookingButton from 'components/bgPerformer/BookingButton/BookingButton';

interface ItemRoleDetailProps {
  job: ShowDayJob;
  booking?: BookingRequest;
  handleClose: Function;
  handleJobRequest: Function;
  handleProcessRequest: Function;
}
const ItemRoleDetail: FunctionComponent<ItemRoleDetailProps> = (props: ItemRoleDetailProps) => {
  const { handleClose, job, booking, handleJobRequest, handleProcessRequest } = props;
  const buttonStatus = booking && booking.status ? booking.status : 'new';
  const [directorObj, setDirectorObj] = useState<any>(job.createdBy || {});

  useEffect(() => setDirectorObj(job.createdBy || {}), [booking, job]);

  const handleSaveLater = () => handleClose();

  const getJobDates = useMemo(() => {
    if (booking && typeof booking === 'object') {
      if (booking.newDates) {
        const bookingDates = booking.dates.concat(booking.newDates).sort();
        return bookingDates.filter((item: string, pos: number) => bookingDates.indexOf(item) === pos);
      }
      return booking.dates.sort();
    }
    return job.dates.sort();
  }, [job]);

  const isBookingDatesChange = () =>
    buttonStatus === JOB_STATUS_BOOKED && booking && booking.newDates && booking.newDates.length > 0;

  const getBottomButtons = () => {
    let currentDate: any = moment();
    currentDate = currentDate.format('YYYY-MM-DD 00:00:00');
    const lastDate: any = job.dates[job.dates.length - 1];
    if (buttonStatus === JOB_STATUS_NEW && lastDate >= currentDate) {
      return (
        <div className={Style.roleDetailFooter}>
          <Button
            type="customCancelBtn"
            cssClass={`mr-3 ${Style.submitBtn}`}
            label="Submit"
            icon={mailSvg}
            clickHandler={() => handleJobRequest()}
          />
          <Button
            // type="customCancelBtn"
            cssClass="mr-3"
            label="Save for later"
            icon={saveSvg}
            clickHandler={() => {}}
          />
        </div>
      );
    }

    if (buttonStatus === JOB_STATUS_REQUESTED || isBookingDatesChange()) {
      if (lastDate >= currentDate) {
        return (
          <div className={Style.roleDetailFooter}>
            {/* {isBookingDatesChange() && <h6>Date Changes</h6>} */}
            <div className={Style.roleInnerFooter}>
              <Button
                cssClass={Style.saveforlater}
                label="Save for later"
                icon={saveSvg}
                clickHandler={handleSaveLater}
              />
              <div className="d-flex">
                <Button
                  type="customSaveBtn"
                  cssClass={`mr-3 ${Style.submitBtn} w-100`}
                  label={isBookingDatesChange() ? 'Accept Dates' : 'Accept'}
                  icon={mailSvg}
                  clickHandler={() => handleProcessRequest(JOB_STATUS_BOOKED)}
                />
                <Button
                  type="customCancelPending"
                  cssClass="mr-3 w-100"
                  label={isBookingDatesChange() ? 'Cancel Dates' : 'Cancel Booking'}
                  icon={cancelSvg}
                  clickHandler={() => handleProcessRequest(JOB_STATUS_CANCELLED, 'not interested')}
                />
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className={Style.roleDetailFooter}>
          <Button type="customCancelBtn" cssClass={Style.saveforlater} label="Cancel" clickHandler={handleSaveLater} />
        </div>
      );
    }

    if (buttonStatus === JOB_STATUS_PENDING || buttonStatus === JOB_STATUS_BOOKED) {
      return (
        <BookingButton
          closeDialog={closeDialog}
          jobDates={job.dates}
          type="customCancelPending"
          job={job.id}
          isCancelOnly
        />
      );
    }

    return <></>;
  };
  const closeDialog = () => {
    handleClose();
  };

  const parseString = (
    label: string,
    start: string | undefined | null,
    end: string | undefined | null,
    type: string,
  ) => {
    let str: string = '';

    if ((start && start.length > 0) || (end && end.length > 0)) {
      str += label;
    }

    if (start) {
      const typeText = /^[a-zA-Z]+$/.test(start) ? '' : type;
      str += `${start}${typeText}`;
    }
    if (str.length > 0) str += ' - ';
    if (end) {
      const typeText = /^[0-9]?[a-zA-Z]+$/.test(end) ? '' : type;
      str += `${end}${typeText}, `;
    }
    return str;
  };

  const getClothingOption = () => {
    let str: string = '';
    str += parseString('Neck: ', job.neck, job.maxNeck, '"');
    str += parseString('Sleeve: ', job.sleeve, job.maxSleeve, '"');
    str += parseString('Jacket: ', job.jacket, job.maxJacket, '"');
    str += parseString('Inseam: ', job.inseam, job.maxInseam, '"');
    str += parseString('Shoe: ', job.shoes, job.maxShoes, 'no');
    return str || 'N/A';
  };

  const getBodyRequirements = () => {
    let str: string = '';
    if (job.chest) str += parseString('Chest: ', job.chest, job.maxChest, '"');
    if (job.waist) str += parseString('Waist: ', job.waist, job.maxWaist, '"');
    return str || 'N/A';
  };

  const handleNewTabClick = () => {
    saveUserOnLocalStorage(directorObj);
    window.open('/profile-view', '_blank');
  };

  return (
    <div>
      <div className={Style.roleDetailWrapper}>
        <ModalHeader
          title="Role Details"
          handleClick={() => {
            handleClose();
          }}
          icon={roleSvg}
        />

        <div className={`customScrollBar ${Style.roleDetailBody}`}>
          {isBookingDatesChange() && <div className={Style.alertDialog}>Date Changes</div>}
          <div className="d-flex justify-content-between">
            <p className={Style.roleDetailTitle} style={{ width: 'auto' }}>
              <img className="mr-2" src={roleSvg} alt="" />
              {job.title}
            </p>
            <p className={Style.roleDetailTitle} style={{ width: 'auto' }}>
              <span
                style={{ color: '#eb5757' }}
                className="mb-0"
                onClick={() => {}}
                onKeyPress={() => {}}
                role="button"
                tabIndex={0}
              >
                report
              </span>
            </p>
          </div>
          <p className={`mb-0 ${Style.roleDetailDescription}`} />
          <div className="d-flex mt-4 flex-column flex-wrap flex-md-row row-gap-10">
            <span
              className={`d-flex mb-2 mb-md-0 justify-content-start justify-content-md-between align-items-center 
              ${Style.pr70}`}
            >
              <img src={locationImage} alt="location" />
              <p className="mb-0 ml-3">{job.location ? job.location.label : ''}</p>
            </span>
            <span
              className={`d-flex mb-2 mb-md-0 justify-content-start 
            justify-content-md-between align-items-center ${Style.pr70}`}
            >
              <img src={tagImage} alt="tag" />
              <p className="mb-0 ml-3">{job.category}</p>
            </span>
            <span
              className={`d-flex mb-2 mb-md-0 justify-content-start 
              justify-content-md-between align-items-center ${Style.pr70}`}
            >
              <img className="mr-4" src={calendar2Svg} alt="tag" />
              <DateItem newDates={booking ? booking.newDates : []} cssClass={Style.columnMargin} dates={getJobDates} />
            </span>
          </div>
          <p className={`mt-30 mb-30 ${Style.roleDetailTitle}`}>
            <img className="mr-2" src={infoSvg} alt="" />
            Additional Information
          </p>
          <div className="row">
            <div className="col-sm-12">
              <ProfileInfo cssClass="mb-3" label="Custome Notes" value={job.costumeNote || ''} />
            </div>
            <div className="col-sm-6">
              <ProfileInfo
                cssClass="mb-3"
                label="Height Range"
                value={`${formatHeight(job.minHeight || '')} - ${formatHeight(job.maxHeight || '')}`}
              />
            </div>
            <div className="col-sm-6">
              <ProfileInfo cssClass="mb-3" label="Ethnicity" value={job.ethnicity || ''} />
            </div>
            <div className="col-sm-6 mt-4">
              <ProfileInfo cssClass="mb-3" label="Clothing Option" value={getClothingOption()} />
            </div>
            <div className="col-sm-6 mt-4">
              <ProfileInfo cssClass="mb-3" label="Body Requirements" value={getBodyRequirements()} />
            </div>
            <div className="col-sm-12 mt-4">
              <ProfileInfo cssClass="mb-3" label="Remarks" value={job.remarks} />
            </div>
            <div className="col-sm-12 mt-4">
              <ProfileInfo cssClass="mb-3" label="Rate" value={job.rate ? job.rate.rate.toString() : ''} />
            </div>
            <div className="col-sm-6 mt-4">
              <ProfileInfo
                cssClass="mb-3"
                label="Casting Director"
                value={directorObj ? `${directorObj.firstName} ${directorObj.lastName}` : ''}
                isRedirectLink
                handleRedirect={handleNewTabClick}
              />
            </div>
          </div>
        </div>
        {getBottomButtons()}
      </div>
      <div
        className={Style.showRoleBgWrapper}
        onClick={closeDialog}
        onKeyPress={closeDialog}
        tabIndex={0}
        role="button"
      />
    </div>
  );
};

export default ItemRoleDetail;
