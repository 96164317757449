import React, { FunctionComponent, useEffect } from 'react';
import ShowLeftNavigation from 'components/ShowLeftNavigation';
import MainContainer from 'containers/MainContainer';
import LandingStyle from 'views/castingDirector/landing/landing.module.css';
// import { ShowTopCalendarTypeList } from 'modules/Show/show.types';
import ProducerMatrix from 'components/Producer/Matrix/index';
import TopNavBar from 'components/TopNavBar/TopNavBar';
import { Route, Switch } from 'react-router-dom';
import MatrixView from 'components/Producer/Matrix/MatrixItem/MatrixView';

interface ProducerLandingProps {
  // showCalendarDates: ShowTopCalendarTypeList;
  // setShowSelectedDate: Function;
  getEmailTemplateRequest: Function;
  getSettingRequest: Function;
  // clearSelectedJobListing: Function;
}
const ProducerLanding: FunctionComponent<ProducerLandingProps> = (props) => {
  const { getEmailTemplateRequest, getSettingRequest } = props;

  useEffect(() => {
    getEmailTemplateRequest({});
    getSettingRequest();
  }, []);

  return (
    <>
      <TopNavBar />
      <MainContainer cssClass={LandingStyle.paddingTop30}>
        <div className={LandingStyle.landingMainLayout}>
          <div className={`${LandingStyle.ladingLeftSide}`}>
            <ShowLeftNavigation />
          </div>
          <div className={`ml-0 ml-sm-3 ml-md-3 ml-lg-3 ml-xl-3 ${LandingStyle.landingRightSide}`}>
            <Switch>
              <Route exact path="/production/matrix" component={MatrixView} />
              <Route exact path="/production/home" component={ProducerMatrix} />
            </Switch>
          </div>
        </div>
      </MainContainer>
    </>
  );
};

export default ProducerLanding;
