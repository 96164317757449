import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import SearchButton from 'components/controls/button/button';
import SliderWrapper from 'components/controls/Slider/SliderWrapper';
import PerformerSearchFilterTab from 'components/PerformerSearchFilterTabs/PerformerSearchFilterTab';
import { OptionList, Options, OPTION_TYPE, RentalOptionList, ScreenSize } from 'modules/general/general.type';
import DropDownSearch from 'components/controls/select/DropDownSearch';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import {
  getHeightOptions,
  getInseamOptions,
  getJacketSizeOptions,
  getNeckOptions,
  getSleeveOptions,
  NumberOptions,
} from 'helpers/data/SelectOptions';
import { SearchFilterType, SearchFilterTypeList } from 'modules/PerformerList/list.types';
import { IPerformerSearchParam } from 'modules/params/param.type';
import { ShowDayJob, ShowTopCalendarTypeList } from 'modules/Show/show.types';
import barSvg from 'assets/svg/bar-black.svg';
import searchSvg from 'assets/svg/search.svg';
import upArrowSvg from 'assets/svg/up-arrow.svg';

import SearchProfileContStyle from 'containers/FindPerformerContainer/SearchProfileContainer.module.css';
import { formatHeight, formatInUnit, formatWeight } from 'helpers/utils';
import CalendarItem from 'components/calendar/CalendarItem/CalendarItem';
import Style from 'components/calendar/CalendarItem/CalendarItem.module.css';
import { Pagination } from 'modules/jobs/types';
import moment from 'moment';
import {
  PERFORMER_TAB,
  SCREEN_SIZE_MEDIUM_TABLET,
  SCREEN_SIZE_MOBILE,
  SCREEN_SIZE_TABLET,
  UNIT_FORMAT,
} from 'store/castingPax.constants';
import { useSelector } from 'react-redux';
import { getScreenWidth } from 'store/selector/general.selector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { directorDays } from 'components/controls/Slider/SliderResponsiveness';

interface SearchPeformerProps {
  isLoading: boolean;
  searchPerformerRequest: Function;
  clearSearchResult: Function;
  selectedJob: ShowDayJob;
  setPerformerViewMode: Function;
  showDates: ShowTopCalendarTypeList;
  selcetedDates: string[];
  pagination: Pagination;
  isLoadMore: number;
  sortPerformerResult: Function;
  sortingOrder: number;
  searchExistingPerformer: Function;
  cssClass?: string;
  performerTab: string;
  setPerformerTab: Function;
  rentalOpt: RentalOptionList;
}
const FindPerformerContainer: FunctionComponent<SearchPeformerProps> = (props) => {
  const {
    isLoading,
    searchPerformerRequest,
    clearSearchResult,
    selectedJob,
    setPerformerViewMode,
    showDates,
    selcetedDates,
    pagination,
    isLoadMore,
    sortPerformerResult,
    sortingOrder,
    searchExistingPerformer,
    cssClass,
    performerTab,
    setPerformerTab,
    rentalOpt,
  } = props;
  const [selectedDate, setSelectedDate] = useState<string[]>(selcetedDates || []);
  const [ethnicity, setEthnicity] = useState(selectedJob.ethnicity || '');
  const [category, setCategory] = useState(selectedJob.category || '');
  const [unionStatus, setUnionStatus] = useState<OptionList>();
  const [gender, setGender] = useState('');
  const [rental, setRental] = useState<OptionList>();
  const [specialSkills, setSpecialSkills] = useState<OptionList>();
  const [location, setLocation] = useState<OptionList>();
  const [lowestAge, setLowestAge] = useState(selectedJob.age || '');
  const [highestAge, setHighestAge] = useState(selectedJob.maxAge || '');
  const [minHeight, setMinHeight] = useState(selectedJob.minHeight || '');
  const [maxHeight, setMaxHeight] = useState(selectedJob.maxHeight || '');
  const [lightestWeight, setLightestWeight] = useState(selectedJob.weight || '');
  const [heaviestWeight, setHeaviestWeight] = useState(selectedJob.maxWeight || '');
  const [eyes, setEyes] = useState(selectedJob.eyes || '');
  const [hair, setHair] = useState(selectedJob.hair || '');
  const [chest, setChest] = useState(selectedJob.chest || '');
  const [maxChest, setMaxChest] = useState(selectedJob.maxChest || '');
  const [neck, setNeck] = useState(selectedJob.neck || '');
  const [maxNeck, setMaxNeck] = useState(selectedJob.maxNeck || '');
  const [sleeve, setSleeve] = useState(selectedJob.sleeve || '');
  const [maxSleeve, setMaxSleeve] = useState(selectedJob.maxSleeve || '');
  const [jacket, setJacket] = useState(selectedJob.jacket || '');
  const [maxJacket, setMaxJacket] = useState(selectedJob.maxJacket || '');
  const [inseam, setInseam] = useState(selectedJob.inseam || '');
  const [maxInseam, setMaxInseam] = useState(selectedJob.maxInseam || '');
  const [shoes, setShoes] = useState(selectedJob.shoes || '');
  const [maxShoes, setMaxShoes] = useState(selectedJob.maxShoes || '');
  const [activeStatus, setActiveStatus] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const limit = 6;

  useEffect(() => {
    console.log();
    return () => setPerformerTab(PERFORMER_TAB.FIND_PERFORMER);
  }, []);

  useEffect(() => {
    setSelectedDate(selcetedDates);
  }, [selcetedDates]);

  useEffect(() => {
    const timeOutId = setTimeout(() => searchExistingPerformer({ searchText, selectedDate, activeStatus }), 300);
    return () => clearTimeout(timeOutId);
  }, [searchText, selectedDate, activeStatus]);

  useEffect(() => {
    clearSearchResult();
    clickHandle();
  }, []);

  useEffect(() => {
    if (isLoading) return;
    if (isLoadMore > 0 && pagination && pagination.page < pagination.totalPages) {
      searchPerformerRequest(getSearchParamObject(pagination.page + 1));
    }
  }, [isLoadMore]);

  const handleRemoveFilter = (e: React.FormEvent<HTMLDivElement>, type: OPTION_TYPE) => {
    switch (type) {
      case 'ethinicity':
        return setEthnicity('');
      case 'union':
        return setUnionStatus([]);
      case 'gender':
        return setGender('');
      case 'category':
        return setCategory('');
      case 'rental':
        return setRental([]);
      case 'skills':
        return setSpecialSkills([]);
      case 'location':
        return setLocation([]);
      case 'minheight':
        return setMinHeight('');
      case 'maxheight':
        return setMaxHeight('');
      case 'minage':
        return setLowestAge('');
      case 'maxage':
        return setHighestAge('');
      case 'minweight':
        return setLightestWeight('');
      case 'maxweight':
        return setHeaviestWeight('');
      case 'eyes':
        return setEyes('');
      case 'hair':
        return setHair('');
      case 'neck':
        return setNeck('');
      case 'maxneck':
        return setMaxNeck('');
      case 'sleeve':
        return setSleeve('');
      case 'maxsleeve':
        return setMaxSleeve('');
      case 'shoe':
        return setShoes('');
      case 'maxshoe':
        return setMaxShoes('');
      case 'jacket':
        return setJacket('');
      case 'maxjacket':
        return setMaxJacket('');
      case 'chest':
        return setChest('');
      case 'maxchest':
        return setMaxChest('');
      case 'inseam':
        return setInseam('');
      case 'maxinseam':
        return setMaxInseam('');
      default:
        return '';
    }
  };

  const handleRemoveWrapper = (label: string, type: OPTION_TYPE) => ({
    label,
    remove: (e: React.FormEvent<HTMLDivElement>) => {
      handleRemoveFilter(e, type);
    },
  });

  const filterOptionLabel = (list?: OptionList) => {
    if (list) {
      return list.map((x: Options) => x.label).join(',');
    }
    return '';
  };

  const filterOptionId = (list?: OptionList) => {
    if (list) {
      return list.map((x: Options) => x.value).join(',');
    }
    return '';
  };

  const updateFilters = () => {
    const arr: SearchFilterTypeList = [];
    if (ethnicity) arr.push(handleRemoveWrapper(`Ethinicity: ${ethnicity}`, 'ethinicity'));
    if (unionStatus && unionStatus.length > 0) {
      arr.push(handleRemoveWrapper(`Union: ${filterOptionLabel(unionStatus)}`, 'union'));
    }
    if (gender) arr.push(handleRemoveWrapper(`Gender: ${gender}`, 'gender'));
    if (category) arr.push(handleRemoveWrapper(`Category: ${category}`, 'category'));
    if (rental) arr.push(handleRemoveWrapper(`Rental ${filterOptionLabel(rental)}`, 'rental'));
    if (specialSkills && specialSkills.length > 0) {
      arr.push(handleRemoveWrapper(`Skills: ${filterOptionLabel(specialSkills)} `, 'skills'));
    }
    if (location && location.length > 0) {
      arr.push(handleRemoveWrapper(`Locations: ${filterOptionLabel(location)}`, 'location'));
    }
    if (lowestAge) arr.push(handleRemoveWrapper(`Lowest age: ${lowestAge}`, 'minage'));
    if (highestAge) arr.push(handleRemoveWrapper(`Highest age: ${highestAge}`, 'maxage'));
    if (lightestWeight) arr.push(handleRemoveWrapper(`Min weight: ${formatWeight(lightestWeight)}`, 'minweight'));
    if (heaviestWeight) arr.push(handleRemoveWrapper(`Max weight: ${formatWeight(heaviestWeight)}`, 'maxweight'));
    if (eyes) arr.push(handleRemoveWrapper(`Eyes: ${eyes}`, 'eyes'));
    if (hair) arr.push(handleRemoveWrapper(`Hair: ${hair}`, 'hair'));
    if (minHeight) arr.push(handleRemoveWrapper(`Min height ${formatHeight(minHeight || '')}`, 'minheight'));
    if (maxHeight) arr.push(handleRemoveWrapper(`Max height ${formatHeight(maxHeight || '')}`, 'maxheight'));
    if (neck) arr.push(handleRemoveWrapper(`Min neck ${formatInUnit(neck, UNIT_FORMAT.INCH)}`, 'neck'));
    if (maxNeck) arr.push(handleRemoveWrapper(`Max neck ${formatInUnit(maxNeck, UNIT_FORMAT.INCH)}`, 'maxneck'));
    if (sleeve) arr.push(handleRemoveWrapper(`Min sleeve ${formatInUnit(sleeve, UNIT_FORMAT.INCH)}`, 'sleeve'));
    if (maxSleeve) {
      arr.push(handleRemoveWrapper(`Max sleeve ${formatInUnit(maxSleeve, UNIT_FORMAT.INCH)}`, 'maxsleeve'));
    }
    if (inseam) arr.push(handleRemoveWrapper(`Min inseam ${formatInUnit(inseam, UNIT_FORMAT.INCH)}`, 'inseam'));
    if (maxInseam) {
      arr.push(handleRemoveWrapper(`Max inseam ${formatInUnit(maxInseam, UNIT_FORMAT.INCH)}`, 'maxinseam'));
    }
    if (shoes) arr.push(handleRemoveWrapper(`Min shoes ${shoes}`, 'shoe'));
    if (maxShoes) arr.push(handleRemoveWrapper(`Max shoes ${maxShoes}`, 'maxshoe'));
    if (jacket) arr.push(handleRemoveWrapper(`Min jacket ${jacket}`, 'jacket'));
    if (maxJacket) arr.push(handleRemoveWrapper(`Max jacket ${maxJacket}`, 'maxjacket'));
    if (chest) arr.push(handleRemoveWrapper(`Min chest ${formatInUnit(chest, UNIT_FORMAT.INCH)}`, 'chest'));
    if (maxChest) arr.push(handleRemoveWrapper(`Max chest ${formatInUnit(maxChest, UNIT_FORMAT.INCH)}`, 'maxchest'));

    return arr.map((filter: SearchFilterType, index) => <PerformerSearchFilterTab key={index} filter={filter} />);
  };

  const clickHandle = () => {
    if (isLoading) return;
    console.log(getSearchParamObject(1));
    searchPerformerRequest(getSearchParamObject(1));
  };

  const getSearchParamObject = (pageNo: number): IPerformerSearchParam => ({
    ethinicity: ethnicity,
    location: filterOptionId(location),
    unionStatus: filterOptionId(unionStatus),
    minHeight,
    maxHeight,
    minWeight: lightestWeight,
    maxWeight: heaviestWeight,
    minAge: lowestAge,
    maxAge: highestAge,
    rental: filterOptionLabel(rental),
    hair,
    eyes,
    skills: filterOptionId(specialSkills),
    gender,
    minChest: chest,
    maxChest,
    minNeck: neck,
    maxNeck,
    minSleeve: sleeve,
    maxSleeve,
    minInseam: inseam,
    maxInseam,
    minJacket: jacket,
    maxJacket,
    minShoes: shoes,
    maxShoes,
    dates: selectedDate.map((x: string) => x.substring(0, 10)),
    jobId:
      performerTab !== PERFORMER_TAB.FIND_PERFORMER && selectedJob && selectedJob.id !== '-1'
        ? selectedJob.id
        : undefined,
    text: searchText,
    page: pageNo,
    limit,
    activeStatus: getLastActiveDate,
    isPending: performerTab === PERFORMER_TAB.SUBMITTED_REQUEST,
  });

  const getLastActiveDate = useMemo(() => {
    let num: string = '';
    if (activeStatus) {
      const arr: string[] = activeStatus.split(' ');
      const len: number = arr.length;
      let i: number = 0;
      while (i < len && !num) {
        const n: number = parseInt(arr[i], 10);
        if (n > 0) {
          num = moment().add(-Math.abs(n), 'days').format('YYYY-MM-DD HH:mm:ss');
        }
        i += 1;
      }
    }
    return num;
  }, [activeStatus]);

  const handleDateSelection = (date: string) => {
    const index = selectedDate.findIndex((x: string) => x === date);
    if (index === -1) {
      setSelectedDate([...selectedDate, date]);
    } else {
      const prev = selectedDate;
      prev.splice(index, 1);
      setSelectedDate([...prev]);
    }
  };
  const [showSearchWrapper, setShowSearchWrapper] = useState(false);

  const toggleSearchWrapper = () => {
    setShowSearchWrapper(!showSearchWrapper);
  };

  const handleSearchText = (event: React.FormEvent<HTMLInputElement>) => setSearchText(event.currentTarget.value);

  const reOrder = (type: number) => {
    if (type !== sortingOrder) sortPerformerResult(type);
  };
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);
  const searchProfile = () => {
    if (
      currentScreenSize === SCREEN_SIZE_MOBILE ||
      currentScreenSize === SCREEN_SIZE_TABLET ||
      currentScreenSize === SCREEN_SIZE_MEDIUM_TABLET
    ) {
      return (
        <div
          className={`d-flex flex-column hello justify-content-between 
          ${cssClass} ${SearchProfileContStyle.sortWrapper}`}
        >
          <div className="form-group mb-3 position-relative w-100">
            <input
              type="text"
              placeholder="Search"
              onChange={handleSearchText}
              className={`border-0 form-control ${SearchProfileContStyle.searchArea}`}
            />
            <FontAwesomeIcon icon={faSearch} className={SearchProfileContStyle.inputSearchIcon} />
          </div>
          <div className={SearchProfileContStyle.sortingContentMobile}>
            <p className={`mb-0  ${SearchProfileContStyle.sortTitle}`}>Sort by</p>
            <div className={SearchProfileContStyle.searchbarIconsWrapperMobile}>
              <div
                onClick={() => setPerformerViewMode()}
                onKeyPress={() => setPerformerViewMode()}
                role="button"
                tabIndex={0}
              >
                <img src={barSvg} alt="" className={SearchProfileContStyle.bar} />
              </div>

              <div onClick={() => reOrder(-1)} onKeyPress={() => reOrder(-1)} role="button" tabIndex={0}>
                <img src={upArrowSvg} alt="" />
              </div>
              <div onClick={() => reOrder(1)} onKeyPress={() => reOrder(1)} role="button" tabIndex={0}>
                <img src={downArrowSvg} alt="" />
              </div>
            </div>
          </div>
          <div
            className={`w-100 d-flex align-items-center justify-content-between 
          ${SearchProfileContStyle.dateActiveWrapperMobile}`}
          >
            <div className={`d-flex performerSearchWrapper searchSectionWrapper ${SearchProfileContStyle.searchSort}`}>
              <DropDownSearch
                defaultValue="Active"
                optionType="activeStatus"
                value={activeStatus}
                handleSelect={setActiveStatus}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={`d-flex justify-content-between ${cssClass} ${SearchProfileContStyle.sortWrapper}`}>
        <p className={`mb-0  ${SearchProfileContStyle.sortTitle}`}>Sort by</p>
        <div className="d-flex performerSearchWrapper findPerformerPage searchSectionWrapper">
          <DropDownSearch
            defaultValue="Active"
            optionType="activeStatus"
            value={activeStatus}
            handleSelect={setActiveStatus}
          />
        </div>
        <div className={SearchProfileContStyle.searchbarIconsWrapper}>
          <div
            onClick={() => setPerformerViewMode()}
            onKeyPress={() => setPerformerViewMode()}
            role="button"
            tabIndex={0}
          >
            <img src={barSvg} alt="" className={SearchProfileContStyle.bar} />
          </div>

          <div onClick={() => reOrder(-1)} onKeyPress={() => reOrder(-1)} role="button" tabIndex={0}>
            <img src={upArrowSvg} alt="" />
          </div>
          <div onClick={() => reOrder(1)} onKeyPress={() => reOrder(1)} role="button" tabIndex={0}>
            <img src={downArrowSvg} alt="" />
          </div>
        </div>
        <div className={`form-group mb-0 position-relative ${SearchProfileContStyle.searchAreaWrapper}`}>
          <input
            type="text"
            placeholder="Search"
            onChange={handleSearchText}
            className={`border-0 form-control ${SearchProfileContStyle.searchArea}`}
          />
          <img src={searchSvg} alt="" className={SearchProfileContStyle.inputSearchIcon} />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`mb-3 ${SearchProfileContStyle.searchWrappercontainer}`}>
        <button
          type="button"
          onClick={toggleSearchWrapper}
          className={`py-3 ${SearchProfileContStyle.searchWrapperbtn} ${SearchProfileContStyle.customsearchWrapper}`}
        >
          {/* {showSearchWrapper ? 'Hide Search' : 'Show Search'} */}
          show search
          <img src={downArrowSvg} alt="" />
        </button>
        {showSearchWrapper && (
          <div className={`${SearchProfileContStyle.searchWrapper}`}>
            <div className="row performerSearchWrapper customperformerSearchWrapper">
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  label="Ethnicity"
                  value={ethnicity}
                  defaultValue="Scottish"
                  optionType="ethnicity"
                  isMultiSelect
                  handleSelect={setEthnicity}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  label="Category"
                  value={category}
                  optionType="category"
                  defaultValue="Select"
                  isMultiSelect
                  handleSelect={setCategory}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  label="Union status"
                  optionType="union"
                  selectedObj={unionStatus || []}
                  defaultValue="Select"
                  isMultiSelect
                  storeObject
                  handleSelect={setUnionStatus}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  label="Hair"
                  optionType="hair"
                  value={hair}
                  defaultValue="Select"
                  isMultiSelect
                  handleSelect={setHair}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  label="Eyes"
                  optionType="eyes"
                  value={eyes}
                  defaultValue="Select"
                  isMultiSelect
                  handleSelect={setEyes}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  label="Gender"
                  optionType="gender"
                  value={gender}
                  defaultValue="Select"
                  handleSelect={setGender}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  label="Rental"
                  defaultValue="Select"
                  selectedObj={rental}
                  isMultiSelect
                  storeObject
                  handleSelect={setRental}
                  data={rentalOpt}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  label="Special skills"
                  defaultValue="Select"
                  selectedObj={specialSkills}
                  optionType="skills"
                  isMultiSelect
                  storeObject
                  handleSelect={setSpecialSkills}
                />
              </div>
              <div className="col-md-12 col-lg-12 col-xl-3">
                <DropDownSearch
                  label="Location"
                  selectedObj={location}
                  defaultValue="Select"
                  optionType="location"
                  isMultiSelect
                  storeObject
                  handleSelect={setLocation}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  label="Age"
                  value={lowestAge}
                  defaultValue="Lowest"
                  data={NumberOptions(5, 55)}
                  handleSelect={setLowestAge}
                />
                {/* <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-6">

              </div>
              <div className="col-md-12 col-lg-12 col-xl-6">

              </div>
            </div> */}
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  label="Max Age"
                  value={highestAge}
                  defaultValue="Highest"
                  data={NumberOptions(5, 55)}
                  handleSelect={setHighestAge}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  value={formatHeight(minHeight || '')}
                  label="Height"
                  data={getHeightOptions()}
                  defaultValue="Min"
                  handleSelect={setMinHeight}
                  sort
                />
              </div>

              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  value={formatHeight(maxHeight || '')}
                  data={getHeightOptions()}
                  label="Max Height"
                  defaultValue="Max"
                  handleSelect={setMaxHeight}
                  sort
                />
              </div>
              {/* <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-6">
                <DropDownSearch
                  value={formatHeight(minHeight || '')}
                  label="Height"
                  data={getHeightOptions()}
                  defaultValue="Min"
                  handleSelect={setMinHeight}
                  sort
                />
              </div>
              <div className="col-md-12 col-lg-12 col-xl-6">
                <DropDownSearch
                  value={formatHeight(maxHeight || '')}
                  data={getHeightOptions()}
                  label="Max Height"
                  defaultValue="Max"
                  handleSelect={setMaxHeight}
                  sort
                />
              </div>
            </div>
          </div> */}
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  value={formatWeight(lightestWeight)}
                  label="Weight"
                  data={NumberOptions(10, 400, UNIT_FORMAT.LBS)}
                  defaultValue="Lightest"
                  handleSelect={setLightestWeight}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  value={formatWeight(heaviestWeight)}
                  data={NumberOptions(10, 400, UNIT_FORMAT.LBS)}
                  label="weight"
                  defaultValue="Heaviest"
                  handleSelect={setHeaviestWeight}
                />
              </div>
              {/* <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-6">
                <DropDownSearch
                  value={formatWeight(lightestWeight)}
                  label="Weight"
                  data={NumberOptions(10, 400, UNIT_FORMAT.LBS)}
                  defaultValue="Lightest"
                  handleSelect={setLightestWeight}
                />
              </div>
              <div className="col-md-12 col-lg-12 col-xl-6">
                <DropDownSearch
                  value={formatWeight(heaviestWeight)}
                  data={NumberOptions(10, 400, UNIT_FORMAT.LBS)}
                  label="weight"
                  defaultValue="Heaviest"
                  handleSelect={setHeaviestWeight}
                />
              </div>
            </div>
          </div> */}
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  value={formatInUnit(chest, UNIT_FORMAT.INCH)}
                  label="Chest"
                  data={NumberOptions(15, 55, UNIT_FORMAT.INCH)}
                  defaultValue="min"
                  handleSelect={setChest}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  value={formatInUnit(maxChest, UNIT_FORMAT.INCH)}
                  data={NumberOptions(15, 55, UNIT_FORMAT.INCH)}
                  label="Max Chest"
                  defaultValue="max"
                  handleSelect={setMaxChest}
                />
              </div>
              {/* <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-6">
                <DropDownSearch
                  value={formatInUnit(chest, UNIT_FORMAT.INCH)}
                  label="Chest"
                  data={NumberOptions(15, 55, UNIT_FORMAT.INCH)}
                  defaultValue="min"
                  handleSelect={setChest}
                />
              </div>
              <div className="col-md-12 col-lg-12 col-xl-6">
                <DropDownSearch
                  value={formatInUnit(maxChest, UNIT_FORMAT.INCH)}
                  data={NumberOptions(15, 55, UNIT_FORMAT.INCH)}
                  label="Max Chest"
                  defaultValue="max"
                  handleSelect={setMaxChest}
                />
              </div>
            </div>
          </div> */}
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  value={formatInUnit(neck, UNIT_FORMAT.INCH)}
                  label="Neck"
                  data={getNeckOptions()}
                  defaultValue="min"
                  handleSelect={setNeck}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  value={formatInUnit(maxNeck, UNIT_FORMAT.INCH)}
                  data={getNeckOptions()}
                  label="max Neck"
                  defaultValue="max"
                  handleSelect={setMaxNeck}
                />
              </div>
              {/* <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-6">
                <DropDownSearch
                  value={formatInUnit(neck, UNIT_FORMAT.INCH)}
                  label="Neck"
                  data={getNeckOptions()}
                  defaultValue="min"
                  handleSelect={setNeck}
                />
              </div>
              <div className="col-md-12 col-lg-12 col-xl-6">
                <DropDownSearch
                  value={formatInUnit(maxNeck, UNIT_FORMAT.INCH)}
                  data={getNeckOptions()}
                  label="max Neck"
                  defaultValue="max"
                  handleSelect={setMaxNeck}
                />
              </div>
            </div>
          </div> */}
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  value={formatInUnit(sleeve, UNIT_FORMAT.INCH)}
                  label="Sleeve"
                  data={getSleeveOptions()}
                  defaultValue="min"
                  handleSelect={setSleeve}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  value={formatInUnit(maxSleeve, UNIT_FORMAT.INCH)}
                  data={getSleeveOptions()}
                  label="max Neck"
                  defaultValue="max"
                  handleSelect={setMaxSleeve}
                />
              </div>
              {/* <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-6">
                <DropDownSearch
                  value={formatInUnit(sleeve, UNIT_FORMAT.INCH)}
                  label="Sleeve"
                  data={getSleeveOptions()}
                  defaultValue="min"
                  handleSelect={setSleeve}
                />
              </div>
              <div className="col-md-12 col-lg-12 col-xl-6">
                <DropDownSearch
                  value={formatInUnit(maxSleeve, UNIT_FORMAT.INCH)}
                  data={getSleeveOptions()}
                  label="max Neck"
                  defaultValue="max"
                  handleSelect={setMaxSleeve}
                />
              </div>
            </div>
          </div> */}
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  value={jacket ?? ''}
                  cssClass={SearchProfileContStyle.createInputLabel}
                  data={getJacketSizeOptions()}
                  label="Jacket"
                  defaultValue="min"
                  handleSelect={setJacket}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  value={maxJacket ?? ''}
                  data={getJacketSizeOptions()}
                  label="Max Jacket"
                  defaultValue="max"
                  handleSelect={setMaxJacket}
                />
              </div>
              {/* <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-6">

              </div>
              <div className="col-md-12 col-lg-12 col-xl-6">

              </div>
            </div>
          </div> */}
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  value={formatInUnit(inseam, UNIT_FORMAT.INCH)}
                  cssClass={SearchProfileContStyle.createInputLabel}
                  label="Inseam"
                  data={getInseamOptions()}
                  defaultValue="min"
                  handleSelect={setInseam}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <DropDownSearch
                  value={formatInUnit(maxInseam, UNIT_FORMAT.INCH)}
                  label="Max Inseam"
                  data={getInseamOptions()}
                  defaultValue="max"
                  handleSelect={setMaxInseam}
                />
              </div>
              {/* <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-6">

              </div>
              <div className="col-md-12 col-lg-12 col-xl-6">

              </div>
            </div>
          </div> */}

              <div className="col-md-12 col-lg-12 col-xl-3">
                <DropDownSearch
                  value={shoes ?? ''}
                  cssClass={SearchProfileContStyle.createInputLabel}
                  data={NumberOptions(5, 15)}
                  label="Shoe size"
                  defaultValue="min"
                  handleSelect={setShoes}
                />
              </div>
              <div className="col-md-12 col-lg-12 col-xl-3">
                <DropDownSearch
                  value={maxShoes ?? ''}
                  data={NumberOptions(5, 15)}
                  label="Max Shoes"
                  defaultValue="max"
                  handleSelect={setMaxShoes}
                />
              </div>
              {/* <div className="col-md-12 col-lg-12 col-xl-3">
            <div className="row">
              <div className="col-md-6 col-lg-6 col-xl-6">
                <DropDownSearch
                  value={shoes ?? ''}
                  cssClass={SearchProfileContStyle.createInputLabel}
                  data={NumberOptions(5, 15)}
                  label="Shoe size"
                  defaultValue="min"
                  handleSelect={setShoes}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-6">
                <DropDownSearch
                  value={maxShoes ?? ''}
                  data={NumberOptions(5, 15)}
                  label="Max Shoes"
                  defaultValue="max"
                  handleSelect={setMaxShoes}
                />
              </div>
            </div>
          </div> */}
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="text-right">
                  <SearchButton
                    cssClass="fontFourteen font-weight-normal performerSearchBtn"
                    label="Search"
                    clickHandler={clickHandle}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {showDates && showDates.length > 0 && (
        <div
          className={`mb-3 justify-content-between 
        ${SearchProfileContStyle.searchCalenderWrapper} 
        ${Style.searchCalendarPerformer}`}
        >
          <SliderWrapper sliderSettings={directorDays} slideToShow={10}>
            {showDates.map((date) => (
              <CalendarItem
                key={date.id}
                isSelected={selectedDate.includes(date.date)}
                title={date.title}
                dayNo={date.dayTitle}
                handleClick={() => {
                  handleDateSelection(date.date);
                }}
              />
            ))}
          </SliderWrapper>
        </div>
      )}
      <div className="mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-0">{searchProfile()}</div>
      <div className="mb-3 d-flex flex-wrap">{updateFilters()}</div>
    </>
  );
};

export default FindPerformerContainer;
