import React, { useState } from 'react';
import Modal from 'components/Payroll/VoucherManagement/voucher/utils/modal';
import StyledFilterTab from '../styles/filter/FilterTab.styled';
import DownArrow from '../common/DownArrow';
import DropMenu from '../VoucherManagement/voucher/dropMenu.component';
import HideCompletedMenu from '../VoucherManagement/voucher/hide-completed.component';

interface Props {
  placeholderText?: string;
  label?: string;
  height?: number;
  withLabel?: boolean;
  style?: any;
  enableInput?: boolean;
  fontSize?: number;
  fontWeight?: number;
  lightText?: boolean;
  extraText?: string;
  borderRight?: string;
  labelBorderRight?: string;
  enableCheck?: boolean;
  data?: DataItem[];
  checked: boolean;
  toggleCheck: () => void;
  handleFilter?: any;
}

interface DataItem {
  id: string;
  label: string;
  value: string;
}

const FilterTab: React.FC<Props> = ({
  placeholderText,
  label,
  withLabel,
  height,
  style,
  enableInput,
  fontSize,
  fontWeight,
  lightText,
  borderRight,
  labelBorderRight,
  extraText,
  data,
  checked,
  toggleCheck,
  enableCheck,
  handleFilter,
}) => {
  const [toggleIcon, setToggleIcon] = useState<boolean>(false);
  const handleClick = () => {
    setToggleIcon(!toggleIcon);
  };

  return (
    <>
      <StyledFilterTab
        fontSize={fontSize}
        fontWeight={fontWeight}
        withLabel={withLabel}
        height={height}
        style={style}
        enableInput={enableInput}
        borderRight={borderRight}
        labelBorderRight={labelBorderRight}
        onClick={handleClick}
      >
        {lightText ? <h5>{label}</h5> : <h4>{label}</h4>}
        {extraText ? <h2>{extraText}</h2> : ''}
        <input type="search" placeholder={placeholderText} />
        <DownArrow toggleIcon={toggleIcon} />
      </StyledFilterTab>
      {toggleIcon && !enableCheck && <DropMenu data={data} handleFilter={handleFilter} />}
      {toggleIcon && enableCheck && (
        <HideCompletedMenu checked={checked} toggleCheck={toggleCheck} handleFilter={handleFilter} />
      )}
      {toggleIcon && <Modal closeModal={handleClick} />}
    </>
  );
};
export default FilterTab;
