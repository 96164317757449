import React, { FunctionComponent, useEffect, useState } from 'react';
import { User, SearchUserActionType, ROLE_PRODUCTION, ROLE_DIRECTOR } from 'modules/user/types';
import Style from 'components/UserView/UserView.module.css';
import plusSvg from 'assets/svg/plus.svg';
import closeSvg from 'assets/svg/close.svg';
import { getName, getRoleFromTitle, getRoleId, saveUserOnLocalStorage } from 'helpers/utils';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import downArrowSvg from 'assets/svg/down-arrow-secondary-color.svg';
import exportBorderedSvg from 'assets/svg/export-secondary-color.svg';
import deleteSvg from 'assets/svg/delete-secondary-color.svg';
// import infoUserImage from 'assets/images/profile2.png';
import { tagShowRoles } from 'helpers/data/SelectOptions';
import { SelectOptionType } from 'modules/PerformerList/list.types';
import checkSvg from 'assets/svg/only-check.svg';

interface UserViewProps {
  user: User;
  handleClick?: (e: React.FormEvent<HTMLSpanElement>) => void;
  icon?: SearchUserActionType;
  handleChangeShowRole?: Function;
}
const UserView: FunctionComponent<UserViewProps> = (props) => {
  const { user, handleClick, icon, handleChangeShowRole } = props;
  const [roles, setRoles] = useState<SelectOptionType[]>([]);
  const [selectedRole, setSelectedRole] = useState<any>(
    user.role === ROLE_PRODUCTION || user.role === ROLE_DIRECTOR ? getRoleId(user.role) : user.showRole || '',
  );

  useEffect(() => setRoles(tagShowRoles()), []);

  const addClickListener = () => {
    switch (icon) {
      case 'ADD':
        return <img src={plusSvg} alt="" />;
      case 'REMOVE':
        return <img src={closeSvg} alt="" />;
      default:
        return '';
    }
  };

  const handleClickLi = (roleId: string) => {
    setSelectedRole(roleId);
    if (handleChangeShowRole && icon === 'REMOVE') handleChangeShowRole(user, roleId);
  };

  const handleNewTabClick = () => {
    saveUserOnLocalStorage(user);
    window.open('/profile-view', '_blank');
  };

  const assistantComponent = () => (
    <ul role="menu" className={`list-unstyled mb-0 ${Style.assistantWrapper} cursor-pointer`}>
      {roles.map((x: SelectOptionType, index: number) => (
        <li
          onClick={() => handleClickLi(x.value)}
          onKeyPress={() => handleClickLi(x.value)}
          tabIndex={0}
          role="menuitem"
          key={index}
        >
          {x.label}
          {selectedRole && selectedRole === x.value && <img src={checkSvg} alt="" className={Style.checkIcon} />}
        </li>
      ))}
    </ul>
  );

  return (
    <>
      {icon === 'REMOVE' ? (
        <div className="mb-3">
          <div className="">
            <div className={Style.infoBgWrapper}>
              <div className={Style.infoFirstSection}>
                <img src={user.profilePicUrl} alt="info user img" />
                <h5 className={Style.infoTitle}>{getName(user)}</h5>
              </div>
              <div className={Style.infoSecondSection}>
                <p className="mb-0">{getRoleFromTitle(selectedRole)}</p>
                <PopoverContainer itemComponent={assistantComponent}>
                  <img src={downArrowSvg} alt="arrow down icon" />
                </PopoverContainer>
              </div>
              <div className={Style.infoThirdSection}>
                <span className="mb-0" onClick={handleClick} onKeyPress={handleClick} role="button" tabIndex={0}>
                  <img src={deleteSvg} alt="delete icon" />
                </span>
                <span
                  className="mb-0"
                  onClick={handleNewTabClick}
                  onKeyPress={handleNewTabClick}
                  role="button"
                  tabIndex={0}
                >
                  <img src={exportBorderedSvg} alt="export icon" />
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={Style.userWrapper}>
          <div className="d-flex justify-content-start align-items-center">
            <img className="mr-2" src={user.profilePicUrl} alt="user" />
            <p>{getName(user)}</p>
          </div>
          <span className="mb-0" onClick={handleClick} onKeyPress={handleClick} role="button" tabIndex={0}>
            {addClickListener()}
          </span>
        </div>
      )}
    </>
  );
};

export default UserView;
