/* eslint-disable react/destructuring-assignment */
import React, { FunctionComponent } from 'react';
import Styled from 'styled-components';
import ShowProducerItem from './ShowProducerItem';
import { ShowPerformerRowData } from './showProducerData';
import Search from './Search';

interface ShowProducerProps {
  open: boolean;
}

const ShowProducerDropDown: FunctionComponent<ShowProducerProps> = (props: ShowProducerProps) => (
  <StyledDropDownWrapper open={props.open}>
    <SearchContainer>
      <Search />
    </SearchContainer>

    <StyledDropdown>
      {ShowPerformerRowData.map((item, index) => (
        <ShowProducerItem name={item.name} type={item.type} key={index} />
      ))}
    </StyledDropdown>
  </StyledDropDownWrapper>
);

const SearchContainer = Styled.div`
`;

const StyledDropDownWrapper = Styled.div`
  height: fit-content;
  position: absolute;
  display: ${(props: ShowProducerProps) => (props.open ? 'flex' : 'none')};
  box-sizing: border-box;
  position: absolute;
  width: 300px;
  color: blue;
  z-index:500;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 300px;
  right: -5px;
  top: 60px;
  border: 2px solid lightBlue;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border: 2px solid lightBlue;

  &::after {
    content: '';
    position: absolute;
    height: 14px;
    width: 14px;
    right: 24px;
    top: -8px;
    background: white;
    border: 2px solid lightBlue;
    border-right: transparent;
    border-bottom: transparent;
    transform: rotate(45deg);
  }
`;

const StyledDropdown = Styled.div`
  width: 100%;
  padding-top: 170px;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  border-radius: 10px;
`;

export default ShowProducerDropDown;
