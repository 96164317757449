import React, { FunctionComponent } from 'react';
import topHeader from 'components/topHeader/payrollNavHeader.module.css';
import { Link } from 'react-router-dom';

const Header: FunctionComponent = () => (
  <header className={topHeader.container}>
    <Link to="/payroll/home">
      <div className={topHeader.topHeading}>
        <h1>
          Casting
          <span>PAX</span>
        </h1>
        <h2>PAYROLL</h2>
      </div>
    </Link>
  </header>
);

export default Header;
