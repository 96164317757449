import React, { FunctionComponent, useState } from 'react';
import Button from 'components/controls/button/button';
import CustomInput from 'components/controls/input/customInput/CustomInput';

interface IModalProp {
  handleSave: Function;
  handleClose: Function;
  item: any;
}
const MileageTimeModalBody: FunctionComponent<IModalProp> = (props) => {
  const { handleSave, handleClose, item } = props;
  const [time, setTime] = useState<number>(item.data.mileage);
  const [mileage, setMileage] = useState<number>(item.data.mileageType);
  const [timeError, setTimeError] = useState<string>('');
  const [mileageError, setMileageError] = useState<string>('');

  const handleSaveModal = () => {
    if (!time) setTimeError('Time Mileage Required');
    if (!mileage) setMileageError('Amount Mileage Required');
    if (time && mileage) {
      handleSave({ mileage, mileageType: time });
      handleClose();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-3 col-lg-3 col-sm-4">
          <label htmlFor="">Time:</label>
        </div>
        <div className="col-md-9 col-lg-9 col-sm-8">
          <CustomInput
            value={time ? time.toString() : ''}
            placeholder="Enter Time"
            type="number"
            handleChange={setTime}
            errorMessage={timeError}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 col-lg-3 col-sm-4">
          <label htmlFor="">Distance in KM.:</label>
        </div>
        <div className="col-md-9 col-lg-9 col-sm-8">
          <CustomInput
            value={mileage ? mileage.toString() : ''}
            placeholder="Enter Distance"
            type="number"
            handleChange={setMileage}
            errorMessage={mileageError}
          />
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <Button type="customSaveBtn" clickHandler={() => handleSaveModal()} label="Save" />
        &ensp;
        <Button type="customCancelBtn" clickHandler={() => handleClose()} label="Cancel" />
      </div>
    </>
  );
};

export default MileageTimeModalBody;
