import React, { FunctionComponent } from 'react';
import PerformerProfileStyle from 'components/bgPerformer/BasicInfo/BasicInfo.module.css';
import ProfileInfo from 'components/PerformerProfile/ProfileInfo';
import { User } from 'modules/user/types';
import Button from 'components/controls/button/button';
import payrollSvg from 'assets/svg/dollar.svg';
import uploadSvg from 'assets/svg/upload.svg';
import editSvg from 'assets/svg/edit-white.svg';
import { getRoleFromTitle } from 'helpers/utils';
import { resumeUploadingProgress } from 'store/selector/auth.selector';
import { useSelector } from 'react-redux';
import Resume from 'components/bgPerformer/Resume/Resume';
import { showResumeLoading } from 'store/selector/performer.selector';
import PhoneInputFormat from 'components/phoneInput/phoneInputFormat';

interface BasicInfoProps {
  profile: User;
  handleEdit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleResumeUpload: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleEditResume: Function;
}
const BasicInfo: FunctionComponent<BasicInfoProps> = (props: BasicInfoProps) => {
  const { profile, handleEdit, handleResumeUpload, handleEditResume } = props;
  const showResumeUploadingProgress = useSelector(resumeUploadingProgress);
  const resumeLoading = useSelector(showResumeLoading);
  const hide: string = '1';

  const handlePayroll = () => console.log('handle Size card');

  const isResumeUploaded = () => {
    let count = 0;
    if (profile.resumeUrl1?.includes('null') || profile.resumeUrl1 === undefined) count += 1;
    if (profile.resumeUrl2?.includes('null') || profile.resumeUrl2 === undefined) count += 1;

    return count === 2;
  };

  return (
    <div className="d-flex align-items-center justify-content-start">
      <div className={`${PerformerProfileStyle.imageWrapper}`}>
        <img src={profile.profilePicUrl} alt="" className={PerformerProfileStyle.profileImage} />
      </div>
      <div className={`${PerformerProfileStyle.infoWrapper}`}>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <p className={`mb-0 ${PerformerProfileStyle.profileName}`}>{`${profile.firstName} ${profile.lastName}`}</p>
          <div className="d-flex align-items-center mb-3">
            {hide === '2' && (
              <div>
                <Button
                  label="Payroll"
                  type="primary"
                  cssClass="d-flex align-items-center fontFourteen font-weight-normal px-2.5 py-2"
                  icon={payrollSvg}
                  clickHandler={handlePayroll}
                />
              </div>
            )}

            <div className="ml-3">
              <Button
                label="edit profile details"
                icon={editSvg}
                type="secondary"
                clickHandler={handleEdit}
                cssClass="d-flex align-items-center fontFourteen font-weight-normal px-3 py-2 mb-0"
              />
            </div>
          </div>
        </div>
        <div className={`position-relative ${PerformerProfileStyle.profileCard}`}>
          <div className="row mb-2 pt-3 px-3">
            <div className="col-sm-12 pt-2">
              <div className={PerformerProfileStyle.profileInfoWrapper}>
                <div className={PerformerProfileStyle.profileInfoInner}>
                  <ProfileInfo label="Role" value={getRoleFromTitle(profile.role)} />
                </div>
                <div className={PerformerProfileStyle.profileInfoInner}>
                  <ProfileInfo label="City" value={profile.address} />
                </div>
                <div className={PerformerProfileStyle.profileInfoInner}>
                  <PhoneInputFormat label="Phone" value={profile.phoneNo} />
                </div>
                <div className={PerformerProfileStyle.profileInfoInner}>
                  <ProfileInfo label="Email" value={profile.email} />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex px-3 justify-content-end pb-3 ">
            <Button
              label="Upload resume"
              icon={uploadSvg}
              type="secondary"
              showLoading={showResumeUploadingProgress}
              clickHandler={handleResumeUpload}
              cssClass="d-flex align-items-center fontFourteen font-weight-normal px-3 py-2 mr-3"
            />
            {!isResumeUploaded() && (
              <div className="uploadResumeBtn dropdown">
                <Button
                  showLoading={resumeLoading}
                  label=" View resume"
                  icon={uploadSvg}
                  type="secondary"
                  clickHandler={() => {}}
                  cssClass="d-flex align-items-center fontFourteen font-weight-normal px-3 py-2 viewResumeBtn"
                />
                <div className="dropdown-content">
                  <Resume user={profile} handleEditResume={handleEditResume} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
