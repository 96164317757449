import React, { FunctionComponent, useEffect } from 'react';
import CastingRequest from 'components/bgPerformer/CastingRequest/index';
import PerformerUpdate from 'components/bgPerformer/Update/index';
import Style from 'containers/bgPerformer/Submission/Submission.module.css';
import Calls from 'containers/bgPerformer/Calls/index';
import TopCalendar from 'components/bgPerformer/TopCalendar/index';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJobSearchFilterBegin } from 'store/actions/job.actions';
import { Route, Switch, useHistory } from 'react-router-dom';
import ExpandedSearch from 'containers/bgPerformer/ExpandedSearch/index';
import SearchView from 'components/bgPerformer/SubmissionSearch/SearchView';
import leftArrowSvg from 'assets/svg/left-arrow-blue.svg';
import { getScreenWidth } from 'store/selector/general.selector';
import { ScreenSize } from 'modules/general/general.type';
import { SCREEN_SIZE_MOBILE } from 'store/castingPax.constants';

const PerformerSubmission: FunctionComponent = () => {
  const dispatch = useDispatch();
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);

  const history = useHistory();

  useEffect(() => {
    dispatch(fetchJobSearchFilterBegin());
  }, []);

  const handleBack = () => history.goBack();

  const showBackButton = () => {
    if (history.location.pathname === '/job/search') {
      return (
        <div
          className={`mb-3 ${Style.backLinkText}`}
          onClick={handleBack}
          onKeyPress={handleBack}
          role="button"
          tabIndex={0}
        >
          <img src={leftArrowSvg} alt="back arrow" />
          <span className="mb-0">BACK</span>
        </div>
      );
    }
    return '';
  };
  if (currentScreenSize === SCREEN_SIZE_MOBILE) {
    return (
      <>
        <section className={Style.performerCastingPage}>
          {showBackButton()}
          <TopCalendar cssClass={Style.submissionTopCalendar} />
          <Switch>
            <Route path="/job/search" exact>
              <ExpandedSearch />
            </Route>
            <Route path="/performer/home" exact>
              <div className={`row ${Style.BoxCastingWrapper}`}>
                <div className="col-sm-12">
                  <div className={currentScreenSize === SCREEN_SIZE_MOBILE ? '' : `${Style.boxInnerWrapper}`}>
                    <SearchView />
                  </div>
                  <div className={`${Style.boxInnerWrapper} ${Style.boxInnerForRequest}`}>
                    <CastingRequest />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className={`${Style.boxInnerWrapper} ${Style.boxInnerForRequest}`}>
                    <PerformerUpdate />
                  </div>
                  <div
                    className={
                      currentScreenSize === SCREEN_SIZE_MOBILE
                        ? `py-0 px-0  ${Style.boxInnerWrapper}`
                        : `py-0 ${Style.boxInnerWrapper}`
                    }
                  >
                    <Calls />
                  </div>
                </div>
              </div>
            </Route>
          </Switch>
        </section>
      </>
    );
  }
  return (
    <>
      <section className={Style.performerCastingPage}>
        {showBackButton()}
        <TopCalendar cssClass={Style.submissionTopCalendar} />
        <Switch>
          <Route path="/job/search" exact>
            <ExpandedSearch />
          </Route>
          <Route path="/performer/home" exact>
            <div className={`row ${Style.BoxCastingWrapper}`}>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className={`${Style.boxInnerWrapper} ${Style.boxInnerForRequest}`}>
                  <CastingRequest />
                </div>
                <div className={`${Style.boxInnerWrapper}`}>
                  <PerformerUpdate />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className={currentScreenSize === SCREEN_SIZE_MOBILE ? '' : `${Style.boxInnerWrapper}`}>
                  <SearchView />
                </div>
                <div
                  className={
                    currentScreenSize === SCREEN_SIZE_MOBILE
                      ? `py-0 px-0  ${Style.boxInnerWrapper}`
                      : `py-0 ${Style.boxInnerWrapper}`
                  }
                >
                  <Calls />
                </div>
              </div>
            </div>
          </Route>
        </Switch>
      </section>
    </>
  );
};

export default PerformerSubmission;
