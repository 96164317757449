import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Style from 'components/castingDirector/Matrix.module.css';
import { MatrixAtoms, MatrixType, PerformerItem, Show } from 'modules/Show/show.types';
import { fetchMatrixPerformerBegin } from 'store/actions/show.actions';

import MatrixItem from 'components/Producer/Matrix/MatrixItem/MatrixGrid';

import { getUser } from 'store/selector/auth.selector';
import { User } from 'modules/user/types';
import Header from 'views/producer/header/Header';
import Button from 'components/controls/button/button';
import reloadSvg from 'assets/svg/reload_secondary.svg';
import { isMatrixPerformerLoading } from 'store/selector/show.selector';

interface MatrixDetailProps {
  dayItem: MatrixType;
  user: User;
  fetchMatrixPerformer: Function;
  isPerformerLoading: boolean;
}

const MatrixDetail: FunctionComponent<MatrixDetailProps> = (props: MatrixDetailProps) => {
  const { dayItem, user, fetchMatrixPerformer, isPerformerLoading } = props;
  const [item, setItem] = useState<MatrixType>(dayItem);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  useEffect(() => {
    setItem(dayItem);
    if (!item.performers || item.performers.length === 0) {
      loadPerformerData();
    }
  }, [dayItem]);

  useEffect(() => {
    setItem(dayItem);
  }, [dayItem.atoms, dayItem.performers]);

  const itemAtoms = useMemo(() => {
    const { atoms } = item;
    if (atoms) {
      return atoms.map((x: MatrixAtoms) => {
        if (item.performers !== undefined && item.performers.length > 0) {
          const obj = x;
          obj.performers = item.performers.filter((p: PerformerItem) => p.atoms === x.id);
          return obj;
        }
        return x;
      });
    }
    return [];
  }, [item, item.performers, item.atoms]);

  const loadPerformerData = () => fetchMatrixPerformer({ workingDayId: item.id, id: user.id });

  const handleRefresh = () => loadPerformerData();

  return (
    <>
      <div className={`mt-lg-0 mt-xl-0 ${Style.mainWrapper}`} id="mainMatrix">
        <Header ttlUsers={0} show={item.showId} setIsExpanded={setIsExpanded} isExpanded={isExpanded} />
        {isExpanded && (
          <>
            <div className={Style.extraMatrixWrapper}>
              <p className="mb-0">Extra Matrix</p>
              <Button
                showLoading={isPerformerLoading}
                cssClass={`matrixRefreshBtn ${Style.refreshBtn}`}
                clickHandler={handleRefresh}
                icon={reloadSvg}
              />
            </div>
            <div className={Style.extraMatrixItemWrapper}>
              {itemAtoms.map((atom: MatrixAtoms, index: number) => (
                <MatrixItem key={atom.id} atoms={atom} index={index} />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: Show) => {
  const user: User = getUser(state);
  const isPerformerLoading: boolean = isMatrixPerformerLoading(state);
  return { user, isPerformerLoading };
};

const mapDispatchToProps = {
  fetchMatrixPerformer: fetchMatrixPerformerBegin,
};

export default connect(mapStateToProps, mapDispatchToProps)(MatrixDetail);
