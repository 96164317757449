import React, { FunctionComponent } from 'react';
import downloadArrowSvg from 'assets/svg/download-arrow.svg';
import arrowDownSvg from 'assets/svg/arrowDown.svg';
import printSvg from 'assets/svg/print.svg';
import printWithoutSvg from 'assets/svg/print-without-text.svg';
import plusYellowSvg from 'assets/svg/plus-yellow.svg';
import editSvg from 'assets/svg/edit-background.svg';
import Style from 'components/Producer/Matrix/Budget/CreateBudget.module.css';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import BudgetExpert from 'components/Producer/Matrix/Budget/Popover/BudgetExport';
import { Bar } from 'react-chartjs-2';

interface ChartProps {
  data: any;
  handleAdd: (event: React.FormEvent<HTMLDivElement>) => void;
  handleEdit: (event: React.FormEvent<HTMLDivElement>) => void;
  handlePrint: (event: React.FormEvent<HTMLDivElement>) => void;
  handleExportDownload: Function;
  printRef: any;
  exportLoading: number;
  loaderType: string;
  type?: string;
}
const Chart: FunctionComponent<ChartProps> = (props: ChartProps) => {
  const { data, handleAdd, handleEdit, handlePrint, handleExportDownload, printRef, exportLoading, loaderType, type } =
    props;
  const getComponent = (
    <BudgetExpert isloading={exportLoading} loaderType={loaderType} type="chart" handleClick={handleExportDownload} />
  );

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },

    datasets: {
      bar: {
        barPercentage: 15,
        categoryPercentage: 0.01,
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
          },
        },
      ],
      yAxes: [
        {
          id: 'y',
          display: 'auto',
          ticks: {
            min: 0,
            stepSize: 0.5,
          },
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };

  return (
    <>
      <div className={type === 'cost' ? `p-2 ${Style.buttonsWrapper}` : `${Style.buttonsWrapper}`}>
        <div className={`mr-2 ${Style.exportButtonWrapper}`}>
          <img src={downloadArrowSvg} alt="" />
          <p className={type === 'cost' ? `mb-0 mr-0 ${Style.exportTitle}` : `mb-0 ${Style.exportTitle}`}>Export</p>
          <PopoverContainer itemComponent={() => getComponent}>
            <img src={arrowDownSvg} alt="" className={type === 'cost' ? '' : 'ml-3'} />
          </PopoverContainer>
        </div>
        <div className="mr-2" role="button" onClick={handlePrint} onKeyPress={handlePrint} tabIndex={0}>
          <img src={type === 'cost' ? printWithoutSvg : printSvg} alt="" />
        </div>
        <div className="mr-2" onClick={handleAdd} onKeyUp={handleAdd} tabIndex={0} role="button">
          <img src={plusYellowSvg} alt="" />
        </div>
        <div onClick={handleEdit} onKeyUp={handleEdit} tabIndex={0} role="button">
          <img src={editSvg} alt="" />
        </div>
      </div>
      <div ref={printRef}>
        <Bar type="bar" data={data} width={10} height={297} className={Style.chart} options={options} />
      </div>
    </>
  );
};

export default Chart;
