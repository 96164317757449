import React, { useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import TravelPopup from './TravelPopup';

const TravelCellRenderer = (props: any) => {
  const { value, valueFormatted } = props;
  const tippyRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const popUpContent = (
    <TravelPopup
      open={visible}
      width={328}
      height={194}
      message="Lorem Ipsum is simply dummy text of the printing and
      typesetting industry. Lorem Ipsum has been the industry's
      standard dummy text ever since the 1500s, when an unknown
      printer took a galley of type and scrambled it to make a type specimen book. It has survived"
    />
  );
  const travelCellValue = valueFormatted || value;
  return (
    <Tippy
      ref={tippyRef}
      content={popUpContent}
      visible={visible}
      allowHTML
      arrow
      appendTo={document.body}
      interactive
      placement="left"
    >
      <Wrapper>
        <p onMouseEnter={show} onMouseLeave={hide}>
          {props.data.mileage !== null &&
            props.data.mileageType !== null &&
            `${props.data.mileage || ''} / ${props.data.mileageType || ''}`}
        </p>
      </Wrapper>
    </Tippy>
  );
};

export default TravelCellRenderer;

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;

  & > p {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;
