import React from 'react';
import styled from 'styled-components';

interface Props {
  heightPercent?: number;
}

interface ISection {
  heightPercent?: number;
}

const ChartItemSection: React.FC<Props> = (props) => {
  const { heightPercent } = props;
  return (
    <>
      <ItemSection heightPercent={heightPercent} />
    </>
  );
};

export default ChartItemSection;

const ItemSection = styled.div<ISection>`
  background: #49d360;
  width: 100%;
  height: ${({ heightPercent }) => `${heightPercent}%`};
  border-radius: inherit;
`;
