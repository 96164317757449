import { connect } from 'react-redux';
import { SkillCategory } from 'modules/general/general.type';
import { getProfile, showLoading } from 'store/selector/performer.selector';
import { deleteResumeBegin, performerProfileBegin } from 'store/actions/performer.actions';
import { getUser } from 'store/selector/auth.selector';
import { setSelectedTab, uploadProfileImageBegin } from 'store/actions/auth.actions';
import UserProfile from './UserProfile';

const mapStateToProps = (state: SkillCategory) => {
  const profileData = getProfile(state);
  const user = getUser(state);
  const loading = showLoading(state);
  return { profileData, user, loading };
};

const mapDispatchToProps = {
  setSelectedTab,
  performerProfileBegin,
  uploadProfileImageBegin,
  deleteResumeBegin,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
