import React, { FunctionComponent } from 'react';
import { Episode, ShowWorkingDay } from 'modules/Show/show.types';
import Button from 'components/controls/button/button';
import Style from 'components/CreateShow/CreateShow.module.css';
import EpisodeDayInput from 'components/CreateShow/ShowEpisode/EpisodeDay/EpisodeDayInput';
import plusSvg from 'assets/svg/plus.svg';

interface SeasonEpisodeContainerProps {
  episodeData: Episode;
  handleAddEpisodeDay: (day: Episode) => void;
  handleChangeEpisodeData: Function;
}
const ShowEpisodeDayContainer: FunctionComponent<SeasonEpisodeContainerProps> = (props) => {
  const { episodeData, handleAddEpisodeDay, handleChangeEpisodeData } = props;

  const handleEpisodeAdd = () => {
    handleAddEpisodeDay(episodeData);
  };

  return (
    <>
      <div className={Style.episodeBgColor}>
        <h6>
          Episode
          {episodeData.episodeNo}
        </h6>

        <div className={`d-flex align-items-end ${Style.episodeWrapper}`}>
          <div className={Style.episodeInner}>
            {episodeData.episodeDays
              ? episodeData.episodeDays.map((obj: ShowWorkingDay, index) => (
                  <div className={`position-relative ${Style.showEpisodes}`}>
                    <EpisodeDayInput
                      key={obj.id}
                      lastIndex={episodeData.episodeDays.length === index + 1}
                      episodeDayData={obj}
                      handleChangeEpisodeData={handleChangeEpisodeData}
                    />
                  </div>
                ))
              : ''}
          </div>
          <Button cssClass={Style.seriesPlusBtn} icon={plusSvg} clickHandler={handleEpisodeAdd} />
        </div>
      </div>
    </>
  );
};

export default ShowEpisodeDayContainer;
