import React, { FunctionComponent } from 'react';
import ProfileCalenderStyle from 'components/ProfileCalender/ProfileCalender.module.css';
import { BookingDate } from 'modules/user/types';
import { setJobPostDates } from 'store/actions/show.actions';
import { connect } from 'react-redux';

interface ProfileCalenderProps {
  userId: string;
  date: BookingDate;
  cssClass?: string;
  isShowDate?: boolean;
  handleDateSelection?: Function;
  setDateSelection: Function;
}
const ProfileCalender: FunctionComponent<ProfileCalenderProps> = (props: ProfileCalenderProps) => {
  const { userId, date, cssClass, isShowDate, handleDateSelection, setDateSelection } = props;
  const handleClick = () => {
    if (handleDateSelection) handleDateSelection(date);
    else setDateSelection(date.date, userId);
  };
  return (
    <div
      className={`profileCalendarAfter ${date.type} ${ProfileCalenderStyle.calenderBox} ${cssClass} ${
        isShowDate ? ProfileCalenderStyle.showDate : ''
      }`}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
    >
      <span className={ProfileCalenderStyle.monthTitle}>{date.month}</span>
      <p className={`mb-0 mt-0 ${ProfileCalenderStyle.monthDate}`}>{date.day}</p>
    </div>
  );
};

const mapDispatchToProps = {
  setDateSelection: setJobPostDates,
};

export default connect(null, mapDispatchToProps)(ProfileCalender);
