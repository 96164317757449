import { ToastItem } from 'modules/general/general.type';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { removeToast } from 'store/actions/general.actions';
import { TOAST_TYPE } from 'store/castingPax.constants';
import { getToastList } from 'store/selector/general.selector';

const MessageToast: FunctionComponent = () => {
  const message: ToastItem = useSelector(getToastList);
  const dispatch = useDispatch();
  useEffect(() => {
    if (message) {
      if (message && 'id' in message) {
        if (!toast.isActive(message.id)) {
          if (message.type === TOAST_TYPE.SUCCESS) {
            toast(message.title, { toastId: message.id });
          } else {
            toast.error(message.title, { toastId: message.id });
          }
        }
      }
      dispatch(removeToast());
    }
  }, [message]);

  return <></>;
};

export default MessageToast;
