import DashboardInput from 'components/controls/input/dashboardInput/dashboardInput';
import React, { FunctionComponent } from 'react';

interface NewListProps {
  setName: Function;
}
const NewList: FunctionComponent<NewListProps> = (props) => {
  const { setName } = props;

  return (
    <div className="row">
      <div className=" col-sm-12">
        <DashboardInput placeholder="List Name" handleChange={setName} />
      </div>
    </div>
  );
};

export default NewList;
