import { connect } from 'react-redux';
import Search from 'components/bgPerformer/SubmissionSearch/Search';
import {
  getSearchJobResult,
  getSearchJobResultLoading,
  getSearchJobSortOrder,
  jobSearchFilterList,
} from 'store/selector/job.selector';
import { ShowDayJob } from 'modules/Show/show.types';
import { searchJobBegins, sortJobResult } from 'store/actions/job.actions';

const mapStateToProps = (state: ShowDayJob) => {
  const jobsResult = getSearchJobResult(state);
  const loading = getSearchJobResultLoading(state);
  const filterList = jobSearchFilterList(state);
  const sortOrder: number = getSearchJobSortOrder(state);
  return {
    jobsResult,
    loading,
    filterList,
    sortOrder,
  };
};

const mapDispatchToProps = {
  searchJobBegins,
  sortJobResult,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
