import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

interface DatePickerProps {
  open: boolean;
}

interface Props {
  open: boolean;
}

const DatePicker: React.FC<Props> = (props) => {
  const { open } = props;
  const defaultDate = useMemo(() => new Date('2022-05-12T13:45:00-05:00'), []);

  const events = [
    {
      title: 'My Event',
      start: '2015-04-12T13:45:00-05:00',
      end: '2015-04-12T14:00:00-05:00',
    },
  ];

  return (
    <DatePickerContainer>
      <StyledDatePicker open={open}>
        {' '}
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          // views={{ week: false }}
          defaultDate={defaultDate}
        />
      </StyledDatePicker>
    </DatePickerContainer>
  );
};

export default DatePicker;

const DatePickerContainer = styled.div``;

const StyledDatePicker = styled.section<DatePickerProps>`
  position: absolute;
  width: 450px;
  height: 500px;
  right: 0px;
  top: 75px;
  padding: 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: white;
  cursor: default;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border: 2px solid ${({ theme }) => theme.colors.lightBlue};

  z-index: ${({ open }) => (open ? 555 : 0)};

  &::after {
    content: '';
    position: absolute;
    height: 14px;
    width: 14px;
    background: white;
    top: -7px;
    right: 18.4px;
    transform: rotate(45deg);
    border: 2px solid ${({ theme }) => theme.colors.lightBlue};
    border-bottom: transparent;
    border-right: transparent;
  }
`;
