import React, { FunctionComponent, useState } from 'react';
import PlusSvg from 'assets/svg/plus-blue.svg';
import MinusSvg from 'assets/svg/minus-sign.svg';
import Style from 'views/helpCenter/HelpCenter.module.css';

interface HelpItemProps {
  title: string;
  description: string;
}
const HelpItem: FunctionComponent<HelpItemProps> = (props: HelpItemProps) => {
  const { title, description } = props;

  const [cardBody, setCardBody] = useState(false);
  return (
    <>
      <div className="card-header mb-0 d-flex justify-content-between align-items-center">
        <h4 className={`mb-0 ${Style.cardTitle}`}>{title}</h4>
        <div
          onClick={() => setCardBody(!cardBody)}
          onKeyPress={() => setCardBody(!cardBody)}
          role="button"
          tabIndex={0}
        >
          <img src={!cardBody ? PlusSvg : MinusSvg} alt="plus icon" />
        </div>
      </div>
      {cardBody && (
        <div className="card-body slide-down">
          <p className="mb-0 fontFourteen">{description}</p>
        </div>
      )}
    </>
  );
};
export default HelpItem;
