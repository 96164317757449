import PerformerImg from 'assets/images/performer.png';
import CoordinatorImg from 'assets/images/coordinator.png';
import CastingDirectorImg from 'assets/images/casting-director.png';
import ProductionDirectorImg from 'assets/images/production-director.png';
import { ROLE_COORDINATOR, ROLE_DIRECTOR, ROLE_PERFORMER, ROLE_PRODUCTION, ROLE_PAYROLL } from 'modules/user/types';

export default [
  { label: 'BG Performer', type: ROLE_PERFORMER, image: PerformerImg },
  { label: 'BG Coordinator', type: ROLE_COORDINATOR, image: CoordinatorImg },
  { label: 'Casting Director', type: ROLE_DIRECTOR, image: CastingDirectorImg },
  { label: 'Production Director', type: ROLE_PRODUCTION, image: ProductionDirectorImg },
  { label: 'Payroll', type: ROLE_PAYROLL, image: ProductionDirectorImg },
];
