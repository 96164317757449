import React, { FunctionComponent } from 'react';
import Style from 'components/calendar/CalendarItem/CalendarItem.module.css';
import { EpisodeDayList, ShowWorkingDay } from 'modules/Show/show.types';
import moment from 'moment';

interface DatePopupProps {
  list?: EpisodeDayList;
  setWorkingDay: Function;
  workingDays: EpisodeDayList;
  singleSelection?: boolean;
}
const JobDateSelection: FunctionComponent<DatePopupProps> = (props: DatePopupProps) => {
  const { list, workingDays, setWorkingDay, singleSelection } = props;

  const parseDate = (pdate: string, format: string) => moment(pdate).format(format);

  const handleClick = (d: ShowWorkingDay) => {
    const prevIds = workingDays;

    if (singleSelection) {
      prevIds.splice(0, 1, d);
      setWorkingDay([...prevIds]);
      return;
    }
    console.log('Days', workingDays, d);
    const index = workingDays.findIndex((x: ShowWorkingDay) => x.date.includes(d.date.split(' ')[0]));
    if (index !== -1 && workingDays[index].id !== d.id) {
      return;
    }

    const pos = workingDays.findIndex((x: ShowWorkingDay) => x.id === d.id);
    if (pos !== -1) {
      prevIds.splice(pos, 1);

      setWorkingDay([...prevIds]);
    } else {
      const wd = [...prevIds, d];
      setWorkingDay(wd);
    }
  };

  const getSelectedClass = (wd: string) => {
    const isExists = workingDays.filter((x: ShowWorkingDay) => x.id === wd);
    return isExists.length > 0 ? Style.selected : '';
  };

  return (
    <>
      <div className={`d-flex ${Style.dateBoxPopupWrapper}`}>
        {list &&
          list.map((d: ShowWorkingDay) => (
            <div
              key={d.id}
              onClick={() => {
                handleClick(d);
              }}
              onKeyPress={() => {
                handleClick(d);
              }}
              role="button"
              tabIndex={0}
              className={`${Style.itemWrapper} ${Style.itemSelectDate} ${getSelectedClass(d.id)}`}
            >
              <h5>{parseDate(d.date || '', 'MMM DD')}</h5>
              <h6>{d.title}</h6>
            </div>
          ))}
      </div>
    </>
  );
};

export default JobDateSelection;
