import { connect } from 'react-redux';
import ProducerMatrix from 'components/Producer/Matrix/ProducerMatrix';
import {
  getSelectedShowId,
  getShowLists,
  getShowSelectedDates,
  isUpdateShowStats,
  redirectedMatrix,
} from 'store/selector/show.selector';
import { Show } from 'modules/Show/show.types';
import { clearMatrixRedirected, fetchShowUserStatsBegin, getMatrixDataBegin } from 'store/actions/show.actions';

const mapStateToProps = (state: Show) => {
  const showList = getShowLists(state);
  const isUpdateStats = isUpdateShowStats(state);
  const dates = getShowSelectedDates(state);
  const showId = getSelectedShowId(state);
  const redirectedData = redirectedMatrix(state);

  return { showList, isUpdateStats, dates, showId, redirectedData };
};

const mapDispatchToProps = {
  fetchUserCount: fetchShowUserStatsBegin,
  getMatrixDataBegin,
  clearMatrixRedirected,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProducerMatrix);
