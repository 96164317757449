import React, { FunctionComponent } from 'react';
import { BookingRequestList, JobEventList } from 'modules/jobs/types';
import BgCalendar from 'components/bgPerformer/Booking/BgCalendar/BgCalendar';
import Details from 'components/bgPerformer/Booking/Details/Details';
import JobView from 'components/JobView';
import { User } from 'modules/user/types';
import moment from 'moment';

interface BookingProps {
  pendingBooking?: BookingRequestList;
  confirmedBooking?: BookingRequestList;
  eventList?: JobEventList;
  updateCalendarStatusBegin?: Function;
  updateCalendarStatusLocal?: Function;
  user: User;
}
const Booking: FunctionComponent<BookingProps> = (props: BookingProps) => {
  const { pendingBooking, confirmedBooking, eventList, user, updateCalendarStatusBegin, updateCalendarStatusLocal } =
    props;

  const getMonthWeeks = () => moment().endOf('month').week() - moment().startOf('month').week();
  return (
    <>
      <div className="calendarWrapperPage">
        <div className="row">
          <BgCalendar
            monthWeeks={getMonthWeeks()}
            user={user}
            bookingRequests={eventList || []}
            updateCalendarStatusBegin={updateCalendarStatusBegin}
            updateCalendarStatusLocal={updateCalendarStatusLocal}
          />
          <div className="col-lg-6 mob-no-padding">
            <Details bookingRequests={confirmedBooking} type="booked" />
            <Details bookingRequests={pendingBooking} type="pending" />
          </div>
        </div>
      </div>
      <JobView />
    </>
  );
};

export default Booking;
