import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import Style from 'components/TopNavBar/TopNavBar.module.css';
import hamburgerSvg from 'assets/svg/hamburger.svg';
import Search from 'components/TopNavBar/Search';
import HomePageLink from 'views/home/HomePageLink';
import closeSvg from 'assets/svg/close.svg';
import SideBar from 'components/TopNavBar/SideBar/SideBar';
import TopBarUserIcon from 'components/TopNavBar/User/TopBarUserIcon';
import TopNotificationIcon from 'components/TopNavBar/NotificationItem/TopNotificationIcon';
import { getUser } from 'store/selector/auth.selector';
import { ROLE_PERFORMER, ROLE_PAYROLL, User } from 'modules/user/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import TopNavigationArea from './TopNavigationArea';

interface topNavBarProps {
  user?: User;
  setShowSideNav?: React.Dispatch<React.SetStateAction<boolean>>;
  showSideNav?: boolean;
}
const TopNavBar: FunctionComponent<topNavBarProps> = (props: topNavBarProps) => {
  const { user, setShowSideNav, showSideNav } = props;
  const [showSidebar, setShowSidebar] = useState(true);
  const homeBurgerClicked = () => {
    setShowSidebar(!showSidebar);
  };
  const closeIconSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const getSearchComponent = () => {
    if (user && user.role !== ROLE_PERFORMER) {
      return <Search />;
    }
    return <></>;
  };
  const updateNavState = () => {
    if (setShowSideNav) setShowSideNav(!showSideNav);
  };
  const handleNavExpanded = (e: any) => {
    localStorage.setItem('navbar', e);
    console.log('nav...', localStorage.getItem('navbar'));
  };
  return (
    <div className={Style.headerWrapper}>
      <div className={Style.header}>
        {user && user.role === ROLE_PAYROLL && (
          <div className={Style.icon} onClick={updateNavState} onKeyPress={updateNavState} tabIndex={0} role="button">
            {showSideNav ? (
              <FontAwesomeIcon size="1x" icon={faTimes} onClick={() => handleNavExpanded(false)} />
            ) : (
              <FontAwesomeIcon size="1x" icon={faBars} onClick={() => handleNavExpanded(true)} />
            )}
          </div>
        )}
        <div className={`d-flex align-items-center ${Style.logoWrapper}`}>
          <div
            className="mobile-display-hamburger"
            onClick={homeBurgerClicked}
            onKeyPress={homeBurgerClicked}
            tabIndex={0}
            role="button"
          >
            <img className="mobile-display-only w-16" src={hamburgerSvg} alt="hamburger-icon" />
          </div>
          <HomePageLink />
        </div>
        <TopNavigationArea />
        <div className={`d-flex justify-content-end align-items-center ${Style.searchBoxWrapper}`}>
          {getSearchComponent()}
          <TopNotificationIcon />
          <TopBarUserIcon />
        </div>
        <div className={showSidebar ? `${Style.sidebarWrapper}` : `${Style.sidebarWrapper} ${Style.activeSidebar}`}>
          <div onClick={closeIconSidebar} onKeyPress={closeIconSidebar} tabIndex={0} role="button">
            <img className={Style.closeSidebar} src={closeSvg} alt="close icon" />
          </div>
          <SideBar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: User) => {
  const user = getUser(state);
  return {
    user,
  };
};

export default connect(mapStateToProps, null)(TopNavBar);
