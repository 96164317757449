/* eslint-disable array-callback-return */
import React from 'react';
import Styled from 'styled-components';
import RollVideoImage from 'assets/images/rollMovie.svg';
import InfoRowItem from './InfoRowItem';
import { PerformerRowData, PerformerRowBottomData } from './performanceData';

const PerformerItem = () => (
  <ItemContainer>
    <ImageContainer>
      <StyledImage src={RollVideoImage} />
    </ImageContainer>
    <PerformerInfoContainer>
      <PerformerInfoTop>Jonty rodas </PerformerInfoTop>
      <PerformerInfoBottom>
        <InfoBottomRow>
          {PerformerRowData.map((item, index) => (
            <InfoRowItem key={index} text={item.title} title={item.text} />
          ))}
        </InfoBottomRow>
        <InfoBottomRow>
          {PerformerRowBottomData.map((item, index) => (
            <InfoRowItem key={index} text={item.title} title={item.text} />
          ))}
        </InfoBottomRow>
      </PerformerInfoBottom>
    </PerformerInfoContainer>
  </ItemContainer>
);

const ItemContainer = Styled.div`
display:flex;
width:100%;
height: 410px;
`;

const ImageContainer = Styled.div`
width: 400px;
height:95%;
`;

const PerformerInfoContainer = Styled.div`
display:flex;
width: 100%;
height:100%;
align-item: center;
flex-direction:column;
backdrop-filter: blur(20px);
`;

const StyledImage = Styled.img`
height:400px;
`;

const PerformerInfoTop = Styled.div`
width: 100%;
padding-top:50px;
height: 20%;
display: flex;
align-items: center;
font-weight: 600;
font-family: 'Montserrat';
font-size: 34px;
padding-left: 40px;
margin-bottom: 40px;
`;

const PerformerInfoBottom = Styled.div`
display: flex;
flex-direction: column;
justify-content: center;
padding-top: 35px;
height: 200px;
background: #fff;
border-top-right-radius: 15px;
border-bottom-right-radius: 15px;
`;
const InfoBottomRow = Styled.div`
height: 50%;
width:100%;
display:flex;
align-items: center;
padding-left: 45px;
// padding-top: 35px;
padding-bottom: 40.6px;
margin-bottom: 0px !important;

`;

export default PerformerItem;
