import { connect } from 'react-redux';
import { ShowDayJobList } from 'modules/Show/show.types';
import ExpandedSearch from 'containers/bgPerformer/ExpandedSearch/ExandedSearch';
import {
  getAllBookingRequest,
  getBookingIds,
  getSearchJobResult,
  jobSearchPagination,
} from 'store/selector/job.selector';

const mapStateToProps = (state: ShowDayJobList) => {
  const jobs = getSearchJobResult(state);
  const bookingIds = getBookingIds(state);
  const pagination = jobSearchPagination(state);
  const allBookingRequest = getAllBookingRequest(state);
  return {
    jobs,
    bookingIds,
    pagination,
    allBookingRequest,
  };
};

export default connect(mapStateToProps, null)(ExpandedSearch);
