import React, { useState } from 'react';
import StyledDropdown from 'views/Payroll/styles/bg-dropdown/Dropdown.styled';
import StyledDropDownWrapper from 'views/Payroll/styles/bg-dropdown/DropdownWrapper.styled';
import styled from 'styled-components';
// import Style from 'components/controls/Modal/Modal.module.css';
import DropdownItem from '../show/components/DropdownItem';

interface DataItem {
  id: string;
  label: string;
  value: string;
}
interface Props {
  data?: DataItem[];
  enableCheckBox?: boolean;
  handleFilter?: Function;
}

const DropMenu: React.FC<Props> = (props) => {
  const { data, enableCheckBox, handleFilter } = props;
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const toggleDropdown = () => setOpenDropdown(!openDropdown);
  return (
    <>
      <CustomDropDown open onClick={toggleDropdown}>
        <CustomItemContainer>
          {enableCheckBox && <input type="checkbox" checked />}
          {data && (
            <div>
              {data &&
                data.map((item) => (
                  <CustomItem
                    key={item.id}
                    value={item.value}
                    label={item.label}
                    id={item.id}
                    handleFilter={handleFilter}
                  />
                ))}
            </div>
          )}
        </CustomItemContainer>
      </CustomDropDown>
    </>
  );
};

export default DropMenu;

const CustomDropDown = styled(StyledDropDownWrapper)`
  position: absolute;
  margin-top: 18px;
  left: 0px;
  width: fit-content;
  padding: 5px 0px;
  height: fit-content;
  overflow-y: hidden;
  cursor: none;
  @media only screen and (max-width: 1199px) {
    margin-top: 12px;
    left: -128px;
  }
`;

const CustomItemContainer = styled(StyledDropdown)`
  position: relative;
  width: 100%;
  padding: 1px 0px;
  border-bottom: none;
  align-items: center;
  overflow-y: hidden;
  gap: 0;
  min-height: 0px;
`;

const CustomItem = styled(DropdownItem)`
  position: relative;
  width: 100%;
  border-top: 0px;
  outline: none;
  max-width: 100%;
  text-align: left;
  justify-content: flex-start;
  cursor: none;
  &:hover {
    background: #f0f5f8;
  }
  & p {
    position: relative;
    width: 210px;
    cursor: pointer;
    transition: all 0.3s linear;
    padding: 0 10px;
    color: #4b4d67;
  }
`;
