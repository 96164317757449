import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import TopNavBar from 'components/TopNavBar/TopNavBar';
import Style from 'views/profileMenu/profileMenu.module.css';
import LeftNavigation from 'components/ProfileMenu/LeftNavigation/LeftNavigation';
import Setting from 'components/ProfileMenu/Settings/Setting';
import Voucher from 'components/ProfileMenu/VoucherInfo';
import Residency from 'components/ProfileMenu/ResidencyDocs/index';
import HelpCenter from 'views/helpCenter/HelpCenter';
import DaysWorked from 'components/ProfileMenu/DaysWorked/DaysWorked';
import Privacy from 'components/ProfileMenu/Privacy/Privacy';
import TaxInformation from 'components/ProfileMenu/TaxInformation/TaxInformation';
import QRCode from 'components/ProfileMenu/QrCode/index';

const MenuLanding: FunctionComponent = () => (
  <div className={Style.mainWrapper}>
    <div className={Style.TopNavWrapper}>
      <TopNavBar />
    </div>
    <div className={Style.settingBodyWrapper}>
      <div className={`${Style.bodyWrapper}`}>
        <LeftNavigation />
        <Switch>
          <Route exact path="/settings">
            <Setting />
          </Route>
          <Route exact path="/privacy-setting">
            <Privacy />
          </Route>
          <Route exact path="/help_center">
            <HelpCenter />
          </Route>
          <Route path="/qr_code">
            <QRCode />
          </Route>
          <Route path="/days">
            <DaysWorked />
          </Route>
          <Route path="/voucher_info">
            <Voucher />
          </Route>
          <Route path="/residency_proof">
            <Residency />
          </Route>
          <Route exact path="/tax-info">
            <TaxInformation />
          </Route>
        </Switch>
      </div>
    </div>
  </div>
);

export default MenuLanding;
