import React, { FunctionComponent, useEffect, useState } from 'react';
import HelpItem from 'views/helpCenter/HelpItem';
import Style from 'views/helpCenter/HelpCenter.module.css';
import { HelpCenterItem, HelpCenterList, HelpCenterQuestion } from 'modules/general/general.type';
import userApi from 'store/services/user.services';

const HelpCenter: FunctionComponent = () => {
  const [helpItems, setHelpItems] = useState<HelpCenterList>([]);

  useEffect(() => {
    userApi
      .fetchHelpCenterData()
      .then((res) => {
        if (setHelpItems) setHelpItems(res);
      })
      .catch((err) => err);
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className={`col-sm-12 bg-white ${Style.borderRadius}`}>
            {helpItems.map((item: HelpCenterItem) => (
              <>
                <h4 className="my-3 font-weight-bold text-dark">{item.title}</h4>
                <div>
                  {item.children.map((qus: HelpCenterQuestion) => (
                    <HelpItem title={qus.title} description={qus.answer} />
                  ))}
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default HelpCenter;
