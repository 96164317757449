import { SkillCategory } from 'modules/general/general.type';
import { BookingRequestList, CalendarList, PerformerJobs } from 'modules/jobs/types';

export interface User {
  voucher: any;
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  phoneNo?: string;
  email: string;
  address?: string;
  postCode?: string;
  password?: string;
  aboutUs?: string;
  city?: string;
  country?: string;
  profilePicUrl?: string;
  parentId?: number;
  experience?: number;
  roles?: string;
  unionNo?: string;
  unionStatus?: UnionNo;
  unionStatusId?: string;
  accountReason?: string;
  resumeUrl1?: string;
  resumeUrl2?: string;
  bgPerformer?: BGPerformer;
  resumeFile?: File;
  profileFile?: File;
  isCompleted?: Boolean;
  isProfilePublic?: boolean;
  role: string;
  token?: string;
  date?: string;
  lastActivity?: string;
  calendar?: CalendarList;
  subscription?: SubscriptionPlan;
  subscriptionExpirAt?: string;
  customerId?: string;
  cardId?: string;
  permissionStatus?: string;
  job?: PerformerJobs[];
  isEmailNotificationOn?: boolean;
  isNotificationOn?: boolean;
  UserWhoCanSeeProfiles?: string[];
  rentalImages?: RentalImageList;
  stripeLinked?: boolean;
  userRole?: string;
  businessName?: string;
  businessAddress?: string;
  PSTNumber?: string;
  GSTNumber?: string;
  showRole?: string;
}

export type UserObj = User;

export type UserList = User[];

export type dataUpdateMatrixList = dataUpdateMatrix[];

export interface dataUpdateMatrix {
  id: string;
  workingDayId: string;
}

export interface UnionNo {
  id: string;
  label: string;
  name: string;
  value: string;
  short: string;
}

export interface BGPerformer {
  id: string;
  gender: string;
  dateOfBirth: string;
  height: string;
  weight: string;
  hair: string;
  eyeColor: string;
  chest: string;
  waist: string;
  neck: string;
  shoe: string;
  jacket: string;
  inseam: string;
  sleeve: string;
  date?: string;
  calendar?: CalendarList;
  documents?: BGperformerDocuments;
  bookingDates?: BookingRequestList;
  images?: ImageFolderList;
  skills?: PerformerSkillList;
}

export type ImageFolderItem = {
  id: string;
  name: string;
  isDefault: boolean;
  sequence: number;
  images?: PerformerImageList;
};

export type ImageFolderList = ImageFolderItem[];

export type PerformerImage = {
  id: string;
  imageType: string;
  imageFile: string;
  folderId: string;
  folderName: string;
  image: string;
  sequence: number;
  isDisable?: boolean;
};

export type PerformerImageList = PerformerImage[];

export type PerformerSkill = {
  id: string;
  name: string;
  skillId: string;
  skillLevel: string;
  skillCategoryId: SkillCategory;
};

export type PerformerSkillList = PerformerSkill[];

export interface BGperformerDocuments {
  type: string;
  path: string;
  createdAt: string;
}

export type BookingDate = {
  id?: string;
  month: string;
  date: string;
  day: string;
  type?: string;
  job?: BookingRequestList;
  isJobDate?: boolean;
};

export type BookingDateList = BookingDate[];

export interface LOGIN_PARAMS {
  email: string;
  password: string;
}

export const ROLE_PAYROLL = 'PAYROLL' as RoleCode;
export const ROLE_PRODUCTION = 'PRODUCTION' as RoleCode;
export const ROLE_DIRECTOR = 'DIRECTOR' as RoleCode;
export const ROLE_COORDINATOR = 'COORDINATOR' as RoleCode;
export const ROLE_PERFORMER = 'PERFORMER' as RoleCode;
export const ROLE_HMW = 'HMW';
export const ROLE_ACCOUNT = 'ACCOUNT';
export const ROLE_ASSISTANT_DIRECTOR = 'ASSISTANT DIRECTOR';

export type RoleCode =
  | 'PAYROLL'
  | 'PRODUCTION'
  | 'DIRECTOR'
  | 'COORDINATOR'
  | 'PERFORMER'
  | 'HMW'
  | 'ADMIN'
  | 'ACCOUNT'
  | 'ASSISTANT DIRECTOR'
  | 'CASTING';

export interface ProfileInputProps {
  handleClick: Function;
  isLoading: boolean;
  authError: string;
  uploadProfileImage?: Function;
  user: User;
  saveSkillRef?: any;
  setSkillDialog?: Function;
}

export interface SignupProfile {
  user: User;
  step: number;
}

export type SubscriptionPlan = {
  id: string;
  title: string;
  ratePerMonth: number;
  ratePerYear: number;
  category: string;
  features: string;
  photos: number;
  skills: number;
  jobs: number;
  days: number;
};

export type RentalImage = {
  id: string;
  image: string;
  imageFile: string;
  rentalType: string;
};

export type RentalImageList = RentalImage[];

export type SearchUserActionType = 'ADD' | 'REMOVE' | 'NONE';

export type SELECTED_TAB = 'MATRIX' | 'CASTING_REQUEST' | 'FIND_PERFORMER' | 'TOOLS';

export type BUTTON_TYPE =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'customSaveBtn'
  | 'customCancelBtn'
  | 'loginButton'
  | 'customPending'
  | 'customCancelPending';

export const BOOLEAN_TRUE = true;
export const BOOLEAN_FALSE = false;

export type BookingStatus = 'requested' | 'pending' | 'booked' | 'available' | 'notavailable' | 'no';
