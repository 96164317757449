import React, { FunctionComponent } from 'react';
import Style from 'components/castingDirector/ContactSection/ContactItem.module.css';
import arrowDownSvg from 'assets/svg/arrowDown.svg';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import BgSizeCardView from 'components/castingDirector/PopOvers/BgSizeCard/BgSizeCard';

interface BgSizeCardProps {
  cssClass?: string;
  handleClick: Function;
  bgSizeCardLoading: boolean;
  type: string;
  icon: any;
}
const BgSizeCard: FunctionComponent<BgSizeCardProps> = (props: BgSizeCardProps) => {
  const { cssClass, handleClick, bgSizeCardLoading, type, icon } = props;

  const getComponent = () => (
    <BgSizeCardView type={type} bgSizeCardLoading={bgSizeCardLoading} handleClick={handleClick} />
  );

  return (
    <>
      <PopoverContainer itemComponent={() => getComponent()}>
        <div className={`d-flex align-items-center ${Style.contactSection} ${cssClass}`}>
          <img src={icon} alt="" className={`${'mr-3'}`} />
          <p className={`mb-0 w-100 ${Style.contactTitle}`}>BG Size Card</p>
          <img src={arrowDownSvg} alt="" className="ml-3" />
        </div>
      </PopoverContainer>
    </>
  );
};

export default BgSizeCard;
