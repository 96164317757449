import React, { FunctionComponent, useMemo, useState } from 'react';
import UpgradeButton from 'components/controls/button/button';
import CalendarStatus from 'components/calendar/CalendarStatus/CalendarStatus';
import Style from 'components/bgPerformer/Booking/Booking.module.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { getBookingStatusColor, getStatusNumebr } from 'helpers/utils';
import { BookingStatus, User } from 'modules/user/types';
import { JobEvent, JobEventList } from 'modules/jobs/types';
import BookingPopup from 'components/bgPerformer/Booking/BookingPopup/BookingPopup';
import { ICalendarUpdateParams } from 'modules/params/param.type';
import leftArrowSvg from 'assets/svg/left-arrow.svg';
import rightArrowSvg from 'assets/svg/right-arrow.svg';
import { useHistory } from 'react-router';

interface BgCalendarProps {
  bookingRequests: JobEventList;
  updateCalendarStatusBegin?: Function;
  updateCalendarStatusLocal?: Function;
  user: User;
  monthWeeks: number;
}
const BgCalendar: FunctionComponent<BgCalendarProps> = (props: BgCalendarProps) => {
  const { bookingRequests, updateCalendarStatusBegin, updateCalendarStatusLocal, user, monthWeeks } = props;
  const history = useHistory();
  const localizer = momentLocalizer(moment);
  const [calendarHeight, setCalendarHeight] = useState<string>(
    monthWeeks < 5 ? Style.calendarTopMargin : Style.calendarExtraTopMargin,
  );

  const calculateCalendarHeight = (monthNumber: number) => {
    const currentDate = moment().set('month', monthNumber);
    const weeksInMonth = currentDate.endOf('month').week() - currentDate.startOf('month').week();
    setCalendarHeight(weeksInMonth < 5 ? Style.calendarTopMargin : Style.calendarExtraTopMargin);
  };

  const handleClick = () => {
    history.push('/settings');
  };

  const dayStyleGetter = (date: Date) => {
    if (!bookingRequests) return {};
    const dateObj = date;
    dateObj.setDate(date.getDate() + 1);
    const dateValue = dateObj.toISOString().split('T')[0];
    const index = bookingRequests.findIndex((x: JobEvent) => x.date.includes(dateValue));

    const backgroundColor =
      index === -1 ? '#fff' : getBookingStatusColor(bookingRequests[index].status as BookingStatus);

    const style = {
      backgroundColor,
      borderRadius: '50%',
      margin: '0 23px',
      color: '#fff',
    };

    return {
      style,
    };
  };

  const eventStyleGetter = () => {
    const style = {
      height: '50px',
      backgroundColor: '#fff',
    };
    return {
      style,
    };
  };

  const handleCalendarClick = (event: JobEvent) => {
    if (event.jobs?.length !== 0) return;
    if (updateCalendarStatusBegin) {
      const params: ICalendarUpdateParams = {
        id: user ? user.bgPerformer?.id : '',
        workDate: event.date,
        workStatus: getStatusNumebr(event.status),
      };
      updateCalendarStatusBegin(params);
      if (updateCalendarStatusLocal) {
        updateCalendarStatusLocal(params);
      }
    }
  };

  const isProUser = useMemo(() => {
    if (user && user.subscription && user.subscription.id === '609a5f810e6ee514507fa965') return true;
    return false;
  }, [user]);

  const CustomToolbar = (toolbar: any) => {
    const goToBack = () => {
      const currentDate = moment();
      const calendarDate = moment(toolbar.date);
      if (currentDate.isSameOrBefore(calendarDate)) {
        calculateCalendarHeight(calendarDate.add(-1, 'month').get('months'));
        toolbar.onNavigate('PREV');
      }
    };

    const goToNext = () => {
      const currentDate = moment(toolbar.date);
      const maxDate = moment().add(2, 'month');
      if (currentDate.isSameOrBefore(maxDate)) {
        calculateCalendarHeight(currentDate.add(1, 'month').get('months'));
        toolbar.onNavigate('NEXT');
      }
    };

    return (
      <div className={`${Style.calendarHeader} ${Style['toolbar-container']}`}>
        <span className={`mb-0 ${Style.calendarMonthName} ${Style['label-date']}`}>
          {moment(toolbar.date).format('MMMM')}
        </span>

        <div className={`d-flex align-items-center ${Style['back-next-buttons']}`}>
          <span className={`mb-0 ${Style.calendarYearName} ${Style['label-date']}`}>
            {moment(toolbar.date).format('YYYY')}
          </span>
          <div className={`d-flex justify-content-between align-items-center ${Style.calendarButtonsWrapper}`}>
            <button type="button" className={`${Style.nextBackBtn} ${Style['btn-back']}`} onClick={goToBack}>
              <img src={leftArrowSvg} alt="left-arrow" />
            </button>
            <button type="button" className={`${Style.nextBackBtn} ${Style['btn-next']}`} onClick={goToNext}>
              <img src={rightArrowSvg} alt="right-arrow" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="col-lg-6 mob-no-padding">
        <div className="d-flex pb-3 justify-content-center">
          <CalendarStatus cssClass={Style.availableStatus} label="Available" />
          <CalendarStatus cssClass={Style.pendingStatus} label="Pending" />
          <CalendarStatus cssClass={Style.bookedStatus} label="Booked" />
          <CalendarStatus cssClass={Style.notAvailableStatus} label="Not Available" />
        </div>
        <div className={Style.calendarWrapper}>
          <div className={`d-flex justify-content-center flex-column ${Style.calendarFooter}`}>
            <Calendar
              views={['month']}
              localizer={localizer}
              events={bookingRequests}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              onSelectEvent={handleCalendarClick}
              dayPropGetter={dayStyleGetter}
              eventPropGetter={eventStyleGetter}
              components={{ event: BookingPopup, toolbar: CustomToolbar }}
            />
            <div className={`d-flex align-items-center justify-content-center ${calendarHeight}`}>
              {!isProUser && (
                <>
                  <p className={`mt-0 mb-0 mr-3 ${Style.needForCalendar}`}>Need a four week calendar ? </p>
                  <UpgradeButton cssClass={Style.calendarBtn} label="UPGRADE TO PRO" clickHandler={handleClick} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BgCalendar;
