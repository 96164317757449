import {
  IEmail,
  IIDParams,
  IProfileFileParam,
  IResetPassword,
  ISaveCardParams,
  IUpdatePassword,
  IValidateToken,
  INotificationSetting,
  IStripeAccount,
  IBusinessInfo,
} from 'modules/params/param.type';
import { LOGIN_PARAMS, User } from 'modules/user/types';
import { apiCall } from 'store/services/service';
import {
  URL_LOGIN,
  URL_PROFILE_UPDATE,
  URL_SIGNUP,
  URL_PROFILE_FILE,
  URL_UPDATE_PASSWORD,
  URL_FORGOT_REQUEST,
  URL_VALIDATE_FORGOT_TOKEN,
  URL_UPDATE_PASSWORD_TOKEN,
  URL_CONFIRM_EMAIL,
  URL_SUBSCRIPTION_PLANS,
  URL_UPDATE_SUBSCRIPTION,
  URL_FETCH_CARD_DETAILS,
  URL_SAVE_CARD_INFO,
  URL_NOTIFICATION_SETTING,
  URL_UPDATE_STRIPE,
  URL_ACCOUNT_TOKEN,
  URL_PAYROLL_INVITE,
  URL_DELETE_ACCOUNT,
  URL_PROFILE_SUBSCRIPTION,
  URL_STRIPE_LINKED,
  URL_BUSINESS_INFO,
} from 'store/services/URL';

export const api = {
  loginRequest(loginParams: LOGIN_PARAMS) {
    return apiCall.post({ url: URL_LOGIN, isAuthToken: false, params: loginParams });
  },
  signupRequest(user: User) {
    return apiCall.post({ url: URL_SIGNUP, isAuthToken: false, params: user });
  },
  profileUpdate(user: User) {
    return apiCall.post({ url: URL_PROFILE_UPDATE, isAuthToken: true, params: user });
  },
  invitePayrollUserRequest(invitePayrollParams: { email: string }) {
    return apiCall.post({ url: URL_PAYROLL_INVITE, isAuthToken: false, params: invitePayrollParams });
  },
  profileFiles(params: IProfileFileParam) {
    return apiCall.filePost(URL_PROFILE_FILE, params);
  },
  updatePassword(params: IUpdatePassword) {
    return apiCall.post({ url: URL_UPDATE_PASSWORD, isAuthToken: true, params });
  },
  sendForgotEmail(params: IEmail) {
    return apiCall.post({ url: URL_FORGOT_REQUEST, isAuthToken: false, params });
  },
  validateAuthToken(params: IValidateToken) {
    return apiCall.post({ url: URL_VALIDATE_FORGOT_TOKEN, isAuthToken: false, params });
  },
  confirmAccountEmail(params: IValidateToken) {
    return apiCall.post({ url: URL_CONFIRM_EMAIL, isAuthToken: false, params });
  },
  resetPassword(params: IResetPassword) {
    return apiCall.post({ url: URL_UPDATE_PASSWORD_TOKEN, isAuthToken: false, params });
  },
  fetchSubscriptionPlanList() {
    return apiCall.get({ url: URL_SUBSCRIPTION_PLANS, isAuthToken: true, params: {} });
  },
  updateSubscriptionPlan(params: IIDParams) {
    return apiCall.post({ url: URL_UPDATE_SUBSCRIPTION, isAuthToken: true, params });
  },
  fetchCardDetails() {
    return apiCall.get({ url: URL_FETCH_CARD_DETAILS, isAuthToken: true, params: {} });
  },
  saveCardInfo(params: ISaveCardParams) {
    return apiCall.post({ url: URL_SAVE_CARD_INFO, isAuthToken: true, params });
  },
  setNotificationPrivacy(params: INotificationSetting) {
    return apiCall.post({ url: URL_NOTIFICATION_SETTING, isAuthToken: false, params });
  },
  updateStripeAccount(params: IStripeAccount) {
    return apiCall.post({ url: URL_UPDATE_STRIPE, isAuthToken: false, params });
  },
  fetchAccountToken() {
    return apiCall.get({ url: URL_ACCOUNT_TOKEN, isAuthToken: true, params: {} });
  },
  deleteAccount(params: any) {
    return apiCall.post({ url: URL_DELETE_ACCOUNT, isAuthToken: true, params });
  },
  getSubscriptionHistory(params: any) {
    return apiCall.post({ url: URL_PROFILE_SUBSCRIPTION, isAuthToken: true, params });
  },
  stripeLinkedAccount() {
    return apiCall.get({ url: URL_STRIPE_LINKED, isAuthToken: true, params: {} });
  },
  updateBusinessInfo(params: IBusinessInfo) {
    return apiCall.post({ url: URL_BUSINESS_INFO, isAuthToken: true, params });
  },
};

export default api;
