import Login from 'views/login';
import SignupCategory from 'views/signupcategory/SignupCategory';
import Singup from 'views/signup';
import SignupProfile from 'views/signup/profile';
import CastingDirector from 'views/castingDirector/landing';
import ForgotPassword from 'views/forgotPassword/ForgotPassword';
import ResetPassword from 'views/resetPassword/ResetPassword';
import PrivacyPolicy from 'views/privacyPolicy/PrivacyPolicy';
import Cookies from 'views/cookies/cookies';
import TermsAndConditions from 'views/termsAndConditions/TermsAndConditions';
import Landing from 'views/landing/landing';
import Features from 'views/landing/features';
import ConfirmAccount from 'views/signup/confirmAccount';
import PayrollPerformer from 'views/PayrollPerformerProfile';
import UserManagement from 'views/usermanagement';
import RateManagement from 'views/rateManagement';
import VoucherManagement from 'views/VoucherManagement';

export default [
  {
    path: '/Home',
    component: Landing,
  },
  {
    path: '/features',
    component: Features,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/privacy',
    component: PrivacyPolicy,
  },
  {
    path: '/cookies',
    component: Cookies,
  },
  {
    path: '/terms',
    component: TermsAndConditions,
  },
  {
    path: '/signupcategory',
    component: SignupCategory,
  },
  {
    path: '/profile/:type',
    component: SignupProfile,
  },
  {
    path: '/signup/:type',
    component: Singup,
  },
  {
    path: '/casting_director',
    component: CastingDirector,
  },
  {
    path: '/forgot_password',
    component: ForgotPassword,
  },
  {
    path: '/reset_password/:token/:id',
    component: ResetPassword,
  },
  {
    path: '/confirm/:token',
    component: ConfirmAccount,
  },
  {
    path: '/performer-profile',
    component: PayrollPerformer,
  },
  {
    path: '/user-management',
    component: UserManagement,
  },
  {
    path: '/rate-management',
    component: RateManagement,
  },
  {
    path: '/voucher-management',
    component: VoucherManagement,
  },
];
