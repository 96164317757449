import getItemFromLocalStorage from './getItemFromLocalStorage';

export const temporarySaveSearchFilter = (params: any) => {
  localStorage.setItem('searchJobFilter', JSON.stringify(params));
};

export const gettemporarySaveSearchFilter = () => {
  const item = getItemFromLocalStorage('searchJobFilter');
  if (item) return item;
  return {};
};
