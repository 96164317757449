import React, { FunctionComponent } from 'react';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import { User } from 'modules/user/types';
import PerformerListItemStyle from 'components/MyPerformerList/MyPerformerList.module.css';
import ItemDetail from 'components/MyPerformerList/ListItems/ItemDetail';
import { getName, getUnionClass, getUnionValue } from 'helpers/utils';
import ImageView from 'components/controls/imageViewer/imageView';

interface ListItemProps {
  item: User;
}
const ListItem: FunctionComponent<ListItemProps> = (props: ListItemProps) => {
  const { item } = props;
  const itemComponent = () => <ItemDetail item={item} />;

  const unionNo = getUnionValue(item);

  return (
    <>
      <PopoverContainer itemComponent={itemComponent}>
        <div className={`d-flex  ${PerformerListItemStyle.listingItemWrapper}`}>
          <ImageView
            src={item.profilePicUrl || ''}
            fullImgSrc={item.profilePicUrl || ''}
            key={item.id}
            alt=""
            cssClass={PerformerListItemStyle.itemImage}
          />
          {/* <img src={item.profilePicUrl} alt="" className={PerformerListItemStyle.itemImage} /> */}
          <div className={PerformerListItemStyle.listingInfo}>
            <p className={`ml-3 mb-0 ${PerformerListItemStyle.performerListItemTitle}`}>{getName(item)}</p>
            {unionNo && (
              <span className={`text-white ${PerformerListItemStyle.afterTitle} ${getUnionClass(item)}`}>
                {getUnionValue(item)}
              </span>
            )}
          </div>
        </div>
      </PopoverContainer>
    </>
  );
};
export default ListItem;
