import DropDownSearch from 'components/controls/select/DropDownSearch';
import { Show, ShowDayJob, ShowList } from 'modules/Show/show.types';
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import Style from 'components/PerformerProfile/ShowSelection/ShowSelection.module.css';
import Button from 'components/controls/button/button';
import sendSvg from 'assets/svg/mail.svg';
import messageSvg from 'assets/svg/message.svg';
import { BookingDateList, BookingDate, User } from 'modules/user/types';
import ProfileCalender from 'components/ProfileCalender/ProfileCalender';
import showApi from 'store/services/show.services';
import { Options } from 'modules/general/general.type';
import Loader from 'components/controls/Loader/Loader';
import { ISendJobRequestParam } from 'modules/params/param.type';
import { PerformerJobs } from 'modules/jobs/types';
import { JOB_STATUS_BOOKED, JOB_STATUS_CANCELLED, TOAST_IDS, TOAST_TYPE } from 'store/castingPax.constants';
import moment from 'moment';

interface ShowSelectionProps {
  performer: User;
  jobRequestFromCDBegin?: Function;
  calendarDates?: BookingDateList;
  note?: string;
  selectedMainShow: Show;
  shows: ShowList;
  showToast: Function;
  submitBlackDay?: Function;
}
const ShowSelection: FunctionComponent<ShowSelectionProps> = (props) => {
  const { performer, calendarDates, jobRequestFromCDBegin, note, selectedMainShow, shows, showToast, submitBlackDay } =
    props;
  const [selectedShow, setSelectedShow] = useState<Options>();
  const [selectedJob, setSelectedJob] = useState<Options>();
  const [jobs, setJobs] = useState<ShowDayJob[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [dates, setDates] = useState<string[]>([]);
  const mountedRef = useRef(true);

  const [isText, setIsText] = useState<boolean>(false);

  const handleRequest = (isTextMessage: boolean) => {
    if (selectedJob && dates.length > 0) {
      setIsText(isTextMessage);
      if (!canApplyForJob(selectedJob.id)) {
        showToast({
          id: TOAST_IDS.APPLY_FOR_JOB_ERROR,
          title: 'Job has already requested',
          type: TOAST_TYPE.ERROR,
        });
        setIsText(false);
        return;
      }

      if (isPerformerBooked(dates)) {
        showToast({
          id: TOAST_IDS.APPLY_FOR_JOB_ERROR,
          title: 'Performer is booked on Job Dates',
          type: TOAST_TYPE.ERROR,
        });
        setIsText(false);
        return;
      }

      if (checkAvailableDates()) {
        const params: ISendJobRequestParam = {
          performerId: performer ? performer.id : '',
          jobId: selectedJob.id,
          isTextMessage,
          message: note || '',
          dates,
          workingDayIds: getWorkingDayIds(),
        };
        if (jobRequestFromCDBegin) jobRequestFromCDBegin(params);
        setIsText(false);
        document.body.click();
      }
      setIsText(false);
    }
  };

  useEffect(() => {
    showApi
      .fetchShowJobs({ ids: shows.map((s: Show) => s.id) })
      .then((res: ShowDayJob[]) => {
        if (mountedRef.current) {
          setJobs(res);
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    console.log('Selected Show', selectedMainShow);
  }, [shows]);

  const canApplyForJob = (jobId: string) => {
    if (performer.job) {
      return (
        performer.job.findIndex((j: PerformerJobs) => j.jobId === jobId && j.status !== JOB_STATUS_CANCELLED) === -1
      );
    }
    return true;
  };

  const isPerformerBooked = (bDates?: string[]) => {
    if (!bDates || bDates.length === 0) return true;
    let isBooked: boolean = false;
    if (performer && performer.job) {
      const jobList = performer.job;
      bDates.forEach((d: string) => {
        const index: number = jobList.findIndex(
          (j: PerformerJobs) => j.status === JOB_STATUS_BOOKED && j.dates.includes(d),
        );
        if (index !== -1) isBooked = true;
      });
    }
    return isBooked;
  };

  const checkAvailableDates = () => {
    if (calendarDates) {
      const notAvailableDates = calendarDates
        .filter((c: any) => c.type === 'notavailable')
        .map((a: any) => moment(a.date).format('Y-MM-D 00:00:00'));
      const isValidDate =
        selectedJob && selectedJob.dates ? selectedJob.dates.filter((c: any) => notAvailableDates.includes(c)) : [];
      console.log(isValidDate);
      if (isValidDate.length > 0) {
        handleSubmit();
        return false;
      }
    }
    return true;
  };

  const handleSubmit = () => {
    if (selectedJob && dates.length > 0 && submitBlackDay) {
      const params: ISendJobRequestParam = {
        performerId: performer ? performer.id : '',
        jobId: selectedJob.id,
        isTextMessage: isText,
        message: note || '',
        dates,
        workingDayIds: getWorkingDayIds(),
      };
      submitBlackDay(params, true);
      // if (jobRequestFromCDBegin) jobRequestFromCDBegin(params);
    }
    setIsText(false);
    document.body.click();
  };

  const getShows = () =>
    shows?.map((s: Show) => {
      const obj: Options = { id: s.id, label: s.title, value: s.id, type: 'show' };
      return obj;
    });

  useEffect(() => {
    if (selectedJob && selectedJob.dates) {
      setDates([...selectedJob.dates]);
    }
  }, [selectedJob]);

  const getWorkingDayIds = () => {
    const ids: string[] = [];

    if (selectedJob && jobs) {
      const jobIndex: number = jobs.findIndex((j: ShowDayJob) => j.id === selectedJob.id);
      if (jobIndex !== -1) {
        dates.forEach((x: string) => {
          const index: number = jobs[jobIndex].dates.indexOf(x);
          if (index !== -1) ids.push(jobs[jobIndex].workingDayId[index]);
        });
      }
    }
    return ids;
  };

  const isDisabledButton = () => dates.length === 0;

  const isShowDate = (date: string) => dates.join(',').includes(date);

  const handleDateSelection = (paramDate: BookingDate) => {
    const date: string = `${paramDate.date} 00:00:00`;
    if (selectedJob && selectedJob.dates) {
      if (selectedJob.dates.indexOf(date) !== -1) {
        const index: number = dates.indexOf(date);
        const selectedDates = dates;
        if (index !== -1) selectedDates.splice(index, 1);
        else selectedDates.push(date);
        selectedDates.sort();
        setDates([...selectedDates]);
      }
    }
  };

  const showJobs = useMemo(() => {
    if (selectedShow && jobs) {
      return jobs
        .filter((j: ShowDayJob) => j.showId === selectedShow.id)
        .map((s: ShowDayJob) => {
          const obj: Options = {
            id: s.id,
            label: s.title,
            value: s.id,
            type: 'show',
            dates: s.dates,
          };
          return obj;
        });
    }
    return [];
  }, [selectedShow]);

  const showButtons = () => {
    if (loading) {
      return <Loader isSmallView />;
    }
    return (
      <>
        <div className="performerSearchWrapper">
          <DropDownSearch
            data={getShows()}
            handleSelect={setSelectedShow}
            storeObject
            value={selectedShow ? selectedShow.label : 'Select'}
          />
          <DropDownSearch
            data={showJobs}
            value={selectedJob ? selectedJob.label : 'Select'}
            storeObject
            handleSelect={setSelectedJob}
          />
        </div>
        <div className={`d-flex justify ${Style.buttonSectionWrapper}`}>
          <Button
            clickHandler={() => {
              handleRequest(false);
            }}
            label="Email Request"
            icon={sendSvg}
            cssClass={Style.emailButton}
            isDisabled={isDisabledButton()}
          />
          <Button
            clickHandler={() => {
              handleRequest(true);
            }}
            label="Text Request"
            icon={messageSvg}
            cssClass={Style.textButton}
            isDisabled={isDisabledButton()}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`popup ${Style.wrapper}`}>
        <div className="d-flex justify-content-start flex-wrap">
          {calendarDates &&
            calendarDates.map((date: BookingDate) => (
              <ProfileCalender
                userId={performer ? performer.id : ''}
                cssClass={Style.showSelectionDates}
                key={date.id}
                date={date}
                isShowDate={isShowDate(date.date)}
                handleDateSelection={handleDateSelection}
              />
            ))}
        </div>
        {showButtons()}
      </div>
    </>
  );
};

export default ShowSelection;
