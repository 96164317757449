import React, { FunctionComponent, useState } from 'react';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import Button from 'components/controls/button/button';
import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';

interface IModalProp {
  handleSave: Function;
  handleClose: Function;
}
const SignInTimeModalBody: FunctionComponent<IModalProp> = (props) => {
  const { handleSave, handleClose } = props;
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [input, setinput] = useState<string>('');

  const handleSaveModal = () => {
    setErrorMessage('');
    let isValid: boolean = false;
    isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])?$/.test(input);
    if (isValid) {
      handleSave(input);
      handleClose();
    } else setErrorMessage('Format is InValid');
  };
  return (
    <>
      <span>Enter Sign In Time</span>
      <CustomInput type="text" placeholder="Enter Sign In time" handleChange={setinput} />
      <TextErrorMessage message={errorMessage} />
      <div style={{ display: 'flex' }}>
        <Button type="customSaveBtn" clickHandler={() => handleSaveModal()} label="Save" />
        &ensp;
        <Button type="customCancelBtn" clickHandler={() => handleClose()} label="Cancel" />
      </div>
    </>
  );
};

export default SignInTimeModalBody;
