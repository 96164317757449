import { User } from 'modules/user/types';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Header from 'components/topHeader/topHeader';
import { stripeLinkedAccountBegin } from 'store/actions/auth.actions';
import { getUser } from 'store/selector/auth.selector';
import signupCatStyle from 'components/singupCategory/signupCategory.module.css';
import { useHistory } from 'react-router';

interface StripeLinkedProps {
  stripeLinkedCall: Function;
  user: User;
}
const StripeLinked: FunctionComponent<StripeLinkedProps> = (props: StripeLinkedProps) => {
  const { stripeLinkedCall, user } = props;
  const [linked, setLinked] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (user.stripeLinked) history.push('/');
    stripeLinkedCall();
  }, []);

  useEffect(() => {
    if (user.stripeLinked) setLinked(true);
  }, [user]);

  return (
    <>
      {linked && (
        <>
          <Header />
          <div className={signupCatStyle.backgroundWrapper}>
            <div className={signupCatStyle.bgInnerContent}>
              <h1>Stripe Account</h1>
              <p className={signupCatStyle.alfterHeading}>
                {linked ? 'Stripe Account Linked Successfully' : 'Stripe Accunt Cannot Linked'}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = {
  stripeLinkedCall: stripeLinkedAccountBegin,
};

const mapStateToProps = (state: any) => {
  const user = getUser(state);
  return { user };
};

export default connect(mapStateToProps, mapDispatchToProps)(StripeLinked);
