import React, { FunctionComponent } from 'react';
import Style from 'components/Producer/Matrix/Budget/Popover/Popover.module.css';
import PopoverItem from 'components/Producer/Matrix/Budget//Popover/PopoverItem';
import pdfSvg from 'assets/svg/pdf.svg';
import csvSvg from 'assets/svg/csv.svg';
import jpegSvg from 'assets/svg/jpeg.svg';
import zipSvg from 'assets/svg/zip.svg';

interface IBudgetExport {
  data?: any;
  handleClick: Function;
  isloading: number;
  loaderType: string;
  type: string;
}
const BudgetExport: FunctionComponent<IBudgetExport> = (props: IBudgetExport) => {
  const { handleClick, isloading, loaderType, type, data } = props;

  const popover = [
    {
      type: 'pdf',
      icon: pdfSvg,
    },
    {
      type: type === 'invoice' ? 'csv' : 'jpeg',
      icon: type === 'invoice' ? csvSvg : jpegSvg,
    },
    {
      type: 'zip',
      icon: zipSvg,
    },
  ];
  return (
    <div className={Style.mainWrapper}>
      {popover.map((i: any, index: number) => (
        <PopoverItem
          item={i}
          data={data}
          loaderType={loaderType}
          isloading={isloading}
          key={index}
          type={type}
          handleClick={() => handleClick(i.type, type)}
        />
      ))}
    </div>
  );
};

export default BudgetExport;
