import React, { FunctionComponent } from 'react';
import Header from 'components/topHeader/topHeader';
import signupCatStyle from 'components/singupCategory/signupCategory.module.css';
import SingupCategoryOption from 'components/singupCategory/SignupCategoryOptions';
import { RoleCode } from 'modules/user/types';
import { useHistory } from 'react-router';
import options from 'components/singupCategory/options';
import { Link } from 'react-router-dom';
import Style from 'containers/bgPerformer/Submission/Submission.module.css';
import leftArrowSvg from 'assets/svg/left-arrow-white.svg';

const SingupCategory: FunctionComponent = () => {
  const history = useHistory();

  const clickHandler = (type: RoleCode) => {
    history.push(`signup/${type === 'DIRECTOR' ? 'CASTING' : type}`);
  };
  return (
    <>
      <div className={signupCatStyle.signupWrapper}>
        <Header />
        <div className={signupCatStyle.backgroundWrapper}>
          <Link to="/login" className={`pt-3 pl-3 text-white ${Style.backLinkText}`}>
            <img src={leftArrowSvg} alt="back arrow" />
            <span className="mb-0 text-white">BACK</span>
          </Link>
          <div className={signupCatStyle.bgInnerContent}>
            <h1>Sign up</h1>
            <p className={signupCatStyle.alfterHeading}>Simplify casting calls and finding work through CastingPAX.</p>
          </div>
          <div className={signupCatStyle.cardsWrapper}>
            <div className={signupCatStyle.noTextDesktop}>
              Simplify casting calls and finding work through CastingPAX.
            </div>
            <div className={signupCatStyle.cardsInnerWrapper}>
              {options.map((opt, index) => (
                <SingupCategoryOption
                  key={index}
                  label={opt.label}
                  image={opt.image}
                  clickHandler={() => clickHandler(opt.type)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SingupCategory;
