import React, { FunctionComponent } from 'react';
import Style from 'components/CreateShow/Detail/EpisodeItem/Item.module.css';
import DateItem from 'components/CreateShow/Detail/SeasonDateItem/Item';
import { Episode } from 'modules/Show/show.types';
import SliderWrapper from 'components/controls/Slider/SliderWrapper';

interface EpisodeItemProps {
  episode: Episode;
}
const EpisodeItem: FunctionComponent<EpisodeItemProps> = (props: EpisodeItemProps) => {
  const { episode } = props;
  return (
    <div className="col-sm-12">
      <div className={`${Style.episodeWrapper}`}>
        <p className={`mb-0 ${Style.seasonTitle}`}>{episode.title}</p>
        <div className="d-flex w-100 episodeItemWrapper">
          <SliderWrapper slideToShow={8}>
            {episode.episodeDays.map((day) => (
              <DateItem day={day} />
            ))}
          </SliderWrapper>
        </div>
      </div>
    </div>
  );
};

export default EpisodeItem;
