import React from 'react';
import Ratemanagement from '../../components/Payroll/Nav/RateManagement';

const index = () => (
  <div>
    <Ratemanagement />
  </div>
);

export default index;
