import React, { FunctionComponent, useEffect, useState } from 'react';
import { BGPerformer, UnionNo, User } from 'modules/user/types';
import Style from 'views/bgperformer/profile/ProfileEdit/ProfileEdit.module.css';
import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';
import ImageInput from 'components/controls/fileInput/ImageInput/ImageInput';
import GenderTypes from 'helpers/data/Gender';
import Input from 'components/controls/input/input';
import DropDownSearch from 'components/controls/select/DropDownSearch';
import { formatHeight, formatWeight } from 'helpers/utils';
import { getHeightOptions, getWeightOption } from 'helpers/data/SelectOptions';
import CountryOptions from 'helpers/data/countries';
import PhoneNoInput from 'components/controls/phone/PhoneNoInput';
import Button from 'components/controls/button/button';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import { IProfileFileParam } from 'modules/params/param.type';
import { LOADER_STATUS } from 'store/castingPax.constants';

interface ProfileEditProps {
  updateProfileBegin?: Function;
  user: User;
  uploadProfileImageBegin?: Function;
  authError?: string;
  isLoading?: boolean;
  closeModal: Function;
}
const ProfileEdit: FunctionComponent<ProfileEditProps> = (props: ProfileEditProps) => {
  const { updateProfileBegin, uploadProfileImageBegin, authError, user, isLoading, closeModal } = props;
  const userObj = user && user.id ? user : getItemFromLocalStorage('user');

  const [aboutUs] = useState(userObj.aboutUs || '');
  const [accountReason] = useState(userObj.accountReason || '');
  const [age, setAge] = useState(userObj.bgPerformer?.dateOfBirth || '');
  const [sex, setSex] = useState(userObj.bgPerformer?.gender || '');
  const [height, setHeight] = useState(userObj.bgPerformer?.height || '');
  const [weight, setWeight] = useState(userObj.bgPerformer?.weight || '');
  const [address, setAddress] = useState(userObj.address || '');
  const [city, setCity] = useState(userObj.city || '');
  const [country, setCountry] = useState(userObj.country || '');
  const [date] = useState(userObj.date || '');
  const [email] = useState(userObj.email || '');
  const [firstName, setFirstName] = useState(userObj.firstName || '');
  const [userId] = useState(userObj.id || '');
  const [lastName, setLastName] = useState(userObj.lastName || '');
  const [phone, setPhone] = useState(userObj.phoneNo || '');
  const [phoneError, setPhoneError] = useState<string>(userObj.phoneNo || '');
  const [profilePicUrl] = useState<string>(userObj.profilePicUrl || '');
  const [unionStatus, setUnionStatus] = useState<UnionNo>(
    userObj.unionStatus || { id: '-1', name: 'select', label: 'select', value: 'select' },
  );
  const [unionNo, setUnionNo] = useState<string>(userObj.unionNo || '');
  const [postCode, setPostCode] = useState<string>(userObj.postCode || '');
  const [imageParams, setImageParams] = useState<IProfileFileParam>();

  const [dataupdate, setDataUpdate] = useState<number>(LOADER_STATUS.NA);

  useEffect(() => {
    if (isLoading !== true && dataupdate >= 0) closeModal();
  }, [isLoading]);

  const handleSubmitProfile = () => {
    if (isLoading) return;
    const performer = user.bgPerformer
      ? user.bgPerformer
      : { chest: '', eyeColor: '', hair: '', inseam: '', jacket: '', neck: '', shoe: '', sleeve: '', waist: '' };
    const bgPerformer: BGPerformer = {
      chest: performer.chest,
      eyeColor: performer.eyeColor,
      hair: performer.hair,
      inseam: performer.inseam,
      jacket: performer.jacket,
      neck: performer.neck,
      shoe: performer.shoe,
      sleeve: performer.sleeve,
      waist: performer.waist,
      id: user.bgPerformer ? user.bgPerformer.id : '',
      gender: sex,
      dateOfBirth: age,
      height,
      weight,
    };

    const userPayload: User = {
      id: user.id,
      name: firstName,
      aboutUs,
      accountReason,
      bgPerformer,
      country,
      city,
      address,
      postCode,
      date,
      email,
      firstName,
      lastName,
      isCompleted: true,
      isProfilePublic: true,
      phoneNo: phone,
      role: userObj.role,
      unionNo: Number.isNaN(parseInt(unionNo, 10)) ? '' : unionNo,
      unionStatusId: unionStatus && unionStatus.id !== '-1' ? unionStatus.id : undefined,
    };

    if (updateProfileBegin) {
      updateProfileBegin(userPayload);
    }
    if (imageParams && uploadProfileImageBegin) {
      uploadProfileImageBegin(imageParams);
    }
    setDataUpdate(LOADER_STATUS.COMPLETED);
  };

  return (
    <>
      <div className={`${Style.formWrapper}`}>
        {authError && <TextErrorMessage message={authError} />}
        <div className="row">
          <div className="col-sm-5">
            <ImageInput handelFile={setImageParams} userId={userId} profilePic={profilePicUrl} updateCall />
          </div>
        </div>
        <div className="row mb-3 align-items-center profileCreation">
          <div className="col-sm-5">
            <span className="mb-0">First Name</span>
          </div>
          <div className="col-sm-7">
            <Input
              label=""
              value={firstName}
              handleChange={setFirstName}
              profileFieldMargin={Style.fieldBottomMargin}
            />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-md-5">
            <span className="mb-0">Last Name</span>
          </div>
          <div className="col-sm-7">
            <Input label="" value={lastName} handleChange={setLastName} profileFieldMargin={Style.fieldBottomMargin} />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Sex</span>
          </div>
          <div className="col-sm-7">
            <DropDownSearch
              label=""
              value={sex}
              data={GenderTypes}
              handleSelect={setSex}
              defaultValue="select"
              cssClass={Style.fieldBottomMargin}
            />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Height</span>
          </div>
          <div className="col-sm-7">
            <DropDownSearch
              label=""
              profileFieldMargin={Style.fieldBottomMargin}
              value={formatHeight(height || '')}
              cssClass={Style.createInputLabel}
              data={getHeightOptions()}
              defaultValue="select"
              handleSelect={setHeight}
              sort
            />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Weight</span>
          </div>
          <div className="col-sm-7">
            <DropDownSearch
              label=""
              value={formatWeight(weight)}
              profileFieldMargin={Style.fieldBottomMargin}
              cssClass={Style.createInputLabel}
              data={getWeightOption()}
              defaultValue="select"
              handleSelect={setWeight}
            />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Union Type</span>
          </div>
          <div className="col-sm-7">
            <DropDownSearch
              label=""
              value={unionStatus && Object.keys(unionStatus).length > 0 ? unionStatus.label : 'select'}
              profileFieldMargin={Style.fieldBottomMargin}
              cssClass={Style.createInputLabel}
              optionType="union"
              storeObject
              handleSelect={setUnionStatus}
            />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Union No</span>
          </div>
          <div className="col-sm-7">
            <CustomInput
              value={unionNo}
              cssClass={Style.unionNoStyle}
              handleChange={setUnionNo}
              placeholder=""
              type=""
            />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Date Of Birth</span>
          </div>
          <div className="col-sm">
            <Input label="" type="date" value={age} handleChange={setAge} />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Phone Number</span>
          </div>
          <div className="col-sm-7 editProfile">
            <PhoneNoInput handleChange={setPhone} value={phone} error={setPhoneError} />
            {phoneError && <TextErrorMessage message={phoneError} />}
          </div>
        </div>
        <hr />
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Address</span>
          </div>
          <div className="col-sm-7">
            <Input label="" value={address} handleChange={setAddress} profileFieldMargin={Style.fieldBottomMargin} />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Post Code</span>
          </div>
          <div className="col-sm-7">
            <CustomInput
              value={postCode}
              cssClass={Style.postCodeStyle}
              handleChange={setPostCode}
              placeholder=""
              type="number"
            />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">City</span>
          </div>
          <div className="col-sm-7">
            <Input label="" value={city} handleChange={setCity} profileFieldMargin={Style.fieldBottomMargin} />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Country</span>
          </div>
          <div className="col-sm-7">
            <DropDownSearch
              label=""
              value={country}
              profileFieldMargin={Style.fieldBottomMargin}
              cssClass={Style.createInputLabel}
              data={CountryOptions}
              defaultValue="select"
              handleSelect={setCountry}
            />
          </div>
        </div>
        <div className="text-center mt-4">
          <div className="row">
            <div className="col-sm-12 w-100">
              <Button
                label="Update"
                cssClass="fontFourteen font-weight-normal login-btn w-100"
                showLoading={isLoading}
                clickHandler={handleSubmitProfile}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileEdit;
