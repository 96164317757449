// eslint-disable-next-line no-underscore-dangle
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Style from 'components/ProfileMenu/ResidencyDocs/Residency.module.css';
import Dropzone from 'components/controls/dropzone/dropZoneResidency/Dropzone';
import UploadResidencyItem from 'components/ProfileMenu/ResidencyDocs/UploadResidencyItem';
import ResidencyDocsItem from 'components/ProfileMenu/ResidencyDocs/RecidencyDocsItem';
import Loader from 'components/controls/Loader/Loader';

interface ResidencyDocsProps {
  loading: boolean;
  docs: any;
  docsRequest: any;
  removeDocs: any;
  renameDocs: any;
}
const Residency: FunctionComponent<ResidencyDocsProps> = (props: ResidencyDocsProps) => {
  const { loading, docsRequest, removeDocs, docs, renameDocs } = props;
  const [myArray, setMyArray]: any[] = useState([]);
  const [showNameFile, setShowNameFile] = useState<any>([]);
  const dispatch = useDispatch();
  const [progress, setProgress] = useState<any>(0);
  const handleClose = () => {
    setShowNameFile([]);
  };
  const showFileName = (event: any) => {
    const totalFiles = event;
    console.log('thisss', totalFiles);
    setShowNameFile([...showNameFile, totalFiles]);
  };
  useEffect(() => {
    docsRequest();
  }, []);
  useEffect(() => {
    setMyArray(docs.files);
  }, [docs]);

  useEffect(() => {}, [myArray]);
  const handleDelete = (item: any) => {
    const arr = myArray?.filter((val: any) => val._id !== item._id && val.isDeleted === false);
    setMyArray(arr);
    dispatch(removeDocs({ id: item._id }));
  };
  const handleFileName = (item: any, e: any, type: string) => {
    console.log(item, e, type);
    const newArray = myArray.map((obj: any) => {
      const { oldPropName, ...rest } = obj;
      return { ...rest, [e]: oldPropName };
    });
    setMyArray([...myArray, newArray]);
    dispatch(renameDocs({ id: item._id, [type]: e }));
  };
  const handleFile = (uploadFile: any) => {
    console.log('handle file', uploadFile);
    // if (type === 'pdf') {
    //   fileUploadHandler(uploadFile);
    //   return;
    // }
    // if (type === 'rental') {
    //   setFile(uploadFile);
    //   return;
    // }
    // const files = uploadFile.filter((u: any) => u.status === 'pending');
    // if (type === 'album') {
    //   setFile(files);
    //   return;
    // }
    // fileUploadHandler(files);
  };
  return (
    <div className={Style.mainWrapper}>
      <p className={Style.uploadProofTitle}>Proof of residency docs</p>
      <div className={Style.uploadProofWrapper}>
        {loading ? (
          <Loader isSmallView />
        ) : (
          myArray?.map((item: any) => {
            console.log();
            return item.isDeleted === false ? (
              <ResidencyDocsItem
                item={item}
                handleDelete={() => handleDelete(item)}
                handleClick={(e: any, type: any) => handleFileName(item, e, type)}
              />
            ) : (
              <></>
            );
          })
        )}
      </div>
      <div className={Style.divider} />
      <p className={Style.uploadProofTitle}>Upload proof of residency</p>
      <div className={Style.uploadProofOfResidency}>
        {/* {selectedFile && ( */}
        <div className={Style.uploadProofWrapper}>
          {showNameFile.length > 0 &&
            showNameFile.map((item: any, index: any) => (
              <UploadResidencyItem progress={progress} item={item} key={index} handleClose={handleClose} />
            ))}
        </div>
        <div className={Style.dropzoneWrapper}>
          <div className={Style.dropzone}>
            <Dropzone
              closeDialog={handleClose}
              loader={false}
              fileUploadHandler={handleFile}
              type=""
              showFileName={(e: any) => showFileName(e)}
              setProgress={setProgress}
              docsRequest={docsRequest}
              removeFileList={setShowNameFile}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Residency;
