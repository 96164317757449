import { connect } from 'react-redux';
import DropDown from 'components/bgPerformer/skills/DropDown/DropDown';
import { SkillCategory } from 'modules/general/general.type';
import { getDefaultSkills } from 'store/selector/general.selector';

const mapStateToProps = (state: SkillCategory) => {
  const skills = getDefaultSkills(state);
  return { skills };
};

export default connect(mapStateToProps, null)(DropDown);
