import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';

interface PopoverStickOnHoverProps {
  children: any;
  delay?: number;
  onMouseEnter?: Function;
  component: Function;
  cssClass?: string;
}

const PopoverHover: FunctionComponent<PopoverStickOnHoverProps> = (props: PopoverStickOnHoverProps) => {
  const { delay, onMouseEnter, children, component, cssClass } = props;
  const [showPopover, setShowPopover] = useState(false);
  const childNode = useRef(null);
  let setTimeoutConst: any = null;
  useEffect(() => () => {
    if (setTimeoutConst) {
      clearTimeout(setTimeoutConst);
    }
  });

  const handleMouseEnter = () => {
    setTimeoutConst = setTimeout(() => {
      setShowPopover(true);
      if (onMouseEnter) onMouseEnter();
    }, delay);
  };

  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst);
    setShowPopover(false);
  };

  const handleClick = () => {
    setShowPopover(true);
  };

  const displayChild = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      onClick: handleClick,
      ref: (node: any) => {
        childNode.current = node;
        const { ref } = child;
        if (typeof ref === 'function') {
          ref(node);
        }
      },
    }),
  )[0];

  return (
    <>
      {displayChild}
      <Overlay show={showPopover} placement="bottom" target={childNode}>
        <Popover
          className={cssClass}
          onMouseEnter={() => {
            setShowPopover(true);
          }}
          onMouseLeave={handleMouseLeave}
          id="popover"
        >
          {component()}
        </Popover>
      </Overlay>
    </>
  );
};

PopoverHover.defaultProps = {
  delay: 0,
  onMouseEnter: () => {},
};

export default PopoverHover;
