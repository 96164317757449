import { call, put, all, takeEvery } from 'redux-saga/effects';
import { LOGIN_PARAMS, SignupProfile, User } from 'modules/user/types';
import { authActions, generalActions, performerActions } from 'store/actions';
import api from 'store/services/auth.services';
import ACTIONS, { ROLE_PERFORMER, TOAST_IDS, TOAST_TYPE } from 'store/castingPax.constants';
import ApiResponse from 'modules/response/response.types';
import {
  IIDParams,
  IProfileFileParam,
  ISaveCardParams,
  INotificationSetting,
  IBusinessInfo,
} from 'modules/params/param.type';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';

function* login(state: { type: string; payload: LOGIN_PARAMS }) {
  try {
    yield put(authActions.loginAttempt.pending);
    const response: ApiResponse = yield call(api.loginRequest, state.payload);
    yield localStorage.removeItem('profileStep');
    yield localStorage.setItem('user', JSON.stringify(response.data));
    if (response.data.token) yield localStorage.setItem('accessToken', response.data.token);
    yield put(authActions.loginAttempt.success(response.data));
  } catch (error) {
    yield put(authActions.loginAttempt.error(error.message));
  }
}

function* logout() {
  yield call([localStorage, 'removeItem'], 'user');
  yield call([localStorage, 'removeItem'], 'accessToken');
  yield call([localStorage, 'removeItem'], 'redirect');
  yield call([localStorage, 'removeItem'], 'copyItem');
  yield call([localStorage, 'removeItem'], 'cppv');
  yield call([localStorage, 'removeItem'], 'searchJobFilter');
  yield call([sessionStorage, 'removeItem'], 'currentTab');
  yield put(authActions.logoutSucceed());
}

function* signup(state: { type: string; payload: User }) {
  try {
    yield put(authActions.registerUser.pending);
    const response: ApiResponse = yield call(api.signupRequest, state.payload);
    if (response.data.token) yield localStorage.setItem('accessToken', response.data.token);
    if (response.data.isCompleted && response.data.role !== ROLE_PERFORMER) {
      yield localStorage.setItem('user', JSON.stringify(response.data));
      yield put(authActions.signUpComplete(response.data.role));
      yield put(authActions.registerUser.success(response.data, 1));
    } else {
      yield localStorage.setItem('profileStep', '2');
      yield localStorage.setItem('puser', JSON.stringify(response.data));
      yield put(authActions.registerUser.success(response.data, 2));
    }
  } catch (error) {
    yield put(authActions.registerUser.error(error.message));
  }
}

function* signupProfile(state: { type: string; payload: SignupProfile }) {
  try {
    yield put(authActions.registerUser.pending);
    const response: ApiResponse = yield call(api.profileUpdate, state.payload.user);
    yield localStorage.removeItem('profileStep');
    yield localStorage.removeItem('puser');
    yield put(authActions.registerUser.success(response.data, 1));
    if (response.data.role === ROLE_PERFORMER) {
      yield localStorage.setItem('user', JSON.stringify(response.data));
      yield put(authActions.signUpComplete(response.data.role));
    } else {
      yield localStorage.removeItem('accessToken');
      yield localStorage.removeItem('profileStep');
      yield put(authActions.signUpComplete(response.data.role));
    }
  } catch (error) {
    yield put(authActions.registerUser.error(error.message));
  }
}

function* updateProfile(state: { type: string; payload: User }) {
  try {
    yield put(authActions.updateProfile.pending);
    const response: ApiResponse = yield call(api.profileUpdate, state.payload);
    yield localStorage.setItem('user', JSON.stringify(response.data));
    yield put(performerActions.getPerformerProfile.success(response.data));
    yield put(authActions.updateProfile.success(response.data));
  } catch (error) {
    yield put(authActions.registerUser.error(error.message));
  }
}

function* fileUpload(state: { type: string; payload: IProfileFileParam }) {
  try {
    const formData: any = state.payload.params;
    const resume1 = formData.get('resumeUrl1');
    const resume2 = formData.get('resumeUrl2');
    if (resume1 || resume2) yield put(authActions.profileFileUpload.pending('resume'));
    else yield put(authActions.profileFileUpload.pending());
    const response: ApiResponse = yield call(api.profileFiles, state.payload);
    if (state.payload.updateCall) {
      yield localStorage.setItem('user', JSON.stringify(response.data));
      yield put(performerActions.updateUserImages(response.data, state.payload));
    }
    yield put(authActions.profileFileUpload.success(response.data, state.payload));
  } catch (error) {
    yield put(authActions.profileFileUpload.error(error.message));
  }
}

function* updateSubscriptionPlan(state: { type: string; payload: IIDParams }): any {
  try {
    yield put(authActions.updateSubscription.pending);
    const response: any = yield call(api.updateSubscriptionPlan, state.payload);
    const user: User = yield getItemFromLocalStorage('user');
    yield (user.subscription = response.subscriptionPlan);
    yield (user.subscriptionExpirAt = response.subscriptionExpirAt);
    yield localStorage.setItem('user', JSON.stringify(user));
    yield put(authActions.updateSubscription.success(response));
  } catch (error) {
    yield put(authActions.updateSubscription.error(error.message));
  }
}

function* fetchCardDetails(): any {
  try {
    const response: any = yield call(api.fetchCardDetails);
    // yield put(authActions.getCardDetails.success(response));
  } catch (error) {
    yield put(authActions.getCardDetails.error(error.message));
  }
}

function* saveCardData(state: { type: string; payload: ISaveCardParams }) {
  try {
    yield put(authActions.saveCardData.pending);
    const response: boolean = yield call(api.saveCardInfo, state.payload);
    if (response) {
      const user: User = yield getItemFromLocalStorage('user');
      yield (user.cardId = state.payload.id);
      yield localStorage.setItem('user', JSON.stringify(user));
      yield put(authActions.saveCardData.success(state.payload));
    }
  } catch (error) {
    yield put(authActions.saveCardData.error(error.message));
  }
}

function* setNotificationSetting(state: { type: String; payload: INotificationSetting }): any {
  try {
    yield put(authActions.setNotificationPrivacyStatus.pending);
    const response: any = yield call(api.setNotificationPrivacy, state.payload);
    if (response) {
      const user: User = yield getItemFromLocalStorage('user');
      yield (user.isEmailNotificationOn = response.isEmailNotificationOn);
      yield (user.isNotificationOn = response.isNotificationOn);
      yield (user.isProfilePublic = response.isProfilePublic);
      yield (user.UserWhoCanSeeProfiles = response.UserWhoCanSeeProfiles);
      yield localStorage.setItem('user', JSON.stringify(user));
      yield put(authActions.setNotificationPrivacyStatus.success(user));
    }
  } catch (error) {
    yield put(authActions.setNotificationPrivacyStatus.error(error.message));
  }
}
function* deleteAccount(state: { type: String; payload: any }): any {
  try {
    yield put(authActions.deleteAccount.pending);
    const response: any = yield call(api.deleteAccount, state.payload);
    if (response) yield logout();
  } catch (error) {
    yield put(authActions.deleteAccount.error(error.essage));
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.DELETE_ACCOUNT_ERROR,
        title: 'Something get wrong try again',
        type: TOAST_TYPE.ERROR,
      }),
    );
  }
}
function* getSubscriptionHistory(state: { type: string; payload: { page: number; limit: number } }) {
  try {
    yield put(authActions.subscriptionHistory.pending);
    const response: ApiResponse = yield call(api.getSubscriptionHistory, state.payload);
    if (response) {
      yield put(authActions.subscriptionHistory.success(response));
    }
  } catch (err: any) {
    yield put(authActions.subscriptionHistory.error(err.message));
  }
}
function* stripeLinked() {
  try {
    yield put(authActions.stripeLinkedAccount.pending);
    const response: ApiResponse = yield call(api.stripeLinkedAccount);
    if (response.data) {
      const user: User = yield getItemFromLocalStorage('user');
      yield (user.stripeLinked = response.data);
      yield localStorage.setItem('user', JSON.stringify(user));
      yield put(authActions.stripeLinkedAccount.success(user));
    }
  } catch (err: any) {
    yield put(authActions.stripeLinkedAccount.error(err.message));
  }
}

function* updateBusinessInfo(state: { type: string; payload: IBusinessInfo }): any {
  yield console.log('here', state);
  try {
    yield put(authActions.updateBusinessInfo.pending);
    const response: any = yield call(api.updateBusinessInfo, state.payload);
    if (response.businessAddress && response.businessName) {
      const user: User = yield getItemFromLocalStorage('user');
      yield (user.PSTNumber = response.PSTNumber);
      yield (user.GSTNumber = response.GSTNumber);
      yield (user.businessName = response.businessName);
      yield (user.businessAddress = response.businessAddress);
      yield localStorage.setItem('user', JSON.stringify(user));
      yield put(authActions.updateBusinessInfo.success(user));
    }
  } catch (err: any) {
    yield put(authActions.updateBusinessInfo.error(err.message));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.LOGIN_CALL, login),
    takeEvery(ACTIONS.REGISTER_NEW_USER, signup),
    takeEvery(ACTIONS.AUTH_LOGOUT_BEGIN, logout),
    takeEvery(ACTIONS.SIGNUP_STEP_TWO, signupProfile),
    takeEvery(ACTIONS.PROFILE_FILE_UPLOAD_BEGIN, fileUpload),
    takeEvery(ACTIONS.PROFILE_UPDATE_BEGIN, updateProfile),
    takeEvery(ACTIONS.UPDATE_SUBSCRIPTION_PLAN_BEGIN, updateSubscriptionPlan),
    takeEvery(ACTIONS.GET_CARD_DETAILS_BEGIN, fetchCardDetails),
    takeEvery(ACTIONS.SAVE_CARD_DATA_BEGIN, saveCardData),
    takeEvery(ACTIONS.SET_NOTIFICATION_FOR_SETTING, setNotificationSetting),
    takeEvery(ACTIONS.DELETE_ACCOUNT_BEGIN, deleteAccount),
    takeEvery(ACTIONS.SUBSCRIPTION_HISTORY_BEGIN, getSubscriptionHistory),
    takeEvery(ACTIONS.STRIPE_LINKED_ACCOUNT_BEGIN, stripeLinked),
    takeEvery(ACTIONS.UPDATE_BUSINESS_INFO_BEGIN, updateBusinessInfo),
  ]);
}
