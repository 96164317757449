import React from 'react';
import styled from 'styled-components';

interface Props {}

const ControlButtonsWrapper: React.FC<Props> = (props) => {
  const { children } = props;
  return <ControlsWrapper>{children}</ControlsWrapper>;
};

export default ControlButtonsWrapper;

const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // margin-top: 11px;
  // padding-right: 10px;
  width: 100%;
  gap: 5px;
  flex-wrap: wrap;
`;
