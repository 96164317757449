import React, { FunctionComponent } from 'react';
import PerformerProfileStyle from 'components/PerformerProfile/PerformerProfile.module.css';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

interface PhoneInputFormatProps {
  label: string;
  value?: string;
  cssClass?: string;
  title?: string;
  icon?: string;
  labelClass?: string;
}
const PhoneInputFormat: FunctionComponent<PhoneInputFormatProps> = (props: PhoneInputFormatProps) => {
  const { label, value, cssClass, title, icon, labelClass } = props;

  return (
    <div>
      <p className={`${cssClass} ${labelClass} ${PerformerProfileStyle.personalInfoTitle}`} title={title}>
        {label}
      </p>
      <p className={`${cssClass} ${PerformerProfileStyle.personalInfo}`} title={title}>
        {icon && <img src={icon} alt="icon" className={`mr-2 ${PerformerProfileStyle.iconImage}`} />}
        {formatPhoneNumberIntl(value || '')}
      </p>
    </div>
  );
};

PhoneInputFormat.defaultProps = {
  value: '',
};

export default PhoneInputFormat;
