import React, { useState } from 'react';
import styled from 'styled-components';
import logo from 'assets/svg/warnStatus.svg';
import Modal from 'components/Payroll/VoucherManagement/voucher/utils/modal';
import StyledStatus from '../../../styles/show/components/Status.styled';

interface Props {}

const Status: React.FC<Props> = () => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const toggleDropdown = () => setShowDropdown((currentVal) => !currentVal);
  return (
    <StatusContainer>
      <StyledStatus onClick={toggleDropdown}>
        <p>Status</p>
        <div />
      </StyledStatus>
      <StatusDropdownWrapper open={showDropdown}>
        <StatusItem>
          <ImageItem src={logo} alt="" />
          <TextBox>Not Available</TextBox>
        </StatusItem>
        <StatusItem>
          <ImageItem src={logo} alt="" />
          <TextBox>Lorem</TextBox>
        </StatusItem>
        <StatusItem>
          <ImageItem src={logo} alt="" />
          <TextBox>Lorem</TextBox>
        </StatusItem>
        <StatusItem>
          <ImageItem src={logo} alt="" />
          <TextBox>Lorem</TextBox>
        </StatusItem>
        {showDropdown && <Modal closeModal={toggleDropdown} />}
      </StatusDropdownWrapper>
    </StatusContainer>
  );
};

export default Status;

const StatusContainer = styled.div`
  position: relative;
`;

const StatusDropdownWrapper = styled.div<{ open: boolean }>`
  padding-top: 6px;
  height: fit-content;
  position: relative;
  box-sizing: border-box;
  position: absolute;
  width: 175px;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  left: calc(50% - 167px / 2 + 0px);
  top: 45px;
  border: 2px solid ${({ theme }) => theme.colors.lightBlue};
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border: 2px solid ${({ theme }) => theme.colors.lightBlue};
  z-index: 999;

  &::after {
    content: '';
    position: absolute;
    height: 14px;
    width: 14px;
    right: 50px;
    top: -8px;
    background: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.lightBlue};
    border-right: transparent;
    border-bottom: transparent;
    transform: rotate(45deg);
  }
`;

const ImageItem = styled.img``;

const StatusItem = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 23px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4f0fc;
`;

const TextBox = styled.p`
  padding-left: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #252427;
  margin-bottom: 0px;
`;
