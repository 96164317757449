import React, { FunctionComponent } from 'react';
import Style from 'components/castingDirector/PopOvers/BgSizeCard/BgSizeCard.module.css';
import shareSvg from 'assets/svg/share-bg-white.svg';
import emailSvg from 'assets/svg/breakdown-email.svg';
import viewSvg from 'assets/svg/breakdown-view.svg';
import Loader from 'components/controls/Loader/Loader';

interface BreakDownPopoverProps {
  handleClick: Function;
  type: string;
  breakdownLoading: boolean;
}

const BreakdownPopover: FunctionComponent<BreakDownPopoverProps> = (props: BreakDownPopoverProps) => {
  const { handleClick, breakdownLoading, type } = props;

  return (
    <div className={Style.mainWrapper}>
      <div
        className={Style.TextSection}
        role="button"
        tabIndex={0}
        onClick={() => handleClick('share')}
        onKeyPress={() => handleClick('share')}
      >
        {type === 'share' && breakdownLoading && <Loader isSmallView />}
        <img src={shareSvg} alt="" className={Style.searchIcon} />
        <p className={Style.text}>Share Breakdown</p>
      </div>
      <div
        className={Style.TextSection}
        role="button"
        tabIndex={0}
        onClick={() => handleClick('self')}
        onKeyPress={() => handleClick('self')}
      >
        {type === 'Self' && breakdownLoading && <Loader isSmallView />}
        <img src={emailSvg} alt="" className={Style.searchIcon} />
        <p className={Style.text}>Email to Self</p>
      </div>
      <div
        className={Style.TextSection}
        role="button"
        tabIndex={0}
        onClick={() => handleClick('view')}
        onKeyPress={() => handleClick('view')}
      >
        {type === 'view' && breakdownLoading ? (
          <Loader isSmallView cssClass={Style.searchIcon} />
        ) : (
          <img src={viewSvg} alt="" className={Style.searchIcon} />
        )}
        <p className={Style.text}>View Breakdown</p>
      </div>
    </div>
  );
};

export default BreakdownPopover;
