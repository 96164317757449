import React, { FunctionComponent, useEffect } from 'react';
import Style from 'components/ProfileMenu/ResidencyDocs/Residency.module.css';
import PDF from 'assets/svg/pdf-gray.svg';
import Close from 'assets/svg/close.svg';
import DropMenu from '../../Payroll/VoucherManagement/voucher/dropMenu.component';

interface UploadResidencyProps {
  item?: any;
  handleClose?: any;
  progress: any;
}

const UploadResidencyItem: FunctionComponent<UploadResidencyProps> = (props: UploadResidencyProps) => {
  const { item, handleClose, progress } = props;

  const formatBytes = (bytes: any, decimals = 2) => {
    if (!+bytes) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  };
  useEffect(() => {
    console.log('setProgress', progress);
  }, [progress]);
  const handleChange = () => {
    console.log('handleChange', handleChange);
  };
  return (
    <div className={Style.uploadProofItemWrapper}>
      <div className={`d-flex justify-content-between align-items-center ${Style.fileWrapper}`}>
        <img src={PDF} alt="" />
        <p className="mb-0 ml-4">{item[0].name}</p>
      </div>
      <div className={`d-flex justify-content-between align-items-center ${Style.progressBar}`}>
        <div className={`progress ${Style.progressWidth}`}>
          <div
            className={`progress-bar ${Style.progressLine}`}
            role="progressbar"
            style={{ width: `${progress}%` }}
            // aria-valuenow="25"
            // aria-valuemin="0"
            // aria-valuemin="100"
          />
        </div>
        <p className="mb-0 ml-4">{formatBytes(item[0].size)}</p>
      </div>
      <select
        className={`form-select ${Style.selectBox}`}
        aria-label="Default select example"
        onChange={handleChange}
        disabled
      >
        <option selected>Select</option>
        <option value="Work Permit" className="text-capitalize">
          Work Permit
        </option>
        <option value="Notice of Assessment" className="text-capitalize">
          Notice of Assessment
        </option>
        <option value="3" className="text-capitalize">
          Bill from last year
        </option>
        <option value="Drivers License" className="text-capitalize">
          Drivers License
        </option>
        <option value="Health Card/Service Card" className="text-capitalize">
          Health Card/Service Card
        </option>
        <option value="Union Card" className="text-capitalize">
          Union Card
        </option>
        <option value="Other" className="text-capitalize">
          Other
        </option>
      </select>
      <div className={Style.dropdown}>
        <DropMenu />
      </div>
      <div className={Style.cancel} onClick={handleClose} onKeyPress={handleClose} role="button" tabIndex={0}>
        <img src={Close} alt="" />
      </div>
    </div>
  );
};
export default UploadResidencyItem;
