import React, { FunctionComponent } from 'react';
import Image from 'assets/images/image1.png';
import upArrowSvg from 'assets/svg/up-arrow.svg';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import exportBorderedSvg from 'assets/svg/export_bordered.svg';
import Style from 'views/producer/header/Header.module.css';
import { Show } from 'modules/Show/show.types';
import { matrixRedirect } from 'helpers/utils';
import { ScreenSize } from 'modules/general/general.type';
import { useSelector } from 'react-redux';
import { getScreenWidth } from 'store/selector/general.selector';
import { SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET } from 'store/castingPax.constants';

interface HeaderProps {
  ttlUsers: number;
  show: Show;
  setIsExpanded: Function;
  isExpanded: boolean;
}

const Header: FunctionComponent<HeaderProps> = (props: HeaderProps) => {
  const { ttlUsers, show, setIsExpanded, isExpanded } = props;
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);

  const handleNewTabClick = (e: any) => {
    e.stopPropagation();
    matrixRedirect.save({
      showId: show.id,
    });
  };

  if (currentScreenSize === SCREEN_SIZE_MOBILE || currentScreenSize === SCREEN_SIZE_TABLET) {
    return (
      <div
        className="matrixWaterFallWrapper position-relative helloTester"
        onKeyUp={() => setIsExpanded(!isExpanded)}
        onClick={() => setIsExpanded(!isExpanded)}
        role="button"
        tabIndex={0}
      >
        <div className={Style.imageWrapper}>
          {show.imageFile && <img src={show && show.showImage ? show.showImage : Image} alt="imageWrapper" />}
        </div>
        <div className={Style.producerShowTitle}>
          <p className={`mb-0 ${Style.showTitle}`}>{show.title}</p>
          <div
            className={`d-flex justify-content-start align-items-center flex-wrap ${Style.performerCoordinatorWrapper}`}
          >
            {/* <div className={Style.greyWrapper}> */}
            {/*  <p className="mb-0">{formateMonthDate(matrixItem.date)}</p> */}
            {/*  <span className="mb-0">{matrixItem.dayTitle}</span> */}
            {/* </div> */}
            <div className={Style.greenWrapper}>
              <p className="mb-0">Binders</p>
              <span className="mb-0">4</span>
            </div>
            <div className={Style.blueWrapper}>
              <p className="mb-0">Users</p>
              <span className="mb-0">{ttlUsers}</span>
            </div>
          </div>
          <div className={`d-flex justify-content-between align-items-center ${Style.actionsWrapper}`}>
            <div>
              <a href="/casting/home" target="_blank" onClick={handleNewTabClick}>
                <img src={exportBorderedSvg} alt="" className={Style.exportIcon} />
              </a>
            </div>
            <div>
              <img src={isExpanded ? upArrowSvg : downArrowSvg} alt="" className={Style.arrowIcon} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="matrixWaterFallWrapper hello"
      onKeyUp={() => setIsExpanded(!isExpanded)}
      onClick={() => setIsExpanded(!isExpanded)}
      role="button"
      tabIndex={0}
    >
      <div className="matrixWaterFallLeft d-flex align-items-center">
        <div className={Style.imageWrapper}>
          <img src={show && show.showImage ? show.showImage : Image} alt="imageWrapper" />
        </div>
        <div className={Style.producerShowTitle}>
          <p className={`mb-0 ${Style.showTitle}`}>{show.title}</p>
        </div>
      </div>
      <div className="matrixWaterFallRight">
        <div className="d-flex justify-content-between align-items-center">
          {/*  <div className={Style.greyWrapper}>
            <p className="mb-0">{formateMonthDate(matrixItem.date)}</p>
              <span className="mb-0">{matrixItem.dayTitle}</span>
          </div> */}
          <div className={Style.greenWrapper}>
            <p className="mb-0">Binders</p>
            <span className="mb-0">4</span>
          </div>
          <div className={Style.blueWrapper}>
            <p className="mb-0">Users</p>
            <span className="mb-0">{ttlUsers}</span>
          </div>
        </div>
        <div className={`d-flex justify-content-between align-items-center ${Style.actionsWrapper}`}>
          <div>
            <a href="/casting/home" target="_blank" onClick={handleNewTabClick}>
              <img src={exportBorderedSvg} alt="" className={Style.exportIcon} />
            </a>
          </div>
          <div>
            <img src={isExpanded ? upArrowSvg : downArrowSvg} alt="" className={Style.arrowIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
