import React from 'react';
import styled from 'styled-components';
import { Show } from 'modules/Show/show.types';
import DownArrow from '../../common/DownArrow';
import StyledTopInfoTab from '../../../styles/show/show-info/TopInfoTab.styled';

interface Props {
  arrowColor?: string;
  handleShowGrid: () => void;
  showGrid: boolean;
  show: Show;
  title: string;
  date?: string;
}

const TopInfoTab: React.FC<Props> = ({ arrowColor, handleShowGrid, showGrid, show, title, date }) => (
  <TopInfoContainer>
    <StyledTopInfoTab onClick={handleShowGrid}>
      <Section>
        <HeaderText>{show.title}</HeaderText>
        <Wrapper>
          <HeaderTextSmall>{date}</HeaderTextSmall>
          <Paragragh>{title}</Paragragh>
        </Wrapper>
      </Section>
      <ArrowContainer>
        <DownArrow color={arrowColor} toggleIcon={showGrid} />
      </ArrowContainer>
    </StyledTopInfoTab>
  </TopInfoContainer>
);

const TopInfoContainer = styled.div`
  display: flex;
  width: 100%;
`;

const ArrowContainer = styled.div`
  align-self: flex-end;
  padding: 0 10px;
  cursor: pointer;
`;

const Section = styled.section`
  display: flex;
`;

const HeaderText = styled.h3``;

const HeaderTextSmall = styled.h2`
  margin: 0;
  padding-bottom: 4px;
`;

const Wrapper = styled.div`
  margin-left: 30px;
  padding-top: 5px;
  background: #ffffff;
  border-radius: 5px;
`;

const Paragragh = styled.p`
  margin: 0;
`;

export default TopInfoTab;
