import React from 'react';
import Payroll from 'components/Payroll/Nav/Payroll';

const index = () => (
  <div>
    <Payroll />
  </div>
);

export default index;
