import { connect } from 'react-redux';
import Voucher from 'components/ProfileMenu/VoucherInfo/VoucherInfo';
import { saveVoucherInfoRequest, getVoucherInfoRequest } from 'store/actions/performer.actions';
import { getVoucherLoading, getVoucher } from 'store/selector/performer.selector';

const mapStateToProps = (state: any) => {
  const loading = getVoucherLoading(state);
  const voucherList = getVoucher(state);
  return {
    loading,
    voucherList,
  };
};

const mapDispatchToProps = {
  saveVoucherInfo: saveVoucherInfoRequest,
  getVoucherInfo: getVoucherInfoRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Voucher);
