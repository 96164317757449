import React, { FunctionComponent, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import exportSvg from 'assets/svg/export.svg';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import upArrowSvg from 'assets/svg/up-arrow.svg';
import mailSvg from 'assets/svg/mail-green.svg';
import locationGreenSvg from 'assets/svg/location-green.svg';
import mobileSvg from 'assets/svg/mobile.svg';
import roleSettingSvg from 'assets/svg/role-setting.svg';
import deleteSvg from 'assets/svg/delete-white.svg';
import resetPasswordSvg from 'assets/svg/reset-password.svg';
import Style from 'components/Producer/Matrix/UserManagementItem/UserListing.module.css';
import ContactInfoItem from 'components/Producer/Matrix/UserManagementItem/ContactInfoItem/ContactInfoItem';
import Button from 'components/controls/button/button';
import RolesPopover from 'components/Producer/Matrix/UserManagementItem/Popover/RolesPopover';
import { getName, getRoleFromTitle } from 'helpers/utils';
import { Options, ScreenSize } from 'modules/general/general.type';
import { roleOptions } from 'helpers/data/SelectOptions';
import { SelectOptionType } from 'modules/PerformerList/list.types';
import { ShowTeamMember } from 'modules/Show/show.types';
import ShowListing from 'components/Producer/Matrix/UserManagementItem/ShowAccess/ShowListing';
import CPModal from 'components/Modal/CPModal';
import { changeUserRoleBegin, removeShowAccess } from 'store/actions/show.actions';
import showApi from 'store/services/show.services';
import api from 'store/services/auth.services';
import { toast } from 'react-toastify';
import { SCREEN_SIZE_MOBILE } from 'store/castingPax.constants';
import { getScreenWidth } from 'store/selector/general.selector';

interface UserItemProps {
  user: ShowTeamMember;
  removeAccess: Function;
  changeRole: Function;
}

const UserItem: FunctionComponent<UserItemProps> = (props: UserItemProps) => {
  const { user, removeAccess, changeRole } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const roles: SelectOptionType[] = roleOptions();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [cancelItem, setCancelItem] = useState<ShowTeamMember>();

  const expandToggle = () => setIsExpanded(!isExpanded);
  const handleReset = () => {
    api
      .sendForgotEmail({ email: user.userId.email })
      .then(() => toast('Reset link has been sent.'))
      .catch(() => toast('Fail to send the link.'));
  };

  const handleRoleChange = (option: Options) => {
    changeRole({ id: user.id, roleId: option.value, showId: user.showId });
  };

  const handleRemoveUser = () => handleCancelAccess(user);
  const handleCancelAccess = (item: ShowTeamMember) => {
    setShowModal(true);
    setCancelItem(item);
  };

  const handleModalClose = (isConfirm: boolean) => {
    if (isConfirm && cancelItem) {
      showApi.removeShowAccess({ id: cancelItem.id }).catch((err) => err);
      if (cancelItem.callBack) cancelItem.callBack(cancelItem.id);
      removeAccess(cancelItem);
    } else {
      setCancelItem(undefined);
    }
    setShowModal(false);
  };
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);

  const getRolePopover = () => <RolesPopover selected={user.showRole} handleClick={handleRoleChange} data={roles} />;
  const mobileScreenChanges = () => {
    if (currentScreenSize === SCREEN_SIZE_MOBILE) {
      return (
        <div className={`${isExpanded ? Style.mainExpandedWrapper : Style.mainWrapper}`}>
          <div
            className="d-flex align-items-center"
            onClick={expandToggle}
            onKeyUp={expandToggle}
            tabIndex={0}
            role="button"
          >
            <div className={`d-flex align-items-center ${Style.profileWrapper}`}>
              <img src={user.userId.profilePicUrl} alt="" className={Style.profileImage} />
              <div className={Style.mobileDetailsWrapper}>
                <p className={`mb-0 ${Style.profileName}`}>{getName(user.userId)}</p>
                <p className={`mb-0 ${Style.role}`}>{getRoleFromTitle(user.userId.role)}</p>
              </div>
            </div>
            <div className={`d-flex align-items-center position-relative ${Style.iconsWrapper}`}>
              <img src={exportSvg} alt="" className="mr-3" />
              <img src={isExpanded ? downArrowSvg : upArrowSvg} alt="" />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={`${isExpanded ? Style.mainExpandedWrapper : Style.mainWrapper}`}>
        <div
          className="d-flex align-items-center"
          onClick={expandToggle}
          onKeyUp={expandToggle}
          tabIndex={0}
          role="button"
        >
          <div className={`d-flex align-items-center ${Style.profileWrapper}`}>
            <img src={user.userId.profilePicUrl} alt="" className={Style.profileImage} />
            <p className={`mb-0 ${Style.profileName}`}>{getName(user.userId)}</p>
          </div>
          <div className={Style.roleWrapper}>
            <p className={`mb-0 ${Style.role}`}>{getRoleFromTitle(user.userId.role)}</p>
          </div>
          <div className={`d-flex align-items-center ${Style.iconsWrapper}`}>
            <div>
              <img src={exportSvg} alt="" className="mr-3" />
            </div>
            <div>
              <img src={isExpanded ? downArrowSvg : upArrowSvg} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {mobileScreenChanges()}
      {isExpanded && (
        <div className={`d-flex ${Style.expandWrapper}`}>
          <div className={`${Style.contactInfoWrapper}`}>
            <div className={`d-flex justify-content-between align-items-center ${Style.contactInfoImage}`}>
              <img src={user.userId.profilePicUrl} alt="profile" className={Style.contactProfileImage} />
              <div className={`d-flex justify-content-between flex-wrap ${Style.contactInfoInner}`}>
                <div className={Style.contactName}>
                  <ContactInfoItem label={user.userId.email} icon={mailSvg} cssClass={`${Style.mailSvg}`} />
                </div>
                <div className={Style.contactAddress}>
                  <ContactInfoItem
                    label={user.userId.address || 'N/A'}
                    icon={locationGreenSvg}
                    cssClass={Style.addressSvg}
                  />
                </div>
                <div className={Style.contactName}>
                  <ContactInfoItem label={user.userId.phoneNo || 'N/A'} icon={mobileSvg} cssClass={Style.mobileSvg} />
                </div>
                <div className={Style.contactAddress}>
                  <ContactInfoItem
                    label="Roles"
                    icon={roleSettingSvg}
                    cssClass={Style.settingsSvg}
                    popopOver={getRolePopover}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={Style.showListingWrapper}>
            <ShowListing userId={user.userId.id} showRole={user.showRole} handleCancelAccess={handleCancelAccess} />
          </div>
          <div className={Style.actionButtonsWrapper}>
            <Button
              clickHandler={handleReset}
              label="Reset Password"
              icon={resetPasswordSvg}
              cssClass={Style.resetBtn}
            />
            <Button
              clickHandler={handleRemoveUser}
              label="Remove User"
              icon={deleteSvg}
              cssClass={Style.removeUserBtn}
            />
          </div>
        </div>
      )}
      <CPModal isShow={showModal} hideModal={handleModalClose}>
        <p>Are you sure? you want to remove the access</p>
      </CPModal>
    </>
  );
};

const mapDispatchToProps = {
  removeAccess: removeShowAccess,
  changeRole: changeUserRoleBegin,
};

export default connect(null, mapDispatchToProps)(UserItem);
