import React, { FunctionComponent, useEffect, useState } from 'react';
import Style from 'components/castingDirector/skills/Skills.module.css';
import { PerformerSkillList, PerformerSkill } from 'modules/user/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import SkillViewItem from 'components/castingDirector/skills/SkillViewItem';

interface SkillsProps {
  skills?: PerformerSkillList;
}
const Skill: FunctionComponent<SkillsProps> = (props) => {
  const { skills } = props;
  const [skillList, setSkillList] = useState<PerformerSkillList>(skills || []);

  useEffect(() => {
    if (skills) setSkillList(skills);
  }, [skills]);

  return (
    <>
      <div className={`d-flex justify-content-between align-items-center ${Style.skillTitle}`}>
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faSort} />
          <p className="mb-0 pl-2">Special Skills</p>
        </div>
      </div>
      <div className={`d-flex flex-wrap mt-30 ${Style.specialSkillsWrapper}`}>
        {skillList.map((skill: PerformerSkill) => (
          <SkillViewItem key={skill.id} skill={skill} />
        ))}
      </div>
    </>
  );
};

export default Skill;
