import React, { FunctionComponent, useState } from 'react';
import Style from 'components/bgPerformer/Calls/Calls.module.css';
import exportSvg from 'assets/svg/export.svg';
import { ShowDayJob } from 'modules/Show/show.types';
import { formateJobDate, saveJobKeyInLS } from 'helpers/utils';
import { useDispatch, useSelector } from 'react-redux';
import { openJobDetails } from 'store/actions/job.actions';
import { BookingRequest } from 'modules/jobs/types';
import PopoverStickOnHover from 'components/controls/Popover/PopoverStick';
import { Link } from 'react-router-dom';
import { getScreenWidth } from 'store/selector/general.selector';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import upArrowSvg from 'assets/svg/up-arrow.svg';
import Detail from 'components/bgPerformer/Calls/Details/Detail';
import { ScreenSize } from 'modules/general/general.type';
import { SCREEN_SIZE_MOBILE } from 'store/castingPax.constants';
import BookingButton from 'components/bgPerformer/BookingButton/BookingButton';

interface CastingCallItemProps {
  handleDetail: Function;
  job: ShowDayJob;
  booking: BookingRequest | undefined;
}

const Item: FunctionComponent<CastingCallItemProps> = (props) => {
  const { handleDetail, booking, job } = props;

  const dispatch = useDispatch();
  const [dropDownClicked, setDropDownClicked] = useState(false);
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);
  const toggleArrow = () => {
    setDropDownClicked(!dropDownClicked);
  };
  const detailComponent = () => {
    console.log('helo');
  };
  const fullDetailHandle = () => {
    document.body.click();
    dispatch(openJobDetails(job, booking));
  };

  const showButton = () => <BookingButton job={job.id} jobDates={job.dates} isCastingCall />;

  const handleLinkClick = (event: any) => {
    event.preventDefault();
  };

  const handleContextClick = () => {
    saveJobKeyInLS.save(job);
  };
  if (currentScreenSize === SCREEN_SIZE_MOBILE) {
    return (
      <>
        <tr className="pt-1 position-relative">
          <td>
            <div className="d-flex text-left position-relative">
              <PopoverStickOnHover item={job} status="call" fullDetailHandle={fullDetailHandle}>
                <Link
                  className={`mb-0 ${Style.castingCallTitle}`}
                  to="/performer/home"
                  onClick={handleLinkClick}
                  onContextMenu={handleContextClick}
                >
                  {job.title}
                </Link>
              </PopoverStickOnHover>
            </div>
          </td>
          <td className={`d-flex align-items-center ${Style.tabsMobileButtons}`}>
            <div className="">{showButton()}</div>
            <div onClick={toggleArrow} onKeyPress={toggleArrow} tabIndex={0} role="button">
              <img
                className={Style.callTabsArrows}
                src={dropDownClicked ? upArrowSvg : downArrowSvg}
                alt="dropdown-arrow"
              />
            </div>
          </td>
        </tr>
        <div className={Style.showRequestDetailMobile}>
          {dropDownClicked && (
            <Detail
              handleMouseLeave={detailComponent}
              fullDetailHandle={fullDetailHandle}
              dates={booking ? booking.dates : job.dates}
            />
          )}
        </div>
      </>
    );
  }
  return (
    <tr className="pt-1 position-relative">
      <td>
        <div className="d-flex text-left position-relative">
          <PopoverStickOnHover item={job} status="call" fullDetailHandle={fullDetailHandle}>
            <Link
              className={`mb-0 ${Style.castingCallTitle}`}
              to="/performer/home"
              onClick={handleLinkClick}
              onContextMenu={handleContextClick}
            >
              Title
            </Link>
          </PopoverStickOnHover>
        </div>
      </td>
      <td>
        <div className="d-flex text-left">
          <p className={`mb-0 ${Style.castingCallRole}`}>{job.title}</p>
        </div>
      </td>
      <td>
        <div className="d-flex text-left">
          <div
            className="ml-4"
            onClick={() => handleDetail(job, booking)}
            onKeyUp={() => handleDetail(job, booking)}
            tabIndex={0}
            role="button"
          >
            <img src={exportSvg} alt="" />
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex text-left">
          <p className={`mb-0 ${Style.castingCallDate}`}>{job.location ? job.location.label : ''}</p>
        </div>
      </td>
      <td>
        <div className="d-flex text-left">
          <p className={`mb-0 ${Style.castingCallDate} ${Style.textEllipsis}`}>{formateJobDate(job.dates, 'MMM DD')}</p>
        </div>
      </td>
      <td className="text-right">{showButton()}</td>
    </tr>
  );
};

export default Item;
