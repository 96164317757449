import addDays from './addDays';

const getDaysAfter = (numOfDays: number, originDate: Date = new Date()) => {
  const dates = [];
  for (let i = 1; i <= numOfDays; i += 1) {
    dates.push(addDays(i, originDate));
  }

  return dates;
};

export default getDaysAfter;
