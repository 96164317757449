import React, { FunctionComponent } from 'react';
import LeftNavStyle from 'components/ShowLeftNavigation/ShowLeftNav.module.css';
import { ShowWorkingDay } from 'modules/Show/show.types';
import { useDispatch, useSelector } from 'react-redux';
import { setDatesForFitler } from 'store/actions/show.actions';
import { getShowSelectedDates } from 'store/selector/show.selector';
// import pencilSvg from 'assets/svg/edit.svg';

interface EpisodesProps {
  workingDay: ShowWorkingDay;
}

const FilmDays: FunctionComponent<EpisodesProps> = (props: EpisodesProps) => {
  const { workingDay } = props;
  const dispatch = useDispatch();

  const showDates = useSelector(getShowSelectedDates);

  const isSelectedDate = (): string => {
    if (showDates && showDates.length === 1 && showDates[0] === workingDay.date) return LeftNavStyle.selectedDate;
    return '';
  };

  const handleSelectDate = () => {
    dispatch(setDatesForFitler([workingDay.date]));
  };

  return (
    <>
      <div className={`mb-2 ${LeftNavStyle.contentBorderBottom}`}>
        <div className="d-flex justify-content-between align-items-center">
          <div onClick={handleSelectDate} onKeyPress={handleSelectDate} role="button" tabIndex={0}>
            <p className={`${LeftNavStyle.showTitle} ${isSelectedDate()}`}>{workingDay.title}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilmDays;
