import React, { useMemo, useState } from 'react';
import Style from 'components/ProfileMenu/Settings/Setting.module.css';
import Button from 'components/controls/button/button';
import { connect, useDispatch, useSelector } from 'react-redux';
import { cardLoading, closeAccountLoading, getUser, privacyInProgress } from 'store/selector/auth.selector';
import { RoleCode, User } from 'modules/user/types';
import { deleteAccountBegin, setNotificationPrivacy, setSelectedTab } from 'store/actions/auth.actions';
import MultiSelectList from 'components/controls/MultiSelectList/MultiSelectList';
import { rolesData } from 'helpers/data/SelectOptions';
import { SelectOptionType } from 'modules/PerformerList/list.types';
import PopoverHover from 'components/controls/Popover/PopoverHover';
import { useHistory } from 'react-router';
import { getHomePage, getRoletitle } from 'helpers/utils';
import DropDownSearch from 'components/controls/select/DropDownSearch';

const Privacy = () => {
  const user: User = useSelector(getUser);
  const dispatch = useDispatch();
  const [enableNotification, setEnableNotification] = useState<boolean>(user.isNotificationOn || false);
  const privacyLoading = useSelector(privacyInProgress);
  const [enableEmail, setEnableEmail] = useState<boolean>(user.isEmailNotificationOn || false);
  const [roles, setRoles] = useState<string[]>(user.UserWhoCanSeeProfiles || []);
  const [rolesDataOption, setRolesDataOption] = useState(rolesData(user.UserWhoCanSeeProfiles || []));
  const history = useHistory();

  const publicProfile = [
    { id: 1, value: 'private', label: 'private' },
    { id: 2, value: 'public', label: 'public' },
  ];
  const handleSetProfilePrivacy = () => {
    if (user.isProfilePublic) return 'public';
    return 'private';
  };
  const [profilePrivacy, setProfilePrivacy] = useState<string>(handleSetProfilePrivacy());
  const handleNotificationPrivacy = (e: any) => {
    if (e.target.name === 'notification') setEnableNotification(e.target.checked);
    if (e.target.name === 'email') setEnableEmail(e.target.checked);
  };
  const handleSavePrivacy = () => {
    let value: boolean = false;
    if (profilePrivacy === 'public') value = true;
    dispatch(
      setNotificationPrivacy({
        email: enableEmail,
        notification: enableNotification,
        isPublic: value,
        profileAccess: roles,
      }),
    );
  };
  const setNewRoles = (role: string) => {
    const data = rolesDataOption;
    data.map((o: SelectOptionType) => {
      const obj = o;
      obj.isChecked = role.includes(obj.value);
      return obj;
    });
    setRolesDataOption(data);
    setRoles(role.split(','));
  };

  const roleComponentLoad = () => <MultiSelectList list={rolesDataOption} setSelectedIds={setNewRoles} />;

  const selectedRolesToDisplay = useMemo(
    () =>
      rolesDataOption
        .filter((x: SelectOptionType) => x.isChecked === true)
        .map((w: SelectOptionType) => w.label)
        .join(','),
    [roles],
  );
  const handleNavigate = () => {
    const role = getRoletitle(user.role as RoleCode);
    if (role === 'BG Performer') {
      dispatch(setSelectedTab('3'));
      history.push(getHomePage(user.role as RoleCode));
      window.scrollTo(0, 0);
    } else history.push('/my-profile');
  };
  return (
    <div className={Style.settingWrapper}>
      <div className={`pt-40 ${Style.profileWrapper} ${Style.paddingTopZero}`}>
        <p className={`mb-0 ${Style.settingHeading}`}>Profile Management</p>
        <div className={`row performerSearchWrapper ${Style.password}`}>
          <div className="col-lg-6 col-xl-4">
            <label>Edit your public profile</label>
            <DropDownSearch
              cssClass={Style.createInputLabel}
              data={publicProfile}
              defaultValue={profilePrivacy}
              // label="Edit your public profile"
              handleSelect={setProfilePrivacy}
            />
          </div>
          <div className="row">
            <div className="col-lg-6 col-xl-8">
              <p className={`mb-0 ml-0 ${Style.profileDetailDesc} ${Style.profileDetailDescMT}`}>
                We’ll occasionally send updates about your account to this inbox.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-xl-4">
            <div className={`${Style.password}`}>
              <label>Who can see my profile details</label>
              <PopoverHover cssClass="datelayoutWrapper" component={roleComponentLoad}>
                <div>
                  <div className={`mr-6 ${Style.dateLayout}`}>
                    {selectedRolesToDisplay || 'Who can see my profile details'}
                  </div>
                </div>
              </PopoverHover>
              <br />
            </div>
          </div>
          <div className="col-lg-6 col-xl-8" />
        </div>
        <div className="row mt-0 mt-lg-3 mt-xl-3 align-items-center">
          <div className="col-lg-6 col-xl-4">
            <p className={`mb-3 mb-lg-0 mb-xl-0 ${Style.profileDetailTitle}`}>Change your profile details</p>
          </div>
          <div className="col-lg-6 col-xl-8">
            <p className={`mb-3 mb-lg-0 mb-xl-0 ml-0 ${Style.profileDetailDesc}`}>
              We’ll occasionally send updates about your account to this inbox.
            </p>
          </div>
        </div>
        <div className="d-lg-block d-xl-flex d-xxl-flex d-block justify-content-start mt-1">
          <p className={`mb-3 mb-lg-0 mb-xl-0 ${Style.profileCEdit}`}>
            Edit, change and adjust what people see on your profile.
          </p>
          <p className={`mb-0 ${Style.purchaseHistory} ${Style.editProfileLink}`}>
            <span onClick={() => handleNavigate()} onKeyPress={() => handleNavigate()} role="button" tabIndex={0}>
              Edit profile
            </span>
          </p>
        </div>
      </div>
      <div className={`pt-40 ${Style.profileWrapper}`}>
        <div className="d-flex justify-content-between align-items-center">
          <p className={`mb-0 ${Style.settingHeading}`}>Notifications</p>
        </div>
        <div className={`mt-4 ${Style.notificationsWrapper}`}>
          <div>
            <div className="custom-control custom-switch mb-3">
              <input
                type="checkbox"
                name="notification"
                className="custom-control-input"
                id="forNotification"
                onChange={(e: any) => handleNotificationPrivacy(e)}
                checked={enableNotification}
              />
              <label className="custom-control-label" htmlFor="forNotification">
                Enable Notifications
              </label>
            </div>
            <div className="custom-control custom-switch mb-3 mb-lg-0 mb-xl-0 mb-xxl-0">
              <input
                type="checkbox"
                name="email"
                className="custom-control-input"
                id="forEmail"
                onChange={(e: any) => handleNotificationPrivacy(e)}
                checked={enableEmail}
              />
              <label className="custom-control-label" htmlFor="forEmail">
                Enable Email
              </label>
            </div>
          </div>
          <Button
            showLoading={privacyLoading}
            clickHandler={handleSavePrivacy}
            label="Save Privacy"
            cssClass={Style.addMethod}
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  deleteAccount: deleteAccountBegin,
};
const mapStateToProps = (state: any) => {
  const closeLoading = closeAccountLoading(state);
  const cardLoader = cardLoading(state);
  return {
    closeLoading,
    cardLoader,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Privacy);
