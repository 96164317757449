import {
  BookingRequest,
  BookingRequestList,
  CalendarItem,
  CalendarList,
  JobSearchFilterItem,
  JobSearchFilterList,
  Pagination,
} from 'modules/jobs/types';
import {
  IBookingParams,
  ICancelRequestParams,
  IIDParams,
  IPerformerId,
  ISearchJobParams,
  ISendJobRequestParam,
  ICalendarUpdateParams,
  IWorkedDaysParams,
} from 'modules/params/param.type';
import {
  PerformerWorkedDay,
  ShowDayJob,
  ShowDayJobList,
  ShowJob,
  UpdatePerformerMatrix,
} from 'modules/Show/show.types';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const getCastingRequest = {
  pending: {
    type: ACTIONS.FETCH_CASTING_REQUEST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: BookingRequestList) => ({
    type: ACTIONS.FETCH_CASTING_REQUEST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FETCH_CASTING_REQUEST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getBookingRequest = {
  pending: {
    type: ACTIONS.FETCH_BOOKING_REQUEST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: BookingRequestList) => ({
    type: ACTIONS.FETCH_BOOKING_REQUEST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FETCH_BOOKING_REQUEST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCalendarDates = {
  pending: {
    type: ACTIONS.FETCH_CALENDAR_REQUEST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: CalendarList) => ({
    type: ACTIONS.FETCH_CALENDAR_REQUEST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FETCH_CALENDAR_REQUEST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCastingCall = {
  pending: (data: number) => ({
    type: ACTIONS.FETCH_CASTING_CALL_REQUEST.PENDING,
    data,
    status: FETCH_STATUS.LOADING,
  }),
  success: (data: ShowDayJobList, pagination?: Pagination) => ({
    type: ACTIONS.FETCH_CASTING_CALL_REQUEST.SUCCESS,
    data,
    pagination,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FETCH_CASTING_CALL_REQUEST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCastingUpdates = {
  pending: {
    type: ACTIONS.FETCH_CASTING_UPDATE_NEWS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: BookingRequestList) => ({
    type: ACTIONS.FETCH_CASTING_UPDATE_NEWS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FETCH_CASTING_UPDATE_NEWS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const castingRequestBegin = (payload: IPerformerId) => ({
  type: ACTIONS.FETCH_CASTING_REQUEST_BEGIN,
  payload,
});

export const bookingRequestBegin = (payload: IPerformerId) => ({
  type: ACTIONS.FETCH_BOOKING_REQUEST_BEGIN,
  payload,
});

export const calendarDatesBegin = () => ({
  type: ACTIONS.FETCH_CALENDAR_REQUEST_BEING,
  payload: {},
});

export const castingCallRequestBegin = (type: IBookingParams) => ({
  type: ACTIONS.FETCH_CASTING_CALL_REQUEST_BEGIN,
  payload: type,
});

export const castingUpdateRequestBegin = (payload: IPerformerId) => ({
  type: ACTIONS.FETCH_CASTING_UPDATE_NEWS_BEGIN,
  payload,
});

export const openJobDetails = (data: ShowDayJob, bookingData?: BookingRequest) => ({
  type: ACTIONS.SET_JOB_FOR_VIEW,
  data,
  bookingData,
});

export const openJobFromNotification = (data: string) => ({
  type: ACTIONS.JOB_FOR_VIEW_FROM_NOTIFICATIONS,
  data,
});

export const closeOpenJobDetails = () => ({
  type: ACTIONS.CLOSE_JOB_FOR_VIEW,
});

export const cancelBooking = {
  pending: {
    type: ACTIONS.SET_CANCEL_BOOKING.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ICancelRequestParams) => ({
    type: ACTIONS.SET_CANCEL_BOOKING.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SET_CANCEL_BOOKING.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const cancelBookingLocally = (data: ICancelRequestParams) => ({
  type: ACTIONS.SET_CANCEL_BOOKING_LOCALLY,
  data,
});

export const cancelBookingBegin = (payload: ICancelRequestParams) => ({
  type: ACTIONS.SET_CANCEL_BOOKING_BEGIN,
  payload,
});

export const acceptCastingRequest = {
  pending: {
    type: ACTIONS.SET_ACCEPT_CASTING_REQUEST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: BookingRequest) => ({
    type: ACTIONS.SET_ACCEPT_CASTING_REQUEST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SET_ACCEPT_CASTING_REQUEST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const accepCastingRequestLocally = (payload: ICancelRequestParams) => ({
  type: ACTIONS.SET_ACCEPT_CASTING_REQUEST_LOCALLY,
  data: payload,
});

export const acceptCastingRequestBegin = (payload: ICancelRequestParams) => ({
  type: ACTIONS.SET_ACCEPT_CASTING_REQUEST_BEGIN,
  payload,
});

export const addNewBooking = (data: BookingRequest) => ({
  type: ACTIONS.ADD_NEW_BOOKING,
  data,
});

export const addNewCastingRequest = (data: BookingRequest) => ({
  type: ACTIONS.ADD_NEW_CASTING_REQUEST,
  data,
});

export const addNewJobCall = (data: ShowDayJob) => ({
  type: ACTIONS.ADD_NEW_CASTING_CALL,
  data,
});

export const jobUpdateNotification = (data: BookingRequest) => ({
  type: ACTIONS.ADD_NEW_JOB_UPDATE_MESSAGE,
  data,
});

export const jobDeleteNotification = (jobId: ShowJob) => ({
  type: ACTIONS.DELETE_JOB_MESSAGE,
  data: jobId,
});

export const jobCloseNotfication = (data: BookingRequest) => ({
  type: ACTIONS.CLOSE_JOB_MESSAGE,
  data,
});

export const markedJobUpdateStatus = {
  pending: {
    type: ACTIONS.SET_JOB_UPDATE_STATUS_READ.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: IIDParams) => ({
    type: ACTIONS.SET_JOB_UPDATE_STATUS_READ.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SET_JOB_UPDATE_STATUS_READ.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const markedJobUpdateStatusBegin = (payload: IIDParams) => ({
  type: ACTIONS.SET_JOB_UPDATE_STATUS_READ_BEGIN,
  payload,
});

export const submitJobRequest = {
  pending: {
    type: ACTIONS.SUBMIT_JOB_REQUEST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: BookingRequest) => ({
    type: ACTIONS.SUBMIT_JOB_REQUEST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error, payload: IIDParams) => ({
    type: ACTIONS.SUBMIT_JOB_REQUEST.ERROR,
    error,
    payload,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateJobRequestLocally = (data: string) => ({
  type: ACTIONS.SUBMIT_JOB_REQUEST_LOCALLY,
  data,
});

export const submitJobRequestBegin = (payload: IIDParams) => ({
  type: ACTIONS.SUBMIT_JOB_REQUEST_BEGIN,
  payload,
});

export const searchJobs = {
  pending: {
    type: ACTIONS.SET_SEARCH_JOBS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ShowJob[], pagination?: Pagination) => ({
    type: ACTIONS.SET_SEARCH_JOBS.SUCCESS,
    data,
    pagination,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SET_SEARCH_JOBS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const searchJobBegins = (payload: ISearchJobParams) => ({
  type: ACTIONS.SET_SEARCH_JOBS_BEGIN,
  payload,
});

export const jobRequestFromCD = {
  pending: {
    type: ACTIONS.CALL_JOB_REQUEST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: BookingRequest) => ({
    type: ACTIONS.CALL_JOB_REQUEST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CALL_JOB_REQUEST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const jobRequestFromCDBegin = (payload: ISendJobRequestParam) => ({
  type: ACTIONS.CALL_JOB_REQUEST_BEGIN,
  payload,
});

export const updateCalendarStatus = {
  pending: {
    type: ACTIONS.UPDATE_CALENDAR_STATUS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: CalendarItem) => ({
    type: ACTIONS.UPDATE_CALENDAR_STATUS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPDATE_CALENDAR_STATUS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateCalendarStatusBegin = (payload: ICalendarUpdateParams) => ({
  type: ACTIONS.UPDATE_CALENDAR_STATUS_BEGIN,
  payload,
});

export const updateCalendarStatusLocal = (payload: ICalendarUpdateParams) => ({
  type: ACTIONS.UPDATE_CALENDAR_STATUS_LOCAL,
  data: payload,
});

export const fetchJobSearchFilter = {
  pending: {
    type: ACTIONS.FETCH_JOB_SEARCH_FILTER.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: JobSearchFilterList) => ({
    type: ACTIONS.FETCH_JOB_SEARCH_FILTER.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FETCH_JOB_SEARCH_FILTER.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchJobSearchFilterBegin = () => ({
  type: ACTIONS.FETCH_JOB_SEARCH_FILTER_BEGIN,
  payload: {},
});

export const updateJobSearchFilterList = (data: JobSearchFilterItem) => ({
  type: ACTIONS.UPDATE_JOB_SEARCH_FILTER_LIST,
  data,
});

export const updateJobStats = (data: any) => ({
  type: ACTIONS.UPDATE_BOOKING_STATS,
  data,
});

export const removeJobFromBooking = (data: any) => ({
  type: ACTIONS.REMOVE_JOB_FROM_BOOKING,
  data,
});

export const addPerformerInMatrixData = (data: UpdatePerformerMatrix) => ({
  type: ACTIONS.ADD_PERFORMER_IN_MATRIX,
  data,
});

export const fetchPerformerWorkedDays = {
  pending: {
    type: ACTIONS.FETCH_PERFORMER_WORKED_DAYS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: PerformerWorkedDay[], pagination: Pagination) => ({
    type: ACTIONS.FETCH_PERFORMER_WORKED_DAYS.SUCCESS,
    data,
    pagination,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FETCH_PERFORMER_WORKED_DAYS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchPerformerWorkedDaysBegin = (payload: IWorkedDaysParams) => ({
  type: ACTIONS.FETCH_PERFORMER_WORKED_DAYS_BEGIN,
  payload,
});

export const updateOpenPerformerCalendar = (data: BookingRequest) => ({
  type: ACTIONS.UPDATE_SELECTED_PERFORMER_CALENDAR,
  data,
});

export const sortJobResult = (data: number) => ({
  type: ACTIONS.SORT_JOB_RESULT_ORDER,
  data,
});

export const performerJobBookingNotificationView = (jobId: string) => ({
  type: ACTIONS.PERFORMER_JOB_BOOKING_NOTIFICATION_VIEW,
  payload: { id: jobId },
});
