import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
// import VoucherManagement from 'components/Payroll/Nav/VoucherManagement';
// import TopNavBar from 'components/Payroll/Nav/PayrollNav';
import SideNav from 'components/Payroll/Nav/PayrollSideNav';
import { VoucherManagementSideNavItemData } from 'components/Payroll/Data/VoucherDashboardData';
import TopNavBar from 'components/TopNavBar/TopNavBar';

interface VoucherManagementProps {}

const VoucherManagement: FunctionComponent<VoucherManagementProps> = () => {
  const [showSideNav, setShowSideNav] = useState(true);
  return (
    <Wrapper>
      {/* <VoucherManagement /> */}
      <DashboardContainer>
        {/* <TopNavBar showSideNav={showSideNav} setShowSideNav={setShowSideNav} /> */}
        <TopNavBar setShowSideNav={setShowSideNav} showSideNav={showSideNav} />
        <SideNav
          showNavIcon
          showChevron={false}
          sideNavData={VoucherManagementSideNavItemData}
          showSideNav={showSideNav}
        />
      </DashboardContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  // overflow-y: scroll;
  // overflow-x: hidden;
  // min-width: 1080px;
  background-color: #e5e5e5;
  margin: 0 auto;
  @media only screen and (max-width: 991px) {
    // min-width: 765px;
  }
`;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  // min-width: 1080px;
  margin: 0 auto;
  @media only screen and (max-width: 991px) {
    // min-width: 765px;
  }
`;

export default VoucherManagement;
