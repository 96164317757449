import React, { useState, FunctionComponent } from 'react';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styled from 'styled-components';

interface DropDownInterface {
  setShowDropDown: React.Dispatch<React.SetStateAction<boolean>>;
  showDropDown: boolean;
}

const ChevronIcon: FunctionComponent<DropDownInterface> = (props: DropDownInterface) => {
  const [toggleChevron, setToggleChevron] = useState(false);
  const { setShowDropDown, showDropDown } = props;

  const handleClick = () => {
    setToggleChevron(!toggleChevron);
    setShowDropDown(!showDropDown);
  };

  return (
    <ChevronContainer>
      {toggleChevron ? (
        <StyledChevronDown onClick={handleClick} icon={faChevronDown} />
      ) : (
        <StyledChevronRight onClick={handleClick} icon={faChevronRight} />
      )}
    </ChevronContainer>
  );
};

const ChevronContainer = Styled.div`
display: flex;
margin-left: auto;
`;

const StyledChevronRight = Styled(FontAwesomeIcon)`
margin-top:10px;
color: black;
margin-top: auto;
cursor: pointer;
`;

const StyledChevronDown = Styled(FontAwesomeIcon)`
margin-top:10px;
color: black;
margin-top: auto;
cursor: pointer;
`;

export default ChevronIcon;
