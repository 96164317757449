import styled from 'styled-components';

interface DateItemProps {
  isSelected: boolean;
}
// eslint-disable-next-line import/prefer-default-export
const StyledDateBox = styled.div<DateItemProps>`
  display: flex;
  background: ${({ isSelected }) => (isSelected ? '#4B4D67' : '')};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-radius: 5px;
  width: 70px;
  margin-left: 6px;
  padding: 5.43px 4.2px;
  padding-top: 8.29px;
  cursor: pointer;

  h3,
  div {
    color: ${({ isSelected }) => (isSelected ? '#FFFFFF' : '#828282;')};
    font-size: 16px;
  }

  & > div {
  }
`;

export default StyledDateBox;
