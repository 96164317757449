import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { openFullViewImage } from 'store/actions/performer.actions';

interface ImageViewProps {
  src: string;
  alt: string;
  cssClass: string;
  isFullView?: boolean;
  fullImgSrc?: string;
  setProfile?: Function;
  openFullViewImagePreview: Function;
}

const ImageView: FunctionComponent<ImageViewProps> = (props: ImageViewProps) => {
  const { src, alt, cssClass, isFullView, fullImgSrc, setProfile, openFullViewImagePreview } = props;
  const handleClick = () => {
    if (isFullView) {
      let imgName = src;
      if (imgName.includes('-thumbnail')) {
        if (fullImgSrc) {
          imgName = fullImgSrc;
        } else {
          imgName = imgName.replace('-thumbnail', '');
        }
      }
      if (setProfile) setProfile(imgName);
      else {
        openFullViewImagePreview(imgName);
      }
    }
  };
  return <img src={src} alt={alt} className={cssClass} onClick={handleClick} role="presentation" />;
};

const mapDisptcahToProps = {
  openFullViewImagePreview: openFullViewImage,
};
export default connect(null, mapDisptcahToProps)(ImageView);
