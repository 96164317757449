import React, { FunctionComponent, useEffect, useState } from 'react';
import Style from 'components/ProfileMenu/Settings/Setting.module.css';
import Input from 'components/controls/input/dashboardInput/dashboardInput';
import { User } from 'modules/user/types';
import { connect } from 'react-redux';
import { getBusinessLoading } from 'store/selector/auth.selector';
import { updateBusinessInfoBegin } from 'store/actions/auth.actions';
import Button from 'components/controls/button/button';
import { LOADER_STATUS } from 'store/castingPax.constants';

interface IBillingInfoProps {
  user: User;
  businessLoading: boolean;
  updateBusinessInfo: Function;
}
const BillingInfo: FunctionComponent<IBillingInfoProps> = (props: IBillingInfoProps) => {
  const { user, businessLoading, updateBusinessInfo } = props;
  const [pst, setPst] = useState<string>(user.PSTNumber || '');
  const [gst, setGst] = useState<string>(user.GSTNumber || '');
  const [businessName, setBusinessName] = useState<string>(user.businessName || '');
  const [businessAddress, setBusinessAddress] = useState<string>(user.businessAddress || '');
  const [businessDisable, setBusinessDisable] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [loaderStatus, setLoaderStatus] = useState<number>(LOADER_STATUS.NA);

  useEffect(() => {
    if (!businessLoading) setVisible(false);
  }, [businessLoading]);

  useEffect(() => {
    setBusinessDisable(
      pst.length === 0 || gst.length === 0 || businessName.length === 0 || businessAddress.length === 0,
    );
  });

  useEffect(() => {
    if (loaderStatus === LOADER_STATUS.COMPLETED) {
      setVisible(pst.length > 0 || gst.length > 0 || businessName.length > 0 || businessAddress.length > 0);
    }
    setLoaderStatus(LOADER_STATUS.COMPLETED);
  }, [pst, gst, businessName, businessAddress]);

  const handleUpdateBusinessInfo = () => {
    updateBusinessInfo({
      PSTNumber: pst,
      GSTNumber: gst,
      businessName,
      businessAddress,
    });
  };

  const handleDisable = () => {
    if (!visible) return true;
    if (visible && !businessDisable) return false;
    return true;
  };

  return (
    <div className={Style.passwordWrapper}>
      <p className={`mb-0 ${Style.settingHeading}`}>Billing Info</p>
      <div className="row">
        <div className="col-lg-6 col-xl-4">
          <div className={Style.password}>
            <Input
              value={pst}
              placeholder="PST"
              type="text"
              cssClass={Style.input}
              handleChange={setPst}
              inputLabel={Style.settingInputLabel}
              label="PST"
            />
          </div>
        </div>
        <div className="col-lg-6 col-xl-4">
          <div className={Style.password}>
            <Input
              value={gst}
              placeholder="GST"
              type="text"
              cssClass={Style.input}
              inputLabel={Style.settingInputLabel}
              handleChange={setGst}
              label="GST"
            />
          </div>
        </div>
      </div>
      <div className={`row ${Style.password}`}>
        <div className="col-lg-6 col-xl-4">
          <Input
            value={businessName}
            placeholder="Business Name"
            type="text"
            cssClass={Style.input}
            inputLabel={Style.settingInputLabel}
            handleChange={setBusinessName}
            label="Business Name"
          />
        </div>
        <div className="col-lg-6 col-xl-4">
          <Input
            value={businessAddress}
            placeholder="Business Address"
            type="text"
            cssClass={Style.input}
            inputLabel={Style.settingInputLabel}
            handleChange={setBusinessAddress}
            label="Business Address"
          />
        </div>

        <div className="col-lg-6 col-xl-4">
          <Button
            clickHandler={handleUpdateBusinessInfo}
            showLoading={businessLoading}
            isDisabled={handleDisable()}
            label="Update"
            cssClass={`mt-30 ${Style.passwordButton}`}
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  updateBusinessInfo: updateBusinessInfoBegin,
};
const mapStateToProps = (state: any) => {
  const businessLoading = getBusinessLoading(state);
  return { businessLoading };
};
export default connect(mapStateToProps, mapDispatchToProps)(BillingInfo);
