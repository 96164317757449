/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useState } from 'react';
import Styled from 'styled-components';
import ReviewIcon from 'assets/images/reviewIcon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faker } from '@faker-js/faker';

interface NoteItemProps {
  date: string;
  summary: string;
  title: string;
}

const ReviewNoteItem: FunctionComponent<NoteItemProps> = (props: NoteItemProps) => {
  const [showSideNav, setShowSideNav] = useState(false);
  const [showNote, setShowNote] = useState(false);

  const toggleShowNote = () => {
    setShowNote(!showNote);
  };

  return (
    <ContentContainer>
      <ItemContainer>
        <TopItemContainer>
          <ItemTextContainer>
            <DateContainer>{props.date}</DateContainer>
            <TextContainer>{props.title}</TextContainer>
          </ItemTextContainer>
          <RightContainer>
            <ClickIcon src={ReviewIcon} />
            {showNote ? (
              <StyledChevronDown onClick={toggleShowNote} size="1x" icon={faChevronUp} />
            ) : (
              <StyledChevronDown onClick={toggleShowNote} size="1x" icon={faChevronDown} />
            )}
          </RightContainer>
        </TopItemContainer>
        {showNote ? <NoteSummary>{props.summary}</NoteSummary> : ''}
      </ItemContainer>
    </ContentContainer>
  );
};
const ContentContainer = Styled.div`
display: flex;
flex-direction: column;
`;
const NoteSummary = Styled.p`
padding-top: 15px;
padding-right: 52px;
color: #202224;
font-weight: 400;
font-size: 13px;
margin-bottom: 0px;
display: flex;
justify-content: flex-start;
width: 100%;
`;

const ItemContainer = Styled.div`
display: flex;
flex-direction: column;
margin: 0px 20px;
width: 95%;
border-bottom: 1px dashed #E0E0E0;
padding-top: 16px;
padding-bottom: 14.5px;
align-items: center;
justify-content: space-between;
`;
const TopItemContainer = Styled.div`
display: flex;
justify-content: space-between;
width: 100%;
`;

const ItemTextContainer = Styled.div`
  display: flex;
  align-items: center;
`;

const TextContainer = Styled.p`
  margin: 0;
  display: flex;
  opacity: 0.9;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat,sans-serif;
`;

const ClickIcon = Styled.img`
  width: 80px;
  cursor:pointer;
`;

const UserIcon = Styled.img`
margin-bottom: 16px;
height: 20px;
width: 20px;
border-radius: 100%;
margin-right: 10px;
cursor:pointer;
`;

const DateContainer = Styled.p`
  margin: 0;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  min-width: 90px;
  color: "#202224";
  font-family: 'Work Sans';
`;

const StyledChevronDown = Styled(FontAwesomeIcon)`
  margin-left: 38px;
  color: '#202224';
  cursor:pointer;
`;

const RightContainer = Styled.div`
  display: flex;
  align-items: center;
`;

export default ReviewNoteItem;
