import React from 'react';
import styled from 'styled-components';
import ArrowUpLeft from 'assets/images/arrowUpRight.svg';

// interface Props {}

const NameCellRenderer: React.FC = (props: any) => {
  const { value } = props;
  return (
    <DivContainer>
      <p>{value}</p>
      <IconWrapper>
        <Image src={ArrowUpLeft} alt="" />
      </IconWrapper>
    </DivContainer>
  );
};
export default NameCellRenderer;

const DivContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #2f80edcc;
  & p {
    margin-bottom: 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2f80edcc;
  }
`;

const Image = styled.img`
  height: 16px;
  width: 14px;

  &:hover {
    cursor: pointer;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
