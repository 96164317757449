import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { shareCustomRateWithUser } from 'store/actions/show.actions';
import styled from 'styled-components';
import SideRateOptions from './components/rates/SideRateOptions.container';
import { RateCard } from './components/rates/rate-card';
import { AllShows } from './components/allShow-dropdown';

interface Props {
  shareCustomRate?: any;
}

const RateManagementPageComponent: React.FC<Props> = (props) => {
  const { shareCustomRate } = props;
  const [shareRate, setShareRate] = useState({
    userId: '',
    rateId: '',
  });
  const [rateValueId, setRateValueId] = useState('');
  const [isEditAble, setIsEditAble] = useState(false);
  const onClickHandler = useCallback((id: string, attribute: string) => {
    setShareRate((prevState) => ({
      ...prevState,
      [attribute]: id,
    }));
  }, []);
  useEffect(() => {
    if (shareRate.rateId !== '' && shareRate.userId !== '') {
      shareCustomRate(shareRate);
      setShareRate({
        userId: '',
        rateId: '',
      });
    }
  }, [shareRate]);
  return (
    <PageContainer>
      <Dashboard>
        <PayrollHeaderContainer>
          {' '}
          <TextBox>Payroll Rate Management</TextBox>
          <AllShows />
        </PayrollHeaderContainer>
        <RateBottomContainer>
          <SideRateOptions id={setRateValueId} onClickHandler={onClickHandler} isEditAble={setIsEditAble} />
          <RateCard isEditAble={isEditAble} rateValId={rateValueId} onClickHandler={onClickHandler} />
        </RateBottomContainer>
      </Dashboard>
    </PageContainer>
  );
};

const RateBottomContainer = styled.div`
  display: flex;
`;

const PageContainer = styled.div`
  display: flex;
  flex: 1;
`;

const Dashboard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1600px;
  // min-width: 1080px;
  min-height: 90vh;
  flex: 1;
  @media only screen and (max-width: 1199px) {
    // min-width: 950px;
  }
  @media only screen and (max-width: 991px) {
    // min-width: 645px;
  }
`;

const TextBox = styled.p`
  width: 400px;
  height: 30px;
  color: #4b4d67;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0px;
`;

const PayrollHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;
const mapDispatchToProps = {
  shareCustomRate: shareCustomRateWithUser,
};

export default connect(null, mapDispatchToProps)(RateManagementPageComponent);
