import { connect } from 'react-redux';
import { User } from 'modules/user/types';
import ProducerLanding from 'views/producer/landing/ProducerLanding';
import { getShowCalendarDates } from 'store/selector/show.selector';
import { clearSelectedJobListing, getEmailTemplateRequest, setShowSelectedDate } from 'store/actions/show.actions';
import { getSettingRequest } from 'store/actions/general.actions';

const mapStateToProps = (state: User) => {
  const showCalendarDates = getShowCalendarDates(state);
  return {
    showCalendarDates,
  };
};

const mapDispatchToProps = {
  setShowSelectedDate,
  getEmailTemplateRequest,
  clearSelectedJobListing,
  getSettingRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProducerLanding);
