import React from 'react';
import styled from 'styled-components';
import FilterTab from './FilterTab';
import TabContainer from '../common/FilterTabContainer';
import SearchTab from '../VoucherManagement/filter-tab/SearchTab';
import { FilterComponentContainer } from '../VoucherManagement/voucher/FilterTabsWrapper';

interface Props {
  handleFilter: any;
  handleSearch: any;
  handleDayFilter: any;
}

const FilterListWrapper: React.FC<Props> = (props) => {
  // const [filter, setFilter] = useState('');
  const { handleFilter, handleSearch, handleDayFilter } = props;
  // console.log(filter);
  const today = new Date();
  const firstDayOfWeek = 1; // Monday
  const daysSinceFirstDay = (today.getDay() + 7 - firstDayOfWeek) % 7;
  const sunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - daysSinceFirstDay);

  const dummyData = [
    {
      id: '1',
      label: 'Monday',
      value: new Date(sunday.getTime() + 1 * 86400000).toISOString().substr(0, 10),
    },
    {
      id: '2',
      label: 'Tuesday',
      value: new Date(sunday.getTime() + 2 * 86400000).toISOString().substr(0, 10),
    },
    {
      id: '3',
      label: 'Wednesday',
      value: new Date(sunday.getTime() + 3 * 86400000).toISOString().substr(0, 10),
    },
    {
      id: '4',
      label: 'Thursday',
      value: new Date(sunday.getTime() + 4 * 86400000).toISOString().substr(0, 10),
    },
    {
      id: '5',
      label: 'Friday',
      value: new Date(sunday.getTime() + 5 * 86400000).toISOString().substr(0, 10),
    },
    {
      id: '6',
      label: 'Saturday',
      value: new Date(sunday.getTime() + 6 * 86400000).toISOString().substr(0, 10),
    },
    {
      id: '7',
      label: 'Sunday',
      value: sunday.toISOString().substr(0, 10),
    },
  ];

  const recentlyBookedData = [
    {
      id: '4',
      label: 'All',
      value: 'all',
    },
    {
      id: '1',
      label: '7 days',
      value: '7',
    },
    {
      id: '2',
      label: '14 Days',
      value: '14',
    },
    {
      id: '3',
      label: '21 Days',
      value: '21',
    },
  ];
  return (
    <TabContainer>
      <Label>Sort By</Label>
      <FilterComponentContainer>
        <FilterTab
          extraText="Shoot day  "
          placeholderText="Shoot day"
          borderRight="#e0e0e07f"
          style={{ paddingRight: '10px' }}
          data={dummyData}
          checked
          toggleCheck={() => {}}
          handleFilter={handleDayFilter}
        />
      </FilterComponentContainer>
      <FilterComponentContainer>
        <FilterTab
          extraText="Recently Booked"
          placeholderText="Recently Booked"
          borderRight="#e0e0e07f"
          style={{ paddingRight: '10px' }}
          data={recentlyBookedData}
          checked
          toggleCheck={() => {}}
          handleFilter={handleFilter}
        />
      </FilterComponentContainer>
      <SearchContainer>
        <SearchTab placeholderText="Search" handleSearch={handleSearch} />
      </SearchContainer>
    </TabContainer>
  );
};
const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  border-right: 1px solid #e0e0e07f;
  height: 100%;
`;

export const Label = styled.p`
  margin: 0;
  color: #4b4d67;
  font-weight: 500;
  border-right: 1px solid #e0e0e07f;
  font-family: 'Montserrat';
  font-style: normal;
  line-height: 24px;
  justify-content: center;
  font-size: 20px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 15px;
`;

export default FilterListWrapper;
