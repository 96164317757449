import React, { FunctionComponent } from 'react';
import { User } from 'modules/user/types';
import PerformerListItemStyle from 'components/MyPerformerList/MyPerformerList.module.css';
import { getImageThumbnail, getName, saveUserOnLocalStorage } from 'helpers/utils';

interface ListItemProps {
  item: User;
  cssClass?: string;
  type?: string;
}
const SearchItem: FunctionComponent<ListItemProps> = (props: ListItemProps) => {
  const { item, cssClass, type } = props;

  const viewProfile = () => {
    if (item.role === '60671874f9d2b991014963fa') {
      saveUserOnLocalStorage(item);
      window.open('/casting/profile/view');
    } else {
      saveUserOnLocalStorage(item);
      window.open('/profile-view');
    }
  };

  return (
    <>
      <div className={`d-flex  ${PerformerListItemStyle.listingItemWrapper}`} onClick={viewProfile} role="presentation">
        <img
          src={getImageThumbnail(item.profilePicUrl)}
          alt=""
          className={type ? `${cssClass}` : `${PerformerListItemStyle.itemImage} ${cssClass}`}
        />
        <p className={`ml-3 mb-0 ${PerformerListItemStyle.performerListItemTitle}`}>{getName(item)}</p>
      </div>
    </>
  );
};
export default SearchItem;
