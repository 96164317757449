import React, { FunctionComponent, useMemo, useRef } from 'react';
import Style from 'components/TopNavBar/TopNavBar.module.css';
import calendarSvg from 'assets/svg/calendar.svg';
import prelimCallSvg from 'assets/svg/mobile-orange.svg';
import finalCallSvg from 'assets/svg/mobile.svg';
import settingBlueSvg from 'assets/svg/setting-blue.svg';
import notificationSvg from 'assets/svg/Notification.svg';
import squareCloseSvg from 'assets/svg/square-close.svg';
import squareCheckSvg from 'assets/svg/square-check.svg';
import squareCheckOrangeSvg from 'assets/svg/square-check-orange.svg';
import { Notification } from 'modules/general/general.type';
import { useInViewport } from 'react-in-viewport';

interface NotificationViewProps {
  notification: Notification;
  handleRead: Function;
  handleNotificationView: Function;
}

const NotificationView: FunctionComponent<NotificationViewProps> = (props: NotificationViewProps) => {
  const { notification, handleRead, handleNotificationView } = props;

  const myRef = useRef<HTMLDivElement>(null);
  const { inViewport } = useInViewport(myRef, {}, { disconnectOnLeave: false }, props);

  useMemo(() => {
    if (inViewport && !notification.isSeen) {
      const prev = localStorage.getItem('unreadNotificationList');
      console.log('is View', prev);
      if (prev) {
        const list = JSON.parse(prev);
        const isExists = list.findIndex((x: string) => x === notification.id);
        if (isExists === -1) {
          localStorage.setItem('unreadNotificationList', JSON.stringify([...list, notification.id]));
        }
      } else {
        localStorage.setItem('unreadNotificationList', JSON.stringify([notification.id]));
      }
      handleRead(notification.id);
    }
  }, [inViewport]);

  const settingIcon = (type: any) => {
    switch (type) {
      case 'calender':
        return calendarSvg;
      case 'prelim':
        return prelimCallSvg;
      case 'final':
        return finalCallSvg;
      case 'setting':
        return settingBlueSvg;
      case 'update':
        return notificationSvg;
      case 'jobCancel':
      case 'denied':
        return squareCloseSvg;
      case 'jobUpdate':
      case 'jobAccept':
      case 'accepted':
        return squareCheckSvg;
      case 'jobRequest':
      case 'pending':
        return squareCheckOrangeSvg;
      default:
        return settingBlueSvg;
    }
  };

  const handleClick = () => {
    handleNotificationView(notification);
  };

  return (
    <>
      <div
        onClick={handleClick}
        onKeyPress={handleClick}
        tabIndex={0}
        role="button"
        ref={myRef}
        className={`d-flex align-items-center ${Style.notificationItemWrapper} ${
          notification.isSeen ? '' : Style.notificationUnRead
        } `}
      >
        <img src={settingIcon(`${notification.type}`)} alt="cog" />
        <div className={`${Style.notificationContentWrapper}`}>
          <p className={`mb-0 ${Style.notificationTitle}`}>{notification.title}</p>
          <p className={`mb-0 ${Style.notificationDesc}`}>{notification.message}</p>
        </div>
      </div>
    </>
  );
};

export default NotificationView;
