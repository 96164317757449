import React, { FunctionComponent } from 'react';
import signupCatStyle from 'components/singupCategory/signupCategory.module.css';

interface CategoryProps {
  label: string;
  image: string;
  clickHandler: (e: React.FormEvent<HTMLDivElement>) => void;
}

const SingupCategoryOption: FunctionComponent<CategoryProps> = (props: CategoryProps) => {
  const { label, image, clickHandler } = props;
  return (
    <div
      className={`${signupCatStyle.cardBox}`}
      onClick={clickHandler}
      onKeyPress={clickHandler}
      role="button"
      tabIndex={0}
    >
      <img src={image} alt="performer" />
      <p>{label}</p>
    </div>
  );
};
export default SingupCategoryOption;
