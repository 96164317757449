import React, { FunctionComponent, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';

interface AccordionItemProps {
  title: string;
  description: string;
  eventKey: string;
}
const AccordionItem: FunctionComponent<AccordionItemProps> = (props: AccordionItemProps) => {
  const { title, description, eventKey } = props;
  const [isExpand, setIsExpand] = useState(false);
  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={eventKey}
        onClick={() => setIsExpand(!isExpand)}
        onKeyPress={() => setIsExpand(!isExpand)}
        role="button"
        tabIndex={0}
        className={isExpand ? 'collapsed' : ''}
      >
        <h5 className="mb-0 cardTitle">{title}</h5>
      </Accordion.Toggle>

      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>
          <p className="mb-0">{description}</p>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
export default AccordionItem;
