import axios from 'axios';
import { showMessageBox } from 'store/actions/general.actions';
import { MESSAGE_BOX_TYPE } from 'store/castingPax.constants';
import store from 'store/index';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    if (response && response.status === 401) {
      const user = getItemFromLocalStorage('user');
      if (user) {
        localStorage.clear();
        store.dispatch(
          showMessageBox({
            title: 'Session Expire',
            message: 'Session has been expired. login again',
            type: MESSAGE_BOX_TYPE.SUCCESS_EXPIRE,
          }),
        );
      }
    }
    return Promise.reject(error);
  },
);

export default axios;
