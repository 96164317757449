import { all, takeEvery, put, call } from 'redux-saga/effects';
import { MyPerformerListType, PerformerList } from 'modules/PerformerList/list.types';
import { myPerformerActions, showActions } from 'store/actions';
import ACTIONS from 'store/castingPax.constants';
import api from 'store/services/myPerformer.services';
import {
  IAddPerformerInMyList,
  ICreateMyPerformerList,
  IIDParams,
  IUserJobStatusParam,
  IPerformerDetail,
  IPerformerSearchParam,
  IPerformerId,
} from 'modules/params/param.type';
import ApiResponse from 'modules/response/response.types';
import { BookingRequestList } from 'modules/jobs/types';
import { ImageFolderList } from 'modules/user/types';
import { ShowDayJob } from 'modules/Show/show.types';
import showApi from 'store/services/show.services';

function* processMyPerformerList() {
  try {
    yield put(myPerformerActions.getMyPerformerList.pending);
    const response: MyPerformerListType = yield call(api.fetchMyPerformerList);
    yield put(myPerformerActions.getMyPerformerList.success(response));
  } catch (error) {
    yield put(myPerformerActions.getMyPerformerList.error(error.message));
  }
}

function* createListItem(state: { type: string; payload: ICreateMyPerformerList }) {
  try {
    yield put(myPerformerActions.createList.pending);

    const response: PerformerList = yield call(api.newListItem, state.payload);
    yield put(myPerformerActions.createList.success(response));
  } catch (error) {
    yield put(myPerformerActions.createList.error(error.message));
  }
}

function* fetchPerformerDetails(state: { type: string; payload: IPerformerDetail }) {
  try {
    yield put(myPerformerActions.fetchPerformerDetail.pending);
    const response: ApiResponse = yield call(api.fetchPerformerDetail, state.payload);
    yield put(myPerformerActions.fetchPerformerDetail.success(response.data));
  } catch (error) {
    yield put(myPerformerActions.fetchPerformerDetail.error(error.message));
  }
}

function* fetchPerformerImages(state: { type: string; payload: IIDParams }) {
  try {
    yield put(myPerformerActions.fetchPerformerIamges.pending);
    const response: ImageFolderList = yield call(api.fetchPerformerImages, state.payload);
    yield put(myPerformerActions.fetchPerformerIamges.success(response));
  } catch (error) {
    yield put(myPerformerActions.fetchPerformerIamges.error(error.message));
  }
}

function* fetchPerformerBookings(state: { type: string; payload: IPerformerId }) {
  try {
    yield put(myPerformerActions.fetchPerformerBookings.pending);
    const response: BookingRequestList = yield call(api.fetchPerformerBookings, state.payload);
    yield put(myPerformerActions.fetchPerformerBookings.success(response));
  } catch (error) {
    yield put(myPerformerActions.fetchPerformerBookings.error(error.message));
  }
}

function* addPerformerInList(state: { type: string; payload: IAddPerformerInMyList }) {
  try {
    yield put(myPerformerActions.addPerfomerInMyList.pending);
    const response: string = yield call(api.addPerformerInMyList, state.payload);
    yield put(myPerformerActions.addPerfomerInMyList.success(response));
    const res: MyPerformerListType = yield call(api.fetchMyPerformerList);
    yield put(myPerformerActions.getMyPerformerList.success(res));
  } catch (error) {
    yield put(myPerformerActions.addPerfomerInMyList.error(error.message));
  }
}

function* fetchPersonalNote() {
  try {
    yield put(myPerformerActions.fetchPersonalNotes.pending);
    const response: ApiResponse = yield call(api.fetchPersonalNote);
    yield put(myPerformerActions.fetchPersonalNotes.success(response.data));
  } catch (error) {
    yield put(myPerformerActions.fetchPersonalNotes.error(error.message));
  }
}

function* fetchUserJobStatus(state: { type: string; payload: IUserJobStatusParam }) {
  try {
    yield put(myPerformerActions.setUserJobStatus.pending);
    const response: ShowDayJob[] = yield call(api.fetchUserJobStatus, state.payload);
    yield put(myPerformerActions.setUserJobStatus.success(response));
  } catch (error) {
    yield put(myPerformerActions.setUserJobStatus.error(error.message));
  }
}

function* fetchPerformerUpdatedData(state: { type: string; payload: IPerformerSearchParam }) {
  try {
    const response: any = yield call(showApi.searchPerformer, state.payload);
    yield put(myPerformerActions.setPerformerUpdatedData(response));
    yield put(showActions.setPerformerUpdatedData(response));
  } catch (error) {
    console.log('Error', error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.FETCH_MY_PERFORMER_LIST_BEGIN, processMyPerformerList),
    takeEvery(ACTIONS.SET_CREATE_MY_PERFORMER_LIST_BEGIN, createListItem),
    takeEvery(ACTIONS.FETCH_PERFORMER_DETAIL_BEGIN, fetchPerformerDetails),
    takeEvery(ACTIONS.FETCH_PERFORMER_BOOKINGS_BEGIN, fetchPerformerBookings),
    takeEvery(ACTIONS.SET_ADD_PERFOMER_IN_MY_LIST_BEGIN, addPerformerInList),
    takeEvery(ACTIONS.FETCH_PERFORMER_PERSONAL_NOTE_BEGIN, fetchPersonalNote),
    takeEvery(ACTIONS.FETCH_MY_PERFORMER_IMAGES_BEGIN, fetchPerformerImages),
    takeEvery(ACTIONS.FETCH_USER_JOB_STATUS_BEGIN, fetchUserJobStatus),
    takeEvery(ACTIONS.FETCH_PERFORMER_UPDATED_DETAILS_BEGIN, fetchPerformerUpdatedData),
  ]);
}
