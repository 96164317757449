import { currencyFormat } from 'helpers/utils';
import moment from 'moment';
import React, { FunctionComponent } from 'react';

interface HistoryItemProps {
  item: any;
}
const HistoryItem: FunctionComponent<HistoryItemProps> = (props: HistoryItemProps) => {
  const { item } = props;
  return (
    <>
      <tr>
        <td>{item.subscriptionId.category}</td>
        <td>{item.subscriptionId.title}</td>
        <td>{currencyFormat(item.amount)}</td>
        <td>{moment(item.purchaseDate).format('DD-MM-YYYY')}</td>
      </tr>
    </>
  );
};

export default HistoryItem;
