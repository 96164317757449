import { connect } from 'react-redux';
import Matrix from 'components/Coordinator/Matrix/Matrix';
import {
  getMatrixData,
  getMatrixSearchSortingOrder,
  getSelectedShowId,
  getShowSelectedDates,
  isMatrixLoading,
  matrixPagination,
} from 'store/selector/show.selector';
import { Show } from 'modules/Show/show.types';
import {
  getMatrixDataBegin,
  searchExistingMatrix,
  setDatesForFitler,
  setMatrixUpdateTiming,
  sortMatrixData,
} from 'store/actions/show.actions';

const mapStateToProps = (state: Show) => {
  const dates = getShowSelectedDates(state);
  const showId = getSelectedShowId(state);
  const matrixData = getMatrixData(state);
  const loading = isMatrixLoading(state);
  const pagination = matrixPagination(state);
  const sortingOrder = getMatrixSearchSortingOrder(state);
  return { dates, showId, matrixData, loading, pagination, sortingOrder };
};

const mapDispatchToProps = {
  getMatrixDataBegin,
  searchExistingMatrix,
  sortMatrixData,
  setDatesForFitler,
  setMatrixUpdateTiming,
};

export default connect(mapStateToProps, mapDispatchToProps)(Matrix);
