import React, { FunctionComponent } from 'react';
import { ShowDayJob, ShowDayJobList } from 'modules/Show/show.types';
import Button from 'components/controls/button/button';
import Style from 'components/ShowJob/ShowRoleList.module.css';
import customStyle from 'assets/css/customStyle.module.css';
import plusSvg from 'assets/svg/plus.svg';
import reload from 'assets/svg/reload-black.svg';
import Loader from 'components/controls/Loader/Loader';
import { SHOW_JOB_STATUS } from 'store/castingPax.constants';

interface ShowJobListProps {
  showJobList: ShowDayJobList;
  setSelectedJob: Function;
  createEmptyShowJob: Function;
  selectedJob: ShowDayJob;
  loading: boolean;
  errorLoading: boolean;
  recallSelectedJobDate: Function;
}
const ShowJobList: FunctionComponent<ShowJobListProps> = (props: ShowJobListProps) => {
  const { showJobList, setSelectedJob, createEmptyShowJob, selectedJob, loading, errorLoading, recallSelectedJobDate } =
    props;

  const styleClass = (roleId: string) => (selectedJob && selectedJob.id === roleId ? Style.selectedRoleItem : '');

  const handleClickNew = () => {
    createEmptyShowJob();
  };
  const handleReload = () => recallSelectedJobDate();

  const getDraftTitle = (title: string) => (
    <span className={`position-relative ${Style.draftText}`} style={{ color: 'lightgrey', marginBottom: '0' }}>
      {title}
    </span>
  );

  return (
    <>
      {loading && (
        <div className={Style.listLoading}>
          <Loader isSmallView />
        </div>
      )}
      {errorLoading && (
        <span
          className={Style.listLoading}
          onClick={() => handleReload()}
          onKeyPress={() => handleReload()}
          role="button"
          tabIndex={0}
        >
          <img src={reload} alt="" className={Style.exclamationMark} />
        </span>
      )}
      {showJobList.map((obj: ShowDayJob) => (
        <div
          className={`${Style.roleItem} ${styleClass(obj.id)}`}
          key={obj.id}
          onClick={() => {
            setSelectedJob(obj);
          }}
          onKeyPress={() => {
            setSelectedJob(obj);
          }}
          role="button"
          tabIndex={0}
        >
          {obj.status === SHOW_JOB_STATUS.DRAFT ? getDraftTitle(obj.title) : obj.title}
        </div>
      ))}
      <div className={Style.newRoleButton}>
        <Button cssClass={customStyle.customBtnLabel} label="New" icon={plusSvg} clickHandler={handleClickNew} />
      </div>
    </>
  );
};

export default ShowJobList;
