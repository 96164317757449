import React from 'react';
import styled from 'styled-components';
import downloadLogo from '../../../../../../assets/svg/downloadBtn.svg';
// import exportLogo from '../../../../../../assets/svg/exportBtn.svg';

interface Props {}

const DocsComponent: React.FC<Props> = () => {
  const onButtonClick = () => {
    fetch('sample.docx').then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'Sample.docx';
        alink.click();
      });
    });
  };
  return (
    <DivContainer>
      <p>Docs</p>
      <IconWrapper>
        {/* <Image src={exportLogo} alt="" /> */}
        <Image src={downloadLogo} alt="" onClick={onButtonClick} />
      </IconWrapper>
    </DivContainer>
  );
};

export default DocsComponent;

const DivContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & p {
    margin-bottom: 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #828282;
  }
`;

const Image = styled.img`
  height: 16px;
  width: 16px;

  &:hover {
    cursor: pointer;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
