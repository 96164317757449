import React, { FunctionComponent } from 'react';
import PerformerProfileStyle from 'components/PerformerProfile/PerformerProfile.module.css';
import exportBorderedSvg from 'assets/svg/export-secondary-color.svg';
import parse from 'html-react-parser';

interface ProfileInfoProps {
  label: string;
  value?: string;
  cssClass?: string;
  isHTML?: string;
  title?: string;
  icon?: string;
  labelClass?: string;
  isRedirectLink?: boolean;
  handleRedirect?: Function;
}
const ProfileInfo: FunctionComponent<ProfileInfoProps> = (props: ProfileInfoProps) => {
  const { label, value, cssClass, isHTML, title, icon, labelClass, isRedirectLink, handleRedirect } = props;

  return (
    <div>
      <p className={`${cssClass} ${labelClass} ${PerformerProfileStyle.personalInfoTitle}`} title={title}>
        {label}
      </p>
      {isHTML ? (
        <p className={`${cssClass} ${PerformerProfileStyle.personalInfo}`}>{parse(isHTML || '')}</p>
      ) : (
        <p
          className={`${isRedirectLink && PerformerProfileStyle.linkName} ${cssClass} ${
            PerformerProfileStyle.personalInfo
          }`}
          title={title}
        >
          {icon && <img src={icon} alt="icon" className="mr-2" />}
          {value}
          {isRedirectLink && handleRedirect && (
            <span
              className="mb-0 ml-2"
              onClick={() => handleRedirect()}
              onKeyPress={() => handleRedirect()}
              role="button"
              tabIndex={0}
            >
              <img className="mb-1" style={{ width: '15px' }} src={exportBorderedSvg} alt="export icon" />
            </span>
          )}
        </p>
      )}
    </div>
  );
};

ProfileInfo.defaultProps = {
  value: '',
};

export default ProfileInfo;
