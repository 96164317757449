import { connect } from 'react-redux';
import { getResidencyDocsRequest, removeResidencyDocs, renameResidencyDocs } from 'store/actions/performer.actions';
import { getResidencyDocs, showLoading } from 'store/selector/performer.selector';
import Residency from './Residency';

const mapDispatchToProps = {
  docsRequest: getResidencyDocsRequest,
  removeDocs: removeResidencyDocs,
  renameDocs: renameResidencyDocs,
};

const mapStateToProps = (state: any) => {
  const loading = showLoading(state);
  const docs = getResidencyDocs(state);
  return {
    loading,
    docs,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Residency);
