import React, { FunctionComponent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import InputStyle from 'components/controls/input/input.module.css';
import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';
import { removeWhiteSpaces } from 'helpers/utils';

interface InputProps {
  name?: string;
  label: string;
  placeholder?: string;
  cssClass?: string;
  profileFieldMargin?: string;
  value?: string;
  type?: string;
  errorMessage?: string;
  handleChange?: Function;
  handleKeyDown?: Function;
  inputChangeHandler?: (event: React.FormEvent<HTMLInputElement>) => void;
  blurHandler?: (event: React.FormEvent<HTMLInputElement>) => void;
  maxLength?: number;
}
const InputField: FunctionComponent<InputProps> = (props: InputProps) => {
  const {
    name,
    label,
    placeholder,
    cssClass,
    value,
    type,
    handleChange,
    blurHandler,
    errorMessage,
    inputChangeHandler,
    handleKeyDown,
    profileFieldMargin,
    maxLength,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>(errorMessage || '');

  useEffect(() => {
    if (errorMessage !== undefined) setErrorMsg(errorMessage);
  }, [errorMessage]);

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (handleChange) handleChange(event.currentTarget.value);
  };

  const onBlurHandle = (event: React.FormEvent<HTMLInputElement>) => {
    const val: string = event.currentTarget.value;
    if (maxLength && val && val.length >= maxLength && blurHandler) {
      setErrorMsg('');
      blurHandler(event);
      return;
    }
    if (maxLength && val.length > 0) setErrorMsg(`must be ${maxLength} Character`);
    if (blurHandler) blurHandler(event);
  };

  return (
    <>
      <div className={`${InputStyle.inputWrapper} ${InputStyle.inputGroup} ${profileFieldMargin}`}>
        <input
          name={name || removeWhiteSpaces(label)}
          value={value}
          type={type && !showPassword ? type : 'text'}
          className={InputStyle.positionRelative + cssClass}
          required
          placeholder={placeholder}
          onChange={handleChange ? handleOnChange : inputChangeHandler}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (handleKeyDown) handleKeyDown(e);
          }}
          onBlur={onBlurHandle}
        />
        <span className={cssClass}>{label}</span>
        {type === 'password' && (
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={showPasswordHandler} />
        )}
        {errorMsg && <TextErrorMessage message={errorMsg} />}
      </div>
    </>
  );
};

export default InputField;
