import { connect } from 'react-redux';
import { PeformerJobDates, Show, ShowDayJob } from 'modules/Show/show.types';
import {
  getJobPostDates,
  getPerformerTab,
  getShowJob,
  isLoading,
  isRequestingPerformer,
} from 'store/selector/show.selector';
import ButtonSection from 'components/PerformerProfile/ButtonSection/ButtonSection';
import { getMyPeformerListOnly, getPerformerNotes } from 'store/selector/myPerformer.selector';
import { callAddPerformerInFavList, getMyPerformerListRequest } from 'store/actions/myPerformer.actions';
import { jobRequestFromCDBegin } from 'store/actions/job.actions';
import { PerformerNoteList } from 'modules/PerformerList/list.types';
import { acceptJobRequestBegin, cancelJobRequestBegin } from 'store/actions/show.actions';

const mapStateToProps = (state: Show) => {
  const loading: boolean = isLoading(state);
  const selectedJob: ShowDayJob = getShowJob(state);
  const myPerformerList = getMyPeformerListOnly(state);
  const performerNotes: PerformerNoteList = getPerformerNotes(state);
  const selectedJobDates: PeformerJobDates[] = getJobPostDates(state);
  const requestInprogress: string = isRequestingPerformer(state);
  const performerTab: string = getPerformerTab(state);
  return { loading, selectedJob, myPerformerList, performerNotes, selectedJobDates, requestInprogress, performerTab };
};

const mapDispatchToProps = {
  jobRequestFromCDBegin,
  callAddPerformerInFavList,
  getMyPerformerListRequest,
  acceptJobRequestBegin,
  cancelJobRequestBegin,
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonSection);
