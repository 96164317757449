/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Style from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import SideNavTabItem from './SideNavTabItem';
import { SideNavDataInterface } from '../Interface';
import Footer from '../Footer/Footer';
// import { title } from 'process';

interface SideNavInterface {
  showSideNav: Boolean;
  sideNavData: SideNavDataInterface;
  showChevron?: Boolean;
  showNavIcon?: Boolean;
}

const PayRollSideNav: FunctionComponent<SideNavInterface> = (props: SideNavInterface) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { showSideNav, sideNavData, showChevron, showNavIcon } = props;
  const [currentPath, setCurrentPath]: any = useState(null);
  const [previousPath, setPreviousPath]: any = useState(null);
  const location = useLocation();
  useEffect(() => {
    console.log(previousPath);
    if (location.pathname !== currentPath) {
      setPreviousPath(currentPath);
      setCurrentPath(location.pathname);
    }
  }, [location.pathname]);
  const handleSelect = (index: SetStateAction<number>) => {
    setSelectedIndex(index);
  };

  return (
    <SideNavContainer>
      <TabsContainer selectedIndex={selectedIndex} onSelect={handleSelect}>
        <TablistContainer showSideNav={showSideNav}>
          {sideNavData.SideNavMenuItems.map((item, index) => (
            <TabContainer
              key={index}
              // style={{
              //   pointerEvents:
              //     item.link === '/privacy' || item.link === '/help' || item.link === '/settings' ? 'none' : 'auto',
              // }}
            >
              <StyledLink to={item.link}>
                <SideNavTabItem
                  showNavIcon={showNavIcon}
                  showChevron={showChevron}
                  title={item.title}
                  icon={item.icon}
                  showSideNav={showSideNav}
                />
              </StyledLink>
            </TabContainer>
          ))}
        </TablistContainer>
        <StyledTabPanel forceRenderTabPanel showSideNav={showSideNav}>
          <sideNavData.Component showSideNav={showSideNav} />
          <Footer />
        </StyledTabPanel>
      </TabsContainer>
    </SideNavContainer>
  );
};

const SideNavContainer = Style.div`
  background-color: #F2F2F5;
  flex: 1;
`;
const TabsContainer = Style(Tabs)`
  display: flex;
  // height: 100%;
  // min-height: 100vh;
  position: relative;
`;
// width: ${(props: SideNavInterface) => (props.showSideNav === true ? '21%' : '5%')};
const TablistContainer = Style(TabList)`
margin: 0;
flex: 1;
width: ${(props: SideNavInterface) => (props.showSideNav === true ? '250px' : '64px')};
background-color: #fff;

@media only screen and (max-width: 991px) {
  position: absolute;
  height: 100%;
  z-index: 1;
  width: ${(props: SideNavInterface) => (props.showSideNav === true ? '250px' : '64px')};
}
`;

const StyledTabPanel = Style(TabPanel)`
// height: 100%;
margin: 0;
// width: 100%;
padding: 0 30px;
padding-top: 30px;
// overflow-y: hidden;
// overflow-x: hidden;
width: ${(props) => (props.showSideNav ? 'calc(100% - 250px)' : 'calc(100% - 64px)')};
        // width: calc(100% - 64px) !important;
// margin-left: ${(props) => (props.showSideNav ? '250px' : '64px')};

// @media only screen and (min-width: 1201px) {
//   width: calc(100% - 64px) !important;
// }
// @media only screen and (max-width: 1200px) {
//   width: calc(100% - 64px) !important;
// }
@media only screen and (max-width: 991px) {
width: ${(props) => (props.showSideNav ? 'calc(100% - 0px)' : 'calc(100% - 64px)')};
margin-left: ${(props) => (props.showSideNav ? '0px' : '64px')};
  padding: 0 15px;
  padding-top: 15px;
}
`;

StyledTabPanel.tabsRole = 'TabPanel';

const TabContainer = Style(Tab)`
  // background-color: #fff;
  background-color: red;
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledLink = Style(Link)`
text-decoration: none;
color: black;
&:hover {
  text-decoration: none;
  color: black;
}
`;

export default PayRollSideNav;
