import React, { FunctionComponent, useEffect, useState } from 'react';
import { BGPerformer, User } from 'modules/user/types';
import Style from 'views/bgperformer/profile/ProfileEdit/ProfileEdit.module.css';
import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';
import DropDownSearch from 'components/controls/select/DropDownSearch';
import {
  getWasitOption,
  getNeckOptions,
  getJacketSizeOptions,
  getInseamOptions,
  getSleeveOptions,
  NumberOptions,
} from 'helpers/data/SelectOptions';
import Button from 'components/controls/button/button';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';
import { LOADER_STATUS, UNIT_FORMAT } from 'store/castingPax.constants';
import { formatInUnit } from 'helpers/utils';

interface SizeCardEditProps {
  updateProfileBegin?: Function;
  user: User;
  authError?: string;
  isLoading?: boolean;
  closeModal: Function;
}
const SizeCard: FunctionComponent<SizeCardEditProps> = (props: SizeCardEditProps) => {
  const { updateProfileBegin, authError, user, isLoading, closeModal } = props;
  const userObj = user && user.id ? user : getItemFromLocalStorage('user');

  const [chest, setChest] = useState<string>(userObj.bgPerformer?.chest || '');
  const [eyeColor, setEyeColor] = useState(userObj.bgPerformer?.eyeColor || '');
  const [hair, setHair] = useState(userObj.bgPerformer?.hair || '');
  const [inseam, setInseam] = useState<string>(userObj.bgPerformer?.inseam || '');
  const [jacket, setJacket] = useState<string>(userObj.bgPerformer?.jacket || '');
  const [neck, setNeck] = useState<string>(userObj.bgPerformer?.neck || '');
  const [shoes, setShoes] = useState<string>(userObj.bgPerformer?.shoe || '');
  const [sleeve, setSleeve] = useState<string>(userObj.bgPerformer?.sleeve || '');
  const [waist, setWaist] = useState<string>(userObj.bgPerformer?.waist || '');
  const [date] = useState(userObj.date || '');
  const [email] = useState(userObj.email || '');

  const [dataupdate, setDataUpdate] = useState<number>(LOADER_STATUS.NA);

  useEffect(() => {
    if (isLoading !== true && dataupdate >= 0) closeModal();
  }, [isLoading]);

  const handleSubmitProfile = () => {
    if (isLoading) return;

    const performer = user.bgPerformer ? user.bgPerformer : { dateOfBirth: '', gender: '', height: '', weight: '' };
    const bgPerformer: BGPerformer = {
      dateOfBirth: performer.dateOfBirth,
      gender: performer.gender,
      height: performer.height,
      weight: performer.weight,
      id: user.bgPerformer ? user.bgPerformer.id : '',
      chest,
      eyeColor,
      hair,
      inseam,
      jacket,
      neck,
      shoe: shoes,
      sleeve,
      waist,
    };
    const userPayload: User = {
      firstName: user.firstName,
      lastName: user.lastName,
      name: user.name,
      id: user.id,
      aboutUs: user.aboutUs,
      accountReason: user.accountReason,
      bgPerformer,
      date,
      email,
      address: user.address,
      phoneNo: user.phoneNo,
      isCompleted: true,
      isProfilePublic: true,
      role: userObj.role,
    };
    if (updateProfileBegin) {
      updateProfileBegin(userPayload);
    }
    setDataUpdate(LOADER_STATUS.COMPLETED);
  };

  return (
    <>
      <div className={`${Style.formWrapper}`}>
        {authError && <TextErrorMessage message={authError} />}
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Hair color</span>
          </div>
          <div className="col-sm-7">
            <DropDownSearch
              label="Hair"
              value={hair}
              profileFieldMargin={Style.fieldBottomMargin}
              cssClass={Style.createInputLabel}
              optionType="hair"
              defaultValue="select"
              handleSelect={setHair}
            />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Eye color</span>
          </div>
          <div className="col-sm-7">
            <DropDownSearch
              label=""
              value={eyeColor}
              profileFieldMargin={Style.fieldBottomMargin}
              cssClass={Style.createInputLabel}
              optionType="eyes"
              defaultValue="select"
              handleSelect={setEyeColor}
            />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Chest</span>
          </div>
          <div className="col-sm-7">
            <DropDownSearch
              label=""
              value={formatInUnit(chest, UNIT_FORMAT.INCH)}
              profileFieldMargin={Style.fieldBottomMargin}
              cssClass={Style.createInputLabel}
              data={NumberOptions(15, 80)}
              defaultValue="select"
              handleSelect={setChest}
            />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Waist</span>
          </div>
          <div className="col-sm-7">
            <DropDownSearch
              label=""
              value={formatInUnit(waist, UNIT_FORMAT.INCH)}
              profileFieldMargin={Style.fieldBottomMargin}
              cssClass={Style.createInputLabel}
              data={getWasitOption()}
              defaultValue="select"
              handleSelect={setWaist}
            />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Neck</span>
          </div>
          <div className="col-sm-7">
            <DropDownSearch
              label=""
              value={formatInUnit(neck, UNIT_FORMAT.INCH)}
              profileFieldMargin={Style.fieldBottomMargin}
              cssClass={Style.createInputLabel}
              data={getNeckOptions()}
              defaultValue="select"
              handleSelect={setNeck}
            />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Jacket</span>
          </div>
          <div className="col-sm-7">
            <DropDownSearch
              label=""
              value={jacket}
              profileFieldMargin={Style.fieldBottomMargin}
              cssClass={Style.createInputLabel}
              data={getJacketSizeOptions()}
              defaultValue="select"
              handleSelect={setJacket}
            />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Inseam</span>
          </div>
          <div className="col-sm-7">
            <DropDownSearch
              label=""
              value={formatInUnit(inseam, UNIT_FORMAT.INCH)}
              profileFieldMargin={Style.fieldBottomMargin}
              cssClass={Style.createInputLabel}
              data={getInseamOptions()}
              defaultValue="select"
              handleSelect={setInseam}
            />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Sleeve</span>
          </div>
          <div className="col-sm-7">
            <DropDownSearch
              label=""
              value={formatInUnit(sleeve, UNIT_FORMAT.INCH)}
              profileFieldMargin={Style.fieldBottomMargin}
              cssClass={Style.createInputLabel}
              data={getSleeveOptions()}
              defaultValue="select"
              handleSelect={setSleeve}
            />
          </div>
        </div>
        <div className="row profileCreation align-items-center mb-3">
          <div className="col-sm-5">
            <span className="mb-0">Shoe</span>
          </div>
          <div className="col-sm-7">
            <DropDownSearch
              label=""
              value={shoes}
              profileFieldMargin={Style.fieldBottomMargin}
              cssClass={Style.createInputLabel}
              data={NumberOptions(5, 15)}
              defaultValue="select"
              handleSelect={setShoes}
            />
          </div>
        </div>
        <div className="text-center mt-4">
          <div className="row">
            <div className="col-sm-12 w-100">
              <Button
                label="Update"
                cssClass="fontFourteen font-weight-normal login-btn w-100"
                showLoading={isLoading}
                clickHandler={handleSubmitProfile}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SizeCard;
