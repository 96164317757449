import React from 'react';
import styled from 'styled-components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTable } from '@fortawesome/free-solid-svg-icons';

interface Props {}

const CheckControls: React.FC<Props> = (props: any) => {
  const { value, valueFormated } = props;
  const checkboxValue = valueFormated || value;

  console.log(checkboxValue, 'checkbox');

  return (
    <Container>
      <StyledCheckbox type="checkbox" />
    </Container>
  );
};

export default CheckControls;

const StyledCheckbox = styled.input`
  height: 100%;
  width: 14px;
  cursor: pointer;
`;

const Container = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
