import CPModal from 'components/Modal/CPModal';
import { MessageBoxItem } from 'modules/general/general.type';
import { BOOLEAN_TRUE } from 'modules/user/types';
import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callLogout } from 'store/actions/auth.actions';
import { clearMessageBox } from 'store/actions/general.actions';
import { MESSAGE_BOX_TYPE } from 'store/castingPax.constants';
import { getMessageBoxObj } from 'store/selector/general.selector';

const MessageBox: FunctionComponent = () => {
  const dispatch = useDispatch();
  const message: MessageBoxItem = useSelector(getMessageBoxObj);

  const handleCloseModal = () => {
    if (message.type === MESSAGE_BOX_TYPE.SUCCESS_EXPIRE) {
      dispatch(callLogout());
    }
    dispatch(clearMessageBox());
  };

  if (message && Object.keys(message).length > 0) {
    return (
      <CPModal
        isShow={Object.keys(message).length > 0}
        isOkOnly={BOOLEAN_TRUE}
        hideModal={handleCloseModal}
        title={message.title}
      >
        <div>{message.message}</div>
      </CPModal>
    );
  }
  return <></>;
};

export default MessageBox;
