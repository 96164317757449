import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import StyledMenuTab from '../styles/filter/MenuTab.styled';
import DownArrow from '../../common/DownArrow';

interface Props {
  borderRadiusRight?: boolean;
  reOrder?: Function;
}

const MenuTab: React.FC<Props> = (props: Props) => {
  const { borderRadiusRight, reOrder } = props;
  const handleReOrder = (num: number) => {
    if (reOrder) reOrder(num);
  };
  return (
    <StyledMenuTab borderRadiusRight={borderRadiusRight}>
      <FontAwesomeIcon icon={faBars} />
      {/* <FontAwesomeIcon icon={faAngleDown} />
      <FontAwesomeIcon icon={faAngleUp} />  */}
      {reOrder ? (
        <>
          <div onClick={() => handleReOrder(1)} onKeyPress={() => handleReOrder(1)} role="button" tabIndex={0}>
            <DownArrow toggleIcon={false} />
          </div>
          <div onClick={() => handleReOrder(-1)} onKeyPress={() => handleReOrder(-1)} role="button" tabIndex={0}>
            <DownArrow toggleIcon />
          </div>
        </>
      ) : (
        <>
          <DownArrow toggleIcon={false} />
          <DownArrow toggleIcon />
        </>
      )}
    </StyledMenuTab>
  );
};
export default MenuTab;
