import { connect } from 'react-redux';
import Booking from 'containers/bgPerformer/Booking/Booking';
import { Show } from 'modules/Show/show.types';
import { getPendingBooking, getConfirmedBooking, getJobEvents } from 'store/selector/job.selector';
import { updateCalendarStatusBegin, updateCalendarStatusLocal } from 'store/actions/job.actions';
import { getUser } from 'store/selector/auth.selector';

const mapStateToProps = (state: Show) => {
  const pendingBooking = getPendingBooking(state);
  const confirmedBooking = getConfirmedBooking(state);
  const eventList = getJobEvents(state);
  const user = getUser(state);
  return {
    pendingBooking,
    confirmedBooking,
    eventList,
    user,
  };
};

const mapDispatchToProps = {
  updateCalendarStatusBegin,
  updateCalendarStatusLocal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
