import React, { FunctionComponent } from 'react';
import PerformerFullProfile from 'components/PerformerProfile/PerformerFullProfile';
import { User, UserList } from 'modules/user/types';
import reloadSvg from 'assets/svg/reload.svg';
import PerformerProfile from 'components/PerformerProfile/PerformerProfile';
import { Pagination } from 'modules/jobs/types';
import Button from 'components/controls/button/button';
import Loader from 'components/controls/Loader/Loader';

interface PerformerSearchResultProps {
  isLoading?: boolean;
  performerResult?: UserList;

  isFullView?: boolean;
  pagination: Pagination;
  loadMoreBgPerformer: Function;
}
const BgSearchResultContainer: FunctionComponent<PerformerSearchResultProps> = (props: PerformerSearchResultProps) => {
  const { performerResult, isFullView, isLoading, pagination, loadMoreBgPerformer } = props;

  const showListing = () => {
    if (performerResult && performerResult.length > 0) {
      if (isFullView) {
        return (
          <>
            {performerResult.map((profile: User) => (
              <PerformerFullProfile key={profile.id} profile={profile} selectedJobDates={[]} />
            ))}
          </>
        );
      }
      return (
        <div className="d-flex justify-content-between flex-wrap">
          {performerResult.map((profile: User) => (
            <PerformerProfile key={profile.id} profile={profile} selectedJobDates={[]} />
          ))}
        </div>
      );
    }
    return <></>;
  };

  const handleLoadMore = () => loadMoreBgPerformer();

  return (
    <>
      {isLoading && <Loader isSmallView />}
      {showListing()}
      {pagination && pagination.totalPages > 1 && pagination.page < pagination.totalPages && (
        <div className=" text-center w-100">
          {performerResult && performerResult.length ? (
            <Button
              type="secondary"
              icon={reloadSvg}
              label="Load more"
              clickHandler={handleLoadMore}
              showLoading={isLoading}
            />
          ) : (
            ''
          )}
        </div>
      )}
    </>
  );
};

BgSearchResultContainer.defaultProps = {
  isLoading: false,
};

export default BgSearchResultContainer;
