import Header from 'components/topHeader/topHeader';
import React, { FunctionComponent } from 'react';

const TermsAndConditions: FunctionComponent = () => (
  <>
    <Header />
    <div style={{ marginLeft: '15%', marginRight: '15%' }}>
      <br />
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '20pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Terms And Condition
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          CastingPAX is an online service and mobile platform for connecting potential film and tv members with work
          acting as a hiring service. When using our services you receive them as an “as is” basis. While CastingPAX
          focuses on background casting services for simplifying background casting services and also for the background
          performer.&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          These Terms of Service (“Terms”, “Terms of Service”) govern your relationship with www.castingpax.com website
          and CastingPAX web app / application’s (the “Service”) operated by BCPAX production services ltd. (operating
          CastingPAX.com services / site) &nbsp;(“us”, “we”, or “our”).
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Please read these Terms of Service carefully before using our website and CastingPAX &nbsp;technology services
          (the “Service”).
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Your access to and use of the Service is based on your acceptance of and compliance with these Terms. These
          Terms apply to all visitors, users and others who access or use the Service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          By accessing or using the Service you agree to be bound by these Terms and accept all legal consequences. If
          you do not agree to these terms and services, in whole or in part, please do not use the Service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Basic Terms and Posting Guidelines
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'decimal',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              You must be a minimum age of 18 years of age. (if you are under this age you must have permission and
              supervision of your parental guardian’s consent &amp; permission)&nbsp;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'decimal',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              You may not post nude, partially nude, or sexually suggestive photos. (if casting specifically asks for
              photos, this can not be done over our service and encourage you to seek your performers union guidelines
              before proceeding.&nbsp;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'decimal',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Any activity that occurs under your name (or stage name / name your account is affiliated with) and posted
              under your account you are liable for.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'decimal',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Your job is to keep your password safe.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'decimal',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              You must not abuse, harass, threaten, lead on, impersonate or intimidate other CastingPAX users.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'decimal',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              You are solely responsible for any content that is posted and actions of others that occur from your
              content under your account with comments, posts, and any data submitted.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'decimal',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              You must not modify, adapt, access or hack CastingPAX in any way or release viruses.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'decimal',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              You must not impersonate CastingPAX, or our identity, url in any way unless with written permission.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'decimal',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              You may not use CastingPAX and services for any illegal or unauthorized purpose. International users agree
              to comply with all local laws regarding to online conduct and acceptable content. (including copyright
              laws)
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'decimal',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              You may not create, post or submit any unwanted content to other CastingPAX users like “spam”.
            </span>
          </p>
        </li>
      </ol>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Purchases
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You don’t own these products: instead you buy a limited revocable license to use them. All in-app /online and
          online sales are final and non-refundable. Purchases refers to future intended content, and advertisements,
          boosted posts and company/organizations/users that join or purchase from CastingPAX.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          If you wish to purchase any product or service made available through the Service (“Purchase”), you may be
          asked to supply certain information relevant to your Purchase including, without limitation, your credit card
          number, the expiration date of your credit card, your billing address, and your shipping information.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment
          method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and
          complete.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          By submitting such information, you grant us the right to provide the information to third parties for
          purposes of facilitating the completion of Purchases.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We reserve the right to refuse or cancel your order at any time for certain reasons including but not limited
          to: product or service availability, errors in the description or price of the product or service, error in
          your order or other reasons.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is
          suspected. We will not be held responsible or liable for any failure for the Purchase to complete, or any
          resulting loss or damages to you.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Availability, Errors and Inaccuracies
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          In order to provide exceptional service, and accuracy, we regularly update the products and services on the
          Service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We cannot and do not guarantee the accuracy or completeness of any information, including prices, product
          images, specifications, availability, and services. We reserve the right to change or update information and
          to correct errors, inaccuracies, or omissions at any time without prior notice.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Despite our best efforts, the products or services available on our Service may have an error regarding the
          price, be inaccurately described, or be unavailable.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We may experience delays in updating information on the Service and in our advertising on other websites.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Contests, Sweepstakes and Promotions
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Any contests, sweepstakes or other promotions (collectively, “Promotions”) made available through the Service
          may be governed by rules that are separate from these Terms. If you participate in any Promotions, please
          review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these
          Terms, the Promotion rules will apply.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Promotions may be available for a limited time.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Content
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Our Service allows you to post, link, store, share and otherwise make available certain information, text,
          graphics, videos, or other material (“Content”). You are responsible for the Content that you post to the
          Service, including its legality, reliability, and appropriateness.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          By posting Content to the Service, you grant us the right and license to use, modify, publicly perform,
          publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of
          your rights to any Content you submit, post or display on or through the Service and you are responsible for
          protecting those rights. But you grant CastingPAX a worldwide, perpetual, royalty-free, sub licensable, and
          transferable license for all content and date you publish/post on CastingPAX for the whole public and for
          future service too.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You agree that this license includes the right for us to make your Content available to other users of the
          Service, who may also use your Content subject to these Terms. You also grant CastingPAX the unrestricted
          worldwide, perpetual right and license to use your personal name or business with our service and future
          service and do not offer any compensation.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and
          grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through
          the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other
          rights of any person.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We reserve all rights to block or remove communications or materials that we determine to be: (a) abusive,
          defamatory, or obscene; (b) fraudulent, deceptive, or misleading; (c) in violation of a copyright, trademark
          or, other intellectual property right of another or; (d) offensive or otherwise unacceptable to us in our sole
          discretion.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You acknowledge that, by providing you with the ability to view and distribute user-generated content on the
          Service, we are merely acting as a passive conduit for such distribution and is not undertaking any obligation
          or liability relating to any contents or activities on the Service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You should not rely on the accuracy of any part of the content. You accept and acknowledge we shall not in any
          way be liable for any damage, loss, costs or expenses arising directly or indirectly from your reliance on or
          use of any of the Content for any purpose.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          The Content of Others
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Much of the content on our service is produced by users, publishers and other third parties. &nbsp;Weather
          content is private or public, the content is the sole responsibility of the person or organization that
          created it. &nbsp;We reserve the right to review all content that appears on our service, and make it our
          clear purpose that our brand image, and service stands to its highest identity and not put to any bad uses.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Privacy
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Your protection and safety of your privacy is our highest concern in accordance to the structure of our
          service. Our full privacy policy referred to in this document and on the service is found on our Privacy
          Policy page online. Please review our Privacy Policy be accepting all the terms.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Accounts
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          When you create an account with us, you must provide us information that is accurate, complete, and current at
          all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of
          your account on our Service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You are responsible for safeguarding the password that you use to access the Service and for any activities or
          actions under your password, whether your password is with our Service or a third-party service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware
          of any breach of security or unauthorized use of your account.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You may not use as a username the name of another person or entity or that is not lawfully available for use,
          a name or trade mark that is subject to any rights of another person or entity other than you without
          appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Your License
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Your license we grant you is for the sole purpose of the user singularly per a singular account.
          &nbsp;Accounts created by business and organizations may be put through different sign up process in the
          future. CastingPAX grants you a personal, worldwide, royalty-free, non-assignable, nonexclusive, revocable and
          non-sub licensable license to access and use the Services and future services.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Responsibility
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You are responsible for any information, opinions, messages, comments, photos, videos, location, posts, sounds
          and other content or material that you submit, upload, post or otherwise make available on or through
          CastingPAX. &nbsp;We reserve the right to retrain your Submissions, even after they have have expired from the
          view within the service or even after you have deleted them. However, you agree that we have no obligation to
          retain any submission for any person of time also, or are we liable for any loss of content.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Copyright Policy
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We respect the intellectual property rights of others. It is our policy to respond to any claim that Content
          posted on the Service infringes the copyright or other intellectual property infringement (“Infringement”) of
          any person.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has
          been copied in a way that constitutes copyright infringement that is taking place through the Service, you
          must submit your notice in writing to the attention of “Copyright Infringement” of Please contact us at
          support@CastingPAX.com and include in your notice a detailed description of the alleged Infringement.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You may be held accountable for damages (including costs and attorneys’ fees) for misrepresenting that any
          Content is infringing your copyright.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Subject to these terms, CastingPAX hereby grants you a limited, non-exclusive, non-sub licensable,
          non-transferable and revocable license to use and display the material/content and to use the services solely
          for your personal, non commercial use. Except for the foregoing license, you have no other rights in the
          Services or any material and your may not modify, edit, copy, reproduce, create derivate works of, reverse
          engineer, alter, enhance or in any way explore any of the services or materials in any manner.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Intellectual Property
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          The Service and all contents, including but not limited to text, images, graphics or code are the property of
          CastingPAX and are protected by copyright, trademarks, database and other intellectual property rights. You
          may display and copy, download or print portions of the material from the different areas of the Service only
          for your own non-commercial use, or to place an order with CastingPAX. Any other use is strictly prohibited
          and may violate copyright, trademark and other laws. These Terms do not grant you a license to use any
          trademark of CastingPAX or its affiliates. You further agree not to use, change or delete any proprietary
          notices from materials downloaded from the Service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Any comments, suggestion, survey answers or related material regarding CastingPAX we take as non-confidential.
          You assign all right, title, and interest in CastingPAX to be used freely by CastingPAX with no compensation
          or any obligation for CastingPAX to reply to you.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Links To Other Web Sites
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          The Service may contain links to third-party web sites or services that are not owned or controlled by
          CastingPAX.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          CastingPAX has no control over, and assumes no responsibility for, the content, privacy policies, or practices
          of any third party web sites or services. You further acknowledge and agree that CastingPAX shall not be
          responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in
          connection with use of or reliance on any such content, goods or services available on or through any such
          websites or services.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We strongly advise you to read the terms and services and privacy policies of any third-party web sites or
          services that you visit.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Termination
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We may terminate or suspend access to our Service immediately, without prior notice or liability, for any
          reason whatsoever, including, without limitation, if you breach the Terms.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          All provisions of the Terms shall survive termination, including, without limitation, ownership provisions,
          warranty disclaimers, indemnity and limitations of liability.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account,
          you may simply discontinue using the Service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Data Charges and Mobile Phones
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You are responsible for any mobile charges that you may incur for using our services, including text messages
          and data charges. If you change your mobile phone number, email or contact info, you must update us with your
          newest information immediately.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Indemnification
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You agree to indemnify, defend and hold harmless CastingPAX, its principals, officers, directors,
          representatives, employees, contractors, licensors, licensees, suppliers and agents, from and against any
          claims, losses, damages, losses, obligations, costs, actions or demands.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          These include but are not limited to: (a) legal and accounting fees resulting from your use of the Service;
          (b) your breach of any of these Terms; (c) anything you post on or upload to the Service; and (d) any activity
          related to your account. This includes any negligent or illegal conduct by you, any person or entity accessing
          the Service using your account whether such access is obtained via fraudulent or illegal means.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Limitation Of Liability
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          CastingPAX, its directors, employees, partners, agents, suppliers, or affiliates, shall not be liable for any
          loss or damage, direct or indirect, incidental, special, consequential or punitive damages, including without
          limitation, economic loss, loss or damage to electronic media or data, goodwill, or other intangible losses,
          resulting from (i) your access to or use of the Service; (ii) your inability to access or use the Service;
          (iii) any conduct or content of any third-party on or related to the Service; (iiv) any content obtained from
          or through the Service; and (v) the unauthorized access to, use of or alteration of your transmissions or
          content, whether based on warranty, contract, tort (including negligence) or any other claim in law, whether
          or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found
          to have failed of its essential purpose.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Disclaimer And Non-Waiver of Rights
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          CastingPAX makes no guarantees, representations or warranties of any kind as regards the website and
          associated technology. Any purportedly applicable warranties, terms of service are excluded, to the fullest
          extent permitted by law. Your use of the Service is at your sole risk. The Service is provided on an “AS IS”
          and “AS AVAILABLE” basis. CastingPAX hopes all job posts are legit, but we assume the user will do their due
          diligence and protect themselves from physical or potential financial harm by engaging other users job
          applications, posts or applicants proposals. The Service is provided without warranties of any kind, whether
          express or implied, including, but not limited to, implied warranties of merchantability, fitness for a
          particular purpose, non-infringement or course of performance, except as provided for under the laws of any
          province in Canada. In such cases, the provincial law shall apply to the extent necessary.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          CastingPAX and its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will
          function uninterrupted, secure or available at any particular time or location; b) any errors or defects will
          be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the
          Service will meet your requirements.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          If you breach any of these Terms and CastingPAX chooses not to immediately act, or chooses not to act at all,
          CastingPAX will still be entitled to all rights and remedies at any later date, or in any other situation,
          where you breach these Terms. CastingPAX does not waive any of its rights. CastingPAX shall not be responsible
          for any purported breach of these Terms caused by circumstances beyond its control. A person who is not a
          party to these Terms shall have no rights of enforcement.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You may not assign, sub-license or otherwise transfer any of your rights under these Terms.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Exclusions
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          As set out, above, some jurisdictions do not allow the exclusion of certain warranties or the exclusion or
          limitation of liability for consequential or incidental damages, so the limitations above may not apply to
          you. Provincial laws of Canada may apply to certain products and service provided.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          The services may not be available in all locations, and we may block access to the Services from certain
          locations based on your devices geolocation information. We may add or remove at any time the services in a
          specific location without notice.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We may, in our sole discretion, refuse to offer the Services to any person or entity. We may, without notice
          and in our sole description terminate your right to use the Services, or any portion thereof, and block your
          future access.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Governing Law
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          These Terms shall be governed by, and interpreted and enforced in accordance with, the laws in the Province of
          British Columbia and the laws of Canada, as applicable.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          If any provision of these Terms is held to be invalid or unenforceable by a court of competent jurisdiction,
          then any remaining provisions of these Terms will remain in effect. These Terms constitute the entire
          agreement between us regarding our Service, and supersede and replace any prior agreements, oral or otherwise,
          regarding the Service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You understand and agree that by entering into this Agreement you are waiving the right to a jury trial or a
          trail before a judge in a public court. Any normal rights due to the situation which you would normally have
          right to more discovery may be more limited or may also be waived.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Changes &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is
          material we will make reasonable efforts to provide at least 15 days’ notice prior to any new terms taking
          effect. What constitutes a material change will be determined at our sole discretion.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          By continuing to access or use our Service after those revisions become effective, you agree to be bound by
          the revised terms. If you do not agree to the new terms, in whole or in part, please stop using the website
          and the Service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Contact Us
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          If you have any questions about this Terms of Service, please contact us at&nbsp;
        </span>
        <a href="http://scouttheapp.com/contact-us/" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '12pt',
              fontFamily: 'Arial',
              color: '#111111',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            support@castingpax.com
          </span>
        </a>
      </p>
    </div>
  </>
);

export default TermsAndConditions;
