import React, { FunctionComponent, useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import Style from 'components/ProfileMenu/VoucherInfo/VoucherInfo.module.css';
import Input from 'components/controls/input/dashboardInput/dashboardInput';
import Button from 'components/controls/button/button';
import CheckBox from 'components/controls/checkbox/checkBox';
import saveSvg from 'assets/svg/save.svg';
import { User } from 'modules/user/types';
import { PerformerVoucher, Options } from 'modules/general/general.type';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';
import DropDownSearch from 'components/controls/select/DropDownSearch';
import locationSvg from 'assets/svg/location.svg';

interface VoucherInterface {
  saveVoucherInfo: Function;
  getVoucherInfo: Function;
  loading: boolean;
  voucherList: PerformerVoucher;
}

const Voucher: FunctionComponent<VoucherInterface> = (props: VoucherInterface) => {
  const { saveVoucherInfo, loading, getVoucherInfo, voucherList } = props;
  const user: User = getItemFromLocalStorage('user');
  const [performerId] = useState(user.id);
  const [name, setName] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [apartmentNumber, setApartmentNumber] = useState<number>();
  const [address, setAddress] = useState('');
  const [state, setState] = useState('');
  const [location, setLocation] = useState<Options>();
  const [zipCode, setZipCode] = useState<number>();
  const [city, setCity] = useState('');
  const [sin, setSIN] = useState('');
  const [gst, setGST] = useState('');
  const [union, setUnion] = useState('');
  const [nonUnion, setNonUnion] = useState(false);
  const [fullUnion, setFullUnion] = useState(false);
  const [apprentice, setApprentice] = useState(false);
  const [extraUnion, setExtraUnion] = useState(false);
  const saveHandle = () => {
    const locationID = location && typeof location === 'string' ? location : location?.id;
    const obj = {
      performerId,
      name,
      street,
      number,
      apartmentNumber,
      address,
      state,
      zipCode,
      city,
      sin,
      gst,
      union,
      nonUnion,
      fullUnion,
      apprentice,
      extraUnion,
      location: locationID,
    };
    saveVoucherInfo(obj);
  };

  useEffect(() => {
    if (Object.keys('performerId').length > 0) {
      setGST(voucherList.gst);
      setSIN(voucherList.sin);
      setUnion(voucherList.union);
      setNonUnion(voucherList.nonUnion);
      setFullUnion(voucherList.fullUnion);
      setApprentice(voucherList.apprentice);
      setExtraUnion(voucherList.extraUnion);
      setName(voucherList.name);
      setAddress(voucherList.address);
      setStreet(voucherList.street);
      setState(voucherList.state);
      setCity(voucherList.city);
      setZipCode(voucherList.zipCode);
      setLocation(voucherList.location);
      setApartmentNumber(voucherList.apartmentNumber);
    }
  }, [voucherList]);

  useEffect(() => {
    getVoucherInfo({
      performerId: user.id,
    });
  }, []);

  return (
    <>
      <div className={Style.mainWrapper}>
        {/* <div className={`d-flex justify-content-between ${Style.inputFields}`}> */}
        {/*  <Input */}
        {/*    type="text" */}
        {/*    cssClass={Style.input} */}
        {/*    inputLabel={Style.voucherInputLabel} */}
        {/*    label="Name" */}
        {/*    handleChange={setName} */}
        {/*  /> */}
        {/*  <Input */}
        {/*    type="text" */}
        {/*    cssClass={Style.input} */}
        {/*    inputLabel={Style.voucherInputLabel} */}
        {/*    label="Street number & name" */}
        {/*    handleChange={setStreet} */}
        {/*  /> */}
        {/* </div> */}
        {/* <div className={`d-flex justify-content-between ${Style.inputFields}`}> */}
        {/*  <Input */}
        {/*    type="text" */}
        {/*    cssClass={Style.input} */}
        {/*    inputLabel={Style.voucherInputLabel} */}
        {/*    label="Number" */}
        {/*    handleChange={setNumber} */}
        {/*  /> */}
        {/*  <Input */}
        {/*    type="text" */}
        {/*    cssClass={Style.input} */}
        {/*    inputLabel={Style.voucherInputLabel} */}
        {/*    label="Apartment number" */}
        {/*    handleChange={setApartmentNumber} */}
        {/*  /> */}
        {/* </div> */}
        {/* <div className={`d-flex justify-content-between ${Style.inputFields}`}> */}
        {/*  <Input */}
        {/*    type="text" */}
        {/*    cssClass={Style.input} */}
        {/*    inputLabel={Style.voucherInputLabel} */}
        {/*    label="Address" */}
        {/*    handleChange={setAddress} */}
        {/*  /> */}
        {/*  <Input */}
        {/*    type="text" */}
        {/*    cssClass={Style.input} */}
        {/*    inputLabel={Style.voucherInputLabel} */}
        {/*    label="State" */}
        {/*    handleChange={setState} */}
        {/*  /> */}
        {/* </div> */}
        {/* <div className={`d-flex justify-content-between ${Style.inputFields}`}> */}
        {/*  <Input */}
        {/*    type="text" */}
        {/*    cssClass={Style.input} */}
        {/*    inputLabel={Style.voucherInputLabel} */}
        {/*    label="Zip Code" */}
        {/*    handleChange={setZipCode} */}
        {/*  /> */}
        {/* </div> */}
        {/* <div className={`${Style.inputFields}`}> */}
        {/*  <Input */}
        {/*    type="text" */}
        {/*    cssClass={Style.input} */}
        {/*    inputLabel={Style.voucherInputLabel} */}
        {/*    label="City" */}
        {/*    handleChange={setCity} */}
        {/*  /> */}
        {/* </div> */}
        <div className={`d-flex justify-content-between flex-wrap ${Style.inputFields}`}>
          <Input
            type="text"
            cssClass={Style.input}
            inputLabel={Style.voucherInputLabel}
            label="Legal Name"
            handleChange={setName}
            value={name}
          />
          <div className={`performerSearchWrapper   col-12 pr-0  col-sm-6 requiredDropDown ${Style.locationSearch}`}>
            <span>
              <img src={locationSvg} alt="location svg" />
              &nbsp; Shooting Region
            </span>
            <DropDownSearch
              value={location ? location.label : 'select'}
              storeObject
              cssClass={Style.voucherInputLabel}
              optionType="location"
              handleSelect={setLocation}
            />
          </div>
          <div className={`d-flex w-100 justify-content-between flex-wrap ${Style.inputFields}`}>
            <Input
              type="text"
              cssClass={Style.input}
              inputLabel={Style.voucherInputLabel}
              label="Address"
              handleChange={setAddress}
              value={address}
            />
            <Input
              type="text"
              cssClass={Style.input}
              inputLabel={Style.voucherInputLabel}
              label="Street"
              handleChange={setStreet}
              value={street}
            />
          </div>
          <div className={`d-flex w-100 justify-content-between flex-wrap ${Style.inputFields}`}>
            <Input
              type="text"
              cssClass={Style.input}
              inputLabel={Style.voucherInputLabel}
              label="City"
              handleChange={setCity}
              value={city}
            />
            <Input
              type="text"
              cssClass={Style.input}
              inputLabel={Style.voucherInputLabel}
              label="Zip Code"
              handleChange={setZipCode}
              value={zipCode}
            />
          </div>
          <div className={`d-flex w-100 justify-content-between flex-wrap ${Style.inputFields}`}>
            <Input
              type="text"
              cssClass={Style.input}
              inputLabel={Style.voucherInputLabel}
              label="State"
              handleChange={setState}
              value={state}
            />
            <Input
              type="text"
              cssClass={Style.input}
              inputLabel={Style.voucherInputLabel}
              label="SIN#"
              handleChange={setSIN}
              value={sin}
            />
          </div>
          <div className={`d-flex w-100 justify-content-between flex-wrap ${Style.inputFields}`}>
            <Input
              type="text"
              cssClass={Style.input}
              inputLabel={Style.voucherInputLabel}
              label="GST#"
              handleChange={setGST}
              value={gst}
            />
            <Input
              type="text"
              cssClass={Style.input}
              inputLabel={Style.voucherInputLabel}
              label="UNION #"
              handleChange={setUnion}
              value={union}
            />
          </div>
        </div>
        <div className={`d-flex justify-content-between ${Style.inputFields}`}>
          <div className={`${Style.input} ${Style.checkboxWrapper}`}>
            <div className={`voucherInfoCheckbox ${Style.voucherCheckboxes}`}>
              <CheckBox
                label="Non Union"
                handleChecked={(e: any) => setNonUnion(e)}
                id="nonunion"
                defaultCheck={nonUnion}
              />
              <CheckBox
                label="Full union"
                handleChecked={(e: any) => setFullUnion(e)}
                id="fullunion"
                defaultCheck={fullUnion}
              />
              <CheckBox
                label="Apprentice"
                handleChecked={(e: any) => setApprentice(e)}
                id="apprentice"
                defaultCheck={apprentice}
              />
              <CheckBox
                label="Extra union"
                handleChecked={(e: any) => setExtraUnion(e)}
                id="extraunion"
                defaultCheck={extraUnion}
              />
            </div>
          </div>
        </div>
        <div className={`text-center ${Style.saveButtonWrapper}`}>
          <Button
            icon={saveSvg}
            clickHandler={saveHandle}
            label="Save"
            showLoading={loading}
            cssClass={Style.saveButton}
          />
        </div>
      </div>
    </>
  );
};

export default Voucher;
