import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import Style from 'components/castingDirector/Matrix.module.css';
import reloadSvg from 'assets/svg/reload_secondary.svg';
import callSheetSvg from 'assets/svg/call-sheet.svg';
import crewCallSvg from 'assets/svg/crew-call-new.svg';
import breakdownSvg from 'assets/svg/breakdown.svg';
import bgSizeCardSvg from 'assets/svg/bg-size-card.svg';
import statusSvg from 'assets/svg/status.svg';
import shareSvg from 'assets/svg/share.svg';
import plusSvg from 'assets/svg/plus.svg';
import ContactItem from 'components/castingDirector/ContactSection/ContactItem';
import Button from 'components/controls/button/button';
import { bgSizeCardEmpty, breakdownEmpty, EmailTemplateList, ScreenSize } from 'modules/general/general.type';
import EmailView from 'components/castingDirector/ContactSection/EmailView';
import { MatrixAtoms, MatrixType, PerformerItem, Show } from 'modules/Show/show.types';

import {
  copyMatrixItemBegin,
  fetchMatrixPerformerBegin,
  newAtomsBegin,
  sendBgSizeCardBegin,
  sendCallSheetBegin,
  setCopyFromAboveData,
  setMatrixCoordinator,
  setMatrixUpdateItem,
} from 'store/actions/show.actions';
import { GridApi, RowNode } from '@ag-grid-community/core';
import MatrixItem from 'components/castingDirector/MatrixItem/MatrixItem';
import CoordinatorView from 'components/castingDirector/ContactSection/CoordinatorView';
import CrewCall from 'components/castingDirector/ContactSection/crewCall';
import Loader from 'components/controls/Loader/Loader';

import {
  IBgSizeCardParams,
  IBreakdownParams,
  IChangeAtoms,
  ICopyMatrixItemResponse,
  IUpdateMatrixItem,
} from 'modules/params/param.type';
import showApi from 'store/services/show.services';
import moment from 'moment';
import {
  getBgSizeCardLoading,
  getCopiedMatrixItemResponse,
  getSelectedShow,
  isMatrixAtomLoading,
  isMatrixPerformerLoading,
} from 'store/selector/show.selector';
import { getUser } from 'store/selector/auth.selector';
import { User } from 'modules/user/types';
import { connect, useDispatch, useSelector } from 'react-redux';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';
import { toast } from 'react-toastify';
import Header from 'components/Matrix/Header';
import { SCREEN_SIZE_MOBILE } from 'store/castingPax.constants';
import { getRates, getScreenWidth } from 'store/selector/general.selector';
import Modal from 'components/controls/Modal/Modal';
import BgSizeCard from '../ContactSection/BgSizeCard';
import EmailPdfModal from '../MatrixItem/EmailPdfModal';
import Breakdown from '../ContactSection/Breakdown';

interface MatrixDetailProps {
  emailTemplates: EmailTemplateList;
  dayItem: MatrixType;
  show: Show;
  user: User;
  fetchMatrixPerformer: Function;
  loadMatrixCoordinator: Function;
  fetchNewAtoms: Function;
  isPerformerLoading: boolean;
  isAtomLoading: boolean;
  matrixUpdateItem: Function;
  sendCallTimeBegin: Function;
  BgSizeCardBegin: Function;
  bgSizeCardLoading: boolean;
  isViewBreakdown: Boolean;
  handleBreakDownData: Function;
  isLoadingBreakdown: Boolean;
  rateList: any;
}

const MatrixDetail: FunctionComponent<MatrixDetailProps> = (props: MatrixDetailProps) => {
  const {
    dayItem,
    emailTemplates,
    show,
    user,
    fetchMatrixPerformer,
    loadMatrixCoordinator,
    fetchNewAtoms,
    isPerformerLoading,
    isAtomLoading,
    matrixUpdateItem,
    sendCallTimeBegin,
    BgSizeCardBegin,
    bgSizeCardLoading,
    isViewBreakdown,
    handleBreakDownData,
    isLoadingBreakdown,
    rateList,
  } = props;
  const [item, setItem] = useState<MatrixType>(dayItem);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const copiedMatrixResponse: ICopyMatrixItemResponse[] = useSelector(getCopiedMatrixItemResponse);
  const [openSizeCardModal, setOpenSizeCardModal] = useState<boolean>(false);
  const [bgSizeCardObj, setBgSizeCardObj] = useState<IBgSizeCardParams>(bgSizeCardEmpty);
  const [breakdownObj, setBreakdownObj] = useState<IBreakdownParams>(breakdownEmpty);
  const [openBreakdownModal, setOpenBreakdownModal] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (bgSizeCardObj.type === 'download' && !bgSizeCardLoading) setBgSizeCardObj(bgSizeCardEmpty);
  }, [bgSizeCardLoading]);

  useEffect(() => {
    setItem(dayItem);
    if (!item.performers || item.performers.length === 0) {
      loadPerformerData();
    }
  }, [dayItem]);

  useEffect(() => {
    setItem(dayItem);
  }, [dayItem.atoms, dayItem.performers]);

  useEffect(() => {
    if (isExpanded) loadMatrixCoordinator(dayItem.bgCoordinator, dayItem.bgInvitation);
  }, [isExpanded]);

  useEffect(() => {
    if (copiedMatrixResponse && copiedMatrixResponse.length > 0 && copiedMatrixResponse[0].workingDayId === item.id) {
      const tmpObj = item;
      copiedMatrixResponse.forEach((i: ICopyMatrixItemResponse) => {
        const index: number = tmpObj.performers.findIndex((x: PerformerItem) => x.id === i.id);
        if (index !== -1) {
          const p: PerformerItem = tmpObj.performers[index];
          p.bookingId = i.bookingId;
          tmpObj.performers.splice(index, 1, { ...p });
        }
      });
      setItem({ ...tmpObj });
    }
  }, [copiedMatrixResponse]);

  const loadPerformerData = () => fetchMatrixPerformer({ workingDayId: item.id, id: user.id });

  const handleRefresh = () => loadPerformerData();

  const getSelectedPerformer = (sourceIndex: number, params: any) => {
    let data: PerformerItem[] = handleRowSelectionChange(sourceIndex);

    if (data.length === 0) {
      data.push(params.node.data);
    } else {
      const selectedIndex = data.findIndex((x: PerformerItem) => x.id === params.node.data.id);
      if (selectedIndex === -1) {
        data = [];
        data.push(params.node.data);
      }
    }
    return data;
  };

  const addToAtoms = (sourceIndex: number, destinationIndex: number, params: any) => {
    const data: PerformerItem[] = getSelectedPerformer(sourceIndex, params);
    addPerformerInGrid(destinationIndex, data);
    removePerformerInGrid(sourceIndex, data);
    updatePerformerData(sourceIndex, destinationIndex, data);
  };

  const removePerformerFromAtoms = (sourceIndex: number, params: any) => {
    if (matrixIsLocked()) return;

    const data: PerformerItem[] = getSelectedPerformer(sourceIndex, params);
    showApi
      .removePerformerFromAtoms({
        ids: data.map((x: PerformerItem) => x.id),
        bids: data.map((x: PerformerItem) => x.bookingId),
        date: moment(dayItem.date).format('YYYY-MM-DD'),
      })
      .catch((err) => console.log(err));
    data.map((p: PerformerItem) => clearPerformerData(p));
    updatePerformerInGrid(sourceIndex, data);
  };

  const updatePerformerData = (sourceIndex: number, index: number, data: PerformerItem[]) => {
    const obj = item;

    if (index >= 0 && obj && obj.atoms) {
      obj.atoms[index].performers = [...obj.atoms[index].performers, ...data];
      updateOnServer({
        atoms: obj.atoms[index].id,
        ids: data.map((x: PerformerItem) => x.id),
      });
    }

    if (sourceIndex >= 0 && obj && obj.atoms) {
      const prevPerformer = obj.atoms[sourceIndex].performers;
      data.forEach((p: PerformerItem) => {
        if (obj && obj.atoms) {
          const i = prevPerformer.findIndex((pr: PerformerItem) => pr.id === p.id);
          if (i !== -1) {
            prevPerformer.splice(i, 1);
          }
        }
      });
      obj.atoms[sourceIndex].performers = [...prevPerformer];
    }
    setItem(obj);
  };

  const addPerformerInGrid = (index: number, data: any) => {
    const api = getGridApi(index);
    if (api) {
      const rowAlreadyInGrid = api.getRowNode(data.id);
      if (rowAlreadyInGrid) {
        return;
      }
      const transaction = {
        add: data,
      };
      api.applyTransaction(transaction);
    }
  };

  const removePerformerInGrid = (index: number, data: any) => {
    const api = getGridApi(index);
    if (api) {
      const transaction = {
        remove: data,
      };
      api.applyTransaction(transaction);
    }
  };

  const updatePerformerInGrid = (index: number, data: any) => {
    const api = getGridApi(index);
    if (api) {
      const transaction = {
        update: data,
      };
      api.applyTransaction(transaction);
    }
  };

  const getGridApi = (index: number) => {
    if (item.atoms) {
      return item.atoms[index].grid;
    }
    return null;
  };

  const updateOnServer = (params: IChangeAtoms) => {
    showApi.changeMatrixAtoms(params).catch((err) => console.log(err));
  };

  const getAtomsList = (pos: number, params: any) => {
    const arr: any = [];
    if (item) {
      item.atoms?.forEach((x: MatrixAtoms, index: number) => {
        if (pos !== index) {
          arr.push({
            name: `Add to ${x.title}`,
            action: () => {
              addToAtoms(pos, index, params);
            },
          });
        }
      });
    }
    return arr;
  };

  const gridDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    const dragSupported = event.dataTransfer.types.length;
    const e = event;
    if (dragSupported) {
      e.dataTransfer.dropEffect = 'move';
      event.preventDefault();
    }
    return e;
  };

  const gridDrop = (grid: number, event: any) => {
    if (matrixIsLocked()) return;

    event.preventDefault();
    const {
      navigator: { userAgent },
    } = window;
    const isIE = userAgent.indexOf('Trident/') >= 0;
    const jsonData = event.dataTransfer.getData(isIE ? 'text' : 'application/json');
    const data = JSON.parse(jsonData);
    if (!data || data.id == null) {
      return;
    }
    const obj = item;
    data.isDataupdate = false;
    addPerformerInGrid(grid, [data]);

    if (obj.atoms) {
      obj.atoms.forEach((x: MatrixAtoms, index: number) => {
        const api = x.grid;
        if (index !== grid && api) {
          const prevNode = api.getRowNode(data.id);
          if (prevNode) {
            const transaction = {
              remove: [prevNode],
            };
            api.applyTransaction(transaction);

            if (obj.atoms) {
              obj.atoms[index].performers = obj.atoms[index].performers.filter((p: PerformerItem) => p.id !== data.id);
            }
          }
        }
      });

      obj.atoms[grid].performers = [...obj.atoms[grid].performers, data];

      updateOnServer({
        atoms: obj.atoms[grid].id,
        ids: [data.id],
      });
    }

    setItem(obj);
  };

  const getPerformerCount = () => {
    if (item.performers && item.performers.length !== 0) return item.performers.length;
    return 0;
  };

  const itemAtoms = useMemo(() => {
    const { atoms } = item;
    if (atoms) {
      return atoms.map((x: MatrixAtoms) => {
        if (item.performers !== undefined && item.performers.length > 0) {
          const obj = x;
          obj.performers = item.performers.filter((p: PerformerItem) => p.atoms === x.id);
          return obj;
        }
        return x;
      });
    }
    return [];
  }, [item, item.performers, item.atoms]);

  const handleAtoms = () => {
    const atmosNo = item.atoms ? item.atoms.length + 1 : 1;
    fetchNewAtoms({
      showId: item.showId.id,
      title: `new atmos ${atmosNo}`,
      workingDayId: item.id,
      sequence: atmosNo,
    });
  };

  const handleRowSelectionChange = (index: number) => {
    if (!item.atoms) return [];

    const api = item.atoms[index].grid;
    if (api) {
      const selectedNodes: any = api.getSelectedNodes();
      const cellRanges: any = api.getCellRanges();
      console.log('RANGE_SELETION', selectedNodes, cellRanges);
      if (selectedNodes && selectedNodes.length > 0) {
        return selectedNodes.map((node: RowNode) => node.data);
      }
      if (cellRanges && cellRanges.length > 0) {
        const cellRange: any = cellRanges[0];
        const selectedColumns: string[] = cellRange.columns.map((c: any) => c.colId);
        const data: PerformerItem[] = [];
        const atomsPerformers: PerformerItem[] = item.atoms[index].performers || [];
        const start: number = cellRange.startRow.rowIndex;
        const end: number = cellRange.endRow.rowIndex;
        const ttlLoop: number = end <= atomsPerformers.length ? end : atomsPerformers.length;
        for (let i = start; i <= ttlLoop; i += 1) {
          const obj = atomsPerformers[i];
          obj.columns = selectedColumns;
          data.push(obj);
        }
        return data;
      }
      api.deselectAll();
    }
    return [];
  };

  const handleUpdateAtomsTitle = (index: number, title: string) => {
    const obj = item;
    if (obj.atoms) {
      obj.atoms[index].title = title;
      showApi.updateAtomsName({ id: obj.atoms[index].id, title }).catch((err) => console.log(err));
      setItem({ ...obj });
    }
  };

  const sendCallToSpecificBg = (index: number, params: any) => {
    if (matrixIsLocked()) return;
    if (!item.atoms) return;
    let list: PerformerItem[] = [];
    if (index === -1) {
      item.atoms.forEach((x: MatrixAtoms) => {
        if (x.grid) {
          const nodes: any[] = x.grid.getSelectedNodes();
          nodes.forEach((node: any) => {
            const { data } = node;
            list.push(data);
          });
        }
      });
    } else if (index === -2) {
      list = [];
    } else {
      list = getSelectedPerformer(index, params);
    }
    sendCallTimeBegin({
      workingDayId: dayItem.id,
      templateId: dayItem.prelimEmailTemplateId || '',
      performerList: list.map((x: PerformerItem) => x.id),
      showName: show.title,
      type: dayItem.status ? dayItem.status : 'prelim',
    });
  };

  const updateUnionRate = (index: number, params: any, text: string) => {
    if (matrixIsLocked()) return;

    const tmp = item;
    const data: PerformerItem[] = getSelectedPerformer(index, params);
    const updateData = data.map((x: PerformerItem) => {
      const obj = x;

      if (tmp && tmp.atoms) {
        const pos = tmp.atoms[index].performers.findIndex((p: PerformerItem) => p.id === x.id);
        if (pos !== -1) {
          tmp.atoms[index].performers.splice(pos, 1, obj);
        }
      }
      obj.rate = text;
      return obj;
    });
    const api = getGridApi(index);
    if (api) {
      const transaction = {
        update: updateData,
      };
      api.applyTransaction(transaction);
    }
    matrixUpdateItem(updateData);
    setItem(tmp);
  };

  const removeDeletedPerformer = (index: number, id: string) => {
    const matrixObj = dayItem;
    if (matrixObj.atoms) {
      const performerIndex: number = matrixObj.atoms[index].performers.findIndex((x: PerformerItem) => x.id === id);
      if (performerIndex !== -1) {
        matrixObj.atoms[index].performers.splice(
          performerIndex,
          1,
          clearPerformerData(matrixObj.atoms[index].performers[performerIndex]),
        );
      }
      setItem(matrixObj);
    }
  };

  const clearPerformerData = (obj: PerformerItem) => {
    const tmpObj = obj;
    tmpObj.bookingId = '';
    tmpObj.prevBookingId = undefined;
    tmpObj.callTime = '';
    tmpObj.unionStatus = '';
    tmpObj.unionNo = '';
    tmpObj.performerId = '';
    tmpObj.performerName = '';
    tmpObj.isDelete = false;
    tmpObj.deleteReason = '';
    tmpObj.status = 'pending';
    return tmpObj;
  };

  const checkCallTimeFromAbove = (params: any) => {
    const { data } = params;
    const currentCallTime = params;
    currentCallTime.data.callTime = params.newValue;
    if (data.performerId) {
      const isExists: PerformerItem[] = item.performers.filter(
        (p: PerformerItem) => p.performerId === data.performerId && p.id !== data.id,
      );
      if (isExists.length > 0) {
        isExists.push(currentCallTime.data);
        const sortPerformers = isExists.sort((a: any, b: any) => {
          if (a.callTime === null) return 0;
          if (b.callTime === null) return -1;
          if (a.callTime < b.callTime) return -1;
          return 0;
        });

        sortPerformers.map((p: PerformerItem, index: number) => {
          const obj: PerformerItem = p;
          obj.isDataUpdate = false;
          if (index !== 0) {
            obj.callTime = 'from above';
          }
          if (item.atoms) {
            const atomsIndex = item.atoms.findIndex((a: MatrixAtoms) => a.id === obj.atoms);
            const api = item.atoms[atomsIndex].grid;
            if (api) {
              const transaction = {
                update: [obj],
              };
              api.applyTransaction(transaction);
            }
          }
          return obj;
        });
        const updateList: IUpdateMatrixItem[] = [];
        sortPerformers.forEach((p: PerformerItem) => {
          updateList.push({
            id: p.id,
            role: p.role,
            callTime: p.callTime,
            unionNo: p.unionNo,
            rate: p.rate,
            note: p.note || '',
          });
        });
        if (updateList.length > 0) {
          matrixUpdateItem(updateList);
        }
      } else return false;
    } else return false;

    return true;
  };

  const handleCopyFromAbove = async (index: number, params: any) => {
    if (matrixIsLocked()) return;

    const data: PerformerItem[] = getSelectedPerformer(index, params);
    const updatedData: PerformerItem[] = [];

    data.forEach((x: PerformerItem) => {
      const obj: PerformerItem = JSON.parse(JSON.stringify(x));
      obj.id = Math.random().toString();
      obj.columns = undefined;
      obj.callTime = 'from Above';
      updatedData.push(obj);
    });

    try {
      const result = await showApi.addItemInMatrix({ items: updatedData });
      addPerformerInGrid(index, result);
      dispatch(setCopyFromAboveData(result));
      const prevItem = item;
      if (prevItem && prevItem.atoms) {
        prevItem.atoms[index].performers = [...prevItem.atoms[index].performers, result];
        prevItem.performers = [...prevItem.performers, result];
        setItem({ ...prevItem });
      }
    } catch (err) {
      console.log('Error', err);
    }
  };

  const handleCopy = (index: number, params: any) => {
    const list: PerformerItem[] = getSelectedPerformer(index, params);
    const copyObject: MatrixType = {
      id: item.id,
      title: item.title,
      performers: [],
      bgCoordinator: [],
      type: item.type,
      showId: item.showId,
      date: `${moment(item.date).format('YYYY-MM-DD')} 00:00:00`,
    };
    localStorage.setItem('copyItem', JSON.stringify(list));
    localStorage.setItem('copyMatrix', JSON.stringify(copyObject));
  };

  const matrixIsLocked = () => {
    if (moment(item.date).isBefore(moment(), 'day')) {
      toast('Previous matrix can not changed');
      return true;
    }
    return false;
  };

  const handlePaste = (index: number, params: any) => {
    if (matrixIsLocked()) return;

    const copiedPerformer: PerformerItem[] = getItemFromLocalStorage('copyItem');
    const copiedFrom: MatrixType = getItemFromLocalStorage('copyMatrix');
    if (copiedPerformer.length === 0 || !item.atoms || item.atoms[index].performers.length === 0) return;

    const currentAtoms: MatrixAtoms = item.atoms[index];
    const isSameMatrix: boolean = item.id === copiedPerformer[0].workingDayId;
    const isPartailCopy: boolean =
      (copiedPerformer[0].columns &&
        copiedPerformer[0].columns.length > 0 &&
        copiedPerformer[0].columns[0] !== 'performerName') ||
      false;
    if (isSameMatrix && !isPartailCopy) {
      toast('Copy not allowed in same matrix');
      return;
    }

    const workingDayPerformer: PerformerItem[] = item.atoms[index].performers;
    workingDayPerformer.forEach((wp: PerformerItem) => {
      const cpIndex: number = copiedPerformer.findIndex((x: PerformerItem) => x.performerId === wp.performerId);
      if (cpIndex !== -1 && !copiedPerformer[cpIndex].columns) copiedPerformer.splice(cpIndex, 1);
    });

    if (copiedPerformer.length === 0) {
      toast('Copied Performer is already in Matrix');
      return;
    }

    const updatedList: PerformerItem[] = [];
    const currentIndex: number = params.node.rowIndex;
    const copiedPerformerCount: number = copiedPerformer.length;
    const ttlAtomsRecord: number = currentAtoms.performers.length;
    const ttlLoop =
      ttlAtomsRecord - currentIndex > copiedPerformerCount ? currentIndex + copiedPerformerCount : ttlAtomsRecord;

    if (copiedPerformer[0].columns && params.column.colId !== copiedPerformer[0].columns[0]) {
      toast('Copy and paste column index not match');
      return;
    }

    let inc = 0;
    for (let i = currentIndex; i < ttlLoop; i += 1) {
      const toData: PerformerItem = currentAtoms.performers[i];
      const fromData: PerformerItem = copiedPerformer[inc];
      const columnToCopy: string[] = fromData.columns || [];

      if (!toData.isDelete) {
        if (columnToCopy.length > 0) {
          if (!columnToCopy.includes('performerName') || !toData.performerId) {
            columnToCopy.forEach((c: string) => {
              switch (c) {
                case 'performerName':
                  toData.performerId = fromData.performerId;
                  toData.performerName = fromData.performerName;
                  toData.prevBookingId = fromData.bookingId;
                  toData.unionNo = fromData.unionNo;
                  toData.unionStatus = fromData.unionStatus;
                  toData.status = 'requested';
                  break;
                case 'callTime':
                  toData.callTime = fromData.callTime;
                  break;
                case 'unionNo':
                  toData.unionNo = fromData.unionNo;
                  break;
                case 'unionStatus':
                  toData.unionStatus = fromData.unionStatus;
                  break;
                case 'rate':
                  toData.rate = fromData.rate;
                  break;
                default:
                  break;
              }
            });
            toData.updateActionType = columnToCopy.includes('performerName') ? 'new' : 'update';
            updatedList.push(toData);
          }
        } else {
          toData.performerId = fromData.performerId;
          toData.performerName = fromData.performerName;
          toData.status = 'requested';
          toData.prevBookingId = fromData.bookingId;
          toData.callTime = fromData.callTime;
          toData.unionNo = fromData.unionNo;
          toData.unionStatus = fromData.unionStatus;
          toData.rate = fromData.rate;
          toData.prevJobId = fromData.jobId;
          toData.prevMatrixId = fromData.id;
          toData.updateActionType = 'new';
          currentAtoms.performers.splice(i, 1, toData);
          updatedList.push(toData);
        }
      }
      inc += 1;
    }

    if (updatedList.length > 0) {
      if (currentAtoms.grid) {
        currentAtoms.isUpdate += 1;
        const transaction = {
          update: updatedList,
        };
        currentAtoms.grid.applyTransaction(transaction);
        const obj: MatrixType = item;
        obj.atoms?.splice(index, 1, { ...currentAtoms });
        setItem(obj);
      }

      const matrixDate: string = `${moment(item.date).format('YYYY-MM-DD')} 00:00:00`;
      const newDataList: PerformerItem[] = updatedList.filter((x: PerformerItem) => x.updateActionType === 'new');
      if (newDataList.length > 0) {
        dispatch(
          copyMatrixItemBegin({
            data: newDataList,
            date: matrixDate,
            fromDate: copiedFrom.date,
          }),
        );
      }

      const updateDataList: IUpdateMatrixItem[] = updatedList
        .filter((x: PerformerItem) => x.updateActionType === 'update')
        .map((data: PerformerItem) => {
          const obj: IUpdateMatrixItem = {
            id: data.id,
            role: data.role,
            callTime: data.callTime,
            unionNo: data.unionNo,
            rate: data.rate,
            note: data.note || '',
          };
          return obj;
        });
      if (updateDataList.length > 0) {
        matrixUpdateItem(updateDataList);
      }
    } else {
      toast('Not able to paste the data');
    }
  };

  const updateGridDataOnServer = (changeIds: string[], pos: number) => {
    if (changeIds.length === 0) return;

    const grid = getGridApi(pos);
    if (!grid) return;

    const performers: PerformerItem[] = dayItem.performers.filter(
      (p: PerformerItem) => p.isDataUpdate === false && !changeIds.includes(p.id),
    );
    const updateList: IUpdateMatrixItem[] = [];
    if (performers.length > 0) {
      performers.forEach((m: PerformerItem) => {
        updateList.push({
          id: m.id,
          role: m.role,
          callTime: m.callTime,
          unionNo: m.unionNo,
          rate: m.rate,
          note: m.note || '',
          isAllowancesEnable: m.isAllowancesEnable,
          voucherType: m.voucherType,
        });
      });
    }
    changeIds.forEach((id: string) => {
      const node: any = grid.getRowNode(id);
      if (node) {
        const { data } = node;
        data.isDataUpdate = false;
        updateList.push({
          id: data.id,
          role: data.role,
          callTime: data.callTime,
          unionNo: data.unionNo,
          rate: data.rate,
          note: data.note || '',
          isAllowancesEnable: data.isAllowancesEnable,
          voucherType: data.voucherType,
        });
      }
    });
    if (updateList.length > 0) {
      matrixUpdateItem(updateList);
    }
  };

  const updateGridInstance = (index: number, grid: GridApi) => {
    const prevItem = item;
    grid.setFillHandleDirection('y');
    if (prevItem.atoms) {
      const prevAtoms = prevItem.atoms[index];
      prevAtoms.grid = grid;
      prevItem.atoms.splice(index, 1, { ...prevAtoms });
    }

    console.log('GridApi ', grid);
    setItem(prevItem);
  };

  const handleCrewCall = (time: string) => {
    const obj = dayItem;
    obj.crewCall = time;
    setItem({ ...obj });
  };

  const handleSizeCardSelection = (type: string) => {
    if (matrixIsLocked()) return;
    if (!item.atoms) return;
    const list: string[] = [];
    const count = item.atoms.reduce((obj: any, x: MatrixAtoms) => {
      let num = obj;
      if (x.performers) num += x.performers.length;
      return num;
    }, 0);
    if (count === 0) {
      toast('No Performers in matrix');
      return;
    }
    item.atoms.forEach((x: MatrixAtoms) => {
      if (x.grid && type === 'share') {
        const nodes: any[] = x.grid.getSelectedNodes();
        nodes.forEach((node: any) => {
          const { data } = node;
          if (data.status === 'booked') list.push(data.performerId);
        });
      } else if (type === 'download' || type === 'shareAll') {
        x.performers.forEach((p: PerformerItem) => !p.isDelete && p.status === 'booked' && list.push(p.performerId));
      }
    });
    if (type === 'share' && list.length === 0) {
      toast('Select Performers for size card');
      return;
    }
    setBgSizeCardObj({ ...bgSizeCardObj, type, ids: list });
    if (type === 'download') BgSizeCardBegin({ type, ids: list });
    else setOpenSizeCardModal(true);
  };

  const handleSendBgSizeCard = (email: string) => {
    setBgSizeCardObj({ ...bgSizeCardObj, email });
    if (email) BgSizeCardBegin({ ...bgSizeCardObj, email });
  };

  const handleBreakdownSelection = async (type: string) => {
    if (matrixIsLocked()) return;
    if (!item.atoms) return;
    const list: string[] = [];
    const count = item.atoms.reduce((obj: any, x: MatrixAtoms) => {
      let num = obj;
      if (x.performers) num += x.performers.length;
      return num;
    }, 0);
    if (count === 0) {
      toast('No Performers in matrix');
      return;
    }
    item?.atoms?.forEach((x: MatrixAtoms) => {
      if (x.grid) {
        const nodes: any[] = x.grid.getSelectedNodes();
        nodes.forEach((node: any) => {
          const { data } = node;
          if (data.status === 'booked') list.push(data.performerId);
        });
      }
    });
    if (list.length === 0) {
      item.atoms.forEach((x) =>
        x.performers.forEach((p: PerformerItem) => !p.isDelete && p.status === 'booked' && list.push(p.performerId)),
      );
    }
    setBreakdownObj({ ...breakdownObj, type, ids: list });

    if (type === 'share') {
      setOpenBreakdownModal(true);
    } else {
      handleBreakDownData(dayItem, user.email, type);
    }
  };

  const handleBreakdown = (email: string) => {
    handleBreakDownData(dayItem, email);
    setOpenBreakdownModal(false);
  };

  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);

  const mobileLayout = () => {
    if (currentScreenSize === SCREEN_SIZE_MOBILE) {
      return (
        <div className={`d-flex align-items-center ${Style.statusCallWrapper}`}>
          <ContactItem
            cssClass={Style.statusSection}
            title="Status"
            type="status"
            icon={statusSvg}
            workingDay={dayItem}
          />
          <CrewCall
            title="Crew Call"
            type="crewCall"
            icon={crewCallSvg}
            workingDay={dayItem}
            handleCrewCall={handleCrewCall}
          />
          <ContactItem cssClass={Style.statusSection} title="Share" type="share" icon={shareSvg} workingDay={dayItem} />
        </div>
      );
    }
    return '';
  };

  return (
    <>
      <div className={Style.mainWrapper} id="mainMatrix">
        <Header item={item} bgCount={getPerformerCount()} setIsExpanded={setIsExpanded} isExpanded={isExpanded} />
        {isExpanded && (
          <>
            <div className={`${Style.contactSectionWrapper}`}>
              <ContactItem
                cssClass={`${Style.statusNoDisplay} ${Style.statusSection}`}
                title="Status"
                type="status"
                icon={statusSvg}
                workingDay={dayItem}
              />
              <ContactItem title="Call sheet" type="call" icon={callSheetSvg} workingDay={dayItem} />
              <CrewCall
                cssClass={`${Style.statusNoDisplay}`}
                title="Crew Call"
                type="crewCall"
                icon={crewCallSvg}
                workingDay={item}
                handleCrewCall={handleCrewCall}
              />
              <EmailView
                list={emailTemplates}
                sendCallToSpecificBg={sendCallToSpecificBg}
                workingDayId={dayItem.id}
                emailTemplate={dayItem.prelimEmailTemplateId || ''}
              />
              <CoordinatorView
                workingDayId={dayItem.id}
                handleClick={() => loadMatrixCoordinator(dayItem.bgCoordinator, item.bgInvitation)}
              />
              {/* {user.role === ROLE_COORDINATOR && ( */}
              {!isViewBreakdown && (
                <Breakdown
                  icon={breakdownSvg}
                  type="email"
                  breakdownLoading={false}
                  handleClick={handleBreakdownSelection}
                />
              )}
              {/* )} */}
              <BgSizeCard
                icon={bgSizeCardSvg}
                type={bgSizeCardObj.type}
                bgSizeCardLoading={bgSizeCardLoading}
                handleClick={handleSizeCardSelection}
              />
              {mobileLayout()}
              <ContactItem
                cssClass={`${Style.statusNoDisplay} ${Style.statusSection}`}
                title="Share"
                type="share"
                icon={shareSvg}
                workingDay={dayItem}
              />
            </div>
            <div className={Style.extraMatrixWrapper}>
              {isLoadingBreakdown && <Loader isSmallView />}

              <p className="mb-0">Extra Matrix</p>
              <Button
                showLoading={isPerformerLoading}
                spinnerCss={Style.spinnerCss}
                cssClass={`matrixRefreshBtn ${Style.refreshBtn}`}
                clickHandler={handleRefresh}
                icon={reloadSvg}
              />
            </div>

            <div className={Style.extraMatrixItemWrapper}>
              {!isLoadingBreakdown &&
                itemAtoms.map((atom: MatrixAtoms, index: number) => (
                  <MatrixItem
                    key={atom.id}
                    atoms={atom}
                    grid={atom.grid}
                    index={index}
                    gridDrop={gridDrop}
                    gridDragOver={gridDragOver}
                    atomsMenu={getAtomsList}
                    handleRemoveLeftMenu={removePerformerFromAtoms}
                    handleUpdateAtomsTitle={handleUpdateAtomsTitle}
                    sendCallToSpecificBg={sendCallToSpecificBg}
                    updateUnionRate={updateUnionRate}
                    removeDeletedPerformer={removeDeletedPerformer}
                    copyFromAbove={handleCopyFromAbove}
                    handleCopy={handleCopy}
                    handlePaste={handlePaste}
                    updateGridInstance={updateGridInstance}
                    updateGridDataOnServer={updateGridDataOnServer}
                    checkCallTimeFromAbove={checkCallTimeFromAbove}
                    rateList={rateList}
                  />
                ))}
            </div>
            <div className="text-center">
              <Button
                showLoading={isAtomLoading}
                clickHandler={handleAtoms}
                label="Atoms"
                icon={plusSvg}
                cssClass={Style.atomsButton}
              />
            </div>
          </>
        )}
      </div>
      {openSizeCardModal && (
        <Modal
          title={`Bg Size Card (${bgSizeCardObj.type.toUpperCase()})`}
          body={() => (
            <EmailPdfModal
              type={bgSizeCardObj.type}
              isLoading={bgSizeCardLoading}
              handleSave={handleSendBgSizeCard}
              handleClose={() => {
                setOpenSizeCardModal(false);
                setBgSizeCardObj(bgSizeCardEmpty);
              }}
            />
          )}
          closeModal={() => {
            setOpenSizeCardModal(false);
            setBgSizeCardObj(bgSizeCardEmpty);
          }}
        />
      )}
      {openBreakdownModal && (
        <Modal
          title="Matrix Breakdown"
          body={() => (
            <EmailPdfModal
              type={breakdownObj.type}
              isLoading={bgSizeCardLoading}
              handleSave={handleBreakdown}
              handleClose={() => {
                setOpenBreakdownModal(false);
                setBreakdownObj(breakdownEmpty);
              }}
            />
          )}
          closeModal={() => {
            setOpenBreakdownModal(false);
            setBreakdownObj(breakdownEmpty);
          }}
        />
      )}
    </>
  );
};
const mapStateToProps = (state: Show) => {
  const user: User = getUser(state);
  const show: Show = getSelectedShow(state);
  const isPerformerLoading: boolean = isMatrixPerformerLoading(state);
  const isAtomLoading: boolean = isMatrixAtomLoading(state);
  const bgSizeCardLoading: boolean = getBgSizeCardLoading(state);
  const rateList = getRates(state);

  return { user, show, isPerformerLoading, isAtomLoading, bgSizeCardLoading, rateList };
};

const mapDispatchToProps = {
  fetchMatrixPerformer: fetchMatrixPerformerBegin,
  loadMatrixCoordinator: setMatrixCoordinator,
  fetchNewAtoms: newAtomsBegin,
  matrixUpdateItem: setMatrixUpdateItem,
  sendCallTimeBegin: sendCallSheetBegin,
  BgSizeCardBegin: sendBgSizeCardBegin,
};

export default connect(mapStateToProps, mapDispatchToProps)(MatrixDetail);
