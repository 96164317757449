/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useState, useEffect } from 'react';
import Styled from 'styled-components';
import ClockIcon from 'assets/images/clockIcon.svg';
import { defaultPagination } from 'helpers/utils';
import { Pagination } from 'modules/jobs/types';
import PaginationComponent from 'components/controls/pagination/Pagination';
// import { actionItems } from 'components/Payroll/Data/RecentActionsData';
import ReviewNoteItem from './RecentActionsItem';

export interface Props {
  actionItems?: any;
}

const ReviewNotesComponent: FunctionComponent<Props> = (props) => {
  const { actionItems } = props;
  const [totalPage, setTotalPage] = useState(0);
  const [item, setItem] = useState<Array<Record<string, string>>>();
  const [pagination, setPagination] = useState<Pagination>(defaultPagination());

  useEffect(() => {
    setTotalPage(Math.ceil(actionItems.length / 6));
    paginateAction(actionItems, 6, 0);
  }, [totalPage]);

  const paginateAction = (array: Array<Record<string, string>>, pageSize: number, pageNumber: number) => {
    const sort: Array<Record<string, string>> = array.slice(pageNumber * pageSize, pageNumber * pageSize + pageSize);
    return setItem(sort);
  };

  const handlePages = (updatePage: any) => {
    paginateAction(actionItems, 6, updatePage.selected);
  };

  return (
    <ErrorNoteContainer>
      <NoteHeader>
        <LogoImage src={ClockIcon} />
        <ActionsHeaderText>Recent Actions</ActionsHeaderText>
      </NoteHeader>
      <BottomSectionContainer>
        <NoteItemContainer>
          <ActionsItemsContainer>
            {item?.map((actionItem) => (
              <ReviewNoteItem type={actionItem.type} name={actionItem.name} description={actionItem.description} />
            ))}
          </ActionsItemsContainer>
          <PaginationContainer>
            <PaginationComponent
              page={pagination.page}
              totalPages={totalPage > 0 ? totalPage : 1}
              handlePagination={handlePages}
            />
          </PaginationContainer>
        </NoteItemContainer>
      </BottomSectionContainer>
    </ErrorNoteContainer>
  );
};

const ErrorNoteContainer = Styled.div`
display: flex;
height: 460px;
width: 49%;
border-radius: 10px;
flex-direction: column;
@media only screen and (max-width: 991px) {
  width: 100%;
}
`;

const NoteHeader = Styled.div`
display: flex;
background: #fff;
border-bottom: 1.5px solid #F2F2F2;
border-top-left-radius: 14px;
border-top-right-radius: 14px;
padding: 14px 28px;
`;

const ActionsHeaderText = Styled.p`
  margin: 0;
  padding-left: 8px;
  font-family: 'Montserrat';
`;

const LogoImage = Styled.img`
height:15px;
margin-top: 4px;
width:15px;
`;

const BottomSectionContainer = Styled.div`
background: #fff;
border-bottom-right-radius: 12px;
border-bottom-left-radius: 12px;
flex: 1;
padding-bottom: 18px;
`;

const NoteItemContainer = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const PaginationContainer = Styled.div`
display: flex;
justify-content: flex-end;
width: 100%;
padding-top: 10px;
padding-right: 10px;
`;

const ActionsItemsContainer = Styled.div`
@media only screen and (max-width: 767px) {
  height: 310px;
  overflow-y: scroll;
}
// overflow-y: hidden;

`;

export default ReviewNotesComponent;
