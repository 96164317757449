import React, { FunctionComponent, useState } from 'react';
import { User, UserList } from 'modules/user/types';
import ListItem from 'components/MyPerformerList/ListItems/ListItem';
import PerformerListStyle from 'components/MyPerformerList/MyPerformerList.module.css';
import downArrowSvg from 'assets/svg/down-arrow.svg';

interface MyPerformerListProps {
  label?: string;
  performList?: UserList;
}
const MyPerformerList: FunctionComponent<MyPerformerListProps> = (props: MyPerformerListProps) => {
  const { label, performList } = props;
  const [toggleItemList, setToggleItemList] = useState(false);

  const handleClick = () => {
    setToggleItemList(!toggleItemList);
  };

  return (
    <div className={`${PerformerListStyle.listCard}`}>
      <div className="d-flex justify-content-between align-items-center">
        <p className={PerformerListStyle.listTitle}>{label}</p>
        <span
          className={PerformerListStyle.angleDown}
          onClick={handleClick}
          role="button"
          tabIndex={0}
          onKeyPress={handleClick}
        >
          <img src={downArrowSvg} alt="" />
        </span>
      </div>
      {toggleItemList && (
        <div className={`customScrollBar ${PerformerListStyle.mainListItemWrapper}`}>
          {performList ? performList.map((item: User) => <ListItem key={item.id} item={item} />) : ''}
        </div>
      )}
    </div>
  );
};

MyPerformerList.defaultProps = {
  label: '',
  performList: [],
};

export default MyPerformerList;
