export const emptyTemplate = () => ({
  id: '-1',
  showId: '-1',
  title: '',
  subject: '',
  templateHtml: '',
  templateType: '',
  messageType: '',
  type: '',
  createdFrom: '',
  attachements: [],
});

export const empty = () => {};
