import ItemRoleDetail from 'components/bgPerformer/Booking/BookingItem/ItemRoleDetail/ItemRoleDetail';
import { ShowDayJob } from 'modules/Show/show.types';
import { User } from 'modules/user/types';
import React, { FunctionComponent, useState } from 'react';
import { ICancelRequestParams } from 'modules/params/param.type';
import { BookingRequest, BookingRequestList } from 'modules/jobs/types';
import { JOB_STATUS_BOOKED, TOAST_IDS, TOAST_TYPE } from 'store/castingPax.constants';
import { useSelector } from 'react-redux';
import { getCalendarDates } from 'store/selector/job.selector';
import moment from 'moment';
import Modal from 'components/controls/Modal/Modal';
import ConfirmationModal from 'components/bgPerformer/BookingButton/ConfirmationModal';
// import { JOB_STATUS_BOOKED } from 'store/castingPax.constants';

interface JobViewProps {
  job?: ShowDayJob;
  booking?: BookingRequest;
  user: User;
  closeOpenJobDetails?: Function;
  submitJobRequestBegin: Function;
  showMessageBox: Function;
  acceptCastingRequestBegin: Function;
  allBookings?: BookingRequestList;
  showToast: Function;
}
const JobView: FunctionComponent<JobViewProps> = (props) => {
  const {
    job,
    closeOpenJobDetails,
    submitJobRequestBegin,
    acceptCastingRequestBegin,
    booking,
    allBookings,
    showToast,
    user,
    showMessageBox,
  } = props;
  const calenderDates = useSelector(getCalendarDates);
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
  console.log(user, showMessageBox);
  const handleClose = () => {
    if (closeOpenJobDetails) closeOpenJobDetails();
  };

  const handleJobRequest = () => {
    if (job) {
      if (!canApplyForJob()) {
        showToast({
          id: TOAST_IDS.CANCEL_BOOKING_ID,
          title: 'Please first cancel the booking that clash with current Job.',
          type: TOAST_TYPE.ERROR,
        });
        return;
      }
      if (checkAvailableDates()) submitJobRequestBegin({ id: job.id });
    }
  };

  const canApplyForJob = () => {
    let isAvailable: boolean = true;
    if (allBookings) {
      if (booking) {
        const currentBooking: any = allBookings.filter((b: BookingRequest) => {
          let checkDate: string = '0';
          if (b.status === JOB_STATUS_BOOKED && b.id !== booking.id) {
            booking.dates.forEach((x) => {
              if (b.dates.includes(x)) checkDate = '1';
            });
          }
          return checkDate === '0' ? null : b;
        });
        return currentBooking.length === 0;
      }
      job?.dates.forEach((d: string) => {
        const index: number = allBookings.findIndex(
          (j: BookingRequest) => j.status === JOB_STATUS_BOOKED && j.dates.includes(d),
        );
        if (index !== -1) isAvailable = false;
      });
    }
    return isAvailable;
  };

  const checkAvailableDates = () => {
    const notAvailableDates = calenderDates
      .filter((c: any) => c.workStatus === 2)
      .map((a: any) => moment(a.workDate).format('Y-M-D 00:00:00'));
    if (job) {
      const isValidDate = job.dates.filter((c: any) => notAvailableDates.includes(c));
      if (isValidDate.length > 0) {
        showToast({
          id: TOAST_IDS.NOT_AVAILABLE_BOOKING,
          title: 'You are Not Available on that day',
          type: TOAST_TYPE.ERROR,
        });
        setIsConfirmation(true);
        return false;
      }
      return true;
    }
    return false;
  };

  const ModalBody = () => (
    <ConfirmationModal handleSubmit={handleSubmit} handleClose={() => setIsConfirmation(false)} />
  );

  const handleSubmit = () => {
    if (job) submitJobRequestBegin({ id: job.id });
    setIsConfirmation(false);
  };

  const handleProcessRequest = (status: string, cancelRemarks?: string) => {
    if (!job) return;

    if (cancelRemarks && booking) {
      const params: ICancelRequestParams = { id: booking.id, status, cancelRemarks };
      acceptCastingRequestBegin(params);
      return;
    }

    if (allBookings && booking) {
      const currentBooking: any = allBookings.filter((b: BookingRequest) => {
        let checkDate: string = '0';
        if (b.status === JOB_STATUS_BOOKED && b.id !== booking.id) {
          booking.dates.forEach((x) => {
            if (b.dates.includes(x)) checkDate = '1';
          });
        }
        return checkDate === '0' ? null : b;
      });
      if (currentBooking.length === 0) {
        const params: ICancelRequestParams = { id: booking.id, status };
        acceptCastingRequestBegin(params);
      } else {
        showToast({
          id: TOAST_IDS.CANCEL_BOOKING_ID,
          title: 'Please first cancel the booking that clash with current Job.',
          type: TOAST_TYPE.ERROR,
        });
      }
    }
  };

  return job && 'title' in job ? (
    <>
      <ItemRoleDetail
        job={job}
        booking={booking}
        handleClose={handleClose}
        handleJobRequest={handleJobRequest}
        handleProcessRequest={handleProcessRequest}
      />
      {isConfirmation && (
        <Modal title="Confirmation Alert!" body={ModalBody} closeModal={() => setIsConfirmation(false)} />
      )}
    </>
  ) : (
    <>.</>
  );
};

export default JobView;
