import React, { FunctionComponent, useState } from 'react';
import ModalHeader from 'components/Modal/Header';
import Style from 'components/CreateShow/CreateShow.module.css';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { Show, Episode } from 'modules/Show/show.types';
import { User } from 'modules/user/types';
import Heading from 'components/controls/heading/Heading';
import EpisodeItem from 'components/CreateShow/Detail/EpisodeItem/Item';
import UserItem from 'components/CreateShow/Detail/UserItem/Item';
import infoSvg from 'assets/svg/info.svg';
import locationSvg from 'assets/svg/location.svg';

interface DetailShowProps {
  closeDialog?: Function;
  detailData: Show;
}
const DetailShow: FunctionComponent<DetailShowProps> = (props: DetailShowProps) => {
  const { closeDialog, detailData } = props;
  const [isExpand, setIsExpand] = useState(false);
  console.log('status : ', isExpand);
  const handleClick = () => {
    if (closeDialog) closeDialog({});
  };

  return (
    <div className={Style.bgLayer}>
      <div className={Style.ContentWrapper}>
        <ModalHeader title={detailData.title} handleClick={handleClick} icon={faVideo} />
        <div className={`modal-body customScrollBar ${Style.modalBody}`}>
          <div className="row">
            <div className="col-sm-12">
              <Heading path={locationSvg} label="Episodes" />
            </div>
          </div>
          <div className="row mt-4">
            {detailData.episodes && detailData.episodes.map((episode: Episode) => <EpisodeItem episode={episode} />)}
          </div>
          <div className="row text-left">
            <div className="col-sm-12">
              <Heading label="Additional Information" path={infoSvg} />
            </div>
          </div>
          <div className="row text-left">
            <div className="col-sm-12">
              <div className="table-responsive">
                <table className="table mb-0 mt-4 userDetailTable">
                  {detailData.users &&
                    detailData.users.map((user: User) => (
                      <UserItem user={user} isExpanded={() => setIsExpand(!isExpand)} />
                    ))}
                </table>
              </div>
            </div>
          </div>
          <div className="row text-left">
            <div className="col-sm-12 mt-2">
              <span className={Style.createInputLabel}>Remarks</span>
              <p />
            </div>
          </div>
          <div className="row text-left">
            <div className="col-sm-12">
              <span className={Style.createInputLabel}>Additional Information</span>
              <p />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailShow;
