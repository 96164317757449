import styled from 'styled-components';

const StyledDaysWorked = styled.div`
  height: 30px;
  background: rgba(242, 201, 76, 0.1);
  border-radius: 8px;
  padding: 0 9px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;

  p {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    color: ${({ theme }) => theme.colors.yellow};
    margin: 0px;
  }

  div {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.white};
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    background: ${({ theme }) => theme.colors.yellow};
  }
`;

export default StyledDaysWorked;
