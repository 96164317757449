import styled from 'styled-components';

interface StyledWrapperProp {
  open: boolean;
}
const StyledBgCordinatorWrapper = styled.div<StyledWrapperProp>`
  /* display: ${({ open }) => (open ? 'block' : 'none')}; */
  position: relative;
  height: fit-content;
  cursor: pointer;
`;

export default StyledBgCordinatorWrapper;
