import { IRateValues, IVoucherStatus, IRateTitle, ICreateVoucher } from 'modules/rates/rate.types';
import { apiCall } from 'store/services/service';
import {
  URL_CREATE_RATE_VALUES,
  URL_UPDATE_RATE_VALUES,
  URL_VIEW_RATE_VALUES,
  URL_UPDATE_VOUCHER_STATUS,
  URL_UPDATE_RATE_TITLE,
  URL_CREATE_VOUCHER,
} from './URL';

export const payrollApi = {
  createRateValues(params: IRateValues) {
    return apiCall.post({ url: URL_CREATE_RATE_VALUES, isAuthToken: true, params });
  },
  updateRateValues(params: IRateValues) {
    return apiCall.post({ url: URL_UPDATE_RATE_VALUES, isAuthToken: true, params });
  },
  getRateValues(params: any) {
    return apiCall.post({ url: URL_VIEW_RATE_VALUES, isAuthToken: true, params });
  },
  updateVoucherStatus(params: IVoucherStatus) {
    return apiCall.put({ url: URL_UPDATE_VOUCHER_STATUS, isAuthToken: true, params });
  },
  createVoucher(params: ICreateVoucher) {
    return apiCall.put({ url: URL_CREATE_VOUCHER, isAuthToken: true, params });
  },
  updateRateTitle(params: IRateTitle) {
    return apiCall.put({ url: URL_UPDATE_RATE_TITLE, isAuthToken: true, params });
  },
};

export default payrollApi;
