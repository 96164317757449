import React, { FunctionComponent, useState } from 'react';
import Style from 'components/castingDirector/ContactSection/ContactItem.module.css';
import arrowDownSvg from 'assets/svg/arrowDown.svg';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import EmailDetail from 'components/castingDirector/PopOvers/EmailDetail/EmailDetail';
import { EmailTemplate, EmailTemplateList } from 'modules/general/general.type';
import emailManagementSvg from 'assets/svg/email-management.svg';
import CreateEmailTemplate from '../PopOvers/EmailDetail/CreateEmailTemplate';

interface EmailViewProps {
  list?: EmailTemplateList;
  sendCallToSpecificBg: Function;
  workingDayId: string;
  emailTemplate: string;
}
const EmailView: FunctionComponent<EmailViewProps> = (props: EmailViewProps) => {
  const { list, sendCallToSpecificBg, workingDayId, emailTemplate } = props;
  const [toggleNewTemplate, setToggleNewTemplate] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const [isShow, setIsShow] = useState<boolean>(true);

  const handleEditTemplate = (isShowModal: boolean, item: EmailTemplate) => {
    setToggleNewTemplate(isShowModal);
    setSelectedTemplate(item);
    setIsShow(false);
  };

  const handleCloseDialog = () => {
    setIsShow(true);
    setToggleNewTemplate(false);
    setSelectedTemplate(null);
  };
  const getComponent = () => (
    <EmailDetail
      list={list}
      handleNewTemplate={setToggleNewTemplate}
      sendCallToSpecificBg={sendCallToSpecificBg}
      workingDayId={workingDayId}
      emailTemplate={emailTemplate}
      handleEdit={handleEditTemplate}
    />
  );

  return (
    <>
      {isShow && (
        <PopoverContainer itemComponent={() => getComponent()}>
          <div className={`d-flex align-items-center ${Style.contactSection}`}>
            <img src={emailManagementSvg} alt="" className="mr-3" />
            <p className="mb-0 w-100">Email</p>
            <img src={arrowDownSvg} alt="" className="ml-3" />
          </div>
        </PopoverContainer>
      )}
      {toggleNewTemplate && (
        <CreateEmailTemplate createdFrom="matrix" closeDialog={handleCloseDialog} emailTemplate={selectedTemplate} />
      )}
    </>
  );
};

export default EmailView;
