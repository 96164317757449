import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import Style from 'components/Producer/Matrix/UserManagementItem/ShowAccess/Search/Search.module.css';
import searchSvg from 'assets/svg/search.svg';
import { Show, ShowList } from 'modules/Show/show.types';
import plusSvg from 'assets/svg/plus-green.svg';
import { getShowLists } from 'store/selector/show.selector';
import { connect } from 'react-redux';

interface ShowSearchProps {
  showIds: string[];
  shows: ShowList;
  handleAddShow: Function;
}

const ShowSearch: FunctionComponent<ShowSearchProps> = (props: ShowSearchProps) => {
  const { showIds, shows, handleAddShow } = props;
  const [searchText, setSearchText] = useState<string>('');
  const [list, setList] = useState<ShowList>(shows);

  useEffect(() => {
    const timeOutId = setTimeout(() => searchShow(), 500);
    return () => clearTimeout(timeOutId);
  }, [searchText]);

  const searchShow = () => {
    if (searchText.length === 0) {
      setList(shows);
      return;
    }
    setList(shows.filter((s: Show) => s.title.toLowerCase().includes(searchText.toLowerCase())));
    /*  userApi
      .searchShow({ searchText })
      .then((res: ApiResponse) => {
        setList(res.data);
      })
      .catch((err) => err); */
  };

  const filterList = useMemo(() => list.filter((s: Show) => !showIds.includes(s.id)), [list, showIds]);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
  };

  const handleAdd = (show: Show) => {
    handleAddShow(show.id);
  };

  return (
    <div className={Style.mainWrapper}>
      <div className={Style.searchSection}>
        <img src={searchSvg} alt="" className={Style.searchIcon} />
        <input type="text" placeholder="Search" onChange={handleChange} />
      </div>
      <div className={Style.seachResultWrapper}>
        {filterList.map((item: Show) => (
          <div className="d-flex justify-content-between align-items-center mb-4 px-3">
            <span>{item.title}</span>
            <div onClick={() => handleAdd(item)} onKeyPress={() => handleAdd(item)} tabIndex={0} role="button">
              <img src={plusSvg} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: Show) => {
  const shows: ShowList = getShowLists(state);
  return { shows };
};

export default connect(mapStateToProps, null)(ShowSearch);
