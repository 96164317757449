import React from 'react';

interface Props {}

const dummy = ['Emeka', 'John'];

const UsersList: React.FC<Props> = () => (
  <div>
    {dummy.map((item, i) => (
      <div key={i}>
        <p>{item}</p>
      </div>
    ))}
  </div>
);

export default UsersList;
