import React, { FunctionComponent } from 'react';
import checkboxStyle from 'components/controls/checkbox/checkbox.module.css';

interface CheckBoxProps {
  id: string;
  name?: string;
  label?: string;
  handleChecked?: Function;
  defaultCheck?: boolean;
  isDisable?: boolean;
  cssClass?: string;
}
const CheckBox: FunctionComponent<CheckBoxProps> = (props: CheckBoxProps) => {
  const { id, label, handleChecked, defaultCheck, name, isDisable, cssClass } = props;
  return (
    <>
      <div className={`custom-control custom-checkbox ${cssClass} ${checkboxStyle.customCheckBoxWrapper}`}>
        <input
          type="checkbox"
          className={`custom-control-input ${checkboxStyle.customControlInput}`}
          id={id}
          name={name && name ? name : id}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (handleChecked) handleChecked(e.target.checked);
          }}
          checked={defaultCheck}
          disabled={isDisable}
        />
        <label className={`custom-control-label ${checkboxStyle.customControlLabel} ${cssClass}`} htmlFor={id}>
          {label}
        </label>
      </div>
    </>
  );
};
export default CheckBox;
