import styled from 'styled-components';

const StyledTopInfoTab = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  section {
    display: flex;
    justify-content: space-between;

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.secondaryColor};
    }
    & > div {
      display: flex;
      padding: 9px 5px;
      padding-top: 5px;
      flex-direction: column;
      align-items: center;
      background: ${({ theme }) => theme.colors.white};

      & > h2 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }

      & > p {
        font-family: 'Montserrat';
        height: 16px;
        font-style: normal;
        padding: 0 7px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: ${({ theme }) => theme.colors.gray6};
        color: ${({ theme }) => theme.colors.gray3};
      }
    }
  }
`;

export default StyledTopInfoTab;
