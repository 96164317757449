import styled from 'styled-components';

interface Props {
  className?: string;
}

const StyledDropdown = styled.div<Props>`
  box-sizing: border-box;
  width: 167px;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 121px;
  gap: 24px;
  border-radius: 10px;
  background: transparent;
`;

export default StyledDropdown;
