import moment from 'moment';
import React from 'react';
import StyledDaysWorked from '../../../styles/show/components/DaysWorked';

interface Props {
  dayItem?: any;
  prevElement?: any;
}

const DaysWorked: React.FC<Props> = (props) => {
  const { dayItem, prevElement } = props;
  return (
    <StyledDaysWorked>
      <p>Days Since Worked</p>
      <div>{`#${prevElement ? moment(dayItem.date).diff(moment(prevElement.date), 'days') : 0}`}</div>
    </StyledDaysWorked>
  );
};

export default DaysWorked;
