import Button from 'components/controls/button/button';
import React, { FunctionComponent } from 'react';
import Style from 'components/bgPerformer/BookingButton/ConfirmationModal.module.css';
import { getUser } from 'store/selector/auth.selector';
import { useSelector } from 'react-redux';
import { User } from 'modules/user/types';
import { ROLE_DIRECTOR } from 'store/castingPax.constants';

interface IPropsConfirmation {
  handleSubmit: Function;
  handleClose: Function;
}
const ConfirmationModal: FunctionComponent<IPropsConfirmation> = (props: IPropsConfirmation) => {
  const { handleSubmit, handleClose } = props;
  const user: User = useSelector(getUser);

  console.log('user', user);

  const handleSave = () => handleSubmit();
  //   console.log(handleClose);
  const handleCancel = () => handleClose();

  return (
    <>
      <div className="row text-left">
        <div className="col-md-12 fontFourteen">Not Available On This Day!</div>
        <div className="col-md-12 fontFourteen">
          Are You Sure For
          {user.role === ROLE_DIRECTOR ? ' Requesting ' : ' Submitting '}
          Job?
        </div>
      </div>
      <br />
      <div style={{ display: 'flex', justifyContent: 'end', columnGap: '15px' }}>
        <Button
          cssClass={Style.submitBtn}
          clickHandler={handleSave}
          label={`${user.role === ROLE_DIRECTOR ? 'Request' : 'Submit'} anyways`}
        />
        <Button type="customCancelBtn" clickHandler={handleCancel} label="Cancel" />
      </div>
    </>
  );
};

export default ConfirmationModal;
