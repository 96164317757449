import React, { FunctionComponent } from 'react';
import BookingItem from 'components/bgPerformer/Booking/BookingItem/BookingItem';
import Style from 'components/bgPerformer/Booking/Booking.module.css';
import { BookingRequest, BookingRequestList } from 'modules/jobs/types';
import { BookingStatus } from 'modules/user/types';
import calendarRedSvg from 'assets/svg/calendar-red.svg';
import calendarYellowSvg from 'assets/svg/pending.svg';
import { useDispatch } from 'react-redux';
import { openJobDetails } from 'store/actions/job.actions';
import { setSelectedTab } from 'store/actions/auth.actions';

interface DetailsProps {
  bookingRequests?: BookingRequestList;
  type: BookingStatus;
}
const Details: FunctionComponent<DetailsProps> = (props: DetailsProps) => {
  const { bookingRequests, type } = props;

  const dispatch = useDispatch();

  const handleDetail = (item?: BookingRequest) => {
    if (item) {
      document.body.click();
      dispatch(openJobDetails(item.jobId, item));
    }
  };

  const handleRedirectSubmission = () => {
    dispatch(setSelectedTab('2'));
  };

  return (
    <>
      <div className={`mb-4 ${Style.bookingWrapper}`}>
        {type === 'booked' ? (
          <div className={Style.bookingHeader}>
            <img src={calendarRedSvg} alt="" />
            <p className={`mb-0 ${Style.bookingHeading}`}>Bookings</p>
          </div>
        ) : (
          <div className={Style.bookingHeader}>
            <img src={calendarYellowSvg} alt="" />
            <p className={`mb-0 ${Style.bookingHeading}`}>Pending Bookings</p>
          </div>
        )}

        <div className={`customScrollBar ${Style.bookingItemSec}`}>
          {bookingRequests && bookingRequests.length > 0 ? (
            bookingRequests.map((job: BookingRequest) => (
              <BookingItem key={job.id} handleDetail={() => handleDetail(job)} job={job} />
            ))
          ) : (
            <div
              className="text-center fontFourteen mb-3"
              onClick={handleRedirectSubmission}
              onKeyPress={handleRedirectSubmission}
              role="button"
              tabIndex={0}
            >
              start submitting to jobs now
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Details.defaultProps = {
  bookingRequests: [],
};

export default Details;
