import React, { FunctionComponent } from 'react';
import Style from 'components/Producer/Matrix/Items/Items.module.css';

interface MatrixContactItemProps {
  title: string;
  icon: string;
  cssClass: string;
  handleClick?: (e: React.FormEvent<HTMLDivElement>) => void;
}
const MatrixContactItem: FunctionComponent<MatrixContactItemProps> = (props: MatrixContactItemProps) => {
  const { title, icon, cssClass, handleClick } = props;
  return (
    <div
      className={`d-flex align-items-center ${cssClass} ${Style.mainSection}`}
      onClick={handleClick}
      onKeyUp={handleClick}
      tabIndex={0}
      role="button"
    >
      <img src={icon} alt="" className="mr-3" />
      <p className="mb-0">{title}</p>
    </div>
  );
};

export default MatrixContactItem;
