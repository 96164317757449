import React, { FunctionComponent } from 'react';
import topHeader from 'components/topHeader/topHeader.module.css';
import { Link } from 'react-router-dom';

const Header: FunctionComponent = () => (
  <header>
    <div className={topHeader.topHeading}>
      <h1>
        <Link to="/Home">
          <span className="mb-0">Casting</span>
          PAX
        </Link>
      </h1>
    </div>
  </header>
);

export default Header;
