import React from 'react';
import styled from 'styled-components';
import downloadIcon from 'assets/svg/downloadBlueIcon.svg';

interface Props {
  clickHandler?: () => void;
}

const DownloadAll: React.FC<Props> = (props: Props) => {
  const { clickHandler } = props;

  const handleDownload = () => {
    const emptyPdf = new Blob([''], { type: 'application/pdf' });

    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(emptyPdf);
    anchor.download = 'empty.pdf';

    anchor.click();

    URL.revokeObjectURL(anchor.href);

    if (clickHandler) {
      clickHandler();
    }
  };

  return (
    <div onClick={handleDownload} onKeyPress={handleDownload} role="button" tabIndex={0}>
      <Container>
        <ImageBox src={downloadIcon} alt="download" />
        <p>Download All</p>
      </Container>
    </div>
  );
};

export default DownloadAll;

const Container = styled.div`
  margin-left: 5px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: #e9f2fd;
  border-radius: 8px;
  padding: 4px 7px;

  & > p {
    font-size: 12px;
    margin-bottom: 0;
    line-height: 12px !important;
    color: #4b4d67 !important;
    font-weight: 400 !important;
  }
`;

const ImageBox = styled.img`
  background: transparent;
  cursor: pointer;
`;
