import React, { FunctionComponent, useEffect, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import Style from 'containers/bgPerformer/Calls/BgCalls.module.css';
import CastingCalls from 'components/bgPerformer/Calls/index';
import { useSelector } from 'react-redux';
import { getScreenWidth } from 'store/selector/general.selector';
import { ScreenSize } from 'modules/general/general.type';
import { SCREEN_SIZE_MOBILE } from 'store/castingPax.constants';

interface CallsProps {
  castingCallRequestBegin: Function;
}
const Calls: FunctionComponent<CallsProps> = (props) => {
  const { castingCallRequestBegin } = props;
  const [selectedType, setSelectedType] = useState<string>('');
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);
  useEffect(() => {
    handleTabChange('current');
  }, []);

  const handleTabChange = (type: string) => {
    castingCallRequestBegin({ type, page: 1 });
    setSelectedType(type);
  };

  const handleLoadMore = (page: number) => {
    castingCallRequestBegin({ type: selectedType, page });
  };
  if (currentScreenSize === SCREEN_SIZE_MOBILE) {
    return (
      <>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <div className="row callWrapper">
            <div className={`col-sm-12 p-0 ${Style.pillsWrapper}`}>
              <Nav variant="pills" className={Style.navPills}>
                <Nav.Item className={Style.navItem}>
                  <Nav.Link
                    className={`${Style.navLink}  ${Style.navLinkFirstItem}`}
                    onSelect={() => handleTabChange('current')}
                    eventKey="first"
                  >
                    Related Jobs
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className={Style.navItem}>
                  <Nav.Link
                    className={`${Style.navLink} ${Style.navLinkSecondItem}`}
                    onSelect={() => handleTabChange('recent')}
                    eventKey="second"
                  >
                    Recent
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className={Style.navItem}>
                  <Nav.Link
                    className={`${Style.navLink} ${Style.navLinkSecondItem}`}
                    onSelect={() => handleTabChange('cattle')}
                    eventKey="third"
                  >
                    Crowd calls
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className={Style.navItem}>
                  <Nav.Link
                    className={`${Style.navLink} ${Style.navLinkSecondItem}`}
                    onSelect={() => handleTabChange('almost')}
                    eventKey="fourth"
                  >
                    Almost closed
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className={`col-sm-12 px-0 ${Style.tabContentWrapper}`}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <CastingCalls handleLoadMore={handleLoadMore} />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <CastingCalls handleLoadMore={handleLoadMore} />
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <CastingCalls handleLoadMore={handleLoadMore} />
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <CastingCalls handleLoadMore={handleLoadMore} />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </>
    );
  }

  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="row callWrapper">
          <div className={`col-sm-12 p-0 ${Style.pillsWrapper}`}>
            <Nav variant="pills" className={Style.navPills}>
              <Nav.Item className={Style.navItem}>
                <Nav.Link
                  className={`${Style.navLink}  ${Style.navLinkFirstItem}`}
                  onSelect={() => handleTabChange('current')}
                  eventKey="first"
                >
                  Related Jobs
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={Style.navItem}>
                <Nav.Link
                  className={`${Style.navLink} ${Style.navLinkSecondItem}`}
                  onSelect={() => handleTabChange('recent')}
                  eventKey="second"
                >
                  Recent
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={Style.navItem}>
                <Nav.Link
                  className={`${Style.navLink} ${Style.navLinkSecondItem}`}
                  onSelect={() => handleTabChange('cattle')}
                  eventKey="third"
                >
                  Crowd calls
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={Style.navItem}>
                <Nav.Link
                  className={`${Style.navLink} ${Style.navLinkSecondItem}`}
                  onSelect={() => handleTabChange('almost')}
                  eventKey="fourth"
                >
                  Almost closed
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className={`col-sm-12 ${Style.tabContentWrapper}`}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <CastingCalls handleLoadMore={handleLoadMore} />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <CastingCalls handleLoadMore={handleLoadMore} />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <CastingCalls handleLoadMore={handleLoadMore} />
              </Tab.Pane>
              <Tab.Pane eventKey="fourth">
                <CastingCalls handleLoadMore={handleLoadMore} />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </>
  );
};

export default Calls;
