import React, { FunctionComponent } from 'react';
import Style from 'components/Producer/Matrix/UserManagementItem/ShowAccess/ShowAccessItem.module.css';
import cancelSvg from 'assets/svg/close.svg';
import { ShowTeamMember } from 'modules/Show/show.types';

interface ShowItemProps {
  item: ShowTeamMember;
  onCancelAccess: Function;
}
const ShowItem: FunctionComponent<ShowItemProps> = (props: ShowItemProps) => {
  const { item, onCancelAccess } = props;
  const handleClick = () => onCancelAccess(item);

  return (
    <div className={`d-flex justify-content-between align-items-center ${Style.listMainWrapper}`}>
      <p className="mb-0">{item.showId.title}</p>
      <div onClick={handleClick} onKeyPress={handleClick} role="button" tabIndex={0}>
        <img src={cancelSvg} alt="" />
      </div>
    </div>
  );
};

export default ShowItem;
