import subtractDays from './subtractDate';

const getDaysBefore = (numOfDays: number, originDate: Date = new Date()) => {
  const dates = [];
  for (let i = numOfDays; i > 0; i -= 1) {
    dates.push(subtractDays(i, originDate));
  }

  return dates;
};

export default getDaysBefore;
