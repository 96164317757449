import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { ImageFolderItem, ImageFolderList, PerformerImage, PerformerImageList, User } from 'modules/user/types';
import Style from 'components/bgPerformer/Images/Image.module.css';
import Dialog from 'components/bgPerformer/Images/Dialog';
import plusSvg from 'assets/svg/plus-circle.svg';
import arrowUpSvg from 'assets/svg/up-arrow-filled.svg';
import arrowDownSvg from 'assets/svg/down-arrow-filled.svg';
import Button from 'components/controls/button/button';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ChangeImageOrder, IProfileFileParam, IUserImageParams } from 'modules/params/param.type';
import { isValidFile } from 'helpers/utils';
import DragablePhoto from 'components/bgPerformer/Images/DragablePhoto';
import performerApi from 'store/services/performer.services';
import CPModal from 'components/Modal/CPModal';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { deleteImageFiles, updateFileCounter } from 'store/actions/performer.actions';
import { fileUploadErros } from 'store/selector/performer.selector';

interface BasicInfoProps {
  user: User;
  list: ImageFolderList;
  loading: boolean;
  fileUploadHandler: Function;
  changeImageOrderBegin: Function;
  deleteImageBegin: Function;
  openFullViewImage: Function;
  counter: number;
  newFolderId: string;
  updateCounter: Function;
  editableId: string;
  totalUploadedImagesCount: number;
}

const BgAlbum: FunctionComponent<BasicInfoProps> = (props: BasicInfoProps) => {
  const {
    user,
    list,
    fileUploadHandler,
    deleteImageBegin,
    changeImageOrderBegin,
    openFullViewImage,
    counter,
    updateCounter,
    newFolderId,
    editableId,
    loading,
    totalUploadedImagesCount,
  } = props;
  const [imageDialog, setImageDialog] = useState<boolean>(false);
  const [params, setParams] = useState<IUserImageParams>({});
  const [dialogType, setDialogType] = useState<string>('img');
  const [folderList, setFolderList] = useState<ImageFolderList>(list);
  const [messagePopup, setMessagePopup] = useState<boolean>(false);
  const [isSubscriptionExpire, setIsSubscriptionExpire] = useState<boolean>(false);
  const [fileLoader, setFileLoader] = useState<boolean>(false);
  const [totalFileIndex, setTotalFileIndex] = useState<number>(-1);
  const [uploadingFiles, setUploadingFiles] = useState<any>([]);
  const [uploadingFolder, setUploadingFolder] = useState<string>('');
  const fileErrors = useSelector(fileUploadErros);
  const dispatch = useDispatch();

  useEffect(() => {
    if (list) {
      setFolderList(list);
    }
  }, [list]);

  useEffect(() => {
    if (params.sequence) params.sequence += 1;
    if (counter + fileErrors.length >= totalFileIndex) {
      setFileLoader(false);
      updateCounter();
      setTotalFileIndex(-1);
      setUploadingFiles([]);
      if (uploadingFolder !== '') handleImageDialog(false);
      setUploadingFolder('');
    }

    if (totalFileIndex !== -1 && counter - fileErrors.length < totalFileIndex) handleUploadFiles();
  }, [counter]);

  useEffect(() => {
    if (uploadingFiles.length > 0) {
      let showToastMessage = '';
      if (imageLimitExceeds) {
        if (isSubscriptionExpire) {
          showToastMessage = 'Your Subscription expire';
        } else {
          if (!folderList[0].isDefault) setMessagePopup(true);
          showToastMessage = 'Image Limit Exceeds';
        }
        if (showToastMessage) {
          toast.error(showToastMessage);
          setImageDialog(false);
          setUploadingFiles([]);
          setUploadingFolder('');
          setTotalFileIndex(-1);
        }
      } else {
        handleUploadFiles();
      }
    }
  }, [uploadingFiles]);

  useEffect(() => {
    if (!loading) {
      setImageDialog(false);
    }
  }, [loading]);

  const inputFile = useRef<HTMLInputElement>(null);

  const handleClick = (type: string, folderId?: string, sequence?: string) => {
    setParams({ folderId, sequence });
    setDialogType(type);
    setImageDialog(true);
  };

  const imageLimitExceeds = useMemo(() => {
    let isDefaultFolder: any;
    let maxPhotos = 5;
    if (user.subscription) {
      maxPhotos = user.subscription.photos;
    }
    const uploadedImages = folderList.reduce((a: number, b: ImageFolderItem) => {
      let num = a;
      if (b.images) num += b.images.length;
      return num;
    }, 0);
    if (uploadedImages + uploadingFiles.length <= maxPhotos && totalUploadedImagesCount > maxPhotos) {
      setIsSubscriptionExpire(true);
      return true;
    }
    if (uploadedImages + uploadingFiles.length <= maxPhotos) {
      if (params.folderId) isDefaultFolder = folderList.filter((f: ImageFolderItem) => f.id === params.folderId);
      if (isDefaultFolder && isDefaultFolder[0]) {
        if (isDefaultFolder[0].images.length + uploadingFiles.length > 5 && isDefaultFolder[0].isDefault) return true;
        if (isDefaultFolder[0].images.length + uploadingFiles.length > 12 && !isDefaultFolder[0].isDefault) {
          return true;
        }
        if (uploadingFiles.length > 12 && uploadingFolder === '' && !isDefaultFolder[0].isDefault) {
          console.log('here');
          return true;
        }
      }

      if (uploadingFiles.length > 12 && uploadingFolder !== '') return true;
    }
    if (uploadedImages + uploadingFiles.length > maxPhotos) return true;
    return false;
  }, [folderList, uploadingFiles]);

  const handleImageChange = async (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const file: any = event.currentTarget.files[0];
      if (file) {
        const message = isValidFile(file, 'image');
        if (message.length > 0) {
          toast.error(message);
          return;
        }
        // const isUpload = await isValidImageHeightWidth(file);
        // if (!isUpload) {
        //   dispatch(updateFileCounter(file));
        //   return;
        // }

        const formData = new FormData();
        if (params) {
          if (params.folderId) formData.append('folderId', params.folderId);
          if (params.sequence) formData.append('sequence', params.sequence);
          if (params.imageId) formData.append('imageId', params.imageId);
          if (params.prevFileName) formData.append('prevFileName', params.prevFileName);
        }
        formData.append('profilePicUrl', file);
        formData.append('id', file.id);
        const obj: IProfileFileParam = {
          params: formData,
        };
        fileUploadHandler(obj);
      }
    }
  };

  const handleUploadFiles = async () => {
    if (totalFileIndex === -1 || counter === -1 || counter > totalFileIndex) return;

    if (!uploadingFiles[counter]) return;

    const isUpload = await isValidImageHeightWidth(uploadingFiles[counter].file);

    if (!isUpload) {
      toast.error('Image size should be 250px*250px');
      dispatch(updateFileCounter(uploadingFiles[counter].id));
      return;
    }

    const formData = new FormData();
    if (params) {
      if (params.folderId) formData.append('folderId', params.folderId || '');
      if (params.sequence) formData.append('sequence', params.sequence || '1');
    }
    if (uploadingFolder !== '') {
      const folderSeq: any = folderList.length + 1;
      if (newFolderId === '') {
        formData.append('folderName', uploadingFolder);
        formData.append('folderSequence', folderSeq);
      } else {
        formData.append('folderId', newFolderId);
      }
      const seq = counter + 1;
      formData.append('sequence', seq.toString());
    }
    if (uploadingFiles) {
      formData.append('id', uploadingFiles[counter].id);
      formData.append('profilePicUrl', uploadingFiles[counter].file);
    }
    const obj: IProfileFileParam = {
      params: formData,
    };
    setFileLoader(true);
    fileUploadHandler(obj);
  };

  const uploadImage = async (file: File[], folderName?: string) => {
    setTotalFileIndex(file.length);
    if (folderName) setUploadingFolder(folderName);
    setUploadingFiles(file);
  };

  const isValidImageHeightWidth = (file: File) =>
    new Promise<boolean>((resolve) => {
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        if (img.width >= 250 && img.height >= 250) {
          resolve(true);
        }
        resolve(false);
      };
      img.onerror = () => resolve(false);
    });

  const handleEdit = (photo: PerformerImage) => {
    setParams({
      folderId: photo.folderId,
      sequence: photo.sequence.toString(),
      imageId: photo.id,
      prevFileName: photo.imageFile,
    });
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const reorder = (items: PerformerImageList, startIndex: number, endIndex: number) => {
    const result = Array.from(items);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const reorderFolder = (items: ImageFolderList, startIndex: number, endIndex: number) => {
    const result = Array.from(items);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getList = (index: any) => {
    if (index !== -1) return folderList[index].images || [];
    return [];
  };

  const getFolderIndex = (id: string) => folderList.findIndex((x: ImageFolderItem) => x.id === id);

  const updateFolderList = (index: number, imgesList: PerformerImageList) => {
    if (index !== -1 && folderList) {
      const prev = folderList;
      prev[index].images = imgesList;
      setFolderList(prev);
      const orderList = imgesList.map((x: PerformerImage, seq: number) => {
        const obj: ChangeImageOrder = { id: x.id, folderId: x.folderId, sequence: seq + 1 };
        return obj;
      });
      changeImageOrderBegin({ images: orderList });
    }
  };

  const onDragEnd = (dragData: any, provider: any) => {
    const { source, destination } = dragData;
    if (!provider || !source.droppableId || !destination.droppableId) {
      return;
    }
    console.log('Data', source.droppableId, destination.droppableId);
    const sourceFolderIndex = getFolderIndex(source.droppableId);
    const destFolderIndex = getFolderIndex(destination.droppableId);

    if (source.droppableId === destination.droppableId) {
      const items = reorder(getList(sourceFolderIndex), source.index, destination.index);
      updateFolderList(sourceFolderIndex, items);
    } else {
      if (folderList[destFolderIndex].isDefault) {
        const folderTtlImages = folderList[destFolderIndex].images;
        if (folderTtlImages && folderTtlImages.length >= 5) {
          return;
        }
      }
      const sourceClone = Array.from(getList(sourceFolderIndex));
      const destClone = Array.from(getList(destFolderIndex));
      const [removed] = sourceClone.splice(source.index, 1);

      removed.folderId = folderList[destFolderIndex].id;

      destClone.splice(destination.index, 0, removed);
      updateFolderList(sourceFolderIndex, sourceClone);
      updateFolderList(destFolderIndex, destClone);
    }
  };

  const getProfileImages = (imgList: PerformerImageList, folder: ImageFolderItem) => {
    const imagesList = [...imgList];
    if ((folder.isDefault && imgList.length < 5) || (!folder.isDefault && imgList.length < 12)) {
      imagesList.push({
        id: Math.random().toString(),
        imageType: 'img',
        imageFile: plusSvg,
        folderId: folder.id,
        folderName: 'default',
        image: plusSvg,
        sequence: imgList.length + 1,
        isDisable: true,
      });
    }
    return imagesList.map((image: PerformerImage, index: number) => (
      <DragablePhoto
        photo={image}
        index={index}
        key={image.id}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleUploadImage={handleClick}
        openFullViewImage={openFullViewImage}
        editableId={editableId}
      />
    ));
  };

  const handleDelete = (photo: PerformerImage) => {
    deleteImageBegin({ id: photo.id, folderId: photo.folderId });
  };

  const handleFolderNavigation = (prevPos: number, nextPos: number) => {
    if (nextPos > 1 && nextPos < folderList.length) {
      const newFolderList = reorderFolder(folderList, nextPos, prevPos);
      setFolderList(newFolderList);
      const orderList = newFolderList.map((x: ImageFolderItem, seq: number) => {
        const obj: ChangeImageOrder = { id: x.id, folderId: x.id, sequence: seq + 1 };
        return obj;
      });
      try {
        performerApi.saveFolderOrder({ images: orderList });
      } catch (error: any) {
        console.log('Error!', error.message);
      }
    }
  };

  const handleImageDialog = (event: boolean) => {
    setImageDialog(event);
    setFileLoader(false);
    dispatch(deleteImageFiles());
  };

  return (
    <>
      <input type="file" ref={inputFile} accept="image/*" className={Style.displayNone} onChange={handleImageChange} />

      <DragDropContext onDragEnd={onDragEnd}>
        {folderList.map((folder: ImageFolderItem, index: number) => (
          <div
            key={folder.id}
            className={`${
              !folder.isDefault ? Style.sortingWrapper : `${Style.sortingWrapper} ${Style.featuresWrapper}`
            }`}
          >
            <div
              className={`${Style.titleWrapper} ${!folder.isDefault && Style.ImageTitle} 
              ${index + 1 === folderList.length && Style.borderBottomDiv}`}
            >
              <div className="d-flex align-items-center w-100">
                {!folder.isDefault && (
                  <>
                    <div className="d-flex align-items-center flex-column">
                      <div
                        className={Style.arrowIconsWrapper}
                        onClick={() => {
                          handleFolderNavigation(index, index - 1);
                        }}
                        role="presentation"
                      >
                        <img src={arrowUpSvg} alt="arrow up" />
                      </div>
                      <div
                        className={Style.arrowIconsWrapper}
                        onClick={() => {
                          handleFolderNavigation(index, index + 1);
                        }}
                        role="presentation"
                      >
                        <img src={arrowDownSvg} alt="arrow down" />
                      </div>
                    </div>
                    <p className="mb-0 pl-2">{folder.name}</p>
                  </>
                )}
              </div>
              <div className={`w-100 ${Style.newLabelBtn}`}>
                {index + 1 === folderList.length && (
                  <Button
                    icon={plusSvg}
                    cssClass="addNewImage"
                    label="Create New Album"
                    type="primary"
                    clickHandler={() => {
                      handleClick('album');
                    }}
                  />
                )}
              </div>
            </div>
            <Droppable droppableId={folder.id} direction="horizontal">
              {(provided) => (
                <div
                  className={`SortImageWrapper ${Style.mainWrapper}`}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {getProfileImages(folder.images || [], folder)}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        ))}
      </DragDropContext>

      {imageDialog && (
        <Dialog
          fileLoader={fileLoader}
          closeDialog={handleImageDialog}
          fileUploadHandler={uploadImage}
          type={dialogType}
        />
      )}
      <CPModal isOkOnly isShow={messagePopup} hideModal={() => setMessagePopup(false)}>
        <p>Limit exceed! Please upgrage to pro</p>
      </CPModal>
    </>
  );
};

export default BgAlbum;
