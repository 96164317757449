import { connect } from 'react-redux';
import ShowSelection from 'components/PerformerProfile/ShowSelection/ShowSelection';
import { jobRequestFromCDBegin } from 'store/actions/job.actions';
import { Show } from 'modules/Show/show.types';
import { getSelectedShowId, getShowLists } from 'store/selector/show.selector';
import { showToast } from 'store/actions/general.actions';

const mapStateToProps = (state: Show) => {
  const selectedMainShow = getSelectedShowId(state);
  const shows = getShowLists(state);
  return { selectedMainShow, shows };
};

const mapDispatchToProps = {
  jobRequestFromCDBegin,
  showToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowSelection);
