/* eslint-disable import/prefer-default-export */
const PerformerRowData = [
  {
    title: 'Lorem Lipsum',
    text: 'lorem',
  },
  {
    title: 'Lorem Lipsum',
    text: 'ipsium',
  },
  {
    title: 'Lorem Lipsum',
    text: 'lorem',
  },
  {
    title: 'Lorem Lipsum',
    text: 'lorem    ',
  },
  {
    title: 'Lorem Lipsum',
    text: 'lorem',
  },
];

const PerformerRowBottomData = [
  {
    title: 'Lorem Lipsum',
    text: 'lorem',
  },
  {
    title: 'Lorem Lipsum',
    text: 'ipsium',
  },
  {
    title: 'Lorem Lipsum',
    text: 'lorem',
  },
];

const AttachedFileData = [
  {
    background: 'rgba(47, 128, 237, 0.1);',
    color: '#2F80ED;',
  },
  {
    background: 'rgba(235, 87, 87, 0.1)',
    color: '#EB5757;',
  },
  {
    background: 'rgba(39, 174, 96, 0.1);',
    color: '#27AE60;',
  },
];

export { PerformerRowData, PerformerRowBottomData, AttachedFileData };
