import React, { FunctionComponent } from 'react';
import Style from 'components/bgPerformer/Calls/Calls.module.css';
import Button from 'components/controls/button/button';
import reloadSvg from 'assets/svg/reload.svg';
import CallItem from 'components/bgPerformer/Calls/Item/CallItem';
import { ShowDayJob, ShowDayJobList } from 'modules/Show/show.types';
import { BookingRequest, BookingRequestList, Pagination } from 'modules/jobs/types';
import { JOB_STATUS_REQUESTED, SHOW_JOB_STATUS } from 'store/castingPax.constants';

interface CastingCallsProps {
  castingRequests?: ShowDayJobList;
  openJobDetails: Function;
  loadingCalls: boolean;
  bookings: BookingRequestList;
  pagination: Pagination;
  handleLoadMore: Function;
}
const CastingCalls: FunctionComponent<CastingCallsProps> = (props) => {
  const { castingRequests, loadingCalls, openJobDetails, bookings, pagination, handleLoadMore } = props;
  const searchBooking = (id: string) => {
    const index = bookings.findIndex((x: BookingRequest) => x.jobId.id === id);
    if (index !== -1) {
      return bookings[index];
    }
    return undefined;
  };

  const checkInviteCall = (job: ShowDayJob) => {
    const book = searchBooking(job.id);
    if (job.status === SHOW_JOB_STATUS.PUBLIC) return true;
    if (book !== undefined && book.status === JOB_STATUS_REQUESTED && job.status === SHOW_JOB_STATUS.INVITE) {
      return true;
    }
    return false;
  };

  const showLoading = () => (
    <table className="table table-layout-fixed">
      <tbody className="MobileResponsiveView">
        {castingRequests &&
          castingRequests.map(
            (job: ShowDayJob) =>
              checkInviteCall(job) && (
                <CallItem key={job.id} job={job} booking={searchBooking(job.id)} handleDetail={openJobDetails} />
              ),
          )}
      </tbody>
    </table>
  );

  return (
    <div className="text-center pb-30 pt-3">
      <div className="customScrollBar callTableWrapper">{showLoading()}</div>
      {castingRequests?.length !== 0 && pagination && pagination.page < pagination.nextPage && (
        <Button
          label="Load More"
          icon={loadingCalls ? '' : reloadSvg}
          showLoading={loadingCalls}
          clickHandler={() => {
            handleLoadMore(pagination.nextPage);
          }}
          cssClass={Style.loadMoreBtn}
        />
      )}
    </div>
  );
};

export default CastingCalls;
