import { connect } from 'react-redux';
import { User } from 'modules/user/types';
import { setPerformerForView } from 'store/actions/myPerformer.actions';
import PerformerView from 'components/MyPerformerList/PerformerView/PerformerView';
import { getSelectedPerformer } from 'store/selector/myPerformer.selector';

const mapStateToProps = (state: User) => {
  const performer = getSelectedPerformer(state);
  return {
    performer,
  };
};

const mapDisptcahToProps = {
  setPerformerForView,
};

export default connect(mapStateToProps, mapDisptcahToProps)(PerformerView);
