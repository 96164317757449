import { all, takeEvery, put, call } from 'redux-saga/effects';
import { NotificationList } from 'modules/general/general.type';
import { generalActions } from 'store/actions';
import ACTIONS from 'store/castingPax.constants';
import { generalApi } from 'store/services/general.services';
import ApiResponse from 'modules/response/response.types';
import { IIDS } from 'modules/params/param.type';

function* fetchGeneralSetting() {
  try {
    yield put(generalActions.getSettings.pending);
    const response: ApiResponse = yield call(generalApi.fetchGeneralSetting);
    yield put(generalActions.getSettings.success(response.data));
  } catch (error) {
    yield put(generalActions.getSettings.error(error.message));
  }
}

function* fetchNotifications() {
  try {
    yield put(generalActions.getNotifications.pending);
    const response: NotificationList = yield call(generalApi.fetchNotifications);
    yield put(generalActions.getNotifications.success(response));
  } catch (error) {
    yield put(generalActions.getNotifications.error(error.message));
  }
}

function* updateNotificationStatus(state: { type: string; payload: IIDS }) {
  try {
    yield call(generalApi.markReadNotification, state.payload);
  } catch (error) {
    console.log('Error', error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.GENERAL_SETTING_BEGIN, fetchGeneralSetting),
    takeEvery(ACTIONS.NOTIFICATIONS_BEGIN, fetchNotifications),
    takeEvery(ACTIONS.SET_NOTIFICATION_FOR_MARK_READ_BEGIN, updateNotificationStatus),
  ]);
}
