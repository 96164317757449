import React, { FunctionComponent } from 'react';
import { EpisodeDayList, ShowWorkingDay } from 'modules/Show/show.types';
import Button from 'components/controls/button/button';
import EpisodeDayInput from 'components/CreateShow/ShowEpisode/EpisodeDay/EpisodeDayInput';
import plusSvg from 'assets/svg/plus.svg';

interface ShowDateContainerProps {
  dates: EpisodeDayList;
  handleShowDate: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleChangeShowDate: Function;
  createShowClass?: string;
}
const ShowDateContainer: FunctionComponent<ShowDateContainerProps> = (props: ShowDateContainerProps) => {
  const { dates, handleShowDate, handleChangeShowDate, createShowClass } = props;
  return (
    <div>
      {dates.map((obj: ShowWorkingDay) => (
        <EpisodeDayInput
          createShowClass={createShowClass}
          key={obj.id}
          episodeDayData={obj}
          handleChangeEpisodeData={handleChangeShowDate}
        />
      ))}
      <Button
        label=" Add Date"
        cssClass="mt-3 mb-3 fontFourteen font-weight-normal"
        icon={plusSvg}
        clickHandler={handleShowDate}
      />
    </div>
  );
};

export default ShowDateContainer;
