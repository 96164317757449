import React, { FunctionComponent, useState } from 'react';
import Style from 'components/castingDirector/PopOvers/EmailDetail/EmailDetail.module.css';
import checkSvg from 'assets/svg/only-check.svg';
import { EmailTemplate, EmailTemplateList } from 'modules/general/general.type';
import showApi from 'store/services/show.services';
import { useDispatch } from 'react-redux';
import { updatePrelimEmailTemplateId } from 'store/actions/show.actions';
// import editSvg from 'assets/svg/edit.svg';

interface EmailDetailProps {
  list?: EmailTemplateList;
  handleNewTemplate: Function;
  sendCallToSpecificBg: Function;
  workingDayId: string;
  emailTemplate: string;
  handleEdit?: Function;
}
const MatrixEmailDetail: FunctionComponent<EmailDetailProps> = (props: EmailDetailProps) => {
  const { list, handleNewTemplate, sendCallToSpecificBg, workingDayId, emailTemplate, handleEdit } = props;
  const [selectedTemplate, setSelectedTemplate] = useState<string>(emailTemplate);
  const dispatch = useDispatch();
  const handleClick = () => {
    document.body.click();
    handleNewTemplate(true);
  };

  console.log(handleEdit);
  const handleSelectTemplate = (template: EmailTemplate) => {
    setSelectedTemplate(selectedTemplate === template.id ? '' : template.id);
    if (template.id) {
      showApi
        .setMatrixEmailTemplate({ workingDayId, templateId: template.id })
        .then((res) => {
          if (res) dispatch(updatePrelimEmailTemplateId({ showId: workingDayId, templateId: template.id }));
        })
        .catch((err) => err);
    }
  };

  const sendCallToAll = () => {
    sendCallToSpecificBg(-2, null);
  };

  const handleCall = () => {
    sendCallToSpecificBg(-1, null);
  };
  // const handleEditTemplate = (item: EmailTemplate) => {
  //   if (handleEdit) {
  //     handleNewTemplate(false);
  //     handleEdit(true, item);
  //   }
  // };

  return (
    <>
      <div className={Style.mainWrapper}>
        <div
          className={`d-flex ${Style.header}`}
          onClick={() => handleClick()}
          onKeyUp={() => handleClick()}
          role="button"
          tabIndex={0}
        >
          <p className="mb-0">+ Create new template</p>
        </div>
        {list &&
          list.map((item: EmailTemplate) => (
            <div
              className={`d-flex justify-content-between align-items-center ${Style.itemWrapper}`}
              onClick={() => {
                handleSelectTemplate(item);
              }}
              onKeyPress={() => {
                handleSelectTemplate(item);
              }}
              role="button"
              tabIndex={0}
            >
              <p className="mb-0">{item.title}</p>
              {selectedTemplate === item.id && <img src={checkSvg} alt="" className={Style.checkIcon} />}
              {/* <span
                style={{ float: 'right' }}
                onClick={() => handleEditTemplate(item)}
                onKeyPress={() => handleEditTemplate(item)}
                role="button"
                tabIndex={0}
              >
                <img src={editSvg} alt="" />
              </span> */}
            </div>
          ))}

        <div
          className={`d-flex ${Style.callSheetBtn}`}
          onClick={sendCallToAll}
          onKeyPress={sendCallToAll}
          tabIndex={0}
          role="button"
        >
          <p className="mb-0">Send call times to all</p>
        </div>
        <div
          className={`d-flex justify-content-between align-items-center ${Style.footer}`}
          onClick={handleCall}
          onKeyPress={handleCall}
          tabIndex={0}
          role="button"
        >
          <p className="mb-0">Send call to Selected</p>
        </div>
      </div>
    </>
  );
};

export default MatrixEmailDetail;
