import React, { FunctionComponent } from 'react';
import ShowJobList from 'components/ShowJob';
import ShowJobEntry from 'components/ShowJob/ShowJobEntry';
import Style from 'containers/ShowJobContainer/ShowRoleContainer.module.css';

const ShowJobContainer: FunctionComponent = () => (
  <>
    <div className={Style.showJobsWrapper}>
      <div className={Style.roleListWrapper}>
        <ShowJobList />
      </div>
      <div className={Style.roleEntryWrapper}>
        <ShowJobEntry />
      </div>
    </div>
  </>
);

export default ShowJobContainer;
