import {
  IBGSkillDeleteParams,
  IBGSkillParams,
  IChangeImageOrder,
  IChangeRentalType,
  IIDParams,
  IPerformerDetail,
  IPerformerVoucherParam,
  IProfileFileParam,
  IRemoveRental,
  IRemoveResume,
  IUpdatePerformerVoucherParam,
  IUserVoucher,
} from 'modules/params/param.type';
import { apiCall } from 'store/services/service';
import {
  URL_ADD_SKILLS,
  URL_PERFORMER_DETAILS,
  URL_UPLOAD_IMAGE,
  URL_CHANGE_IMAGE_ORDER,
  URL_DELETE_IMAGE,
  URL_REMOVE_RESUME,
  URL_CHANGE_FOLDER_ORDER,
  URL_DELETE_SKILL,
  URL_UPLOAD_RENTAL_IMAGE,
  URL_REMOVE_RENTAL_IMAGE,
  URL_UPDATE_RENTAL_TYPE,
  URL_SAVE_PERFORMER_VOUCHER,
  URL_GET_PERFORMER_VOUCHER,
  URL_PROOF_OF_RESIDENCY_DOCS,
  URL_REMOVE_RESIDENCY_DOCS,
  URL_UPLOAD_PERFORMER_FILES,
  URL_RENAME_RESIDENCY_DOCS,
  URL_CREATE_USER_VOUCHER,
  URL_PERFORMER_VOUCHER_STATUS,
} from './URL';

export const performerApi = {
  fetchPerformerProfile(params: IPerformerDetail) {
    return apiCall.post({ url: URL_PERFORMER_DETAILS, isAuthToken: true, params });
  },
  saveSkill(params: IBGSkillParams) {
    return apiCall.post({ url: URL_ADD_SKILLS, isAuthToken: true, params });
  },
  saveImage(params: IProfileFileParam) {
    return apiCall.filePost(URL_UPLOAD_IMAGE, params);
  },
  uploadPerformerFiles(params: any) {
    return apiCall.post({ url: URL_UPLOAD_PERFORMER_FILES, isAuthToken: true, params });
  },
  saveImageOrder(params: IChangeImageOrder) {
    return apiCall.post({ url: URL_CHANGE_IMAGE_ORDER, isAuthToken: true, params });
  },
  saveFolderOrder(params: IChangeImageOrder) {
    return apiCall.post({ url: URL_CHANGE_FOLDER_ORDER, isAuthToken: true, params });
  },
  deleteImage(params: IIDParams) {
    return apiCall.post({ url: URL_DELETE_IMAGE, isAuthToken: true, params });
  },
  deleteResume(user: IRemoveResume) {
    return apiCall.post({ url: URL_REMOVE_RESUME, isAuthToken: true, params: user });
  },
  deleteSkill(params: IBGSkillDeleteParams) {
    return apiCall.post({ url: URL_DELETE_SKILL, isAuthToken: true, params });
  },
  uploadRentalImage(params: IProfileFileParam) {
    return apiCall.filePost(URL_UPLOAD_RENTAL_IMAGE, params);
  },
  removeRentalImage(params: IRemoveRental) {
    return apiCall.post({ url: URL_REMOVE_RENTAL_IMAGE, isAuthToken: true, params });
  },
  updateRentalType(params: IChangeRentalType) {
    return apiCall.post({ url: URL_UPDATE_RENTAL_TYPE, isAuthToken: true, params });
  },
  savePerformerVoucher(params: IPerformerVoucherParam) {
    return apiCall.post({ url: URL_SAVE_PERFORMER_VOUCHER, isAuthToken: true, params });
  },
  getPerformerVoucher(params: IPerformerVoucherParam) {
    return apiCall.post({ url: URL_GET_PERFORMER_VOUCHER, isAuthToken: true, params });
  },
  getResidencyDocs() {
    return apiCall.get({ url: URL_PROOF_OF_RESIDENCY_DOCS, isAuthToken: true, params: {} });
  },
  deleteResidency(params: any) {
    return apiCall.post({ url: URL_REMOVE_RESIDENCY_DOCS, isAuthToken: true, params });
  },
  renameResidencyDocs(params: any) {
    return apiCall.post({ url: URL_RENAME_RESIDENCY_DOCS, isAuthToken: true, params });
  },
  craeteUserVoucher(params: IUserVoucher) {
    return apiCall.post({ url: URL_CREATE_USER_VOUCHER, isAuthToken: true, params });
  },
  updatePerformerVoucherStatus(params: IUpdatePerformerVoucherParam) {
    return apiCall.post({ url: URL_PERFORMER_VOUCHER_STATUS, isAuthToken: true, params });
  },
};

export default performerApi;
