import React, { FunctionComponent, useState } from 'react';
import editSvg from 'assets/svg/edit.svg';
import Style from 'components/castingDirector/MatrixItem/MatrixItem.module.css';
import CheckBox from 'components/controls/checkbox/checkBox';

interface IPerformerTimeProps {
  params: any;
  showModal?: Function;
  handleCellIconClick?: Function;
}
const PerformerTime: FunctionComponent<IPerformerTimeProps> = (props) => {
  const { params, handleCellIconClick, showModal } = props;
  const [checkRental, setCheckRental] = useState<boolean>(params.data.isRental);
  const [checkNDB, setCheckNDB] = useState<boolean>(params.data.NDB);
  const [checkAllowances, setCheckAllowances] = useState<boolean>(params.data.isAllowancesEnable);
  console.log(showModal);
  const lunchTimeList = () => {
    let { lunch } = params.data;
    if (!lunch) lunch = [];
    lunch = lunch.toString();
    return <>{lunch && <span>{lunch}</span>}</>;
  };

  const enableRentalField = (isCheck: boolean) => {
    const { data } = params;
    data.isRental = isCheck;
    setCheckRental(isCheck);
    if (handleCellIconClick) handleCellIconClick(params, isCheck);
  };

  const handleClick = () => {
    if (handleCellIconClick) {
      handleCellIconClick(params, false);
    }
  };
  const handleNDBCheck = (isCheck: boolean) => {
    const { data } = params;
    data.NDB = isCheck;
    setCheckNDB(isCheck);
    if (handleCellIconClick) handleCellIconClick(params, isCheck);
  };
  const handleAllowances = (isCheck: boolean) => {
    const { data } = params;
    data.isAllowancesEnable = isCheck;
    setCheckAllowances(isCheck);
    if (handleCellIconClick) handleCellIconClick(params, isCheck);
  };

  const checkDisable = () => {
    if (params.data.status === 'booked' && !params.data.invoiceId && !params.data.isDelete) return false;
    return true;
  };

  const handleShow = () => {
    if (!params.node.data.performerId) {
      return '';
    }
    const {
      colDef: { field },
    } = params;

    if (field === 'lunch') {
      return <>{lunchTimeList()}</>;
    }
    if (field === 'signInTime') {
      return (
        <>
          {params.data.signInTime && <span>{params.data.signInTime}</span>}
          <span
            style={{ float: 'right' }}
            onClick={() => handleClick()}
            onKeyPress={() => handleClick()}
            role="button"
            tabIndex={0}
          >
            {params.data.signInTime && <img src={editSvg} alt="" className={Style.exclamationMark} />}
          </span>
        </>
      );
    }
    if (field === 'rental') {
      return (
        <>
          {params.data.rental && (
            <span style={{ position: 'relative' }}>
              {typeof params.data.rental === 'string' && `$${params.data.rental}`}
            </span>
          )}

          <div style={{ position: 'absolute', bottom: '7px', left: '55px' }}>
            <CheckBox
              id={params.data.id}
              handleChecked={enableRentalField}
              defaultCheck={checkRental}
              isDisable={checkDisable()}
            />
          </div>
        </>
      );
    }
    if (field === 'NDB') {
      return (
        <>
          <div style={{ width: '20px', float: 'right', marginBottom: '30px' }}>
            <CheckBox
              id={`${params.data.id}-${Math.random().toString()}`}
              handleChecked={handleNDBCheck}
              defaultCheck={checkNDB}
              isDisable={checkDisable()}
            />
          </div>
        </>
      );
    }

    if (field === 'mileage' || field === 'mileageType') {
      return (
        <>
          {params.data.mileage && (
            <span style={{ position: 'relative' }}>{`${params.data.mileage}/${params.data.mileageType}`}</span>
          )}
          {!checkDisable() && (
            <span
              style={{ right: '0px', bottom: '-5px', position: 'absolute' }}
              onClick={() => handleClick()}
              onKeyPress={() => handleClick()}
              role="button"
              tabIndex={0}
            >
              <img src={editSvg} alt="" className={Style.exclamationMark} />
            </span>
          )}
        </>
      );
    }

    if (field === 'isAllowancesEnable') {
      return (
        <>
          <div style={{ bottom: '15px', position: 'relative' }}>
            <CheckBox
              id={`${params.data.id}-${Math.random().toString()}`}
              handleChecked={handleAllowances}
              defaultCheck={checkAllowances}
              isDisable={checkDisable()}
            />
          </div>
          {/* <span style={{ position: 'absolute', bottom: '-5px', right: '20px' }}>{params.data.rate}</span> */}
        </>
      );
    }

    return '';
  };

  return <>{handleShow()}</>;
};

export default PerformerTime;
