import React from 'react';
import styled from 'styled-components';
import addLogo from 'assets/svg/addVoucherGreen.svg';
import CreateVoucherModal from './CreateVoucherModal';

const CreateVoucherRenderer: React.FC<any> = (props) => {
  const {
    data: { performerId },
  } = props;
  console.log(performerId, '-----------------------here--------------------------');
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <Container>
      <Button onClick={() => setModalShow(true)}>
        <ImageButton src={addLogo} alt="button" />
      </Button>
      <CreateVoucherModal show={modalShow} performerId={performerId} onHide={() => setModalShow(false)} />
    </Container>
  );
};

export default CreateVoucherRenderer;

const ImageButton = styled.img`
  height: 25px;
  width: 25px;
`;

const Button = styled.button`
  background: blue;
  border: none;
  outline: none;
  border-radius: 50%;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
