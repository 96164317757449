import { NotificationList, ToastItem, MessageBoxItem, Notification } from 'modules/general/general.type';
import { IIDS } from 'modules/params/param.type';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const getSettings = {
  pending: {
    type: ACTIONS.GENERAL_SETTING.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.GENERAL_SETTING.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.GENERAL_SETTING.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const getSettingRequest = () => ({
  type: ACTIONS.GENERAL_SETTING_BEGIN,
  data: {},
});

export const showToast = (data: ToastItem) => ({
  type: ACTIONS.ADD_TOAST_IN_LIST,
  data,
});

export const removeToast = () => ({
  type: ACTIONS.REMOVE_TOAST_IN_LIST,
  data: '',
});

export const getNotifications = {
  pending: {
    type: ACTIONS.NOTIFICATIONS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: NotificationList) => ({
    type: ACTIONS.NOTIFICATIONS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.NOTIFICATIONS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const getNotificationsRequest = () => ({
  type: ACTIONS.NOTIFICATIONS_BEGIN,
  data: {},
});

export const addNotificationInList = (data: Notification) => ({
  type: ACTIONS.ADD_NEW_NOTIFICATION,
  data,
});

export const resetNewNotification = () => ({
  type: ACTIONS.RESET_NEW_NOTIFICATION_ICON,
  data: {},
});

export const showMessageBox = (data: MessageBoxItem) => ({
  type: ACTIONS.SHOW_MESSAGE_BOX,
  data,
});

export const clearMessageBox = () => ({
  type: ACTIONS.CLEAR_SHOW_MESSAGE_BOX,
  data: {},
});

export const setAllSkillFalse = () => ({
  type: ACTIONS.SET_ALL_SKILLS_FALSE,
});

export const markReadNotification = (payload: IIDS) => ({
  type: ACTIONS.SET_NOTIFICATION_FOR_MARK_READ_BEGIN,
  payload,
});

export const readNotifcation = (data: string) => ({
  type: ACTIONS.SET_NOTIFICATION_FOR_MARK_READ_LOCALLY,
  data,
});

export const screenSizeChange = (data: number) => ({
  type: ACTIONS.SET_SCREEN_WIDTH,
  data,
});
