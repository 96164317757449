import React, { FunctionComponent, useEffect, useState } from 'react';
import { NotificationList, Notification } from 'modules/general/general.type';
import NotificationView from 'components/TopNavBar/NotificationItem/View';
import NotificationViewed from 'components/TopNavBar/NotificationItem/Viewed';
import { useDispatch } from 'react-redux';
import { readNotifcation } from 'store/actions/general.actions';
import { openJobFromNotification, performerJobBookingNotificationView } from 'store/actions/job.actions';
import CPModal from 'components/Modal/CPModal';
import showApi from 'store/services/show.services';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';

interface NotificationItemProps {
  notifications: NotificationList;
  resetNewNotification: Function;
  closeNotificationView: Function;
}

const NotificationItem: FunctionComponent<NotificationItemProps> = (props: NotificationItemProps) => {
  const { notifications, resetNewNotification, closeNotificationView } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedNotification, setSelectedNotification] = useState<Notification>();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    resetNewNotification();
  }, []);

  const handleNotificationView = (item: Notification) => {
    let currentDate: any = moment();
    currentDate = currentDate.format('YYYY-MM-DD 00:00:00');
    let lastDate;
    if (item.data.dates) lastDate = item.data.dates.at(-1);
    else lastDate = null;

    const notiTypes = 'jobRequest,jobUpdate,pending';
    if (notiTypes.includes(item.type) && lastDate >= currentDate) {
      const notificationJobId = typeof item.data.jobId === 'object' ? item.data.jobId.id : item.data.jobId;
      dispatch(openJobFromNotification(notificationJobId));
      if (location.pathname === '/settings') history.push('/performer/home');
    } else if (item.type === 'showAccess') {
      setShowModal(true);
      setSelectedNotification(item);
    } else {
      if (item.data.jobId) dispatch(performerJobBookingNotificationView(item.data.jobId));
      if (location.pathname === '/settings') history.push('/performer/home');
    }
  };

  const handleRead = (id: string) => {
    dispatch(readNotifcation(id));
  };
  const handleModalClose = (status: boolean) => {
    setShowModal(false);
    closeNotificationView(false);
    if (status && selectedNotification) {
      showApi
        .acceptAhowAccessRequest({
          userId: selectedNotification.data.userId,
          showId: selectedNotification.data.showId,
          showRole: selectedNotification.data.showRole,
        })
        .catch((err) => err);
    }
  };

  return (
    <>
      {notifications.map((notification: Notification) => {
        if (notification.isSeen) {
          return (
            <NotificationViewed
              notification={notification}
              key={notification.id}
              handleNotificationView={handleNotificationView}
            />
          );
        }
        return (
          <NotificationView
            notification={notification}
            key={notification.id}
            handleRead={handleRead}
            handleNotificationView={handleNotificationView}
          />
        );
      })}
      <CPModal isShow={showModal} hideModal={handleModalClose} labelOk="Accept">
        {selectedNotification && (
          <>
            <h5>{selectedNotification.title}</h5>
            <p>{selectedNotification.message}</p>
          </>
        )}
      </CPModal>
    </>
  );
};

export default NotificationItem;
