import React, { useEffect, useMemo, useState } from 'react';
import BudgetExpert from 'components/Producer/Matrix/Budget/Popover/BudgetExport';
// import { Bar } from 'react-chartjs-2';
import Style from 'components/Producer/Matrix/Budget/CreateBudget.module.css';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import downloadArrowSvg from 'assets/svg/download-arrow.svg';
import arrowDownSvg from 'assets/svg/arrowDown.svg';
import { LOADER_STATUS } from 'store/castingPax.constants';
import html2canvas from 'html2canvas';
import Jspdf from 'jspdf';
import { MatrixChartData, MatrixChartStats, MatrixType, Show } from 'modules/Show/show.types';
// import printWithoutSvg from 'assets/svg/print-without-text.svg';
// import printSvg from 'assets/svg/print.svg';
// import plusYellowSvg from 'assets/svg/plus-yellow.svg';
// import editSvg from 'assets/svg/edit-background.svg';
import { getChartData, getSelectedShow } from 'store/selector/show.selector';
import { connect } from 'react-redux';
import { getTimeDifference } from 'helpers/utils';
import styled from 'styled-components';
import DaysWorked from '../components/DaysWorked';
import PendingReview from '../components/PendingReview';
import Breakdown from '../components/Breakdown';
import Status from '../components/Status';
import BgCordinator from '../components/BgCordinator';
import StyledBottomInfoTab from '../../../styles/show/show-info/BottomInfoTab.styled';
import InvoicePDF from '../../Invoice/index';
import Loader from '../../../../controls/Loader/Loader';
// import {  Show } from 'modules/Show/show.types';
interface Props {
  dayItem: MatrixType;
  chartData: any;
  selectedShow: Show;
  printRef: any;
  prevElement: any;
  pendingPerformer: Function;
  pdfType?: string;
  isPrint: boolean;
  setIsPrint?: Function;
  handleBreakDown: (e: React.FormEvent<HTMLDivElement>) => void;
}

const BottomInfoTab: React.FC<Props> = (props: Props) => {
  const { dayItem, chartData, selectedShow, printRef, prevElement, pendingPerformer, pdfType, handleBreakDown } = props;
  // const {
  //   data,
  //   handleAdd,
  //   handleEdit,
  //   handlePrint,
  //   handleExportDownload,
  //   printRef,
  //   exportLoading,
  //   loaderType,
  //   type,
  // } = props;
  // const { marginLeft, chartData, selectedShow } = props;
  // const [showSideNav, setShowSideNav] = useState(false);

  // const handlePrint = () => window.print();
  const [exportLoading, setExportLoading] = useState<number>(LOADER_STATUS.NA);
  const [loaderType, setLoaderType] = useState<string>('');
  const [chartType] = useState<string>('cost');
  const [show, setShow] = useState<Show>();
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [isPrint, setIsPrint] = useState<boolean>(false);
  const bookingChartData = useMemo(() => {
    const requiredData: number[] = [];
    const actualData: number[] = [];
    const color: string[] = [];
    let label: string[] = [];
    if (Object.keys(chartData).length > 0) {
      chartData.data.forEach((c: MatrixChartData) => {
        const pos: number = chartData.stats.findIndex((st: MatrixChartStats) => st.id === c.id);
        if (pos === -1) {
          requiredData.push(0);
          actualData.push(0);
          color.push('');
        } else {
          actualData.push(chartData.stats[pos].actualData);
          requiredData.push(chartType === 'budget' ? c.budget : chartData.stats[pos].count);
          color.push(chartData.stats[pos].color);
        }
      });
      label = chartData.data.map((x: any) => x.title);
    }

    const data = {
      labels: label,
      datasets: [
        {
          label: chartType === 'budget' ? 'Budget' : 'Required',
          stack: '2',
          order: 2,
          backgroundColor: chartType === 'budget' ? '#49D360' : '#E0E0E0',
          borderColor: chartType === 'budget' ? '#49D360' : '#E0E0E0',
          data: requiredData,
        },
        {
          label: chartType === 'budget' ? 'Total spend' : 'Booked',
          stack: '1',
          order: 1,
          backgroundColor: color,
          borderColor: 'rgb(75, 192, 192)',
          data: actualData,
        },
      ],
    };
    return data;
  }, [chartData]);

  // const handleAddBudget = () => {
  //   // setAddBudgetModal(true);
  //   // handleCloseChart();
  //   console.log('Add budget', show);
  // };
  // const options = {
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //   },

  //   datasets: {
  //     bar: {
  //       barPercentage: 15,
  //       categoryPercentage: 0.01,
  //     },
  //   },
  //   scales: {
  //     xAxes: [
  //       {
  //         stacked: true,
  //         gridLines: {
  //           color: 'rgba(0, 0, 0, 0)',
  //         },
  //       },
  //     ],
  //     yAxes: [
  //       {
  //         id: 'y',
  //         display: 'auto',
  //         ticks: {
  //           min: 0,
  //           stepSize: 0.5,
  //         },
  //         gridLines: {
  //           color: 'rgba(0, 0, 0, 0)',
  //         },
  //       },
  //     ],
  //   },
  //   maintainAspectRatio: false,
  // };

  // const handleEditBudget = () => console.log('Edit');

  const handleDownload = (exportType: string, type: string) => {
    setLoaderType(exportType);

    if (exportType === 'jpeg') handleDownloadImage().then();
    if (exportType === 'pdf') handleDownloadPdf(type);
  };

  const handleDownloadPdf = (type: string) => {
    if (type === 'chart') {
      setExportLoading(LOADER_STATUS.PENDING);
      const element: any = printRef.current;
      html2canvas(element).then((canvas) => {
        const imgWidth = 208;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const imgData = canvas.toDataURL('img/png');
        const pdf = new Jspdf('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('download.pdf');
        setExportLoading(LOADER_STATUS.COMPLETED);
      });
    }
    if (type === 'voucher') {
      const performerArr = localStorage.getItem('performerArr');
      const arr = performerArr ? JSON.parse(performerArr) : [];
      const b: any = [];
      if (arr && arr.length > 0) {
        setExportLoading(LOADER_STATUS.PENDING);
        arr.forEach((d: any, i: number) => {
          const row: { totalTime: string } = d;
          if (d.signInTime !== null) {
            if (d.wrapTime) {
              row.totalTime = getTimeDifference(d.signInTime, d.wrapTime);
            }
            const voucherBasicInfo = d?.voucherBasicData;
            if (voucherBasicInfo) {
              b.push({ ...d, ...voucherBasicInfo });
            } else {
              b.push(d);
            }
            setIsPrint(true);
          } else {
            setIsPrint(false);
          }
          return true;
        });

        const arrWithProperTime = arr.filter(
          (arrayVal: any) => arrayVal.signInTime != null && arrayVal.wrapTime != null,
        );
        setItems(b);

        if (arrWithProperTime.length === b.length) {
          setExportLoading(LOADER_STATUS.COMPLETED);
        }
        localStorage.removeItem('performerArr');
      }
    }
  };

  const handleDownloadImage = async () => {
    setExportLoading(LOADER_STATUS.PENDING);
    const element: any = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = data;
      link.download = 'image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setExportLoading(LOADER_STATUS.COMPLETED);
    } else {
      setExportLoading(LOADER_STATUS.COMPLETED);
      window.open(data);
    }
  };

  useEffect(() => {
    if (selectedShow) {
      setShow(selectedShow);
    }
  }, [selectedShow]);

  useEffect(() => {
    if (isPrint) {
      setTimeout(() => {
        setIsPrint(false);
      }, 2000);
    }
  }, [isPrint]);

  useEffect(() => {}, [bookingChartData]);
  const getComponent = (
    <BudgetExpert
      isloading={exportLoading}
      loaderType={loaderType}
      type={pdfType || 'chart'}
      handleClick={handleDownload}
    />
  );
  const checkType = 'cost';

  return (
    <>
      <StyledBottomInfoTab>
        <Status />
        <BgCordinator coordinators={dayItem.bgCoordinator} />
        <DaysWorked dayItem={dayItem} prevElement={prevElement} />
        <PendingReview pendingPerformer={pendingPerformer} />
        <Breakdown handleClick={handleBreakDown} />
        <div
          className={
            checkType === 'cost' && chartData.length <= 0
              ? `${Style.buttonsWrapper} p-2 ${Style.buttonsWrapperDisabled}`
              : `${Style.buttonsWrapper}`
          }
        >
          <PopoverContainer itemComponent={() => getComponent}>
            <div className={`mr-2 ${Style.exportButtonWrapper}`}>
              <img src={downloadArrowSvg} alt="" />
              <p className={checkType === 'cost' ? `mb-0 mr-0 ${Style.exportTitle}` : `mb-0 ${Style.exportTitle}`}>
                Export
              </p>
              <img src={arrowDownSvg} alt="" className={checkType === 'cost' ? '' : 'ml-3'} />
            </div>
          </PopoverContainer>
          {/* <div className="opacity-0"> */}
          {/*  {isPrint && !LOADER_STATUS.COMPLETED ? ( */}
          {/*    items.map((item) => <InvoicePDF item={item} isLoading={setExportLoading} />) */}
          {/*  ) : ( */}
          {/*    <></> */}
          {/*  )} */}
          {/* </div> */}
        </div>
      </StyledBottomInfoTab>
      <div className="opacity-0">
        {isPrint && items.length > 0 ? (
          items.map((item: any) => <InvoicePDF item={item} isDownload isLoading={setExportLoading} />)
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state: Show) => {
  const chartData = getChartData(state);
  const selectedShow = getSelectedShow(state);
  return {
    chartData,
    selectedShow,
  };
};

const ShowLoader = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

// const mapDispatchToProps = {
//   setShowSelectedDate,
//   clearSelectedJobListing,
// };

export default connect(mapStateToProps)(BottomInfoTab);
