import Landing from 'views/bgperformer/landing/index';
import CastingDirectorLanding from 'views/castingDirector/landing';
import MenuSetting from 'views/profileMenu/landing';
import DirectorProfileView from 'views/castingDirector/profile/DirectorProfileView';
import ProfileEdit from 'views/bgperformer/profile/ProfileEdit';
import ProfileView from 'views/profileMenu/profile/profileView/ProfileView';
import UserProfile from 'views/profileMenu/profile';
import ProducerLanding from 'views/producer/landing';
import BgCoordinator from 'views/bgCoordinator/landing';
import StripeLinked from 'views/stripe/StripeLinked';
import ConfirmCallSheet from 'components/castingDirector/MatrixItem/confirmCallSheet';
import Payroll from 'views/Payroll/index';
import PayrollVoucherManagement from 'views/VoucherManagement';
import PayrollRateManagement from 'views/Payroll/rate-management/RateManagement';
// import PayrollUserManagement from 'views/Payroll/user-management/components/UserSortFilter';
import PayrollUserManagement from 'views/usermanagement/UserManagement';

export default [
  {
    path: '/performer/home',
    component: Landing,
    role: 'PERFORMER',
  },
  {
    path: '/job/search',
    component: Landing,
    role: 'PERFORMER',
  },
  {
    path: '/job/view/:token',
    component: Landing,
    role: 'PERFORMER',
  },
  {
    path: '/job/detail/:id',
    component: Landing,
    role: 'PERFORMER',
  },
  {
    path: '/casting/home',
    component: CastingDirectorLanding,
    role: 'DIRECTOR',
  },
  {
    path: '/production/home',
    component: ProducerLanding,
    role: 'PRODUCTION',
  },
  {
    path: '/payroll/home',
    component: Payroll,
    role: 'PAYROLL',
  },
  {
    path: '/payroll/rate-management',
    component: PayrollRateManagement,
    role: 'PAYROLL',
  },
  {
    path: '/payroll/user-management',
    component: PayrollUserManagement,
    role: 'PAYROLL',
  },
  {
    path: '/payroll/voucher-management',
    component: PayrollVoucherManagement,
    role: 'PAYROLL',
  },
  {
    path: '/payroll',
    component: Payroll,
    role: 'PAYROLL',
  },
  {
    path: '/production/matrix',
    component: ProducerLanding,
    role: 'PRODUCTION',
  },
  {
    path: '/performer/detail',
    component: BgCoordinator,
    role: 'COORDINATOR',
  },
  {
    path: '/coordinator/home',
    component: BgCoordinator,
    role: 'COORDINATOR',
  },
  {
    path: '/casting/profile/view',
    component: DirectorProfileView,
    role: 'ADMIN',
  },
  {
    path: '/profile-view',
    component: ProfileView,
    role: 'ADMIN',
  },
  {
    path: '/my-profile',
    component: UserProfile,
    role: 'DIRECTOR,PRODUCTION,COORDINATOR,PAYROLL',
  },
  {
    path: '/performer/profile/edit',
    component: ProfileEdit,
    role: 'ADMIN',
  },
  {
    path: '/settings',
    component: MenuSetting,
    role: 'ADMIN',
  },
  {
    path: '/tax-info',
    component: MenuSetting,
    role: 'ADMIN',
  },
  {
    path: '/privacy-setting',
    component: MenuSetting,
    role: 'ADMIN',
  },
  {
    path: '/help_center',
    component: MenuSetting,
    role: 'ADMIN',
  },
  {
    path: '/qr_code',
    component: MenuSetting,
    role: 'ADMIN',
  },
  {
    path: '/days',
    component: MenuSetting,
    role: 'ADMIN',
  },
  {
    path: '/voucher_info',
    component: MenuSetting,
    role: 'ADMIN',
  },
  {
    path: '/residency_proof',
    component: MenuSetting,
    role: 'ADMIN',
  },
  {
    path: '/account_link',
    component: StripeLinked,
    role: 'DIRECTOR',
  },
  {
    path: '/matrix/confirm_call_sheet/:token/:workingDayId',
    component: ConfirmCallSheet,
    role: 'PERFORMER',
  },
];
