import React, { FunctionComponent, useEffect, useState } from 'react';
import UserItem from 'components/Producer/Matrix/UserManagementItem/Item/UserItem';
import { fetchShowUsersBegin } from 'store/actions/show.actions';
import { connect } from 'react-redux';
import { Show, ShowTeamMember, TeamObject } from 'modules/Show/show.types';
import { defaultPagination } from 'helpers/utils';
import PaginationComponent from 'components/controls/pagination/Pagination';
import { Pagination } from 'modules/jobs/types';

interface UserListingProps {
  fetchUsers: Function;
  show: Show;
  team: TeamObject;
}

const UserListing: FunctionComponent<UserListingProps> = (props: UserListingProps) => {
  const { fetchUsers, show, team } = props;
  const [pagination, setPagination] = useState<Pagination>(defaultPagination());
  const [teamData, setTeamData] = useState<TeamObject>(team);

  useEffect(() => {
    loadData(1);
  }, [show.users]);

  useEffect(() => {
    if (team && team.pagination) {
      setPagination(team.pagination);
      setTeamData(team);
    }
  }, [team]);

  const loadData = (page: number) => {
    fetchUsers({ showId: show.id, page, limit: 7 });
  };

  const handlePages = (updatePage: any) => {
    loadData(updatePage.selected + 1);
  };

  const getTeamList = () => {
    if (teamData && teamData.data) {
      return teamData.data.filter((t: ShowTeamMember) => t.userId.id !== show.createdBy);
    }
    return [];
  };

  return (
    <>
      {getTeamList().map((u: ShowTeamMember, index: number) => (
        <UserItem key={index} user={u} />
      ))}
      <div className="mt-4">
        <PaginationComponent
          page={pagination.page}
          totalPages={pagination.totalPages > 0 ? pagination.totalPages : 1}
          handlePagination={handlePages}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  fetchUsers: fetchShowUsersBegin,
};

export default connect(null, mapDispatchToProps)(UserListing);
