import { connect } from 'react-redux';
import { Show } from 'modules/Show/show.types';
import { getCustomPerformerRateList, getPerformerRateList } from 'store/selector/show.selector';
import InvoicePDF from 'components/Payroll/VoucherManagement/Invoice/InvoicePDF';
import { getRateListRequest, getCustomRateListRequest } from 'store/actions/show.actions';

const mapStateToProps = (state: Show) => {
  const allRates = getPerformerRateList(state);
  const allCustomRates = getCustomPerformerRateList(state);
  return { allRates, allCustomRates };
};

export default connect(mapStateToProps, null)(InvoicePDF);
