import React from 'react';
import styled from 'styled-components';
import { IMAGE_BASE_URL } from 'store/castingPax.constants';

interface Props {
  id: string;
  role?: string;
  imageUrl?: string;
  label?: string;
  value?: string;
  className?: string;
  handleFilter?: Function;
}

const DropdownItem: React.FC<Props> = (props) => {
  const { id, imageUrl, role, label, className, value, handleFilter } = props;
  const handleClick = () => handleFilter && handleFilter(value);
  return (
    <StyledDropdownItem className={className} onClick={handleClick}>
      {imageUrl && <img src={`${IMAGE_BASE_URL}${imageUrl}`} alt="" />}
      <p>{role || label}</p>
    </StyledDropdownItem>
  );
};

export default DropdownItem;

const StyledDropdownItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
  height: 38px;
  padding: 0 12px;
  border-bottom: 1px solid #e4f0fc;
  cursor: pointer;

  img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }

  p {
    width: max-content;
    height: 24px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0px;
    color: #202224;
  }
`;
