/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Show } from 'modules/Show/show.types';
// import { getShowListingRequest } from 'store/actions/show.actions';
import styled from 'styled-components';
import { faBars, faChevronRight, faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
// import SliderWrapper from 'components/controls/Slider/SliderWrapper';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserGroup from 'assets/images/userGroup.svg';
import TopNotificationIcon from 'components/TopNavBar/NotificationItem/TopNotificationIcon';
// import ShowStyle from 'components/TopNavBar/TopNavBar.module.css';
// import ShowTopNavigation from 'components/ShowTopNavigation';
import { useLocation } from 'react-router-dom';
import TopBarUserIcon from 'components/TopNavBar/User/TopBarUserIcon';
import Search from 'components/TopNavBar/Search';
import { getSelectedShowId } from 'store/selector/show.selector';
import Header from './NavHeader';
import ShowProducerDropDown from '../ShowProducer/ShowProducerDropDown';
import { dummyProductions } from '../Data/payrollData';

interface NavInterface {
  setShowSideNav: React.Dispatch<React.SetStateAction<boolean>>;
  selectedShowId?: string;
  showSideNav: boolean;
  // showList: ShowList;
  // getShowListing: Function;
}

const PayrollNav: FunctionComponent<NavInterface> = (props: NavInterface) => {
  const { selectedShowId } = props;
  console.log('Selected Id', selectedShowId);
  const [selectedId, setSelectedId] = useState<string>('');
  const [showProducerDropDown, setShowProducerDropDown] = useState(false);
  const updateNavState = () => {
    props.setShowSideNav(!props.showSideNav);
  };
  const updateShowProducer = () => {
    setShowProducerDropDown(!showProducerDropDown);
  };
  const [currentPath, setCurrentPath] = useState('');

  const location = useLocation();

  const sliderRef = useRef<any>();

  const handleSelect = (id: string) => setSelectedId(id);

  const settings = {
    infinite: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: false,
    height: 'auto',
    // adaptiveHeight: true,
  };

  // const handleClick = (event: React.FormEvent<HTMLDivElement>) => {
  //   console.log('Selected Tab', event.currentTarget);
  //   // setSelectedShow(event.currentTarget.id);
  //   // if (!location.pathname.includes('home')) {
  //   //   history.push(getHomePage(user.role as RoleCode));
  //   // }
  // };

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  return (
    <NavContainer>
      <IconNavContainer>
        {currentPath === '/performer-profile' ? (
          <IconContainer />
        ) : (
          <IconContainer onClick={updateNavState}>
            {props.showSideNav ? (
              <FontAwesomeIcon size="1x" icon={faTimes} />
            ) : (
              <FontAwesomeIcon size="1x" icon={faBars} />
            )}
          </IconContainer>
        )}
        <PayrollLogoContainer>
          <Header />
        </PayrollLogoContainer>
      </IconNavContainer>
      <MiddleNavContainer>
        <ProductionsListContainer>
          <ListControlLeft onClick={() => sliderRef?.current?.slickPrev()}>
            <StyledChevron size="1x" icon={faChevronLeft} />
          </ListControlLeft>
          <ProductionsList>
            <ListContainer>
              <Slider {...settings} arrows={false} ref={sliderRef}>
                {dummyProductions.map((production) => (
                  <Production
                    key={production.id}
                    selected={selectedId === production.id}
                    onClick={() => handleSelect(production.id)}
                  >
                    <ProductionLabel>{production.title}</ProductionLabel>
                  </Production>
                ))}
              </Slider>
            </ListContainer>
          </ProductionsList>
          <ListControlRight onClick={() => sliderRef?.current?.slickNext()}>
            <StyledChevron size="1x" icon={faChevronRight} />
          </ListControlRight>
        </ProductionsListContainer>
        <ProductionControls>
          {/* <ShowTopNavigation cssClass={`coordinatorSlider ${ShowStyle.coordinatorSlider}`} /> */}
        </ProductionControls>
      </MiddleNavContainer>
      <RightNavContainer>
        <UserGroupContainer>
          <StyledUserGroup onClick={updateShowProducer} src={UserGroup} alt="catingpax" />
          <ShowProducerDropDown open={showProducerDropDown} />
        </UserGroupContainer>
        <Search />
        <TopNotificationIcon />
        <TopBarUserIcon />
      </RightNavContainer>
    </NavContainer>
  );
};

const PayrollLogoContainer = styled.div`
  margin-top: -5px;
`;
const ProductionLabel = styled.p`
  margin: 0;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e6e6e6;
  cursor: pointer;
`;
const Production = styled.div<{ selected?: boolean }>`
  display: flex;
  min-width: 191px;
  align-items: center;
  justify-content: center;
  height: 70px;
  ${({ selected }) => (selected ? 'background-color: #4B4D67; color: white;' : '')}
`;
const ProductionsList = styled.div`
  display: flex;
  height: auto;
  flex: 1;
`;

const ListContainer = styled.div`
  display: flex;
  // max-width: 950px;
  max-width: 640px;
  height: 100%;
  overflow: hidden;
`;

const ListControlLeft = styled.div`
  height: 100%;
  padding: 20px;
  border-right: 1px solid #e0e0e0;
`;
const ListControlRight = styled.div`
  height: 100%;
  padding: 20px;
  border-left: 1px solid #e0e0e0;
`;

const ProductionControls = styled.div`
  display: flex;
  margin-right: 45px;
`;

const ProductionsListContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

const NavContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  border-bottom: 2px solid #e2e2e2;
  background-color: #fff;
  height: 70px;
  flex-direction: row;
  z-index: 1000;
`;

const IconNavContainer = styled.div`
  display: flex;
  width: 18%;
`;

const MiddleNavContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 17px;
  flex: 1;
`;

const RightNavContainer = styled.div`
  display: flex;
  padding-right: 20px;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  width: 70px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledUserGroup = styled.img`
  width: 40px;
  height: 40px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledChevron = styled(FontAwesomeIcon)`
  color: #4b4d67;
  background-color: #fff;
  margin-left: 8px;
  cursor: pointer;
`;

const UserGroupContainer = styled.div`
  display: flex;
  position: relative;
  margin-right: 25px;
`;

// const mapDispatchToProps = {
//   getShowListing: getShowListingRequest,
// };

const mapStateToProps = (state: Show) => {
  const selectedShowId = getSelectedShowId(state);
  return { selectedShowId };
};

export default connect(mapStateToProps, null)(PayrollNav);

// export default PayrollNav;
