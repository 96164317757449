import { connect } from 'react-redux';
import TopCalendar from 'components/bgPerformer/TopCalendar/TopCalendar';

import { Show } from 'modules/Show/show.types';
import { getJobEvents } from 'store/selector/job.selector';

const mapStateToProps = (state: Show) => {
  const jobEvent = getJobEvents(state);
  return {
    jobEvent,
  };
};

export default connect(mapStateToProps, null)(TopCalendar);
