/* eslint-disable react/destructuring-assignment */
import React, { FunctionComponent } from 'react';
import Styled from 'styled-components';
import RollVideoImage from 'assets/images/rollMovie.svg';
import Bin from 'assets/images/bin.svg';
import Add from 'assets/images/PludAdd.svg';

interface ShowProducerItemInterface {
  type: string;
  name: string;
}

interface StyledProps {
  type: string;
}

const ShowProducerItem: FunctionComponent<ShowProducerItemInterface> = (props: ShowProducerItemInterface) => (
  <ItemContainer type={props.type}>
    <ProducerImage src={RollVideoImage} />
    <RightContainer>
      <TextContainer>{props.name}</TextContainer>
      <IconContainer src={props.type === 'delete' ? Bin : Add} />
    </RightContainer>
  </ItemContainer>
);

const ItemContainer = Styled.div`
width: 100%;
display: flex;
padding: 20px;
align-items: center;
background: ${(props: StyledProps) => (props.type === 'delete' ? 'lightblue' : '#fff')};
height: 60px;
`;
const ProducerImage = Styled.img`
height: 50px;
width: 50px;
border-radius: 10px;
border: 2.5px solid #FFFFFF;
`;
const RightContainer = Styled.div`
display: flex;
height: 100%;
align-items: center;
width:80%;
justify-content: space-between;
`;

const TextContainer = Styled.p`
display: flex;
margin-left: 10px;
align-items: center;
height: 100%;
color: black;
`;

const IconContainer = Styled.img`
height: 15px;
width:15px;
&:hover {
  cursor: pointer;
}
`;

export default ShowProducerItem;
