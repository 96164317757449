import React, { FunctionComponent, useState } from 'react';
import Button from 'components/controls/button/button';
import { checkEmail } from 'utils/FormValidation';
import Header from 'components/topHeader/topHeader';
import LoginStyle from 'views/login/login.module.css';
import signupCatStyle from 'components/singupCategory/signupCategory.module.css';
import api from 'store/services/auth.services';
import CPModal from 'components/Modal/CPModal';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import { Link } from 'react-router-dom';
import leftArrowSvg from 'assets/svg/left-arrow-white.svg';

const ForgotPassword: FunctionComponent = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleForgot = () => {
    isButtonEnabled();
    if (email.length === 0 || emailError.length > 0 || isLoading) {
      return;
    }
    sendEmail();
  };

  const sendEmail = () => {
    const params = { email };

    setIsLoading(true);
    api
      .sendForgotEmail(params)
      .then(() => {
        setIsLoading(false);
        setShowModal(true);
      })
      .catch(() => {
        setIsLoading(false);
        setEmailError('Fail to send the email');
      });
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleBlur = (event: React.FormEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    setEmailError(checkEmail(val));
  };
  const isButtonEnabled = () => {
    if (email.length === 0) {
      setEmailError('please enter email address');
      return;
    }
    setEmailError(checkEmail(email));
  };
  return (
    <>
      <Header />
      <div className={signupCatStyle.backgroundWrapper}>
        <Link to="/login" className={`pt-3 pl-3 text-white ${signupCatStyle.backLinkText}`}>
          <img src={leftArrowSvg} alt="back arrow" />
          <span className="mb-0 text-white">BACK</span>
        </Link>
        <div className={signupCatStyle.bgInnerContent}>
          <h1>Reset Password</h1>
          <p className={signupCatStyle.alfterHeading}>
            Enter the email associated with your account and we`ll send an email with instructions to reset your
            password.
          </p>
        </div>
        <div className={LoginStyle.loginWrapper}>
          <CustomInput
            placeholder="Email Address"
            type="email"
            errorMessage={emailError}
            blurHandler={handleBlur}
            handleChange={setEmail}
            cssClass={emailError ? 'border-danger' : ''}
          />
          <div className="text-center w-100">
            <Button label="Send Email" cssClass="login-btn w-25" clickHandler={handleForgot} showLoading={isLoading} />
          </div>
        </div>
        {showModal && (
          <CPModal isShow={showModal} hideModal={handleModalClose} isOkOnly>
            <p>Please check your email</p>
          </CPModal>
        )}
      </div>
    </>
  );
};
export default ForgotPassword;
