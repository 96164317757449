import { IRateValues, IVoucherStatus, ICreateVoucher } from 'modules/rates/rate.types';
import { call, all, takeEvery, put } from 'redux-saga/effects';
import { payrollActions, generalActions } from 'store/actions';
import ACTIONS, { TOAST_IDS, TOAST_TYPE } from 'store/castingPax.constants';
import rateApi from 'store/services/payroll.services';

function* createRateValues(state: { type: string; payload: IRateValues }) {
  try {
    yield put(payrollActions.createRateValues.pending);
    const list: any = yield call(rateApi.createRateValues, state.payload);
    yield put(payrollActions.createRateValues.success(list));
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.CHANGE_RENTAL_TYPE,
        title: 'Rate values created successfully',
        type: TOAST_TYPE.SUCCESS,
      }),
    );
  } catch (error) {
    yield put(payrollActions.createRateValues.error(error.message));
  }
}

function* updateRateValues(state: { type: string; payload: IRateValues }) {
  try {
    yield put(payrollActions.updateRateValues.pending);
    const response: any = yield call(rateApi.updateRateValues, state.payload);
    if (response.data) {
      yield put(payrollActions.updateRateValues.success(response));
      yield put(
        generalActions.showToast({
          id: TOAST_IDS.CHANGE_RENTAL_TYPE,
          title: 'Rate values changed successfully',
          type: TOAST_TYPE.SUCCESS,
        }),
      );
    }
  } catch (err: any) {
    yield put(payrollActions.updateRateValues.error(err.message));
  }
}

function* getRateValues(state: { type: string; payload: IRateValues }) {
  try {
    yield put(payrollActions.getRateValues.pending);
    const response: any = yield call(rateApi.getRateValues, state.payload);
    if (response.data) {
      yield put(payrollActions.getRateValues.success(response));
    }
  } catch (err: any) {
    yield put(payrollActions.getRateValues.error(err));
  }
}

function* updateVoucherStatus(state: { type: string; payload: IVoucherStatus }) {
  try {
    yield put(payrollActions.updateVoucherStatus.pending);
    const response: any = yield call(rateApi.updateVoucherStatus, state.payload);
    if (response.data) {
      yield put(payrollActions.updateVoucherStatus.success(response));
      yield put(
        generalActions.showToast({
          id: TOAST_IDS.CHANGE_RENTAL_TYPE,
          title: 'Voucher status changed successfully',
          type: TOAST_TYPE.SUCCESS,
        }),
      );
    }
  } catch (err: any) {
    yield put(payrollActions.updateVoucherStatus.error(err.message));
  }
}

function* createVoucher(state: { type: string; payload: ICreateVoucher }) {
  try {
    yield put(payrollActions.createVoucher.pending);
    const response: any = yield call(rateApi.createVoucher, state.payload);
    if (response.data) {
      yield put(payrollActions.createVoucher.success(response));
    }
  } catch (err: any) {
    yield put(payrollActions.createVoucher.error(err.message));
  }
}

function* updateRateTitle(state: { type: string; payload: IRateValues }) {
  try {
    yield put(payrollActions.updateRateTitle.pending);
    const list: any = yield call(rateApi.updateRateTitle, state.payload);
    yield console.log('Response...', list);
    yield put(payrollActions.updateRateTitle.success(list));
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.CHANGE_RENTAL_TYPE,
        title: 'Rate Title updated successfully',
        type: TOAST_TYPE.SUCCESS,
      }),
    );
  } catch (error) {
    yield put(payrollActions.updateRateTitle.error(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.CREATE_RATE_VALUES_BEGIN, createRateValues),
    takeEvery(ACTIONS.UPDATE_RATE_VALUES_BEGIN, updateRateValues),
    takeEvery(ACTIONS.VIEW_RATE_VALUES_BEGIN, getRateValues),
    takeEvery(ACTIONS.UPDATE_VOUCHER_STATUS_BEGIN, updateVoucherStatus),
    takeEvery(ACTIONS.CREATE_VOUCHER_BEGIN, createVoucher),
    takeEvery(ACTIONS.UPDATE_RATE_TITLE_BEGIN, updateRateTitle),
  ]);
}
