import React, { FunctionComponent } from 'react';
import Button from 'components/controls/button/button';
import Style from 'components/CreateShow/CreateShow.module.css';
import { Episode, EpisodeList } from 'modules/Show/show.types';
import ShowEpisodeDayContainer from 'components/CreateShow/ShowEpisode/ShowEpisodeDayContainer/ShowEpisodeDaycontainer';
import locationSvg from 'assets/svg/location.svg';
import plusSvg from 'assets/svg/plus.svg';

interface ShowEpisodeProps {
  episodes: EpisodeList | undefined;
  handleNewEpisodes: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleEpisodeDays: (episode: Episode) => void;
  handleChangeEpisodeData: Function;
}
const ShowEpisode: FunctionComponent<ShowEpisodeProps> = (props: ShowEpisodeProps) => {
  const { handleNewEpisodes, episodes, handleEpisodeDays, handleChangeEpisodeData } = props;

  const showEpisodes = () => {
    if (episodes) {
      return episodes.map((episode: Episode) => (
        <ShowEpisodeDayContainer
          key={episode.id}
          episodeData={episode}
          handleAddEpisodeDay={handleEpisodeDays}
          handleChangeEpisodeData={handleChangeEpisodeData}
        />
      ));
    }
    return '';
  };

  return (
    <div className="row">
      <div className="col-sm-12 mb-3">
        <h4 className="mb-3">
          <img src={locationSvg} alt="" />
          &nbsp;Create Episode
        </h4>
        {showEpisodes()}
        <Button
          label=" Episode"
          cssClass={`mt-3 ${Style.seriesPlusBtn} ${Style.buttonText}`}
          icon={plusSvg}
          clickHandler={handleNewEpisodes}
        />
      </div>
    </div>
  );
};

export default ShowEpisode;
