import React, { FunctionComponent, useEffect } from 'react';
import SortBy from 'components/Sort/Sort';
import Style from 'components/castingDirector/Matrix.module.css';
import { MatrixItemList, MatrixType } from 'modules/Show/show.types';
import MatrixDetail from 'components/Coordinator/Matrix/Details/MatrixDetail';
import { Pagination } from 'modules/jobs/types';

interface MatrixProps {
  dates: string[];
  showId: string;
  loading: boolean;
  matrixData: MatrixItemList;
  pagination: Pagination;
  getMatrixDataBegin: Function;
  searchExistingMatrix: Function;
  sortMatrixData: Function;
  sortingOrder: number;
  setDatesForFitler: Function;
  setMatrixUpdateTiming: Function;
}

const Matrix: FunctionComponent<MatrixProps> = (props: MatrixProps) => {
  const {
    dates,
    showId,
    loading,
    matrixData,
    getMatrixDataBegin,
    pagination,
    searchExistingMatrix,
    sortMatrixData,
    sortingOrder,
    setDatesForFitler,
    setMatrixUpdateTiming,
  } = props;
  const limit = 6;

  useEffect(() => {
    loadData(1);
  }, []);
  useEffect(() => {
    loadData(1);
  }, [dates, showId]);

  const loadData = (page: number) => {
    if (showId && !loading) {
      getMatrixDataBegin({ date: dates, showId, page, limit });
    }
  };

  const handleLoadMore = () => loadData(pagination.page + 1);

  const handleSearch = (text: string) => searchExistingMatrix(text);

  const reOrder = (num: number) => {
    if (num !== sortingOrder) {
      sortMatrixData(num);
    }
  };
  const handleFilterDates = (filterDates: string[]) => setDatesForFitler(filterDates);
  return (
    <>
      <SortBy filterDates={handleFilterDates} reOrder={reOrder} handleSearch={handleSearch} />

      {/* {matrixData.length === 0 && <Loader isSmallView />} */}
      {matrixData.map((item: MatrixType) => (
        <MatrixDetail key={item.id} dayItem={item} matrixUpdateTiming={setMatrixUpdateTiming} />
      ))}
      {pagination && pagination.page < pagination.totalPages && (
        <div className="text-center">
          <button type="button" onClick={handleLoadMore} className={Style.loadMoreButton}>
            View More
          </button>
        </div>
      )}
    </>
  );
};

export default Matrix;
