import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { ShowDayJob, roleValidationErrorObj, EpisodeDayList, Show, ShowWorkingDay } from 'modules/Show/show.types';
import Button from 'components/controls/button/button';
import Heading from 'components/controls/heading/Heading';
import locationSvg from 'assets/svg/location.svg';
import closeSvg from 'assets/svg/close.svg';
import DashboardInput from 'components/controls/input/dashboardInput/dashboardInput';
import DashboardTextarea from 'components/controls/textArea/dashbaordTextarea/dashboardTextarea';
import Style from 'components/CreateShow/CreateShow.module.css';
import customStyle from 'assets/css/customStyle.module.css';
import DropDownSearch from 'components/controls/select/DropDownSearch';
import additionalInfoSvg from 'assets/svg/info.svg';
import envelopeSvg from 'assets/svg/mail.svg';
import searchSvg from 'assets/svg/search-white.svg';
import plusSvg from 'assets/svg/plus.svg';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import JobDateSelection from 'components/ShowJob/JobDateSelection/JobDateSelection';
import moment from 'moment';
import { SelectOptionTypeList } from 'modules/PerformerList/list.types';
import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';
import { formatHeight, formatInUnit, formatWeight, getInUTCFormat } from 'helpers/utils';
import { Options, RateOptions, EmailTemplate, EmailTemplateList } from 'modules/general/general.type';
import CreateEmailTemplate from 'components/castingDirector/PopOvers/EmailDetail/CreateEmailTemplate';
import PopoverHover from 'components/controls/Popover/PopoverHover';
import { toast } from 'react-toastify';
import { PERFORMER_TAB, SHOW_JOB_STATUS, UNIT_FORMAT } from 'store/castingPax.constants';
import checkSvg from 'assets/svg/only-check.svg';
import {
  getHeightOptions,
  getInseamOptions,
  getJacketSizeOptions,
  getNeckOptions,
  getSleeveOptions,
  getWasitOption,
  getWeightOption,
  NumberOptions,
} from 'helpers/data/SelectOptions';
import Loader from 'components/controls/Loader/Loader';
import { emptyTemplate } from 'modules/template/template';
import PopoverContainer from '../../controls/Popover/PopoverContainer';

interface ShowJobEntryProps {
  createJobBegin: Function;
  closeOrDeleteJobBegin: Function;
  selectedJob: ShowDayJob;
  setSelectedTab: Function;
  setSelectedJob: Function;
  templateList: EmailTemplateList;
  workingDaysList: EpisodeDayList;
  showLoading: boolean;
  templateListOpt: SelectOptionTypeList;
  requestListOpt: SelectOptionTypeList;
  selectedShow: Show;
  apiError: string;
  clearJobErrorMessage: Function;
  setDatesForFitler: Function;
  setPerformerTab: Function;
  rateList: any;
}

const ShowJobEntry: FunctionComponent<ShowJobEntryProps> = (props) => {
  const {
    closeOrDeleteJobBegin,
    createJobBegin,
    selectedJob,
    setSelectedTab,
    setSelectedJob,
    templateList,
    workingDaysList,
    showLoading,
    templateListOpt,
    requestListOpt,
    selectedShow,
    apiError,
    clearJobErrorMessage,
    setDatesForFitler,
    setPerformerTab,
    rateList,
  } = props;
  const [id, setId] = useState<string>(selectedJob.id || '-1');
  const [title, setTitle] = useState<string>(selectedJob.title || '');
  const [noOfPerformer, setNoOfPerformer] = useState<number>(selectedJob.noPerformerRequired);
  const [location, setLocation] = useState<Options>(selectedJob.location);
  const [ethnicity, setEthnicity] = useState<string>(selectedJob.ethnicity || '');
  const [clothingOption, setClothingOption] = useState<string>(selectedJob.clothingOption || '');
  const [bodyRequirements, setBodyRequirements] = useState<string>(selectedJob.bodyRequirements || '');
  const [costumeNote, setCostumeNote] = useState<string>(selectedJob.costumeNote || '');
  const [remarks, setRemarks] = useState<string>(selectedJob.remarks || '');
  const [additionalInfo, setAdditionalInfo] = useState<string>(selectedJob.additionalInfo || '');
  const [errors, setErrors] = useState(roleValidationErrorObj);
  const [minHeight, setMinHeight] = useState(selectedJob.minHeight);
  const [maxHeight, setMaxHeight] = useState(selectedJob.maxHeight);
  const [weight, setWeight] = useState(selectedJob.weight);
  const [maxWeight, setMaxWeight] = useState(selectedJob.maxWeight);
  const [waist, setWaist] = useState(selectedJob.waist || '');
  const [maxWaist, setMaxWaist] = useState(selectedJob.maxWaist || '');
  const [neck, setNeck] = useState(selectedJob.neck || '');
  const [maxNeck, setMaxNeck] = useState(selectedJob.maxNeck || '');
  const [jacket, setJacket] = useState(selectedJob.jacket || '');
  const [maxJacket, setMaxJacket] = useState(selectedJob.maxJacket || '');
  const [inseam, setInseam] = useState(selectedJob.inseam || '');
  const [maxInseam, setMaxInseam] = useState(selectedJob.maxInseam || '');
  const [sleeve, setSleeve] = useState(selectedJob.sleeve || '');
  const [maxSleeve, setMaxSleeve] = useState(selectedJob.maxSleeve || '');
  const [hair, setHair] = useState(selectedJob.hair || '');
  const [eyes, setEyes] = useState(selectedJob.eyes || '');
  const [age, setAge] = useState(selectedJob.age || '');
  const [maxAge, setMaxAge] = useState(selectedJob.maxAge || '');
  const [chest, setChest] = useState(selectedJob.chest || '');
  const [maxChest, setMaxChest] = useState(selectedJob.maxChest || '');
  const [shoes, setShoes] = useState(selectedJob.shoes || '');
  const [maxShoes, setMaxShoes] = useState(selectedJob.maxShoes || '');
  const [category, setCategory] = useState(selectedJob.category || '');
  const [templateId, setTemplateId] = useState(selectedJob.templateId || '');
  const [messageId, setMessageId] = useState(selectedJob.messageId || '');
  const [rate, setRate] = useState<RateOptions>(selectedJob.rate || '');
  const [status, setStatus] = useState<string>('');

  const [selectedWD, setSelectedWD] = useState<EpisodeDayList>(
    selectedJob.workingDayId
      ? workingDaysList.filter((x: ShowWorkingDay) => selectedJob.workingDayId.includes(x.id))
      : [],
  );
  const [additionalInfoContent, setAdditionalInfoContent] = useState(false);
  const [toggleNewTemplate, setToggleNewTemplate] = useState<boolean>(false);

  const [toggleNewRequest, setToggleNewRequest] = useState<boolean>(false);
  const [showDisable, setShowDisable] = useState<boolean>(false);
  // const [showPost, setShowPost] = useState(false);

  useEffect(() => {
    if (selectedJob.id !== id) {
      setId(selectedJob.id || '-1');
      setTitle(selectedJob.title || '');
      setNoOfPerformer(selectedJob.noPerformerRequired || 0);
      setLocation(selectedJob.location);
      setRate(selectedJob.rate);
      setEthnicity(selectedJob.ethnicity || '');
      setMinHeight(selectedJob.minHeight || '');
      setMaxHeight(selectedJob.maxHeight || '');
      setClothingOption(selectedJob.clothingOption || '');
      setBodyRequirements(selectedJob.bodyRequirements || '');
      setCostumeNote(selectedJob.costumeNote || '');
      setRemarks(selectedJob.remarks || '');
      setAdditionalInfo(selectedJob.additionalInfo || '');
      setWeight(selectedJob.weight || '');
      setMaxWeight(selectedJob.maxWeight || '');
      setWaist(selectedJob.waist || '');
      setMaxWaist(selectedJob.maxWaist || '');
      setNeck(selectedJob.neck || '');
      setMaxNeck(selectedJob.maxNeck || '');
      setJacket(selectedJob.jacket || '');
      setMaxJacket(selectedJob.maxJacket || '');
      setInseam(selectedJob.inseam || '');
      setMaxInseam(selectedJob.maxInseam || '');
      setSleeve(selectedJob.sleeve || '');
      setMaxSleeve(selectedJob.maxSleeve || '');
      setHair(selectedJob.hair || '');
      setEyes(selectedJob.eyes || '');
      setAge(selectedJob.age || '');
      setMaxAge(selectedJob.maxAge || '');
      setChest(selectedJob.chest || '');
      setMaxChest(selectedJob.maxChest || '');
      setCategory(selectedJob.category || '');
      setShoes(selectedJob.shoes || '');
      setMaxShoes(selectedJob.maxShoes || '');
      setTemplateId(selectedJob.templateId || '');
      setMessageId(selectedJob.messageId || '');
      setErrors({});
      setStatus(selectedJob.status || '');
      setSelectedWD(
        selectedJob.workingDayId
          ? workingDaysList.filter((x: ShowWorkingDay) => selectedJob.workingDayId.includes(x.id))
          : [],
      );
    }
  }, [selectedJob]);

  useEffect(() => {
    if (templateId === '-1') setToggleNewTemplate(true);
    if (messageId === '-1') setToggleNewRequest(true);
  }, [templateId, messageId]);

  useEffect(() => {
    if (templateId === '-1') setTemplateId('');
    if (messageId === '-1') setMessageId('');
  }, [toggleNewRequest, toggleNewTemplate]);

  useEffect(() => {
    if (!showLoading) setShowDisable(false);
  }, [showLoading]);

  useEffect(() => {
    if (apiError) {
      toast.error(apiError);
      clearJobErrorMessage();
    }
  }, [apiError]);

  const textAreaChangeHandler = (event: React.FormEvent<HTMLTextAreaElement>, stateFun: Function) => {
    stateFun(event.currentTarget.value);
  };

  const validateInput = () => {
    const err = {};

    if (title.length === 0) Object.assign(err, { title: 'Title Required' });
    if (noOfPerformer === 0) Object.assign(err, { performer: 'No of Performer Required' });
    if (selectedWD.length === 0) Object.assign(err, { date: 'Date Required' });
    if (!location || Object.keys(location).length === 0 || location.id === '-1') {
      Object.assign(err, { location: 'Location Required' });
    }
    if (templateId.length === 0) Object.assign(err, { emailTemplate: 'Email Template is Required' });
    if (messageId.length === 0) Object.assign(err, { requestText: 'Request Text is Required' });
    setErrors({ ...err });

    return Object.keys(err).length > 0;
  };

  const getJobObject = (statusType?: string) => {
    const type: string = statusType && statusType ? statusType : selectedJob.status || '';
    setStatus(type);
    const sortedDays = selectedWD.sort((a: ShowWorkingDay, b: ShowWorkingDay) => (a.date < b.date ? -1 : 0));
    const dates: string[] = sortedDays.map((x: ShowWorkingDay) => x.date);
    const job: ShowDayJob = {
      id: selectedJob.id || '-1',
      showId: selectedShow.id,
      workingDayId: sortedDays.map((w: ShowWorkingDay) => w.id),
      title,
      date: dates.length > 0 ? getInUTCFormat(dates[dates.length - 1]) : '',
      startDate: dates.length > 0 ? getInUTCFormat(dates[0]) : '',
      dates,
      location,
      rate,
      rateId: rate ? rate.id : '',
      locationId: location ? location.id : '',
      minHeight,
      maxHeight,
      weight,
      maxWeight,
      waist,
      maxWaist,
      neck,
      maxNeck,
      jacket,
      maxJacket,
      inseam,
      maxInseam,
      sleeve,
      maxSleeve,
      hair,
      eyes,
      age,
      maxAge,
      chest,
      maxChest,
      shoes,
      maxShoes,
      category,
      ethnicity,
      clothingOption,
      bodyRequirements,
      costumeNote,
      remarks,
      additionalInfo,
      isActive: true,
      status: type,
      noPerformerRequired: noOfPerformer,
      templateId: templateId || undefined,
      messageId: messageId || undefined,
    };

    return job;
  };

  const handleClick = async (type: string) => {
    if (validateInput()) {
      return;
    }
    setShowDisable(true);
    if (type === SHOW_JOB_STATUS.CLOSE || type === SHOW_JOB_STATUS.DELETE) {
      const obj = {
        id: selectedJob.id || '-1',
        showId: selectedJob.showId,
        status: type,
      };
      closeOrDeleteJobBegin(obj);
    } else createJobBegin(getJobObject(type));
  };

  const handleFindPerformer = () => {
    setPerformerTab(PERFORMER_TAB.SUGGESTED_BG);
    setDatesForFitler([]);
    setSelectedTab('2');
    setSelectedJob(getJobObject());
  };

  const getSelectedTemplate = () => {
    if (templateId) {
      const index = templateList.findIndex((x: EmailTemplate) => x.id === templateId);
      if (index !== -1) {
        return { value: templateId, label: templateList[index].title };
      }
    }

    return '';
  };
  const getSelectedRequest = () => {
    if (messageId) {
      const index = templateList.findIndex((x: EmailTemplate) => x.id === messageId);
      if (index !== -1) {
        return { value: messageId, label: templateList[index].title };
      }
    }
    return '';
  };

  const datePopupComponent = () => (
    <JobDateSelection list={workingDaysList} setWorkingDay={setSelectedWD} workingDays={selectedWD} />
  );

  const showSelectedDate = useMemo(() => {
    if (selectedWD) {
      return selectedWD
        .map((x: ShowWorkingDay) => moment(x.date).format('DD-MM-YYYY'))
        .sort()
        .join(',');
    }
    return 'Select Date';
  }, [selectedWD]);

  const additionalInfoContentDiv = () => {
    setAdditionalInfoContent(!additionalInfoContent);
  };

  const handleNewTemplate = (type: string) => {
    if (type === 'message') {
      setToggleNewRequest(true);
      return;
    }
    setToggleNewTemplate(true);
  };

  const handleSumitted = () => {
    setPerformerTab(PERFORMER_TAB.SUBMITTED_REQUEST);
    setDatesForFitler([]);
    setSelectedTab('2');
    setSelectedJob(getJobObject());
  };

  const getTemplate = (type: string) => {
    let index = -1;
    if (type === 'message') index = templateList.findIndex((x: EmailTemplate) => x.id === messageId);
    if (type === 'email') index = templateList.findIndex((x: EmailTemplate) => x.id === templateId);
    if (index !== -1) return templateList[index];
    return emptyTemplate();
  };

  const getComponent = () => (
    <ul role="menu" className={`list-unstyled mb-0 ${customStyle.postShowContent}`}>
      <li
        className="cursor-pointer d-flex align-items justify-content-between"
        onClick={() => handleClick(SHOW_JOB_STATUS.PUBLIC)}
        onKeyPress={() => handleClick(SHOW_JOB_STATUS.PUBLIC)}
        tabIndex={0}
        role="menuitem"
      >
        Public
        {selectedJob.status && selectedJob.status === SHOW_JOB_STATUS.PUBLIC && (
          <img src={checkSvg} alt="" className={Style.checkIcon} />
        )}
      </li>
      <li
        className="cursor-pointer d-flex align-items justify-content-between"
        onClick={() => handleClick(SHOW_JOB_STATUS.INVITE)}
        onKeyPress={() => handleClick(SHOW_JOB_STATUS.INVITE)}
        tabIndex={0}
        role="menuitem"
      >
        Invite
        {selectedJob.status && selectedJob.status === SHOW_JOB_STATUS.INVITE && (
          <img src={checkSvg} alt="" className={Style.checkIcon} />
        )}
      </li>
    </ul>
  );

  const getCloseComponent = () => (
    <ul role="menu" className={`list-unstyled mb-0 ${customStyle.postShowContent}`}>
      <li
        className="cursor-pointer d-flex align-items justify-content-between"
        onClick={() => handleClick(SHOW_JOB_STATUS.CLOSE)}
        onKeyPress={() => handleClick(SHOW_JOB_STATUS.CLOSE)}
        tabIndex={0}
        role="menuitem"
      >
        Close Job
        {selectedJob.status && selectedJob.status === SHOW_JOB_STATUS.CLOSE && (
          <img src={checkSvg} alt="" className={Style.checkIcon} />
        )}
      </li>
      <li
        className="cursor-pointer d-flex align-items justify-content-between"
        onClick={() => handleClick(SHOW_JOB_STATUS.DELETE)}
        onKeyPress={() => handleClick(SHOW_JOB_STATUS.DELETE)}
        tabIndex={0}
        role="menuitem"
      >
        Delete Job
        {selectedJob.status && selectedJob.status === SHOW_JOB_STATUS.DELETE && (
          <img src={checkSvg} alt="" className={Style.checkIcon} />
        )}
      </li>
    </ul>
  );
  console.log(showDisable);

  const showButton = () => {
    if (Object.keys(selectedJob).length > 0 && selectedJob.id !== '-1') {
      return (
        <div className="row">
          <div className="col-sm-12 d-block d-sm-flex d-md-flex d-lg-flex d-xl-flex justify-content-between">
            <div className="d-block d-sm-flex d-md-flex d-lg-flex d-xl-flex">
              <div className={`d-flex align-items-center justify-content-around ${customStyle.postBtn}`}>
                <span
                  className="mb-0 font-weight-normal"
                  onClick={() => handleClick(selectedJob.status || '')}
                  onKeyPress={() => handleClick(selectedJob.status || '')}
                  tabIndex={0}
                  role="button"
                >
                  {showLoading ? <Loader isSmallView /> : <img src={envelopeSvg} alt="plus icon" className="mr-1" />}
                  update
                </span>
                <PopoverContainer itemComponent={getComponent}>
                  <img src={downArrowSvg} alt="down icon" className="cursor-pointer" />
                </PopoverContainer>
              </div>
              {selectedJob.status && selectedJob.status !== SHOW_JOB_STATUS.DRAFT && (
                <>
                  <Button
                    cssClass={`ml-0 mb-3 ml-sm-3 ml-md-3 ml-lg-3 ml-xl-3 
            ${customStyle.customBtnLabel} ${customStyle.roleListBtn} ${customStyle.secondaryColor}`}
                    label="Find Suggested BG"
                    clickHandler={handleFindPerformer}
                    icon={searchSvg}
                  />
                  <Button
                    cssClass={`ml-0 mb-3 ml-sm-3 ml-md-3 ml-lg-3 ml-xl-3 
            ${customStyle.customBtnLabel} ${customStyle.submittedBtn} ${customStyle.secondaryColor}`}
                    label="Submitted Background"
                    clickHandler={handleSumitted}
                    icon={envelopeSvg}
                  />
                </>
              )}
              {selectedJob.status && selectedJob.status === SHOW_JOB_STATUS.DRAFT && (
                <Button
                  showLoading={showLoading && status === SHOW_JOB_STATUS.DRAFT}
                  cssClass={`ml-0 ml-sm-3 ml-md-3 ml-lg-3 ml-xl-3 
              ${customStyle.customBtnLabel} ${customStyle.draftBtn}`}
                  label="Save As Draft"
                  clickHandler={() => handleClick(SHOW_JOB_STATUS.DRAFT)}
                />
              )}
            </div>
            <div>
              <PopoverContainer itemComponent={getCloseComponent}>
                <div className={`btn ${customStyle.closeBtn}`}>
                  <img src={closeSvg} alt="close icon" />
                </div>
              </PopoverContainer>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div
          className="col-sm-12 d-flex
         flex-row flex-wrap justify-content-between column-gap-20 row-gap-10"
        >
          <div
            className="d-flex
         flex-row row-gap-10 flex-wrap"
          >
            <div className={`d-flex align-items-center justify-content-around ${customStyle.postBtn}`}>
              <span
                className="mb-0 font-weight-normal"
                onClick={() => handleClick(SHOW_JOB_STATUS.PUBLIC)}
                onKeyPress={() => handleClick(SHOW_JOB_STATUS.PUBLIC)}
                tabIndex={0}
                role="button"
              >
                {showLoading && (status === SHOW_JOB_STATUS.PUBLIC || status === SHOW_JOB_STATUS.INVITE) ? (
                  <Loader isSmallView />
                ) : (
                  <img src={plusSvg} alt="plus icon" className="mr-1" />
                )}
                Post
              </span>
              <PopoverContainer itemComponent={getComponent}>
                <img src={downArrowSvg} alt="down icon" className="cursor-pointer" />
              </PopoverContainer>
            </div>
            <Button
              showLoading={showLoading && status === SHOW_JOB_STATUS.DRAFT}
              cssClass={`ml-0 ml-sm-3 ml-md-3 ml-lg-3 ml-xl-3 
              ${customStyle.customBtnLabel} ${customStyle.draftBtn}`}
              label="Save As Draft"
              clickHandler={() => handleClick(SHOW_JOB_STATUS.DRAFT)}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="row">
        {/* <div className=" col-sm-12 mb-3"> */}
        {/*  <Heading label="Role Title" path={role} /> */}
        {/* </div> */}
      </div>
      <div className="row">
        <div className="col-sm-12 textAreaPlaceholder">
          <DashboardTextarea
            value={title}
            cssClass={customStyle.textAreaPlaceholder}
            errorMessage={errors.title}
            placeholder="Role Title"
            inputChangeHandler={(e: React.FormEvent<HTMLTextAreaElement>) => textAreaChangeHandler(e, setTitle)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 textAreaPlaceholder">
          <span className={Style.createInputLabel}>Additional Info</span>
          <DashboardTextarea
            value={remarks}
            cssClass={customStyle.textAreaPlaceholder}
            placeholder="Additional Info"
            inputChangeHandler={(e: React.FormEvent<HTMLTextAreaElement>) => textAreaChangeHandler(e, setRemarks)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-xl-6 estimated-bg-heading">
          <DashboardInput
            type="number"
            label="Estimated BG"
            value={noOfPerformer ? noOfPerformer.toString() : ''}
            placeholder="BG #"
            errorMessage={errors.performer}
            handleChange={setNoOfPerformer}
            requiredField
            minVal="1"
          />
        </div>
        <div className="col-lg-12 col-xl-6 estimated-bg-heading">
          <span className="requiredField">Dates</span>
          <PopoverHover component={datePopupComponent}>
            <div>
              <div className={`mr-4 ${Style.dateLayout}`}>{showSelectedDate || 'Select Date'}</div>
              <TextErrorMessage message={errors.date || ''} />
            </div>
          </PopoverHover>
        </div>
      </div>

      <div className="row">
        <div className="performerSearchWrapper col-lg-12 col-xl-6">
          <span>Rate</span>
          <DropDownSearch
            cssClass={`${Style.createInputLabel} ${Style.marginBottomZero}`}
            value={rate ? rate.title : 'select'}
            data={rateList}
            storeObject
            defaultValue="select"
            handleSelect={setRate}
          />
        </div>
        <div className="performerSearchWrapper col-lg-12 col-xl-6 requiredDropDown">
          <span>
            <img src={locationSvg} alt="location svg" />
            &nbsp; Shooting Region
          </span>
          <DropDownSearch
            cssClass={`${Style.createInputLabel} ${Style.marginBottomZero}`}
            value={location ? location.label : 'select'}
            storeObject
            defaultValue="select"
            optionType="location"
            handleSelect={setLocation}
          />
          <TextErrorMessage message={errors.location || ''} />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 textAreaPlaceholder">
          <span className={`${Style.createInputLabel} ${Style.customeNotesColor}`}>Costume Notes</span>
          <DashboardTextarea
            value={costumeNote}
            cssClass={customStyle.customeNotes}
            placeholder="Costume Notes"
            inputChangeHandler={(e: React.FormEvent<HTMLTextAreaElement>) => textAreaChangeHandler(e, setCostumeNote)}
          />
        </div>
      </div>

      <div className="row">
        <div className="mb-3 col-lg-12 col-xl-6 position-relative d-flex align-items-center column-gap-10">
          <Heading label="Additional Information" path={additionalInfoSvg} />
          <span
            onClick={additionalInfoContentDiv}
            onKeyPress={additionalInfoContentDiv}
            tabIndex={0}
            role="button"
            className={`d-inline-block mb-0 ${Style.arrowDownTitle}`}
          >
            <img src={downArrowSvg} alt="downArrow" />
          </span>
        </div>
      </div>
      <div className="performerSearchWrapper">
        {additionalInfoContent && (
          <div>
            <div className="row">
              <div className="col-lg-12 col-xl-6">
                <div className="form-group">
                  <DropDownSearch
                    cssClass={Style.createInputLabel}
                    label="Category"
                    defaultValue="select"
                    value={category}
                    optionType="category"
                    handleSelect={setCategory}
                  />
                </div>
              </div>
              <div className=" col-lg-12 col-xl-6">
                <div className="form-group">
                  <DropDownSearch
                    cssClass={Style.createInputLabel}
                    label="Ehtnicity"
                    defaultValue="select"
                    value={ethnicity}
                    optionType="ethnicity"
                    handleSelect={setEthnicity}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-6">
                <DropDownSearch
                  label="Hair"
                  cssClass={Style.createInputLabel}
                  value={hair ?? ''}
                  defaultValue="select"
                  optionType="hair"
                  isMultiSelect
                  showAllLabel
                  handleSelect={setHair}
                />
              </div>
              <div className="col-lg-12 col-xl-6">
                <DropDownSearch
                  cssClass={Style.createInputLabel}
                  label="Eyes"
                  value={eyes ?? ''}
                  optionType="eyes"
                  isMultiSelect
                  showAllLabel
                  handleSelect={setEyes}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-6">
                <div className="row">
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      label="Age"
                      cssClass={Style.createInputLabel}
                      value={age ?? ''}
                      data={NumberOptions(5, 55)}
                      defaultValue="select"
                      handleSelect={setAge}
                    />
                  </div>
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      label="Max age"
                      cssClass={Style.createInputLabel}
                      value={maxAge ?? ''}
                      data={NumberOptions(5, 55)}
                      defaultValue="select"
                      handleSelect={setMaxAge}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xl-6">
                <div className="row">
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      label="Min Height"
                      cssClass={Style.createInputLabel}
                      value={formatHeight(minHeight || '')}
                      data={getHeightOptions()}
                      defaultValue="select"
                      handleSelect={setMinHeight}
                      sort
                    />
                  </div>
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      value={formatHeight(maxHeight || '')}
                      label="Max Height"
                      cssClass={Style.createInputLabel}
                      defaultValue="select"
                      data={getHeightOptions()}
                      handleSelect={setMaxHeight}
                      sort
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-6">
                <div className="row">
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      label="Weight"
                      cssClass={Style.createInputLabel}
                      value={formatWeight(weight)}
                      data={getWeightOption()}
                      defaultValue="select"
                      handleSelect={setWeight}
                    />
                  </div>
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      label="Max Weight"
                      cssClass={Style.createInputLabel}
                      value={formatWeight(maxWeight)}
                      data={getWeightOption()}
                      defaultValue="select"
                      handleSelect={setMaxWeight}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xl-6">
                <div className="row">
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      value={formatInUnit(chest, UNIT_FORMAT.INCH)}
                      label="Chet/Bust"
                      cssClass={Style.createInputLabel}
                      defaultValue="select"
                      data={NumberOptions(15, 55, UNIT_FORMAT.INCH)}
                      handleSelect={setChest}
                    />
                  </div>
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      value={formatInUnit(maxChest, UNIT_FORMAT.INCH)}
                      label="Max. Chet/Bust"
                      cssClass={Style.createInputLabel}
                      defaultValue="select"
                      data={NumberOptions(15, 55, UNIT_FORMAT.INCH)}
                      handleSelect={setMaxChest}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-6">
                <div className="row">
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      value={formatInUnit(waist, UNIT_FORMAT.INCH)}
                      cssClass={Style.createInputLabel}
                      label="Waist"
                      defaultValue="select"
                      data={getWasitOption()}
                      handleSelect={setWaist}
                    />
                  </div>
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      value={formatInUnit(maxWaist, UNIT_FORMAT.INCH)}
                      cssClass={Style.createInputLabel}
                      label="Max Waist"
                      defaultValue="select"
                      data={getWasitOption()}
                      handleSelect={setMaxWaist}
                    />
                  </div>
                </div>
              </div>
              <div className=" col-lg-12 col-xl-6">
                <div className="row">
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      value={formatInUnit(neck, UNIT_FORMAT.INCH)}
                      cssClass={Style.createInputLabel}
                      label="Neck"
                      data={getNeckOptions()}
                      defaultValue="select"
                      handleSelect={setNeck}
                    />
                  </div>
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      value={formatInUnit(maxNeck, UNIT_FORMAT.INCH)}
                      cssClass={Style.createInputLabel}
                      label="Max Neck"
                      data={getNeckOptions()}
                      defaultValue="select"
                      handleSelect={setMaxNeck}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-6">
                <div className="row">
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      value={formatInUnit(inseam, UNIT_FORMAT.INCH)}
                      cssClass={Style.createInputLabel}
                      label="Inseam"
                      data={getInseamOptions()}
                      defaultValue="select"
                      handleSelect={setInseam}
                    />
                  </div>
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      value={formatInUnit(maxInseam, UNIT_FORMAT.INCH)}
                      cssClass={Style.createInputLabel}
                      label="Max Inseam"
                      data={getInseamOptions()}
                      defaultValue="select"
                      handleSelect={setMaxInseam}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xl-6">
                <div className="row">
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      value={formatInUnit(sleeve, UNIT_FORMAT.INCH)}
                      cssClass={Style.createInputLabel}
                      data={getSleeveOptions()}
                      label="Sleeve"
                      defaultValue="select"
                      handleSelect={setSleeve}
                    />
                  </div>
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      value={formatInUnit(maxSleeve, UNIT_FORMAT.INCH)}
                      cssClass={Style.createInputLabel}
                      data={getSleeveOptions()}
                      label="Max Sleeve"
                      defaultValue="select"
                      handleSelect={setMaxSleeve}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-6">
                <div className="row">
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      value={jacket ?? ''}
                      cssClass={Style.createInputLabel}
                      data={getJacketSizeOptions()}
                      label="Jacket"
                      defaultValue="select"
                      handleSelect={setJacket}
                    />
                  </div>
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      value={maxJacket ?? ''}
                      cssClass={Style.createInputLabel}
                      data={getJacketSizeOptions()}
                      label="Max Jacket"
                      defaultValue="select"
                      handleSelect={setMaxJacket}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xl-6">
                <div className="row">
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      value={shoes ?? ''}
                      cssClass={Style.createInputLabel}
                      data={NumberOptions(5, 15)}
                      label="Shoe size"
                      defaultValue="select"
                      handleSelect={setShoes}
                    />
                  </div>
                  <div className="col-lg-12 col-xl-6">
                    <DropDownSearch
                      value={maxShoes ?? ''}
                      cssClass={Style.createInputLabel}
                      data={NumberOptions(5, 15)}
                      label="Max Shoes"
                      defaultValue="select"
                      handleSelect={setMaxShoes}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className=" col-sm-12 d-flex align-items-center column-gap-20">
            <div className="form-group w-100">
              <DropDownSearch
                cssClass={Style.createInputLabel}
                data={requestListOpt}
                defaultValue="select"
                selectedObj={getSelectedRequest()}
                label="Request Text"
                handleSelect={setMessageId}
                requiredField
              />
            </div>
            <Button icon={plusSvg} clickHandler={() => handleNewTemplate('message')} cssClass={Style.requestTextBtn} />
          </div>
          <TextErrorMessage message={errors.requestText || ''} />
        </div>
        <div className="row">
          <div className=" col-sm-12 d-flex align-items-center column-gap-20">
            <div className="form-group w-100">
              <DropDownSearch
                cssClass={Style.createInputLabel}
                data={templateListOpt}
                defaultValue="select"
                selectedObj={getSelectedTemplate()}
                label="Email Template"
                handleSelect={setTemplateId}
                requiredField
              />
            </div>
            <Button icon={plusSvg} clickHandler={() => handleNewTemplate('email')} cssClass={Style.requestTextBtn} />
          </div>
          <TextErrorMessage message={errors.emailTemplate || ''} />
        </div>
      </div>
      {toggleNewTemplate && (
        <CreateEmailTemplate
          emailTemplate={getTemplate('email')}
          createdFrom="job"
          closeDialog={setToggleNewTemplate}
        />
      )}
      {toggleNewRequest && (
        <CreateEmailTemplate
          emailTemplate={getTemplate('message')}
          createdFrom="job"
          type="message"
          closeDialog={setToggleNewRequest}
        />
      )}
      {showButton()}
    </>
  );
};

export default ShowJobEntry;
