import React from 'react';
import styled from 'styled-components';

interface Props {
  text?: string;
  budgetExceeded?: boolean;
}

interface IText {
  budgetExceeded?: boolean;
}

const ChartItemText: React.FC<Props> = (props) => {
  const { text, budgetExceeded } = props;
  return <TextItem budgetExceeded={budgetExceeded}>{text}</TextItem>;
};

export default ChartItemText;

const TextItem = styled.p<IText>`
  font-size: 12px;
  text-align: center;
  color: ${({ budgetExceeded }) => (budgetExceeded ? 'red' : 'green')};
  transform: rotate(90deg);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 48px;
  height: 22.14px;
  margin-top: 15px;
`;
