import React, { FunctionComponent, useEffect, useState } from 'react';
import Style from 'components/ProfileMenu/ResidencyDocs/Residency.module.css';
import PDF from 'assets/svg/pdf-gray.svg';
import Delete from 'assets/svg/delete-red.svg';
import Edit from 'assets/svg/edit-green.svg';
import Save from 'assets/svg/save-green.svg';
import Close from 'assets/svg/close.svg';

interface ResidencyDocsProps {
  item: any;
  handleDelete: any;
  handleClick: any;
}
const ResidencyDocsItem: FunctionComponent<ResidencyDocsProps> = (props: ResidencyDocsProps) => {
  const { item, handleDelete, handleClick } = props;

  const [value, setValue] = useState('');
  const [readOnly, setReadOnly] = useState(true);
  useEffect(() => {
    setValue(item.originalName);
  }, [item]);
  const handlePress = () => {
    setReadOnly(true);
    handleClick(value, 'newFileName');
  };
  const handleChange = (e: any) => {
    handleClick(e.target.value, 'type');
  };
  return (
    <div className={`mb-3 ${Style.uploadProofItemWrapper}`}>
      <div className={`d-flex justify-content-between align-items-center ${Style.fileWrapper}`}>
        <img src={PDF} alt="" />
        <input
          type="text"
          className={`mb-0 ml-4 ${Style.documentName}`}
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          readOnly={readOnly}
        />
        <div className={Style.editSaveIconsWrapper}>
          {readOnly ? (
            <div onClick={() => setReadOnly(false)} onKeyPress={() => setReadOnly(false)} role="button" tabIndex={0}>
              <img src={Edit} alt="" className={`ml-4 ${Style.editIcon}`} />
            </div>
          ) : (
            <>
              <div onClick={() => setReadOnly(true)} onKeyPress={() => setReadOnly(true)} role="button" tabIndex={0}>
                <img src={Close} alt="" />
              </div>
              <div onClick={handlePress} onKeyPress={handlePress} role="button" tabIndex={0}>
                <img src={Save} alt="" className={`ml-4 ${Style.editIcon}`} />
              </div>
            </>
          )}
        </div>
        <select
          className={`form-select ${Style.selectBox}`}
          aria-label="Default select example"
          onChange={handleChange}
        >
          <option selected>Select</option>
          <option
            value="Work Permit"
            selected={item.type !== null && item.type === 'Work Permit'}
            className="text-capitalize"
          >
            Work Permit
          </option>
          <option
            value="Notice of Assessment"
            selected={item.type !== null && item.type === 'Notice of Assessment'}
            className="text-capitalize"
          >
            Notice of Assessment
          </option>
          <option
            value="3"
            selected={item.type !== null && item.type === 'Notice of Assessment'}
            className="text-capitalize"
          >
            Bill from last year
          </option>
          <option
            value="Drivers License"
            selected={item.type !== null && item.type === 'Drivers License'}
            className="text-capitalize"
          >
            Drivers License
          </option>
          <option
            value="Health Card/Service Card"
            selected={item.type !== null && item.type === 'Health Card/Service Card'}
            className="text-capitalize"
          >
            Health Card/Service Card
          </option>
          <option
            value="Union Card"
            selected={item.type !== null && item.type === 'Union Card'}
            className="text-capitalize"
          >
            Union Card
          </option>
          <option value="Other" selected={item.type !== null && item.type === 'Other'} className="text-capitalize">
            Other
          </option>
        </select>
      </div>
      <div
        className={`cursor-pointer ${Style.cancel}`}
        onClick={handleDelete}
        onKeyPress={handleDelete}
        role="button"
        tabIndex={0}
      >
        <img src={Delete} alt="delete icon" />
      </div>
    </div>
  );
};

export default ResidencyDocsItem;
