import React from 'react';
import styled from 'styled-components';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledDownArrow = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 50px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

interface Props {
  handleClick?: () => void;
  toggleIcon: boolean;
}

const DownArrow: React.FC<Props> = ({ toggleIcon, handleClick }) => (
  <StyledDownArrow>
    <FontAwesomeIcon icon={toggleIcon ? faAngleUp : faAngleDown} onClick={handleClick} />
  </StyledDownArrow>
);

export default DownArrow;
