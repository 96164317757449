import React, { FunctionComponent } from 'react';
import Header from 'components/topHeader/topHeader';

const PrivacyPolicy: FunctionComponent = () => (
  <>
    <Header />
    <div style={{ marginLeft: '15%', marginRight: '15%' }}>
      <br />
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '20pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Privacy Policy
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          BCPAX production services ltd. (“us”, “we”, or “our”) operates the www.CastingPAX.com website / web app and
          the mobile application (all included as the “Service”).
        </span>
      </p>
      <pre dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          CastingPAX is an online service and mobile platform for connecting potential film and tv members with work
          acting as a hiring application. When using our services you receive them as an “as is” basis. While CastingPAX
          focuses on background casting services for simplifying background casting services and also for the background
          performer.&nbsp;
        </span>
      </pre>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          When you use our service and future service we roll out, you will inevitably share some information with us.
          &nbsp;This affects your privacy, so we want to explain how this can affect you and hopefully shows what you
          want to share and what to keep private.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Personal Information Collect, Use, Disclosure and Consent
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Personal Information may include your name, email address,age, home address, phone number, marital
          status,income, credit history, medical information, education, employment information and social registration
          numbers.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We are committed to providing our clients, customers, members (“you”, “your” or “them”) with exceptional
          service. Providing exceptional service may involve the collection, use and, at times, the disclosure of your
          Personal Information. Protecting your Personal Information is one of our highest priorities. While we have
          always respected your privacy and safeguarded all Personal Information, we have strengthened our commitment to
          this goal. This is to continue to provide exceptional service to you and to comply with all laws regarding the
          collection, use and disclosure of Personal Information. We will inform you of why and how we collect, use and
          disclose Personal Information; obtain your consent, as required; and handle Personal Information according to
          applicable law. Our privacy commitment includes ensuring the accuracy, confidentiality, and security of your
          Personal Information and allowing you to request access to, and correction of, your personal information.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          This page informs you of our policies regarding the collection, use and disclosure of Personal Information
          when you use our Service.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We will not use or share your information with anyone except as described in this Privacy Policy.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We will only collect Personal Information that is necessary to fulfill the following purposes:
        </span>
      </p>
      <ul style={{ marginLeft: '50px', marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
              }}
            >
              To verify identity;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
              }}
            >
              To identify your preferences;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
              }}
            >
              To open and manage an account;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
              }}
            >
              To ensure you receive a high standard of service;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
              }}
            >
              To meet regulatory requirements;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
              }}
            >
              Other legal reasons apply to the goods and services requested.
            </span>
          </p>
        </li>
      </ul>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We will obtain your consent to collect, use or disclose Personal Information. In some cases, we can do so
          without your consent (see below). You can provide consent orally, in writing, electronically or through an
          authorized representative.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You provide implied consent where our purpose for collecting using or disclosing your Personal Information
          would be considered obvious or reasonable in the circumstances. Consent may also be implied where you have
          notice and a reasonable opportunity to opt-out of having your Personal Information used for mail-outs,
          marketing or fundraising and you do not opt-out.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We may collect, use or disclose Personal Information without the consent:
        </span>
      </p>
      <ul style={{ marginLeft: '50px', marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
              }}
            >
              When permitted or required by law;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
              }}
            >
              In an emergency that threatens an individual’s life, health, or personal security;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
              }}
            >
              When the Personal Information is available from a public source;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
              }}
            >
              When we require legal advice from a lawyer;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
              }}
            >
              For the purposes of collecting a debt or protection from fraud;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
              }}
            >
              Other legally established reasons.
            </span>
          </p>
        </li>
      </ul>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We will not sell your Personal Information to other parties unless consent has been provided or implied. We
          retain your Personal Information for the time necessary to fulfill the identified purposes or a legal or
          business purpose. We will make reasonable efforts to ensure that your Personal Information is accurate and
          complete. You may request correction to your Personal Information to ensure its accuracy and completeness. A
          request must be in writing and provide sufficient detail to identify your Personal Information and the
          correction being sought.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We are committed to ensuring the security of your Personal Information and may use passwords, encryption,
          firewalls, restricted employee access or other methods, in our discretion. We will use appropriate security
          measures when destroying your Personal Information such as shredding documents or deleting electronically
          stored information, in our discretion.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We collect information, content and data that you post, or information of things you have viewed. Content
          information can include which pages you view the most, and how you interact with specific users and features.
          &nbsp;Other information given to us is phone information, device phone book (only if we ask for it), location
          information (explained more further on), information collected by cookies and similar technologies, and camera
          and photos.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          How We Use Information We Collect
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          To place you in a specific location, we collect your geolocation so that we can show posts specific to your
          filter settings and what is near you. We may preference posts due to your history of viewing specific posts to
          help you find what’s most relevant to you.
        </span>
      </p>
      <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '15pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Other ways we use your information are through what jobs you apply to, your location so we can notify you when
          more jobs appear near you, to verify your identity and to prevent spam, and to better understand what users
          want, and the use of our service to better tailor the service towards our users. &nbsp;Information that you
          post (including posts, messages, replies, and other public information) may be used as follows:
        </span>
      </p>
      <ul style={{ marginLeft: '50px', marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
              }}
            >
              We may disclose information we collect to third party vendors, service providers, contractors or agents
              who perform functions on our behalf.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
              }}
            >
              We may transfer the information we collect to another business.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
              }}
            >
              For advertisement purposes
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#222222',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
          }}
        >
          <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#222222',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
              }}
            >
              For posting content/post details and content like photos to make things more interesting to other users.
            </span>
          </p>
        </li>
      </ul>
    </div>
  </>
);

export default PrivacyPolicy;
