import React, { FunctionComponent } from 'react';
import Style from 'components/castingDirector/skills/Skills.module.css';
import { PerformerSkill } from 'modules/user/types';
import Professional from 'assets/images/diamond.png';
import Advanced from 'assets/images/star.png';
import Average from 'assets/images/wave.png';
import AboveAverage from 'assets/images/meter.png';

interface SkillsProps {
  skill: PerformerSkill;
}
const SkillViewItem: FunctionComponent<SkillsProps> = (props) => {
  const { skill } = props;
  const getIcon = (type: string) => {
    switch (type) {
      case 'professional':
        return Professional;
      case 'expert':
        return Advanced;
      case 'average':
        return Average;
      case 'aboveaverage':
        return AboveAverage;
      default:
        return Professional;
    }
  };
  return (
    <>
      <div className={`d-flex mr-3 mb-3 ${Style.skillWrapper}`}>
        <p className={`mb-0 ${Style.skillName}`}>{skill.skillCategoryId ? skill.skillCategoryId.title : ''}</p>
        <div className={`d-flex align-items-center justify-content-between ${Style.skillDashWrapper}`}>
          <span className={`mb-0 mr-3 ${Style.skillDash}`}>&nbsp; - </span>
          <div className="newSkillSearch d-flex align-items-center">
            <img src={getIcon(skill.skillLevel)} alt="" />
            {skill.skillLevel}
          </div>
        </div>
      </div>
    </>
  );
};

export default SkillViewItem;
