import React, { FunctionComponent, useRef } from 'react';
import QRCode from 'react-qr-code';
import { User } from 'modules/user/types';
import downloadSvg from 'assets/svg/download-arrow.svg';
import html2canvas from 'html2canvas';
import Style from './qrCode.module.css';

interface QrCodeProps {
  user?: User;
}
const QrCode: FunctionComponent<QrCodeProps> = (props: QrCodeProps) => {
  const { user } = props;
  const downloadRef = useRef<any>();

  const downloadQRCode = async () => {
    const element = downloadRef.current;
    const canvas = await html2canvas(element);
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'QR.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <div className="w-100">
      <div className="pt-3 px-3">
        <h3 className={Style.qrHeading}>QR Code</h3>
        <p className={`${Style.qrDescription} mb-0`}>
          Please save the QR code for easy sign in / sign out on set with the BG Coordinators
        </p>
      </div>
      <hr className={Style.horizontalLine} />
      <div className="text-center w-100 mt-5">
        <div className={Style.qrCodeBox} ref={downloadRef}>
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={user?.id || ''}
            viewBox="0 0 250 250"
          />
          <p className={`mb-0 ${Style.userName}`}>{user?.name || ''}</p>
        </div>
        <button type="button" className={Style.downloadButton}>
          <img src={downloadSvg} alt="download arrow" />
          <span className="mb-0" onClick={downloadQRCode} onKeyPress={downloadQRCode} role="button" tabIndex={0}>
            Save To Phone
          </span>
        </button>
      </div>
    </div>
  );
};

export default QrCode;
