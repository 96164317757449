import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { MatrixRedirect, Show, ShowList, ShowTypes } from 'modules/Show/show.types';
import Button from 'components/controls/button/button';
import SliderWrapper from 'components/controls/Slider/SliderWrapper';
import CreateShow from 'components/CreateShow/index';
import ShowDetail from 'components/CreateShow/Detail/index';
import NavItem from 'components/ShowTopNavigation/NavItems/NavItem';
import Style from 'components/ShowTopNavigation/ShowTopNav.module.css';
import plusSvg from 'assets/svg/plus.svg';
import downArrowSvg from 'assets/svg/arrowDownn.svg';
import upArrowSvg from 'assets/svg/upArroww.svg';
import { defaultPagination, getCurrentDate, getHomePage } from 'helpers/utils';
import { RoleCode, User } from 'modules/user/types';
import {
  ROLE_COORDINATOR,
  ROLE_PRODUCTION,
  SCREEN_SIZE_MOBILE,
  SHOW_FROM_NAV_BAR,
  ROLE_PAYROLL,
} from 'store/castingPax.constants';
import { useSelector } from 'react-redux';
import { ScreenSize } from 'modules/general/general.type';
import { getScreenWidth } from 'store/selector/general.selector';
import { useHistory, useLocation } from 'react-router';
import TopSliderWrapper from 'components/controls/Slider/TopSliderWrapper';
import { directorTopNavSlider } from 'components/controls/Slider/SliderResponsiveness';

interface ShowTopNavigationProps {
  showList: ShowList;
  selectedShowId: string;
  showToOpen: string;
  setSelectedShow: Function;
  getShowListing: Function;
  showEdit: Show;
  showDetail: Show;
  showEditRequest: Function;
  loading: boolean;
  user: User;
  showDetailBegin: Function;
  redirectedMatrixInfo: MatrixRedirect;
  clearMatrixRedirected: Function;
  setDatesForFitler: Function;
  cssClass: string;
  setShowRedirect: Function;
}

const ShowTopNavigation: FunctionComponent<ShowTopNavigationProps> = (props) => {
  const {
    showList,
    selectedShowId,
    showToOpen,
    setSelectedShow,
    getShowListing,
    showEdit,
    showDetail,
    showEditRequest,
    showDetailBegin,
    loading,
    user,
    redirectedMatrixInfo,
    clearMatrixRedirected,
    setDatesForFitler,
    cssClass,
    setShowRedirect,
  } = props;
  useEffect(() => {}, [selectedShowId]);
  const showId = Math.random().toString();
  const showObj = {
    id: showId,
    title: '',
    showType: ShowTypes.FILM,
    remarks: '',
    additionalInfo: '',
    seasons: [],
    isNew: true,
    templateId: '',
    imageFile: null,
    showWorkingDays: [
      {
        id: Math.random().toString(),
        showId,
        title: 'Day 1',
        dayTitle: 'D1',
        date: getCurrentDate(),
        isNew: true,
        type: 'film',
      },
    ],
    users: [],
    team: { data: [], pagination: defaultPagination() },
    matrix: { data: [], pagination: defaultPagination(), stats: [] },
  };
  const [showSlideListing, setShowSLideListing] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);

  useEffect(() => {
    getShowListing();
  }, []);

  useEffect(() => {
    if (redirectedMatrixInfo && redirectedMatrixInfo.showId) {
      if (showList.length > 0) {
        setSelectedShow(redirectedMatrixInfo.showId);
        clearMatrixRedirected();
      }
      setDatesForFitler([redirectedMatrixInfo.date]);
    } else if (showToOpen) {
      if (showList.length > 0) {
        setSelectedShow(showToOpen);
        setShowRedirect('');
      }
    }
  }, [showList]);

  useEffect(() => {
    if (selectedShowId) {
      const showData = showList.find((x: Show) => x.id === selectedShowId);
      if (showData && !(showData.episodes || showData.showWorkingDays)) {
        showDetailBegin({ showId: selectedShowId }, SHOW_FROM_NAV_BAR);
      }
    }
  }, [selectedShowId]);

  const handleClick = (event: React.FormEvent<HTMLDivElement>) => {
    setSelectedShow(event.currentTarget.id);
    if (!location.pathname.includes('home')) {
      history.push(getHomePage(user.role as RoleCode));
    }
  };

  const handleClickNew = () => {
    if (loading) return;
    showEditRequest(showObj);
  };

  const slideToShow = useMemo(() => {
    switch (user.role) {
      case ROLE_COORDINATOR:
      case ROLE_PRODUCTION:
        return 4;
      case ROLE_PAYROLL:
        return 3;
      default:
        return 7;
    }
  }, [user]);
  const listingShow = () => {
    setShowSLideListing(!showSlideListing);
    console.log('arrowclicked', showSlideListing);
  };

  if (currentScreenSize === SCREEN_SIZE_MOBILE) {
    return (
      <div className={`main-top-slider ${Style.mainWrapper}`}>
        <div className={Style.mobileSliderHeader}>
          <span className="d-inline-block" onClick={listingShow} onKeyPress={listingShow} role="button" tabIndex={0}>
            <img src={showSlideListing ? upArrowSvg : downArrowSvg} className={Style.downArrow} alt="down arrow" />
          </span>
          <span className={`mb-0 ${Style.coordinatorPlusButton}`}>
            {user.role !== ROLE_COORDINATOR && (
              <Button cssClass={Style.plusItemBtn} showLoading={loading} clickHandler={handleClickNew} icon={plusSvg} />
            )}
          </span>
        </div>
        {showSlideListing && (
          <>
            <div
              className={`showMobileSlider ${Style.navItemContainer} ${
                user.role === ROLE_PRODUCTION && Style.producerContainer
              }`}
            >
              <SliderWrapper sliderSettings={directorTopNavSlider} slideToShow={slideToShow} goto={showList.length}>
                {showList.map((item: Show) => (
                  <NavItem key={item.id} selected={item.id === selectedShowId} item={item} handleClick={handleClick} />
                ))}
              </SliderWrapper>
            </div>

            {'title' in showEdit ? <CreateShow closeDialog={showEditRequest} /> : ''}
            {'title' in showDetail ? <ShowDetail detailData={showDetail} /> : ''}
          </>
        )}
      </div>
    );
  }

  const getCoordinatorClass = () => {
    if (user.role === ROLE_PRODUCTION || user.role === ROLE_COORDINATOR) return Style.coordinatorContainer;
    return '';
  };

  const moveSlider = () => {
    if (selectedShowId) {
      const index: number = showList.findIndex((s: Show) => s.id === selectedShowId);
      if (index !== -1) return index;
    }
    return showList.length;
  };

  const getSliderComponent = () => {
    if (user.role === ROLE_PRODUCTION || user.role === ROLE_COORDINATOR) {
      return (
        <TopSliderWrapper sliderSettings={directorTopNavSlider} slideToShow={slideToShow} goto={moveSlider()}>
          {showList.map((item: Show) => (
            <NavItem key={item.id} selected={item.id === selectedShowId} item={item} handleClick={handleClick} />
          ))}
        </TopSliderWrapper>
      );
    }
    return (
      <SliderWrapper sliderSettings={directorTopNavSlider} slideToShow={slideToShow} goto={moveSlider()}>
        {showList.map((item: Show) => (
          <NavItem key={item.id} selected={item.id === selectedShowId} item={item} handleClick={handleClick} />
        ))}
      </SliderWrapper>
    );
  };

  return (
    <div className={`main-top-slider ${Style.mainWrapper} ${cssClass}`}>
      <div className={`${Style.navItemContainer} ${getCoordinatorClass()}`}>{getSliderComponent()}</div>
      <span className="mb-0 pr-2 plusBtn">
        {user.role !== ROLE_COORDINATOR && user.role !== ROLE_PAYROLL && (
          <Button cssClass={Style.plusItemBtn} showLoading={loading} clickHandler={handleClickNew} icon={plusSvg} />
        )}
      </span>
      {'title' in showEdit ? <CreateShow closeDialog={showEditRequest} /> : ''}
      {'title' in showDetail ? <ShowDetail detailData={showDetail} /> : ''}
    </div>
  );
};

export default ShowTopNavigation;
