import React, { FunctionComponent, useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Style from 'components/ProfileMenu/ResidencyDocs/Residency.module.css';
import Button from 'components/controls/button/button';
import { PerformerWorkedDay } from 'modules/Show/show.types';
import { formateDate } from 'helpers/utils';
import { SCREEN_SIZE_MEDIUM_TABLET, SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET } from 'store/castingPax.constants';
import { ScreenSize } from 'modules/general/general.type';
import { getScreenWidth } from 'store/selector/general.selector';
import InvoicePDF from 'components/Payroll/VoucherManagement/Invoice/index';
import { getRateListRequest, getCustomRateListRequest } from 'store/actions/show.actions';
import WorkdayStyle from 'components/ProfileMenu/DaysWorked/DaysWorked.module.css';
import { updatePerformerVoucherStatus } from 'store/actions/performer.actions';
import PERFORMER_STATUS from 'utils/performerStatus';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import CancelReason from 'components/bgPerformer/popover/CancelReason';
import Modal from 'components/controls/Modal/Modal';

interface WorkItemProps {
  item: PerformerWorkedDay;
  getRateList: Function;
  updatePerformerVoucher: Function;
}

const WorkItem: FunctionComponent<WorkItemProps> = (props: WorkItemProps) => {
  const { item, getRateList, updatePerformerVoucher } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleDetail = () => console.log();
  const handleClick = () => setIsExpanded(!isExpanded);
  const totalWage = () => item.totalWageAmount + item.totalCostumeAmount + item.totalMileageAmount;
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);
  const [isViewRequest, setIsViewRequest] = useState<boolean>(false);
  const [voucherData, setVoucherData] = useState<any>({});

  useEffect(() => {
    getRateList();
  }, []);

  useEffect(() => {
    if (!isExpanded) {
      setIsLoading(false);
    }
  }, [isExpanded]);

  useEffect(() => {
    const voucherBasicInfo = item?.voucherBasicData;
    if (item.signInTime) {
      if (voucherBasicInfo) {
        setVoucherData({ ...item, ...voucherBasicInfo });
      } else {
        setVoucherData(item);
      }
    }
  }, [item]);

  const handleDownloadVoucher = () => {
    if (voucherData && voucherData._id) {
      setIsLoading(true);
    }
  };

  const updateVoucher = (workingDayId: string, performerId: string, status: string, reason?: string) => {
    if (!workingDayId || !performerId) return;
    updatePerformerVoucher({ workingDayId, performerId, status, message: reason });
  };

  const handleConfirm = () => {
    const { workingDayId, performerId } = item;
    const status = PERFORMER_STATUS.CONFIRM;
    const reason = 'Performer Confirmed';
    if (updatePerformerVoucher) {
      updateVoucher(workingDayId, performerId, status, reason);
    }
  };

  const handleRequestedChange = (reason: string) => {
    const { workingDayId, performerId } = item;
    let status = PERFORMER_STATUS.PENDING;
    if (item.performerVoucherStatus === PERFORMER_STATUS.CONFIRM) {
      status = PERFORMER_STATUS.CONFIRM;
    }
    if (updatePerformerVoucher) {
      updateVoucher(workingDayId, performerId, status, reason);
    }
    document.body.click();
  };

  const isConfirmed = item.performerVoucherStatus === PERFORMER_STATUS.CONFIRM;
  const isPending = item.performerVoucherStatus === PERFORMER_STATUS.PENDING;

  const getConfirmedBtn = () => {
    if (isConfirmed) {
      return <div className={`mb-0 ${Style.confirmedText}`}>Confirmed</div>;
    }
    return <Button clickHandler={handleConfirm} label="confirm work date" cssClass={`ml-3 ${Style.confirmButton}`} />;
  };

  const getRequestedBtn = () => {
    if (isPending || isConfirmed) {
      return (
        <PopoverContainer itemComponent={popoverComponent} cssClass={`${Style.cancelButtonPopover}`}>
          <button type="submit" className={Style.changeButton}>
            Voucher Change Request
          </button>
        </PopoverContainer>
      );
    }
    return (
      <div
        role="button"
        tabIndex={0}
        onClick={() => setIsViewRequest(true)}
        onKeyPress={() => {}}
        className="cursor-pointer"
      >
        View Request
      </div>
    );
  };

  const popoverComponent = () => (
    <CancelReason handleCancel={handleRequestedChange} btnLabel="Send" placeholderTxt="voucher change request" />
  );

  const ModalBody = () => <div>{item?.performerRequestMessage}</div>;

  if (
    currentScreenSize === SCREEN_SIZE_MOBILE ||
    currentScreenSize === SCREEN_SIZE_TABLET ||
    currentScreenSize === SCREEN_SIZE_MEDIUM_TABLET
  ) {
    return (
      <>
        <div className="mb-2">
          <div className={`${Style.itemMainWrapper}`}>
            <div className={`d-flex align-items-center justify-content-between ${Style.marginBottomFive}`}>
              <p className={`mb-0 ${Style.itemDate}`}>{formateDate(item.date, 'DD MMM')}</p>
              <div className={`d-flex align-items-center justify-content-between ${Style.itemIcons}`}>
                <FontAwesomeIcon
                  className="cursor-pointer d-inline-block"
                  icon={faExternalLinkAlt}
                  onClick={handleDetail}
                />
                <FontAwesomeIcon
                  className="cursor-pointer d-inline-block"
                  icon={isExpanded ? faChevronDown : faChevronUp}
                  onClick={handleClick}
                />
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center">
              <p className={`mb-0 ${Style.itemShowName}`}>{item.showId.title}</p>
              <p className={`mb-0 ${Style.itemRole}`}>{item.role}</p>
            </div>
          </div>
          {isExpanded && (
            <div className={`d-flex align-items-center justify-content-between ${Style.itemExpendedWrapper}`}>
              <div className="d-flex align-items-center py-3">
                <div className={Style.detailText}>
                  <p className={`mb-0 ${Style.itemRole}`}>Est</p>
                  <span className={`mb-0 ${Style.dollarSign}`}>$</span>
                  <p className={`mb-0 ${Style.price}`}>{totalWage()}</p>
                </div>
                <div onClick={handleDownloadVoucher} onKeyUp={() => {}} tabIndex={0} role="button">
                  {/* <a href="/#" className={Style.voucherLink}> */}
                  View voucher
                  {/* </a> */}
                </div>
              </div>
              <div className={Style.buttonWrapper}>
                {getRequestedBtn()}
                {getConfirmedBtn()}
              </div>
            </div>
          )}
        </div>
        {isLoading && (
          <div className="w-100">
            <InvoicePDF item={voucherData || {}} isLoading={setIsLoading} />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div className={`mb-2 ${WorkdayStyle.workItemInvoice}`}>
        <div className={`d-flex align-items-center justify-content-between ${Style.itemMainWrapper}`}>
          <p className={`mb-0 ${Style.itemDate}`}>{formateDate(item.date, 'DD MMM')}</p>
          <p className={`mb-0 ${Style.itemShowName}`}>{item.showId.title}</p>
          <p className={`mb-0 ${Style.itemRole}`}>{item.role}</p>
          <div className={`d-flex align-items-center justify-content-between ${Style.itemIcons}`}>
            <FontAwesomeIcon
              className="cursor-pointer d-inline-block"
              icon={faExternalLinkAlt}
              onClick={handleDetail}
            />
            <FontAwesomeIcon
              className="cursor-pointer d-inline-block"
              icon={isExpanded ? faChevronDown : faChevronUp}
              onClick={handleClick}
            />
          </div>
        </div>
        {isExpanded && (
          <div className={`d-flex mb-1 align-items-center justify-content-between ${Style.itemExpendedWrapper}`}>
            <div className="d-flex align-items-center">
              <div className={Style.detailText}>
                <p className={`mb-0 ${Style.itemRole}`}>Est</p>
                <span className={`mb-0 ${Style.dollarSign}`}>$</span>
                <p className={`mb-0 ${Style.price}`}>{totalWage()}</p>
              </div>
              <div onClick={handleDownloadVoucher} onKeyUp={() => {}} tabIndex={0} role="button">
                {/* <a href="/#" className={Style.voucherLink}> */}
                View voucher
                {/* </a> */}
              </div>
            </div>
            <div className={Style.buttonWrapper}>
              {getRequestedBtn()}
              {getConfirmedBtn()}
            </div>
          </div>
        )}
      </div>
      {isLoading && (
        <div className={WorkdayStyle.workItemInvoice}>
          {voucherData.signInTime !== null && <InvoicePDF item={voucherData || {}} isLoading={setIsLoading} />}
        </div>
      )}
      {isViewRequest && <Modal title="Request Message" body={ModalBody} closeModal={() => setIsViewRequest(false)} />}
    </>
  );
};

const mapDispatchToProps = {
  getRateList: getRateListRequest,
  getCustomRateList: getCustomRateListRequest,
  updatePerformerVoucher: updatePerformerVoucherStatus,
};

export default connect(null, mapDispatchToProps)(WorkItem);
