import createSelector from 'utils/reselect';

const userStateData = (state: any) => {
  const { users } = state;
  return users || {};
};

export const getUser = createSelector(userStateData, (users) => {
  const u = users.get('user') || null;
  if (u) {
    return JSON.parse(JSON.stringify(u));
  }
  return u;
});

export const cardLoading = createSelector(userStateData, (users) => users.get('cardLoading') || false);

export const pusherRefresh = createSelector(userStateData, (users) => users.get('refreshPusher') || false);

export const closeAccountLoading = createSelector(userStateData, (users) => users.get('closeLoading'));

export const isSignUpCompleted = createSelector(userStateData, (users) => users.get('isSignUpCompleted') || false);

export const loginInProgress = createSelector(userStateData, (users) => users.get('loading'));

export const fileUploadingProgress = createSelector(userStateData, (users) => users.get('fileLoading'));

export const resumeUploadingProgress = createSelector(userStateData, (users) => users.get('resumeLoading'));

export const loginError = createSelector(userStateData, (users) => users.get('error'));

export const getSignupStep = createSelector(userStateData, (users) => users.get('step'));

export const selectedTab = createSelector(userStateData, (users) => users.get('selectedTab'));

export const userCardDetails = createSelector(userStateData, (users) => users.get('cardDetails') || null);

export const userCardError = createSelector(userStateData, (users) => users.get('cardError') || '');

export const userCardLoading = createSelector(userStateData, (users) => users.get('cardLoading') || false);

export const subscriptionStatus = createSelector(userStateData, (users) => users.get('subscriptionStatus') || 'NA');

export const getHistoryData = createSelector(userStateData, (users) => users.get('historyData') || []);

export const getPagination = createSelector(userStateData, (users) => users.get('historyPagination'));

export const privacyInProgress = createSelector(userStateData, (users) => users.get('privacyLoading') || false);

export const getHistoryLoading = createSelector(userStateData, (users) => users.get('historyloading') || false);

export const getBusinessLoading = createSelector(userStateData, (users) => users.get('businessLoading') || false);
