import React from 'react';
import Styles from '../../../styles/show/components/Breakdown.module.css';

interface Props {
  handleClick: (e: React.FormEvent<HTMLDivElement>) => void;
}

const BreakdownButton: React.FC<Props> = (props: Props) => {
  const { handleClick } = props;
  return (
    <div className={Styles.breakDownWrapper} role="button" tabIndex={0} onClick={handleClick} onKeyPress={handleClick}>
      Breakdown
    </div>
  );
};

export default BreakdownButton;
