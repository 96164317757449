import { call, all, takeEvery, put, takeLatest } from 'redux-saga/effects';
import { generalActions, myPerformerActions, showActions } from 'store/actions';
import ACTIONS, {
  JOB_STATUS_BOOKED,
  JOB_STATUS_CANCELLED,
  SHOW_JOB_STATUS,
  TOAST_IDS,
  TOAST_TYPE,
} from 'store/castingPax.constants';
import showApi from 'store/services/show.services';
import {
  CloseOrDeleteShowJob,
  DateJobRequestParams,
  MatrixAtoms,
  PerformerItem,
  ShowCreateParams,
  ShowDayJob,
  ShowList,
  IShowData,
  RateList,
  // RateLists,
} from 'modules/Show/show.types';
import ApiResponse from 'modules/response/response.types';
import {
  IAddCoordinator,
  IAddShowAccess,
  IBGCInvitation,
  IBgSizeCardParams,
  IBreakdownParams,
  ICancelRequestParams,
  IChangeAtoms,
  IChangeRoleParams,
  ICopyMatrixItem,
  ICopyMatrixItemResponse,
  IDailyCost,
  IEmailTemplateParams,
  IMatrixParams,
  INewAtomsParam,
  IPerformerSearchParam,
  IProfileFileParam,
  IRemoveCallSheetFile,
  ISetMatrixEmailTemplate,
  IShowId,
  IUpdateMatrixItem,
  IUpdateMatrixTime,
  IBreakdown,
} from 'modules/params/param.type';
import { EmailTemplate, EmailTemplateList } from 'modules/general/general.type';
import { User } from 'modules/user/types';
import jobApi from 'store/services/job.services';

function* fetchShowListing() {
  try {
    yield put(showActions.showListing.pending);
    const response: ShowList = yield call(showApi.fetchShowListing, { type: 'short' });
    yield put(showActions.showListing.success(response));
  } catch (error) {
    yield put(showActions.showListing.error(error.message));
  }
}

function* fetchShowDetails(state: { type: string; payload: IShowId; showType: string }) {
  try {
    yield put(showActions.showDetail.pending);
    const response: ApiResponse = yield call(showApi.fetchShowDetail, state.payload);
    yield put(showActions.showDetail.success(response.data, state.showType));
  } catch (error) {
    yield put(showActions.showDetail.error(error.message));
  }
}

function* createShow(state: { type: string; payload: ShowCreateParams }) {
  try {
    yield put(showActions.createShow.pending);
    const response: ApiResponse = yield call(showApi.createShow, state.payload);
    yield put(showActions.createShow.success(response.data));
  } catch (error) {
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.CREATE_SHOW_FAIL,
        title: error.message,
        type: TOAST_TYPE.ERROR,
      }),
    );
    yield put(showActions.createShow.error(error.message));
  }
}

function* showAccess(state: { type: string; payload: IAddShowAccess }) {
  try {
    yield put(showActions.showAccessRequest.pending);
    const response: ApiResponse = yield call(showApi.showAccessRequest, state.payload);
    yield put(showActions.showAccessRequest.success(response.data));
  } catch (error) {
    yield put(showActions.showAccessRequest.error(error.message));
  }
}

function* updateShow(state: { type: string; payload: ShowCreateParams }) {
  try {
    yield put(showActions.createShow.pending);
    const response: ApiResponse = yield call(showApi.updateShow, state.payload);
    yield put(showActions.createShow.success(response.data));
  } catch (error) {
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.CREATE_SHOW_FAIL,
        title: 'Fail to create Show',
        type: TOAST_TYPE.ERROR,
      }),
    );
    yield put(showActions.createShow.error(error.message));
  }
}

function* createShowRole(state: { type: string; payload: ShowDayJob }) {
  try {
    yield put(showActions.createJob.pending);
    const response: ApiResponse = yield call(showApi.createRole, state.payload);
    yield put(showActions.createJob.success(response.data));
    const res: EmailTemplateList = yield call(showApi.fetchEmailTemplates, {});
    yield put(showActions.emailTemplates.success(res));

    if (state.payload.id === '-1') {
      yield put(
        generalActions.showToast({
          id: TOAST_IDS.CREATE_JOB_SUCCESS,
          title: 'Job successfully created',
          type: TOAST_TYPE.SUCCESS,
        }),
      );
    } else {
      yield put(
        generalActions.showToast({
          id: TOAST_IDS.CREATE_JOB_SUCCESS,
          title: 'Job successfully updated',
          type: TOAST_TYPE.SUCCESS,
        }),
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({ id: TOAST_IDS.CREATE_JOB_FAIL, title: 'Fail to save Role', type: TOAST_TYPE.ERROR }),
    );
    yield put(showActions.createJob.error(error.message));
  }
}

function* closeOrDeleteShowJob(state: { type: String; payload: CloseOrDeleteShowJob }) {
  try {
    yield put(showActions.closeOrDeleteJob.pending);
    const response: ApiResponse = yield call(showApi.closeOrDeleteRole, state.payload);
    if (response && state.payload.status === SHOW_JOB_STATUS.CLOSE) {
      yield put(showActions.closeOrDeleteJob.success(response.data));
      const res: EmailTemplateList = yield call(showApi.fetchEmailTemplates, {});
      yield put(showActions.emailTemplates.success(res));
    } else if (response && state.payload.status === SHOW_JOB_STATUS.DELETE) {
      yield put(showActions.removeShowJob(state.payload.id));
    }
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.CLOSE_OR_DELETE_JOB_SUCCESS,
        title: `Job ${state.payload.status === SHOW_JOB_STATUS.CLOSE ? 'closed' : 'deleted'} successfully`,
        type: TOAST_TYPE.SUCCESS,
      }),
    );
  } catch (err: any) {
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.CLOSE_OR_DELETE_JOB_ERROR,
        title: 'Fail to save Role',
        type: TOAST_TYPE.ERROR,
      }),
    );
    yield put(showActions.closeOrDeleteJob.error(err.message));
  }
}

function* fetchDayJobs(state: { type: string; payload: DateJobRequestParams }) {
  try {
    yield put(showActions.showSelectedDateJob.pending);
    const response: ApiResponse = yield call(showApi.fetchDayJobListing, state.payload);
    yield put(showActions.showSelectedDateJob.success(response.data, state.payload));
  } catch (error) {
    yield put(showActions.showSelectedDateJob.error(error.message));
  }
}

function* fetchErrorNotes(state: { type: string; payload: any }) {
  try {
    yield put(showActions.errorNotes.pending);
    const response: any = yield call(showApi.fetchErrorNotes, state.payload);
    yield put(showActions.errorNotes.success(response, state.payload));
  } catch (error) {
    yield put(showActions.errorNotes.error(error.message));
  }
}

function* searcPerformerForJob(state: { type: string; payload: IPerformerSearchParam }) {
  try {
    yield put(showActions.searchPerformer.pending);
    const response: any = yield call(showApi.searchPerformer, state.payload);
    yield put(showActions.searchPerformer.success(response));
  } catch (error) {
    yield put(showActions.searchPerformer.error(error.message));
  }
}

function* fetchEmailTemplates(state: { type: string; payload: IEmailTemplateParams }) {
  try {
    yield put(showActions.emailTemplates.pending);
    const response: EmailTemplateList = yield call(showApi.fetchEmailTemplates, state.payload);
    yield put(showActions.emailTemplates.success(response));
  } catch (error) {
    yield put(showActions.emailTemplates.error(error.message));
  }
}

function* saveEmailTemplate(state: { type: string; payload: IEmailTemplateParams }) {
  try {
    yield put(showActions.saveEmailTemplate.pending);
    const response: EmailTemplate = yield call(showApi.saveEmailTemplate, state.payload);
    yield put(showActions.saveEmailTemplate.success(response));
    if (response) {
      yield put(
        generalActions.showToast({
          id: TOAST_IDS.CREATE_REQUEST_MESSAGE,
          title:
            state.payload.templateType === 'message'
              ? 'Request Text successfully created'
              : 'Email Template successfully created',
          type: TOAST_TYPE.SUCCESS,
        }),
      );
    }
  } catch (error) {
    yield put(showActions.saveEmailTemplate.error(error.message));
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.CREATE_REQUEST_MESSAGE,
        title:
          state.payload.templateType === 'message' ? 'Request Text not be created' : 'Email Template not be created',
        type: TOAST_TYPE.ERROR,
      }),
    );
  }
}

function* fetchMatrixData(state: { type: string; payload: IMatrixParams }) {
  try {
    yield put(showActions.fetchMatrixData.pending(state.payload));
    const response: ApiResponse = yield call(showApi.fetchMatrixData, state.payload);
    yield put(showActions.fetchMatrixData.success(response));
  } catch (error) {
    yield put(showActions.fetchMatrixData.error(error.message));
  }
}

function* fetchMatrixPerformer(state: { type: string; payload: IMatrixParams }) {
  try {
    yield put(showActions.fetchMatrixPerformer.pending);
    const response: PerformerItem[] = yield call(showApi.fetchMatrixPerformer, state.payload);
    yield put(showActions.fetchMatrixPerformer.success(response, state.payload));
  } catch (error) {
    yield put(showActions.fetchMatrixPerformer.error(error.message));
  }
}

function* fetchShowHistory(state: { type: string; payload: IMatrixParams }) {
  try {
    yield put(showActions.fetchShowHistory.pending);
    const response: IShowData[] = yield call(showApi.fetchShowHistory, state.payload);
    yield put(showActions.fetchShowHistory.success(response, state.payload));
  } catch (error) {
    yield put(showActions.fetchShowHistory.error(error.message));
  }
}

function* fetchRecentActions(state: { type: string; payload: IMatrixParams }) {
  try {
    yield put(showActions.fetchRecentActions.pending);
    const response: any[] = yield call(showApi.fetchRecentActions, state.payload);
    yield put(showActions.fetchRecentActions.success(response, state.payload));
  } catch (error) {
    yield put(showActions.fetchRecentActions.error(error.message));
  }
}

function* fetchCostBudgetData(state: { type: string; payload: IMatrixParams }) {
  try {
    yield put(showActions.fetchCostBudgetData.pending);
    const response: IShowData[] = yield call(showApi.fetchCostData, state.payload);
    yield put(showActions.fetchCostBudgetData.success(response, state.payload));
  } catch (error) {
    yield put(showActions.fetchCostBudgetData.error(error.message));
  }
}

function* saveAtoms(state: { type: string; payload: INewAtomsParam }) {
  try {
    yield put(showActions.saveNewAtoms.pending);
    const response: MatrixAtoms = yield call(showApi.saveNewAtoms, state.payload);
    yield put(showActions.saveNewAtoms.success(response, state.payload));
  } catch (error) {
    yield put(showActions.saveNewAtoms.error(error.message));
  }
}

function* saveCustomRate(state: { type: string; payload: any }) {
  try {
    yield put(showActions.saveCustomRate.pending);
    const response: any = yield call(showApi.saveCustomRates, state.payload);
    yield put(showActions.saveCustomRate.success(response, state.payload));
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.CREATE_CUSTOM_RATE_SUCCESS,
        title: response.message,
        type: TOAST_TYPE.SUCCESS,
      }),
    );
  } catch (error) {
    yield put(showActions.saveCustomRate.error(error.message));
  }
}

function* shareCustomRate(state: { type: string; payload: any }) {
  try {
    yield put(showActions.shareCustomRate.pending);
    const response: any = yield call(showApi.shareCustomRates, state.payload);
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.SHARE_CUSTOM_RATE_SUCCESS,
        title: response.message,
        type: TOAST_TYPE.SUCCESS,
      }),
    );
  } catch (error) {
    yield put(showActions.shareCustomRate.error(error.message));
  }
}

function* breakdown(state: { type: string; payload: IBreakdown }) {
  try {
    yield put(showActions.breakdown.pending(state.payload));
    const response: any = yield call(showApi.breakdown, state.payload);
    yield put(showActions.breakdown.success(response));
  } catch (error) {
    yield put(showActions.breakdown.error(error.message));
  }
}

function* processCoordinator(state: { type: string; payload: IAddCoordinator; data: User }) {
  try {
    yield put(showActions.manageCoordinator.pending);
    yield call(showApi.addMatrixCoordinator, state.payload);
    yield put(showActions.manageCoordinator.success(state.data, state.payload));
  } catch (error) {
    yield put(showActions.manageCoordinator.error(error.message));
  }
}

function* uploadCallSheetFile(state: { type: string; payload: IProfileFileParam; workingDayId: string }) {
  try {
    yield put(showActions.uploadCallSheet.pending);
    const response: string = yield call(showApi.uploadCallSheet, state.payload);
    yield put(showActions.uploadCallSheet.success(response, state.workingDayId));
  } catch (error) {
    yield put(showActions.uploadCallSheet.error(error.message));
  }
}

function* removeCallSheetFile(state: { type: string; payload: IRemoveCallSheetFile }) {
  try {
    yield put(showActions.removeCallSheet.pending);
    const response: any = yield call(showApi.removeCallSheet, state.payload);
    if (response) yield put(showActions.removeCallSheet.success(response.data));
  } catch (error: any) {
    yield put(showActions.removeCallSheet.error(error.message));
  }
}

function* updateMatrixStatus(state: { type: string; payload: IMatrixParams }) {
  try {
    yield put(showActions.setMatrixStatus.pending);
    yield call(showApi.updateMatrixStatus, state.payload);
    yield put(showActions.setMatrixStatus.success(state.payload));
  } catch (error) {
    yield put(showActions.setMatrixStatus.error(error.message));
  }
}

function* fetchShowUser(state: { type: string; payload: IMatrixParams }) {
  try {
    yield put(showActions.fetchShowUsers.pending);
    const response: ApiResponse = yield call(showApi.fetchShowUser, state.payload);
    yield put(showActions.fetchShowUsers.success(response, state.payload));
  } catch (error) {
    yield put(showActions.fetchShowUsers.error(error.message));
  }
}
function* fetchNotesReview(state: { type: string; payload: IMatrixParams }) {
  try {
    yield put(showActions.notesReview.pending);
    const response: any[] = yield call(showApi.fetchNotesReview, state.payload);
    yield put(showActions.notesReview.success(response, state.payload));
  } catch (error) {
    yield put(showActions.notesReview.error(error.message));
  }
}

function* fetchProducerMatrix(state: { type: string; payload: IMatrixParams }) {
  try {
    yield put(showActions.fetchProducerMatrix.pending);
    const response: ApiResponse = yield call(showApi.fetchProducerMatrix, state.payload);
    yield put(showActions.fetchProducerMatrix.success(response, state.payload));
  } catch (error) {
    yield put(showActions.fetchProducerMatrix.error(error.message));
  }
}

function* fetchPayRollUser(state: { type: string; payload: any }) {
  try {
    yield put(showActions.fetchPayRollUser.pending);
    const response: ApiResponse = yield call(showApi.fetchPayRollUser, state.payload);
    yield put(showActions.fetchPayRollUser.success(response, state.payload));
  } catch (error) {
    yield put(showActions.fetchPayRollUser.error(error.message));
  }
}

function* fetchBookingStats(state: { type: string; payload: IMatrixParams }) {
  try {
    yield put(showActions.fetchBookingStats.pending);
    const response: any = yield call(showApi.fetchBookingStats, state.payload);
    yield put(showActions.fetchBookingStats.success(response, state.payload));
  } catch (error) {
    yield put(showActions.fetchBookingStats.error(error.message));
  }
}

function* fetchShowUserStats(state: { type: string; payload: IMatrixParams }) {
  try {
    yield put(showActions.fetchShowUserStats.pending);
    const response: any = yield call(showApi.fetchShowStats, state.payload);
    yield put(showActions.fetchShowUserStats.success(response, state.payload));
  } catch (error) {
    yield put(showActions.fetchShowUserStats.error(error.message));
  }
}

function* changeUserRole(state: { type: string; payload: IChangeRoleParams }) {
  try {
    yield put(showActions.changeUserRole.pending);
    yield call(showApi.changeUserRole, state.payload);
    yield put(showActions.changeUserRole.success(state.payload));
  } catch (error) {
    yield put(showActions.changeUserRole.error(error.message));
  }
}

function* copyMatrixItem(state: { type: string; payload: ICopyMatrixItem }) {
  try {
    yield put(showActions.copyMatrixItem.pending);
    const response: ICopyMatrixItemResponse = yield call(showApi.copyMatrixItem, state.payload);
    yield put(showActions.copyMatrixItem.success(response));
  } catch (error) {
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.MATRIX_DATA_UPDATE_FAIL,
        title: 'Fail to update the data on server',
        type: TOAST_TYPE.ERROR,
      }),
    );

    yield put(showActions.copyMatrixItem.error(error.message));
  }
}

function* cancelBookingRequest(state: { type: string; payload: ICancelRequestParams }) {
  try {
    yield put(showActions.processJobRequest.pending(state.payload));
    const bookingResponse: any = yield call(jobApi.cancelBookingByDirector, state.payload);
    yield (bookingResponse.jobId = bookingResponse.jobId.id);
    yield put(showActions.processJobRequest.success(state.payload, JOB_STATUS_CANCELLED));
    yield put(myPerformerActions.fetchPeformerUpdatedData({ performerId: state.payload.userId, limit: 1, page: 1 }));
  } catch (error) {
    yield put(showActions.processJobRequest.error(error.message));
  }
}

function* acceptBookingRequest(state: { type: string; payload: ICancelRequestParams }) {
  try {
    yield put(showActions.processJobRequest.pending(state.payload));
    const bookingResponse: any = yield call(jobApi.acceptBookingByDirector, state.payload);
    yield (bookingResponse.jobId = bookingResponse.jobId.id);
    yield put(showActions.processJobRequest.success(state.payload, JOB_STATUS_BOOKED));
    yield put(myPerformerActions.fetchPeformerUpdatedData({ performerId: state.payload.userId, limit: 1, page: 1 }));
  } catch (error) {
    yield put(showActions.processJobRequest.error(error.message));
  }
}
function* fetchDailyCosts(state: { type: string; payload: IDailyCost }) {
  try {
    yield put(showActions.fetchDailyCosts.pending);
    const response: ApiResponse = yield call(showApi.fetchDailyCosts, state.payload);
    if (response) yield put(showActions.fetchDailyCosts.success(response));
  } catch (error) {
    yield put(showActions.fetchDailyCosts.error(error.message));
  }
}
function* setMatrixUpdateTiming(state: { type: String; payload: IUpdateMatrixTime[] }) {
  try {
    const response: any = yield call(showApi.updateMatrixTiming, { data: state.payload });
    if (response) yield put(showActions.updateMatrixData(response.result));
  } catch (error: any) {
    yield console.log(error.message);
  }
}
function* setMatrixUpdateItem(state: { type: String; payload: IUpdateMatrixItem[] }) {
  try {
    const response: any = yield call(showApi.updateMatrixItem, { data: state.payload });
    if (response) yield put(showActions.updateMatrixData(response.result));
  } catch (error: any) {
    yield console.log(error.message);
  }
}
function* changeMatrixAtoms(state: { type: String; payload: IChangeAtoms; workingId: string }) {
  try {
    const response: any = yield call(showApi.changeMatrixAtoms, state.payload);
    if (response.result) yield put(showActions.changeMatrixAtoms(state.payload, state.workingId));
  } catch (error: any) {
    yield console.log(error.message);
  }
}
function* sendCallTimeEmail(state: { type: string; payload: ISetMatrixEmailTemplate }) {
  try {
    yield put(showActions.sendCallSheet.pending(state.payload));
    const response: any = yield call(showApi.sendMatrixEmail, state.payload);
    if (response) {
      yield put(showActions.sendCallSheet.success(state.payload));
      yield put(
        generalActions.showToast({
          id: TOAST_IDS.CALL_SHEET_SEND_SUCCESS,
          title: 'Email send successfully',
          type: TOAST_TYPE.SUCCESS,
        }),
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.CALL_SHEEET_SEND_ERROR,
        title: error.message || 'Fail to send message',
        type: TOAST_TYPE.ERROR,
      }),
    );

    yield put(showActions.sendCallSheet.error(error.message));
  }
}
function* uploadShowImage(state: { type: string; payload: IProfileFileParam }) {
  try {
    yield put(showActions.showImageUpload.pending);
    const response: string = yield call(showApi.uploadShowImage, state.payload);
    yield put(showActions.showImageUpload.success(response));
  } catch (error) {
    yield put(showActions.showImageUpload.error(error.message || 'Please retry'));
  }
}

function* coordinatorInvitation(state: { type: string; payload: IBGCInvitation }) {
  try {
    yield put(showActions.showCoordinatorInvitation.pending);
    const response: string[] = yield call(showApi.coordinatorInvitation, state.payload);
    yield put(showActions.showCoordinatorInvitation.success(response, state.payload));
  } catch (error: any) {
    yield put(showActions.showCoordinatorInvitation.error(error.message || 'Please retry'));
  }
}

function* sendBgSizeCard(state: { type: string; payload: IBgSizeCardParams }) {
  try {
    yield put(showActions.sendBgSizeCard.pending);
    const response: any = yield call(showApi.sendBgSizeCard, state.payload);
    if (!response.result) {
      const anchorTag = document.createElement('a');
      anchorTag.href = response;
      anchorTag.download = 'SizeCard.zip';
      anchorTag.click();
    }
    yield put(showActions.sendBgSizeCard.success('ok'));
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.BG_SIZE_CARD_SUCCESS,
        title: `Size Card ${state.payload.type === 'download' ? 'Downloaded' : 'Shared On Email'}`,
        type: TOAST_TYPE.SUCCESS,
      }),
    );
  } catch (error: any) {
    console.log('err', error);
    yield put(showActions.sendBgSizeCard.error(error.message || 'Please retry'));
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.BG_SIZE_CARD_ERROR,
        title: `Size Card Can't ${state.payload.type === 'download' ? 'Download' : 'Share'}, Try Again!`,
        type: TOAST_TYPE.ERROR,
      }),
    );
  }
}

function* matrixBreakdown(state: { type: string; payload: IBreakdownParams }) {
  try {
    yield put(showActions.sendBreakdownEmail.pending);
    console.log('state.payload22', state.payload);

    const response: any = yield call(showApi.matrixBreakdown, state.payload);
    console.log('response333', response);

    if (response?.message) {
      console.log('before success');

      yield put(showActions.sendBreakdownEmail.success('ok'));
      yield put(
        generalActions.showToast({
          id: TOAST_IDS.BREAKDOWN_MATRIX_SUCCESS,
          title: 'Breakdown Shared On Email',
          type: TOAST_TYPE.SUCCESS,
        }),
      );
    }
  } catch (error: any) {
    console.log('err', error);
    yield put(showActions.sendBreakdownEmail.error(error.message || 'Please retry'));
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.BREAKDOWN_MATRIX_ERROR,
        title: "Breakdown Can't Share, Try Again!",
        type: TOAST_TYPE.ERROR,
      }),
    );
  }
}

function* performerRateList() {
  try {
    yield put(showActions.getRateList.pending);
    const response: RateList = yield call(showApi.fetchRateListing);
    yield put(showActions.getRateList.success(response));
  } catch (error) {
    yield put(showActions.getRateList.error(error.message));
  }
}

function* performerCustomRateList() {
  try {
    yield put(showActions.getCustomRateList.pending);
    const response: RateList = yield call(showApi.fetchCustomRateListing);
    yield put(showActions.getCustomRateList.success(response));
  } catch (error) {
    yield put(showActions.getCustomRateList.error(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.FETCH_SHOW_LISTING_BEGIN, fetchShowListing),
    takeEvery(ACTIONS.CREATE_SHOW_BEGIN, createShow),
    takeEvery(ACTIONS.UPDATE_SHOW_BEGIN, updateShow),
    takeEvery(ACTIONS.SHOW_ACCESS_REQUEST_BEGIN, showAccess),
    takeEvery(ACTIONS.CREATE_JOB_BEGIN, createShowRole),
    takeEvery(ACTIONS.CLOSE_DELETE_JOB_BEGIN, closeOrDeleteShowJob),
    takeLatest(ACTIONS.SET_SHOW_SELECTED_DATE_BEGIN, fetchDayJobs),
    takeEvery(ACTIONS.SET_PERFORMER_SEARCH_BEGIN, searcPerformerForJob),
    takeEvery(ACTIONS.EMAIL_TEMPLATE_BEGIN, fetchEmailTemplates),
    takeLatest(ACTIONS.FETCH_SHOW_DETAIL_BEGIN, fetchShowDetails),
    takeEvery(ACTIONS.SAVE_MATRIX_EMAIL_TEMPLATE_BEGIN, saveEmailTemplate),
    takeEvery(ACTIONS.MATRIX_DATA_BEGIN, fetchMatrixData),
    takeEvery(ACTIONS.MATRIX_PERFORMER_DATA_BEGIN, fetchMatrixPerformer),
    takeEvery(ACTIONS.FETCH_SHOW_HISTORY_BEGIN, fetchShowHistory),
    takeLatest(ACTIONS.FETCH_ERROR_NOTES_BEGIN, fetchErrorNotes),
    takeEvery(ACTIONS.FETCH_SHOW_COST_BUDGET_BEGIN, fetchCostBudgetData),
    takeEvery(ACTIONS.MATRIX_NEW_ATOMS_BEGIN, saveAtoms),
    takeEvery(ACTIONS.MATRIX_ADD_COORDINATOR_BEGIN, processCoordinator),
    takeEvery(ACTIONS.UPLOAD_CALL_SHEET_BEGIN, uploadCallSheetFile),
    takeEvery(ACTIONS.REMOVE_CALL_SHEET_BEGIN, removeCallSheetFile),
    takeEvery(ACTIONS.UDPATE_MATRIX_STATUS_BEGIN, updateMatrixStatus),
    takeEvery(ACTIONS.GET_MATRIX_USERS_BEGIN, fetchShowUser),
    takeEvery(ACTIONS.GET_PRODUCER_MATRIX_BEGIN, fetchProducerMatrix),
    takeEvery(ACTIONS.GET_PAYROLL_USER_BEGIN, fetchPayRollUser),
    takeEvery(ACTIONS.GET_BOOKING_STATS_BEGIN, fetchBookingStats),
    takeEvery(ACTIONS.GET_DAILY_COST_BEGIN, fetchDailyCosts),
    takeEvery(ACTIONS.GET_SHOW_USER_STATS_BEGIN, fetchShowUserStats),
    takeEvery(ACTIONS.CHANGE_USER_ROLE_BEGIN, changeUserRole),
    takeEvery(ACTIONS.MATRIX_COPY_ITEM_BEGIN, copyMatrixItem),
    takeEvery(ACTIONS.ACCEPT_JOB_BOOKING_PROCESS_BEGIN, acceptBookingRequest),
    takeEvery(ACTIONS.CANCEL_JOB_BOOKING_PROCESS_BEGIN, cancelBookingRequest),
    takeEvery(ACTIONS.MATRIX_UPDATE_TIMING_CALL, setMatrixUpdateTiming),
    takeEvery(ACTIONS.MATRIX_UPDATE_ITEM_CALL, setMatrixUpdateItem),
    takeEvery(ACTIONS.CHANGE_MATRIX_ATOMS_CALL, changeMatrixAtoms),
    takeEvery(ACTIONS.SEND_CALL_SHEET_EMAIL_BEGIN, sendCallTimeEmail),
    takeEvery(ACTIONS.SHOW_IMAGE_UPLOAD_BEING, uploadShowImage),
    takeEvery(ACTIONS.SHOW_COORDINATOR_INVITATION_BEGIN, coordinatorInvitation),
    takeLatest(ACTIONS.SEND_BG_SIZE_CARD_BEGIN, sendBgSizeCard),
    takeLatest(ACTIONS.SEND_BREAKDOWN_BEGIN, matrixBreakdown),
    takeLatest(ACTIONS.GET_PERFORMER_RATE_BEGIN, performerRateList),
    takeLatest(ACTIONS.GET_PERFORMER_CUSTOM_RATE_BEGIN, performerCustomRateList),
    takeLatest(ACTIONS.FETCH_RECENT_ACTIONS_BEGIN, fetchRecentActions),
    takeLatest(ACTIONS.FETCH_NOTES_REVIEW_BEGIN, fetchNotesReview),
    takeLatest(ACTIONS.ADD_CUSTOM_RATE_BEGIN, saveCustomRate),
    takeLatest(ACTIONS.SHARE_CUSTOM_RATE_BEGIN, shareCustomRate),
    takeLatest(ACTIONS.BREAKDOWN_BEGIN, breakdown),
  ]);
}
