import { connect } from 'react-redux';
// import SideRateOption from 'components/RateManagement/components/rates/SideRateOption';
import { getRateListRequest, addCustomRateRequest, getCustomRateListRequest } from 'store/actions/show.actions';
import { getPerformerRateList, getCustomPerformerRateList } from 'store/selector/show.selector';
import SideRateOption from './SideRateOption';

const mapDispatchToProps = {
  getRateListRequest,
  addCustomRates: addCustomRateRequest,
  getCustomRateList: getCustomRateListRequest,
};

const mapStateToProps = (state: any) => {
  const allRates = getPerformerRateList(state);
  const customRates = getCustomPerformerRateList(state);

  return {
    allRates,
    customRates,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideRateOption);
