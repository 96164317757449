import React, { FunctionComponent, useEffect, useState } from 'react';
import { User } from 'modules/user/types';
import Style from 'views/bgperformer/profile/Profile.module.css';
import TopNavBar from 'components/TopNavBar/TopNavBar';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';
import { IPerformerDetail } from 'modules/params/param.type';
import { ProfileKey } from 'store/castingPax.constants';
import ApiResponse from 'modules/response/response.types';
import api from 'store/services/myPerformer.services';
import BasicInfoView from 'views/profileMenu/profile/basicInfo/BasicInfoView';

const ProfileView: FunctionComponent = () => {
  const [userObj, setUserObj] = useState<User>(getItemFromLocalStorage(ProfileKey));

  useEffect(() => {
    const params: IPerformerDetail = { userId: userObj.id };
    try {
      api.fetchPerformerDetail(params).then((res: ApiResponse) => {
        setUserObj(res.data);
      });
    } catch (e) {
      console.log('Error', e.message);
    }
  }, []);

  return (
    <>
      <TopNavBar />
      <div className={`${Style.MainWrapper}`}>
        <div className={`${Style.BasicInfoWrapper}`}>
          <BasicInfoView profile={userObj} />
        </div>
      </div>
    </>
  );
};

export default ProfileView;
