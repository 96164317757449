import React from 'react';
import Styled from 'styled-components';
import AttachedFileIcon from './AttachedFileIcon';
import { AttachedFileData } from './performanceData';

const AttachedFile = () => (
  <AttachedFileWrapper>
    <AttachedFilesContainer>
      <AttachedFileHeaderContainer>
        <AttachedFileHeader>Attached Files</AttachedFileHeader>
      </AttachedFileHeaderContainer>
      <IconContainer>
        {AttachedFileData.map((item, index) => (
          <AttachedFileIcon background={item.background} number={index + 1} color={item.color} />
        ))}
      </IconContainer>
    </AttachedFilesContainer>
  </AttachedFileWrapper>
);

const AttachedFilesContainer = Styled.div`
background: #fff;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 10px;
height:150px;
`;

const AttachedFileWrapper = Styled.div`
  padding-top: 40px;
`;

const AttachedFileHeaderContainer = Styled.div`
height: 60px;
border-bottom: 1px solid #F2F2F2;
`;

const AttachedFileHeader = Styled.p`
height: 100%;
padding:25px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
`;

const IconContainer = Styled.div`
display: flex;
height: 50%;
padding: 20px;
width: 100%;
align-items: center;
`;

export default AttachedFile;
