/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useState, useRef, useMemo, useEffect } from 'react';
import Styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SendNoteIcon from 'assets/images/sendNote.svg';
import { ChartGroup } from 'components/Payroll/common/chartGroup/components';
import productionData from 'components/Payroll/common/chartGroup/data/productionData.data';
// import moment from 'moment';
import html2canvas from 'html2canvas';
import Jspdf from 'jspdf';
import { MatrixChartData, MatrixChartStats, Show } from 'modules/Show/show.types';
import Style from 'components/Producer/Matrix/Budget/CreateBudget.module.css';
import GreenIndicatorComponent from '../Common/GreenIndicatorComponent';
import RedIndicatorComponent from '../Common/RedIndicatorComponent';
// import BudgetChartComponent from './BudgetChartComponent';
// import ExportControl from '../Common/controls/export/Export.control';
// import PrintControl from '../Common/controls/print/Print.control';
// import EditControl from '../Common/controls/edit/Edit.control';
// import AddControl from '../Common/controls/add/Add.control';
// import ControlsWrapper from '../Common/controls/ControlButtonsWrapper';
import { LOADER_STATUS } from '../../../../store/castingPax.constants';
// import { IDownloadInvoicesPdf } from '../../../../modules/params/param.type';
// import showApi from '../../../../store/services/show.services';
// import Chart from '../../../Producer/Matrix/Chart/Chart';
// import BudgetChartComponent from '../BudgetHistory/BudgetChartComponent';
import ControlsWrapper from '../Common/controls/ControlButtonsWrapper';
import ExportControl from '../Common/controls/export/Export.control';
import PrintControl from '../Common/controls/print/Print.control';
import AddControl from '../Common/controls/add/Add.control';
import EditControl from '../Common/controls/edit/Edit.control';

interface IndicatorProps {
  marginLeft?: string;
  chartData: any;
  selectedShow: Show;
  showCostBudget?: any;
}

const TotalCostComponent: FunctionComponent<IndicatorProps> = (props: IndicatorProps) => {
  const { marginLeft, chartData, selectedShow, showCostBudget } = props;
  // const [showSideNav, setShowSideNav] = useState(false);
  const printRef = useRef();
  // const handlePrint = () => window.print();
  // const [exportLoading, setExportLoading] = useState<number>(LOADER_STATUS.NA);
  // const [loaderType, setLoaderType] = useState<string>('');
  // const [chartType] = useState<string>('cost');
  const [show, setShow] = useState<Show>();
  useEffect(() => {
    console.log(show, chartData, marginLeft);
  }, [showCostBudget]);

  useEffect(() => {
    if (selectedShow) {
      setShow(selectedShow);
    }
  }, [selectedShow]);

  return (
    <TotalCostContainer>
      <HeaderContainer>
        <GreenIndicatorComponent text="Estimated Cost" />
        <RedIndicatorComponent marginLeft="10px" text="Total Cost" />
      </HeaderContainer>
      <ChartContainer>
        <ChartHeader>
          <SendLogo src={SendNoteIcon} />
          <ChartHeaderText>Total Cost Vs Estimated Cost</ChartHeaderText>
        </ChartHeader>
        {/* <BudgetChartComponent /> */}
        {/* <Chart */}
        {/* //   printRef={printRef}
        //   // data={productionData.slice(0, 7)}
        //   data={bookingChartData}
        //   handleAdd={handleAddBudget}
        //   handleEdit={handleEditBudget}
        //   handleExportDownload={handleDownload}
        //   handlePrint={handlePrint}
        //   exportLoading={exportLoading}
        //   loaderType={loaderType}
        //   type="cost"
        // /> */}
        <ControlsWrapper>
          <div className={`p-2 ${Style.budgetWrapper}`}>
            <ExportControl printRef={printRef} types="cost" />
            <PrintControl />
            <AddControl />
            <EditControl />
          </div>
        </ControlsWrapper>
        <ChartGroup enableTextItem={false} chartData={showCostBudget} printRef={printRef} />
      </ChartContainer>
    </TotalCostContainer>
  );
};

const TotalCostContainer = Styled.div`
display: flex;
width: 33.33%;
border-radius: 5px;
flex-direction: column;
justify-content: space-between;
margin-left: 25px;
@media only screen and (max-width: 991px) {
  width: 100%;
  margin-left: 0;
  margin-top: 15px;
  max-height: 510px;
}
`;

const HeaderContainer = Styled.div`
display: flex;
width: 100%;
align-items: center;
border-radius: 12px;
margin-bottom: 5px;
justify-content: space-around;
padding-bottom: 20px;
`;

const InBudgetContainer = Styled.div`
display: flex;
justify-content: center;
width: 100%;
height: 100%;
margin-left: 0px;
font-size: 12px;
font-weight: 500;
font-family: 'Montserrat';
&:before {
    content: ' ';
    height: 10px;
    width: 10px;
    background: #49D360;
    margin-top:3px;
    margin-right:10px;
    border-radius: 100%;
}
`;

const PastBudgetContainer = Styled.div`
display: flex;
justify-content: center;
width: 100%;
height: 100%;
margin-left: 0px;
font-size: 12px;
font-weight: 500;
font-family: 'Montserrat';
&:before {
    content: ' ';
    height: 10px;
    width: 10px;
    background: #EB5757;
    margin-top:3px;
    margin-right:10px;
    border-radius: 100%;
}
`;

const ChartContainer = Styled.div`
width: 100%;
margin-top: -5px;
background: #fff;
border-radius: 10px;
flex: 1;
display: flex;
flex-direction: column;
justify-content: space-between;
/* align-tems: center; */
`;

const ChartHeader = Styled.div`
width: 100%;
display: flex !important;
flex-direction: row;
padding: 15px 0;
padding-left: 20px;
background: #fff;
border-bottom: 3px solid #F2F2F2;
border-top-left-radius: 12px;
border-top-right-radius: 12px;
align-items: center !important;
`;
const StyledCalender = Styled(FontAwesomeIcon)`
margin-top:6px;
color: #00a8ff;
`;

const ChartHeaderText = Styled.p`
  font-size: 14px;
  font-family: 'Montserrat';
  display: flex;
  margin: 0;
  padding-left: 10px;
  color: #333333;
`;

const SendLogo = Styled.img`
`;

const IndicatorImage = Styled.img`
width: 10px;
height: 10px;
margin-right: 10px;
margin-top: 3px;
`;

const IndicatorContainer = Styled.div`
display: flex;
font-size: 12px;
font-weight: 500;
font-family: 'Montserrat';
align-items: start;
height: 100%;
margin-left: ${(props: IndicatorProps) => (props.marginLeft ? props.marginLeft : '0px')};
`;

const IndicatorTextContainer = Styled.p`
`;

export default TotalCostComponent;
