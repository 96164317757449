import { PerformerList } from 'modules/PerformerList/list.types';
import { User } from 'modules/user/types';
import createSelector from 'utils/reselect';
import moment from 'moment-timezone';

const myPerformerState = (state: any) => {
  const { myPerformer } = state;
  return myPerformer || {};
};

export const getMyPerformerList = createSelector(
  myPerformerState,
  (myPerformer) => myPerformer.getIn(['performer', 'data']) || [],
);

export const getMyPeformerListOnly = createSelector(myPerformerState, (myPerformer) => {
  const list = myPerformer.getIn(['performer', 'data']) || [];
  const newList = list.map((item: PerformerList) => ({
    label: item.title,
    value: item.id,
    isChecked: false,
    ids: item.users?.map((p: User) => p.id),
  }));
  return newList;
});

export const getSelectedPerformer = createSelector(
  myPerformerState,
  (myPerformer) => myPerformer.get('selectedPerformer') || {},
);

export const getCalendarDates = createSelector(myPerformerState, () => {
  const date = moment();
  const arr: string[] = [];
  for (let i = 1; i < 31; i += 1) {
    arr.push(date.format('YYYY-MM-DD'));
    date.add(1, 'days');
  }
  return arr;
});

export const getPerformerNotes = createSelector(
  myPerformerState,
  (myPerformer) => myPerformer.getIn(['performerNotes', 'data']) || [],
);
