/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import UserAvatar from 'assets/images/exp1.png';
import BinIcon from 'assets/images/binIcon.svg';
import EditIcon from 'assets/images/editNoteIcon.svg';
import Modal from 'components/controls/Modal/Modal';
import Button from 'components/controls/button/button';
import { faker } from '@faker-js/faker';

interface NoteItemProps {
  type: string;
  description?: string;
  name?: string;
}

const ReviewNoteItem: FunctionComponent<NoteItemProps> = (props: NoteItemProps) => {
  const [itemData, setItemData] = useState(props);
  const [editActionModalVisible, setEditActionConfigVisibility] = useState<boolean>(false);
  const [deletActionModalVisible, setDeleteActionConfigVisibility] = useState<boolean>(false);
  return (
    <ItemContainer>
      <ContentContainer>
        <CircleIconContainer>
          <UserIcon src={UserAvatar} />
        </CircleIconContainer>
        <TextContainer>
          {props.name} -{props.description}
        </TextContainer>
        <UpdateTimeContainer>updated 13 mins ago </UpdateTimeContainer>
        {props.type === 'delete' ? (
          <ClickIcon
            onClick={() => !deletActionModalVisible && setDeleteActionConfigVisibility(!deletActionModalVisible)}
            src={BinIcon}
          />
        ) : (
          <ClickIcon
            onClick={() => !editActionModalVisible && setEditActionConfigVisibility(!editActionModalVisible)}
            src={EditIcon}
          />
        )}
      </ContentContainer>
      {editActionModalVisible && (
        <Modal
          title="Edit Recent Action"
          body={() => (
            <NewRateModalBody>
              <RateLabel>Edit Recent Action</RateLabel>
              <StyledInput
                value={props.description}
                onChange={(e) => setItemData({ ...itemData, description: e.target.value })}
              />
              <Button
                label="Save Edit"
                clickHandler={() => {
                  setEditActionConfigVisibility(!editActionModalVisible);
                }}
              />
            </NewRateModalBody>
          )}
          closeModal={() => setEditActionConfigVisibility(false)}
        />
      )}
      {deletActionModalVisible && (
        <Modal
          title="Delete Recent Action"
          body={() => (
            <NewRateModalBody>
              <RateLabel>Are you sure you want to delete this action?</RateLabel>
              <ButtonContainer>
                <Button label="Delete" type="primary" clickHandler={() => setDeleteActionConfigVisibility(false)} />
                <Button
                  label="Cancel"
                  type="secondary"
                  cssClass="ml-2"
                  clickHandler={() => setDeleteActionConfigVisibility(false)}
                />
              </ButtonContainer>
            </NewRateModalBody>
          )}
          closeModal={() => setDeleteActionConfigVisibility(false)}
        />
      )}
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  display: flex;
  width: 100;
  justify-content: space-between;
  border-bottom: 1px dashed #e0e0e0;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const NewRateModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;
export const RateLabel = styled.div`
  display: flex;
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #4b4d67;
`;

const TextContainer = styled.p`
  margin: 0;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding-left: 21px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat, sans-serif;
`;

const CircleIconContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
`;

const ClickIcon = styled.img`
  height: 35px;
  width: 35px;
`;

const UserIcon = styled.img`
  width: 100%;
  height: auto;
`;

const UpdateTimeContainer = styled.p`
  margin: 0;
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  font-size: 11px;
  width: 50%;
  color: #bdbdbd;
  font-family: Montserrat, sans-serif;
`;
const ContentContainer = styled.div`
  display: flex;
  width: 100%;
`;
export const StyledInput = styled.textarea`
  border: 1px solid #f2f2f2;
  flex: 1;
  border-radius: 8px;
  height: 15s0px;
  padding: 10px;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 30px;
`;

export default ReviewNoteItem;
