import {
  IProfileFileParam,
  ISaveCardParams,
  IUpdateSuscription,
  INotificationSetting,
  IBusinessInfo,
} from 'modules/params/param.type';
import { User, LOGIN_PARAMS, SignupProfile, RentalImage } from 'modules/user/types';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const loginAttempt = {
  pending: {
    type: ACTIONS.LOGIN.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.LOGIN.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.LOGIN.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const logoutSucceed = () => ({
  type: ACTIONS.AUTH_LOGOUT,
});

export const callLogout = () => ({
  type: ACTIONS.AUTH_LOGOUT_BEGIN,
});

export const authenticatedUser = (state: User) => ({
  type: ACTIONS.AUTHENTICATED_USER,
  data: state,
});

export const getLoginRequest = (state: LOGIN_PARAMS) => ({
  type: ACTIONS.LOGIN_CALL,
  payload: state,
});

export const registerUser = {
  pending: {
    type: ACTIONS.SIGNUP.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User, step: number) => ({
    type: ACTIONS.SIGNUP.SUCCESS,
    data,
    step,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SIGNUP.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const profileFileUpload = {
  pending: (data?: any) => ({
    type: ACTIONS.PROFILE_FILE_UPLOAD.PENDING,
    status: FETCH_STATUS.LOADING,
    data,
  }),
  success: (data: User, payload: IProfileFileParam) => ({
    type: ACTIONS.PROFILE_FILE_UPLOAD.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.PROFILE_FILE_UPLOAD.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const signUpComplete = (userType: string) => ({
  type: ACTIONS.PEROFILE_UPDATE,
  data: userType,
});

export const userRegistrationRequest = (state: User) => ({
  type: ACTIONS.REGISTER_NEW_USER,
  payload: state,
});

export const getSignupProfileRequest = (state: SignupProfile) => ({
  type: ACTIONS.SIGNUP_STEP_TWO,
  payload: state,
});

export const setSelectedTab = (state: string) => ({
  type: ACTIONS.CHANGE_SELECTED_TAB,
  data: state,
});

export const resetErrorMesage = () => ({
  type: ACTIONS.RESET_ERROR_MESSAGE,
});

export const uploadProfileImageBegin = (state: IProfileFileParam) => ({
  type: ACTIONS.PROFILE_FILE_UPLOAD_BEGIN,
  payload: state,
});

export const updatePerformerData = (state: User) => ({
  type: ACTIONS.UPDATE_PERFORMER_DATA,
  data: state,
});
export const uploadRentalImage = (data: any) => ({
  type: ACTIONS.UPDATE_PERFORMER_RENTAL_IMAGES,
  data,
});
export const removeRentalImage = (data: string) => ({
  type: ACTIONS.REMOVE_PERFORMER_RENTAL_IMAGES,
  data,
});
export const updateRentalType = (data: RentalImage, payload: string) => ({
  type: ACTIONS.UPDATE_PERFORMER_RENTAL_TYPE,
  data,
  payload,
});

export const updateProfile = {
  pending: {
    type: ACTIONS.PROFILE_UPADTE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.PROFILE_UPADTE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.PROFILE_UPADTE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateProfileBegin = (payload: User) => ({
  type: ACTIONS.PROFILE_UPDATE_BEGIN,
  payload,
});

export const updateSubscription = {
  pending: {
    type: ACTIONS.UPDATE_SUBSCRIPTION_PLAN.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.UPDATE_SUBSCRIPTION_PLAN.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPDATE_SUBSCRIPTION_PLAN.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateSubscriptionBegin = (payload: IUpdateSuscription) => ({
  type: ACTIONS.UPDATE_SUBSCRIPTION_PLAN_BEGIN,
  payload,
});

export const getCardDetails = {
  pending: {
    type: ACTIONS.GET_CARD_DETAILS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.GET_CARD_DETAILS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.GET_CARD_DETAILS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCardDetailsBegin = () => ({
  type: ACTIONS.GET_CARD_DETAILS_BEGIN,
  payload: {},
});

export const saveCardData = {
  pending: {
    type: ACTIONS.SAVE_CARD_DATA.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ISaveCardParams) => ({
    type: ACTIONS.SAVE_CARD_DATA.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SAVE_CARD_DATA.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const saveCardDataBegin = (payload: ISaveCardParams) => ({
  type: ACTIONS.SAVE_CARD_DATA_BEGIN,
  payload,
});

export const clearCardError = () => ({
  type: ACTIONS.CLEAR_CARD_DATA_ERROR,
  data: {},
});

export const setNotificationPrivacy = (payload: INotificationSetting) => ({
  type: ACTIONS.SET_NOTIFICATION_FOR_SETTING,
  payload,
});

export const setNotificationPrivacyStatus = {
  pending: {
    type: ACTIONS.NOTIFICATION_PRIVACY_STATUS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.NOTIFICATION_PRIVACY_STATUS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.NOTIFICATION_PRIVACY_STATUS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const deleteAccountBegin = (payload: any) => ({
  type: ACTIONS.DELETE_ACCOUNT_BEGIN,
  payload,
});

export const deleteAccount = {
  pending: {
    type: ACTIONS.DELETE_ACCOUNT.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.DELETE_ACCOUNT.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.DELETE_ACCOUNT.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const subscriptionHistoryBegin = (payload: { page: number; limit: number }) => ({
  type: ACTIONS.SUBSCRIPTION_HISTORY_BEGIN,
  payload,
});

export const subscriptionHistory = {
  pending: {
    type: ACTIONS.SUBSCRIPTION_HISTORY.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.SUBSCRIPTION_HISTORY.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SUBSCRIPTION_HISTORY.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const stripeLinkedAccountBegin = () => ({
  type: ACTIONS.STRIPE_LINKED_ACCOUNT_BEGIN,
});

export const stripeLinkedAccount = {
  pending: {
    type: ACTIONS.STRIPE_LINKED_ACCOUNT.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.STRIPE_LINKED_ACCOUNT.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.STRIPE_LINKED_ACCOUNT.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateBusinessInfoBegin = (payload: IBusinessInfo) => ({
  type: ACTIONS.UPDATE_BUSINESS_INFO_BEGIN,
  payload,
});

export const updateBusinessInfo = {
  pending: {
    type: ACTIONS.UPDATE_BUSINESS_INFO.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.UPDATE_BUSINESS_INFO.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPDATE_BUSINESS_INFO.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
