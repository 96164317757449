import React, { FunctionComponent } from 'react';
import Style from 'components/castingDirector/PopOvers/CoordinatorDetail/Coordinator.module.css';
import removeSvg from 'assets/svg/remove-2.svg';

interface InvitationItemProps {
  item: string;
  handleClick: Function;
}
const InvitationItem: FunctionComponent<InvitationItemProps> = (props: InvitationItemProps) => {
  const { item, handleClick } = props;
  const clickHandler = () => {
    handleClick(item);
  };
  return (
    <div className={`d-flex justify-content-between align-items-center ${Style.searchListWrapper} ${Style.selected}`}>
      <div className="d-flex">
        {/* <img src={getImageThumbnail(item.profilePicUrl)} className={Style.profileImage} alt="" /> */}
        <p className={`mb-0 ${Style.profileName}`}>{item}</p>
      </div>
      <div onClick={clickHandler} onKeyUp={clickHandler} tabIndex={0} role="button">
        <img src={removeSvg} alt="" className={`mb-0 ${Style.iconImage}`} />
      </div>
    </div>
  );
};

export default InvitationItem;
