import React, { FunctionComponent } from 'react';
import Style from 'components/TopNavBar/TopNavBar.module.css';
import calendarSvg from 'assets/svg/calendar.svg';
import prelimCallSvg from 'assets/svg/mobile-orange.svg';
import finalCallSvg from 'assets/svg/mobile.svg';
import settingBlueSvg from 'assets/svg/setting-blue.svg';
import notificationSvg from 'assets/svg/Notification.svg';
import squareCloseSvg from 'assets/svg/square-close.svg';
import squareCheckSvg from 'assets/svg/square-check.svg';
import squareCheckOrangeSvg from 'assets/svg/square-check-orange.svg';
import { Notification } from 'modules/general/general.type';

interface NotificationViewProps {
  notification: Notification;
  handleNotificationView: Function;
}

const NotificationViewed: FunctionComponent<NotificationViewProps> = (props: NotificationViewProps) => {
  const { notification, handleNotificationView } = props;

  const settingIcon = (type: any) => {
    switch (type) {
      case 'calender':
        return calendarSvg;
      case 'prelim':
        return prelimCallSvg;
      case 'final':
        return finalCallSvg;
      case 'setting':
        return settingBlueSvg;
      case 'update':
        return notificationSvg;
      case 'jobCancel':
      case 'denied':
        return squareCloseSvg;
      case 'jobUpdate':
      case 'jobAccept':
      case 'accepted':
        return squareCheckSvg;
      case 'jobRequest':
      case 'pending':
        return squareCheckOrangeSvg;
      default:
        return settingBlueSvg;
    }
  };

  const handleClick = () => {
    handleNotificationView(notification);
  };

  return (
    <>
      <div
        onClick={handleClick}
        onKeyPress={handleClick}
        tabIndex={0}
        role="button"
        className={`d-flex align-items-center ${Style.notificationItemWrapper} ${
          notification.isSeen ? '' : Style.notificationUnRead
        } `}
      >
        <img src={settingIcon(`${notification.type}`)} alt="cog" />
        <div className={`${Style.notificationContentWrapper}`}>
          <p className={`mb-0 ${Style.notificationTitle}`}>{notification.title}</p>
          <p className={`mb-0 ${Style.notificationDesc}`}>{notification.message}</p>
        </div>
      </div>
    </>
  );
};

export default NotificationViewed;
