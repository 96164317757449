import React, { FunctionComponent } from 'react';
import { User } from 'modules/user/types';
import PerformerFullProfile from 'components/PerformerProfile/PerformerFullProfile';
import Style from 'components/MyPerformerList/PerformerView/PerformerView.module.css';

interface PerformerViewProps {
  performer: User;
  setPerformerForView: Function;
}
const PerformerView: FunctionComponent<PerformerViewProps> = (props) => {
  const { performer, setPerformerForView } = props;
  const handleCloseClick = (e: React.FormEvent<HTMLDivElement>) => {
    if (e.currentTarget.id === 'bgcontainerdiv') setPerformerForView({});
  };
  const handleClick = (e: React.FormEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <div
      id="bgcontainerdiv"
      className={Style.bgLayer}
      role="button"
      tabIndex={0}
      onClick={handleCloseClick}
      onKeyPress={handleCloseClick}
    >
      <div className={Style.openModalProfile} role="button" tabIndex={0} onKeyPress={handleClick} onClick={handleClick}>
        <PerformerFullProfile profile={performer} selectedJobDates={[]} />
      </div>
    </div>
  );
};

export default PerformerView;
