import React, { FunctionComponent, useState } from 'react';
import roleSvg from 'assets/svg/profile.svg';
import UserInviteStyle from 'components/ProfileMenu/Settings/UserInvite/UserInvite.module.css';
import Modal from 'components/controls/Modal/Modal';
import Button from 'components/controls/button/button';
import InviteUserForm from './inviteUserForm';

const UserInvite: FunctionComponent = () => {
  const [openInviteModal, setOpenInviteModal] = useState(false);

  const handleClose = () => {
    setOpenInviteModal(false);
  };
  const clickInviteModal = () => {
    setOpenInviteModal(true);
  };

  const paymentMethodBody = () => <InviteUserForm handleClose={handleClose} />;

  return (
    <>
      <div className={`pt-40  ${UserInviteStyle.inviteWrapper}`}>
        <div className={`${UserInviteStyle.inviteUserWrapper}`}>
          <p className={`mb-0 ${UserInviteStyle.inviteUserHeader}`}>Users</p>
          <Button label="Invite User" clickHandler={clickInviteModal} cssClass={UserInviteStyle.addMethod} />
        </div>
        <p className={`mb-0 ${UserInviteStyle.inviteDetailDescription}`}> Invite payroll users</p>
      </div>
      <div className={UserInviteStyle.roleDetailWrapper}>
        {openInviteModal && (
          <Modal
            title="Invite Payroll User"
            body={paymentMethodBody}
            closeModal={() => handleClose()}
            icon={roleSvg}
            cssClass=""
          />
        )}
      </div>
    </>
  );
};

export default UserInvite;
