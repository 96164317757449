import React, { useState } from 'react';
import styled from 'styled-components';

interface Props {
  label: string;
  handleFilter: Function;
}

const CheckBoxItem: React.FC<Props> = (props) => {
  const { label, handleFilter } = props;
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const toggleCheck = () => setIsChecked(!isChecked);
  const handleClick = (e: any) => {
    handleFilter(e.target.checked);
  };
  return (
    <CustomItemContainer onClick={toggleCheck}>
      <input type="checkbox" checked={isChecked} onClick={handleClick} onKeyPress={handleClick} />
      <Text>{label}</Text>
    </CustomItemContainer>
  );
};

export default CheckBoxItem;

const Text = styled.p`
  color: #4b4d67;
  margin: 0;
  margin-left: 8px;
`;

const CustomItemContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  margin: 0;
  height: 20px;
  border-radius: 0;
  display: flex;
  border-bottom: none;
  align-items: center;
  overflow-y: hidden;
  gap: 0;
  z-index: 5;
  cursor: pointer;
`;
