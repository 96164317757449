import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import Style from 'components/Producer/Matrix/Budget/CreateBudget.module.css';
import Button from 'components/controls/button/button';
import saveSvg from 'assets/svg/save.svg';
import Dropdown from 'components/controls/select/DropDownSearch/index';
import Input from 'components/controls/input/dashboardInput/dashboardInput';
import {
  Episode,
  EpisodeDayList,
  EpisodeList,
  Show,
  ShowList,
  ShowTypes,
  ShowWorkingDay,
} from 'modules/Show/show.types';
import { connect } from 'react-redux';
import { OptionList, Options } from 'modules/general/general.type';
import showApi from 'store/services/show.services';
import PopoverHover from 'components/controls/Popover/PopoverHover';
import moment from 'moment';
import JobDateSelection from 'components/ShowJob/JobDateSelection/JobDateSelection';
import Loader from 'components/controls/Loader/Loader';

interface CreateBudgetProps {
  closeModal: Function;
  currentShowId: string;
}

const CreateBudget: FunctionComponent<CreateBudgetProps> = (props: CreateBudgetProps) => {
  const { closeModal, currentShowId } = props;
  const [selectedShow, setSelectedShow] = useState<Options>();
  const [selectedEpisode, setSelectedEpisode] = useState<Options>();
  const [episodes, setEpisodes] = useState<OptionList>();
  const [selectedWD, setSelectedWD] = useState<EpisodeDayList>([]);
  const [amount, setAmount] = useState<string>('');
  const [allShows, setAllShows] = useState<ShowList>();
  const [loading, setLoading] = useState<boolean>(false);
  const mountedRef = useRef(true);

  const handleSave = () => {
    if (amount === '' || !selectedShow) return;
    const obj: any = {};
    if (selectedShow.type === ShowTypes.FILM && selectedWD.length > 0) {
      Object.assign(obj, { id: selectedWD[0].id, amount, type: 'day' });
    } else if (selectedShow.type === ShowTypes.SERIES && selectedEpisode) {
      Object.assign(obj, { id: selectedEpisode.id, amount, type: 'episode' });
    } else {
      Object.assign(obj, { id: selectedShow.id, amount, type: 'show' });
    }
    showApi.addBudget(obj).catch((err) => err);
    closeModal(false);
  };

  useEffect(() => {
    setLoading(true);
    showApi
      .getAllShows()
      .then((res: any) => {
        if (mountedRef.current) {
          setAllShows(res);
          const data: ShowList = res.filter((e: any) => e.id === currentShowId);
          if (data && data.length > 0 && data[0].showType === ShowTypes.SERIES) {
            const showObj: Show = data[0];
            const objEpisodes = showObj.episodes;
            if (objEpisodes) {
              setEpisodes(
                objEpisodes.map((r: Episode) => {
                  const obj: Options = {
                    id: r.id,
                    type: '',
                    label: r.title || '',
                    value: r.id,
                  };
                  return obj;
                }),
              );
            }
          }
          setSelectedShow({ label: data[0].title, value: data[0].id, type: data[0].showType, id: data[0].id });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    setSelectedWD([]);
    setEpisodes([]);
    if (selectedShow && allShows) {
      const data: ShowList = allShows.filter((e: any) => e.id === selectedShow.id);
      if (selectedShow.type === ShowTypes.SERIES && data && data.length > 0) {
        const showObj: Show = data[0];
        const objEpisodes = showObj.episodes;
        if (objEpisodes) {
          setEpisodes(
            objEpisodes.map((r: Episode) => {
              const obj: Options = {
                id: r.id,
                type: '',
                label: r.title || '',
                value: r.id,
              };
              return obj;
            }),
          );
        }
      }
    }
  }, [selectedShow]);

  useEffect(() => {
    if (selectedWD.length > 0) {
      setAmount(selectedWD[0].budget || '');
    } else {
      setAmount('');
    }
  }, [selectedWD]);

  const daysListing = useMemo(() => {
    if (!allShows || !selectedShow) {
      return [];
    }
    let currentDate: any = moment();
    currentDate = currentDate.format('YYYY-MM-DD 00:00:00');
    const index: number = allShows.findIndex((s: Show) => s.id === selectedShow.id);
    if (index !== -1) {
      const showSelected: Show = allShows[index];

      if (showSelected.showType === ShowTypes.SERIES && selectedEpisode && showSelected.episodes) {
        const episodeData: EpisodeList = showSelected.episodes.filter((e: Episode) => e.id === selectedEpisode.id);
        if (episodeData.length > 0) {
          const episodeDataObj: EpisodeDayList = episodeData[0].episodeDays.filter(
            (e: ShowWorkingDay) => e.date >= currentDate,
          );
          return episodeDataObj;
        }
      }
      if (showSelected.showType === ShowTypes.FILM && showSelected.showWorkingDays) {
        const showSelectedObj: EpisodeDayList = showSelected.showWorkingDays.filter(
          (e: ShowWorkingDay) => e.date >= currentDate,
        );
        return showSelectedObj;
      }
    }
    return [];
  }, [selectedEpisode, selectedShow]);

  const showSelectedDate = () => {
    if (selectedWD.length > 0) {
      return moment(selectedWD[0].date).format('DD-MM-YYYY');
    }
    return 'Select date';
  };

  const datePopupComponent = () => (
    <JobDateSelection list={daysListing} setWorkingDay={setSelectedWD} workingDays={selectedWD} singleSelection />
  );

  const filterDropDown = () => {
    if (selectedShow && selectedShow.type === ShowTypes.SERIES) {
      return (
        <>
          <Dropdown
            defaultValue="Episode"
            data={episodes}
            selectedObj={[selectedEpisode]}
            storeObject
            handleSelect={setSelectedEpisode}
          />
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      {loading ? (
        <Loader isSmallView />
      ) : (
        <div className={Style.addBudgetWrapper}>
          <div className="performerSearchWrapper">
            {filterDropDown()}
            <Input placeholder="Max Budget" type="number" value={amount} handleChange={setAmount} />
            <div>
              <PopoverHover component={datePopupComponent}>
                <div className="mr-4">{showSelectedDate() || 'Select Date'}</div>
              </PopoverHover>
            </div>
          </div>
          <div className="text-center">
            <Button clickHandler={handleSave} label="Save" icon={saveSvg} cssClass="fontFourteen font-weight-normal" />
          </div>
        </div>
      )}
    </>
  );
};

export default connect(null, null)(CreateBudget);
