import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { getpayRollUser } from 'store/selector/show.selector';
import { fetchPayRollUserBegin } from 'store/actions/show.actions';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from 'components/Payroll/VoucherManagement/voucher/utils/modal';
import EditButton from './EditButton';
import SaveButton from './SaveButton';
import {
  SearchResultsContainer,
  ResultItem,
  UserIcon,
  Avatar,
  UserTitle,
} from '../../../../VoucherManagement/filter-tab/SearchTab';

interface Props {
  fetchPayRollUsers?: any;
  payRollUsers?: any;
  onClickHandler?: any;
  editHandlers?: any;
  handleSave?: any;
  isEditAble: boolean;
}

const RateCardControls: React.FC<Props> = (props) => {
  const [toggleShare, setToggleShare] = useState<boolean>(false);
  const { fetchPayRollUsers, payRollUsers, onClickHandler, editHandlers, handleSave, isEditAble } = props;
  const [editAble] = useState(true);
  useEffect(() => {
    fetchPayRollUsers();
  }, []);
  useEffect(() => {}, [payRollUsers]);
  const editHandler = () => {
    editHandlers();
  };
  return (
    <StyledRateCard>
      <ShareContainer>
        <StyledShareButton onClick={() => setToggleShare(!toggleShare)}>
          <p>Share To Users</p>
          <div>{toggleShare ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}</div>
        </StyledShareButton>
        {toggleShare && (
          <UserDropDownContainer>
            <SearchResultsContainer visible={toggleShare}>
              {payRollUsers &&
                payRollUsers.map((user: any) => (
                  <ResultItem key={user.id} onClick={() => onClickHandler(user.id, 'userId')}>
                    <Avatar>
                      <UserIcon src={user.profilePicUrl} />
                    </Avatar>
                    <UserTitle>{`${user.firstName} ${user.lastName}`}</UserTitle>
                  </ResultItem>
                ))}
            </SearchResultsContainer>
          </UserDropDownContainer>
        )}
        {toggleShare && <Modal closeModal={() => setToggleShare(!toggleShare)} />}
      </ShareContainer>

      <DivEditWrapper
        isEditAble={isEditAble}
        onClick={() => editHandler()}
        role="button"
        tabIndex={0 as number}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            editHandler();
          }
        }}
      >
        <EditButton />
      </DivEditWrapper>
      <DivSaveWrapper
        isEditAble={isEditAble}
        onClick={() => editAble && handleSave()}
        role="button"
        tabIndex={0}
        onKeyPress={() => editAble && handleSave()}
      >
        <SaveButton />
      </DivSaveWrapper>
    </StyledRateCard>
  );
};

const StyledShareButton = styled.div`
  height: 50px;
  border-radius: 10px;
  padding: 10px 20px;
  padding-right: 23px;
  background: rgba(75, 77, 103, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  & > p {
    margin: 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #4b4d67;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 13px;
    min-width: 111px;
  }

  & > div {
    width: 10px;
    cursor: pointer;
  }
`;

const StyledRateCard = styled.div`
  display: flex;
  gap: 12px;
  background: transparent;
  @media only screen and (max-width: 1199px) {
    gap: 6px;
    padding-top: 20px;
  }
  @media only screen and (max-width: 991px) {
    justify-content: space-between;
  }
`;

const UserDropDownContainer = styled.div`
  display: flex;
  position: absolute;
  width: 300px;
  border-radius: 10px;
`;
const ShareContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const DivEditWrapper = styled.div<Props>`
  opacity: ${({ isEditAble }) => (isEditAble ? '1' : '0.5')};
`;
const DivSaveWrapper = styled.div<Props>`
  opacity: ${({ isEditAble }) => (isEditAble ? '1' : '0.5')};
`;

const mapStateToProps = (state: any) => {
  const payRollUsers: boolean = getpayRollUser(state);
  return { payRollUsers };
};

const mapDispatchToProps = {
  fetchPayRollUsers: fetchPayRollUserBegin,
};

export default connect(mapStateToProps, mapDispatchToProps)(RateCardControls);
