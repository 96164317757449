import { connect } from 'react-redux';
import { Options } from 'modules/general/general.type';
import { getSkillsData, getLocations, getOptions, getUnions, getRates } from 'store/selector/general.selector';
import dashboardSelect from 'components/controls/select/DropDownSearch/DropDownSearch';
import { getEmailTemplateAsOpt } from 'store/selector/show.selector';
import { rolesData } from 'helpers/data/SelectOptions';

const mapStateToProps = (state: Options) => {
  const optionData = getOptions(state);
  const locationData = getLocations(state);
  const unionData = getUnions(state);
  const skills = getSkillsData(state);
  const emailTemplateList = getEmailTemplateAsOpt(state);
  const rolesDataOptions = rolesData([]);
  const rateData = getRates(state);

  return { optionData, locationData, unionData, skills, emailTemplateList, rolesDataOptions, rateData };
};

export default connect(mapStateToProps, null)(dashboardSelect);
