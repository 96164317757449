import React, { useMemo, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { MatrixItemList, MatrixType, ShowTopCalendarTypeList } from 'modules/Show/show.types';
import FilterTabsWrapper from 'components/Payroll/VoucherManagement/voucher/FilterTabsWrapper';
import { Pagination } from 'modules/jobs/types';
import Style from 'components/castingDirector/Matrix.module.css';
import DateList from '../../date-box/DateLists';
import StyledEVoucher from '../../../styles/voucher/Voucher.styled';
import ShowInfo from '../../show/show-info/ShowInfo';
import { dateListGenerator } from '../../helpers';
import BreakDown from '../../BreakDown';

interface Props {
  showSideNav?: boolean;
  showCalendarDates: ShowTopCalendarTypeList;
  getMatrixDataBegin: Function;
  matrixData: MatrixItemList;
  pagination: Pagination;
  loading: boolean;
  showId: any;
  dates: any;
  sortMatrixData: Function;
  sortingOrder: number;
  searchExistingMatrix: Function;
  rateFilterExistingMatrix: Function;
  errorNotesFilterExistingMatrix: Function;
  hideFilterExistingMatrix: Function;
  fetchMatrixPerformer: Function;
  breakdownPdfSelector: Function;
}

const Voucher: React.FC<Props> = (props: Props) => {
  const {
    showSideNav,
    showCalendarDates,
    getMatrixDataBegin,
    showId,
    dates,
    matrixData,
    sortMatrixData,
    sortingOrder,
    searchExistingMatrix,
    rateFilterExistingMatrix,
    errorNotesFilterExistingMatrix,
    hideFilterExistingMatrix,
    pagination,
    loading,
    fetchMatrixPerformer,
    breakdownPdfSelector,
  } = props;
  const [render, setRender] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [breakdownItem, setBreakdownItem] = useState({});
  const [selectedShowDate, setSelectedShowDate] = useState('');
  const [isBreakdown, setIsBreakdown] = useState(false);
  const dateCalendarData = useMemo(() => {
    const dateData = dateListGenerator(0, new Date(), 360);
    const selectedDate = showCalendarDates.map((sd) => {
      const sDay = sd.dayTitle.split(' ');
      const date = {
        id: sd.id,
        month: sDay[0],
        day: parseInt(sDay[1], 10),
        date: sd.date,
      };
      return date;
    });
    return dateData.map((day) => {
      const obj = { ...day, date: null };
      selectedDate.filter((sDate: any) => {
        if (sDate.day === day.day && sDate.month === day.month) {
          obj.id = sDate.id;
          obj.isSelected = true;
          obj.date = sDate.date;
          return { ...obj };
        }
        return '';
      });
      return obj;
    });
  }, [showCalendarDates]);
  const limit = 6;
  useEffect(() => {
    if (showId && dates) {
      getMatrixDataBegin({ date: dates, showId, page: 1, limit });
    }
  }, [showId, dates]);
  useEffect(() => {
    const d = dateCalendarData.findIndex((index) => index.id === selectedId);
    setSelectedShowDate(`${dateCalendarData[d].month} ${dateCalendarData[d].day}`);
  }, [selectedId]);
  const handleSearch = (text: string) => searchExistingMatrix(text);
  const loadPerformerData = (item: any, userId: any) => fetchMatrixPerformer({ workingDayId: item, id: userId });
  const handlePerformers = (item: any, userId: any) => {
    loadPerformerData(typeof item === 'string' ? item : item.id, userId);
  };
  const handleRateFilter = (rate: string) => rateFilterExistingMatrix(rate);
  const handleErrorNotesFilter = (val: string) => errorNotesFilterExistingMatrix(val);
  const handleHideFilter = (val: string) => hideFilterExistingMatrix(val);
  const reOrder = (num: number) => {
    if (num !== sortingOrder) sortMatrixData(num);
  };
  const loadData = useCallback(
    (page: number) => {
      if (showId && !loading) {
        getMatrixDataBegin({ date: dates, showId, limit, page });
      }
    },
    [showId],
  );
  useEffect(() => {
    if (render) {
      getMatrixDataBegin({ date: dates, showId, page: 1, limit });
      setRender(false);
    }
  }, [render]);

  useEffect(() => {
    // @ts-ignore
    if (breakdownPdfSelector !== '') {
      setBreakdownItem(breakdownPdfSelector);
      setIsBreakdown(true);
    }
  }, [breakdownPdfSelector]);

  const handleLoadMore = () => loadData(pagination.page + 1);
  return (
    <SectionContainer>
      <StyledEVoucher>
        <DateList
          sideNavOpen={showSideNav || false}
          selectedDateId={setSelectedId}
          showCalendarDates={dateCalendarData || []}
        />
        <FilterTabsWrapper
          handleRateFilter={handleRateFilter}
          handleErrorNotesFilter={handleErrorNotesFilter}
          handleHideFilter={handleHideFilter}
          handleSearch={handleSearch}
          reOrder={reOrder}
        />
        {matrixData.map((item: MatrixType, index) => (
          <ShowInfo
            key={item.id}
            dayItem={item}
            date={selectedShowDate}
            showCalendarDates={showCalendarDates}
            callBack={handlePerformers}
            prevElement={matrixData[index - 1] ? matrixData[index - 1] : null}
            render={setRender}
          />
        ))}
        {pagination && pagination.page < pagination.totalPages && !loading && (
          <div className="text-center">
            <button type="button" onClick={handleLoadMore} className={Style.loadMoreButton}>
              View More
            </button>
          </div>
        )}
        {isBreakdown && <BreakDown item={breakdownItem} setIsBreakdown={setIsBreakdown} />}
      </StyledEVoucher>
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Voucher;
