import React from 'react';
// import { faEdit, faShareAlt } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

interface Props {
  name?: string;
  custom: boolean;
  handleId?: any;
  selected: boolean;
}

const RateItem: React.FC<Props> = (props) => {
  const { name, custom, handleId, selected } = props;
  return (
    <StyledRateItem
      custom={custom}
      selected={selected}
      onClick={(e) => handleId(e)}
      onKeyPress={(e) => handleId(e)}
      role="button"
      tabIndex={0}
    >
      <ItemContainer custom={custom} selected={selected}>
        <p>{name}</p>
        {/* <IconWrapper>
          <div>
            <FontAwesomeIcon icon={faShareAlt} />
          </div>
          <div>
            <FontAwesomeIcon icon={faEdit} />
          </div>R
        </IconWrapper> */}
      </ItemContainer>
    </StyledRateItem>
  );
};

export default RateItem;

const ItemContainer = styled.div<Props>`
  border-bottom: 1px solid #f2f2f5;
  display: flex;
  cursor: pointer;
  height: 35px;
  justify-content: space-between;
  aligh-items: center;
  & > p {
    margin: 0;
    font-size: 14px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-weight: ${({ selected }) => (selected ? 'bold' : '')};
  }
  & > div {
    height: 35px;
    display: none;
  }

  &:hover div {
    display: flex;
    align-items: center;
  }
`;

const StyledRateItem = styled.div<Props>`
  width: 275px;
  align-items: center;
  color: #828282;
  padding-left: 15px;
  margin-right: 15px;
  background: ${({ custom }) => (custom ? 'rgba(45, 156, 219, 0.04)' : '')};
`;

// const IconWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;

//   & > div {
//     margin-left: 16px;
//   }
// `;
