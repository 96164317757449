import React, { FunctionComponent, useEffect } from 'react';
import Style from 'components/ProfileMenu/DaysWorked/DaysWorked.module.css';
import Sort from 'components/Sort/Sort';
import WorkItem from 'components/ProfileMenu/DaysWorked/WorkItem';
import { fetchPerformerWorkedDaysBegin } from 'store/actions/job.actions';
import { connect } from 'react-redux';
import { PerformerWorkedDay, ShowDayJob } from 'modules/Show/show.types';
import { workedDaysList, workedDaysPagination } from 'store/selector/job.selector';
import { Pagination } from 'modules/jobs/types';
import PaginationComponent from 'components/controls/pagination/Pagination';
import { getVoucherLoading } from 'store/selector/performer.selector';
import Loader from 'components/controls/Loader/Loader';

interface DaysWorkedProps {
  workDays: PerformerWorkedDay[];
  workDaysPagination: Pagination;
  fetchWorkedDays: Function;
  loading: Boolean;
}

const DaysWorked: FunctionComponent<DaysWorkedProps> = (props: DaysWorkedProps) => {
  const { workDays, fetchWorkedDays, workDaysPagination, loading } = props;
  const limit: number = 10;

  useEffect(() => {
    fetchWorkedDays({ page: 1, limit });
  }, []);

  const handlePages = (updatePage: any) => {
    fetchWorkedDays({ page: updatePage.selected + 1, limit });
  };

  if (loading) {
    return (
      <div className="d-flex w-100 h-100 justify-content-center align-items-center">
        <Loader isSmallView />
      </div>
    );
  }
  return (
    <div className={Style.mainWrapper}>
      <Sort />
      <div className="mt-40">
        {workDays.map((item: PerformerWorkedDay) => (
          <WorkItem item={item} />
        ))}
      </div>
      <div className="mt-3">
        <PaginationComponent
          page={workDaysPagination.page}
          totalPages={workDaysPagination.totalPages > 0 ? workDaysPagination.totalPages : 1}
          handlePagination={handlePages}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: ShowDayJob) => {
  const workDays: PerformerWorkedDay[] = workedDaysList(state);
  const workDaysPagination: Pagination = workedDaysPagination(state);
  const loading = getVoucherLoading(state);

  return { workDays, workDaysPagination, loading };
};

const mapStateToDispatch = {
  fetchWorkedDays: fetchPerformerWorkedDaysBegin,
};

export default connect(mapStateToProps, mapStateToDispatch)(DaysWorked);
