import React, { FunctionComponent, useEffect, useState } from 'react';
import SortBy from 'components/Sort/Sort';
import Style from 'components/castingDirector/Matrix.module.css';
import { TYPE_MATRIX } from 'store/castingPax.constants';
import { EmailTemplate, EmailTemplateList } from 'modules/general/general.type';
import { MatrixItemList, MatrixType } from 'modules/Show/show.types';
import MatrixDetail from 'components/castingDirector/Detail/MatrixDetail';
import { Pagination } from 'modules/jobs/types';
import Loader from 'components/controls/Loader/Loader';
import BreakDown from 'components/Payroll/VoucherManagement/BreakDown';
import Modal from 'components/controls/Modal/Modal';

interface MatrixProps {
  dates: string[];
  showId: string;
  loading: boolean;
  emailTemplates: EmailTemplateList;
  matrixData: MatrixItemList;
  pagination: Pagination;
  getEmailTemplateRequest: Function;
  getMatrixDataBegin: Function;
  searchExistingMatrix: Function;
  sortMatrixData: Function;
  sortingOrder: number;
  setDatesForFitler: Function;
  breakdownPdfSelector: Function;
  clearBreakDownData: Function;
  breakDownBegin: Function;
  breakdownLoading: boolean;
  breakdown: Function;
}

const Matrix: FunctionComponent<MatrixProps> = (props: MatrixProps) => {
  const {
    dates,
    showId,
    loading,
    emailTemplates,
    matrixData,
    pagination,
    getEmailTemplateRequest,
    getMatrixDataBegin,
    searchExistingMatrix,
    sortMatrixData,
    sortingOrder,
    setDatesForFitler,
    breakdownPdfSelector,
    clearBreakDownData,
    breakDownBegin,
    breakdown,
    breakdownLoading,
  } = props;
  const limit = 6;
  const [isBreakdown, setIsBreakdown] = useState(false);
  const [breakdownItem, setBreakdownItem] = useState({});
  const [selectedEmail, setSelectedEmail] = useState('');
  const [pdfUrl, setPdfurl] = useState<string>('');
  const [isViewBreakdown, setIsViewBreakdown] = useState(false);
  const [selectedBreakDownDayId, setSelectedBreakDownDayId] = useState();

  useEffect(() => {
    getEmailTemplateRequest({ createdFrom: TYPE_MATRIX });
  }, []);

  useEffect(() => {
    loadData(1);
  }, [dates, showId]);

  const loadData = (page: number) => {
    if (showId && !loading) {
      getMatrixDataBegin({ date: dates, showId, limit, page });
    }
  };

  const handleLoadMore = () => loadData(pagination.page + 1);

  const handleSearch = (text: string) => searchExistingMatrix(text);

  const handleFilterDates = (filterDates: string[]) => setDatesForFitler(filterDates);

  const reOrder = (num: number) => {
    if (num !== sortingOrder) sortMatrixData(num);
  };

  useEffect(() => {
    // @ts-ignore
    if (breakdownPdfSelector !== '') {
      setBreakdownItem(breakdownPdfSelector);
      setIsBreakdown(true);
      clearBreakDownData();
    }
  }, [breakdownPdfSelector]);

  const showIframe = () => {
    if (pdfUrl) {
      return <iframe src={pdfUrl} style={{ width: '100%', height: '500px' }} frameBorder="0" title="PDF Viewer" />;
    }

    return '';
  };

  const handleBreakDown = (dayItemData: any, userEmail: string, type?: string) => {
    if (type === 'view') {
      setSelectedEmail('');
      setIsViewBreakdown(true);
    } else {
      setSelectedEmail(userEmail);
      setIsViewBreakdown(false);
    }
    setSelectedBreakDownDayId(dayItemData.id);
    breakdown({ id: dayItemData.id, showId: dayItemData.showId.id });
  };

  const handleBreakdownPdf = (pdfBlob: any) => {
    if (selectedEmail) {
      const formData = new FormData();
      formData.append('pdf', pdfBlob, 'breakdown.pdf');
      formData.append('email', selectedEmail);
      breakDownBegin(formData);
      setSelectedEmail('');
    } else if (isViewBreakdown) {
      const url = URL.createObjectURL(pdfBlob);
      setPdfurl(url);
    }
    setIsBreakdown(false);
  };

  return (
    <>
      <SortBy
        cssClass="DirectorSortingWrapper"
        filterDates={handleFilterDates}
        reOrder={reOrder}
        handleSearch={handleSearch}
      />
      {loading && <Loader isSmallView />}
      {matrixData.map((item: MatrixType) => (
        <MatrixDetail
          key={item.id}
          dayItem={item}
          emailTemplates={emailTemplates.filter((e: EmailTemplate) => e.createdFrom === 'matrix')}
          isViewBreakdown={isViewBreakdown}
          handleBreakDownData={handleBreakDown}
          isLoadingBreakdown={(selectedBreakDownDayId === item.id && breakdownLoading) || false}
        />
      ))}
      {pagination && pagination.page < pagination.totalPages && !loading && (
        <div className="text-center">
          <button type="button" onClick={handleLoadMore} className={Style.loadMoreButton}>
            View More
          </button>
        </div>
      )}
      {isBreakdown && (
        <BreakDown item={breakdownItem} setIsBreakdown={setIsBreakdown} handleBreakdownPdf={handleBreakdownPdf} />
      )}
      {pdfUrl && (
        <Modal
          cssClass={Style.modalSize}
          title="Breakdown View"
          body={() => showIframe()}
          closeModal={() => {
            setPdfurl('');
            setIsViewBreakdown(false);
          }}
        />
      )}
    </>
  );
};

export default Matrix;
