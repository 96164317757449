import React, { FunctionComponent, useRef, useState } from 'react';
import Style from 'components/SearchUser/SearchUser.module.css';
import { debounce } from 'lodash';
import userApi from 'store/services/user.services';
// import DashboardInput from 'components/controls/input/dashboardInput/dashboardInput';
import ApiResponse from 'modules/response/response.types';
import { Show, ShowList } from 'modules/Show/show.types';
import closeSvg from 'assets/svg/close.svg';
import showApi from 'store/services/show.services';
import { Overlay, Tooltip } from 'react-bootstrap';
import Loader from 'components/controls/Loader/Loader';

interface SearchShowProps {
  label: string;
  setShowName: Function;
  handleShowSearch: Function;
  cssClass?: string;
}

const SearchShow: FunctionComponent<SearchShowProps> = (props) => {
  const { label, setShowName, handleShowSearch, cssClass } = props;
  const [searchResult, setSearchResult] = useState<ShowList>([]);
  const target = useRef(null);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = () => {
    setSearchResult([]);
  };

  const loadShowDetails = (show: Show) => {
    showApi
      .fetchShowDetail({ showId: show.id })
      .then((res) => {
        handleShowSearch(res.data);
      })
      .catch((err) => err);
    setIsShow(false);
    setSearchResult([]);
  };

  const showSearchResult = () => {
    if (searchResult.length > 0) {
      return (
        <div className={`${Style.searchWrapper} ${Style.searchToolTip} ${cssClass}`}>
          <div onClick={handleClose} onKeyPress={handleClose} className={Style.CloseIcon} role="button" tabIndex={0}>
            <img src={closeSvg} alt="" />
          </div>
          {searchResult.map((show) => (
            <div
              onClick={() => {
                loadShowDetails(show);
              }}
              onKeyPress={() => {
                loadShowDetails(show);
              }}
              role="button"
              tabIndex={0}
            >
              {show.title}
            </div>
          ))}
        </div>
      );
    }

    return '';
  };
  const searchName = debounce((text: string) => fetchResult(text), 500);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    searchName(event.currentTarget.value);
  };

  const fetchResult = (text: string) => {
    setShowName(text);
    if (text.length === 0) {
      setSearchResult([]);
      setIsShow(false);
      return;
    }
    setIsLoading(true);
    userApi
      .searchShow({ searchText: text })
      .then((res: ApiResponse) => {
        setSearchResult(res.data);
        setIsShow(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsShow(false);
        setIsLoading(false);
      });
  };

  const handleHide = (e: any) => {
    if (e.pointerId) setIsShow(false);
  };
  return (
    <div className={`${cssClass}`}>
      <input
        type="text"
        ref={target}
        className="dashboardInput"
        placeholder={label}
        onChange={(e) => handleChange(e)}
      />
      {isLoading && <Loader isSmallView />}
      <Overlay rootClose target={target.current} onHide={handleHide} show={isShow} placement="bottom-start">
        {(propss) => (
          <Tooltip id="overlay-example" {...propss}>
            {showSearchResult()}
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
};

export default SearchShow;
