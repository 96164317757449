import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getHomePage } from 'helpers/utils';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';

const PublicRoute = ({ component, path }: any) => {
  const user = getItemFromLocalStorage('user') || {};
  if (path.toUpperCase().includes('VIEW') || path.toLowerCase().includes('confirm')) {
    return <Route path={path} exact component={component} />;
  }
  return user.role ? <Redirect to={getHomePage(user.role)} /> : <Route path={path} exact component={component} />;
};

export default PublicRoute;
