import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import PerformerProfileStyle from 'components/PerformerProfile/PerformerProfile.module.css';
import ProfileCalender from 'components/ProfileCalender/ProfileCalender';
import ProfileInfo from 'components/PerformerProfile/ProfileInfo';
import { BookingDate, BookingDateList, ImageFolderItem, PerformerImage, User } from 'modules/user/types';
import ButtonSection from 'components/PerformerProfile/ButtonSection';
import {
  formatHeight,
  formatWeight,
  getAge,
  getImageThumbnail,
  getName,
  getStatusTitle,
  getUnionClass,
  getUnionValue,
  performerIdInLC,
} from 'helpers/utils';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import DatePopup from 'components/PerformerProfile/DatePopup/DatePopup';
import ImageView from 'components/controls/imageViewer/imageView';
import moment from 'moment';
import { PerformerJobs, CalendarItem } from 'modules/jobs/types';
import { getJobPostDates } from 'store/selector/show.selector';
import { connect, useSelector } from 'react-redux';
import { PeformerJobDates } from 'modules/Show/show.types';
import {
  CALENDAR_STATUS_NOT_AVAILABLE,
  JOB_STATUS_BOOKED,
  JOB_STATUS_PENDING,
  JOB_STATUS_REQUESTED,
} from 'store/castingPax.constants';
import PhoneInputFormat from 'components/phoneInput/phoneInputFormat';
import { getCurrentWidth } from 'store/selector/general.selector';
import mobileGreenSvg from 'assets/svg/mobile-green.svg';
import mailGreenSvg from 'assets/svg/mail-green.svg';

interface PerformerFullProfileProps {
  profile: User;
  selectedJobDates: PeformerJobDates[];
}
const PerformerFullProfile: FunctionComponent<PerformerFullProfileProps> = (props: PerformerFullProfileProps) => {
  const { profile, selectedJobDates } = props;
  const [calenderDayItem, setCalendarDayItem] = useState<number>(0);
  const [profilePicture, setProfilePicture] = useState(profile.profilePicUrl || '');
  const currentScreen = useSelector(getCurrentWidth);
  const ref: any = useRef(null);
  useEffect(() => {
    setCalendarDayItem(Math.floor(ref.current.offsetWidth / 75));
  }, [currentScreen]);
  const viewProfile = () => {
    performerIdInLC.save(profile.id);
    window.open('/casting/profile/view');
  };
  const getImages = () => {
    const images = profile.bgPerformer?.images || [];
    const index = images.findIndex((x: ImageFolderItem) => x.isDefault === true);
    if (index !== -1) {
      const imgs = images[index].images || [];
      if (imgs && imgs.length > 4) {
        imgs.splice(4, 1);
        return imgs;
      }
      return imgs;
    }
    return [];
  };

  const getJobDates = useMemo(() => {
    const index: number = selectedJobDates.findIndex((x: PeformerJobDates) => x.userId === profile.id);
    if (index !== -1) return selectedJobDates[index].dates;
    const pos: number = selectedJobDates.findIndex((x: PeformerJobDates) => x.userId === '-1');
    if (pos !== -1) return selectedJobDates[pos].dates;
    return [];
  }, [selectedJobDates]);

  const bookingDates = useMemo(() => {
    const date = moment().startOf('day');
    const arr: BookingDateList = [];
    for (let i = 0; i < 28; i += 1) {
      arr.push({
        id: i.toString(),
        month: date.format('MMM'),
        day: date.format('DD'),
        date: date.format('YYYY-MM-DD'),
        type: '',
        isJobDate: getJobDates.includes(`${date.format('YYYY-MM-DD')} 00:00:00`),
      });
      date.add(1, 'days');
    }

    if (profile) {
      if (profile.bgPerformer && profile.bgPerformer.calendar) {
        profile.bgPerformer.calendar.forEach((c: CalendarItem) => {
          const index = arr.findIndex((x: BookingDate) => c.workDate.includes(x.date));
          if (index !== -1) {
            arr[index].type = getStatusTitle(c.workStatus) || 'pending';
          }
        });
      }
    }
    if (profile.job) {
      profile.job.forEach((b: PerformerJobs) => {
        b.dates.forEach((d: string) => {
          const index = arr.findIndex((x: BookingDate) => d.includes(x.date));
          if (index !== -1 && arr[index].type !== JOB_STATUS_BOOKED) {
            if (b.status === JOB_STATUS_BOOKED) {
              arr[index].type = JOB_STATUS_BOOKED;
            } else if (arr[index].type !== CALENDAR_STATUS_NOT_AVAILABLE) {
              arr[index].type = b.status === JOB_STATUS_REQUESTED ? JOB_STATUS_PENDING : b.status;
            }
          }
        });
      });
    }
    return arr;
  }, [profile, profile.job, profile.calendar, selectedJobDates]);

  const dateComponent = () => <DatePopup userId={profile.id} dates={bookingDates} startKey={calenderDayItem - 1} />;
  return (
    <div className={`position-relative ${PerformerProfileStyle.fullProfileCard}`}>
      <div className={PerformerProfileStyle.performerId}>
        <p className="mb-0 mt-0">
          <span className={`${PerformerProfileStyle.afterTitle} ${getUnionClass(profile)}`}>
            {getUnionValue(profile)}
          </span>
        </p>
        {/* <span className={PerformerProfileStyle.permitOnlyText}>Permit only</span> */}
      </div>
      <div className={`mb-2 pt-3 px-3 ${PerformerProfileStyle.performerFullDetail}`}>
        <div className={PerformerProfileStyle.performerImageView}>
          <ImageView src={profilePicture} alt="" cssClass={PerformerProfileStyle.profileImage} isFullView />
          <div className={`mt-2 ${PerformerProfileStyle.smallImageWrapper}`}>
            {getImages().map((image: PerformerImage) => (
              <ImageView
                // src={getImageThumbnail(image.image)}
                src={image.image}
                fullImgSrc={image.image}
                key={image.id}
                alt=""
                cssClass={PerformerProfileStyle.profileImageSmall}
                isFullView
                setProfile={(e: string) => setProfilePicture(e)}
              />
            ))}
          </div>
        </div>
        <div className={`pt-2 mt-lg-3 mt-xl-0 ${PerformerProfileStyle.performerDetailWrapper}`}>
          <div onClick={viewProfile} onKeyUp={viewProfile} tabIndex={0} role="button">
            <p className={PerformerProfileStyle.profileName}>{getName(profile)}</p>
          </div>
          <div className={PerformerProfileStyle.ProfileInfoWrapper}>
            <div className={PerformerProfileStyle.ProfileInfoFirstColumn}>
              <div className={`ml-0 mr-0 ${PerformerProfileStyle.profileInfoFirstInner}`}>
                <div className={PerformerProfileStyle.mobileEmailSection}>
                  <ProfileInfo
                    icon={mailGreenSvg}
                    labelClass="d-none"
                    label=""
                    value={profile.email}
                    title={profile.email}
                  />
                  <div style={{ position: 'relative', top: '-10px' }}>
                    <PhoneInputFormat
                      labelClass="d-none"
                      icon={mobileGreenSvg}
                      label=""
                      value={profile.phoneNo}
                      title={profile.phoneNo}
                    />
                  </div>
                </div>
                <div className={`px-1 ${PerformerProfileStyle.columnWidth}`}>
                  <ProfileInfo label="Sex" title={profile.bgPerformer?.gender} value={profile.bgPerformer?.gender} />
                </div>
                <div className={`px-1 ${PerformerProfileStyle.columnWidth}`}>
                  <ProfileInfo
                    label="Age"
                    title={getAge(profile.bgPerformer?.dateOfBirth)}
                    value={getAge(profile.bgPerformer?.dateOfBirth)}
                  />
                </div>
                <div className={`px-1 ${PerformerProfileStyle.columnWidth}`}>
                  <ProfileInfo
                    label="Eye color"
                    value={profile.bgPerformer?.eyeColor}
                    title={profile.bgPerformer?.eyeColor}
                  />
                </div>
                <div className={`px-1 ${PerformerProfileStyle.columnWidth}`}>
                  <ProfileInfo
                    label="Weight"
                    value={formatWeight(profile.bgPerformer?.weight)}
                    title={formatWeight(profile.bgPerformer?.weight)}
                  />
                </div>
                <div className={`px-1 ${PerformerProfileStyle.columnWidth}`}>
                  <ProfileInfo
                    label="Height"
                    value={formatHeight(profile.bgPerformer?.height || '')}
                    title={formatHeight(profile.bgPerformer?.height || '')}
                  />
                </div>
                <div className={`px-1 ${PerformerProfileStyle.columnWidth}`}>
                  <ProfileInfo label="Hair" value={profile.bgPerformer?.hair} title={profile.bgPerformer?.hair} />
                </div>
              </div>
            </div>
            <div className={`mb-3 ${PerformerProfileStyle.ProfileInfoSecondColumn}`}>
              <ButtonSection performer={profile} showMessageSection calendarDates={bookingDates} />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div id="hello" ref={ref} className={`position-relative ${PerformerProfileStyle.profileCard}`}>
              <div className={PerformerProfileStyle.profileCardFooter}>
                <div className="d-flex justify-content-between">
                  <div className={`d-flex justify-content-between ${PerformerProfileStyle.performerDayCalendarWidth}`}>
                    {bookingDates &&
                      bookingDates.map((date: BookingDate, index: number) => {
                        if (index < calenderDayItem) {
                          return (
                            <ProfileCalender
                              userId={profile.id}
                              key={date.id}
                              isShowDate={date.isJobDate}
                              date={date}
                            />
                          );
                        }
                        return '';
                      })}
                  </div>
                  <div>
                    <PopoverContainer itemComponent={dateComponent}>
                      <div className={PerformerProfileStyle.calenderDetailBox}>
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </div>
                    </PopoverContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: User) => {
  const selectedJobDates = getJobPostDates(state);
  return { selectedJobDates };
};

export default connect(mapStateToProps, null)(PerformerFullProfile);
