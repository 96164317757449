import CalendarDetail from 'components/bgPerformer/Calender/Detail';
import MultiJobView from 'components/bgPerformer/Calender/MultiJobView';
import CastingCallDetail from 'components/bgPerformer/Calls/Details/Detail';
import { BookingRequest, BookingRequestList } from 'modules/jobs/types';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { JOB_STATUS_BOOKED } from 'store/castingPax.constants';

interface PopoverStickOnHoverProps {
  children: any;
  delay?: number;
  onMouseEnter?: Function;
  item: any;
  status?: string;
  fullDetailHandle?: Function;
}

const PopoverStickOnHover: FunctionComponent<PopoverStickOnHoverProps> = (props: PopoverStickOnHoverProps) => {
  const { delay, onMouseEnter, children, item, status, fullDetailHandle } = props;
  const [showPopover, setShowPopover] = useState(false);
  const childNode = useRef(null);
  let setTimeoutConst: any = null;
  useEffect(() => () => {
    if (setTimeoutConst) {
      clearTimeout(setTimeoutConst);
    }
  });

  const handleMouseEnter = () => {
    setTimeoutConst = setTimeout(() => {
      setShowPopover(true);
      if (onMouseEnter) onMouseEnter();
    }, delay);
  };

  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst);
    setShowPopover(false);
  };

  const handleClick = () => {
    setShowPopover(true);
  };

  const component = () => {
    if (status === 'call' || status === 'update') {
      return (
        <CastingCallDetail
          item={item.jobId ? item.jobId : item}
          dates={item.dates}
          handleMouseLeave={handleMouseLeave}
          fullDetailHandle={fullDetailHandle}
        />
      );
    }
    const jobs = item.jobs ? item.jobs : item.job;
    if (status === 'booked') {
      const filter: BookingRequestList = jobs.filter((x: BookingRequest) => x.status === JOB_STATUS_BOOKED);
      const paramJob: BookingRequest = filter.length > 0 ? filter[0] : jobs[0];
      return <CalendarDetail item={paramJob} handleMouseLeave={handleMouseLeave} />;
    }
    return <MultiJobView items={jobs} handleMouseLeave={handleMouseLeave} />;
  };

  const displayChild = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      onClick: handleClick,
      ref: (node: any) => {
        childNode.current = node;
        const { ref } = child;
        if (typeof ref === 'function') {
          ref(node);
        }
      },
    }),
  )[0];

  return (
    <>
      {displayChild}
      <Overlay show={showPopover} placement="bottom" target={childNode}>
        <Popover
          onMouseEnter={() => {
            setShowPopover(true);
          }}
          onMouseLeave={handleMouseLeave}
          id="popover"
        >
          {component()}
        </Popover>
      </Overlay>
    </>
  );
};

PopoverStickOnHover.defaultProps = {
  delay: 0,
  onMouseEnter: () => {},
};

export default PopoverStickOnHover;
