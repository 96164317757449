import React, { FunctionComponent, useEffect, useState } from 'react';
import Button from 'components/controls/button/button';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import { checkEmail } from 'utils/FormValidation';

interface IModalProp {
  handleSave: Function;
  handleClose: Function;
  isLoading: boolean;
  type: string;
}
const BgSizeCardModal: FunctionComponent<IModalProp> = (props) => {
  const { handleSave, handleClose, isLoading, type } = props;
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  useEffect(() => {
    if (!isLoading && type && email && !emailError) {
      handleClose();
    }
  }, [isLoading]);

  const handleSaveModal = () => {
    setEmailError(checkEmail(email));
    if (!emailError && emailError.length <= 0 && email) {
      handleSave(email);
    }
  };
  const handleBlur = (event: React.FormEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    setEmailError(checkEmail(val));
  };

  return (
    <>
      <div className="row">
        <div className="col-md-3 col-lg-3 col-sm-4">
          <label htmlFor="">Email Address:</label>
        </div>
        <div className="col-md-9 col-lg-9 col-sm-8">
          <CustomInput
            errorMessage={emailError}
            blurHandler={handleBlur}
            handleChange={setEmail}
            type="email"
            placeholder="Email Address"
            cssClass={emailError ? 'border-danger' : ''}
          />
        </div>
      </div>
      <div className="d-flex mt-4">
        <Button
          showLoading={isLoading}
          isDisabled={isLoading && email.length > 0 && !emailError}
          type="customSaveBtn"
          clickHandler={() => handleSaveModal()}
          label="Send"
        />
        &ensp;
        <Button type="customCancelBtn" clickHandler={() => handleClose()} label="Cancel" />
      </div>
    </>
  );
};

export default BgSizeCardModal;
