import {
  IAddPerformerInMyList,
  ICreateMyPerformerList,
  IIDParams,
  IPerformerDetail,
  IPerformerId,
  IPersonalNote,
  IUserJobStatusParam,
} from 'modules/params/param.type';
import { apiCall } from 'store/services/service';
import {
  URL_CREATE_MY_BG_LIST,
  URL_GET_MY_BG_LIST,
  URL_PERFORMER_DETAILS,
  URL_ADD_PERFOMER_IN_LIST,
  URL_PERFORMER_PERSONAL_NOTE,
  URL_PERFORMER_PERSONAL_NOTE_LIST,
  URL_PERFORMER_BOOKING,
  URL_PERFORMER_IMAGES,
  URL_GET_JOB_ALL_BOOKINGS,
  URL_PERFORMER_VIEW_DETAILS,
} from 'store/services/URL';

const api = {
  fetchMyPerformerList() {
    return apiCall.get({ url: URL_GET_MY_BG_LIST, isAuthToken: true, params: {} });
  },
  newListItem(param: ICreateMyPerformerList) {
    return apiCall.post({ url: URL_CREATE_MY_BG_LIST, isAuthToken: true, params: param });
  },
  fetchPerformerDetail(param: IPerformerDetail) {
    return apiCall.post({ url: URL_PERFORMER_DETAILS, isAuthToken: true, params: param });
  },
  fetchPerformerViewDetail(param: IPerformerDetail) {
    return apiCall.post({ url: URL_PERFORMER_VIEW_DETAILS, isAuthToken: true, params: param });
  },
  fetchPerformerBookings(param: IPerformerId) {
    return apiCall.post({ url: URL_PERFORMER_BOOKING, isAuthToken: true, params: param });
  },
  addPerformerInMyList(params: IAddPerformerInMyList) {
    return apiCall.post({ url: URL_ADD_PERFOMER_IN_LIST, isAuthToken: true, params });
  },
  savePersonalNote(params: IPersonalNote) {
    return apiCall.post({ url: URL_PERFORMER_PERSONAL_NOTE, isAuthToken: true, params });
  },
  fetchPersonalNote() {
    return apiCall.get({ url: URL_PERFORMER_PERSONAL_NOTE_LIST, isAuthToken: true, params: {} });
  },
  fetchPerformerImages(params: IIDParams) {
    return apiCall.post({ url: URL_PERFORMER_IMAGES, isAuthToken: true, params });
  },
  fetchUserJobStatus(params: IUserJobStatusParam) {
    return apiCall.post({ url: URL_GET_JOB_ALL_BOOKINGS, isAuthToken: true, params });
  },
};

export default api;
