import React, { FunctionComponent } from 'react';
import { performerIdInLC } from 'helpers/utils';

interface IRedirectProps {
  performerId: string;
  performerName: string;
}
const PerformeRedirectLink: FunctionComponent<IRedirectProps> = (props) => {
  const { performerId, performerName } = props;

  const handleNewTabClick = () => {
    performerIdInLC.save(performerId);
  };
  return (
    <>
      <a href="/casting/profile/view" target="_blank" onClick={handleNewTabClick}>
        {performerName}
      </a>
    </>
  );
};

export default PerformeRedirectLink;
