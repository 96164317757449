import React, { FunctionComponent, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'components/controls/button/button';

interface ModalProps {
  title?: string;
  isShow: boolean;
  hideModal?: Function;
  isOkOnly?: boolean;
  labelOk?: string;
  labelCancel?: string;
}
const CPModal: FunctionComponent<ModalProps> = (props) => {
  const { isShow, title, children, hideModal, isOkOnly, labelOk, labelCancel } = props;
  const [showModal, setShowModal] = useState<boolean>(isShow);

  useEffect(() => {
    setShowModal(isShow);
  }, [isShow]);

  const handleCloseModal = (status: boolean) => {
    setShowModal(false);
    if (hideModal) hideModal(status);
  };

  const showButtons = () => {
    if (isOkOnly) {
      return <Button clickHandler={() => handleCloseModal(true)} label="Ok" type="customSaveBtn" />;
    }
    return (
      <>
        <Button clickHandler={() => handleCloseModal(false)} label={labelCancel} type="customCancelBtn" />
        <Button clickHandler={() => handleCloseModal(true)} label={labelOk} type="customSaveBtn" />
      </>
    );
  };

  if (showModal) {
    return (
      <Modal show={isShow} onHide={() => handleCloseModal(false)}>
        {title && <Modal.Header>{title}</Modal.Header>}
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>{showButtons()}</Modal.Footer>
      </Modal>
    );
  }

  return <></>;
};

CPModal.defaultProps = {
  labelOk: 'Ok',
  labelCancel: 'Cancel',
};

export default CPModal;
