import { connect } from 'react-redux';
import JobView from 'components/JobView/JobView';
import { ShowDayJob } from 'modules/Show/show.types';
import { getSelectedJob, getSelectedBooking, getBookingRequest } from 'store/selector/job.selector';
import { acceptCastingRequestBegin, closeOpenJobDetails, submitJobRequestBegin } from 'store/actions/job.actions';
import { getUser } from 'store/selector/auth.selector';
import { showMessageBox, showToast } from 'store/actions/general.actions';

const mapStateToProps = (state: ShowDayJob) => {
  const job = getSelectedJob(state);
  const booking = getSelectedBooking(state);
  const user = getUser(state);
  const allBookings = getBookingRequest(state);
  return { job, booking, user, allBookings };
};

const mapDispatchToProps = {
  closeOpenJobDetails,
  submitJobRequestBegin,
  showMessageBox,
  acceptCastingRequestBegin,
  showToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobView);
