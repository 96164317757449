import { fromJS } from 'immutable';
import { ImageFolderItem, PerformerImage, PerformerImageList, User } from 'modules/user/types';
import ACTIONS from 'store/castingPax.constants';

const initialState = fromJS({
  profile: {},
  residency_docs: [],
  skillLoading: false,
  fullViewImage: '',
  loading: false,
  counter: 0,
  folderId: '',
  fileUploadError: null,
  imageFiles: null,
  rentalLoading: false,
  voucherLoading: false,
  voucherInfo: {},
});

export default function performerReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.FETCH_PERFORMER_PROFILE.PENDING: {
      return state.setIn(['profile', 'loading'], action.status === 'LOADING');
    }
    case ACTIONS.FETCH_PERFORMER_PROFILE.SUCCESS: {
      return state
        .setIn(['profile', 'loading'], false)
        .setIn(['profile', 'error'], '')
        .setIn(['profile', 'data'], action.data);
    }
    case ACTIONS.FETCH_PERFORMER_PROFILE.ERROR: {
      return state.setIn(['profile', 'loading'], false).setIn(['profile', 'error'], '');
    }
    case ACTIONS.SAVE_PERFORMER_SKILL.PENDING: {
      return state.set('skillLoading', true);
    }
    case ACTIONS.SAVE_PERFORMER_SKILL.ERROR: {
      return state.set('skillLoading', false);
    }
    case ACTIONS.SAVE_PERFORMER_SKILL.SUCCESS: {
      const performer: User = state.getIn(['profile', 'data']);
      if (performer.bgPerformer) {
        performer.bgPerformer.skills = [...action.data];
      }
      return state.set('skillLoading', false).setIn(['profile', 'data'], { ...performer });
    }
    case ACTIONS.SAVE_PERFORMER_SKILL_SIGN_UP.PENDING: {
      return state.set('skillLoading', true);
    }
    case ACTIONS.SAVE_PERFORMER_SKILL_SIGN_UP.ERROR: {
      return state.set('skillLoading', false);
    }
    case ACTIONS.SAVE_PERFORMER_SKILL_SIGN_UP.SUCCESS: {
      return state.set('skillLoading', false).setIn(['profile', 'data'], { bgPerformer: { skills: action.data } });
    }
    case ACTIONS.DELETE_PERFORMER_SKILL.SUCCESS: {
      const prev: User = state.getIn(['profile', 'data']);
      if (prev.bgPerformer && prev.bgPerformer.skills) {
        prev.bgPerformer.skills = action.data;
      }
      return state.setIn(['profile', 'data'], { ...prev });
    }
    case ACTIONS.OPEN_FULL_VIEW_IMAGE: {
      return state.set('fullViewImage', action.data);
    }
    case ACTIONS.SAVE_PERFORMER_IMAGE.PENDING: {
      return state.set('loading', true).set('editableId', action.data);
    }
    case ACTIONS.SAVE_PERFORMER_IMAGE.ERROR: {
      return state.set('loading', false).set('editableId', '');
    }
    case ACTIONS.SAVE_PERFORMER_IMAGE.SUCCESS: {
      const prev = state.getIn(['profile', 'data']) || [];

      const { image, folder } = action.data;

      // const { folderId } = action.data.image;
      const prevFolder = prev.bgPerformer.images;
      let count = state.get('counter');
      const prevFiles = state.get('imageFiles') || [];

      const folderId = folder?.id;

      const imageIndex = prevFiles.findIndex((i: any) => i.id === parseInt(action.fileId, 10));
      if (imageIndex !== -1) {
        const data = prevFiles[imageIndex];
        data.status = 'success';
        prevFiles.splice(imageIndex, 1, { ...data });
      }
      count += 1;

      // means if album not created
      if (folderId) {
        const newObj = {
          id: folderId,
          name: folder.name,
          isDefault: false,
          sequence: folder.sequence,
          images: [image],
        };
        prev.bgPerformer.images.push(newObj);

        return state
          .setIn(['profile', 'data'], { ...prev })
          .set('folderId', folderId)
          .set('loading', false)
          .set('counter', count)
          .set('editableId', '');
      }
      // handle if  album exist
      const index = prev.bgPerformer.images.findIndex((x: ImageFolderItem) => x.id === image.folderId);

      if (index !== -1) {
        const imgPos = prevFolder[index].images.findIndex((x: PerformerImage) => x.id === image.id);
        if (imgPos !== -1) {
          prevFolder[index].images.splice(imgPos, 1, image);
        } else {
          prevFolder[index].images = [...prevFolder[index].images, image];
        }
        prev.bgPerformer.images = [...prevFolder];
      }
      return state
        .setIn(['profile', 'data'], { ...prev })
        .set('loading', false)
        .set('counter', count)
        .set('editableId', '')
        .set('imageFiles', [...prevFiles]);
    }
    case ACTIONS.ADD_IMAGE_UPLOAD_COUNTER: {
      const count = state.get('counter');
      const imageFiles = state.get('imageFiles') || [];
      if (imageFiles) {
        const prevIndex = imageFiles.findIndex((i: any) => i.id === action.data);
        if (prevIndex !== -1) {
          const data = imageFiles[prevIndex];
          data.status = 'error';
          imageFiles.splice(prevIndex, 1, { ...data });
        }
      }
      const errorList = state.get('fileUploadError') || [];
      return state
        .set('counter', count + 1)
        .set('imageFiles', [...imageFiles])
        .set('fileUploadError', [...errorList, { count, error: 'small size image' }]);
    }
    case ACTIONS.DELETE_PERFORMER_IMAGE_LOCALLY:
    case ACTIONS.DELETE_PERFORMER_IMAGE.SUCCESS: {
      const prev: User = state.getIn(['profile', 'data']);
      if (prev && prev.id) {
        const folderIndex = prev.bgPerformer.images.findIndex((x: ImageFolderItem) => x.id === action.data.folderId);
        if (folderIndex !== -1) {
          const prevFolder = prev.bgPerformer.images;
          const index = prevFolder[folderIndex].images.findIndex((x: PerformerImage) => x.id === action.data.id);
          if (index !== -1) {
            prevFolder[folderIndex].images.splice(index, 1);
            prev.bgPerformer.images = [...prevFolder];
          }
        }
        return state.setIn(['profile', 'data'], { ...prev }).set('loading', false);
      }
      return state;
    }
    case ACTIONS.DELETE_RESUME.PENDING: {
      return state.set('resumeLoading', true);
    }
    case ACTIONS.DELETE_RESUME.ERROR: {
      return state.set('resumeLoading', false);
    }
    case ACTIONS.DELETE_RESUME.SUCCESS: {
      const prevUser: User = state.getIn(['profile', 'data']);
      if (prevUser.id) {
        if (action.data.resumeUrl1) {
          prevUser.resumeUrl1 = undefined;
        } else if (action.data.resumeUrl2) {
          prevUser.resumeUrl2 = undefined;
        }
        return state.setIn(['profile', 'data'], { ...prevUser }).set('resumeLoading', false);
      }
      return state;
    }
    case ACTIONS.UPDATE_PERFORMER_IMAGE_RESUME_FILE: {
      const prev: User = state.getIn(['profile', 'data']);
      prev.profilePicUrl = action.data.profilePicUrl;
      prev.resumeUrl1 = action.data.resumeUrl1;
      prev.resumeUrl2 = action.data.resumeUrl2;
      return state.setIn(['profile', 'data'], { ...prev });
    }
    case ACTIONS.UPDATE_PERFORMER_FILE_COUNTER: {
      return state.set('counter', 0).set('folderId', '').set('fileUploadError', null);
    }
    case ACTIONS.DELETE_FILE_ERROR: {
      const prevImages = state.get('imageFiles') || [];
      const imageIndex = prevImages.findIndex((i: any) => i.id === action.data);
      if (imageIndex !== -1) {
        prevImages.splice(imageIndex, 1);
        return state.set('imageFiles', [...prevImages]);
      }
      return state;
    }
    case ACTIONS.IMAGE_UPLOAD_FILES: {
      const prevImages = state.get('imageFiles') || [];
      return state.set('imageFiles', [...prevImages.concat(action.data)]);
    }
    case ACTIONS.IMAGE_DELETE_FILES: {
      return state.set('imageFiles', null);
    }
    case ACTIONS.UPLOAD_RENTAL_IMAGE.PENDING: {
      return state.set('rentalLoading', true);
    }
    case ACTIONS.UPLOAD_RENTAL_IMAGE.SUCCESS: {
      const prevUser: User = state.getIn(['profile', 'data']);
      if (prevUser && prevUser.id) {
        prevUser.rentalImages = action.data;
        return state.setIn(['profile', 'data'], { ...prevUser }).set('rentalLoading', false);
      }
      return state;
    }
    case ACTIONS.UPLOAD_RENTAL_IMAGE.ERROR: {
      return state.set('rentalLoading', false);
    }
    case ACTIONS.REMOVE_RENTAL_IMAGE.PENDING: {
      return state.set('rentalLoading', true);
    }
    case ACTIONS.REMOVE_RENTAL_IMAGE.SUCCESS: {
      const prevUser: User = state.getIn(['profile', 'data']);
      if (prevUser && prevUser.id && action.data) {
        const { rentalImages } = prevUser;
        if (rentalImages && rentalImages.length > 0) {
          const index = rentalImages.findIndex((r: any) => r.id === action.data);
          if (index !== -1 && prevUser.rentalImages) {
            prevUser.rentalImages.splice(index, 1);
          }
        }
        return state.setIn(['profile', 'data'], { ...prevUser }).set('rentalLoading', false);
      }
      return state.set('rentalLoading', false);
    }
    case ACTIONS.REMOVE_RENTAL_IMAGE.ERROR: {
      return state.set('rentalLoading', false);
    }
    case ACTIONS.UPDATE_RENTAL_TYPE.PENDING: {
      return state.set('rentalLoading', true);
    }
    case ACTIONS.UPDATE_RENTAL_TYPE.SUCCESS: {
      const prevUser: User = state.getIn(['profile', 'data']);
      if (prevUser && prevUser.id && action.data) {
        const { rentalImages } = prevUser;
        if (rentalImages && rentalImages.length > 0) {
          const index = rentalImages.findIndex((r: any) => r.id === action.payload);
          if (index !== -1 && prevUser.rentalImages) {
            prevUser.rentalImages.splice(index, 1, action.data);
          }
        }
        return state.setIn(['profile', 'data'], { ...prevUser }).set('rentalLoading', false);
      }
      return state.set('rentalLoading', false);
    }
    case ACTIONS.UPDATE_RENTAL_TYPE.ERROR: {
      return state.set('rentalLoading', false);
    }
    case ACTIONS.SAVE_PERFORMER_VOUCHER.PENDING: {
      return state.set('voucherLoading', true);
    }
    case ACTIONS.SAVE_PERFORMER_VOUCHER.SUCCESS: {
      return state.set('voucherLoading', false).set('voucherInfo', action.data);
    }
    case ACTIONS.SAVE_PERFORMER_VOUCHER.ERROR: {
      return state.set('voucherLoading', false);
    }

    case ACTIONS.GET_PERFORMER_VOUCHER.PENDING: {
      return state.set('voucherLoading', false);
    }
    case ACTIONS.GET_PERFORMER_VOUCHER.SUCCESS: {
      return state.set('voucherLoading', false).set('voucherInfo', action.data);
    }
    case ACTIONS.GET_PERFORMER_VOUCHER.ERROR: {
      return state.set('voucherLoading', false);
    }

    case ACTIONS.GET_PROOF_OF_RESIDENCY.PENDING: {
      return state.set('loading', true);
    }
    case ACTIONS.GET_PROOF_OF_RESIDENCY.SUCCESS: {
      return state.set('loading', false).set('error', '').set('residency_docs', action.data);
    }
    case ACTIONS.GET_PROOF_OF_RESIDENCY.ERROR: {
      return state.set('loading', false).set('error', action.message);
    }

    case ACTIONS.PERFORMER_FILES_UPLOAD.PENDING: {
      return state.set('loading', true);
    }
    case ACTIONS.PERFORMER_FILES_UPLOAD.SUCCESS: {
      const prevFiles: any = state.getIn(['residency_docs', 'data']);
      console.log(prevFiles);
      return state.set('loading', false).set('error', '').set('residency_docs', action.data.performerPapulateFiles);
    }
    case ACTIONS.PERFORMER_FILES_UPLOAD.ERROR: {
      return state.set('loading', false).set('error', action.message);
    }

    default: {
      return state;
    }
  }
}
