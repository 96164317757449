import { connect } from 'react-redux';
import ShowDetail from 'components/Producer/Matrix/Details/ShowDetail';
import { clearShowStats, fetchBookingStatsBegin, fetchDailyCostInvoices } from 'store/actions/show.actions';
import { Show } from 'modules/Show/show.types';
import { getChartData, getShowPermissions, getShowUsersCount } from 'store/selector/show.selector';
import { getUser } from 'store/selector/auth.selector';

const mapStateToProps = (state: Show) => {
  const chartData = getChartData(state);
  const showUserCount = getShowUsersCount(state);
  const showPermissions = getShowPermissions(state);
  const user = getUser(state);
  return { chartData, showUserCount, showPermissions, user };
};

const mapDispatchToProps = {
  fetchStats: fetchBookingStatsBegin,
  fetchDailyCost: fetchDailyCostInvoices,
  clearStatsData: clearShowStats,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowDetail);
