import React, { FunctionComponent, useEffect, useState } from 'react';
import Style from 'components/bgPerformer/SubmissionSearch/Search.module.css';
import Button from 'components/controls/button/button';
import searchSvg from 'assets/svg/search.svg';
import searchWhiteSvg from 'assets/svg/search-white.svg';
import saveSvg from 'assets/svg/save.svg';
import eyeSvg from 'assets/svg/eye.svg';
import { ISearchJobParams } from 'modules/params/param.type';
import DropDownSearch from 'components/controls/select/DropDownSearch';
import { OptionList, Options, ScreenSize, SearchJobParams } from 'modules/general/general.type';
import PerformerSearchFilterTab from 'components/PerformerSearchFilterTabs/PerformerSearchFilterTab';
import { SearchFilterType, SearchFilterTypeList } from 'modules/PerformerList/list.types';
import { temporarySaveSearchFilter } from 'utils/LocalStorageItems';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import SaveFilter from 'components/bgPerformer/ExpandedSearch/SaveFilter/SaveFilter';
import { JobSearchFilterItem, JobSearchFilterList } from 'modules/jobs/types';
import { faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import DateRange from 'components/controls/dateRange/dateRange';
import { getFormattedCurrentDate, getJobSearchActiveFilter } from 'helpers/utils';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { getScreenWidth } from 'store/selector/general.selector';
import searchBlackSvg from 'assets/svg/search-black.svg';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import { SCREEN_SIZE_MOBILE } from 'store/castingPax.constants';

interface SearchProps {
  loading: boolean;
  searchJobBegins: Function;
  prevFilter?: SearchJobParams;
  filterList: JobSearchFilterList;
  nextPage: number;
  sortOrder: number;
  sortJobResult: Function;
}

const Search: FunctionComponent<SearchProps> = (props: SearchProps) => {
  const { loading, searchJobBegins, prevFilter, filterList, nextPage, sortOrder, sortJobResult } = props;
  const [category, setCategory] = useState<string>(prevFilter?.category || '');
  const [location, setLocation] = useState<OptionList>(prevFilter?.location || []);
  const [dateRange, setDateRange] = useState<string>('');
  const [active, setActive] = useState<string>('');
  const [search, setSearch] = useState('');
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);
  const limit: number = 10;

  useEffect(() => {
    if (nextPage > 0) searchHandle(nextPage);
  }, [nextPage]);

  useEffect(() => {
    setCategory(prevFilter?.category || '');
    setLocation(prevFilter?.location || []);
  }, [prevFilter]);

  useEffect(() => {
    let categories: string[] = [];
    let locations: OptionList = [];
    filterList
      .filter((x: JobSearchFilterItem) => x.isChecked)
      .forEach((x: JobSearchFilterItem) => {
        if (x.params.category) categories = categories.concat(x.params.category.split(','));
        if (x.params.location) locations = locations.concat(x.params.location);
      });

    setCategory(Array.from(new Set(categories)).join(','));

    const unqieLocations = locations.filter(
      (x: Options, index: number) => locations.findIndex((y: Options) => y.id === x.id) === index,
    );

    setLocation(unqieLocations);
  }, [filterList]);

  useEffect(() => {
    fetchData(1);
  }, [category, location]);

  useEffect(() => {
    const timeOutId = setTimeout(() => fetchData(1), 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  const handleShowAllJobs = () => {
    temporarySaveSearchFilter({});
    setLocation([]);
    setCategory('');
    setActive('');
    setDateRange('');
    setSearch('');
  };

  const searchHandle = (page: number) => {
    temporarySaveSearchFilter({ category, location });
    fetchData(page);
  };

  const fetchData = (page: number) => {
    let startDate: string = '';
    let endDate: string = '';

    if (dateRange) {
      const arr: string[] = dateRange.split('to');
      if (arr.length > 1) {
        startDate = `${moment(arr[0].trim(), 'DD MM YYYY').format('YYYY-MM-DD')} 00:00:00`;
        endDate = `${moment(arr[1].trim(), 'DD MM YYYY').format('YYYY-MM-DD')} 00:00:00`;
      }
    }

    const params: ISearchJobParams = {
      location: location?.map((x: Options) => x.id).join(','),
      category,
      page,
      startDate,
      endDate,
      active,
      search,
      limit,
    };
    if (!loading) searchJobBegins(params);
  };

  const handleRemoveFilter = (e: React.FormEvent<HTMLDivElement>, value: string, type: string) => {
    if (type === 'category') {
      setCategory(
        category
          .split(',')
          .filter((x: string) => x !== value)
          .join(','),
      );
    } else if (type === 'location' && location) {
      const index = location.findIndex((x: Options) => x.label === value);
      if (index !== -1) {
        location.splice(index, 1);
        setLocation([...location]);
      }
    } else if (type === 'Date') {
      setDateRange('');
    } else if (type === 'active') {
      setActive('');
    }
  };

  const showFilters = () => {
    const arr: SearchFilterTypeList = [];

    if (category) {
      category.split(',').forEach((value: string) => {
        arr.push({
          label: value,
          remove: (event) => {
            handleRemoveFilter(event, value, 'category');
          },
        });
      });
    }
    if (location) {
      location.forEach((opt: Options) => {
        arr.push({
          label: opt.label,
          remove: (event) => {
            handleRemoveFilter(event, opt.label, 'location');
          },
        });
      });
    }
    if (dateRange) {
      console.log(dateRange);
      arr.push({
        label: `Date: ${dateRange}`,
        remove: (event) => {
          handleRemoveFilter(event, dateRange, 'Date');
        },
      });
    }

    if (active) {
      arr.push({
        label: `Active: ${active}`,
        remove: (event) => {
          handleRemoveFilter(event, active, 'active');
        },
      });
    }
    return arr.map((filter: SearchFilterType, index) => <PerformerSearchFilterTab key={index} filter={filter} />);
  };

  const handleDateChange = (start: any, end: any) => {
    setDateRange(`${moment(start).format('DD-MM-YYYY')} to ${moment(end).format('DD-MM-YYYY')}`);
  };
  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };
  const popoverComponent = () => <SaveFilter filterParam={{ category, location }} list={filterList} />;

  const reOrder = (type: number) => {
    if (type !== sortOrder) sortJobResult(type);
  };

  if (currentScreenSize === SCREEN_SIZE_MOBILE) {
    return (
      <>
        <div className="MobileResponsiveView">
          <div className={`${Style.responsiveBoxWrapper}`}>
            <div className={Style.responsiveHeader}>
              <img src={searchBlackSvg} alt="search icon" />
              <p className="mb-0 ml-2">Search</p>
            </div>
            <div className="form-group">
              <DateRange
                startDate={getFormattedCurrentDate('MM-DD-YYYY')}
                endDate={getFormattedCurrentDate('MM-DD-YYYY')}
                handleDateChange={handleDateChange}
              >
                <div className={`d-flex ${Style.dateRangeInput}`}>
                  Date
                  <img src={downArrowSvg} alt="dropdown-arrow" />
                </div>
              </DateRange>
            </div>
            <div className="form-group performerSearchWrapper">
              <DropDownSearch defaultValue="Select" />
            </div>
            <div className="form-group performerSearchWrapper">
              <DropDownSearch defaultValue="Casting Director" />
            </div>
            <div className="form-group performerSearchWrapper">
              <DropDownSearch defaultValue="#BG" />
            </div>
          </div>
          <div className={`d-flex flex-column justify-content-between ${Style.expandedSortWrapper}`}>
            <div className="form-group mb-3 position-relative w-100">
              <input
                type="text"
                placeholder="Search"
                onChange={handleOnChange}
                className={`border-0 form-control ${Style.searchArea}`}
              />
              <FontAwesomeIcon icon={faSearch} className={Style.inputSearchIcon} />
            </div>
            <div className={Style.sortingContentMobile}>
              <p className={`mb-0  ${Style.sortTitleMobile}`}>Sort by</p>
              <div className={Style.searchbarIconsWrapperMobile}>
                <FontAwesomeIcon
                  icon={faChevronUp}
                  onClick={() => reOrder(-1)}
                  onKeyPress={() => reOrder(-1)}
                  role="button"
                  tabIndex={0}
                />
                <FontAwesomeIcon
                  icon={faChevronDown}
                  onClick={() => reOrder(1)}
                  onKeyPress={() => reOrder(1)}
                  role="button"
                  tabIndex={0}
                />
              </div>
            </div>
            <div className={`w-100 d-flex align-items-center justify-content-between ${Style.dateActiveWrapperMobile}`}>
              <DateRange
                startDate={getFormattedCurrentDate('MM-DD-YYYY')}
                endDate={getFormattedCurrentDate('MM-DD-YYYY')}
                handleDateChange={handleDateChange}
              >
                <div className={`position-relative ${Style.sortDateTitle}`}>
                  Date
                  <FontAwesomeIcon icon={faChevronDown} className={Style.dateIcon} />
                </div>
              </DateRange>
              <div className={`performerSearchWrapper activeDropdown position-relative ${Style.activeDropdown}`}>
                <DropDownSearch
                  defaultValue="Active"
                  value={active}
                  handleSelect={setActive}
                  data={getJobSearchActiveFilter()}
                  cssClass="mb-0"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className={Style.topSearchSection}>
        <div className={Style.castingSearchTitle}>
          <img src={searchSvg} alt="" />
          <p className="mb-0 ml-2">Search</p>
        </div>
        <div className="row performerSearchWrapper">
          <div className="col-sm-6">
            <DropDownSearch
              cssClass={Style.marginTopThirty}
              defaultValue="Select"
              optionType="category"
              value={category}
              isMultiSelect
              handleSelect={setCategory}
            />
          </div>
          <div className="col-sm-6">
            <span className="mb-0">&nbsp;</span>
            <DropDownSearch
              cssClass={Style.searchTopMargin}
              defaultValue="Select"
              optionType="location"
              storeObject
              selectedObj={location}
              isMultiSelect
              handleSelect={setLocation}
            />
          </div>
          <div className="col-sm-12 d-flex flex-wrap">{showFilters()}</div>
        </div>
      </div>
      <div className={Style.sortByWrapper}>
        <div className="mb-4">
          <div className={`d-flex justify-content-between ${Style.expandedSortWrapper}`}>
            <p className={`mb-0  ${Style.sortTitle}`}>Sort by</p>
            <DateRange
              startDate={getFormattedCurrentDate('MM-DD-YYYY')}
              endDate={getFormattedCurrentDate('MM-DD-YYYY')}
              handleDateChange={handleDateChange}
            >
              <div className={`d-flex performerSearchWrapper searchSectionWrapper ${Style.sortDateTitle}`}>
                Date
                <FontAwesomeIcon icon={faChevronDown} className={Style.dateIcon} />
              </div>
            </DateRange>
            <div className="d-flex performerSearchWrapper searchSectionWrapper">
              <DropDownSearch
                defaultValue="Active"
                value={active}
                handleSelect={setActive}
                data={getJobSearchActiveFilter()}
              />
            </div>

            <div className={Style.searchbarIconsWrapper}>
              <FontAwesomeIcon
                icon={faChevronUp}
                onClick={() => reOrder(-1)}
                onKeyPress={() => reOrder(-1)}
                role="button"
                tabIndex={0}
              />
              <FontAwesomeIcon
                icon={faChevronDown}
                onClick={() => reOrder(1)}
                onKeyPress={() => reOrder(1)}
                role="button"
                tabIndex={0}
              />
            </div>
            <div className={`form-group mb-0 position-relative ${Style.searchAreaWrapper}`}>
              <input
                type="text"
                placeholder="Search"
                onChange={handleOnChange}
                className={`border-0 form-control ${Style.searchArea}`}
              />
              <FontAwesomeIcon icon={faSearch} className={Style.inputSearchIcon} />
            </div>
          </div>
        </div>
        <div className="col-sm-12 text-right d-flex justify-content-between">
          <Button label="See all jobs" icon={eyeSvg} clickHandler={handleShowAllJobs} cssClass={Style.clearBtn} />
          <PopoverContainer itemComponent={popoverComponent} placement="bottom">
            <div className={`mb-0 ${Style.saveBtndiv}`}>
              <img src={saveSvg} alt="" />
              <span>&nbsp;</span>
            </div>
          </PopoverContainer>
          <Button
            label=""
            icon={loading ? '' : searchWhiteSvg}
            clickHandler={() => {
              searchHandle(1);
            }}
            showLoading={loading}
            cssClass={Style.searchBtn}
          />
        </div>
      </div>
    </>
  );
};

export default Search;
