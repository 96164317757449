import React, { FunctionComponent, useMemo, useState } from 'react';
import DropDownSearch from 'components/controls/select/DropDownSearch';
import Style from 'components/EmailTemplate/EmailTemplate.module.css';
import plusSvg from 'assets/svg/plus.svg';
import Button from 'components/controls/button/button';
import CreateEmailTemplate from 'components/castingDirector/PopOvers/EmailDetail/CreateEmailTemplate';
import { EmailTemplate, EmailTemplateList, Show } from 'modules/Show/show.types';
import { getEmailTemplates } from 'store/selector/show.selector';
import { connect } from 'react-redux';
import { SelectOptionType } from 'modules/PerformerList/list.types';

interface EmailTemplateProps {
  templateId: string;
  handleSelect: Function;
  templateList?: EmailTemplateList;
}

const CEmailTemplate: FunctionComponent<EmailTemplateProps> = (props: EmailTemplateProps) => {
  const { templateId, handleSelect, templateList } = props;
  const [toggleNewTemplate, setToggleNewTemplate] = useState<boolean>(false);

  const handleNewTemplate = () => {
    setToggleNewTemplate(true);
  };

  const getSelectedTemplate = useMemo(() => {
    if (templateId && templateList) {
      const index = templateList.findIndex((x: EmailTemplate) => x.id === templateId);
      if (index !== -1) {
        return { value: templateId, lable: templateList[index].title };
      }
    }
    return { value: 'Select', lable: 'Select' };
  }, [templateId]);

  console.log('EMAIL_TEMPLATE', getSelectedTemplate);
  const handleChange = (item: SelectOptionType) => {
    if (templateList) {
      const template: EmailTemplateList = templateList.filter((x: EmailTemplate) => x.id === item.value);
      if (template.length > 0) {
        handleSelect(template[0]);
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className="performerSearchWrapper col-sm-12 d-flex align-items-center column-gap-20">
          <div className="form-group w-100">
            <DropDownSearch
              cssClass={Style.createInputLabel}
              optionType="emailTemplate"
              storeObject
              value={templateId ? getSelectedTemplate.lable : 'select template'}
              label="Email Template"
              handleSelect={handleChange}
            />
          </div>
          <Button icon={plusSvg} clickHandler={handleNewTemplate} cssClass={Style.requestTextBtn} />
        </div>
      </div>
      {toggleNewTemplate && <CreateEmailTemplate createdFrom="show" closeDialog={setToggleNewTemplate} />}
    </>
  );
};

const mapStateToProps = (state: Show) => {
  const templateList: EmailTemplateList = getEmailTemplates(state);
  return { templateList };
};

export default connect(mapStateToProps, null)(CEmailTemplate);
