/* eslint-disable no-plusplus */
import React, { FunctionComponent, useEffect, useState } from 'react';
import Styled from 'styled-components';
import { getUser } from 'store/selector/auth.selector';
import { connect } from 'react-redux';
import {
  getMatrixData,
  getMatrixSearchSortingOrder,
  getSelectedShow,
  getSelectedShowDate,
  getSelectedShowId,
  getSelectedworkingDayId,
  getShowCalendarDates,
  getShowSelectedDates,
  isMatrixLoading,
  matrixPagination,
} from 'store/selector/show.selector';
import { Show } from 'modules/Show/show.types';
import {
  sortMatrixData,
  getMatrixDataBegin,
  setDatesForFitler,
  searchExistingMatrix,
  rateFilterExistingMatrix,
  fetchMatrixPerformerBegin,
  filterUserMangementMatrix,
  filterDayMatrix,
  getRateListRequest,
} from 'store/actions/show.actions';
import { isArray } from 'lodash';
// import { useStripe } from '@stripe/react-stripe-js';
import { User } from 'modules/user/types';
import FilterComponent from '../filter-tab/FilterTabsWrapper';
import FilterBottom from '../filter-tab/FilterBottom';
import LoadMoreButton from '../common/LoadMoreButton';
import UserGrid from './UserGrid/UserGrid';
import { UserManagementDashboardInterface } from './Interface/DashboardInterface';

const UsermanagementPageCOmponent: FunctionComponent<UserManagementDashboardInterface> = (
  props: UserManagementDashboardInterface,
) => {
  const {
    // showSideNav,
    // showCalendarDates,
    getMatrixDataBegins,
    showId,
    dates,
    matrixData,
    // sortMatrixData,
    // sortingOrder,
    searchMatrix,
    // rateFilterExistingMatrix,
    // pagination,
    // loading,
    filterUserMatrix,
    fetchMatrixPerformers,
    filterDayMatrixPerformers,
    user,
    getRateList,
  } = props;
  const [searchText, setSearchText]: any = useState('');
  const [sort, setSort]: any = useState('');
  const limit = 6;

  useEffect(() => {
    if (showId && dates) {
      getMatrixDataBegins({ date: dates, showId, page: 1, limit });
    }
  }, [showId, dates]);

  useEffect(() => {
    if (isArray(matrixData) && matrixData.length > 0) {
      for (let item = 0; item < matrixData.length; item++) {
        if (!matrixData[item].performers || matrixData[item].performers.length === 0) {
          loadPerformerData(matrixData[item].id);
        }
      }
    }
  }, [matrixData]);

  const loadPerformerData = (id: any) => fetchMatrixPerformers({ workingDayId: id, id: user.id });
  const handleFilter = (item: any) => {
    searchMatrix('');
    filterDayMatrixPerformers('');
    filterUserMatrix(item);
  };

  const handleSearch = (text: string) => {
    filterDayMatrixPerformers('');
    filterUserMatrix('');
    searchMatrix(text);
    setSearchText(text);
  };

  const handleSort = () => {
    setSort('asc');
  };

  const handleDayFilter = (val: any) => {
    filterUserMatrix('');
    searchMatrix('');
    filterDayMatrixPerformers(val);
  };

  useEffect(() => {
    getRateList();
  }, []);

  return (
    <DashboardContainer>
      <Dashboard>
        {/* <FilterContainer> */}
        <FilterComponent handleFilter={handleFilter} handleSearch={handleSearch} handleDayFilter={handleDayFilter} />
        {/* </FilterContainer> */}
        <FilterBottom handleSort={handleSort} />
        {matrixData && <UserGrid matrixData={matrixData} search={searchText} sort={sort} />}
        <LoadMoreButton title="Load More" />
      </Dashboard>
    </DashboardContainer>
  );
};
const DashboardContainer = Styled.div`
  display: flex;
  flex: 1;
  min-height: 1045px;
`;

const Dashboard = Styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 100%;
  // min-width: 1080px;
  flex: 1;
  @media only screen and (max-width: 1199px) {
  // min-width: 950px;
  }
  @media only screen and (max-width: 991px) {
  // min-width: 645px;
  }
`;
const mapStateToProps = (state: Show) => {
  const selectedWorkingDayId = getSelectedworkingDayId(state);
  const selectedShowDate = getSelectedShowDate(state);
  const showCalendarDates = getShowCalendarDates(state);
  const selectedShow = getSelectedShow(state);
  const dates = getShowSelectedDates(state);
  const showId = getSelectedShowId(state);
  const matrixData = getMatrixData(state);
  const sortingOrder = getMatrixSearchSortingOrder(state);
  const pagination = matrixPagination(state);
  const loading = isMatrixLoading(state);
  const user: User = getUser(state);
  const show: Show = getSelectedShow(state);
  return {
    selectedShow,
    showCalendarDates,
    selectedShowDate,
    selectedWorkingDayId,
    dates,
    showId,
    matrixData,
    sortingOrder,
    pagination,
    loading,
    user,
    show,
  };
};

const mapDispatchToProps = {
  getMatrixDataBegins: getMatrixDataBegin,
  sortMatrixData,
  setDatesForFitler,
  searchMatrix: searchExistingMatrix,
  rateFilterExistingMatrix,
  filterUserMatrix: filterUserMangementMatrix,
  fetchMatrixPerformers: fetchMatrixPerformerBegin,
  filterDayMatrixPerformers: filterDayMatrix,
  getRateList: getRateListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsermanagementPageCOmponent);
