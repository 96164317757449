import { connect } from 'react-redux';
import { Show } from 'modules/Show/show.types';
import { getShowForEdit, showImageFileName } from 'store/selector/show.selector';
import CreateShow from 'components/CreateShow/CreateShow';
import {
  getCreateShowRequest,
  getUpdateShowRequest,
  showEditRequest,
  showImageUploadBegin,
} from 'store/actions/show.actions';

const mapStateToProps = (state: Show) => {
  const showData = getShowForEdit(state);
  const uploadedImageName = showImageFileName(state);
  return { showData, uploadedImageName };
};

const mapDispatchToProps = {
  saveShow: getCreateShowRequest,
  updateShow: getUpdateShowRequest,
  showEditRequest,
  showImageUploadBegin,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateShow);
