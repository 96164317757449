import { defaultPagination } from 'helpers/utils';
import { BookingRequestList, JobSearchFilterList } from 'modules/jobs/types';
import {
  IBookingParams,
  ICalendarUpdateParams,
  ICancelRequestParams,
  IIDParams,
  IPerformerId,
  ISearchJobParams,
  ISendJobRequestParam,
  IWorkedDaysParams,
} from 'modules/params/param.type';
import ApiResponse from 'modules/response/response.types';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { generalActions, jobs, showActions } from 'store/actions';
import { bookingRequestBegin } from 'store/actions/job.actions';
import ACTIONS, {
  JOB_STATUS_BOOKED,
  JOB_STATUS_PENDING,
  JOB_STATUS_REQUESTED,
  TOAST_IDS,
  TOAST_TYPE,
} from 'store/castingPax.constants';
import jobApi from 'store/services/job.services';
import showApi from 'store/services/show.services';

function* fetchCastingRequest(state: { type: string; payload: IPerformerId }) {
  try {
    yield put(jobs.getCastingRequest.pending);
    const response: BookingRequestList = yield call(jobApi.fetchCastingRequest, state.payload);
    yield put(jobs.getCastingRequest.success(response));
  } catch (error) {
    yield put(jobs.getCastingRequest.error(error.message));
  }
}

function* fetchBookingRequest(state: { type: string; payload: IPerformerId }) {
  try {
    yield put(jobs.getBookingRequest.pending);
    const response: BookingRequestList = yield call(jobApi.fetchBookingRequest, state.payload);
    yield put(jobs.getBookingRequest.success(response));
  } catch (error) {
    yield put(jobs.getBookingRequest.error(error.message));
  }
}

function* fetchPerformerCalendar() {
  try {
    yield put(jobs.getCalendarDates.pending);
    const response: ApiResponse = yield call(jobApi.fetchPerformerCalendar, {});
    yield put(jobs.getCalendarDates.success(response.data));
  } catch (error) {
    yield put(jobs.getCalendarDates.error(error.message));
  }
}
function* fetchCastingCalls(state: { type: string; payload: IBookingParams }) {
  try {
    yield put(jobs.getCastingCall.pending(state.payload.page));
    const response: ApiResponse = yield call(jobApi.fetchCastingCallRequest, state.payload);
    yield put(jobs.getCastingCall.success(response.data, response.pagination));
  } catch (error) {
    yield put(jobs.getCastingCall.error(error.message));
  }
}

function* fetchCastingUpdate() {
  try {
    yield put(jobs.getCastingUpdates.pending);
    const response: ApiResponse = yield call(jobApi.fetchCastingUpdates);
    yield put(jobs.getCastingUpdates.success(response.data));
  } catch (error) {
    yield put(jobs.getCastingUpdates.error(error.message));
  }
}

function* markedJobStatusRead(state: { type: string; payload: IIDParams }) {
  try {
    yield put(jobs.markedJobUpdateStatus.pending);
    yield call(jobApi.markReadJobUpdateStatus, state.payload);
    yield put(jobs.markedJobUpdateStatus.success(state.payload));
  } catch (error) {
    yield put(jobs.markedJobUpdateStatus.error(error.message));
  }
}

function* cancelRequest(state: { type: string; payload: ICancelRequestParams }) {
  try {
    yield put(jobs.cancelBookingLocally(state.payload));
    yield put(jobs.cancelBooking.pending);
    yield call(jobApi.cancelRequest, state.payload);
    yield put(jobs.cancelBooking.success(state.payload));
    yield call(fetchPerformerCalendar);
  } catch (error) {
    yield put(jobs.cancelBooking.error(error.message));
  }
}

function* sendJobRequest(state: { type: string; payload: IIDParams }) {
  try {
    yield put(jobs.updateJobRequestLocally(state.payload.id));
    yield put(jobs.submitJobRequest.pending);
    const response: ApiResponse = yield call(jobApi.submitJobRequest, state.payload);
    yield put(jobs.submitJobRequest.success(response.data));
  } catch (error) {
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.APPLY_FOR_JOB_ERROR,
        title: error.message || 'Please try again!',
        type: TOAST_TYPE.ERROR,
      }),
    );
    yield put(jobs.submitJobRequest.error(error.message, state.payload));
  }
}

function* callJobRequest(state: { type: string; payload: ISendJobRequestParam }) {
  try {
    yield put(jobs.jobRequestFromCD.pending);
    const response: ApiResponse = yield call(showApi.sendRequestToPerfomer, state.payload);
    yield put(jobs.jobRequestFromCD.success(response.data));
    yield (response.data.jobId = response.data.jobId.id);
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.CASTING_REQUEST_SEND_SUCCESS,
        title: 'Casting request send successfully',
        type: TOAST_TYPE.SUCCESS,
      }),
    );
    yield put(jobs.updateOpenPerformerCalendar(response.data));
    yield put(showActions.updatePerformerCalendar(state.payload, response.data));
  } catch (error) {
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.CASTING_REQUEST_SEND_FAIL,
        title: error.message,
        type: TOAST_TYPE.ERROR,
      }),
    );
    yield put(jobs.jobRequestFromCD.error(error.message));
  }
}

function* acceptRejectRequest(state: { type: string; payload: ICancelRequestParams }) {
  try {
    yield put(jobs.accepCastingRequestLocally(state.payload));
    yield put(jobs.acceptCastingRequest.pending);
    const response: ApiResponse = yield call(jobApi.acceptCastingRequest, state.payload);
    yield put(jobs.acceptCastingRequest.success(response.data));
    yield put(bookingRequestBegin({ status: [JOB_STATUS_BOOKED, JOB_STATUS_REQUESTED, JOB_STATUS_PENDING] }));
  } catch (error) {
    console.log('Error ', error);
    yield put(jobs.acceptCastingRequest.error(error.message));
    yield put(bookingRequestBegin({ status: [JOB_STATUS_BOOKED, JOB_STATUS_REQUESTED, JOB_STATUS_PENDING] }));
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.CASTING_REQUEST_SEND_FAIL,
        title: error.message || 'Request fail',
        type: 'error',
      }),
    );
  }
}

function* searchJobs(state: { type: string; payload: ISearchJobParams }) {
  try {
    yield put(jobs.searchJobs.pending);
    const response: ApiResponse = yield call(jobApi.searchJobs, state.payload);
    yield put(jobs.searchJobs.success(response.data, response.pagination));
  } catch (error) {
    yield put(jobs.searchJobs.error(error.message));
  }
}

function* updateCalendar(state: { type: string; payload: ICalendarUpdateParams }) {
  try {
    console.log('Hell');
    yield put(jobs.updateCalendarStatus.pending);
    const response: ApiResponse = yield call(jobApi.updateCalendarStatus, state.payload);
    yield put(jobs.updateCalendarStatus.success(response.data));
  } catch (error) {
    yield put(jobs.updateCalendarStatus.error(error.message));
  }
}

function* fetchJobSearchFilter() {
  try {
    yield put(jobs.fetchJobSearchFilter.pending);
    const response: JobSearchFilterList = yield call(jobApi.getJobSearchFilter);
    yield put(jobs.fetchJobSearchFilter.success(response));
  } catch (error) {
    yield put(jobs.fetchJobSearchFilter.error(error.message));
  }
}

function* fetchWorkedDays(state: { type: string; payload: IWorkedDaysParams }) {
  try {
    yield put(jobs.fetchPerformerWorkedDays.pending);
    const response: ApiResponse = yield call(jobApi.fetchWorkedDays, state.payload);
    yield put(jobs.fetchPerformerWorkedDays.success(response.data, response.pagination || defaultPagination()));
  } catch (error) {
    yield put(jobs.fetchPerformerWorkedDays.error(error.message));
  }
}
function* fetchJobNotification(state: { type: string; payload: string }) {
  try {
    const response: any = yield call(jobApi.fetchJobNotification, state.payload);
    if (response) yield put(jobs.openJobDetails(response.jobId, response));
  } catch (err) {
    yield console.log(err.message);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.FETCH_CASTING_REQUEST_BEGIN, fetchCastingRequest),
    takeEvery(ACTIONS.FETCH_BOOKING_REQUEST_BEGIN, fetchBookingRequest),
    takeEvery(ACTIONS.FETCH_CALENDAR_REQUEST_BEING, fetchPerformerCalendar),
    takeEvery(ACTIONS.FETCH_CASTING_CALL_REQUEST_BEGIN, fetchCastingCalls),
    takeEvery(ACTIONS.FETCH_CASTING_UPDATE_NEWS_BEGIN, fetchCastingUpdate),
    takeEvery(ACTIONS.SET_CANCEL_BOOKING_BEGIN, cancelRequest),
    takeEvery(ACTIONS.SET_ACCEPT_CASTING_REQUEST_BEGIN, acceptRejectRequest),
    takeEvery(ACTIONS.SET_JOB_UPDATE_STATUS_READ_BEGIN, markedJobStatusRead),
    takeEvery(ACTIONS.SUBMIT_JOB_REQUEST_BEGIN, sendJobRequest),
    takeEvery(ACTIONS.SET_SEARCH_JOBS_BEGIN, searchJobs),
    takeEvery(ACTIONS.CALL_JOB_REQUEST_BEGIN, callJobRequest),
    takeEvery(ACTIONS.UPDATE_CALENDAR_STATUS_BEGIN, updateCalendar),
    takeEvery(ACTIONS.FETCH_JOB_SEARCH_FILTER_BEGIN, fetchJobSearchFilter),
    takeEvery(ACTIONS.FETCH_PERFORMER_WORKED_DAYS_BEGIN, fetchWorkedDays),
    takeEvery(ACTIONS.PERFORMER_JOB_BOOKING_NOTIFICATION_VIEW, fetchJobNotification),
  ]);
}
