/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useState } from 'react';
import dateFormator from 'utils/dateFormattor';
import Styled from 'styled-components';
import { faPencilAlt, faPlus, faPencilRuler } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Show } from 'modules/Show/show.types';

interface IconContainnerInterface {
  background: string;
}
interface ShowInprogressProps {
  // eslint-disable-next-line react/no-unused-prop-types
  show: Show;
}

const InprogressItme: FunctionComponent<ShowInprogressProps> = (prop: ShowInprogressProps) => {
  const [showSideNav, setShowSideNav] = useState(false);
  const newDate = moment(Date()).format('MMM DD, YYYY');
  const { show } = prop;
  return (
    <ItemContainer>
      <TextContainer>
        <StyledTextContainer>{prop?.show.title}</StyledTextContainer>
        <StyledDateContainer>{dateFormator(show.createdAt)}</StyledDateContainer>
      </TextContainer>
      <IconsContainer>
        <StyledIconContainer background="rgba(242, 201, 76, 0.1)">
          <StyledPlus className="" icon={faPlus} />
        </StyledIconContainer>
        <StyledIconContainer background="rgba(73, 211, 96, 0.1)">
          <StyledEdit className="" icon={faPencilAlt} />
        </StyledIconContainer>
      </IconsContainer>
    </ItemContainer>
  );
};

const ItemContainer = Styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding-top:3px;
padding-bottom:6px;
font-size: 11px;
border-bottom: 2px solid #F2F2F2;
margin-left: 10px;
margin-right: 10px;
font-family: 'Montserrat';
`;

const TextContainer = Styled.div`
display: flex;
flex-direction: column;
`;

const IconsContainer = Styled.div`
  display: flex;
  height: 100%;
`;

const StyledIconContainer = Styled.div`
margin-left: 6px;
height: 25px;
display: flex;
width: 25px;
justify-content: center;
align-items: center;
padding:6px;
background: ${(props: IconContainnerInterface) => props.background && props.background};
border-radius: 8px;
`;

const StyledPlus = Styled(FontAwesomeIcon)`
color: #F2C94C;
`;

const StyledEdit = Styled(FontAwesomeIcon)`
color: #49D360;
`;

const StyledDateContainer = Styled.div`
font-size: 12px;
color: #828282;
font-weight: 500;
line-height: 14px;
`;
const StyledTextContainer = Styled.div`
font-size: 14px;
line-height: 24px;
`;

export default InprogressItme;
