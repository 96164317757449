import React from 'react';
import styled from 'styled-components';
import downloadIcon from 'assets/svg/downloadBlueIcon.svg';

interface Props {
  number?: number;
  background?: string;
}

const TaxFileDownload: React.FC<Props> = ({ number, background }) => {
  const handleDownloadClick = () => {
    const blob = new Blob([''], { type: 'application/pdf' });

    const blobURL = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.href = blobURL;
    downloadLink.download = 'empty_file.pdf';
    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(blobURL);
  };

  return (
    <Container background={background}>
      <TextItem>{`# ${number}`}</TextItem>
      <ImageBox src={downloadIcon} alt="download" onClick={handleDownloadClick} />
    </Container>
  );
};

export default TaxFileDownload;

const Container = styled.div`
  margin-left: 5px;
  height: 23px;
  width: 40px;
  padding: 4px;
  border-radius: 8px;
  background: ${(props: Props) => (props.background ? props.background : '#e9f2fd')};
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ImageBox = styled.img`
  background: transparent;
  cursor: pointer;
`;

const TextItem = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2f80edcc;
  font-weight: 500;
  margin-bottom: 0px;
`;
