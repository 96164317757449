import React from 'react';
import Style from './modal.module.css';

interface Props {
  closeModal: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const Modal: React.FC<Props> = (props) => {
  const { closeModal } = props;
  return <div onClick={closeModal} tabIndex={0} role="button" onKeyPress={closeModal} className={`${Style.bgLayer}`} />;
};
