import React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
}

const LoadMoreButton: React.FC<Props> = (props) => {
  const { title } = props;
  return (
    <Container>
      <ButtonBox>{title}</ButtonBox>
    </Container>
  );
};

export default LoadMoreButton;

const ButtonBox = styled.div`
  border: none;
  background: transparent;
  width: 200px;
  height: 40px;
  border: 1px solid #4b4d67;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #4b4d67;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
`;

const Container = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;
