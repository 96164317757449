import React, { FunctionComponent } from 'react';
import Style from 'components/castingDirector/skills/Skills.module.css';
import { PerformerSkill, BOOLEAN_TRUE } from 'modules/user/types';
import DropDownSearch from 'components/controls/select/DropDownSearch';
import deleteIcon from 'assets/svg/close.svg';

interface SkillsProps {
  skill: PerformerSkill;
  handleSkillChange: Function;
  skillClass?: string;
  signUpClass?: string;
  handleDeleteSkill: Function;
}
const SkillViewItem: FunctionComponent<SkillsProps> = (props) => {
  const { skill, handleSkillChange, skillClass, signUpClass, handleDeleteSkill } = props;

  return (
    <>
      <div className={`${Style.skillWrapper}`}>
        <p className={`mb-0 ${Style.skillName} ${skillClass} ${signUpClass}`}>
          {skill.skillCategoryId ? skill.skillCategoryId.title : ''}
        </p>
        <div className={`d-flex align-items-center justify-content-between ${Style.skillDashWrapper}`}>
          <span className={`mb-0 mr-3 ${Style.skillDash}`}>&nbsp; - </span>
          <div className="newSkillSearch">
            <DropDownSearch
              optionType="skillLevel"
              showIcon={BOOLEAN_TRUE}
              value={skill.skillLevel}
              handleSelect={handleSkillChange}
            />
          </div>
        </div>
        <span
          className="mb-0"
          onClick={() => handleDeleteSkill(skill.id)}
          onKeyPress={() => handleDeleteSkill(skill.id)}
          role="button"
          tabIndex={0}
        >
          <img className={`${Style.exclamationMark}`} src={deleteIcon} alt="" />
        </span>
      </div>
    </>
  );
};

export default SkillViewItem;
