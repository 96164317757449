import React, { FunctionComponent, useEffect, useState } from 'react';
import arrowUpSvg from 'assets/svg/up-arrow-filled.svg';
import arrowDownSvg from 'assets/svg/down-arrow-filled.svg';
import imagePlaceHolder from 'assets/images/img-placholder.png';
import Style from 'components/bgPerformer/rental/Rental.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { SelectOptionType } from 'modules/PerformerList/list.types';
import { IProfileFileParam } from 'modules/params/param.type';
import Dialog from 'components/bgPerformer/Images/Dialog';
import { LOADER_STATUS, TOAST_IDS, TOAST_TYPE } from 'store/castingPax.constants';
import { getUser } from 'store/selector/auth.selector';
import { RentalImage, User } from 'modules/user/types';
import { showToast } from 'store/actions/general.actions';
import RentalItem from 'components/bgPerformer/rental/RentalItem';
import { RentalOption, RentalOptionList } from 'modules/general/general.type';

interface RentalProps {
  cssClass?: string;
  rentalOpt: RentalOptionList;
  uploadRentalImage: Function;
  isLoading: boolean;
  removeRentalImage: Function;
  updateRentalType: Function;
}
const Rental: FunctionComponent<RentalProps> = (props: RentalProps) => {
  const { cssClass, rentalOpt, uploadRentalImage, isLoading, removeRentalImage, updateRentalType } = props;
  const dispatch = useDispatch();
  const [imageDialog, setImageDialog] = useState<boolean>(false);
  const [loader, setLoader] = useState<number>(LOADER_STATUS.NA);
  const [selectedRental, setSelectedRental] = useState<string>('');
  const [loaderType, setLoaderType] = useState<string>('');
  const user: User = useSelector(getUser);
  const [userRental, setUserRental] = useState<string[]>([]);
  const [rentalOptions, setRentalOptions] = useState<any>(rentalOpt);

  useEffect(() => {
    if (loader === LOADER_STATUS.COMPLETED && !isLoading) setImageDialog(false);
    if (selectedRental && !isLoading && loaderType) {
      setSelectedRental('');
      setLoaderType('');
    }
  }, [isLoading]);

  useEffect(() => {
    if (user.rentalImages) {
      const data = user.rentalImages.map((r: RentalImage) => r.rentalType);
      setUserRental(data);
      if (user.rentalImages.length === rentalOpt.length) setRentalOptions([]);
      setRentalOptions(rentalOpt.filter((r: RentalOption) => !data.includes(r.label)));
    }
  }, [user.rentalImages]);

  const isValidImageHeightWidth = (file: File) =>
    new Promise<boolean>((resolve) => {
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        if (img.width >= 250 && img.height >= 250) {
          resolve(true);
        }
        resolve(false);
      };
      img.onerror = () => resolve(false);
    });

  const handleUpload = async (file: any, rentalType: SelectOptionType) => {
    if (!file || !file[0]) return;
    const isUpload = await isValidImageHeightWidth(file[0]);
    if (!isUpload) {
      dispatch(
        showToast({
          id: TOAST_IDS.UPLOAD_RENTAL_IMAGE,
          type: TOAST_TYPE.ERROR,
          title: 'Image size exceed to 250*250',
        }),
      );
      return;
    }
    const formData = new FormData();
    formData.append('type', rentalType.label);
    formData.append('imageFile', file[0]);
    const obj: IProfileFileParam = {
      params: formData,
    };
    if (uploadRentalImage) uploadRentalImage(obj);
    setLoader(LOADER_STATUS.COMPLETED);
  };

  const handleDelete = (id: string) => {
    if (!id) return;
    setSelectedRental(id);
    setLoaderType('deleteType');
    removeRentalImage({ rentalId: id });
  };

  const handleChangeType = (id: string, type: string) => {
    setSelectedRental(id);
    setLoaderType('changeType');
    updateRentalType({ id, type });
  };

  return (
    <>
      <div className={cssClass}>
        <div className={`d-flex align-items-center w-100 ${Style.rentalHeader}`}>
          <div className="d-flex align-items-center flex-column">
            <div className={Style.arrowIconsWrapper}>
              <img src={arrowUpSvg} alt="arrow up" />
            </div>
            <div className={Style.arrowIconsWrapper}>
              <img src={arrowDownSvg} alt="arrow down" />
            </div>
          </div>
          <p className="pl-2 mb-0">Rental</p>
        </div>
        <div className={Style.imagesCardWrapper}>
          {user.rentalImages &&
            user.rentalImages.length > 0 &&
            user.rentalImages.map((r: RentalImage, index: number) => (
              <RentalItem
                key={index}
                item={r}
                userRental={userRental}
                rentalOpt={rentalOpt}
                selectedId={selectedRental}
                handleChangeType={handleChangeType}
                handleDelete={handleDelete}
                loader={isLoading}
                loaderType={loaderType}
              />
            ))}
          <div
            className={Style.imagePlaceHolder}
            onClick={() => setImageDialog(!imageDialog)}
            onKeyPress={() => setImageDialog(!imageDialog)}
            role="button"
            tabIndex={0}
          >
            <figure className="mb-0 w-100 text-center">
              <img src={imagePlaceHolder} alt="img-placeholder" />
              <figcaption>Add New Image</figcaption>
            </figure>
          </div>
        </div>
      </div>

      {imageDialog && (
        <Dialog
          fileLoader={isLoading}
          closeDialog={() => setImageDialog(false)}
          fileUploadHandler={handleUpload}
          type="rental"
          selectOptions={rentalOptions}
        />
      )}
    </>
  );
};

export default Rental;
