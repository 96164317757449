import { SkillCategoryList } from 'modules/general/general.type';
import React, { FunctionComponent } from 'react';
import Style from 'components/bgPerformer/skills/DropDown/SkillDropDown.module.css';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import SkillPopover from 'components/bgPerformer/skills/SkillPopover/SkillPopover';
import arrowDownSvg from 'assets/svg/down-arrow.svg';

interface DropDownProps {
  skills: SkillCategoryList;
  setSelectedSkills: Function;
  title: string;
}
const DropDown: FunctionComponent<DropDownProps> = (props) => {
  const { skills, setSelectedSkills, title } = props;

  const loadTitle = (value: string) => {
    switch (value) {
      case 'Skills':
        return <span className={`mb-0 ${Style.skillTitlePlaceholder}`}>Skills</span>;
      default:
        return <span className={`mb-0 ${Style.skillTitle}`}>{value}</span>;
    }
  };

  const itemComponent = () => <SkillPopover list={skills} setSelectedSkills={setSelectedSkills} />;
  return (
    <>
      <PopoverContainer itemComponent={itemComponent} placement="bottom" cssClass="skillsPopoverWidth">
        <div className={Style.mainWrapper}>
          {loadTitle(title)}
          <img src={arrowDownSvg} alt="arrow-down" />
        </div>
      </PopoverContainer>
    </>
  );
};

export default DropDown;
