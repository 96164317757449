import React, { useState } from 'react';
import Styled from 'styled-components';
import PayrollNav from '../Nav/PayrollNav';
import PayrollPerformerItem from './PerformerItem';
import AttachedFilesComponent from './AttachedFile';
import Footer from '../Footer/Footer';

const PayrollPerformer = () => {
  const [showSideNav, setShowSideNav] = useState(false);
  return (
    <PayrollPerformerContainer>
      <PayrollNav setShowSideNav={setShowSideNav} showSideNav={showSideNav} />
      <ContentContainer>
        <PayrollPerformerItem />
      </ContentContainer>
      <AttachedFilesComponent />
      <Footer />
    </PayrollPerformerContainer>
  );
};

const PayrollPerformerContainer = Styled.div`
display: flex;
flex-direction: column;
max-width: 1600px;
min-width:1080px;
background-color: #e5e5e5 !important;
height: 100vh;
margin: 0 auto;
`;

const ContentContainer = Styled.div`
display: flex;
width:100%;
padding-top: 43px;
`;

export default PayrollPerformer;
