import React, { FunctionComponent, useEffect } from 'react';
import ShowLeftNavigation from 'components/ShowLeftNavigation';
import LandingStyle from 'views/castingDirector/landing/landing.module.css';
import Matrix from 'components/Coordinator/Matrix';
import TopNavBar from 'components/TopNavBar/TopNavBar';
import { Route, Switch } from 'react-router-dom';

interface IBgCoordinator {
  getSettingRequest: Function;
}

const BgCoordinator: FunctionComponent<IBgCoordinator> = (props: IBgCoordinator) => {
  const { getSettingRequest } = props;
  useEffect(() => {
    getSettingRequest();
  }, []);
  return (
    <>
      <TopNavBar />
      <div className={LandingStyle.coordinatorMainPage}>
        <div className={LandingStyle.landingMainLayout}>
          <div className={`${LandingStyle.ladingLeftSide}`}>
            <ShowLeftNavigation />
          </div>
          <div className={`ml-0 ml-md-3 ml-lg-3 ml-xl-3 ${LandingStyle.landingRightSide}`}>
            <Switch>
              <Route exact path="/coordinator/home" component={Matrix} />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default BgCoordinator;
