import ModalHeader from 'components/Modal/Header';
import React, { FunctionComponent, useState } from 'react';
import Style from 'components/bgPerformer/skills/Dialog/SkillDialog.module.css';
import DropDown from 'components/bgPerformer/skills/DropDown/index';
import DropDownSearch from 'components/controls/select/DropDownSearch';
import Button from 'components/controls/button/button';
import { BOOLEAN_TRUE } from 'modules/user/types';
import createSvg from 'assets/svg/create.svg';
import saveSvg from 'assets/svg/save.svg';
import { SkillCategory, SkillCategoryList } from 'modules/general/general.type';

interface DialogProps {
  closeDialog: Function;
  handleSaveSkills: Function;
}
const Dialog: FunctionComponent<DialogProps> = (props) => {
  const { closeDialog, handleSaveSkills } = props;

  const [skillLevel, setSkillLevel] = useState<string>('');
  const [selectedSkills, setSelectedSkills] = useState<SkillCategoryList>([]);

  const handleSave = () => {
    handleSaveSkills(selectedSkills, skillLevel);
    closeDialog(false);
  };

  const getSelectedSkillNames = () => {
    if (selectedSkills.length > 0) return selectedSkills.map((x: SkillCategory) => x.title).join(',');
    return 'Skills';
  };

  return (
    <>
      <div className={Style.ContentWrapper}>
        <ModalHeader
          title="Create new skill"
          handleClick={() => {
            closeDialog(false);
          }}
          icon={createSvg}
        />
        <div className={`modal-body ${Style.modalBody}`}>
          <div className={Style.skillNamePopup}>
            <img src={createSvg} alt="" />
            &nbsp; Skill name
          </div>
          <div className="row performerSearchWrapper">
            <div className="col-sm-6">
              <DropDown skills={[]} setSelectedSkills={setSelectedSkills} title={getSelectedSkillNames()} />
            </div>
            <div className="col-sm-6 skillDialogWrapper">
              <DropDownSearch
                optionType="skillLevel"
                showIcon={BOOLEAN_TRUE}
                value={skillLevel}
                handleSelect={setSkillLevel}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-12 d-flex justify-content-center">
              <Button cssClass={Style.saveDialogBtn} label="Save" clickHandler={handleSave} icon={saveSvg} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={Style.bgLayer}
        onClick={() => closeDialog()}
        onKeyPress={() => closeDialog()}
        role="button"
        tabIndex={0}
      />
    </>
  );
};

export default Dialog;
