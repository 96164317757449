import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import React, { FunctionComponent, useMemo, useState } from 'react';
import PerformerProfileStyle from 'components/PerformerProfile/PerformerProfile.module.css';
import ProfileCalender from 'components/ProfileCalender/ProfileCalender';
import ProfileInfo from 'components/PerformerProfile/ProfileInfo';
import { BookingDate, BookingDateList, ImageFolderItem, PerformerImage, User } from 'modules/user/types';
import ButtonSection from 'components/PerformerProfile/ButtonSection/index';
import {
  formatHeight,
  formatWeight,
  getImageThumbnail,
  getName,
  getStatusTitle,
  getUnionClass,
  getUnionValue,
  performerIdInLC,
} from 'helpers/utils';
import DatePopup from 'components/PerformerProfile/DatePopup/DatePopup';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import ImageView from 'components/controls/imageViewer/imageView';
import { PerformerJobs, CalendarItem } from 'modules/jobs/types';
import moment from 'moment';
import { PeformerJobDates } from 'modules/Show/show.types';
import { getJobPostDates } from 'store/selector/show.selector';
import { connect } from 'react-redux';
import {
  CALENDAR_STATUS_NOT_AVAILABLE,
  JOB_STATUS_BOOKED,
  JOB_STATUS_PENDING,
  JOB_STATUS_REQUESTED,
} from 'store/castingPax.constants';
import PhoneInputFormat from 'components/phoneInput/phoneInputFormat';
import mobileGreenSvg from 'assets/svg/mobile-green.svg';
import mailGreenSvg from 'assets/svg/mail-green.svg';

interface PerformerProfileProps {
  profile: User;
  selectedJobDates: PeformerJobDates[];
}
const PerformerProfile: FunctionComponent<PerformerProfileProps> = (props: PerformerProfileProps) => {
  const { profile, selectedJobDates } = props;
  const viewProfile = () => {
    performerIdInLC.save(profile.id);
    window.open('/casting/profile/view');
  };
  //  try
  // State to hold the selected profile image
  const [selectedProfileImage, setSelectedProfileImage] = useState(profile.profilePicUrl || '');
  const handleThumbnailClick = (newImageSrc) => {
    console.log('Clicked thumbnail:', newImageSrc);
    setSelectedProfileImage(newImageSrc);
  };
  //  try
  const getImages = () => {
    const images = profile.bgPerformer?.images || [];
    const index = images.findIndex((x: ImageFolderItem) => x.isDefault === true);
    if (index !== -1) {
      return images[index].images || [];
    }
    return [];
  };

  const getJobDates = useMemo(() => {
    const index: number = selectedJobDates.findIndex((x: PeformerJobDates) => x.userId === profile.id);
    if (index !== -1) return selectedJobDates[index].dates;
    const pos: number = selectedJobDates.findIndex((x: PeformerJobDates) => x.userId === '-1');
    if (pos !== -1) return selectedJobDates[pos].dates;
    return [];
  }, [selectedJobDates]);

  const bookingDates = useMemo(() => {
    const date = moment();
    const arr: BookingDateList = [];
    for (let i = 0; i < 28; i += 1) {
      arr.push({
        id: i.toString(),
        month: date.format('MMM'),
        day: date.format('DD'),
        date: date.format('YYYY-MM-DD'),
        type: '',
        isJobDate: getJobDates.includes(`${date.format('YYYY-MM-DD')} 00:00:00`),
      });
      date.add(1, 'days');
    }

    if (profile) {
      if (profile.bgPerformer && profile.bgPerformer.calendar) {
        profile.bgPerformer.calendar.forEach((c: CalendarItem) => {
          const index = arr.findIndex((x: BookingDate) => c.workDate.includes(x.date));
          if (index !== -1) {
            arr[index].type = getStatusTitle(c.workStatus) || 'pending';
          }
        });
      }
    }
    if (profile.job) {
      profile.job.forEach((b: PerformerJobs) => {
        b.dates.forEach((d: string) => {
          const index = arr.findIndex((x: BookingDate) => d.includes(x.date));
          if (index !== -1 && arr[index].type !== JOB_STATUS_BOOKED) {
            if (b.status === JOB_STATUS_BOOKED) {
              arr[index].type = JOB_STATUS_BOOKED;
            } else if (arr[index].type !== CALENDAR_STATUS_NOT_AVAILABLE) {
              arr[index].type = b.status === JOB_STATUS_REQUESTED ? JOB_STATUS_PENDING : b.status;
            }
          }
        });
      });
    }
    return arr;
  }, [profile, profile.calendar, selectedJobDates]);

  const dateComponent = () => <DatePopup userId={profile.id} dates={bookingDates} startKey={8} />;

  return (
    <div className={`position-relative w-100 mb-3 ${PerformerProfileStyle.profileCard}`}>
      <div className={PerformerProfileStyle.performerId}>
        <p className="mb-0 mt-0">
          <span className={`text-white ${PerformerProfileStyle.afterTitle} ${getUnionClass(profile)}`}>
            {getUnionValue(profile)}
          </span>
        </p>
        <span className={PerformerProfileStyle.permitOnlyText}>Permit only</span>
      </div>
      <div className={`mb-2 pt-3 ${PerformerProfileStyle.profileHalfWrapper}`}>
        <div className={PerformerProfileStyle.halfImageView}>
          <ImageView
            src={selectedProfileImage}
            alt=""
            cssClass={`${PerformerProfileStyle.profileImage} ${PerformerProfileStyle.profileHalfView}`}
            isFullView
          />
          <div className={`mt-2 ${PerformerProfileStyle.smallHalfImageWrapper}`}>
            {getImages().map((image) => (
              <ImageView
                src={getImageThumbnail(image.image)}
                fullImgSrc={image.image}
                key={image.id}
                alt=""
                cssClass={PerformerProfileStyle.profileImageSmall}
                isFullView
                onClick={() => handleThumbnailClick(image.image)}
              />
            ))}
          </div>
        </div>
        <div className={`pt-2 ${PerformerProfileStyle.halfDetailsWrapper}`}>
          <div onClick={viewProfile} onKeyUp={viewProfile} tabIndex={0} role="button">
            <p className={PerformerProfileStyle.profileName}>{getName(profile)}</p>
          </div>
          <div className={PerformerProfileStyle.mobileEmailSection}>
            <ProfileInfo
              labelClass="d-none"
              icon={mailGreenSvg}
              cssClass={PerformerProfileStyle.profileHalfFont}
              label="Email"
              value={profile.email}
            />
            <div style={{ position: 'relative', top: '-10px' }}>
              <PhoneInputFormat
                labelClass="d-none"
                icon={mobileGreenSvg}
                cssClass={PerformerProfileStyle.profileHalfFont}
                label="Phone"
                value={profile.phoneNo}
              />
            </div>
          </div>
          <div className="row ml-0 mr-0">
            <div className="col-sm-6 px-1">
              <ProfileInfo
                cssClass={PerformerProfileStyle.profileHalfFont}
                label="Sex"
                value={profile.bgPerformer?.gender}
              />
            </div>
            <div className="col-sm-6 px-1">
              <ProfileInfo
                cssClass={PerformerProfileStyle.profileHalfFont}
                label="Age"
                value={profile.bgPerformer?.dateOfBirth}
              />
            </div>
            <div className="col-sm-6 px-1">
              <ProfileInfo
                cssClass={PerformerProfileStyle.profileHalfFont}
                label="Weight"
                value={formatWeight(profile.bgPerformer?.weight)}
              />
            </div>
            <div className="col-sm-6 px-1">
              <ProfileInfo
                cssClass={PerformerProfileStyle.profileHalfFont}
                label="Height"
                value={formatHeight(profile.bgPerformer?.height || '')}
              />
            </div>
          </div>
          <div className="showMessageSectionWrapper">
            <ButtonSection
              labelType="halfPageView"
              performer={profile}
              showMessageSection={false}
              calendarDates={bookingDates}
            />
          </div>
        </div>
      </div>
      <div className="position-relative">
        <div className={PerformerProfileStyle.profileCardFooter}>
          <div className="d-flex justify-content-between">
            {bookingDates.map((date: BookingDate, index: number) => {
              if (index < 8) {
                return <ProfileCalender userId={profile.id} key={date.id} date={date} isShowDate={date.isJobDate} />;
              }
              return '';
            })}
            <PopoverContainer itemComponent={dateComponent}>
              <div className={PerformerProfileStyle.calenderDetailBox}>
                <FontAwesomeIcon icon={faEllipsisH} />
              </div>
            </PopoverContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: User) => {
  const selectedJobDates = getJobPostDates(state);
  return { selectedJobDates };
};

export default connect(mapStateToProps, null)(PerformerProfile);
