import React, { FunctionComponent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';
import Style from './CustomInput.module.css';

interface CustomInputProps {
  cssClass?: string;
  placeholder: string;
  type: string;

  value?: string;
  errorMessage?: string;
  handleChange?: Function;
  handleKeyDown?: Function;
  inputChangeHandler?: (event: React.FormEvent<HTMLInputElement>) => void;
  blurHandler?: (event: React.FormEvent<HTMLInputElement>) => void;
}
const CustomInput: FunctionComponent<CustomInputProps> = (props) => {
  const {
    placeholder,
    type,
    value,
    handleChange,
    blurHandler,
    errorMessage,
    inputChangeHandler,
    handleKeyDown,
    cssClass,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (handleChange) handleChange(event.currentTarget.value);
  };
  return (
    <>
      <div className="form-group position-relative">
        <input
          value={value}
          type={type && !showPassword ? type : 'text'}
          className={`${Style.customInput} ${cssClass}`}
          placeholder={placeholder}
          required
          onChange={handleChange ? handleOnChange : inputChangeHandler}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (handleKeyDown) handleKeyDown(e);
          }}
          onBlur={blurHandler}
        />
        {type === 'password' ? (
          <FontAwesomeIcon
            className={Style.eyeIcon}
            icon={showPassword ? faEyeSlash : faEye}
            onClick={showPasswordHandler}
          />
        ) : (
          ''
        )}
        {errorMessage ? <TextErrorMessage message={errorMessage} /> : ''}
      </div>
    </>
  );
};
export default CustomInput;
