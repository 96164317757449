import React, { FunctionComponent, useEffect, useState } from 'react';
import ModalHeader from 'components/Modal/Header';
import Style from 'components/CreateShow/CreateShow.module.css';
import Button from 'components/controls/button/button';
import SearchUser from 'components/SearchUser/SearchUser';
import { useSelector } from 'react-redux';
import { showCreateLoading } from 'store/selector/show.selector';
import UserView from 'components/UserView/UserView';
import { RoleCode, User, UserList } from 'modules/user/types';

import {
  Episode,
  ShowWorkingDay,
  EpisodeList,
  Show,
  ShowCreateParams,
  ShowTypes,
  InvitationList,
  Invitation,
  newEpisode,
  EpisodeDayList,
  newShowDate,
  EmailTemplate,
} from 'modules/Show/show.types';
import { ShowTypesOption } from 'helpers/data/SelectOptions';
import DashboardInput from 'components/controls/input/dashboardInput/dashboardInput';
import DashboardSelectBox from 'components/controls/select/dashboardSelectBox';
import DashboardTextarea from 'components/controls/textArea/dashbaordTextarea/dashboardTextarea';
import Heading from 'components/controls/heading/Heading';
import ShowEpisode from 'components/CreateShow/ShowEpisode/ShowEpisode';
import InvitationView from 'components/CreateShow/InvitationView/InvitationView';
import ShowDateContainer from 'components/CreateShow/ShowDateContainer/ShowDateContainer';
import infoSvg from 'assets/svg/info.svg';
import videoSvg from 'assets/svg/video.svg';
import searchSvg from 'assets/svg/search.svg';
import SearchShow from 'components/CreateShow/SearchShow/SearchShow';
import moment from 'moment';
import showApi from 'store/services/show.services';
import { getCurrentDate, getRoleId } from 'helpers/utils';
import CEmailTemplate from 'components/EmailTemplate/EmailTemplate';
import TextEditor from 'components/controls/textEditor/TextEditor';
import FileUploader from 'components/controls/fileUploader/FileUploader';
import ImageInput from 'components/controls/fileInput/ImageInput/ImageInput';
import { IMAGE_PATH_TYPE, ROLE_DIRECTOR, ROLE_PRODUCTION } from 'store/castingPax.constants';

interface CreateShowProps {
  closeDialog?: Function;
  showData: any;
  saveShow: Function;
  updateShow: Function;
  showEditRequest: Function;
  showImageUploadBegin: Function;
  uploadedImageName: any;
}
const CreateShow: FunctionComponent<CreateShowProps> = (props: CreateShowProps) => {
  const { closeDialog, showData, saveShow, updateShow, showEditRequest, showImageUploadBegin, uploadedImageName } =
    props;
  const [fileAttachements, setFileAttachements] = useState<string[]>(showData.attachements);
  const [showName, setShowName] = useState<string>(showData?.title);
  const [showType, setShowType] = useState<ShowTypes>(showData.showType);
  const [remarks, setRemarks] = useState<string>(showData.remarks || '');
  const [additionalInfo, setAdditionalInfo] = useState<string>(showData.additionalInfo || '');
  const [userList, setUserList] = useState<UserList>(showData.users || []);
  const [episodes, setEpisodes] = useState<EpisodeList>(showData.episodes || []);
  const [dates, setDates] = useState<EpisodeDayList>(showData.showWorkingDays || []);
  const [deleteDates, setDeleteDates] = useState<string[]>([]);
  const [disable, setDisable] = useState<boolean>(true);
  const isLoading = useSelector(showCreateLoading);
  const [invitationList, setInvitationList] = useState<InvitationList>(showData.invitationList || []);
  const [templateId, setTemplateId] = useState(showData.templateId || '');
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate>();
  const [showImage, setShowImage] = useState<string>(showData.imageFile || '');
  const [removeUsers, setRemoveUsers] = useState<string[]>([]);
  const [disableRequest, setDisableRequest] = useState<boolean>(false);
  const [uploadingImage, setUploadingImage] = useState<boolean>(false);
  const [newImageAdded, setNewImageAdded] = useState<boolean>(false);
  const [removeInvitation, setRemoveInvitation] = useState<string[]>([]);
  const [productionName, setProductionName] = useState<string>(showData?.productionName);
  const [productionPhone, setProductionPhone] = useState<string>(showData?.productionPhone);
  const [searchText, setSearchText] = useState<string>('');
  const handleClick = () => {
    if (closeDialog) {
      handleRemoveShowImage();
      closeDialog({});
    }
  };
  const textAreaChangeHandler = (event: React.FormEvent<HTMLTextAreaElement>, stateFun: Function) => {
    stateFun(event.currentTarget.value);
  };

  useEffect(() => {
    if (disableRequest && !isLoading) setDisableRequest(false);
  }, [isLoading]);

  useEffect(() => {
    setDisable(showName?.length === 0);

    if (showType === ShowTypes.SERIES && episodes.length === 0) {
      setDisable(true);
    }
  }, [showName, episodes, showType]);

  useEffect(() => {
    setShowName(showData.title);
    setShowType(showData.showType);
    setEpisodes(showData.episodes || []);
    setDates(showData.showWorkingDays || []);
    setUserList(showData.users || []);
    setRemarks(showData.remarks || '');
    setAdditionalInfo(showData.additionalInfo || '');
    setTemplateId(showData.templateId || '');
    setProductionName(showData.productionName || '');
    setProductionPhone(showData.productionPhone || '');
  }, [showData]);

  useEffect(() => {
    if (selectedTemplate) {
      setTemplateId(selectedTemplate.id);
      setAdditionalInfo(selectedTemplate.templateHtml);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (uploadedImageName) {
      setShowImage(uploadedImageName.image);
      setUploadingImage(false);
      setNewImageAdded(true);
    }
  }, [uploadedImageName]);

  const handleCreate = () => {
    if (disableRequest) return;
    if (showData.isReadOnly || isLoading) {
      return;
    }

    const params: ShowCreateParams = {
      attachements: fileAttachements,
      showId: showData.id,
      title: showName,
      showType,
      remarks,
      additionalInfo,
      userIds: getUserIds(),
      episodes: showType === ShowTypes.FILM ? [] : parseEpisodeDateInUTC(episodes),
      workingDays: showType === ShowTypes.SERIES ? [] : parseWorkingDateInUTC(dates),
      invitationList,
      deleteDates,
      templateId: templateId || undefined,
      imageFile: showImage || undefined,
      removeUser: removeUsers,
      removeInvitation,
      productionName,
      productionPhone,
    };
    setDisableRequest(true);
    setNewImageAdded(false);
    if (showData.isNew && showData.isNew) saveShow(params);
    else updateShow(params);
  };

  const parseEpisodeDateInUTC = (edl: Episode[]) => {
    const list = [...edl];
    return list.map((e: Episode) => {
      const eObj = e;
      eObj.episodeDays = parseWorkingDateInUTC(eObj.episodeDays);
      return eObj;
    });
  };

  const parseWorkingDateInUTC = (edl: EpisodeDayList) => {
    const list = [...edl];
    return list.map((d: ShowWorkingDay) => {
      const dObj = d;
      dObj.date = moment.utc(dObj.date).startOf('day').toISOString();
      return dObj;
    });
  };

  const getUserIds = () =>
    userList.map((u: User) => {
      const obj = {
        id: u.id,
        role: u.role === ROLE_PRODUCTION || u.role === ROLE_DIRECTOR ? getRoleId(u.role as RoleCode) : u.showRole || '',
      };
      return obj;
    });

  const handleRemoveUser = (event: React.FormEvent<HTMLSpanElement>, user: User) => {
    let flag = false;
    const productionRoles = userList.filter(
      (x: User) => x.role === ROLE_PRODUCTION || x.userRole === '606717f8f9d2b991014963f7',
    );
    let userRoles;
    userRoles = userList.filter((r: User) => r.role !== ROLE_PRODUCTION);
    userRoles = userRoles.filter((y: User) => y.userRole !== '606717f8f9d2b991014963f7');
    if (userRoles.length > 0) {
      if (
        productionRoles.length === 1 &&
        (user.role === ROLE_PRODUCTION || user.userRole === '606717f8f9d2b991014963f7')
      ) {
        return;
      }
      flag = true;
    }
    if (productionRoles.length > 1 && userRoles.length === 0) flag = true;

    if (flag) {
      const newList = userList.filter((x: User) => x.id !== user.id);
      const data = removeUsers;
      data.push(user.id);
      setRemoveUsers([...data]);
      setUserList([...newList]);
    }
  };

  const handleNewEpisodes = () => {
    setEpisodes([...episodes, newEpisode(episodes.length + 1)]);
  };

  const getNextDate = (date: string) => {
    const mDate = moment(date).startOf('day');
    return mDate.isBefore() ? moment().startOf('day') : mDate;
  };

  const handleEpisodeDays = (episodeObj: Episode) => {
    const index = episodes.findIndex((s: Episode) => s.id === episodeObj.id);
    if (index !== -1) {
      const prevDays = episodes[index].episodeDays || [];
      const prevDate =
        prevDays.length > 0 ? getNextDate(episodes[index].episodeDays[prevDays.length - 1].date) : moment();
      const ttlDays = prevDays.length + 1;
      const newDayObj: ShowWorkingDay = {
        id: Math.random().toString(),
        episodeId: episodes[index].id,
        title: `Day ${ttlDays}`,
        dayTitle: '',
        date: `${prevDate.add(1, 'days').format('YYYY-MM-DD')} 00:00:00`,
        isNew: true,
        type: 'episode',
        isDelete: false,
      };
      const newEpisodesDays = [...prevDays, newDayObj];
      const prevEpisodes = episodes;
      prevEpisodes[index].episodeDays = [...newEpisodesDays];
      setEpisodes([...prevEpisodes]);
    }
  };

  const handleChangeEpisodeData = async (day: ShowWorkingDay) => {
    const index = episodes.findIndex((s: Episode) => s.id === day.episodeId);

    if (index !== -1) {
      const episodeIndex = episodes[index].episodeDays.findIndex((e: ShowWorkingDay) => e.id === day.id);
      if (episodeIndex !== -1) {
        if (day.isDelete) {
          if (day.isNew) {
            episodes[index].episodeDays.splice(episodeIndex, 1);
          } else {
            try {
              await showApi.deleteWorkingDay({ id: day.id });
              episodes[index].episodeDays.splice(episodeIndex, 1);
              setDeleteDates([...deleteDates, day.id]);
            } catch {
              console.log('Error');
            }
          }
        } else {
          episodes[index].episodeDays[episodeIndex] = day;
        }
        setEpisodes([...episodes]);
      }
    }
  };

  const handleChangeShowDate = async (day: ShowWorkingDay) => {
    const index = dates.findIndex((e: ShowWorkingDay) => e.id === day.id);
    if (index !== -1) {
      if (day.isDelete) {
        if (!day.isNew) {
          try {
            await showApi.deleteWorkingDay({ id: day.id });
            dates.splice(index, 1);
            setDeleteDates([...deleteDates, day.id]);
          } catch {
            console.log('Error');
          }
        } else {
          dates.splice(index, 1);
        }
      } else {
        dates[index] = day;
      }
      setDates([...dates]);
    }
  };

  const handleInvitation = (email: string, inviteRole: string) => {
    const newList = [
      ...invitationList,
      { id: Math.random().toString(), email, status: 'pending', isNew: true, userRole: inviteRole },
    ];
    setInvitationList(newList);
  };

  const handleRemoveInvitation = (e: React.FormEvent<HTMLSpanElement>, invitation: Invitation) => {
    const newList = invitationList.filter((i: Invitation) => i.id !== invitation.id);
    setInvitationList([...newList]);
    if (!invitation.isNew) setRemoveInvitation([...removeInvitation, invitation.id]);
  };

  const handleShowDate = () => {
    if (dates.length === 0) {
      setDates([newShowDate(showData.id || '', 0, getCurrentDate())]);
      return;
    }
    setDates([
      ...dates,
      newShowDate(
        showData.id || '',
        dates.length,
        getNextDate(dates[dates.length - 1].date).format('YYYY-MM-DD HH:mm:ss'),
      ),
    ]);
  };

  const getSaveBtnLabel = () => {
    if (showData.isReadOnly) {
      return 'Request For Permission';
    }

    return showData.isNew && showData.isNew ? 'Create' : 'Update';
  };

  const getShowHeaderLabel = () => (showData.isNew && showData.isNew ? 'Create New' : 'Update Show');

  const handleShowSearch = (show: Show) => {
    const obj = Object.assign(show, { isReadOnly: true });
    showEditRequest(obj);
  };

  const showNameLayout = () => {
    if (showData.isNew || showData.isReadOnly) {
      return (
        <SearchShow
          cssClass="form-group"
          label="Show name"
          setShowName={setShowName}
          handleShowSearch={handleShowSearch}
        />
      );
    }
    return <DashboardInput placeholder="name" value={showName} handleChange={setShowName} />;
  };

  const showSeriesLayout = () => {
    if (showType === ShowTypes.SERIES) {
      return (
        <ShowEpisode
          episodes={episodes}
          handleNewEpisodes={handleNewEpisodes}
          handleEpisodeDays={handleEpisodeDays}
          handleChangeEpisodeData={handleChangeEpisodeData}
        />
      );
    }
    return (
      <div>
        <div className="col-sm-6 mb-3 px-0">
          <ShowDateContainer
            createShowClass={Style.createShowDate}
            dates={dates}
            handleChangeShowDate={handleChangeShowDate}
            handleShowDate={handleShowDate}
          />
        </div>
      </div>
    );
  };

  const handleUploadShowImage = (e: FormData) => {
    setUploadingImage(true);
    showImageUploadBegin({ params: e });
  };

  const handleRemoveShowImage = () => {
    if (newImageAdded) {
      showApi.removeShowFile({ fileName: [showImage], type: 'show' }).catch((err) => {
        console.log(err.message);
      });
    }
    setRemoveUsers([]);
  };

  const handleChangeShowRole = (user: User, showRole: string) => {
    const index = userList.findIndex((x: User) => x.id === user.id);
    if (index !== -1) {
      userList[index].showRole = showRole;
    }
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
  };
  return (
    <div>
      <div className={Style.ContentWrapper}>
        <ModalHeader title={getShowHeaderLabel()} handleClick={handleClick} icon={videoSvg} />
        <div className={`modal-body customScrollBar ${Style.modalBody}`}>
          <div className="mb-3">
            <Heading label="Title" />
          </div>
          <div className="d-flex column-gap-20 flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row">
            <div className="w-100 w-sm-50 w-md-50 w-lg-50 w-xl-50">{showNameLayout()}</div>
            <div className="w-100 w-sm-50 w-md-50 w-lg-50 w-xl-50">
              <div className="position-relative select-box-mt">
                <DashboardSelectBox
                  label="helo"
                  cssClass={Style.noLabel}
                  data={ShowTypesOption}
                  value={showType}
                  handleSelect={setShowType}
                />
              </div>
            </div>
          </div>
          {showSeriesLayout()}
          <div className="row text-left">
            <div className="col-sm-12 mb-3">
              <Heading label="Additional Information" path={infoSvg} />
            </div>
          </div>
          <div className="row text-left mb-2">
            <div className="col-sm-12">
              <div className="form-group">
                <span className={Style.createInputLabel}>Tag Team</span>
                <SearchUser
                  label="Search"
                  addUserInList={setUserList}
                  prevList={userList}
                  handleInvitation={handleInvitation}
                />
              </div>
            </div>
          </div>

          <div className="row text-left">
            <div className="col-md-12">
              {userList
                .filter((u: User) => u.id !== showData.createdBy)
                .map((user: User) => (
                  <UserView
                    handleChangeShowRole={handleChangeShowRole}
                    key={user.id}
                    user={user}
                    icon="REMOVE"
                    handleClick={(e: React.FormEvent<HTMLSpanElement>) => {
                      handleRemoveUser(e, user);
                    }}
                  />
                ))}
            </div>
          </div>
          <div className="row text-left">
            <div className="col-sm-12 d-flex flex-wrap align-items-center">
              {invitationList.map((invitation: Invitation) => (
                <InvitationView
                  key={invitation.id}
                  invitation={invitation}
                  handleClick={(e: React.FormEvent<HTMLSpanElement>) => {
                    handleRemoveInvitation(e, invitation);
                  }}
                />
              ))}
            </div>
          </div>

          <div className="row text-left">
            <div className="col-sm-12">
              <span className={Style.createInputLabel}>Remarks</span>
              <DashboardTextarea
                value={remarks}
                inputChangeHandler={(e: React.FormEvent<HTMLTextAreaElement>) => textAreaChangeHandler(e, setRemarks)}
              />
            </div>
          </div>
          <CEmailTemplate templateId={templateId} handleSelect={setSelectedTemplate} />
          <div className="row text-left">
            <div className="col-sm-12">
              <div className="form-group">
                <span className={Style.createInputLabel}>Additional Information</span>
                <TextEditor text={additionalInfo} setText={setAdditionalInfo} />
              </div>
              <span className={Style.createInputLabel}>Attach Show Image</span>
              <ImageInput
                showImage
                handelFile={handleUploadShowImage}
                profilePic={showData.isNew ? '' : showImage}
                currentImage={showImage}
                type={IMAGE_PATH_TYPE.SHOW}
                loading={uploadingImage}
                error={uploadedImageName ? uploadedImageName.error : ''}
                retry={uploadedImageName && uploadedImageName.error}
              />
              <span className={Style.createInputLabel}>Attach NDA Documents</span>
              <FileUploader
                attachements={fileAttachements}
                handlefileUploader={setFileAttachements}
                paths={[]}
                multiple
              />
              <div className="row text-left">
                <div className="col-sm-12 mb-3">
                  <Heading label="Producer Information" path={infoSvg} />
                </div>
              </div>
              <div className="row text-left mb-2">
                <div className="col-sm-12">
                  <div className="form-group">
                    <span className={Style.createInputLabel}>Production Name</span>
                    <DashboardInput
                      type="text"
                      placeholder="Enter Name"
                      value={productionName}
                      handleChange={setProductionName}
                    />
                    <span className={Style.createInputLabel}>Production Phone</span>
                    <DashboardInput
                      type="tel"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      placeholder="Enter Phone Number"
                      value={productionPhone}
                      handleChange={setProductionPhone}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`modal-footer ${Style.modalFooter}`}>
          <div className="row text-left">
            <Button
              cssClass="fontFourteen font-weight-normal"
              label={getSaveBtnLabel()}
              isDisabled={disable}
              showLoading={isLoading}
              clickHandler={handleCreate}
            />
          </div>
        </div>
      </div>
      <div className={Style.bgLayer} onClick={handleClick} tabIndex={0} role="button" onKeyPress={handleClick} />
    </div>
  );
};

export default CreateShow;
