/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useState } from 'react';
import Styled from 'styled-components';
import ClickiconImage from 'assets/images/newClickArrow.svg';
import { faker } from '@faker-js/faker';
import { randomNumberInRange } from 'helpers/utils';

interface Props {
  error?: any;
}

const ErrorNoteItem: FunctionComponent<Props> = (props) => {
  const { error } = props;
  const getStatusColor = (index: number) => {
    const statusColors: string[] = ['#EB5757', '#F2994A'];
    return statusColors[index];
  };

  const [showSideNav, setShowSideNav] = useState(false);
  return (
    <ItemContainer>
      <TextContainer statusColor={getStatusColor(randomNumberInRange(-1, 2))}>{error}</TextContainer>
      <ClickIcon src={ClickiconImage} />
    </ItemContainer>
  );
};

const ItemContainer = Styled.div`
display: flex;
width: 100%;
align-items:center;
justify-content: space-between;
border-bottom: 1px dashed #E0E0E0;
margin-top: auto;
margin-bottom: auto;
padding: 15px;
padding-left: 1px;
padding-right: 5px;
align-items: center;
cursor:pointer;
`;

const TextContainer = Styled.p<{ statusColor: string }>`
display: flex;
justify-content: flex-start;
width: 100%;
height: 100%;
margin-left: 0px;
align-items: center;
margin-top:auto;
margin-bottom: auto;
font-size: 14px;
font-weight: 400;
font-family: 'Montserrat';
&:before {
    content: ' ';
    height: 6px;
    width: 6px;
    background: ${(props) => (props.statusColor ? props.statusColor : '#F2994A')};
    margin-top:3px;
    margin-right:10px;
    border-radius: 100%;
}
`;

const ClickIcon = Styled.img`
margin-bottom: 12px;
margin-top: auto;
margin-bottom: auto;
`;

export default ErrorNoteItem;
