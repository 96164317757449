import React from 'react';
import styled from 'styled-components';

interface Props {
  message?: string;
  width?: number;
  height?: number;
  open?: boolean;
}

interface PopWrapperProp {
  width?: number;
  height?: number;
  open?: boolean;
  withLabel?: boolean;
}

const TravelPopup: React.FC<Props> = (props) => {
  const { message, height, width, open } = props;
  return (
    <PopWrapper width={width} height={height} open={open}>
      <Paragraph>{message}</Paragraph>
    </PopWrapper>
  );
};

export default TravelPopup;
const PopWrapper = styled.div<PopWrapperProp>`
  position: fixed;
  left: 15px;
  top: 18px;
  height: ${({ height }) => `${height}px`};
  padding: 11px 22px;
  width: ${({ width }) => `${width}px`};
  border-radius: 10px;
  background: white;
  border: 2px solid #e4f0fc;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999;

  &::after {
    content: '';
    position: absolute;
    height: 12px;
    width: 12px;
    left: 12px;
    top: -6px;
    background: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.lightBlue};
    border-right: transparent;
    border-bottom: transparent;
    transform: rotate(45deg);
  }

  & > h3 {
    font-size: 14px;
  }
`;

const Paragraph = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
  color: #828282;
`;
