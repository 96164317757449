/* eslint-disable prettier/prettier */
import React from 'react';
import styled from 'styled-components';
import { ChartItemInfoBox } from 'components/Payroll/common/chart/components';
import IShowData from 'components/Payroll/common/chart/interface/showData.interface';

interface Props {
  enableTextItem?: boolean;
  chartData: IShowData[];
  printRef: any;
}

const ChartGroup: React.FC<Props> = (props) => {
  const { enableTextItem, chartData, printRef } = props;
  const maxValue = chartData.reduce(
    (max, show) =>
      (max > Math.max(Number(show.showBudget.budget), Number(show.showBudget.expense))
        ? max
        : Math.max(Number(show.showBudget.budget), Number(show.showBudget.expense))),
    0,
  );
  return (
    <ChartsContainer ref={printRef}>
      {chartData.map((show, index) => (
        <ChartItemInfoBox key={index} showData={show} maxValue={maxValue} enableTextItem={enableTextItem} />
      ))}
    </ChartsContainer>
  );
};

export default ChartGroup;

const ChartsContainer = styled.div`
  margin: 0px auto 22px;
  padding: 30px 30px 0px;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`;
