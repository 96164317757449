import React from 'react';
import Usermanagement from '../../components/Payroll/Nav/Usermanagement';

const index = () => (
  <div>
    <Usermanagement />
  </div>
);

export default index;
