import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SelectOption from 'components/controls/select/SelectOption';
import Styles from 'components/Payroll/RateManagement/components/rates/rate-card/RateItem.module.css';

interface Props {
  rateName?: string;
  readonly: boolean;
  disabled?: boolean;
  handleChange: any;
  // handleChangeStatus?: Function;
  name: string;
  // statusName?: string;
  // status?: boolean;
  value?: string;
  type?: string;
  customClass: boolean;
  options?: any;
  fieldType?: string;
  icon?: string;
  required?: boolean;
  rateError?: string;
}

const RateItem: React.FC<Props> = (props) => {
  const {
    rateName,
    readonly,
    handleChange,
    // handleChangeStatus,
    name,
    // statusName,
    // status,
    value,
    type,
    customClass,
    options,
    fieldType,
    icon,
    disabled,
    required,
    rateError,
  } = props;
  // const [active, setActive] = useState<boolean>(status);
  // useEffect(() => {
  //   setActive(status);
  // }, [status]);
  // useEffect(() => {
  //   const a = {
  //     name: statusName || '',
  //     value: active,
  //   };
  //   if (handleChangeStatus) handleChangeStatus(a);
  // }, [active]);
  // const clickChangeStatus = () => {
  //   if (!readonly) setActive(!active);
  // };
  return (
    <RateItemContainer customClass={customClass}>
      <FormLabel customClass={customClass}>{`${rateName ? `${rateName}:` : ''}`}</FormLabel>
      {fieldType === 'select' ? (
        <ControlGroup>
          <SelectOption
            label={rateName || ''}
            data={options}
            // handleSelect={(e: any) => handleTemplate(e)}
            handleSelect={(e: any) => handleChange(e)}
            cssClass={Styles.dropdown}
            disabled={readonly}
            val={value}
          />
        </ControlGroup>
      ) : (
        // <ControlGroup customClass={customClass}>
        //   <StyledInput
        //     type={type ? 'number' : 'text'}
        //     value={value}
        //     readOnly={readonly}
        //     name={name}
        //     onChange={(e) => handleChange(e)}
        //     hasIcon={!!icon}
        //     disabled={disabled}
        //   />
        //   {icon && <StyledIcon src={icon} alt="icon" />}
        //   {/* <StyledRateStatus onClick={clickChangeStatus} isActive={active}> */}
        //   {/*  <Status readOnly={readonly} placeholder={active ? 'Active' : 'Activate'} /> */}
        //   {/* </StyledRateStatus> */}
        // </ControlGroup>
        <>
          <ControlGroup customClass={customClass}>
            <StyledInput
              type={type ? 'number' : 'text'}
              value={value}
              readOnly={readonly}
              name={name}
              onChange={(e) => handleChange(e)}
              hasIcon={!!icon}
              required={required}
            />
            {icon && <StyledIcon src={icon} alt="icon" />}
            {/* <StyledRateStatus onClick={clickChangeStatus} isActive={active}> */}
            {/*  <Status readOnly={readonly} placeholder={active ? 'Active' : 'Activate'} /> */}
            {/* </StyledRateStatus> */}
          </ControlGroup>
          <span className="text-danger" style={{ fontSize: '14px' }}>
            {rateError}
          </span>
        </>
      )}
    </RateItemContainer>
  );
};

export default RateItem;

const ControlGroup = styled.div<{ customClass?: boolean }>`
  display: flex;
  align-items: center;
  width: ${({ customClass }) => (customClass ? '100%' : '100%')};
  flex: ${({ customClass }) => (customClass ? 'auto' : '1')};
  // padding-right: 80px;
  position: relative;
`;

const RateItemContainer = styled.div<{ customClass?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${({ customClass }) => (customClass ? '50%' : '100%')};
  margin-bottom: 1rem;
  flex-direction: column;
`;

const FormLabel = styled.h3<{ customClass?: boolean }>`
  margin: 0;
  margin-bottom: 5px;
  margin-right: ${({ customClass }) => (customClass ? '1rem' : '0')};
  min-width: ${({ customClass }) => (customClass ? 'auto' : '190px')};
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #4b4d67;
`;
const StyledIcon = styled.img`
  position: absolute;
  right: 10px;
  // width: 30px;
`;
const StyledInput = styled.input<{ customClass?: boolean; hasIcon?: boolean }>`
  border: 1px solid #e2e2ea;
  flex: 1;
  width: ${({ customClass }) => (customClass ? '100%' : '100%')};
  border-radius: 8px;
  height: 40px;
  // margin-right: 12.1px;
  padding-block: 15px;
  padding-left: 15px;
  padding-right: ${({ hasIcon }) => (hasIcon ? '35px' : '15px')};
  &:active {
    outline: none;
    box-shadow: none;
    border: 1px solid #49d360;
  }
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #49d360;
  }
`;

// const StyledItemContainer = styled.div`
//   position: relative;
//   height: 40px;
//   display: flex;
//   align-items: center;
//   & > h3 {
//     margin-bottom: 0;
//     font-size: 18px;
//     color: #4b4d67;
//     width: 100%;
//   }

//   & > div {
//     position: absolute;
//     left: 97px;
//     display: flex;
//     height: 40px;
//     width: fit-content;
//     align-items: center;
//     justify-content: flex-start;
//     gap: 12.1px;

//     & > input {
//       box-sizing: border-box;
//       height: 40px;
//       width: 225.9px;
//       background: #ffffff;
//       border: 1px solid #f2f2f2;
//       border-radius: 8px;
//       font-family: 'Montserrat';
//       font-style: normal;
//       font-weight: 500;
//       font-size: 14px;
//       color: #828282;
//       padding: 0px 10px;

//       outline: none;
//     }
//   }
// `;

interface StatusProps {
  isActive?: boolean;
  readonly?: boolean;
}

const StyledRateStatus = styled.div<StatusProps>`
  min-width: 94px;
`;

// const Status = styled.input<StatusProps>`
//   font-size: 14px;
//   margin: 0;
//   border: 0;
//   outline: none;
//   cursor: ${({ readonly }) => (readonly ? 'default' : 'not-allowed')};
//   pointer-events: ${({ readonly }) => (readonly ? 'default' : 'none')};
//   &::placeholder {
//     color: ${({ isActive }) => (isActive ? '#49D360' : '#828282')};
//   }
// `;
