// import { composeWithDevTools } from 'redux-devtools-extension';
// import { createStore, applyMiddleware } from 'redux';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import rootSaga from 'store/rootSaga';
import createSagaMiddleware from 'redux-saga';
import rootReducer from 'store/combineReducers';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export const selectPayroll = (state: RootState) => state.payroll;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
