import { connect } from 'react-redux';
import Casting from 'components/bgPerformer/Calls/Casting';
import { JobRequest } from 'modules/jobs/types';
import {
  getCastingCalls,
  getCastingCallsLoading,
  getBookingData,
  castingCallPagination,
} from 'store/selector/job.selector';
import { openJobDetails } from 'store/actions/job.actions';

const mapStateToProps = (state: JobRequest) => {
  const castingRequests = getCastingCalls(state);
  const pagination = castingCallPagination(state);
  const loadingCalls = getCastingCallsLoading(state);
  const bookings = getBookingData(state);

  return { castingRequests, loadingCalls, bookings, pagination };
};

const mapDispatchToProps = {
  openJobDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Casting);
