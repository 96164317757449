import React, { FunctionComponent } from 'react';
import PerformerProfileStyle from 'components/bgPerformer/BasicInfo/BasicInfo.module.css';
import ProfileInfo from 'components/PerformerProfile/ProfileInfo';
import { User } from 'modules/user/types';
import { getRoleFromTitle } from 'helpers/utils';
import PhoneInputFormat from 'components/phoneInput/phoneInputFormat';

interface BasicInfoProps {
  profile: User;
}
const BasicInfoView: FunctionComponent<BasicInfoProps> = (props: BasicInfoProps) => {
  const { profile } = props;

  return (
    <div className="d-flex align-items-center justify-content-start">
      <div className={`${PerformerProfileStyle.imageWrapper}`}>
        <img src={profile.profilePicUrl} alt="" className={PerformerProfileStyle.profileImage} />
      </div>
      <div className={`${PerformerProfileStyle.infoWrapper}`}>
        <div className="d-flex align-items-center justify-content-between">
          <p className={PerformerProfileStyle.profileName}>{`${profile.firstName} ${profile.lastName}`}</p>
          <div className="d-flex align-items-center mb-3" />
        </div>
        <div className={`position-relative ${PerformerProfileStyle.profileCard}`}>
          <div className={`row mb-2 pt-3 ${PerformerProfileStyle.profileCardInner}`}>
            <div className="col-sm-12 pt-2">
              <div className={PerformerProfileStyle.profileInfoWrapper}>
                <div className={PerformerProfileStyle.profileInfoInner}>
                  <ProfileInfo label="Role" value={getRoleFromTitle(profile.role)} />
                </div>
                <div className={PerformerProfileStyle.profileInfoInner}>
                  <ProfileInfo label="City" value={profile?.city} />
                </div>
                <div className={PerformerProfileStyle.profileInfoInner}>
                  <PhoneInputFormat label="Phone" value={profile.phoneNo} />
                </div>
                <div className={PerformerProfileStyle.profileInfoInner}>
                  <ProfileInfo label="Email" value={profile.email} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfoView;
