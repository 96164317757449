import React, { FunctionComponent } from 'react';
import { MatrixAtoms } from 'modules/Show/show.types';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import PerformeRedirectLink from 'components/castingDirector/MatrixItem/PerformerRedirectLink';
import { currencyFormat } from 'helpers/utils';

interface MatrixItemProps {
  atoms: MatrixAtoms;
  index: number;
}
const MatrixGrid: FunctionComponent<MatrixItemProps> = (props: MatrixItemProps) => {
  const { atoms, index } = props;

  const onGridReady = (params: any) => {
    const { api } = params;
    api.hideOverlay();
    api.sizeColumnsToFit();
  };

  const handlePerformerDetail = (e: any) => (
    <PerformeRedirectLink performerId={e.data.performerId} performerName={e.data.performerName} />
  );

  const handleTotalAmount = (params: any) => {
    const { data } = params;

    let ttlAmount: number = 0;
    if (data.totalWageAmount) ttlAmount += data.totalWageAmount;
    if (data.isAllowancesEnable) {
      if (data.rental) ttlAmount += parseInt(data.rental, 10);
      if (data.totalCostumeAmount) ttlAmount += data.totalCostumeAmount;
      if (data.totalMileageAmount) ttlAmount += data.totalMileageAmount;
    }
    return ttlAmount === 0 ? null : currencyFormat(ttlAmount.toFixed(2));
  };

  const handleMileage = (params: any) => {
    const { data } = params;
    if (data.mileage && data.mileageType) return `${data.mileage}/${data.mileageType}`;
    return null;
  };

  return (
    <div key={index} className="inner-col ag-theme-alpine">
      <AgGridReact
        modules={[MenuModule, ClientSideRowModelModule, RangeSelectionModule, ExcelExportModule]}
        frameworkComponents={{
          handleTotalAmount,
          handlePerformerDetail,
          handleMileage,
        }}
        onGridReady={(params) => onGridReady(params)}
        getRowNodeId={(data) => data.id}
        domLayout="autoHeight"
        rowHeight={30}
        headerHeight={40}
        rowData={atoms.performers}
        animateRows
        suppressMultiRangeSelection
        enableFillHandle
        suppressContextMenu={false}
        suppressRowClickSelection
        undoRedoCellEditingLimit={1}
        defaultColDef={{
          flex: 1,
          minWidth: 15,
          resizable: true,
          headerComponentParams: { menuIcon: 'fa-bars' },
        }}
      >
        <AgGridColumn
          field="performerName"
          cellRenderer="handlePerformerDetail"
          sortable
          headerName="Name"
          width={150}
        />
        <AgGridColumn field="signInTime" width={80} />
        <AgGridColumn field="unionNo" headerName="UnionNo" width={90} />
        <AgGridColumn field="rate" headerName="Rate" width={60} />
        <AgGridColumn field="role" width={120} />
        <AgGridColumn field="wrapTime" width={80} />
        <AgGridColumn field="lunch" width={80} />
        <AgGridColumn field="rental" width={80} />
        <AgGridColumn field="mileage" width={80} cellRenderer="handleMileage" />
        <AgGridColumn field="TotalAmount" cellRenderer="handleTotalAmount" width={80} />
      </AgGridReact>
    </div>
  );
};

export default MatrixGrid;
