import styled from 'styled-components';

const StyledVoucher = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: #f2f2f5;
`;

export default StyledVoucher;
