import styled from 'styled-components';

interface Props {
  withLabel?: boolean;
  width?: number;
  height?: number;
  enableInput?: boolean;
  leftRadius?: boolean;
  fontSize?: number;
  fontWeight?: number;
  borderRight?: string;
  labelBorderRight?: string;
}

const StyledFilterTab = styled.div<Props>`
  height: ${({ height }) => (height ? `${height}px` : '60px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  border-top-left-radius: ${({ withLabel }) => (withLabel ? '8px' : '')};
  border-bottom-left-radius: ${({ withLabel }) => (withLabel ? '8px' : '')};
  border-right: ${({ borderRight }) => (borderRight ? `1px solid ${borderRight}` : 'none')};
  background: ${({ theme }) => theme.colors.white};
  border-top-left-radius: ${({ leftRadius }) => (leftRadius ? '8px' : '')};
  border-bottom-left-radius: ${({ leftRadius }) => (leftRadius ? '8px' : '')};
  width: ${({ width }) => (width ? `${width}%` : '')};
  cursor: pointer;

  input {
    display: ${({ enableInput }) => (enableInput ? 'block' : 'none')};
    height: 50px;
    border: none;
    outline: none;
    font-size: 16px;
    line-height: 50px;
    width: 40%;
  }

  h3 {
    width: ${({ withLabel, enableInput }) => (withLabel && enableInput ? '40%' : '')};
    color: ${({ theme }) => theme.colors.secondaryColor};
    font-style: normal;
    font-weight: 500;
    margin-right: 20px;
    margin-bottom: 0px;
    height: 100%;
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  h4 {
    color: #4b4d67;
    font-weight: ${({ fontWeight }) => (fontWeight ? `${fontWeight}` : '500')};
    font-family: 'Montserrat';
    font-style: normal;
    line-height: 24px;
    margin-bottom: 0px;
    padding-left: 15px;
    border-right: ${({ labelBorderRight }) => (labelBorderRight ? `1px solid ${labelBorderRight}` : 'none')};
    height: 100%;
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '20px')};
    display: flex;
    align-items: center;
  }

  h5 {
    width: ${({ withLabel, enableInput }) => (withLabel && enableInput ? '40%' : '100%')};
    color: #bdbdbd;
    font-style: normal;
    font-weight: ${({ fontWeight }) => (fontWeight ? `${fontWeight}` : '4s00')};
    margin-bottom: 0px;
    height: 100%;
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '18px')};
    display: flex;
    align-items: center;
  }

  h2 {
    color: #bdbdbd;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0px;
    height: 100%;
    font-size: 18px;
    display: flex;
    align-items: center;
  }

  input::placeholder {
    color: #bdbdbd;
  }
`;

export default StyledFilterTab;
