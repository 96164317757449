import {
  IPerformerId,
  IBookingParams,
  ICancelRequestParams,
  IIDParams,
  ISearchJobParams,
  ICalendarUpdateParams,
  ISaveJobSearchFilter,
  ITokenVerification,
  IWorkedDaysParams,
  IParamsID,
} from 'modules/params/param.type';
import { apiCall } from 'store/services/service';
import {
  URL_PERFORMER_BOOKING,
  URL_CATTLE_REQUEST,
  URL_ACCEPT_REJECT_JOB,
  URL_UPDATE_JOB_READ_STATUS,
  URL_PERFORMER_BOOKING_UPDATES,
  URL_CANCEL_JOB,
  URL_JOB_REQUEST,
  URL_SEARCH_JOB,
  URL_PERFORMER_CALENDAR,
  URL_ADD_CALENDAR_DATE,
  URL_JOB_SEARCH_FILTER,
  URL_JOB_VIEW_VERIFY_URL,
  URL_FETCH_WORKED_DAYS,
  URL_CANCEL_BOOKING_BY_DIRECTOR,
  URL_ACCEPT_BOOKING_BY_DIRECTOR,
  URL_FETCH_JOB_NOTIFICATION,
} from 'store/services/URL';

export const jobApi = {
  fetchCastingRequest(params: IPerformerId) {
    return apiCall.post({ url: URL_PERFORMER_BOOKING, isAuthToken: true, params });
  },
  fetchBookingRequest(params: IPerformerId) {
    return apiCall.post({ url: URL_PERFORMER_BOOKING, isAuthToken: true, params });
  },
  fetchPerformerCalendar(params: IParamsID) {
    return apiCall.post({ url: URL_PERFORMER_CALENDAR, isAuthToken: true, params });
  },
  fetchCastingCallRequest(params: IBookingParams) {
    return apiCall.post({ url: URL_CATTLE_REQUEST, isAuthToken: true, params });
  },
  fetchCastingUpdates() {
    return apiCall.get({ url: URL_PERFORMER_BOOKING_UPDATES, isAuthToken: true, params: {} });
  },
  submitJobRequest(params: IIDParams) {
    return apiCall.post({ url: URL_JOB_REQUEST, isAuthToken: true, params });
  },
  markReadJobUpdateStatus(params: IIDParams) {
    return apiCall.post({ url: URL_UPDATE_JOB_READ_STATUS, isAuthToken: true, params });
  },
  acceptCastingRequest(params: ICancelRequestParams) {
    return apiCall.post({ url: URL_ACCEPT_REJECT_JOB, isAuthToken: true, params });
  },
  cancelRequest(params: ICancelRequestParams) {
    return apiCall.post({ url: URL_CANCEL_JOB, isAuthToken: true, params });
  },
  cancelBookingByDirector(params: ICancelRequestParams) {
    return apiCall.post({ url: URL_CANCEL_BOOKING_BY_DIRECTOR, isAuthToken: true, params });
  },
  acceptBookingByDirector(params: ICancelRequestParams) {
    return apiCall.post({ url: URL_ACCEPT_BOOKING_BY_DIRECTOR, isAuthToken: true, params });
  },
  searchJobs(params: ISearchJobParams) {
    return apiCall.post({ url: URL_SEARCH_JOB, isAuthToken: true, params });
  },
  updateCalendarStatus(params: ICalendarUpdateParams) {
    return apiCall.post({ url: URL_ADD_CALENDAR_DATE, isAuthToken: true, params });
  },
  saveJobSearchFilter(params: ISaveJobSearchFilter) {
    return apiCall.post({ url: URL_JOB_SEARCH_FILTER, isAuthToken: true, params });
  },

  getJobSearchFilter() {
    return apiCall.get({ url: URL_JOB_SEARCH_FILTER, isAuthToken: true, params: {} });
  },

  verifyToken(params: ITokenVerification) {
    return apiCall.post({ url: URL_JOB_VIEW_VERIFY_URL, isAuthToken: true, params });
  },
  fetchWorkedDays(params: IWorkedDaysParams) {
    return apiCall.post({ url: URL_FETCH_WORKED_DAYS, isAuthToken: true, params });
  },
  fetchJobNotification(params: string) {
    return apiCall.post({ url: URL_FETCH_JOB_NOTIFICATION, isAuthToken: true, params });
  },
};

export default jobApi;
