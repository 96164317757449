import React, { FunctionComponent, useEffect, useState } from 'react';
import Button from 'components/controls/button/button';
import Header from 'components/topHeader/topHeader';
import LoginStyle from 'views/login/login.module.css';
import InputStyle from 'components/controls/input/input.module.css';
import signupCatStyle from 'components/singupCategory/signupCategory.module.css';
import api from 'store/services/auth.services';
import { IResetPassword, IValidateToken } from 'modules/params/param.type';
import Loader from 'components/controls/Loader/Loader';
import { Link, useHistory } from 'react-router-dom';
import CPModal from 'components/Modal/CPModal';
import CustomInput from '../../components/controls/input/customInput/CustomInput';

interface ResetPasswordProps {
  match: any;
}

const ResetPassword: FunctionComponent<ResetPasswordProps> = (props: ResetPasswordProps) => {
  const { match } = props;
  const { id, token } = match.params;
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [rePasswordError, setRePasswordError] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [updating, setUpdating] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (id && token) {
      const params: IValidateToken = {
        id,
        token,
      };
      api
        .validateAuthToken(params)
        .then((res) => {
          setUserId(res);
          setIsValid(true);
          setIsLoading(false);
        })
        .catch(() => {
          setIsValid(false);
          setIsLoading(false);
        });
    }
  }, []);

  const HandleForgot = () => {
    if (password.length === 0) {
      setPasswordError('Please enter password');
      return;
    }

    if (confirmPassword.length === 0) {
      setRePasswordError('Please enter confirm password');
      return;
    }

    if (confirmPassword !== password) {
      setRePasswordError('Password did not match');
      return;
    }

    if (updating) return;

    setUpdating(true);

    const params: IResetPassword = {
      userId,
      token,
      password,
    };
    api
      .resetPassword(params)
      .then(() => {
        setUpdating(false);
        setShowModal(true);
      })
      .catch(() => {
        setUpdating(false);
      });
  };

  const handleBlur = (val: string, resetError: Function) => {
    if (val.length > 0) resetError('');
  };

  const handleModalClose = () => {
    setShowModal(false);
    history.push('/login');
  };

  if (isLoading) {
    return <Loader isSmallView />;
  }

  return (
    <>
      {isValid ? (
        <>
          <Header />
          <div className={signupCatStyle.backgroundWrapper}>
            <div className={signupCatStyle.bgInnerContent}>
              <h1>Recover Password</h1>
              <p className={signupCatStyle.alfterHeading}>
                Your new password must be different from previous used passwords.
              </p>
            </div>
            <div className={LoginStyle.loginWrapper}>
              <CustomInput
                placeholder="New Password"
                type="password"
                errorMessage={passwordError}
                handleChange={setPassword}
                blurHandler={(e: React.FormEvent<HTMLInputElement>) => {
                  handleBlur(e.currentTarget.value, setPasswordError);
                }}
              />
              <CustomInput
                placeholder="Confirm Password"
                type="password"
                errorMessage={rePasswordError}
                cssClass={InputStyle.passwordInput}
                handleChange={setConfirmPassword}
                blurHandler={(e: React.FormEvent<HTMLInputElement>) => {
                  handleBlur(e.currentTarget.value, setRePasswordError);
                }}
              />
              <div className="text-center w-100">
                <Button
                  label="Reset Password"
                  cssClass="login-btn w-25"
                  showLoading={updating}
                  clickHandler={HandleForgot}
                />
              </div>
            </div>
          </div>
          <CPModal isShow={showModal} hideModal={handleModalClose} isOkOnly>
            <p>Password successfully reset</p>
          </CPModal>
        </>
      ) : (
        <div>
          Link expired return to
          <Link to="/login">Login</Link>
        </div>
      )}
    </>
  );
};
export default ResetPassword;
