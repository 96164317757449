import React from 'react';
import styled from 'styled-components';

interface Props {
  showName: string;
  imageUrl: string;
}

const ShowItem: React.FC<Props> = ({ imageUrl, showName }) => (
  <StyledItem>
    <img src={imageUrl} alt="" />
    <p>{showName}</p>
  </StyledItem>
);

export default ShowItem;

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100;

  & > img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }

  & > p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0px;
  }
`;
