import React, { FunctionComponent } from 'react';
import Button from 'components/controls/button/button';
import Style from 'components/bgPerformer/Calender/Calendar.module.css';
import exportSvg from 'assets/svg/export-white.svg';
import { BookingRequest } from 'modules/jobs/types';
import { useDispatch } from 'react-redux';
import { openJobDetails } from 'store/actions/job.actions';

interface CalendarDetailProps {
  item?: BookingRequest;
  handleMouseLeave?: Function;
}
const CalendarDetail: FunctionComponent<CalendarDetailProps> = (props: CalendarDetailProps) => {
  const { item, handleMouseLeave } = props;
  const dispatch = useDispatch();
  const fullDetailHandle = () => {
    if (item) {
      if (item) {
        if (handleMouseLeave) {
          handleMouseLeave();
        }
        document.body.click();
        dispatch(openJobDetails(item.jobId, item));
      }
    }
  };

  if (!item) return <></>;

  return (
    <div className={Style.itemDetailPopoverWrapper}>
      <div className={Style.itemDetailPopoverHeader}>
        <p className="mb-0">{item.jobId.title}</p>
      </div>
      <div className={Style.itemDetailPopoverBody}>
        <div className={Style.popoverLocationWrapper}>
          <p className={`mb-2 ${Style.popoverLocationTitle}`}>Location</p>
          <p className={`mb-0 ${Style.popoverLocationDesc}`}>{item.jobId.location ? item.jobId.location.label : ''}</p>
        </div>
        <div className={Style.popoverLocationWrapper}>
          <p className={`mb-2 ${Style.popoverLocationTitle}`}>Description</p>
          <p className={`mb-3 ${Style.popoverLocationDesc}`}>{item.jobId.remarks}</p>
        </div>
        <div className="text-center">
          <Button
            label="Full Detail"
            clickHandler={fullDetailHandle}
            rightIcon={exportSvg}
            cssClass={Style.popoverDetailBtn}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarDetail;
