import React, { FunctionComponent } from 'react';
import Style from 'components/castingDirector/Images/Image.module.css';
import { PerformerImage, BOOLEAN_TRUE } from 'modules/user/types';
import ImageView from 'components/controls/imageViewer/imageView';

interface PhotoViewProps {
  photo: PerformerImage;
}

const PhotoView: FunctionComponent<PhotoViewProps> = (props: PhotoViewProps) => {
  const { photo } = props;

  return (
    <>
      <div className={Style.imageItem}>
        <ImageView src={photo?.image || ''} alt="" cssClass={Style.image} isFullView={BOOLEAN_TRUE} />
      </div>
    </>
  );
};

export default PhotoView;
