/* eslint-disable import/prefer-default-export */
import MoneyBagIcon from 'assets/images/moneyBag.svg';
import UserIcon from 'assets/images/userButton.svg';
import VoucherIcon from 'assets/images/flower.svg';
import AddShowIcon from 'assets/images/addShow.svg';

const TopButtonData = [
  {
    icon: MoneyBagIcon,
    title: 'Rate Management',
    background: '#E0E0E0',
    color: '#4F4F4F',
  },
  {
    icon: UserIcon,
    title: 'User Management',
    background: '#E1EFE6',
    color: '#49D360',
  },
  {
    icon: VoucherIcon,
    title: 'Voucher Management',
    background: '#DEE9F2',
    color: '#2D9CDB',
  },
  {
    icon: AddShowIcon,
    title: 'Add Show',
    background: 'rgba(245, 225, 234)',
    color: '#EB5757',
  },
];
export { TopButtonData };
