import { Show } from 'modules/Show/show.types';
import React from 'react';
import styled from 'styled-components';
import ShowList from './ShowList';

interface Props {
  showList?: Show[];
}

const ShowDropdown: React.FC<Props> = (props) => {
  const { showList } = props;
  return (
    <StyledWrapper>
      <ShowList showList={showList} />
    </StyledWrapper>
  );
};

export default ShowDropdown;

const StyledWrapper = styled.div`
  background: gray;
  position: absolute;
  top: 65px;
  right: 8px;
  width: 299px;
  color: #202224;
  min-height: 62px;
  border-radius: 10px;
  background: #ffffff;
  border: 2px solid #e4f0fc;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  z-index: 555;

  &::after {
    content: '';
    position: absolute;
    height: 14px;
    width: 14px;
    right: 24px;
    top: -8px;
    background: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.lightBlue};
    border-right: transparent;
    border-bottom: transparent;
    transform: rotate(45deg);
  }
`;
