import { connect } from 'react-redux';
import Matrix from 'components/castingDirector/Matrix';
import {
  getEmailTemplates,
  getMatrixData,
  getSelectedShowId,
  getShowSelectedDates,
  isMatrixLoading,
  matrixPagination,
  getMatrixSearchSortingOrder,
  breakdownPdf,
  breakDownLoading,
} from 'store/selector/show.selector';
import { Show } from 'modules/Show/show.types';
import {
  breakdownBegin,
  clearBreakDownData,
  getEmailTemplateRequest,
  getMatrixDataBegin,
  searchExistingMatrix,
  sendBreakdownBegin,
  setDatesForFitler,
  sortMatrixData,
} from 'store/actions/show.actions';

const mapStateToProps = (state: Show) => {
  const dates = getShowSelectedDates(state);
  const emailTemplates = getEmailTemplates(state);
  const showId = getSelectedShowId(state);
  const matrixData = getMatrixData(state);
  const loading = isMatrixLoading(state);
  const pagination = matrixPagination(state);
  const sortingOrder = getMatrixSearchSortingOrder(state);
  const breakdownPdfSelector = breakdownPdf(state);
  const breakdownLoading = breakDownLoading(state);

  return {
    dates,
    emailTemplates,
    showId,
    matrixData,
    loading,
    pagination,
    sortingOrder,
    breakdownPdfSelector,
    breakdownLoading,
  };
};

const mapDispatchToProps = {
  getEmailTemplateRequest,
  getMatrixDataBegin,
  searchExistingMatrix,
  sortMatrixData,
  setDatesForFitler,
  clearBreakDownData,
  breakDownBegin: sendBreakdownBegin,
  breakdown: breakdownBegin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Matrix);
