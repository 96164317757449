import React, { FunctionComponent } from 'react';
import Style from 'components/dateItem/dateItem.module.css';
import { formateDate } from 'helpers/utils';

interface DatePopupProps {
  dates?: string[];
  cssClass?: string;
  start: number;
  newDates?: string[];
}
const DatePopup: FunctionComponent<DatePopupProps> = (props: DatePopupProps) => {
  const { dates, start, cssClass, newDates } = props;

  const checkDateChange = (date: string) => {
    if (newDates === undefined) return '';
    if (!newDates.includes(date)) return '';
    return Style.redBorder;
  };

  return (
    <div className={`d-flex ${Style.dateBoxPopupWrapper}`}>
      <div className={`d-flex flex-wrap justify-content-start align-items-center ${cssClass}`}>
        {dates?.map((x: string, index: number) => {
          if (index >= start) {
            return (
              <div className={`${Style.dateBoxWrapper} ${checkDateChange(x)}`} key={index}>
                <p className="mb-0">{formateDate(x || '', 'MMM')}</p>
                <h6 className="mb-0">{formateDate(x || '', 'DD')}</h6>
              </div>
            );
          }
          return '';
        })}
      </div>
    </div>
  );
};

export default DatePopup;
