import React, { FunctionComponent } from 'react';
import Style from 'components/bgPerformer/Calender/Calendar.module.css';
import exportGreenSvg from 'assets/svg/export-green.svg';
import { BookingRequest, BookingRequestList } from 'modules/jobs/types';
import { useDispatch } from 'react-redux';
import { openJobDetails } from 'store/actions/job.actions';

interface MultiJobViewProps {
  items?: BookingRequestList;
  handleMouseLeave?: Function;
}
const MultiJobView: FunctionComponent<MultiJobViewProps> = (props: MultiJobViewProps) => {
  const { items, handleMouseLeave } = props;
  const dispatch = useDispatch();
  const fullDetailHandle = (b: BookingRequest) => {
    if (handleMouseLeave) {
      handleMouseLeave();
    }
    document.body.click();
    dispatch(openJobDetails(b?.jobId, b));
  };

  return (
    <div className={Style.itemDetailPopoverWrapper}>
      <div className={Style.itemDetailPopoverBody}>
        <div className={`mt-15 ${Style.listingPopupContent}`}>
          {items &&
            items.map((b: BookingRequest) => (
              <ul className="d-flex justify-content-between align-items-center list-unstyled" key={b.id}>
                <li>
                  <h5>{b.jobId.title}</h5>
                  <p className="mb-0">{b.jobId.location ? b.jobId.location.label : ''}</p>
                </li>
                <li>
                  <p className={`mb-0 d-flex align-items-center ${Style.dayEpisodeTitle}`} />
                </li>
                <li>
                  <div
                    onClick={() => fullDetailHandle(b)}
                    onKeyDown={() => fullDetailHandle(b)}
                    role="button"
                    tabIndex={0}
                  >
                    <img src={exportGreenSvg} alt="" />
                  </div>
                </li>
              </ul>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MultiJobView;
