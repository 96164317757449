import createSelector from 'utils/reselect';

const rateStateData = (state: any) => {
  const { rate } = state;
  return rate || {};
};

export const getRateValues = createSelector(rateStateData, (rate) => rate.get('values') || {});

export const getRateTitle = createSelector(rateStateData, (rate) => rate.get('rateValues') || '');

export const showLoading = createSelector(rateStateData, (rate) => rate.get('loading'));
