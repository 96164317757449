import React, { FunctionComponent } from 'react';
import Style from 'components/bgPerformer/Calender/Calendar.module.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { BookingDate } from 'modules/user/types';
import PopoverStickOnHover from 'components/controls/Popover/PopoverStick';

interface PerformerCalenderProps {
  bookingObj: BookingDate;
}
const PerformerCalender: FunctionComponent<PerformerCalenderProps> = (props: PerformerCalenderProps) => {
  const { bookingObj } = props;
  const statusStr = 'pending,requested,booked';

  const getStatusColor = () => {
    switch (bookingObj?.type) {
      case 'requested':
      case 'pending':
        return Style.pending;
      case 'booked':
        return Style.booked;
      case 'available':
        return Style.available;
      case 'notavailable':
        return Style.notavailable;
      default:
        return '';
    }
  };

  if (bookingObj?.type && statusStr.includes(bookingObj?.type)) {
    return (
      <>
        <PopoverStickOnHover item={bookingObj} status={bookingObj.type}>
          <div className={Style.calenderBox}>
            <span className={Style.monthTitle}>{bookingObj?.month}</span>
            <p className={`mb-0 mt-1 ${getStatusColor()} ${Style.monthDate}`}>{bookingObj?.day}</p>
          </div>
        </PopoverStickOnHover>
      </>
    );
  }

  return (
    <>
      <div className={Style.calenderBox}>
        <span className={Style.monthTitle}>{bookingObj?.month}</span>
        <p className={`mb-0 mt-1 ${getStatusColor()} ${Style.monthDate}`}>{bookingObj?.day}</p>
      </div>
    </>
  );
};

export default PerformerCalender;
