import React, { FunctionComponent, useEffect, useState } from 'react';
import Style from 'components/controls/MultiSelectList/MultiSelectList.module.css';
import DashboardInput from 'components/controls/input/dashboardInput/dashboardInput';
import { SkillCategory, SkillCategoryList } from 'modules/general/general.type';
import SkillItem from 'components/bgPerformer/skills/SkillPopover/SkillItem';

interface MultiSelectListProps {
  list: SkillCategoryList;
  setSelectedSkills: Function;
}
const SkillPopover: FunctionComponent<MultiSelectListProps> = (props) => {
  const { list, setSelectedSkills } = props;
  const [search, setSearch] = useState<string>('');
  const [skillList, setSkillList] = useState<SkillCategoryList>(list);
  const [searchList, setSearchList] = useState<SkillCategoryList>([]);

  useEffect(() => {
    const selectedIds: SkillCategory[] = [];
    skillList.forEach((s: SkillCategory) => {
      let parentNotAdded = true;
      if (s.subCategories && s.subCategories.length > 0) {
        s.subCategories.forEach((sub: SkillCategory) => {
          if (sub.isChecked) {
            selectedIds.push(sub);
            parentNotAdded = false;
          }
        });
      }
      if (parentNotAdded && s.isChecked) {
        selectedIds.push(s);
      }
    });
    console.log('SKILLPOPOVER', selectedIds);
    setSelectedSkills(selectedIds);
  }, [skillList]);

  useEffect(() => {
    const filterList: SkillCategoryList = [];
    if (search.length === 0) {
      setSearchList(filterList);
      return;
    }

    skillList.forEach((sx: SkillCategory) => {
      if (sx.title.toLowerCase().includes(search.toLowerCase())) {
        filterList.push({
          id: sx.id,
          title: sx.title,
          parentCategory: sx.parentCategory,
          subCategories: [],
          isChecked: sx.isChecked,
        });
      }
      if (sx.subCategories) {
        sx.subCategories.forEach((subx: SkillCategory) => {
          if (subx.title.toLowerCase().includes(search.toLowerCase())) {
            filterList.push({
              id: subx.id,
              title: subx.title,
              parentCategory: subx.parentCategory,
              subCategories: [],
              isChecked: subx.isChecked,
            });
          }
        });
      }
    });
    setSearchList(filterList);
  }, [search]);

  const handleOnChange = (obj: SkillCategory, subObj: SkillCategory) => {
    const prevSkill = skillList;
    const index = skillList.findIndex((x: SkillCategory) => x.id === obj.id);
    if (index !== -1) {
      const selectedSkill = prevSkill[index];
      if (!subObj) {
        selectedSkill.isChecked = selectedSkill.isChecked ? !selectedSkill.isChecked : true;
      }

      if (subObj && selectedSkill.subCategories) {
        const subIndex = selectedSkill.subCategories.findIndex((sk: SkillCategory) => sk.id === subObj.id);
        if (subIndex !== -1) {
          selectedSkill.subCategories[subIndex].isChecked = !selectedSkill.subCategories[subIndex].isChecked;
        }
        const subSkillLen = selectedSkill.subCategories.length;
        const isCheckSubSkill = selectedSkill.subCategories.filter((i: SkillCategory) => i.isChecked === true);
        if (subSkillLen === isCheckSubSkill.length) selectedSkill.isChecked = true;
        else selectedSkill.isChecked = false;
      } else if (selectedSkill.subCategories) {
        selectedSkill.subCategories.map((subSkill: SkillCategory) => {
          const skillObj = subSkill;
          skillObj.isChecked = selectedSkill.isChecked;
          return skillObj;
        });
      }
      prevSkill.splice(index, 1, selectedSkill);
      setSkillList([...prevSkill]);
    }
  };

  const handleFilterChange = (selSkill: SkillCategory) => {
    console.log('Value ', selSkill);
    const prevSearch = searchList;
    const index = searchList.findIndex((s: SkillCategory) => s.id === selSkill.id);
    if (index !== -1) {
      const prevSkill = searchList[index];
      prevSkill.isChecked = prevSkill.isChecked ? !prevSkill.isChecked : true;
      prevSearch.splice(index, 1, prevSkill);
      setSearchList([...prevSearch]);
    }
    const prevSkill = skillList;
    const pos = prevSkill.findIndex((x: SkillCategory) => x.id === selSkill.id);
    if (pos !== -1) {
      const selectedSkill = prevSkill[pos];
      selectedSkill.isChecked = selectedSkill.isChecked ? !selectedSkill.isChecked : true;
      prevSkill.splice(pos, 1, selectedSkill);
      setSkillList([...prevSkill]);
    } else {
      prevSkill.map((x: SkillCategory) => {
        const parentSkill = x;
        if (x.subCategories && x.subCategories.length > 0) {
          const subIndex = x.subCategories.findIndex((sx: SkillCategory) => sx.id === selSkill.id);
          if (subIndex !== -1) {
            const prevSubSkill = x.subCategories;
            prevSubSkill[subIndex].isChecked = !prevSubSkill[subIndex].isChecked;
            parentSkill.subCategories = [...prevSubSkill];
          }
        }
        return parentSkill;
      });
      setSkillList([...prevSkill]);
    }
  };

  const loadList = () => {
    if (searchList && searchList.length > 0) {
      return (
        searchList &&
        searchList.map((skill: SkillCategory) => (
          <SkillItem key={skill.id} skill={skill} handleOnChange={handleFilterChange} />
        ))
      );
    }

    return (
      skillList &&
      skillList.map((skill: SkillCategory) => (
        <SkillItem key={skill.id} skill={skill} handleOnChange={handleOnChange} />
      ))
    );
  };

  return (
    <div className={Style.multiSelectWrapper}>
      <DashboardInput cssClass={Style.searchAddList} handleChange={setSearch} placeholder="Search" />

      <div className="p-2">
        <ul className={`list-unstyled customScrollBar ${Style.listingWrapper}`}>{loadList()}</ul>
      </div>
    </div>
  );
};

export default SkillPopover;
