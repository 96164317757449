import { connect } from 'react-redux';
import { Show, ShowDayJob, ShowTopCalendarTypeList } from 'modules/Show/show.types';
import {
  searchPerformerRequest,
  clearSearchResult,
  setPerformerViewMode,
  sortPerformerResult,
  searchExistingPerformer,
  setPerformerTab,
} from 'store/actions/show.actions';
import {
  getBgLoadMoreCounter,
  getPerformerSearchSortingOrder,
  getShowCalendarDates,
  getShowJob,
  getShowSelectedDates,
  performerSearchLoading,
  performerSearchPagination,
  getPerformerTab,
} from 'store/selector/show.selector';
import FindPerformerContainer from 'containers/FindPerformerContainer/FindPerformerContainer';
import { RentalOptionList } from 'modules/general/general.type';
import { getRentalOpt } from 'store/selector/general.selector';

const mapStateToProps = (state: Show) => {
  const isLoading: boolean = performerSearchLoading(state);
  const selectedJob: ShowDayJob = getShowJob(state);
  const showDates: ShowTopCalendarTypeList = getShowCalendarDates(state);
  const selcetedDates: string[] = getShowSelectedDates(state);
  const pagination = performerSearchPagination(state);
  const isLoadMore: number = getBgLoadMoreCounter(state);
  const sortingOrder: number = getPerformerSearchSortingOrder(state);
  const performerTab: string = getPerformerTab(state);
  const rentalOpt: RentalOptionList = getRentalOpt(state);
  return {
    isLoading,
    selectedJob,
    showDates,
    selcetedDates,
    pagination,
    isLoadMore,
    sortingOrder,
    performerTab,
    rentalOpt,
  };
};

const mapDispatchToProps = {
  searchPerformerRequest,
  clearSearchResult,
  setPerformerViewMode,
  sortPerformerResult,
  searchExistingPerformer,
  setPerformerTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(FindPerformerContainer);
