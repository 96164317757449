import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import DownArrow from 'components/Payroll/common/DownArrow';
import { Spinner } from 'react-bootstrap';
import plusSvg from 'assets/svg/plus.svg';
import clockSvg from 'assets/svg/clock-icon.svg';
import deleteSvg from 'assets/svg/delete-white.svg';
import { getRateTitle } from 'store/selector/rate.selector';
import dollarIcon from 'assets/svg/dollar-black.svg';
import percentageIcon from 'assets/svg/percentage.svg';
import Select, { components } from 'react-select';
import { hoursOptionsData, templatesContent, lunchOptions, categoriesOptions } from 'helpers/data/rates';
import Styles from './RateCardItemNew.module.css';
import RateItem from './RateItem';
import Button from '../../../../../controls/button/button';

interface Props {
  readonly: boolean;
  rateId: string;
  setRates: Function;
  viewRate: Function;
  rates: any;
  loading: boolean;
  rateTitle: string;
  rateError?: string;
}
const RateCardItemNew: React.FC<Props> = (props) => {
  const { readonly, setRates, rates, rateId, loading, rateTitle, rateError } = props;
  const [rate, setRate] = useState(rateTitle || '');
  const [open, setOpen] = useState<boolean>(true);
  const [openRateBumps, setOpenRateBumps] = useState<boolean>(true);
  const [openCostumes, setOpenCostumes] = useState<boolean>(true);
  const [openCustomRates, setOpenCustomRates] = useState<boolean>(true);
  const [openTravelTime, setOpenTravelTime] = useState<boolean>(true);
  const [isPercentages, setIsPercentages] = useState<boolean>(true);
  const [isGst, setIsGst] = useState<boolean>(true);
  const [isVacation, setIsVacation] = useState<boolean>(true);
  const [isShowData, setIsShowData] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState({ name: '' });
  const [addNewRow, setAddNewRow] = useState<{}[]>([]);
  const toggleOpen = () => {
    setOpen((currentVal) => !currentVal);
  };
  useEffect(() => {
    if (selectedValue.name === 'NON UNION') {
      setIsShowData(true);
      setOpenCostumes(false);
      setOpenTravelTime(false);
      setIsGst(false);
    } else {
      setIsShowData(false);
      setOpenCostumes(true);
      setOpenTravelTime(true);
      setIsGst(true);
    }
  }, [selectedValue]);
  const toggleRateBumps = () => {
    setOpenRateBumps((currentVal) => !currentVal);
  };
  const toggleCostumes = () => {
    setOpenCostumes((currentVal) => !currentVal);
  };
  const toggleCustomRates = () => {
    setOpenCustomRates((currentVal) => !currentVal);
  };
  const toggleTravelTime = () => {
    setOpenTravelTime((currentVal) => !currentVal);
  };
  const togglePercentages = (e: any) => {
    setIsPercentages((currentVal) => !currentVal);
  };
  const toggleGst = (e: any) => {
    setIsGst((currentVal) => !currentVal);
  };
  const toggleVacation = (e: any) => {
    setIsVacation((currentVal) => !currentVal);
  };
  const handleChange = (e: any) => {
    setSelectedValue(e);
    if (typeof e === 'string') {
      setRates({ ...rates, template: e });
    } else {
      setRates({ ...rates, [e.target.name]: e.target.value });
    }
  };
  const handleTemplateChange = (e: any) => {
    setSelectedValue(e);
    setRates({ ...rates, template: e });
  };
  const handleLunchOptionChange = (e: any) => {
    setSelectedValue(e);
    setRates({ ...rates, lunchOption: e });
  };

  const handleRateTitle = (e: any) => {
    setRate(e.value);
  };

  useEffect(() => {
    setRates({ ...rates, rateName: rate });
  }, [rate]);

  const handleMPHourChange = (e: any) => {
    setSelectedValue(e);
    setRates({ ...rates, mpHour: e });
  };

  const handleCategoryChange = (e: any) => {
    setSelectedValue(e);
    setRates({ ...rates, categories: e });
  };

  useEffect(() => {}, [rates]);
  const handleAddNewRow = () => {
    setAddNewRow([...addNewRow, {}]);
  };
  const handleRemoveNewRow = () => {
    setAddNewRow((prevData) => prevData.slice(0, prevData.length - 1));
  };
  useEffect(() => {
    setRate(rateTitle || '');
  }, [rateTitle]);
  console.log('selectedValue.name', selectedValue.name);
  console.log('isShowData', isShowData);
  return (
    <StyledRateCardItems>
      <FieldGroup>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <RateItem
              rateName="Rate Name"
              value={rate}
              readonly={false}
              name="rateName"
              rateError={rateError}
              handleChange={(e: any) => handleRateTitle(e.target)}
              // handleChange={handleChange}
              customClass={false}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 mb-sm-2 mb-md-2 mb-lg-0">
            <StyledSelectLabel>templates:</StyledSelectLabel>
            <div className="performerSearchWrapper rateManagementSelect">
              <Select
                className={`${rates.template !== '' ? 'selected' : ''}`}
                name="template"
                value={rates.template}
                onChange={handleTemplateChange}
                options={templatesContent}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <RateItem
              rateName="Minimum Hours"
              value={`${rates.minimumHours || ''}`}
              readonly={false}
              name="minimumHours"
              handleChange={handleChange}
              customClass={false}
              icon={clockSvg}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <RateItem
              rateName="Hourly Rate"
              value={`${rates.hourlyRate || ''}`}
              type="number"
              readonly={false}
              name="hourlyRate"
              handleChange={handleChange}
              customClass={false}
              icon={dollarIcon}
            />
          </div>
        </div>
        <StyledAdvancedMenu>
          <AdvancedMenuHeading>
            <p className={`mb-0 ${Styles.toggleHeading}`}>Over Time</p>
            <div className="custom-control custom-switch mb-3 position-relative" style={{ top: '-5px' }}>
              <input
                type="checkbox"
                name="overTime"
                className="custom-control-input"
                id="overTime"
                onChange={toggleOpen}
                checked={open}
              />
              <label className="custom-control-label" htmlFor="overTime" />
            </div>
            {/* <DownArrow toggleIcon={open} /> */}
            {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
          </AdvancedMenuHeading>
        </StyledAdvancedMenu>
        {open && (
          <>
            {addNewRow.map((item, index) => (
              <StyledToggleOverTime key={index}>
                <RateItem
                  rateName="Starts"
                  value={`${rates.travel || ''}`}
                  readonly={false}
                  name="starts"
                  handleChange={handleChange}
                  customClass
                />
                <RateItem
                  rateName="Rate"
                  value={`${rates.travel || ''}`}
                  readonly={false}
                  name="rate"
                  handleChange={handleChange}
                  customClass
                />
                {index === -1 ? (
                  <Button cssClass={Styles.btnWrapper} icon={plusSvg} clickHandler={handleAddNewRow} />
                ) : (
                  <Button cssClass={Styles.seriesMinusBtn} icon={deleteSvg} clickHandler={handleRemoveNewRow} />
                )}
              </StyledToggleOverTime>
            ))}
            <StyledToggleOverTime>
              <RateItem
                rateName="Starts"
                value={`${rates.travel || ''}`}
                readonly={false}
                name="starts"
                handleChange={handleChange}
                customClass
                icon={clockSvg}
              />
              <RateItem
                rateName="Rate"
                value={`${rates.travel || ''}`}
                readonly={false}
                name="rate"
                handleChange={handleChange}
                customClass
                icon={dollarIcon}
              />
              <Button cssClass={Styles.btnWrapper} icon={plusSvg} clickHandler={handleAddNewRow} />
            </StyledToggleOverTime>
          </>
        )}
        <AdvancedMenuHeading>
          <p className={`mb-0 ${Styles.toggleHeading}`}>Rate Bumps</p>
          <div className="custom-control custom-switch mb-3 position-relative" style={{ top: '-5px' }}>
            <input
              type="checkbox"
              name="isRateBumps"
              className="custom-control-input"
              id="isRateBumps"
              onChange={toggleRateBumps}
              // checked={enableNotification}
            />
            <label className="custom-control-label" htmlFor="isRateBumps" />
          </div>
          {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
        </AdvancedMenuHeading>
        {openRateBumps && (
          <>
            <StyledToggleOverTime>
              <RateItem
                value={`${rates.rateBumpsPercentage || ''}`}
                readonly={false}
                name="rateBumpsPercentage"
                handleChange={handleChange}
                customClass
                icon={percentageIcon}
              />
              <RateItem
                value={`${rates.rateBumpsDollars || ''}`}
                readonly={false}
                name="rateBumpsDollars"
                handleChange={handleChange}
                customClass
                icon={dollarIcon}
              />
            </StyledToggleOverTime>
          </>
        )}
        <StyledSelectLabel>Lunch Options:</StyledSelectLabel>
        <div className="performerSearchWrapper rateManagementSelect mb-3">
          <Select
            className={`${rates.lunchOption !== '' ? 'selected' : ''}`}
            name="lunchOption"
            value={rates.lunchOption}
            onChange={handleLunchOptionChange}
            options={lunchOptions}
          />
        </div>
        {openCostumes && (
          <>
            <StyledFormHeading>Meal Penalty:</StyledFormHeading>
            <StyledToggleOverTime>
              <RateItem
                // rateName="In"
                value={`${rates.mpPercentage || ''}`}
                readonly={false}
                name="mpPercentage"
                handleChange={handleChange}
                customClass
                icon={percentageIcon}
              />
              <RateItem
                // rateName="In"
                value={`${rates.mpDollars || ''}`}
                readonly={false}
                name="mpDollars"
                handleChange={handleChange}
                customClass
                icon={dollarIcon}
              />
            </StyledToggleOverTime>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6 mb-md-2 mb-lg-0">
                <StyledSelectLabel>Starts at what hour:</StyledSelectLabel>
                <div className="performerSearchWrapper rateManagementSelect">
                  <Select
                    className={`${rates.mpHours !== '' ? 'selected' : ''}`}
                    name="mpHours"
                    value={rates.mpHours}
                    onChange={handleMPHourChange}
                    options={hoursOptionsData}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <RateItem
                  rateName="Rate per Change"
                  value={`${rates.costumeRate || ''}`}
                  readonly={isShowData}
                  name="costumeRate"
                  handleChange={handleChange}
                  customClass={false}
                  icon={dollarIcon}
                  disabled={isShowData}
                />
              </div>
            </div>
          </>
        )}
        <StyledFormHeading>Rentals</StyledFormHeading>
        <RateItem
          value={`${rates.rental || '35'}`}
          readonly={false}
          name="rental"
          handleChange={handleChange}
          customClass={false}
          icon={dollarIcon}
        />
        <AdvancedMenuHeading>
          <p className={`mb-0 ${Styles.toggleHeading}`}>Percentages</p>
          <div className="custom-control custom-switch mb-3 position-relative" style={{ top: '-5px' }}>
            <input
              type="checkbox"
              name="percentages"
              className="custom-control-input"
              id="percentages"
              onChange={togglePercentages}
              checked={isPercentages}
            />
            <label className="custom-control-label" htmlFor="percentages" />
          </div>
          {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
        </AdvancedMenuHeading>
        {isPercentages && (
          <>
            {' '}
            <AdvancedMenuHeading>
              <p className={`mb-0 ${Styles.toggleHeading}`}>GST</p>
              <div className="custom-control custom-switch mb-3 position-relative" style={{ top: '-5px' }}>
                <input
                  type="checkbox"
                  name="gst"
                  className="custom-control-input"
                  id="gst"
                  onChange={toggleGst}
                  checked={isGst}
                />
                <label className="custom-control-label" htmlFor="gst" />
              </div>
              {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
            </AdvancedMenuHeading>
            {isGst && (
              <RateItem
                value={rates.gst || ''}
                readonly={false}
                name="gst"
                handleChange={handleChange}
                customClass={false}
                icon={percentageIcon}
              />
            )}
            <AdvancedMenuHeading>
              <p className={`mb-0 ${Styles.toggleHeading}`}>Vacation</p>
              <div className="custom-control custom-switch mb-3 position-relative" style={{ top: '-5px' }}>
                <input
                  type="checkbox"
                  name="vacation"
                  className="custom-control-input"
                  id="vacation"
                  onChange={toggleVacation}
                  checked={isVacation}
                  // checked={isPercentages}
                />
                <label className="custom-control-label" htmlFor="vacation" />
              </div>
              {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
            </AdvancedMenuHeading>
            {isVacation && (
              <RateItem
                value={rates.vacation || ''}
                readonly={false}
                name="vacation"
                handleChange={handleChange}
                customClass={false}
                icon={dollarIcon}
              />
            )}
          </>
        )}
        {isShowData && (
          <>
            <AdvancedMenuHeading>
              <p className={`mb-0 ${Styles.toggleHeading}`}>Travel Time</p>
              <div className="custom-control custom-switch mb-3 position-relative" style={{ top: '-5px' }}>
                <input
                  type="checkbox"
                  name="isTravel"
                  className="custom-control-input"
                  id="isTravel"
                  onChange={toggleTravelTime}
                  checked={openTravelTime}
                  disabled={isShowData}
                />
                <label className="custom-control-label" htmlFor="isTravel" />
              </div>
              {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
            </AdvancedMenuHeading>
            {openTravelTime && (
              <div className="d-flex justify-content-between">
                <div className="pe-2">
                  <RateItem
                    rateName="Distance"
                    value={`${rates.travelDistance || ''}`}
                    readonly={false}
                    name="travelDistance"
                    handleChange={handleChange}
                    customClass={false}
                  />
                </div>
                <div className="ps-2">
                  <RateItem
                    rateName="Time Rate"
                    value={`${rates.travelTime || ''}`}
                    readonly={false}
                    name="travelTime"
                    handleChange={handleChange}
                    customClass={false}
                  />
                </div>
              </div>
            )}
            <StyledSelectLabel>Categories:</StyledSelectLabel>
            <div className={`performerSearchWrapper rateManagementSelect mb-3 ${isShowData ? 'disabledSelect' : ''}`}>
              <Select
                className={`${rates.categories !== '' ? 'selected' : ''}`}
                name="categories"
                value={rates.categories}
                onChange={handleCategoryChange}
                options={categoriesOptions}
              />
            </div>
            {selectedValue && selectedValue.name === 'OTHERS' && (
              <div className="">
                <RateItem
                  value={rates.categories.value || ''}
                  readonly={false}
                  name="categories"
                  handleChange={handleChange}
                  customClass={false}
                />
              </div>
            )}
          </>
        )}
      </FieldGroup>
    </StyledRateCardItems>
  );
};

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAdvancedMenu = styled.div`
  position: relative;
`;
const StyledFormHeading = styled.p`
  font-size: 18px;
  color: #4b4d67;
  font-weight: 600;
`;
const AdvancedMenuHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
  & > p {
    box-sizing: border-box;
    margin-bottom: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    margin-right: 12.1px;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    display: flex;
    align-items: center;
    /* Secondary color */
    color: #4b4d67;
  }
`;
const StyledSelectLabel = styled.label`
  margin-bottom: 5px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #4b4d67;
`;
const StyledRateCardItems = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  height: fit-content;
  max-width: 50%;
  @media only screen and (max-width: 991px) {
    max-width: 100%;
    margin-top: 15px;
  }
`;
const StyledToggleOverTime = styled.div`
  display: flex;
  gap: 20px;
  height: fit-content;
  max-width: 100%;
`;

const mapStateToProps = (state: any) => {
  const rateTitle = getRateTitle(state);
  return { rateTitle };
};

export default connect(mapStateToProps, null)(RateCardItemNew);
