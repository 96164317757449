import React, { FunctionComponent, useEffect, useState } from 'react';
import { ImageFolderItem, SubscriptionPlan, User } from 'modules/user/types';
import BasicInfo from 'components/bgPerformer/BasicInfo/BasicInfo';
import Skill from 'components/bgPerformer/skills/Skill';
import Style from 'views/bgperformer/profile/Profile.module.css';
import ProfileEdit from 'views/bgperformer/profile/ProfileEdit/index';
import Modal from 'components/controls/Modal/Modal';
import BgAlbum from 'components/bgPerformer/Images/BgAlbum';
import Loader from 'components/controls/Loader/Loader';
import { isValidFile } from 'helpers/utils';
import { IProfileFileParam, IRemoveResume } from 'modules/params/param.type';
import Dialog from 'components/bgPerformer/Images/Dialog';
import SizeCardEdit from 'views/bgperformer/profile/SizeCard/index';
import Rental from 'components/bgPerformer/rental/Rental';
import { RentalOptionList } from 'modules/general/general.type';
import { toast } from 'react-toastify';

interface ProfileProps {
  profileData: User;
  user: User;
  performerProfileBegin?: Function;
  loading: boolean;
  profileDataLoading: boolean;
  uploadImage: Function;
  changeImageOrderBegin: Function;
  deleteImageBegin: Function;
  openFullViewImage: Function;
  uploadProfileImageBegin: Function;
  deleteResumeBegin: Function;
  counter: number;
  newFolderId: string;
  updateCounter: Function;
  editableId: string;
  rentalOpt: RentalOptionList;
  uploadRentalImage: Function;
  removeRentalImage: Function;
  updateRentalType: Function;
  rentalLoading: boolean;
}
const Profile: FunctionComponent<ProfileProps> = (props: ProfileProps) => {
  const {
    profileData,
    performerProfileBegin,
    user,
    loading,
    profileDataLoading,
    uploadImage,
    changeImageOrderBegin,
    deleteImageBegin,
    openFullViewImage,
    uploadProfileImageBegin,
    deleteResumeBegin,
    counter,
    updateCounter,
    newFolderId,
    editableId,
    rentalOpt,
    uploadRentalImage,
    removeRentalImage,
    updateRentalType,
    rentalLoading,
  } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [imageDialog, setImageDialog] = useState<boolean>(false);
  const [resumeKey, setResumeKey] = useState<string>('');
  const [isSizeCard, setSizeCard] = useState<boolean>(false);
  const [totalUploadedImagesCount, setTotalUploadedImagesCount] = useState(0);

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };
  const handleSizeCard = () => {
    setSizeCard(!isSizeCard);
  };

  useEffect(() => {
    if (!profileData && performerProfileBegin) {
      performerProfileBegin({ userId: user.id });
    }
    if (profileData) {
      const performer = profileData.bgPerformer;
      if (performer && performer.images) {
        const totalImages = performer.images.reduce((count: number, item: any) => count + item.images.length, 0);
        setTotalUploadedImagesCount(totalImages);
      }
    }
  }, [profileData]);

  const getImages = () => {
    const data = profileData || user;
    if (data) {
      const performer = data.bgPerformer;
      if (data.subscription) {
        const plan: SubscriptionPlan = data.subscription;
        if (performer && performer.images) {
          const uploadedImages = performer.images.reduce((a: number, b: ImageFolderItem) => {
            let num = a;
            if (b.images) num += b.images.length;
            return num;
          }, 0);
          if (uploadedImages > plan.photos) {
            return performer.images.filter((i: ImageFolderItem) => i.isDefault === true);
          }
        }
      }
      return data.bgPerformer?.images || [];
    }
    return [];
  };

  const getSkills = () => {
    if (profileData) return profileData.bgPerformer?.skills;
    return user.bgPerformer?.skills;
  };

  const getProfile = () => <ProfileEdit closeModal={handleEdit} user={user} />;
  const getSizeCard = () => <SizeCardEdit closeModal={handleSizeCard} user={user} />;

  const uploadResume = (file: File) => {
    if (file) {
      const message = isValidFile(file, 'pdf');
      if (message.length > 0) {
        toast.error(message);
        return;
      }
      const formData = new FormData();
      formData.append(resumeKey, file);

      const params: IProfileFileParam = {
        params: formData,
        updateCall: true,
      };
      uploadProfileImageBegin(params);
      setImageDialog(false);
    }
  };

  const getResumeKey = () => {
    if (profileData && profileData.resumeUrl1) {
      const arr = profileData.resumeUrl1.split('/').pop();
      if (arr !== 'null' && arr !== undefined) {
        return 'resumeUrl2';
      }
    }
    return 'resumeUrl1';
  };

  const handleResumeUpload = () => {
    setResumeKey(getResumeKey());
    setImageDialog(true);
  };

  const handleEditResume = (type: string, action: string) => {
    setResumeKey(type);
    if (action === 'edit') {
      setImageDialog(true);
    } else {
      const params: IRemoveResume = type === 'resumeUrl1' ? { resumeUrl1: 'remove' } : { resumeUrl2: 'remove' };
      deleteResumeBegin(params);
    }
  };

  return (
    <div className={`${Style.MainWrapper}`}>
      <div className={`${Style.BasicInfoWrapper}`}>
        <BasicInfo
          profile={profileData || user}
          handleEdit={handleEdit}
          handleEditResume={handleEditResume}
          handleResumeUpload={handleResumeUpload}
          handleSizeCard={handleSizeCard}
        />
      </div>

      {profileDataLoading && <Loader isSmallView />}
      <BgAlbum
        user={user}
        list={getImages()}
        loading={loading}
        fileUploadHandler={uploadImage}
        changeImageOrderBegin={changeImageOrderBegin}
        deleteImageBegin={deleteImageBegin}
        openFullViewImage={openFullViewImage}
        counter={counter}
        updateCounter={updateCounter}
        newFolderId={newFolderId}
        editableId={editableId}
        totalUploadedImagesCount={totalUploadedImagesCount}
      />
      <div className={Style.SkillWrapper}>
        <Rental
          cssClass={Style.rentalWrapper}
          rentalOpt={rentalOpt}
          uploadRentalImage={uploadRentalImage}
          isLoading={rentalLoading}
          removeRentalImage={removeRentalImage}
          updateRentalType={updateRentalType}
        />
      </div>
      <div className={Style.SkillWrapper}>
        <Skill skillClass={Style.skillsColor} skills={getSkills()} user={user} />
      </div>
      {isEdit && <Modal title="Edit Profile" body={getProfile} closeModal={handleEdit} />}
      {isSizeCard && <Modal title="Edit Size Card" body={getSizeCard} closeModal={handleSizeCard} />}
      {imageDialog && <Dialog closeDialog={setImageDialog} fileUploadHandler={uploadResume} type="pdf" />}
    </div>
  );
};

export default Profile;
