import {
  IBGSkillDeleteParams,
  IBGSkillParams,
  IChangeImageOrder,
  IChangeRentalType,
  IIDParams,
  IPerformerDetail,
  IProfileFileParam,
  IRemoveRental,
  IUserVoucher,
  IRemoveResume,
  RemoveDocs,
  IPerformerVoucherParam,
  IUpdatePerformerVoucherParam,
} from 'modules/params/param.type';
import { PerformerImage, PerformerSkillList, RentalImage, User } from 'modules/user/types';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const getPerformerProfile = {
  pending: {
    type: ACTIONS.FETCH_PERFORMER_PROFILE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.FETCH_PERFORMER_PROFILE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FETCH_PERFORMER_PROFILE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const uploadPerformerFiles = {
  pending: {
    type: ACTIONS.PERFORMER_FILES_UPLOAD.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.PERFORMER_FILES_UPLOAD.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.PERFORMER_FILES_UPLOAD.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const performerProfileBegin = (payload: IPerformerDetail) => ({
  type: ACTIONS.FETCH_PERFORMER_PROFILE_BEGIN,
  payload,
});

export const savePerformerSkills = {
  pending: {
    type: ACTIONS.SAVE_PERFORMER_SKILL.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: PerformerSkillList) => ({
    type: ACTIONS.SAVE_PERFORMER_SKILL.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SAVE_PERFORMER_SKILL.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const savePerformerSkillsBegin = (payload: IBGSkillParams) => ({
  type: ACTIONS.SAVE_PERFORMER_SKILL_BEING,
  payload,
});

export const deletePerformerSkill = {
  pending: {
    type: ACTIONS.DELETE_PERFORMER_SKILL.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: PerformerSkillList) => ({
    type: ACTIONS.DELETE_PERFORMER_SKILL.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.DELETE_PERFORMER_SKILL.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const deletePerformerSkillBegin = (payload: IBGSkillDeleteParams) => ({
  type: ACTIONS.DELETE_PERFORMER_SKILL_BEGIN,
  payload,
});

export const savePerformerSkillsSignUp = {
  pending: {
    type: ACTIONS.SAVE_PERFORMER_SKILL_SIGN_UP.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: PerformerSkillList) => ({
    type: ACTIONS.SAVE_PERFORMER_SKILL_SIGN_UP.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SAVE_PERFORMER_SKILL_SIGN_UP.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const savePerformerSkillsSignUpBegin = (payload: IBGSkillParams) => ({
  type: ACTIONS.SAVE_PERFORMER_SKILL_SIGN_UP_BEGIN,
  payload,
});

export const openFullViewImage = (data: string) => ({
  type: ACTIONS.OPEN_FULL_VIEW_IMAGE,
  data,
});

export const savePerformerImage = {
  pending: (data: any) => ({
    type: ACTIONS.SAVE_PERFORMER_IMAGE.PENDING,
    data,
    status: FETCH_STATUS.LOADING,
  }),
  success: (data: PerformerImage, fileId: number) => ({
    type: ACTIONS.SAVE_PERFORMER_IMAGE.SUCCESS,
    data,
    fileId,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SAVE_PERFORMER_IMAGE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createUserVoucher = {
  pending: {
    type: ACTIONS.CREATE_USER_VOUCHER.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: IUserVoucher) => ({
    type: ACTIONS.CREATE_USER_VOUCHER.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CREATE_USER_VOUCHER.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const savePerformerImageBegin = (payload: IProfileFileParam) => ({
  type: ACTIONS.SAVE_PERFORMER_IMAGE_BEGIN,
  payload,
});

export const createUserVoucherBegin = (payload: IUserVoucher) => ({
  type: ACTIONS.CREATE_USER_VOUCHER_BEGIN,
  payload,
});

export const updateFileCounter = (data: string) => ({
  type: ACTIONS.ADD_IMAGE_UPLOAD_COUNTER,
  data,
});

export const changeImageOrder = {
  pending: {
    type: ACTIONS.CHANGE_IMAGE_ORDER.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: () => ({
    type: ACTIONS.CHANGE_IMAGE_ORDER.SUCCESS,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CHANGE_IMAGE_ORDER.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const changeImageOrderBegin = (payload: IChangeImageOrder) => ({
  type: ACTIONS.CHANGE_IMAGE_ORDER_BEGIN,
  payload,
});

export const deleteImage = {
  pending: {
    type: ACTIONS.DELETE_PERFORMER_IMAGE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: IIDParams) => ({
    type: ACTIONS.DELETE_PERFORMER_IMAGE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.DELETE_PERFORMER_IMAGE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const deleteImageBegin = (payload: IIDParams) => ({
  type: ACTIONS.DELETE_PERFORMER_IMAGE_BEGIN,
  payload,
});

export const deleteImageLocally = (payload: IIDParams) => ({
  type: ACTIONS.DELETE_PERFORMER_IMAGE_LOCALLY,
  data: payload,
});

export const deleteImageAuth = (payload: IIDParams) => ({
  type: ACTIONS.DELETE_PERFORMER_IMAGE_AUTH,
  data: payload,
});

export const deleteResume = {
  pending: {
    type: ACTIONS.DELETE_RESUME.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: IRemoveResume) => ({
    type: ACTIONS.DELETE_RESUME.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.DELETE_RESUME.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const deleteResumeBegin = (payload: IRemoveResume) => ({
  type: ACTIONS.DELETE_RESUME_BEGIN,
  payload,
});

export const updateUserImages = (data: User, payload: IProfileFileParam) => ({
  type: ACTIONS.UPDATE_PERFORMER_IMAGE_RESUME_FILE,
  data,
  payload,
});

export const updateCounter = () => ({
  type: ACTIONS.UPDATE_PERFORMER_FILE_COUNTER,
});

export const deleteFileError = (data: any) => ({
  type: ACTIONS.DELETE_FILE_ERROR,
  data,
});

export const imageUploadFiles = (data: any) => ({
  type: ACTIONS.IMAGE_UPLOAD_FILES,
  data,
});

export const performerUploadFiles = (data: any) => ({
  type: ACTIONS.PERFORMER_FILES_UPLOAD_BEGIN,
  data,
});

export const deleteImageFiles = () => ({
  type: ACTIONS.IMAGE_DELETE_FILES,
});

export const uploadRentalImage = {
  pending: {
    type: ACTIONS.UPLOAD_RENTAL_IMAGE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.UPLOAD_RENTAL_IMAGE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPLOAD_RENTAL_IMAGE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const uploadRentalImageBegin = (payload: IProfileFileParam) => ({
  type: ACTIONS.UPLOAD_RENTAL_IMAGE_BEGIN,
  payload,
});

export const removeRentalImage = {
  pending: {
    type: ACTIONS.REMOVE_RENTAL_IMAGE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: string) => ({
    type: ACTIONS.REMOVE_RENTAL_IMAGE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.REMOVE_RENTAL_IMAGE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const removeRentalImageBegin = (payload: IRemoveRental) => ({
  type: ACTIONS.REMOVE_RENTAL_IMAGE_BEGIN,
  payload,
});

export const updateRentalType = {
  pending: {
    type: ACTIONS.UPDATE_RENTAL_TYPE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: RentalImage, payload: string) => ({
    type: ACTIONS.UPDATE_RENTAL_TYPE.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPDATE_RENTAL_TYPE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const saveVoucherInfoRequest = (payload: IPerformerVoucherParam) => ({
  type: ACTIONS.SAVE_PERFORMER_VOUCHER_BEGIN,
  payload,
});

export const saveVoucherInfo = {
  pending: {
    type: ACTIONS.SAVE_PERFORMER_VOUCHER.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.SAVE_PERFORMER_VOUCHER.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SAVE_PERFORMER_VOUCHER.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getVoucherInfoRequest = (payload: IPerformerVoucherParam) => ({
  type: ACTIONS.GET_PERFORMER_VOUCHER_BEGIN,
  payload,
});

export const getVoucherInfo = {
  pending: {
    type: ACTIONS.GET_PERFORMER_VOUCHER.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.GET_PERFORMER_VOUCHER.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.GET_PERFORMER_VOUCHER.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateRentalTypeBegin = (payload: IChangeRentalType) => ({
  type: ACTIONS.UPDATE_RENTAL_TYPE_BEGIN,
  payload,
});

export const getResidencyDocsRequest = () => ({
  type: ACTIONS.GET_PROOF_OF_RESIDENCY_BEGIN,
});

export const getResidencyDocs = {
  pending: {
    type: ACTIONS.GET_PROOF_OF_RESIDENCY.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.GET_PROOF_OF_RESIDENCY.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.GET_PROOF_OF_RESIDENCY.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const removeResidencyDocs = (payload: RemoveDocs) => ({
  type: ACTIONS.REMOVE_RESIDENCY_DOCS_BEGIN,
  payload,
});

export const renameResidencyDocs = (payload: RemoveDocs) => ({
  type: ACTIONS.RENAME_RESIDENCY_DOCS_BEGIN,
  payload,
});

export const renamedResidencyDocs = {
  pending: {
    type: ACTIONS.RENAME_RESIDENCY_DOCS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: RemoveDocs) => ({
    type: ACTIONS.RENAME_RESIDENCY_DOCS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.RENAME_RESIDENCY_DOCS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const deleteResidencyDocs = {
  pending: {
    type: ACTIONS.DELETE_RESIDENCY_DOCS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: RemoveDocs) => ({
    type: ACTIONS.DELETE_RESIDENCY_DOCS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.DELETE_RESIDENCY_DOCS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updatePerformerVoucherStatus = (payload: IUpdatePerformerVoucherParam) => ({
  type: ACTIONS.UPDATE_PERFORMER_VOUCHER_STATUS_BEGIN,
  payload,
});
