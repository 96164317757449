import { connect } from 'react-redux';
import { getSelectedShow, showDetailLoading } from 'store/selector/show.selector';
import { Show } from 'modules/Show/show.types';
import ShowLeftNavigation from 'components/ShowLeftNavigation/ShowLeftNavigation';
import { showEditRequest, showDetailRequest } from 'store/actions/show.actions';
import { User } from 'modules/user/types';
import { getUser } from 'store/selector/auth.selector';

const mapStateToProps = (state: Show) => {
  const selectedShow = getSelectedShow(state);
  const user: User = getUser(state);
  const loading = showDetailLoading(state);
  return {
    selectedShow,
    user,
    loading,
  };
};

const mapDispatchToProps = {
  editShow: showEditRequest,
  detailShow: showDetailRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowLeftNavigation);
