import React, { useState } from 'react';
import styled from 'styled-components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
// import downloadLogo from '../../../../../../assets/svg/downloadBtn.svg';
import downloadArrowSvg from 'assets/svg/download-arrow.svg';
import arrowDownSvg from 'assets/svg/arrowDown.svg';
// import printSvg from 'assets/svg/print.svg';
// import printWithoutSvg from 'assets/svg/print-without-text.svg';
// import plusYellowSvg from 'assets/svg/plus-yellow.svg';
// import editSvg from 'assets/svg/edit-background.svg';
import Style from 'components/Producer/Matrix/Budget/CreateBudget.module.css';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
// import { Show } from 'modules/Show/show.types';
import BudgetExpert from 'components/Producer/Matrix/Budget/Popover/BudgetExport';
import { LOADER_STATUS } from 'store/castingPax.constants';
import html2canvas from 'html2canvas';
import Jspdf from 'jspdf';

interface Props {
  printRef: any;
  types?: string;
}

const ExportControl: React.FC<Props> = (props) => {
  // const printRef = useRef();
  const { printRef, types } = props;
  // const handlePrint = () => window.print();
  const [exportLoading, setExportLoading] = useState<number>(LOADER_STATUS.NA);
  const [loaderType, setLoaderType] = useState<string>('');
  // const [show, setShow] = useState<Show>();
  const handleDownload = (exportType: string, type: string, startDate: string, endDate: string) => {
    setLoaderType(exportType);
    if (exportType === 'jpeg') handleDownloadImage().then();
    if (exportType === 'pdf') handleDownloadPdf(type, startDate, endDate);
  };

  const handleDownloadPdf = (type: string, startDate: string, endDate: string) => {
    if (type === 'chart') {
      console.log(startDate, endDate);
      setExportLoading(LOADER_STATUS.PENDING);
      const element: any = printRef.current;
      html2canvas(element).then((canvas) => {
        const imgWidth = 208;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const imgData = canvas.toDataURL('img/png');
        const pdf = new Jspdf('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('download.pdf');
        setExportLoading(LOADER_STATUS.COMPLETED);
      });
    }
  };
  const getComponent = (
    <BudgetExpert isloading={exportLoading} loaderType={loaderType} type="chart" handleClick={handleDownload} />
  );

  const handleDownloadImage = async () => {
    setExportLoading(LOADER_STATUS.PENDING);
    const element: any = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = data;
      link.download = 'image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setExportLoading(LOADER_STATUS.COMPLETED);
    } else {
      setExportLoading(LOADER_STATUS.COMPLETED);
      window.open(data);
    }
  };
  const { children } = props;
  console.log(children);
  return (
    <Container>
      {/* <DonwnloadIcon src={downloadLogo} width={12} height={16} /> */}
      <div className={`mr-2 ${Style.exportButtonWrapper}`}>
        <img src={downloadArrowSvg} alt="" />
        <p className={types === 'cost' ? `mb-0 mr-0 ${Style.exportTitle}` : `mb-0 ${Style.exportTitle}`}>Export</p>
        <PopoverContainer itemComponent={() => getComponent}>
          <img src={arrowDownSvg} alt="" />
        </PopoverContainer>
      </div>
      {/* <FontAwesomeIcon icon={faCaretDown} color="#4B4D67" /> */}
    </Container>
  );
};

export default ExportControl;

const Container = styled.div`
  width: 122px;
  height: 38.39px;
  border-radius: 12px;
  padding: 12px;
  background: rgba(80, 181, 255, 0.1);
  mix-blend-mode: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

// const TextBox = styled.div`
//   width: 54px;
//   height: 26.27px;
// `;

// const Text = styled.p`
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   color: #44444f;
// `;

// const DonwnloadIcon = styled.img``;
