import React, { FunctionComponent, useEffect } from 'react';
import performerSvg from 'landingAssets/svg/performer.svg';
import castingSvg from 'landingAssets/svg/casting.svg';
import hmwSvg from 'landingAssets/svg/hmw.svg';
import producerSvg from 'landingAssets/svg/producer.svg';
import eVoucherSvg from 'landingAssets/svg/e-voucher.svg';
import performerPng from 'landingAssets/images/performer.png';
import castingPng from 'landingAssets/images/casting.png';
import hmwPng from 'landingAssets/images/hmw.png';
import producerPng from 'landingAssets/images/producer.png';
import mobilePng from 'landingAssets/images/mobile.png';
import logoSvg from 'landingAssets/svg/logo.svg';
import WOW from 'wowjs';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';
import playStore from 'assets/svg/playStore.svg';
import appStore from 'assets/svg/appStore.svg';
import Style from 'views/landing/landing.module.css';
import { Link } from 'react-router-dom';

const Landing: FunctionComponent = () => {
  const user = getItemFromLocalStorage('user') || {};
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <div className={Style.bodyWrapper}>
      <header className={`${Style.headerClass}`}>
        <div className={`position-relative ${Style.container}`}>
          <nav className="d-flex justify-content-between align-items-center">
            <Link to="/Home" className="d-inline-block">
              <img src={logoSvg} alt="logo" className={`${Style.logoImg}`} />
            </Link>
            <ul className="m-0 list-unstyled d-flex align-items-center">
              <li className="nav-item">
                <Link className={`nav-link py-0 text-white ${Style.fontSixteen}`} to="/features">
                  Features
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link py-0 text-white ${Style.fontSixteen}`} to="/">
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link py-0 text-white ${Style.fontSixteen}`} to="/">
                  Support
                </Link>
              </li>
              {!user.role && (
                <div className={Style.listingBtn}>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${Style.fontSixteen} btn ${Style.signupBtn} text-white`}
                      href="/signupcategory"
                    >
                      Sign Up
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${Style.fontSixteen} btn
                      ${Style.signupBtn} ${Style.signupBtnFilled} text-white`}
                      href="/login"
                    >
                      Sign In
                    </a>
                  </li>
                </div>
              )}
            </ul>
          </nav>
          <div className={`${Style.headerDetails} text-center`}>
            <h1
              className="mb-0 mt-4 text-white text-uppercase position-relative w-100 text-center wow pulse"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              For Film
            </h1>
            <p
              className={`${Style.headerDescription} font-weight-normal ${Style.fontSixteen} text-white wow pulse`}
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              CastingPAX focuses on simplifying the casting process through automation. Through technology, CastingPAX
              can reduce wasted time, simplify timeful tasks and empower communication and control to the casting
              director.
            </p>
            <div className="d-flex justify-content-center wow pulse" data-wow-duration="1s" data-wow-delay="0.7s">
              <a
                className={`nav-link ${Style.fontSixteen} btn ${Style.signupBtn} ${Style.signupBtnFilled} text-white`}
                href="/signUpCategory"
              >
                Sign up
              </a>
            </div>
          </div>
        </div>
      </header>
      <main>
        <section className={Style.container}>
          <div className={`${Style.userCardsWrapper}`}>
            <a
              href="/"
              className="d-block text-decoration-none wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <div className={`text-center ${Style.userCardInner}`}>
                <img src={performerSvg} alt="performer" className="" />
                <p className="text-center mb-0">Performer</p>
              </div>
            </a>
            <a
              href="/"
              className="d-block text-decoration-none wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="1s"
            >
              <div className={`text-center ${Style.userCardInner}`}>
                <img src={castingSvg} alt="performer" className="" />
                <p className="text-center mb-0">Casting</p>
              </div>
            </a>
            <a
              href="/"
              className="d-block text-decoration-none wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="1.5s"
            >
              <div className={`text-center ${Style.userCardInner}`}>
                <img src={hmwSvg} alt="performer" className="" />
                <p className="text-center mb-0">HMW</p>
              </div>
            </a>
            <a
              href="/"
              className="d-block text-decoration-none wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="2s"
            >
              <div className={`text-center ${Style.userCardInner}`}>
                <img src={producerSvg} alt="performer" className="" />
                <p className="text-center mb-0">Producer</p>
              </div>
            </a>
            <a
              href="/"
              className="d-block text-decoration-none wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="2.5s"
            >
              <div className={`text-center ${Style.userCardInner}`}>
                <img src={eVoucherSvg} alt="performer" className="" />
                <p className="text-center mb-0">E - Vouchers</p>
              </div>
            </a>
          </div>
        </section>
        <section className={`${Style.sectionPadding}`}>
          <div className={Style.container}>
            <div className="row">
              <div className="col-lg-6">
                <div className={`${Style.performerWrapperLeft}`}>
                  <h3
                    className={`text-uppercase wow fadeInUp ${Style.h3}`}
                    data-wow-duration="1s"
                    data-wow-delay="0.6s"
                  >
                    Performer
                  </h3>
                  <p
                    className={`font-weight-normal wow fadeInUp ${Style.fontSixteen} ${Style.pgText}`}
                    data-wow-duration="1s"
                    data-wow-delay="0.8s"
                  >
                    Become your own agent and control your own schedule. Connect directly with Casting agents and submit
                    for show quick and fast.
                  </p>
                  {/* <div className="float-start wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">
                <a className={`nav-link ${Style.mt30} ${Style.fontSixteen} btn ${Style.signupBtn} 4
                  ${Style.signupBtnOrange} text-white`} href="/">
                  Read More
                </a>
              </div> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className={`${Style.imgWrapper} position-relative`}>
                  <img src={performerPng} alt="performer" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={`${Style.sectionPadding} ${Style.bgLightGray}`}>
          <div className={Style.container}>
            <div className="row">
              <div className="col-lg-6">
                <div className={`${Style.imgWrapper} position-relative`}>
                  <img src={castingPng} alt="performer" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className={`${Style.performerWrapperRight}`}>
                  <h3
                    className={`text-uppercase wow fadeInUp ${Style.h3}`}
                    data-wow-duration="1s"
                    data-wow-delay="0.6s"
                  >
                    Casting
                  </h3>
                  <p
                    className={`font-weight-normal wow fadeInUp ${Style.fontSixteen} ${Style.pgText}`}
                    data-wow-duration="1s"
                    data-wow-delay="0.8s"
                  >
                    The key casting process has gotten much easier, organized and adaptive with CastingPAX. Through our
                    features, we simplify tasks that would of once required a team:
                  </p>
                  {/* <div className="float-start wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">
                <a className={`nav-link ${Style.mt30} ${Style.fontSixteen} btn ${Style.signupBtn}
                  ${Style.signupBtnOrange} text-white`} href="/">
                  Read More
                </a>
              </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={`position-relative ${Style.hmwWrapper}`}>
          <div className={Style.container}>
            <div className="row">
              <div className="col-lg-6">
                <div className={`${Style.performerWrapperLeft}`}>
                  <h3
                    className={`text-uppercase wow fadeInUp ${Style.h3}`}
                    data-wow-duration="1s"
                    data-wow-delay="0.6s"
                  >
                    BG Coordinator
                  </h3>
                  <p
                    className={`font-weight-normal wow fadeInUp ${Style.fontSixteen} ${Style.pgText}`}
                    data-wow-duration="1s"
                    data-wow-delay="0.8s"
                  >
                    Best for active freelancers and designers who need more projects and premium features. Export your
                    code and unlock more pages for your unhosted projects.
                  </p>
                  <div className="float-start wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">
                    <a
                      className={`nav-link ${Style.mt30} ${Style.fontSixteen}
                    btn ${Style.signupBtn} ${Style.signupBtnOrange}
                    text-white`}
                      href="/"
                    >
                      Join app wait list
                    </a>
                  </div>
                </div>
              </div>
              <div className={`col-lg-6 ${Style.pr0}`} />
            </div>
            <div className={`${Style.imgWrapper} ${Style.hmwRightImg}`}>
              <img src={hmwPng} alt="performer" />
            </div>
          </div>
        </section>
        <section className={`${Style.sectionPadding} ${Style.bgLightGray}`}>
          <div className={Style.container}>
            <div className="row">
              <div className="col-lg-6">
                <div className={`${Style.imgWrapper} position-relative`}>
                  <img src={producerPng} alt="performer" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className={`${Style.performerWrapperRight}`}>
                  <h3
                    className={`text-uppercase wow fadeInUp ${Style.h3}`}
                    data-wow-duration="1s"
                    data-wow-delay="0.6s"
                  >
                    Production
                  </h3>
                  <p
                    className={`font-weight-normal wow fadeInUp ${Style.fontSixteen} ${Style.pgText}`}
                    data-wow-duration="1s"
                    data-wow-delay="0.8s"
                  >
                    Best for active freelancers and designers who need more projects and premium features. Export your
                    code and unlock more pages for your unhosted projects.
                  </p>
                  <div className="float-start wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">
                    <a
                      className={`nav-link ${Style.mt30} ${Style.fontSixteen} btn ${Style.signupBtn}
                  ${Style.signupBtnOrange} text-white ${Style.asdfBtn}`}
                      href="/"
                    >
                      Contact CastingPAX to learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className={`${Style.sectionPadding}`}>
          <div className={Style.container}>
            <div className="row">
              <div className="col-lg-6">
                <div className={`${Style.performerWrapperRight}`}>
                  <h3
                    className={`text-uppercase wow fadeInUp ${Style.h3}`}
                    data-wow-duration="1s"
                    data-wow-delay="0.6s"
                  >
                    asdf
                  </h3>
                  <p
                    className={`font-weight-normal wow fadeInUp ${Style.fontSixteen} ${Style.pgText}`}
                    data-wow-duration="1s"
                    data-wow-delay="0.8s"
                  >
                    Best for active freelancers and designers who need more projects and premium features. Export your
                    code and unlock more pages for your unhosted projects.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={`${Style.imgWrapper} position-relative`}>
                  <img src={producerPng} alt="performer" />
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className={`${Style.pt100} ${Style.bgLightGray}`}>
          <div className={Style.container}>
            <div className="row">
              <div className="col-lg-6">
                <div className={`${Style.performerWrapperLeft}`}>
                  <h3
                    className={`text-uppercase wow fadeInUp ${Style.h3}`}
                    data-wow-duration="1s"
                    data-wow-delay="0.6s"
                  >
                    CrewPAX
                    <img
                      className={`${Style.crewPaxLogo} ml-2 wow fadeInUp`}
                      src="https://i.ibb.co/yhThXS0/CrewPax.png"
                      alt="logoCrewPax"
                      data-wow-duration="1s"
                      data-wow-delay="0.6s"
                    />
                  </h3>
                  <p
                    className={`font-weight-normal ${Style.fontSixteen} ${Style.pgText} wow fadeInUp`}
                    data-wow-duration="1s"
                    data-wow-delay="0.8s"
                  >
                    CrewPAX is the prequel to CastingPAX, an online hiring hub for PA’s and DGC members mainly.
                  </p>
                  <div className=" d-flex">
                    <a
                      className={`float-start wow fadeInUp nav-link ${Style.mt30} ${Style.fontSixteen} btn
                  ${Style.signupBtn} ${Style.PlayStoreBtn} text-white`}
                      data-wow-duration="1s"
                      data-wow-delay="1s"
                      href="https://play.google.com/store/apps/details?id=com.app.crewpax"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={playStore} alt="" className="mr-2" />
                      Play Store
                    </a>
                    <a
                      className={`float-start wow fadeInUp nav-link ${Style.mt30} ${Style.fontSixteen} btn
                  ${Style.signupBtn} ${Style.AppStoreBtn} text-white`}
                      data-wow-duration="1s"
                      data-wow-delay="1.5s"
                      href="https://apps.apple.com/us/app/crewpax/id1503138647"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={appStore} alt="" className="mr-2" />
                      App Store
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={`${Style.imgWrapper} position-relative`}>
                  <img src={mobilePng} alt="performer" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <section className={Style.container}>
          <div className="row">
            <div className={`col-lg-12 ${Style.pt50}`}>
              <a href="/" className="d-inline-block">
                <img src={logoSvg} alt="logo" className={`${Style.logoImg}`} />
              </a>
            </div>
            <div className={`col-lg-4 ${Style.pt50}`}>
              <div className="pb-3">
                <a href="tel:+1 (2345) 678-90-12" className="text-decoration-none text-white font-twenty">
                  604-833-2265
                </a>
              </div>
              <div className="pb-3">
                <a
                  href="mailto:support@personal.com"
                  className={`text-decoration-none text-white ${Style.fontSixteen}`}
                >
                  support@castingpax.com
                </a>
              </div>
            </div>
            {/* <div className={`col-lg-2 ${Style.pt50}`}> */}
            {/*  <div /> */}
            {/*  <div className="pb-3"> */}
            {/*    <a href="/" className="text-decoration-none text-white font-twenty"> */}
            {/*      About */}
            {/*    </a> */}
            {/*  </div> */}
            {/*  <div className="pb-3"> */}
            {/*    <a href="/" className={`text-decoration-none text-white ${Style.fontSixteen}`}> */}
            {/*      Profile */}
            {/*    </a> */}
            {/*  </div> */}
            {/* </div> */}
            <div className={`col-lg-4 ${Style.pt50}`}>
              <div className="pb-3">
                <a href="/" className="text-decoration-none text-white font-twenty">
                  Features
                </a>
              </div>
              <div className="pb-3">
                <a href="/" className="text-decoration-none text-white font-twenty">
                  Blog
                </a>
              </div>
              <div className="pb-3">
                <a href="/" className="text-decoration-none text-white font-twenty">
                  Pricing
                </a>
              </div>
              <div className="pb-3">
                <a href="/" className={`text-decoration-none text-white ${Style.fontSixteen} `}>
                  Contact
                </a>
              </div>
            </div>
            <div className={`col-lg-4 ${Style.pt50}`}>
              <form>
                <div className={`form-group d-flex ${Style.columnGap10}`}>
                  <input
                    type="email"
                    placeholder="Your Email"
                    className={`${Style.textBlack} form-control
                ${Style.fontSixteen} ${Style.inputStyle} `}
                  />
                  <button
                    type="button"
                    className={`btn ${Style.signupBtnFilled} text-white ${Style.fontSixteen}
                ${Style.subscribeBtn}`}
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div>
          <hr className={`w-100 ${Style.horizontalLine}`} />
          <div className="footer-bottom d-flex justify-content-between align-items-center">
            <div className={`d-flex align-items-center ${Style.columnGap10}`}>
              <a href="facebook" target="_blank" className={`text-decoration-none ${Style.iconWrapper}`}>
                <i className="fab fa-facebook" />
              </a>
              <a href="facebook" target="_blank" className={`text-decoration-none ${Style.iconWrapper}`}>
                <i className="fab fa-twitter" />
              </a>
              <a href="facebook" target="_blank" className={`text-decoration-none ${Style.iconWrapper}`}>
                <i className="fab fa-instagram" />
              </a>
            </div>
            <p className={`mb-0 ${Style.reservedText} ${Style.fontSixteen} font-weight-normal`}>
              © 2022 &nbsp;
              <a href="/" className={`${Style.reservedText} text-decoration-none`}>
                BCPAX productions Services ltd. &nbsp;
              </a>
              All rights reserved.
            </p>
          </div>
        </section>
      </footer>
    </div>
  );
};

export default Landing;
