import React, { FunctionComponent, useMemo, useState } from 'react';
import Button from 'components/controls/button/button';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import { BookingRequest, BookingRequestList } from 'modules/jobs/types';
import { ICancelRequestParams } from 'modules/params/param.type';
import { ShowDayJobList } from 'modules/Show/show.types';
import CancelReason from 'components/bgPerformer/popover/CancelReason';
import cancelSvg from 'assets/svg/cancel.svg';
import { useDispatch, connect, useSelector } from 'react-redux';
import calendarSvg from 'assets/svg/calendar-white.svg';
import Style from 'components/bgPerformer/BookingButton/BookingButton.module.css';
import {
  acceptCastingRequestBegin,
  cancelBookingBegin,
  submitJobRequestBegin,
  updateJobStats,
} from 'store/actions/job.actions';

import { showToast } from 'store/actions/general.actions';
import {
  JOB_STATUS_BOOKED,
  JOB_STATUS_PENDING,
  JOB_STATUS_REQUESTED,
  TOAST_IDS,
  TOAST_TYPE,
} from 'store/castingPax.constants';
import { getAllBookingRequest, getCalendarDates } from 'store/selector/job.selector';
import { BUTTON_TYPE, User } from 'modules/user/types';
import moment from 'moment';
import Modal from 'components/controls/Modal/Modal';
import ConfirmationModal from 'components/bgPerformer/BookingButton/ConfirmationModal';
import { getUser } from 'store/selector/auth.selector';

interface BookingButtonProps {
  isCastingCall?: boolean;
  type?: BUTTON_TYPE;
  isCancelOnly?: boolean;
  job: string;
  jobDates: string[];
  acceptCastingRequest?: Function;
  allBookingRequest: BookingRequestList;
  closeDialog?: Function;
}
const BookingButton: FunctionComponent<BookingButtonProps> = (props) => {
  const { type, job, jobDates, acceptCastingRequest, allBookingRequest, isCancelOnly, isCastingCall, closeDialog } =
    props;

  const calenderDates = useSelector(getCalendarDates);
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
  const user: User = useSelector(getUser);

  const dispatch = useDispatch();

  const booking = useMemo(() => {
    const obj = allBookingRequest.filter((b: BookingRequest) => b.jobId.id === job);
    if (obj.length > 0) {
      return obj[0];
    }
    return null;
  }, [allBookingRequest]);

  const getbuttonLabel = () => {
    if (booking) {
      const bookingStatus: string = booking.status;
      if (bookingStatus === JOB_STATUS_BOOKED) {
        return 'cancel';
      }
      if (bookingStatus === JOB_STATUS_PENDING) return 'cancel';
      if (bookingStatus === JOB_STATUS_REQUESTED) return isCastingCall ? 'submit' : 'accept';
    }
    return 'submit';
  };

  const submitHandle = () => {
    const bookingStatus: string = booking ? booking.status : 'submit';

    if (bookingStatus === JOB_STATUS_PENDING) {
      dispatch(updateJobStats({ jobId: job, performerId: user.id, status: 'cancel' }));
      dispatch(cancelBookingBegin({ jobId: job }));
      return;
    }
    if (!canApplyForJob()) {
      dispatch(
        showToast({
          id: TOAST_IDS.CANCEL_BOOKING_ID,
          title: 'Please first cancel the booking that clash with current Job.',
          type: TOAST_TYPE.ERROR,
        }),
      );
      return;
    }
    if (bookingStatus === JOB_STATUS_REQUESTED && booking) {
      dispatch(updateJobStats({ jobId: job, performerId: user.id, status: 'booked' }));
      const params: ICancelRequestParams = { id: booking.id, status: 'booked' };
      if (acceptCastingRequest) acceptCastingRequest(params);
      return;
    }
    if (checkAvailableDates()) {
      dispatch(updateJobStats({ jobId: job, performerId: user.id, status: 'apply' }));
      dispatch(submitJobRequestBegin({ id: job }));
    }
  };

  const canApplyForJob = () => {
    let isAvailable: boolean = true;
    if (allBookingRequest) {
      if (booking) {
        const currentBooking: any = allBookingRequest.filter((b: BookingRequest) => {
          let checkDate: string = '0';
          if (b.status === JOB_STATUS_BOOKED && b.id !== booking.id) {
            booking.dates.forEach((x) => {
              if (b.dates.includes(x)) checkDate = '1';
            });
          }
          return checkDate === '0' ? null : b;
        });
        return currentBooking.length === 0;
      }
      jobDates.forEach((d: string) => {
        const index: number = allBookingRequest.findIndex(
          (j: BookingRequest) => j.status === JOB_STATUS_BOOKED && j.dates.includes(d),
        );
        if (index !== -1) isAvailable = false;
      });
    }
    return isAvailable;
  };

  const checkAvailableDates = () => {
    const notAvailableDates = calenderDates
      .filter((c: any) => c.workStatus === 2)
      .map((a: any) => moment(a.workDate).format('Y-MM-D 00:00:00'));
    const isValidDate = jobDates.filter((c: any) => notAvailableDates.includes(c));
    if (isValidDate.length > 0) {
      setIsConfirmation(true);
      return false;
    }
    return true;
  };

  const getButtonType = () => {
    switch (type) {
      case 'customCancelBtn':
        return 'customCancelBtn';
      default:
        return 'primary';
    }
  };

  const handleProcessRequest = (status: string, cancelRemarks?: string) => {
    if (!job) return;
    dispatch(updateJobStats({ jobId: job, performerId: user.id, status: 'cancel' }));
    if (closeDialog) closeDialog();
    if (cancelRemarks && booking) {
      const params: ICancelRequestParams = { id: booking.id, status, cancelRemarks };
      dispatch(cancelBookingBegin(params));
      return;
    }
    dispatch(cancelBookingBegin({ jobId: job }));
  };

  const handleCancel = (reason: string) => {
    handleProcessRequest('cancel', reason);
    document.body.click();
  };

  const ModalBody = () => (
    <ConfirmationModal handleSubmit={handleSubmit} handleClose={() => setIsConfirmation(false)} />
  );

  const handleSubmit = () => {
    dispatch(updateJobStats({ jobId: job, performerId: user.id, status: 'apply' }));
    dispatch(submitJobRequestBegin({ id: job }));
    setIsConfirmation(false);
  };

  const popoverComponent = () => <CancelReason handleCancel={handleCancel} />;

  const checkBookedBooking = () => {
    if (isCastingCall) {
      return (
        <>
          {!booking ? (
            <button type="button" className={`btn btn-outline-success ${Style.confirmButton}`} onClick={submitHandle}>
              Submit
            </button>
          ) : (
            <PopoverContainer itemComponent={popoverComponent} cssClass={`${Style.cancelButtonPopover}`}>
              <div className={`btn btn-outline-danger ${Style.rejectButton}`}>
                <span className="pl-1">Cancel</span>
              </div>
            </PopoverContainer>
          )}
        </>
      );
    }
    return (
      <>
        {!booking ? (
          <Button
            cssClass={Style.btnWidth}
            label={getbuttonLabel()}
            clickHandler={submitHandle}
            type={getButtonType()}
          />
        ) : (
          <PopoverContainer itemComponent={popoverComponent} cssClass={`${Style.cancelButtonPopover}`}>
            <div className={`mr-3 ${Style.cancelBooking}`}>
              {!isCastingCall && <img src={cancelSvg} alt="" />}
              <span className="mb-0 pl-1">Cancel</span>
            </div>
          </PopoverContainer>
        )}
      </>
    );
  };

  if (isCancelOnly) {
    if (!booking) return <></>;
    if (type) {
      return (
        <>
          {booking.status === JOB_STATUS_PENDING || booking.status === JOB_STATUS_REQUESTED ? (
            <div className={`d-flex justify-content-between ${Style.roleDetailFooter}`}>
              <Button
                icon={cancelSvg}
                type={type}
                cssClass={`${Style.saveforlater} w-25`}
                label="Cancel Booking"
                clickHandler={() => handleProcessRequest('cancel')}
              />
              <Button
                icon={cancelSvg}
                // type="customPending"
                type={type === 'customCancelPending' ? 'customPending' : type}
                cssClass={`${Style.saveforlater} pe-none`}
                label="Pending"
                clickHandler={() => {}}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-end">
              <PopoverContainer itemComponent={popoverComponent} cssClass={`${Style.cancelButtonPopover}`}>
                <div className="paddingLeft30 paddingBottom30">
                  <div className={`${Style.customCancelDiv} mr-3`}>
                    {!isCastingCall && <img src={cancelSvg} alt="" />}
                    <span className="mb-0 pl-1">Cancel Booking</span>
                  </div>
                </div>
              </PopoverContainer>
              <Button
                icon={calendarSvg}
                // type="customPending"
                type={type}
                cssClass={`${Style.customBookedBtn} mr-4`}
                label="Booked"
                clickHandler={() => {}}
              />
            </div>
          )}
        </>
      );
    }

    return (
      <>
        {booking.status === JOB_STATUS_PENDING || booking.status === JOB_STATUS_REQUESTED || type ? (
          <div
            className={Style.cancelButton}
            onClick={() => handleProcessRequest('cancel')}
            onKeyPress={() => handleProcessRequest('cancel')}
            role="button"
            tabIndex={0}
          >
            <img src={cancelSvg} alt="" />
            <span className="mb-0 pl-1">Cancel</span>
          </div>
        ) : (
          <PopoverContainer itemComponent={popoverComponent} cssClass={`${Style.cancelButtonPopover}`}>
            <div className={Style.cancelButton}>
              <img src={cancelSvg} alt="" />
              <span className="mb-0 pl-1">Cancel</span>
            </div>
          </PopoverContainer>
        )}
      </>
    );
  }

  if (booking && (booking.status === JOB_STATUS_REQUESTED || booking.status === JOB_STATUS_PENDING)) {
    return (
      <>
        {isCastingCall ? (
          <div
            className={`btn btn-outline-danger ${Style.rejectButton}`}
            onClick={() => handleProcessRequest('cancel')}
            onKeyPress={() => handleProcessRequest('cancel')}
            role="button"
            tabIndex={0}
          >
            <span className="pl-1">Cancel</span>
          </div>
        ) : (
          <>
            <Button
              cssClass={Style.btnWidth}
              label="Cancel"
              clickHandler={() => handleProcessRequest('cancel')}
              type={getButtonType()}
            />
            {booking.status === JOB_STATUS_REQUESTED && (
              <Button
                cssClass={Style.btnWidth}
                label={getbuttonLabel()}
                clickHandler={submitHandle}
                type={getButtonType()}
              />
            )}
          </>
        )}
      </>
    );
  }

  return (
    <>
      {checkBookedBooking()}
      {isConfirmation && (
        <Modal title="Confirmation Alert!" body={ModalBody} closeModal={() => setIsConfirmation(false)} />
      )}
    </>
  );
};
const mapDispatchToProps = {
  acceptCastingRequest: acceptCastingRequestBegin,
};
const mapStateToProps = (state: ShowDayJobList) => {
  const allBookingRequest = getAllBookingRequest(state);
  return {
    allBookingRequest,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingButton);
