import styled from 'styled-components';

interface Props {
  open?: boolean;
  className?: string;
}

const StyledDropDownWrapper = styled.div<Props>`
  height: fit-content;
  position: relative;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  box-sizing: border-box;
  position: absolute;
  padding: 10px 0;
  width: 167px;
  color: blue;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 121px;
  gap: 14px;
  left: calc(50% - 167px / 2 + 10px);
  top: 45px;
  border: 2px solid ${({ theme }) => theme.colors.lightBlue};
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  z-index: 999;
  &::after {
    content: '';
    position: absolute;
    height: 14px;
    width: 14px;
    right: 24px;
    top: -8px;
    background: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.lightBlue};
    border-right: transparent;
    border-bottom: transparent;
    transform: rotate(45deg);
  }
`;

export default StyledDropDownWrapper;
