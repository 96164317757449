import React from 'react';
import styled from 'styled-components';
import logo from 'assets/images/bg-profile.png';
import { Show } from 'modules/Show/show.types';
import ShowItem from './ShowItem';

interface Props {
  showList?: Show[];
}

// const dummyShows = [
//   {
//     showName: 'Show Name',
//     imageUrl: logo,
//   },
//   {
//     showName: 'Show Name',
//     imageUrl: logo,
//   },
//   {
//     showName: 'Show Name',
//     imageUrl: logo,
//   },
//   {
//     showName: 'Show Name',
//     imageUrl: logo,
//   },
//   {
//     showName: 'Show Name',
//     imageUrl: logo,
//   },
//   {
//     showName: 'Show Name',
//     imageUrl: logo,
//   },
//   {
//     showName: 'Show Name',
//     imageUrl: logo,
//   },
//   {
//     showName: 'Show Name',
//     imageUrl: logo,
//   },
//   {
//     showName: 'Show Name',
//     imageUrl: logo,
//   },
//   {
//     showName: 'Show Name',
//     imageUrl: logo,
//   },
// ];

const ShowList: React.FC<Props> = (props) => {
  const { showList } = props;
  return (
    <StyledList>
      {showList?.map((show, i) => (
        <ShowItem showName={show.title} imageUrl={show.showImage || logo} key={i} />
      ))}
    </StyledList>
  );
};

export default ShowList;

const StyledList = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  min-height: 62px;
  width: 100%;
  z-index: 9999;
  padding: 17px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
`;
