import React, { FunctionComponent, useState } from 'react';
import { ShowWorkingDay } from 'modules/Show/show.types';
import DashboardInput from 'components/controls/input/dashboardInput/dashboardInput';
import DateInput from 'components/controls/input/DateInput';
import { getCurrentDate, isActiveDate, isPastDate } from 'helpers/utils';
import Button from 'components/controls/button/button';
import Style from 'components/CreateShow/CreateShow.module.css';
import deleteSvg from 'assets/svg/delete-white.svg';
import CPModal from 'components/Modal/CPModal';
import moment from 'moment';

interface SeriesEpisodeProps {
  episodeDayData: ShowWorkingDay;
  handleChangeEpisodeData: Function;
  lastIndex?: any;
  createShowClass?: string;
}
const EpisodeDayInput: FunctionComponent<SeriesEpisodeProps> = (props) => {
  const { episodeDayData, handleChangeEpisodeData, lastIndex, createShowClass } = props;
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleChangeDayTitle = (e: React.FormEvent<HTMLInputElement>) => {
    const episode: ShowWorkingDay = {
      id: episodeDayData.id,
      episodeId: episodeDayData.episodeId,
      showId: episodeDayData.showId,
      title: e.currentTarget.value,
      date: episodeDayData.date,
      isNew: episodeDayData.isNew,
      type: episodeDayData.type,
    };
    handleChangeEpisodeData(episode);
  };

  const handleChangeDate = (e: string) => {
    const episode: ShowWorkingDay = {
      id: episodeDayData.id,
      episodeId: episodeDayData.episodeId,
      showId: episodeDayData.showId,
      title: episodeDayData.title,
      date: e.length <= 10 ? `${e} 00:00:00` : e,
      isNew: episodeDayData.isNew,
      type: episodeDayData.type,
      isDateChange: true,
    };
    handleChangeEpisodeData(episode);
  };

  const handleDelete = () => {
    if (episodeDayData.isNew) {
      deleteEpisode(true);
    } else {
      setShowModal(true);
    }
  };

  const checkDate = () => {
    if (episodeDayData.date) {
      if (episodeDayData.date === 'Invalid date') return getCurrentDate();
      return episodeDayData.date;
    }
    return '';
  };

  const handleModalClose = (isYes: boolean) => {
    deleteEpisode(isYes);
    setShowModal(false);
  };

  const deleteEpisode = (isDelete: boolean) => {
    const episode: ShowWorkingDay = {
      id: episodeDayData.id,
      episodeId: episodeDayData.episodeId,
      showId: episodeDayData.showId,
      title: episodeDayData.title,
      date: episodeDayData.date,
      isNew: episodeDayData.isNew,
      type: episodeDayData.type,
      isDelete,
    };
    handleChangeEpisodeData(episode);
  };

  return (
    <>
      <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row column-gap-20">
        <div className={`w-100 w-sm-50 w-md-50 w-lg-50 w-xl-50 ${Style.marginRightMobile}`}>
          <DashboardInput
            type="text"
            length={10}
            value={episodeDayData.title ?? ''}
            inputChangeHandler={handleChangeDayTitle}
          />
        </div>
        <div className={`w-100 w-sm-50 w-md-50 w-lg-50 w-xl-50 d-flex ${Style.marginRightMobile}`}>
          <DateInput
            cssClass={`date-input-style ${Style.dateInputEpisode}`}
            customClass={Style.singleWrapper}
            createShowClass={createShowClass}
            value={checkDate()}
            minDate={moment()}
            maxDate={moment().add(2, 'month')}
            disabled={isPastDate(episodeDayData.date)}
            handleChange={handleChangeDate}
          />
          {!lastIndex && isActiveDate(episodeDayData.date) && (
            <div className="">
              <Button cssClass={Style.seriesMinusBtn} icon={deleteSvg} clickHandler={handleDelete} />
            </div>
          )}
        </div>
      </div>
      <CPModal isShow={showModal} hideModal={handleModalClose} labelOk="Accept">
        <p>you must close bookings before you can delete this day. Please be aware job postings will be lost forever</p>
      </CPModal>
    </>
  );
};

export default EpisodeDayInput;
