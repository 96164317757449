import React, { FunctionComponent, useEffect, useState } from 'react';
import { ImageFolderItem, User } from 'modules/user/types';
import BasicInfo from 'components/castingDirector/BasicInfo/BasicInfo';
import Images from 'components/castingDirector/Images/Images';
import Skill from 'components/castingDirector/skills/Skill';
import Style from 'views/castingDirector/profile/DirectorProfileView.module.css';
import TopNavBar from 'components/TopNavBar/TopNavBar';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';
import { ProfileKey, ROLE_DIRECTOR } from 'store/castingPax.constants';
import api from 'store/services/myPerformer.services';
import ApiResponse from 'modules/response/response.types';
import Loader from 'components/controls/Loader/Loader';
import { performerIdInLC, performerIdInSession } from 'helpers/utils';
import { connect, useDispatch } from 'react-redux';
import { getUser } from 'store/selector/auth.selector';
import { setSelectedTab } from 'store/actions/auth.actions';
import { getShowListingRequest } from 'store/actions/show.actions';

interface IProfileProp {
  Role: User;
}
const DirectorProfileView: FunctionComponent<IProfileProp> = (props) => {
  const { Role } = props;
  const [userObj, setUserObj] = useState<User>();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedTab('9'));
    let id: string = performerIdInLC.get();

    if (id) {
      performerIdInLC.clear();
      performerIdInSession.save(id);
    } else if (performerIdInSession.get()) {
      id = performerIdInSession.get();
    } else {
      const savedUser = getItemFromLocalStorage(ProfileKey);
      if (savedUser) {
        setUserObj(savedUser);
        id = savedUser.id;
      }
    }

    if (!id) {
      dispatch(getShowListingRequest());
      return;
    }
    setLoading(true);

    api
      .fetchPerformerViewDetail({ userId: id })
      .then((res: ApiResponse) => {
        if (setUserObj) {
          setUserObj(res.data);
          setLoading(false);
        }
      })
      .catch(() => {});
    if (setLoading) {
      setLoading(false);
    }
    dispatch(getShowListingRequest());
  }, []);

  return (
    <>
      <TopNavBar />
      {userObj && (
        <div className={`${Style.MainWrapper}`}>
          <div className={`${Style.BasicInfoWrapper}`}>
            <BasicInfo profile={userObj} />
          </div>
          {loading && <Loader isSmallView />}
          {userObj.bgPerformer && userObj.bgPerformer.images && Role.role === ROLE_DIRECTOR && (
            <div>
              {userObj.bgPerformer?.images.map((i: ImageFolderItem) => (
                <div className={`${Style.ImageWrapper}`}>
                  <Images item={i} />
                </div>
              ))}
            </div>
          )}
          {userObj.bgPerformer && Role.role === ROLE_DIRECTOR && (
            <div className={Style.SkillWrapper}>
              <Skill skills={userObj.bgPerformer?.skills || []} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  const Role = getUser(state);
  return { Role };
};

export default connect(mapStateToProps, null)(DirectorProfileView);
