import React, { FunctionComponent, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Style from 'components/TopNavBar/TopNavBar.module.css';
import userSvg from 'assets/svg/profile.svg';
import UserAvatar from 'assets/images/exp1.png';
import payrollSvg from 'assets/svg/payroll.svg';
import voucherSvg from 'assets/svg/voucher.svg';
import settingSvg from 'assets/svg/setting.svg';
import logoutSvg from 'assets/svg/logout.svg';
import { useDispatch, useSelector } from 'react-redux';
import { callLogout, setSelectedTab } from 'store/actions/auth.actions';
import { useHistory } from 'react-router';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import { getUser } from 'store/selector/auth.selector';
import { ROLE_PERFORMER } from 'store/castingPax.constants';
import PusherContext from 'pusherContext';
import ProfileImageView from 'components/controls/imageViewer/profileImageViewer';

const TopBarUserIcon: FunctionComponent = () => {
  const [toggle, setToggle] = useState(false);

  const toggleMenu = () => setToggle(true);
  const handleClick = () => setToggle(false);

  const user = useSelector(getUser || null);
  const history = useHistory();
  const dispatch = useDispatch();

  const { unSubscribeChannel } = useContext(PusherContext);

  const handleLogout = () => {
    unSubscribeChannel();
    dispatch(callLogout());
    history.push('/login');
  };

  const handleSettingClick = () => dispatch(setSelectedTab('9'));

  return (
    <div className={`position-relative ${Style.profileWrapper}`}>
      <div
        className="d-flex justify-content-end align-items-center"
        onClick={toggleMenu}
        onKeyPress={toggleMenu}
        role="button"
        tabIndex={0}
      >
        <ProfileImageView src={user?.profilePicUrl} alt="" cssClass={Style.profilePicture} />
        <img src={downArrowSvg} className={`web-display-only ml-2 ${Style.arrowDown}`} alt="down-arrow" />
      </div>
      {toggle && (
        <div>
          <div className={Style.profileListing}>
            <div className={Style.profileHeader}>
              <h3>{`${user?.firstName || 'John'} ${user?.lastName || 'Doe'}`}</h3>
            </div>

            <div className={Style.profileBody}>
              <ul>
                {user?.role !== ROLE_PERFORMER && (
                  <li>
                    <Link to="/my-profile">
                      <img src={userSvg} alt="profile" />
                      My profile
                    </Link>
                  </li>
                )}

                {user?.role === ROLE_PERFORMER && (
                  <>
                    {/* <li>
                      <Link to="/performer/home">
                        <img src={payrollSvg} alt="payroll" />
                        Payroll
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/voucher_info">
                        <img src={voucherSvg} alt="voucher" />
                        Voucher
                      </Link>
                    </li>
                  </>
                )}
                {Object.keys(user).length === 0 && (
                  <>
                    <li>
                      <Link to="/performer/home">
                        <img src={payrollSvg} alt="payroll" />
                        Payroll
                      </Link>
                    </li>
                    <li>
                      <Link to="/voucher_info">
                        <img src={voucherSvg} alt="voucher" />
                        Voucher
                      </Link>
                    </li>
                  </>
                )}
                <li>
                  <Link to="/settings" onClick={handleSettingClick}>
                    <img src={settingSvg} alt="cog" />
                    Settings
                  </Link>
                </li>
                <li>
                  <div
                    onClick={handleLogout}
                    onKeyPress={handleLogout}
                    role="button"
                    tabIndex={0}
                    className={Style.logoutText}
                  >
                    <img src={logoutSvg} alt="logout" />
                    Logout
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={Style.profileBgColor}
            onClick={handleClick}
            onKeyPress={handleClick}
            role="button"
            tabIndex={0}
          />
        </div>
      )}
    </div>
  );
};

export default TopBarUserIcon;
