import React, { FunctionComponent, useState } from 'react';
import Style from 'components/castingDirector/ContactSection/ContactItem.module.css';
import arrowDownSvg from 'assets/svg/arrowDown.svg';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import CallDetail from 'components/castingDirector/PopOvers/CallDetail/CallDetail';
import StatusDetail from 'components/castingDirector/PopOvers/StatusDetail/StatusDetail';
import SendEmail from 'components/castingDirector/PopOvers/CallDetail/SendEmail';
import { MatrixType, PerformerItem } from 'modules/Show/show.types';
import ShareMatrix from 'components/castingDirector/PopOvers/Share/ShareMatrix';
import moment from 'moment';

interface MatrixContactItemProps {
  title: string;
  icon: string;
  type: string;
  workingDay: MatrixType;
  cssClass?: string;
}
const MatrixContactItem: FunctionComponent<MatrixContactItemProps> = (props: MatrixContactItemProps) => {
  const { title, icon, type, workingDay, cssClass } = props;
  const [toggleNewTemplate, setToggleNewTemplate] = useState<boolean>(false);

  const getComponent = () => {
    switch (type) {
      case 'call':
        return <CallDetail workingDay={workingDay} />;
      case 'status':
        return (
          <StatusDetail
            workingDayId={workingDay.id}
            // status={workingDay.status}
          />
        );
      case 'share':
        return <ShareMatrix workingDayId={workingDay.id} />;
      default:
        return '';
    }
  };

  const checkExistPerformers = () => {
    if (workingDay.performers && workingDay.performers.length > 0) {
      const existPerformers: PerformerItem[] = workingDay.performers.filter((p: PerformerItem) => p.performerId);
      return existPerformers.length;
    }
    return 0;
  };

  const getStatusClass = () => {
    const exist: number = checkExistPerformers();
    const currentDate = moment();
    const matrixDate = moment(workingDay.date);
    if (matrixDate.isBefore(currentDate)) {
      return exist > 0 ? Style.pastCompleted : Style.unAvailable;
    }
    if (matrixDate.isAfter(currentDate)) {
      return exist > 0 ? Style.Pending : Style.unAvailable;
    }
    return Style.completed;
  };

  if (type === 'status') {
    return (
      <>
        <PopoverContainer itemComponent={() => getComponent()}>
          <div className={`d-flex align-items-center ${Style.contactSection}  ${cssClass} `}>
            <div className={`${Style.statusBg} ${Style.statusSectionWrapper}`}>
              <div className={`${Style.statusIcon} ${getStatusClass()}`} />
            </div>
            {/* <p className={`mb-0 ${Style.contactTitle}`}>{title}</p> */}
            <img src={arrowDownSvg} alt="" />
          </div>
        </PopoverContainer>
      </>
    );
  }

  return (
    <>
      <PopoverContainer itemComponent={() => getComponent()}>
        <div className={`d-flex align-items-center ${Style.contactSection} ${cssClass}`}>
          <img src={icon} alt="" className={`${type !== 'share' && 'mr-3'}`} />
          {type !== 'share' && <p className={`mb-0 w-100 ${Style.contactTitle}`}>{title}</p>}
          {type !== 'share' && <img src={arrowDownSvg} alt="" className="ml-3" />}
        </div>
      </PopoverContainer>
      {toggleNewTemplate && <SendEmail closeDialog={setToggleNewTemplate} />}
    </>
  );
};

export default MatrixContactItem;
