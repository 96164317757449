import { redirectPath } from 'helpers/utils';
import { RoleCode } from 'modules/user/types';
import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';

const PrivateRoute = ({ component, path, url, routeRole }: any) => {
  const user = getItemFromLocalStorage('user') || {};
  const role = user.role as RoleCode;
  if (!role) {
    return <Redirect to={{ pathname: '/Home' }} />;
  }
  if (routeRole === 'ADMIN' || routeRole.includes(role)) {
    if (path.includes('job')) redirectPath.save(url);
    return <Route exact path={path} component={withRouter(component)} />;
  }
  return <Redirect to={{ pathname: `/${role === 'DIRECTOR' ? 'casting' : role}/home` }} />;
};

export default PrivateRoute;
