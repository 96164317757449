import { connect } from 'react-redux';
import { Show } from 'modules/Show/show.types';
import { getCustomPerformerRateList, getPerformerRateList } from 'store/selector/show.selector';
import BreakDown from 'components/Payroll/VoucherManagement/BreakDown/BreakDown';

const mapStateToProps = (state: Show) => {
  const allRates = getPerformerRateList(state);
  const allCustomRates = getCustomPerformerRateList(state);

  return { allRates, allCustomRates };
};

export default connect(mapStateToProps, null)(BreakDown);
