import { connect } from 'react-redux';
import Update from 'components/bgPerformer/Update/Update';
import { castingUpdateRequestBegin, markedJobUpdateStatusBegin } from 'store/actions/job.actions';
import { getCastingUpdates, getCastingUpdatesLoading } from 'store/selector/job.selector';
import { Show } from 'modules/Show/show.types';

const mapStateToProps = (state: Show) => {
  const updatedNews = getCastingUpdates(state);
  const loading = getCastingUpdatesLoading(state);
  return {
    updatedNews,
    loading,
  };
};

const mapDispatchToProps = {
  getUpdateNews: castingUpdateRequestBegin,
  markedRead: markedJobUpdateStatusBegin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Update);
