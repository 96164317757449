import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import Style from 'components/Producer/Matrix/UserManagementItem/ShowAccess/ShowAccessItem.module.css';
import plusSvg from 'assets/svg/plus-green.svg';
import cameraGreenSvg from 'assets/svg/camera-green.svg';
import threeDotsSvg from 'assets/svg/three-dots.svg';
import ShowListItem from 'components/Producer/Matrix/UserManagementItem/ShowAccess/ShowItem';
import { ShowTeamMember } from 'modules/Show/show.types';
import showApi from 'store/services/show.services';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import ShowSearch from 'components/Producer/Matrix/UserManagementItem/ShowAccess/Search/ShowSearch';

interface ShowListingProps {
  userId: string;
  showRole: string;
  handleCancelAccess: Function;
}
const ShowListing: FunctionComponent<ShowListingProps> = (props: ShowListingProps) => {
  const { userId, showRole, handleCancelAccess } = props;
  const mountedRef = useRef(true);
  const [list, setList] = useState<ShowTeamMember[]>([]);

  const showLimit: number = 2;
  useEffect(() => {
    showApi
      .fetchUserShowAccess({ id: userId })
      .then((res: ShowTeamMember[]) => {
        if (!mountedRef.current) return null;
        setList(res);
        return true;
      })
      .catch((err) => err);

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const filterList = (isMore: boolean) => {
    if (list.length > showLimit) {
      if (isMore) return list.slice(2, list.length);
      return list.slice(0, showLimit);
    }
    return list;
  };

  const onCancelAccess = (item: ShowTeamMember) => {
    const member = item;
    member.callBack = callBackRemoveItem;
    handleCancelAccess(member);
  };

  const callBackRemoveItem = (id: string) => {
    console.log('Cancel Item', id);
    const index = list.findIndex((i: ShowTeamMember) => i.id === id);
    if (index !== -1) {
      list.splice(index, 1);
      setList([...list]);
    }
  };

  const popoverComponent = () => (
    <div className={`d-flex flex-wrap justify-content-between ${Style.showListWrapper}`}>
      {filterList(true).map((item: ShowTeamMember) => (
        <ShowListItem key={item.id} item={item} onCancelAccess={onCancelAccess} />
      ))}
    </div>
  );

  const showMore = () => {
    if (list.length > showLimit) {
      return (
        <PopoverContainer itemComponent={popoverComponent}>
          <div className={`${Style.showAccessMoreWrapper}`}>
            <img src={threeDotsSvg} alt="" />
          </div>
        </PopoverContainer>
      );
    }
    return <></>;
  };

  const handleAddShow = async (showId: string) => {
    try {
      const result = await showApi.addShowAccess({ showId, showRole, userId });

      if (setList) {
        const prevList = list;
        setList([...prevList, result]);
      }
    } catch (err) {
      console.log('Error', err);
    }
  };

  const showIds = useMemo(() => list.map((l: ShowTeamMember) => l.showId.id), [list]);

  const componentSearchShow = () => <ShowSearch showIds={showIds} shows={[]} handleAddShow={handleAddShow} />;

  return (
    <div className="d-flex justify-content-between w-100">
      <div className="w-100">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex justify-content-between align-items-center">
            <img src={cameraGreenSvg} alt="" />
            <p className={`mb-0 ml-2 ${Style.showAccessTitle}`}>Show Access</p>
          </div>
          <PopoverContainer itemComponent={componentSearchShow}>
            <div>
              <img src={plusSvg} alt="" />
            </div>
          </PopoverContainer>
        </div>
        <div className={`d-flex flex-wrap ${Style.showListWrapper}`}>
          {filterList(false).map((item: ShowTeamMember) => (
            <ShowListItem key={item.id} item={item} onCancelAccess={onCancelAccess} />
          ))}
        </div>
      </div>
      {showMore()}
    </div>
  );
};

export default ShowListing;
