/* eslint-disable import/prefer-default-export */
const ShowPerformerRowData = [
  {
    name: 'lorem',
    type: 'add',
  },
  {
    name: 'lorem',
    type: 'add',
  },
  {
    name: 'lorem',
    type: 'add',
  },
  {
    name: 'lorem',
    type: 'add',
  },
  {
    name: 'lorem',
    type: 'delete',
  },
  {
    name: 'lorem',
    type: 'delete',
  },
  {
    name: 'lorem',
    type: 'delete',
  },
];

export { ShowPerformerRowData };
