import React, { FunctionComponent } from 'react';
import Styled from 'styled-components';

import { VoucherDashboardInterface } from './Interface/DashboardInterface';
import VoucherDashboard from './voucher/Voucher/index';

const VoucherManagementPageComponent: FunctionComponent<VoucherDashboardInterface> = (
  props: VoucherDashboardInterface,
) => {
  const { showSideNav } = props;
  return (
    <DashboardContainer>
      <Dashboard>
        <VoucherDashboard showSideNav={showSideNav} />
      </Dashboard>
    </DashboardContainer>
  );
};
const DashboardContainer = Styled.div`
display: flex;
flex-direction: column;
min-height: 1045px;
`;

const Dashboard = Styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1600px;
  // min-width: 1080px;
  width: 100%;
  flex: 1;
  @media only screen and (max-width: 1199px) {
    // min-width: 950px;
  }
  @media only screen and (max-width: 991px) {
    // min-width: 645px;
  }
`;

export default VoucherManagementPageComponent;
