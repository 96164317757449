import React, { FunctionComponent } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { getMaxDate, getMinDate } from 'helpers/utils';

interface DateRangeProps {
  startDate: string;
  endDate: string;
  children: any;
  handleDateChange: Function;
  minDate?: string;
}

const DateRange: FunctionComponent<DateRangeProps> = (props: DateRangeProps) => {
  const { startDate, endDate, children, handleDateChange, minDate } = props;

  const maxDate = getMaxDate('MM-DD-YYYY');

  const handleCallback = (start: any, end: any) => {
    handleDateChange(start, end);
  };
  const getMinimumDate: any = () => {
    if (minDate) return minDate;
    return getMinDate('MM-DD-YYYY');
  };
  return (
    <>
      <DateRangePicker
        initialSettings={{ startDate, endDate, minDate: getMinimumDate(), maxDate }}
        onCallback={handleCallback}
      >
        {children}
      </DateRangePicker>
    </>
  );
};

export default DateRange;
