import { connect } from 'react-redux';
import Profile from 'views/bgperformer/profile/Profile';
import { SkillCategory } from 'modules/general/general.type';
import {
  getCounter,
  getEditableId,
  getFolderId,
  getProfile,
  getRentalLoading,
  profileLoading,
  showLoading,
} from 'store/selector/performer.selector';
import {
  changeImageOrderBegin,
  updateRentalTypeBegin,
  deleteImageBegin,
  deleteResumeBegin,
  openFullViewImage,
  performerProfileBegin,
  removeRentalImageBegin,
  savePerformerImageBegin,
  updateCounter,
  uploadRentalImageBegin,
} from 'store/actions/performer.actions';
import { getUser } from 'store/selector/auth.selector';
import { uploadProfileImageBegin } from 'store/actions/auth.actions';
import { getRentalOpt } from 'store/selector/general.selector';

const mapStateToProps = (state: SkillCategory) => {
  const profileData = getProfile(state);
  const user = getUser(state);
  const loading = showLoading(state);
  const profileDataLoading = profileLoading(state);
  const counter = getCounter(state);
  const newFolderId = getFolderId(state);
  const editableId = getEditableId(state);
  const rentalOpt = getRentalOpt(state);
  const rentalLoading = getRentalLoading(state);
  return { profileData, user, loading, profileDataLoading, counter, newFolderId, editableId, rentalOpt, rentalLoading };
};

const mapDispatchToProps = {
  performerProfileBegin,
  uploadImage: savePerformerImageBegin,
  changeImageOrderBegin,
  deleteImageBegin,
  openFullViewImage,
  uploadProfileImageBegin,
  deleteResumeBegin,
  updateCounter,
  uploadRentalImage: uploadRentalImageBegin,
  removeRentalImage: removeRentalImageBegin,
  updateRentalType: updateRentalTypeBegin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
