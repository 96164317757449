import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../combineReducers';

export interface Payroll {
  title: string;
  createdAt: string;
}

const initialState: Payroll[] = [{ title: 'Dummy', createdAt: new Date().toISOString() }];

export const payrollSlice = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    addPayroll: {
      reducer: (state, { payload }: PayloadAction<{ title: string; createdAt: string }>) => {
        state.push(payload);
      },
      prepare: ({ title }: { title: string }) => ({
        payload: {
          createdAt: new Date().toISOString(),
          title,
        },
      }),
    },
  },
});

export const selectPayroll = (state: RootState) => state.performer;
export const { addPayroll } = payrollSlice.actions;
export default payrollSlice.reducer;
