import React, { FunctionComponent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faExternalLinkAlt,
  faEnvelope,
  faMapMarkerAlt,
  faMobileAlt,
} from '@fortawesome/free-solid-svg-icons';
import Style from 'components/CreateShow/Detail/UserItem/Item.module.css';
import { User } from 'modules/user/types';
import Info from 'components/CreateShow/Detail/UserItem/Info';

interface UserItemProps {
  user: User;
  isExpanded: (e: React.MouseEvent<HTMLOrSVGElement>) => void;
}
const UserItem: FunctionComponent<UserItemProps> = (props: UserItemProps) => {
  const [isExpand, setIsExpand] = useState(false);
  const { user, isExpanded } = props;
  console.log('User : ', user, isExpanded);
  const handleDetail = () => console.log();

  return (
    <>
      {isExpand ? (
        <>
          <thead>
            <tr className={`mb-2 ${Style.userExpandedWrapper}`}>
              <td>
                <div className={`d-flex ${Style.userList}`}>
                  {/* <p className={`mb-0 ${Style.userNameExpanded}`}>{user.name}</p> */}
                  <p className={`mb-0 ${Style.userNameExpanded}`}>Lisa Rose</p>
                </div>
              </td>
              <td>
                {/* <p className={`mb-0 ${Style.userRole}`}>{user.roles}</p> */}
                <p className={`mb-0 ${Style.userRole}`}>Casting</p>
              </td>
              <td>
                <div className={`${Style.actionIcons}`}>
                  <FontAwesomeIcon icon={faExternalLinkAlt} onClick={handleDetail} />
                  <FontAwesomeIcon icon={faChevronUp} onClick={() => setIsExpand(false)} />
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className={`mb-2 ${Style.userExpandedWrapper}`}>
              <td>
                <div className={`${Style.userExpandedList}`}>
                  <img src="" alt="" />
                </div>
              </td>
              <td>
                <Info icon={faEnvelope} title={user.email} />
              </td>
              <td>
                <Info icon={faMapMarkerAlt} title={user.address} />
              </td>
              <td>
                <Info icon={faMobileAlt} title={user.phoneNo} />
              </td>
            </tr>
          </tbody>
        </>
      ) : (
        <tr className={`mb-2 ${Style.userWrapper}`}>
          <td>
            <div className={`d-flex ${Style.userList}`}>
              <img src="" alt="" />
              <p className={`mb-0 ${Style.userName}`}>{user.name}</p>
            </div>
          </td>
          <td>
            <p className={`mb-0 ${Style.userRole}`}>{user.roles}</p>
          </td>
          <td>
            <div className={`${Style.actionIcons}`}>
              <FontAwesomeIcon icon={faExternalLinkAlt} onClick={handleDetail} />
              <FontAwesomeIcon icon={faChevronDown} onClick={() => setIsExpand(true)} />
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default UserItem;
