import getMonthName from './getMonthName';
import getDaysBefore from './getDaysBefore';
import getDaysAfter from './getDaysAfter';

interface DateListInterface {
  id: string;
  month: string;
  day: number;
  isSelected: boolean;
}

const dateListGenerator = (daysBefore: number, currentDate: Date = new Date(), daysAfter: number) => {
  const dateList: DateListInterface[] = [];
  const month = currentDate.getMonth();
  const monthName = getMonthName(month);
  const day = currentDate.getDate();
  const today = { id: '', month: monthName, day, isSelected: false };
  dateList.push(...getDaysBefore(daysBefore), today, ...getDaysAfter(daysAfter));
  return dateList;
};

export default dateListGenerator;
