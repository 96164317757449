import { IRateValues, IVoucherStatus, ICreateVoucher, IRateUpdate } from 'modules/rates/rate.types';
import { User } from 'modules/user/types';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const createRateValues = {
  pending: {
    type: ACTIONS.CREATE_RATE_VALUES.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.CREATE_RATE_VALUES.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CREATE_RATE_VALUES.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createRateValuesBegin = (payload: IRateValues) => ({
  type: ACTIONS.CREATE_RATE_VALUES_BEGIN,
  payload,
});

export const updateRateValues = {
  pending: {
    type: ACTIONS.UPDATE_RATE_VALUES.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.UPDATE_RATE_VALUES.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPDATE_RATE_VALUES.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const viewRateBegin = (payload: IRateUpdate) => ({
  type: ACTIONS.VIEW_RATE_TITLE_BEGIN,
  payload,
});

export const updateRateBegin = (payload: IRateUpdate) => ({
  type: ACTIONS.UPDATE_RATE_TITLE_BEGIN,
  payload,
});

export const updateRateTitle = {
  pending: {
    type: ACTIONS.UPDATE_RATE_TITLE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.UPDATE_RATE_TITLE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPDATE_RATE_TITLE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateRateValuesBegin = (payload: IRateValues) => ({
  type: ACTIONS.UPDATE_RATE_VALUES_BEGIN,
  payload,
});

export const getRateValues = {
  pending: {
    type: ACTIONS.VIEW_RATE_VALUES.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.VIEW_RATE_VALUES.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.VIEW_RATE_VALUES.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const viewRateValuesBegin = (payload: IRateValues) => ({
  type: ACTIONS.VIEW_RATE_VALUES_BEGIN,
  payload,
});

export const updateVoucherStatus = {
  pending: {
    type: ACTIONS.UPDATE_VOUCHER_STATUS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.UPDATE_VOUCHER_STATUS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPDATE_VOUCHER_STATUS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateVoucherStatusBegin = (payload: IVoucherStatus) => ({
  type: ACTIONS.UPDATE_VOUCHER_STATUS_BEGIN,
  payload,
});

export const createVoucher = {
  pending: {
    type: ACTIONS.CREATE_VOUCHER.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.CREATE_VOUCHER.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CREATE_VOUCHER.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createVoucherBegin = (payload: ICreateVoucher) => ({
  type: ACTIONS.CREATE_VOUCHER_BEGIN,
  payload,
});
