import React, { FunctionComponent } from 'react';
import { Show } from 'modules/Show/show.types';
import Style from 'components/ShowTopNavigation/ShowTopNav.module.css';
import { useLocation } from 'react-router';
import { openShowInNewTab } from 'helpers/utils';

interface ItemProps {
  item: Show;
  selected: boolean;
  handleClick: (event: React.FormEvent<HTMLDivElement>) => void;
}
const NavItem: FunctionComponent<ItemProps> = (props: ItemProps) => {
  const { item, selected, handleClick } = props;
  const cssClass = selected ? `${Style.navItemSelected} selected-item` : Style.navItem;

  const location = useLocation();

  const handleOnClick = (e: React.FormEvent<HTMLAnchorElement>) => {
    e.preventDefault();
  };

  const handleContextMenu = (e: React.FormEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    openShowInNewTab.save(item.id);
    window.open(location.pathname);
  };

  return (
    <div className={`${Style.navItemWrrapper}`}>
      <a href="/performer/home" onClick={handleOnClick} onContextMenu={handleContextMenu}>
        <div
          className={`navItem ${cssClass}`}
          onClick={handleClick}
          id={item.id}
          role="button"
          title={item.title}
          tabIndex={0}
          onKeyPress={handleClick}
        >
          <p className="mb-0">{item.title}</p>
        </div>
      </a>
    </div>
  );
};

export default NavItem;
