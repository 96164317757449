import { BookingRequestList } from 'modules/jobs/types';
import {
  IAddPerformerInMyList,
  ICreateMyPerformerList,
  IIDParams,
  IUserJobStatusParam,
  IPerformerDetail,
  IPerformerId,
  IPersonalNote,
  IPerformerSearchParam,
} from 'modules/params/param.type';
import { MyPerformerListType, PerformerList, PerformerNoteList } from 'modules/PerformerList/list.types';
import { ShowDayJob } from 'modules/Show/show.types';
import { ImageFolderList, User, UserList } from 'modules/user/types';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const getMyPerformerList = {
  pending: {
    type: ACTIONS.FETCH_MY_PERFORMER_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: MyPerformerListType) => ({
    type: ACTIONS.FETCH_MY_PERFORMER_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FETCH_MY_PERFORMER_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const getMyPerformerListRequest = () => ({
  type: ACTIONS.FETCH_MY_PERFORMER_LIST_BEGIN,
  data: {},
});

export const setPerformerForView = (performer: User) => ({
  type: ACTIONS.SET_SELECTED_PERFORMER_FOR_VIEW,
  data: performer,
});

export const createList = {
  pending: {
    type: ACTIONS.SET_CREATE_MY_PERFORMER_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: PerformerList) => ({
    type: ACTIONS.SET_CREATE_MY_PERFORMER_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SET_CREATE_MY_PERFORMER_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createMyPerformerListRequest = (payload: ICreateMyPerformerList) => ({
  type: ACTIONS.SET_CREATE_MY_PERFORMER_LIST_BEGIN,
  payload,
});

export const fetchPerformerDetail = {
  pending: {
    type: ACTIONS.FETCH_PERFORMER_DETAIL.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.FETCH_PERFORMER_DETAIL.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FETCH_PERFORMER_DETAIL.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const performerDetailBegin = (payload: IPerformerDetail) => ({
  type: ACTIONS.FETCH_PERFORMER_DETAIL_BEGIN,
  payload,
});

export const fetchPerformerBookings = {
  pending: {
    type: ACTIONS.FETCH_PERFORMER_BOOKINGS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: BookingRequestList) => ({
    type: ACTIONS.FETCH_PERFORMER_BOOKINGS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FETCH_PERFORMER_BOOKINGS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchPerformerBookingsBegin = (payload: IPerformerId) => ({
  type: ACTIONS.FETCH_PERFORMER_BOOKINGS_BEGIN,
  payload,
});

export const addPerfomerInMyList = {
  pending: {
    type: ACTIONS.SET_ADD_PERFOMER_IN_MY_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: string) => ({
    type: ACTIONS.SET_ADD_PERFOMER_IN_MY_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SET_ADD_PERFOMER_IN_MY_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const callAddPerformerInFavList = (payload: IAddPerformerInMyList) => ({
  type: ACTIONS.SET_ADD_PERFOMER_IN_MY_LIST_BEGIN,
  payload,
});

export const fetchPersonalNotes = {
  pending: {
    type: ACTIONS.FETCH_PERFORMER_PERSONAL_NOTE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: PerformerNoteList) => ({
    type: ACTIONS.FETCH_PERFORMER_PERSONAL_NOTE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FETCH_PERFORMER_PERSONAL_NOTE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchPersonalNoteBegin = () => ({
  type: ACTIONS.FETCH_PERFORMER_PERSONAL_NOTE_BEGIN,
  payload: {},
});

export const setPerformerPersonalNote = (data: IPersonalNote) => ({
  type: ACTIONS.SET_PERFORMER_PERSONAL_NOTE,
  data,
});

export const fetchPerformerIamges = {
  pending: {
    type: ACTIONS.FETCH_MY_PERFORMER_IMAGES.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ImageFolderList) => ({
    type: ACTIONS.FETCH_MY_PERFORMER_IMAGES.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FETCH_MY_PERFORMER_IMAGES.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchPerformerIamgesBegin = (payload: IIDParams) => ({
  type: ACTIONS.FETCH_MY_PERFORMER_IMAGES_BEGIN,
  payload,
});

export const setUserJobStatus = {
  pending: {
    type: ACTIONS.FETCH_USER_JOB_STATUS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ShowDayJob[]) => ({
    type: ACTIONS.FETCH_USER_JOB_STATUS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FETCH_USER_JOB_STATUS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchUserJobStatusBegin = (payload: IUserJobStatusParam) => ({
  type: ACTIONS.FETCH_USER_JOB_STATUS_BEGIN,
  payload,
});

export const fetchPeformerUpdatedData = (payload: IPerformerSearchParam) => ({
  type: ACTIONS.FETCH_PERFORMER_UPDATED_DETAILS_BEGIN,
  payload,
});

export const setPerformerUpdatedData = (data: UserList) => ({
  type: ACTIONS.SET_PERFORMER_UPDATED_DETAILS_IN_LIST,
  data,
});
