import React, { FunctionComponent, useState } from 'react';
import Styled from 'styled-components';
import ChevronComponent from './ChevronIcon';
// import { Tab } from 'react-tabs';

interface SideNavInterface {
  showSideNav: Boolean;
  icon?: string;
  title?: string;
  showChevron?: Boolean;
  showNavIcon?: Boolean;
}

interface DropDownInterface {
  showDropDown?: Boolean;
  setShowDropDown?: React.Dispatch<React.SetStateAction<boolean>>;
}
const SideNavTabItem: FunctionComponent<SideNavInterface> = (props: SideNavInterface) => {
  const { showSideNav, icon, title, showChevron, showNavIcon } = props;
  const [showDropDown, setShowDropDown] = useState(false);

  return (
    <TabContainer showSideNav={showSideNav}>
      <ItemContainer>
        {showNavIcon ? (
          <IconContainer>
            <NavIcon src={icon} />
          </IconContainer>
        ) : (
          <TabTextContainer title={title}>{title}</TabTextContainer>
        )}
        {showSideNav && showNavIcon && <TabTextContainer>{title}</TabTextContainer>}

        {showChevron && showSideNav && (
          <ChevronComponent showDropDown={showDropDown} setShowDropDown={setShowDropDown} />
        )}
      </ItemContainer>

      <DropDownContainer showDropDown={showDropDown}>
        <DropDownText>Hello</DropDownText>
      </DropDownContainer>
    </TabContainer>
  );
};
const TabContainer = Styled.div`
  background-color: #fff;
  display: flex;
  padding: 12px;
  flex-direction:column;
  align-items: center;
  width:  ${(props: SideNavInterface) => (props.showSideNav === true ? '250px' : '100%')};
`;

const ItemContainer = Styled.div`
display: flex;
width:100%;
height:100%;
`;

const IconContainer = Styled.div`
`;
const TabTextContainer = Styled.div`
display: none;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 22px;
align-items: center;
cursor: pointer;
transition: display 0.01s ease;
display: flex;
`;

const NavIcon = Styled.img`
fill: #4B4D67;
width: 20px;
height: 20px;
margin: 10px;
cursor: pointer;
`;
const DropDownContainer = Styled.div`
width: 100%;
display:  ${(props: DropDownInterface) => (props.showDropDown ? 'flex' : 'none')};
`;
const DropDownText = Styled.p`
font-size: 12px;
`;

export default SideNavTabItem;
