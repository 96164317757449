/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import Styled from 'styled-components';
import TopNavBar from 'components/TopNavBar/TopNavBar';
import SideNav from './PayrollSideNav';
import { UserManagementSideNavItemData } from '../Data/UserManagementDashboardData';
// import { ManinNavItemData } from '../Data/PayrollDashboardData';

interface MaindashboardProps {
  setSelectedIndex: (index: number) => void;
}

const MainDashBoard = () => {
  const [showSideNav, setShowSideNav] = useState(true);

  return (
    <DashboardContainer>
      <TopNavBar showSideNav={showSideNav} setShowSideNav={setShowSideNav} />
      <SideNav showChevron={false} sideNavData={UserManagementSideNavItemData} showSideNav={showSideNav} showNavIcon />
    </DashboardContainer>
  );
};

const DashboardContainer = Styled.div`
  // min-width: 1080px;
  background-color: #e5e5e5;
  margin: 0 auto;
  @media only screen and (max-width: 991px) {
    // min-width: 765px;
  }

`;
export default MainDashBoard;
