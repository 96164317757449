import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';

interface Props {}

const MoreFilesButton: React.FC<Props> = () => (
  <MoreButton>
    <ThreeDot icon={faEllipsisH} size="xs" />
  </MoreButton>
);

export default MoreFilesButton;

const MoreButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
`;

const ThreeDot = styled(FontAwesomeIcon)`
  color: #4b4d67;
  opacity: 0.8;
`;
