import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Show } from 'modules/Show/show.types';
import DownArrow from 'components/Payroll/common/DownArrow';
import { getShowListingRequest } from 'store/actions/show.actions';
import { getShowLists } from 'store/selector/show.selector';
import ShowDropdown from './ShowDropdown';

interface Props {
  getShowListing?: any;
  showList?: Show[];
}

const AllShows: React.FC<Props> = (props) => {
  const { showList, getShowListing } = props;
  useEffect(() => {
    getShowListing();
  }, []);
  const [openShow, setIOpenShow] = useState<boolean>(false);

  const toggleShow = () => setIOpenShow(!openShow);
  return (
    <StyledWrapper>
      <StyledShowButton onClick={toggleShow}>
        <p>All Shows</p>
        <div>
          <DownArrow toggleIcon={false} handleClick={toggleShow} />
        </div>
      </StyledShowButton>
      {openShow && <ShowDropdown showList={showList} />}
    </StyledWrapper>
  );
};
const StyledShowButton = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 10px 20px;
  padding-right: 16px;
  background: rgba(242, 153, 74, 0.1);
  border-radius: 10px;
  cursor: pointer;

  & > p {
    margin: 0;
    font-size: 16px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    color: #f2994a;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f2994a;
  }
`;

const StyledWrapper = styled.div`
  position: relative;
  right: 0px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
`;

const mapStateToProps = (state: Show) => {
  const showList = getShowLists(state);

  return {
    showList,
  };
};

const mapDispatchToProps = {
  getShowListing: getShowListingRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllShows);
