import React, { FunctionComponent } from 'react';
import Style from 'components/castingDirector/ContactSection/ContactItem.module.css';
import arrowDownSvg from 'assets/svg/arrowDown.svg';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import CoordinatorDetail from 'components/castingDirector/PopOvers/CoordinatorDetail/CoordinatorDetail';
import manageCoordinatorSvg from 'assets/svg/manage-coordinator.svg';

interface CoordinatorViewProps {
  workingDayId: string;
  handleClick: (e: React.FormEvent<HTMLDivElement>) => void;
}
const CoordinatorView: FunctionComponent<CoordinatorViewProps> = (props: CoordinatorViewProps) => {
  const { workingDayId, handleClick } = props;

  return (
    <PopoverContainer itemComponent={() => <CoordinatorDetail workingDayId={workingDayId} />}>
      <div
        className={`d-flex align-items-center ${Style.contactSection}`}
        onClick={handleClick}
        onKeyPress={handleClick}
        role="button"
        tabIndex={0}
      >
        <img src={manageCoordinatorSvg} alt="" className="mr-3" />
        <p className="mb-0 w-100">Coordinator</p>
        <img src={arrowDownSvg} alt="" className="ml-3" />
      </div>
    </PopoverContainer>
  );
};

export default CoordinatorView;
