import React, { FunctionComponent } from 'react';
import Style from 'components/bgPerformer/Booking/Booking.module.css';
import { ShowDayJob } from 'modules/Show/show.types';

interface BookingItemDetailProps {
  job: ShowDayJob;
}
const BookingItemDetail: FunctionComponent<BookingItemDetailProps> = (props) => {
  const { job } = props;
  return (
    <>
      <div className="mt-3 d-flex">
        <div className={`${Style.contentTitleColumn}`}>
          <p className={`mb-0 ${Style.detailTitle}`}>Role Title</p>
        </div>
        <div className={`${Style.contentDescriptionColumn}`}>
          <p className={`mb-0 ${Style.detailDesc}`}>{`- ${job.title}`}</p>
        </div>
      </div>
      <div className="d-flex">
        <div className={`${Style.contentTitleColumn}`}>
          <p className={`mb-0 ${Style.detailTitle}`}>Location</p>
        </div>
        <div className={`${Style.contentDescriptionColumn}`}>
          <p className={`mb-0 ${Style.detailDesc}`}>{job.location ? `- ${job.location.label}` : ''}</p>
        </div>
      </div>
      <div className="d-flex">
        <div className={`${Style.contentTitleColumn}`}>
          <p className={`mb-0 ${Style.detailTitle}`}>Height Range</p>
        </div>
        <div className={`${Style.contentDescriptionColumn}`}>
          <p className={`mb-0 ${Style.detailDesc}`}>{`- ${job.minHeight} ${job.maxHeight}`}</p>
        </div>
      </div>
      <div className="d-flex">
        <div className={`${Style.contentTitleColumn}`}>
          <p className={`mb-0 ${Style.detailTitle}`}>Ethnicity</p>
        </div>
        <div className={`${Style.contentDescriptionColumn}`}>
          <p className={`mb-0 ${Style.detailDesc}`}>{`- ${job.ethnicity}`}</p>
        </div>
      </div>
      <div className="d-flex">
        <div className={`${Style.contentTitleColumn}`}>
          <p className={`mb-0 ${Style.detailTitle}`}>Eye Color</p>
        </div>
        <div className={`${Style.contentDescriptionColumn}`}>
          <p className={`mb-0 ${Style.detailDesc}`}>{`- ${job.eyes}`}</p>
        </div>
      </div>
    </>
  );
};

export default BookingItemDetail;
