import moment from 'moment';
import React, { FunctionComponent } from 'react';
import download from 'assets/svg/download-arrow.svg';
import { DailyInvoice } from 'modules/general/general.type';
import api from 'store/services/show.services';

interface IitemProps {
  item: DailyInvoice;
}
const InvoiceItem: FunctionComponent<IitemProps> = (props: IitemProps) => {
  const { item } = props;
  const handleDownload = () => {
    try {
      api.sendInvoiceOnEmail({ id: item.id }).then((res) => console.log('response', res));
    } catch (err: any) {
      console.log(err.message);
    }
  };
  return (
    <>
      <tr>
        <td>
          <span onClick={handleDownload} onKeyPress={handleDownload} role="button" tabIndex={0}>
            {moment(item.createdAt).format('DD-MM-YYYY')}
          </span>
        </td>
        <td>{item.description}</td>
        <td>{`$${item.totalBill}`}</td>
        <td>{item.invoiceNo}</td>
        <td>
          <a href={item.file} download>
            <img src={download} alt="pdfDownload" />
          </a>
        </td>
      </tr>
    </>
  );
};

export default InvoiceItem;
