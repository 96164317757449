import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import Styled from 'styled-components';
import { Show } from 'modules/Show/show.types';
import {
  getChartData,
  getRecallRequest,
  getSelectedShow,
  getSelectedShowDate,
  getSelectedworkingDayId,
  getShowCalendarDates,
  getShowHistory,
  getShowCostBudget,
  getErrorNotes,
  getRecentActions,
  getNotesReview,
} from 'store/selector/show.selector';
import {
  clearSelectedJobListing,
  setShowSelectedDate,
  getShowHistoryRequest,
  getShowCostBudgetRequest,
  getErrorNotesData,
  getRecentActionsData,
  getNotesReviewData,
} from 'store/actions/show.actions';
import InprogressComponent from './Inprogress/InprogressComponent.container';
import BudgetHistoryComponent from './BudgetHistory/BudgetHistory';
import TotalCost from './TotalCost/TotalCost';
import ErrorNotesComponent from './ErrorNotes/ErrorNotes';
import RecentActionComponent from './RecentActions/RecentActions';
import NotesToReview from './NotesToReview/NotesToReview';
import TopButtons from './TopButtons/TopButtons';
import { PayrollDashboardInterface } from './Interface';

const MainDashBoard: FunctionComponent<PayrollDashboardInterface> = (props: PayrollDashboardInterface) => {
  const {
    chartData,
    showSideNav,
    selectedShow,
    showCalendarDates,
    selectedShowDate,
    selectedWorkingDayId,
    recallRequest,
    showHistory,
    fetchHistory,
    getShowCost,
    fetchCostBudget,
    errorNotes,
    fetchErrorNotes,
    recentActions,
    fetchRecentActions,
    notesReview,
    fetchNotesReview,
  } = props;
  console.log(
    showSideNav,
    selectedShow,
    showCalendarDates,
    selectedShowDate,
    selectedWorkingDayId,
    recallRequest,
    showHistory,
  );
  useEffect(() => {
    fetchHistory();
    fetchCostBudget();
    fetchErrorNotes();
    fetchRecentActions();
    fetchNotesReview();
  }, []);
  useEffect(() => {}, [showHistory, getShowCost, errorNotes, recentActions]);
  return (
    <DashboardContainer>
      <Dashboard>
        <TopButtons />
        <MainTopSection>
          <RightSection>
            <InprogressComponent />
            {showHistory && getShowCost && errorNotes ? (
              <>
                <BudgetHistoryComponent showHistory={showHistory} />
                <TotalCost chartData={chartData} selectedShow={chartData} showCostBudget={getShowCost} />
                <ErrorNotesComponent errorNotes={errorNotes} />
              </>
            ) : (
              <></>
            )}
          </RightSection>
        </MainTopSection>
        <MainBottomSection>
          {recentActions && <RecentActionComponent actionItems={recentActions} />}
          {notesReview && <NotesToReview noteItems={notesReview} />}
        </MainBottomSection>
      </Dashboard>
    </DashboardContainer>
  );
};

const DashboardContainer = Styled.div`
  display: flex;
  flex: 1;
  @media only screen and (max-width: 991px) {
    display: block;
  }
`;
const Dashboard = Styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1600px;
  // min-width: 1080px;
  flex: 1;
  @media only screen and (max-width: 1199px) {
    // min-width: 900px;
  }
  @media only screen and (max-width: 991px) {
    // min-width: 645px;
  }
`;

const MainTopSection = Styled.div`
  display: flex;
  height: 100%;
  max-height: 450px;

  @media only screen and (max-width: 1440px) {
    max-height: 508px;
  }
  @media only screen and (max-width: 991px) {
    max-height: 100%;
    // display: block;
  }
   
`;

const MainBottomSection = Styled.div`
display: flex;
margin-top: 40px;
justify-content: space-between;
height: 38%;
@media only screen and (max-width: 991px) {
flex-wrap: wrap;
margin-top: 20px;
margin-bottom: 20px;
height: auto;
}
`;

const RightSection = Styled.div`
display: flex;
justify-content: space-between;
// margin-left: 25px;
width: 100%;
height: 100%;
align-content: stretch;
@media only screen and (max-width: 991px) {
 flex-wrap: wrap;
 flex-direction: column;
}

`;

const mapStateToProps = (state: Show) => {
  const selectedWorkingDayId = getSelectedworkingDayId(state);
  const selectedShowDate = getSelectedShowDate(state);
  const showCalendarDates = getShowCalendarDates(state);
  const selectedShow = getSelectedShow(state);
  const recallRequest = getRecallRequest(state);
  const chartData = getChartData(state);
  const showHistory = getShowHistory(state);
  const getShowCost = getShowCostBudget(state);
  const errorNotes = getErrorNotes(state);
  const recentActions = getRecentActions(state);
  const notesReview = getNotesReview(state);
  return {
    selectedShow,
    showCalendarDates,
    selectedShowDate,
    selectedWorkingDayId,
    recallRequest,
    chartData,
    showHistory,
    getShowCost,
    errorNotes,
    recentActions,
    notesReview,
  };
};

const mapDispatchToProps = {
  setShowSelectedDate,
  clearSelectedJobListing,
  fetchHistory: getShowHistoryRequest,
  fetchCostBudget: getShowCostBudgetRequest,
  fetchErrorNotes: getErrorNotesData,
  fetchRecentActions: getRecentActionsData,
  fetchNotesReview: getNotesReviewData,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainDashBoard);
