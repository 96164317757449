import React from 'react';
import styled from 'styled-components';
import StyledDateBox from '../../styles/date-box/DateBox.styled';

interface DateItemProps {
  isSelected: boolean;
}
interface Props {
  isSelected: boolean;
  month: string;
  date: string;
  day: number;
  id: number;
  onClick: (id: number, date: any) => void;
}

const DateBox: React.FC<Props> = (props: Props) => {
  const { isSelected, month, day, date, id, onClick } = props;
  return (
    <StyledDateBox isSelected={isSelected} onClick={() => onClick(id, date)}>
      <MonthText>{month}</MonthText>
      <DayText isSelected={isSelected}>{day}</DayText>
    </StyledDateBox>
  );
};

export default DateBox;

const MonthText = styled.div``;
const DayText = styled.div<DateItemProps>`
  flex-direction: row;
  width: 100%;
  height: 18.86px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isSelected }) => (isSelected ? 'background: #49D360' : 'background: #F2F2F2')};
`;
