import { JobEvent } from 'modules/jobs/types';
import React, { FunctionComponent } from 'react';
import moment from 'moment';
import PopoverStickOnHover from 'components/controls/Popover/PopoverStick';

const BookingPopup: FunctionComponent = (props: any) => {
  const { event } = props;
  const item: JobEvent = event;

  const day = () => {
    const d = moment(item.date);
    return d.format('DD');
  };

  if (item.jobs?.length === 0) {
    return <div className={`popover-height ${item.status}`}>{day()}</div>;
  }

  return (
    <PopoverStickOnHover item={item} delay={1000} status={item.status}>
      <div className={`popover-height ${item.status}`}>{day()}</div>
    </PopoverStickOnHover>
  );
};

export default BookingPopup;
