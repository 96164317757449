interface IProduction {
  title: string;
  id: string;
  selected: boolean;
}

const dummyProductions: IProduction[] = [
  {
    title: 'Production 1',
    id: '1',
    selected: false,
  },
  {
    title: 'Production 2',
    id: '2',
    selected: true,
  },
  {
    title: 'Production 3',
    id: '3',
    selected: false,
  },
  {
    title: 'Production 4',
    id: '4',
    selected: false,
  },
  {
    title: 'Production 5',
    id: '5',
    selected: false,
  },
  {
    title: 'Production 6',
    id: '6',
    selected: false,
  },
  {
    title: 'Production 7',
    id: '7',
    selected: false,
  },
  {
    title: 'Production 8',
    id: '8',
    selected: false,
  },
  {
    title: 'Production 9',
    id: '9',
    selected: false,
  },
];

// eslint-disable-next-line import/prefer-default-export
export { dummyProductions };
