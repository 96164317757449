import React, { useState } from 'react';
import styled from 'styled-components';
import StyledPendingReview from '../../../styles/show/components/PendingReview.styled';

interface Props {
  pendingPerformer: Function;
}

const PendingReview: React.FC<Props> = (props) => {
  const { pendingPerformer } = props;
  const [isEnabled, setEnabled] = useState(false);
  const pendingReview = () => {
    setEnabled(!isEnabled);
    pendingPerformer(!isEnabled);
  };
  return (
    <StyledPendingReview onClick={pendingReview}>
      <p>Pending Review</p>
      <Switch isOpen={isEnabled} />
    </StyledPendingReview>
  );
};

export default PendingReview;

const Switch: React.FC<{ isOpen?: boolean }> = ({ isOpen }) => (
  <SwitchContainer isOpen={isOpen}>
    <Control />
  </SwitchContainer>
);

const SwitchContainer = styled.div<{ isOpen?: boolean }>`
  display: flex;
  align-items: center;
  width: 27px;
  height: 18px;
  border-radius: 9px;
  background: ${({ theme, isOpen }) => (isOpen ? theme.colors.orange : '#bdbdbd')};
  transform: rotate(${({ isOpen }) => (isOpen ? '180' : '0')}deg);
`;

const Control = styled.div`
  display: flex;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  margin-left: 2.5px;
  background: ${({ theme }) => theme.colors.white};
`;
