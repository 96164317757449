import React, { useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import warn from '../../../../../../assets/svg/warnStatus.svg';
import active from '../../../../../../assets/svg/orangeStatus.svg';
import InfoPopup from './InfoPopup';

const StatusCellRenderer = (props: any) => {
  const { value, valueFormatted } = props;
  console.log('Value Status', value);
  const tippyRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const popUpContent = () => {
    switch (value) {
      case 'booked':
        return <InfoPopup open={visible} label="Info" width={220} height={82} message="Performer booked" />;
      case 'pending':
        return <InfoPopup open={visible} label="Info" width={220} height={82} message="Performer Not Available" />;
      default:
        return <InfoPopup open={visible} label="Info" width={220} height={82} message="Performer Not Available" />;
    }
  };

  const statusCellValue = valueFormatted || value;
  return (
    <Tippy
      ref={tippyRef}
      content={popUpContent}
      visible={visible}
      allowHTML
      arrow
      appendTo={document.body}
      interactive
      placement="left"
    >
      <Wrapper>
        <ImageContainer>
          {statusCellValue === 'booked' ? (
            <Image src={statusCellValue ? active : warn} alt="" onMouseEnter={show} onMouseLeave={hide} />
          ) : (
            <Image src={warn} alt="" onMouseEnter={show} onMouseLeave={hide} />
            // <InfoPopup label="Info" message="Performer Not Available" open={open} height={82} width={160} />
          )}
        </ImageContainer>
      </Wrapper>
    </Tippy>
  );
};

export default StatusCellRenderer;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
`;

const Image = styled.img`
  height: 14px;
  width: 14px;
  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  width: 20px;
  margin: auto;
  align-items: center;
`;
