import {
  IBGSkillDeleteParams,
  IBGSkillParams,
  IChangeImageOrder,
  IChangeRentalType,
  IIDParams,
  IPerformerDetail,
  IPerformerVoucherParam,
  IProfileFileParam,
  IRemoveRental,
  IRemoveResume,
  IUpdatePerformerVoucherParam,
  IUserVoucher,
} from 'modules/params/param.type';
import ApiResponse from 'modules/response/response.types';
import { PerformerImage, PerformerSkill, PerformerSkillList, User } from 'modules/user/types';
import { call, all, takeEvery, put } from 'redux-saga/effects';
import { authActions, generalActions, performerActions } from 'store/actions';
import { fetchPerformerWorkedDaysBegin } from 'store/actions/job.actions';
import { getVoucherInfoRequest, performerProfileBegin } from 'store/actions/performer.actions';
import ACTIONS, { TOAST_IDS, TOAST_TYPE } from 'store/castingPax.constants';
import performerApi from 'store/services/performer.services';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';

function* fetchPerformerProfile(state: { type: string; payload: IPerformerDetail }) {
  try {
    yield put(performerActions.getPerformerProfile.pending);
    const response: ApiResponse = yield call(performerApi.fetchPerformerProfile, state.payload);
    yield put(performerActions.getPerformerProfile.success(response.data));
    yield put(authActions.updatePerformerData(response.data));
    yield localStorage.setItem('user', JSON.stringify(response.data));
  } catch (error) {
    yield put(performerActions.getPerformerProfile.error(error.message));
  }
}

function* addSkills(state: { type: string; payload: IBGSkillParams }) {
  try {
    yield put(performerActions.savePerformerSkills.pending);
    const list: PerformerSkillList = yield call(performerApi.saveSkill, state.payload);
    if (list) {
      const user: User = yield getItemFromLocalStorage('user');
      if (user.bgPerformer && user.bgPerformer.skills) {
        user.bgPerformer.skills = [...list];
        yield localStorage.setItem('user', JSON.stringify(user));
        yield put(performerActions.savePerformerSkills.success(list));
      }
    }
  } catch (error) {
    yield put(performerActions.savePerformerSkills.error(error.message));
  }
}
function* addSkillsSignUp(state: { type: string; payload: IBGSkillParams }) {
  try {
    yield put(performerActions.savePerformerSkillsSignUp.pending);
    const list: PerformerSkillList = yield call(performerApi.saveSkill, state.payload);
    yield put(performerActions.savePerformerSkillsSignUp.success(list));
  } catch (error) {
    yield put(performerActions.savePerformerSkillsSignUp.error(error.message));
  }
}

function* createUserVoucher(state: { type: string; payload: IUserVoucher }) {
  try {
    yield put(performerActions.createUserVoucher.pending);
    const list: IUserVoucher = yield call(performerApi.craeteUserVoucher, state.payload);
    yield put(performerActions.createUserVoucher.success(list));
  } catch (error) {
    yield put(performerActions.createUserVoucher.error(error.message));
  }
}

function* addProfileImage(state: { type: string; payload: IProfileFileParam }) {
  try {
    const formData: any = state.payload.params;
    const fileId = formData.get('id');

    const imageId = formData.get('imageId');
    if (imageId) yield put(performerActions.savePerformerImage.pending(imageId));
    else yield put(performerActions.savePerformerImage.pending(''));
    const list: PerformerImage = yield call(performerApi.saveImage, state.payload);
    yield put(performerActions.savePerformerImage.success(list, fileId));
  } catch (error: any) {
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.UPLOAD_BG_ALBUM_IMAGE_ERROR,
        title: 'Failed to upload image',
        type: TOAST_TYPE.ERROR,
      }),
    );
    yield put(performerActions.savePerformerImage.error(error.message));
  }
}

function* changeImageSequence(state: { type: string; payload: IChangeImageOrder }) {
  try {
    yield put(performerActions.changeImageOrder.pending);
    yield call(performerApi.saveImageOrder, state.payload);
    yield put(performerActions.changeImageOrder.success());
  } catch (error) {
    yield put(performerActions.changeImageOrder.error(error.message));
  }
}

function* deleteImage(state: { type: string; payload: IIDParams }) {
  try {
    yield put(performerActions.deleteImageLocally(state.payload));
    yield put(performerActions.deleteImage.pending);
    yield call(performerApi.deleteImage, state.payload);
    yield put(performerActions.deleteImage.success(state.payload));
    yield put(performerActions.deleteImageAuth(state.payload));
  } catch (error) {
    yield put(performerActions.deleteImage.error(error.message));
  }
}

function* performerFilesUpload(state: { type: string; data: any }) {
  try {
    yield put(performerActions.uploadPerformerFiles.pending);
    const files: any = yield call(performerApi.uploadPerformerFiles, state.data);
    yield put(performerActions.uploadPerformerFiles.success(files));
  } catch (error) {
    yield put(performerActions.uploadPerformerFiles.error(error.message));
  }
}
function* deletePerformerDocs(state: { type: string; payload: any }) {
  try {
    yield put(performerActions.deleteResidencyDocs.pending);
    const files: any = yield call(performerApi.deleteResidency, state.payload);
    yield put(performerActions.deleteResidencyDocs.success(files));
  } catch (error) {
    yield put(performerActions.deleteResidencyDocs.error(error.message));
  }
}
function* renamePerformerDocs(state: { type: string; payload: any }) {
  try {
    yield put(performerActions.renamedResidencyDocs.pending);
    const files: any = yield call(performerApi.renameResidencyDocs, state.payload);
    yield put(performerActions.renamedResidencyDocs.success(files));
  } catch (error) {
    yield put(performerActions.renamedResidencyDocs.error(error.message));
  }
}
function* deleteResume(state: { type: string; payload: IRemoveResume }) {
  try {
    yield put(performerActions.deleteResume.pending);
    const response: any = yield call(performerApi.deleteResume, state.payload);
    if (response.data) {
      const user: User = yield getItemFromLocalStorage('user');
      if (state.payload.resumeUrl1) yield (user.resumeUrl1 = undefined);
      if (state.payload.resumeUrl2) yield (user.resumeUrl2 = undefined);
      yield localStorage.setItem('user', JSON.stringify(user));
    }
    yield put(performerActions.deleteResume.success(state.payload));
  } catch (error) {
    yield put(performerActions.deleteResume.error(error.message));
  }
}
function* deleteBGSkill(state: { type: string; payload: IBGSkillDeleteParams }) {
  try {
    yield put(performerActions.deletePerformerSkill.pending);
    const response: boolean = yield call(performerApi.deleteSkill, state.payload);
    if (response) {
      const user: User = yield getItemFromLocalStorage('user');
      if (user.bgPerformer && user.bgPerformer.skills) {
        user.bgPerformer.skills = user.bgPerformer.skills.filter((x: PerformerSkill) => x.id !== state.payload.id);
        yield localStorage.setItem('user', JSON.stringify(user));
        yield put(performerActions.deletePerformerSkill.success(user.bgPerformer.skills));
      }
    }
  } catch (err: any) {
    console.log('error occur');
    const user: User = yield getItemFromLocalStorage('user');
    yield put(performerProfileBegin({ userId: user.id }));
    yield put(performerActions.deletePerformerSkill.error(err.message));
  }
}

function* uploadRentalImage(state: { type: string; payload: IProfileFileParam }) {
  try {
    yield put(performerActions.uploadRentalImage.pending);
    const response: any = yield call(performerApi.uploadRentalImage, state.payload);
    if (response.data) {
      const user: User = yield getItemFromLocalStorage('user');
      yield (user.rentalImages = response.data);
      yield localStorage.setItem('user', JSON.stringify(user));
      yield put(authActions.uploadRentalImage(response.data));
      yield put(performerActions.uploadRentalImage.success(response.data));
      yield put(
        generalActions.showToast({
          id: TOAST_IDS.UPLOAD_RENTAL_IMAGE,
          title: 'Rental image uploaded successfully',
          type: TOAST_TYPE.SUCCESS,
        }),
      );
    }
  } catch (err: any) {
    yield put(performerActions.uploadRentalImage.error(err.message));
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.UPLOAD_RENTAL_IMAGE,
        title: 'Fail to upload rental image',
        type: TOAST_TYPE.ERROR,
      }),
    );
  }
}

function* removeRentalImage(state: { type: string; payload: IRemoveRental }) {
  try {
    yield put(performerActions.removeRentalImage.pending);
    console.log(state.payload.rentalId);
    const response: any = yield call(performerApi.removeRentalImage, state.payload);
    if (response.data) {
      const user: User = yield getItemFromLocalStorage('user');
      const { rentalImages } = user;
      if (rentalImages && rentalImages.length > 0) {
        const index = rentalImages.findIndex((r: any) => r.id === state.payload.rentalId);
        if (index !== -1 && user.rentalImages) {
          user.rentalImages.splice(index, 1);
        }
      }
      yield localStorage.setItem('user', JSON.stringify({ ...user }));
      yield put(authActions.removeRentalImage(state.payload.rentalId));
      yield put(performerActions.removeRentalImage.success(state.payload.rentalId));
      yield put(
        generalActions.showToast({
          id: TOAST_IDS.UPLOAD_RENTAL_IMAGE,
          title: 'Rental image remove successfully',
          type: TOAST_TYPE.SUCCESS,
        }),
      );
    }
  } catch (err: any) {
    console.log(err.message);
    yield put(performerActions.removeRentalImage.error(err.message));
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.REMOVE_RENTAL_IMAGE,
        title: 'Fail to remove rental image',
        type: TOAST_TYPE.ERROR,
      }),
    );
  }
}

function* updateRentalType(state: { type: string; payload: IChangeRentalType }) {
  try {
    yield put(performerActions.updateRentalType.pending);
    const response: any = yield call(performerApi.updateRentalType, state.payload);
    if (response.data) {
      const user: User = yield getItemFromLocalStorage('user');
      const { rentalImages } = user;
      if (rentalImages && rentalImages.length > 0) {
        const index = rentalImages.findIndex((r: any) => r.id === state.payload.id);
        if (index !== -1 && user.rentalImages) {
          user.rentalImages.splice(index, 1, response.data);
        }
      }
      yield localStorage.setItem('user', JSON.stringify({ ...user }));
      yield put(authActions.updateRentalType(response.data, state.payload.id));
      yield put(performerActions.updateRentalType.success(response.data, state.payload.id));
      yield put(
        generalActions.showToast({
          id: TOAST_IDS.CHANGE_RENTAL_TYPE,
          title: 'Rental image type changed successfully',
          type: TOAST_TYPE.SUCCESS,
        }),
      );
    }
  } catch (err: any) {
    console.log(err.message);
    yield put(performerActions.updateRentalType.error(err.message));
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.CHANGE_RENTAL_TYPE,
        title: 'Fail to change rental image type',
        type: TOAST_TYPE.ERROR,
      }),
    );
  }
}

function* savePerformerVoucher(state: { type: string; payload: IPerformerVoucherParam }) {
  try {
    yield put(performerActions.saveVoucherInfo.pending);
    const response: any = yield call(performerApi.savePerformerVoucher, state.payload);
    if (response) {
      const user: User = yield getItemFromLocalStorage('user');
      yield (user.voucher = response);
      yield localStorage.setItem('user', JSON.stringify(user));
      yield put(performerActions.saveVoucherInfo.success(response));
      yield put(
        generalActions.showToast({
          id: TOAST_IDS.CREATE_PERFORMER_VOUCHER_SUCCESS,
          title: 'Performer voucher info save successfully',
          type: TOAST_TYPE.SUCCESS,
        }),
      );
    }
  } catch (err: any) {
    yield put(performerActions.saveVoucherInfo.error(err.message));
    yield put(
      generalActions.showToast({
        id: TOAST_IDS.CREATE_PERFORMER_VOUCHER_ERROR,
        title: 'Fail to save voucher info',
        type: TOAST_TYPE.ERROR,
      }),
    );
  }
}

function* getPerformerVoucher(state: { type: string; payload: IPerformerVoucherParam }) {
  try {
    yield put(performerActions.getVoucherInfo.pending);
    const response: any = yield call(performerApi.getPerformerVoucher, state.payload);
    if (response) {
      yield put(performerActions.getVoucherInfo.success(response));
    }
  } catch (err: any) {
    yield put(performerActions.getVoucherInfo.error(err.message));
  }
}

function* getResidencyDocs() {
  try {
    yield put(performerActions.getResidencyDocs.pending);
    const response: any = yield call(performerApi.getResidencyDocs);
    if (response.data) {
      yield put(performerActions.getResidencyDocs.success(response.data));
    }
  } catch (err: any) {
    yield put(performerActions.getResidencyDocs.error(err.message));
  }
}

function* updatePerformerVoucherStatus(state: { type: string; payload: IUpdatePerformerVoucherParam }) {
  try {
    yield put(performerActions.saveVoucherInfo.pending);
    const response: any = yield call(performerApi.updatePerformerVoucherStatus, state.payload);
    if (response) {
      yield put(fetchPerformerWorkedDaysBegin({ page: 1 }));
      yield put(
        generalActions.showToast({
          id: TOAST_IDS.UPDATE_PERFORMER_VOUCHER__STATUS_SUCCESS,
          title: 'voucher save successfully',
          type: TOAST_TYPE.SUCCESS,
        }),
      );
      yield put(performerActions.saveVoucherInfo.success(response));
    }
  } catch (err: any) {
    yield put(performerActions.saveVoucherInfo.error(err));

    yield put(
      generalActions.showToast({
        id: TOAST_IDS.UPDATE_PERFORMER_VOUCHER__STATUS_SUCCESS,
        title: 'Fail to save request',
        type: TOAST_TYPE.ERROR,
      }),
    );
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.FETCH_PERFORMER_PROFILE_BEGIN, fetchPerformerProfile),
    takeEvery(ACTIONS.SAVE_PERFORMER_SKILL_BEING, addSkills),
    takeEvery(ACTIONS.SAVE_PERFORMER_SKILL_SIGN_UP_BEGIN, addSkillsSignUp),
    takeEvery(ACTIONS.SAVE_PERFORMER_IMAGE_BEGIN, addProfileImage),
    takeEvery(ACTIONS.CHANGE_IMAGE_ORDER_BEGIN, changeImageSequence),
    takeEvery(ACTIONS.DELETE_PERFORMER_IMAGE_BEGIN, deleteImage),
    takeEvery(ACTIONS.DELETE_RESUME_BEGIN, deleteResume),
    takeEvery(ACTIONS.DELETE_PERFORMER_SKILL_BEGIN, deleteBGSkill),
    takeEvery(ACTIONS.UPLOAD_RENTAL_IMAGE_BEGIN, uploadRentalImage),
    takeEvery(ACTIONS.REMOVE_RENTAL_IMAGE_BEGIN, removeRentalImage),
    takeEvery(ACTIONS.UPDATE_RENTAL_TYPE_BEGIN, updateRentalType),
    takeEvery(ACTIONS.SAVE_PERFORMER_VOUCHER_BEGIN, savePerformerVoucher),
    takeEvery(ACTIONS.GET_PERFORMER_VOUCHER_BEGIN, getPerformerVoucher),
    takeEvery(ACTIONS.GET_PROOF_OF_RESIDENCY_BEGIN, getResidencyDocs),
    takeEvery(ACTIONS.PERFORMER_FILES_UPLOAD_BEGIN, performerFilesUpload),
    takeEvery(ACTIONS.REMOVE_RESIDENCY_DOCS_BEGIN, deletePerformerDocs),
    takeEvery(ACTIONS.RENAME_RESIDENCY_DOCS_BEGIN, renamePerformerDocs),
    takeEvery(ACTIONS.CREATE_USER_VOUCHER_BEGIN, createUserVoucher),
    takeEvery(ACTIONS.UPDATE_PERFORMER_VOUCHER_STATUS_BEGIN, updatePerformerVoucherStatus),
  ]);
}
