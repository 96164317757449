import React, { FunctionComponent, useEffect, useState } from 'react';
import Style from 'components/TopNavBar/TopNavBar.module.css';
import { useDispatch, useSelector } from 'react-redux';
import Notification from 'components/TopNavBar/NotificationItem/index';
import { getNotificationsRequest, markReadNotification } from 'store/actions/general.actions';
import bellSvg from 'assets/svg/Notification.svg';
import { isNewNotificationIcon } from 'store/selector/general.selector';

const TopNotificationIcon: FunctionComponent = () => {
  const [notificationsToggle, setNotificationsToggle] = useState(false);

  const toggleNotification = () => setNotificationsToggle(true);
  const handleNotificationClick = () => setNotificationsToggle(false);

  const isNewNotification = useSelector(isNewNotificationIcon);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!notificationsToggle) {
      const list = localStorage.getItem('unreadNotificationList');
      if (list) {
        dispatch(markReadNotification({ ids: JSON.parse(list) }));
        localStorage.removeItem('unreadNotificationList');
      }
    }
  }, [toggleNotification]);

  useEffect(() => {
    dispatch(getNotificationsRequest());
  }, []);

  return (
    <div className={`position-relative ${Style.notificationWrapper}`}>
      <div
        className="d-flex justify-content-end align-items-center"
        onClick={toggleNotification}
        onKeyPress={toggleNotification}
        role="button"
        tabIndex={0}
      >
        <div className="position-relative">
          <img src={bellSvg} alt="" className={Style.bellIcon} />
          {isNewNotification && <span className={Style.notificationCounter} />}
        </div>
      </div>
      {notificationsToggle && (
        <div className={Style.bellNotificationsWrapper}>
          <div className={Style.notificationListing}>
            <div className={`customScrollBar ${Style.notificationBody}`}>
              <ul>
                <li>
                  <Notification closeNotificationView={setNotificationsToggle} />
                </li>
              </ul>
            </div>
          </div>
          <div
            className={Style.profileBgColor}
            onClick={handleNotificationClick}
            onKeyPress={handleNotificationClick}
            role="button"
            tabIndex={0}
          />
        </div>
      )}
    </div>
  );
};

export default TopNotificationIcon;
