/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useState } from 'react';
import Styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SendNoteIcon from 'assets/images/redSendNote.svg';
import GreenIndicatorComponent from '../Common/GreenIndicatorComponent';
import RedIndicatorComponent from '../Common/RedIndicatorComponent';
import ErrorNoteItem from './ErrorNoteItem';
import IndicatorComponent from '../Common/IndicatorComponent';

interface Props {
  errorNotes?: any[];
}
const ErrorNotesComponent: FunctionComponent<Props> = (props) => {
  const { errorNotes } = props;
  const [showSideNav, setShowSideNav] = useState(false);
  // const noteItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <ErrorNoteContainer>
      <HeaderContainer>
        <IndicatorComponent text="Errors" color="EB5757" />
        <IndicatorComponent text="Notes" color="F2994A" />
      </HeaderContainer>
      <NoteHeader>
        <SendLogo src={SendNoteIcon} />
        <NoteHeaderText>Errors / Notes</NoteHeaderText>
      </NoteHeader>
      <BottomSectionContainer>
        <NoteItemContainer>
          {errorNotes?.map((item) => (
            <ErrorNoteItem error={item} />
          ))}
        </NoteItemContainer>
      </BottomSectionContainer>
    </ErrorNoteContainer>
  );
};

const ErrorNoteContainer = Styled.div`
display: flex;
height: 100%;
width: 35.833%;
border-radius: 10px;
flex-direction: column;
margin-left: 25px;
border-radius: 12px;
@media only screen and (max-width: 991px) {
  width: 100%;
  margin-left: 0;
  margin-top: 15px;
}
`;

const HeaderContainer = Styled.div`
display: flex;
width: 100%;
align-items: center;
justify-content: space-evenly;
padding-left: 20px !important;
padding-right: 20px !important;
padding-bottom: 20px;
`;

const InBudgetContainer = Styled.div`
display: flex;
justify-content: flex-start;
width: 100%;
height: 100%;
margin-left: 0px;
font-size: 12px;
margin-bottom: 5px;
font-weight: 500;
font-family: Montserrat;
&:before {
    content: ' ';
    height: 10px;
    width: 10px;
    background: #49D360;
    margin-top:3px;
    margin-right:10px;
    border-radius: 100%;
}
`;

const PastBudgetContainer = Styled.div`
display: flex;
justify-content: flex-start;
width: 100%;
height: 100%;
margin-left: 0px;
font-size: 12px;
font-weight: 500;
font-family: Montserrat;
&:before {
    content: ' ';
    height: 10px;
    width: 10px;
    background: #EB5757;
    margin-top:3px;
    margin-right:10px;
    border-radius: 100%;
}
`;

const NoteContainer = Styled.div`
width: 100%;
border-radius: 12px;
`;

const NoteHeader = Styled.div`
width: 100%;
display: flex;
flex-direction: row;
background: #fff;
border-bottom: 3px solid #F2F2F2;
margin-top: auto;
padding: 15px 0;
padding-left: 30px;
margin-bottom: auto;
border-top-left-radius: 12px;
border-top-right-radius: 12px;
align-items: center;

@media only screen and (max-width: 1440px) {
  margin-top: 0;
  margin-bottom:0;
}

`;
const StyledCalender = Styled(FontAwesomeIcon)`
margin-top:6px;
color: #00a8ff;
`;

const NoteHeaderText = Styled.p`
font-family: Montserrat;
display: flex;
align-items: center;
margin-top: auto;
margin-bottom: auto;
font-size: 14px;
margin-left: 10px;
`;

const SendLogo = Styled.img`
`;

const BottomSectionContainer = Styled.div`
  background: #fff;
  padding: 20px;
  padding-top: 0;
  overflow-y: scroll;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  padding-bottom: 25px;
  max-height: 415px;
  @media only screen and (max-width: 1200px) {
    max-height: 415px;
  }
`;
const NoteItemContainer = Styled.div`
width:100%;
`;

export default ErrorNotesComponent;
