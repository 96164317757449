import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'store/selector/auth.selector';
import { setSelectedTab } from 'store/actions/auth.actions';
import { useHistory, useLocation } from 'react-router';
import { ROLE_PERFORMER } from 'store/castingPax.constants';

interface SideBarProps {
  showSidebar: boolean;
  setShowSidebar: Function;
}
const SideBar: FunctionComponent<SideBarProps> = (props) => {
  const { showSidebar, setShowSidebar } = props;
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const showTabData = (index: string) => {
    if (user.role === ROLE_PERFORMER && location.pathname.toLowerCase() !== '/performer/home') {
      history.push('/performer/home');
    } else if (user.role === 'DIRECTOR' && location.pathname.toLowerCase() !== '/casting/home') {
      history.push('/casting/home');
    }
    dispatch(setSelectedTab(index));
    setShowSidebar(!showSidebar);
  };
  return (
    <>
      <div>
        {user.role === 'DIRECTOR' && (
          <div className="mt-4 px-3">
            <div onClick={() => showTabData('0')} onKeyPress={() => showTabData('0')} role="button" tabIndex={0}>
              <h4 className="text-white">Matrix</h4>
            </div>
            <div onClick={() => showTabData('1')} onKeyPress={() => showTabData('1')} role="button" tabIndex={0}>
              <h4 className="text-white">Casting Request</h4>
            </div>
            <div onClick={() => showTabData('2')} onKeyPress={() => showTabData('2')} role="button" tabIndex={0}>
              <h4 className="text-white">Find Performer</h4>
            </div>
            <div onClick={() => showTabData('3')} onKeyPress={() => showTabData('3')} role="button" tabIndex={0}>
              <h4 className="text-white">Tools</h4>
            </div>
          </div>
        )}
      </div>
      <div>
        {user.role === ROLE_PERFORMER && (
          <div className="mt-4 px-3">
            <div onClick={() => showTabData('1')} onKeyPress={() => showTabData('1')} role="button" tabIndex={0}>
              <h4 className="text-white">Calendar</h4>
            </div>
            <div onClick={() => showTabData('2')} onKeyPress={() => showTabData('2')} role="button" tabIndex={0}>
              <h4 className="text-white">Submit</h4>
            </div>
            <div onClick={() => showTabData('3')} onKeyPress={() => showTabData('3')} role="button" tabIndex={0}>
              <h4 className="text-white">Profile</h4>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default SideBar;
