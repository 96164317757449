import React, { FunctionComponent } from 'react';
import Style from 'components/castingDirector/PopOvers/BgSizeCard/BgSizeCard.module.css';
import shareSvg from 'assets/svg/share-bg-white.svg';
import shareAllSvg from 'assets/svg/share-all.svg';
import downloadSvg from 'assets/svg/download.svg';
import { connect } from 'react-redux';
import { getShowUsers } from 'store/selector/show.selector';
import { Show } from 'modules/Show/show.types';
import Loader from 'components/controls/Loader/Loader';

interface BgSizeCardViewProps {
  handleClick: Function;
  bgSizeCardLoading: boolean;
  type: string;
}

const BgSizeCardView: FunctionComponent<BgSizeCardViewProps> = (props: BgSizeCardViewProps) => {
  const { handleClick, bgSizeCardLoading, type } = props;

  return (
    <div className={Style.mainWrapper}>
      <div
        className={Style.TextSection}
        role="button"
        tabIndex={0}
        onClick={() => handleClick('share')}
        onKeyPress={() => handleClick('share')}
      >
        {type === 'share' && bgSizeCardLoading && <Loader isSmallView />}
        <img src={shareSvg} alt="" className={Style.searchIcon} />
        <p className={Style.text}>Share Selected</p>
      </div>
      <div
        className={Style.TextSection}
        role="button"
        tabIndex={0}
        onClick={() => handleClick('shareAll')}
        onKeyPress={() => handleClick('shareAll')}
      >
        {type === 'shareAll' && bgSizeCardLoading && <Loader isSmallView />}
        <img src={shareAllSvg} alt="" className={Style.searchIcon} />
        <p className={Style.text}>Share All</p>
      </div>
      <div
        className={Style.TextSection}
        role="button"
        tabIndex={0}
        onClick={() => handleClick('download')}
        onKeyPress={() => handleClick('download')}
      >
        {type === 'download' && bgSizeCardLoading ? (
          <Loader isSmallView cssClass={Style.searchIcon} />
        ) : (
          <img src={downloadSvg} alt="" className={Style.searchIcon} />
        )}
        <p className={Style.text}>Download</p>
      </div>
    </div>
  );
};

const mapStatToProps = (state: Show) => {
  const users = getShowUsers(state);
  return {
    users,
  };
};

export default connect(mapStatToProps, null)(BgSizeCardView);
