import React from 'react';
import { faEdit, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

interface Props {
  item?: any;
  id?: string;
  custom: boolean;
  onChangeHandler?: any;
  handleReceiveId?: any;
  selected: boolean;
  userId: string;
  handleEdit: any;
}

const CustomRateItem: React.FC<Props> = (props) => {
  const { item, custom, onChangeHandler, id, handleReceiveId, selected, userId, handleEdit } = props;
  const handleClick = () => {
    handleReceiveId(item);
    handleEdit(item);
  };
  return (
    <StyledRateItem custom={custom} selected={selected} item={item} userId={userId} handleEdit={handleEdit}>
      <ItemContainer
        custom={custom}
        handleReceiveId={handleReceiveId}
        selected={selected}
        userId={userId}
        handleEdit={handleEdit}
      >
        <div role="button" tabIndex={0} onClick={handleClick} onKeyPress={handleClick}>
          <p>{item.title}</p>
        </div>
        <IconWrapper>
          <div
            role="button"
            tabIndex={0 as number}
            onClick={() => onChangeHandler(id, 'rateId')}
            onKeyPress={(event: any) => {
              if (event.key === 'Enter') {
                onChangeHandler(id, 'rateId');
              }
            }}
          >
            {userId === item.createdBy && <FontAwesomeIcon icon={faShareAlt} />}
          </div>
          {/* {userId === item.createdBy && ( */}
          {/*  <div role="button" tabIndex={0} onClick={handleClick} onKeyPress={handleClick}> */}
          {/*    <FontAwesomeIcon icon={faEdit} /> */}
          {/*  </div> */}
          {/* )} */}
        </IconWrapper>
      </ItemContainer>
    </StyledRateItem>
  );
};

export default CustomRateItem;

const ItemContainer = styled.div<Props>`
  border-bottom: 1px solid #f2f2f5;
  display: flex;
  cursor: pointer;
  height: 35px;
  justify-content: space-between;
  align-items: center;
  & div > p {
    margin: 0;
    font-size: 14px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-weight: ${({ selected }) => (selected ? 'bold' : '')};
  }
  // & > div {
  //   height: 35px;
  //   display: none;
  // }

  &:hover div {
    display: flex;
    align-items: center;
  }
`;

const StyledRateItem = styled.div<Props>`
  width: 275px;
  align-items: center;
  background: ${({ item, userId }) => (userId === item.createdBy ? 'rgba(73,211,96,0.1)' : 'rgba(45, 156, 219, 0.04)')};
  color: #828282;
  padding-left: 15px;
  margin-right: 15px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    margin-left: 16px;
  }
`;
