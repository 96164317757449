/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'styled-components';
import PayrollPerformerComponent from '../../components/Payroll/PayrollPerformer/PayrollPerformer';

export const PayrollPerformer = () => (
  <Wrapper>
    <PayrollPerformerComponent />
  </Wrapper>
);

const Wrapper = styled.div`
  overflow-y: scroll;
`;
