import React, { FunctionComponent } from 'react';
import Style from 'components/castingDirector/ContactSection/ContactItem.module.css';
import arrowDownSvg from 'assets/svg/arrowDown.svg';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import BreakdownPopover from '../PopOvers/Breakdown/BreakdownPopover';

interface BreakdownProps {
  cssClass?: string;
  icon: any;
  breakdownLoading: boolean;
  type: string;
  handleClick: Function;
}
const Breakdown: FunctionComponent<BreakdownProps> = (props: BreakdownProps) => {
  const { cssClass, icon, breakdownLoading, type, handleClick } = props;

  const getComponent = () => (
    <BreakdownPopover type={type} breakdownLoading={breakdownLoading} handleClick={handleClick} />
  );
  return (
    <>
      <PopoverContainer itemComponent={() => getComponent()}>
        <div className={`d-flex align-items-center ${Style.contactSection} ${cssClass}`}>
          <img src={icon} alt="" className={`${'mr-3'}`} />
          <p className={`mb-0 w-100 ${Style.contactTitle}`}>Breakdown</p>
          <img src={arrowDownSvg} alt="" className="ml-3" />
        </div>
      </PopoverContainer>
    </>
  );
};

export default Breakdown;
