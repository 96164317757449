import { SkillCategory, Notification, OptionList, RateOptions, RentalOption } from 'modules/general/general.type';
import { SelectOptionTypeList } from 'modules/PerformerList/list.types';
import createSelector from 'utils/reselect';
import {
  SCREEN_SIZE_DESKTOP,
  SCREEN_SIZE_LAPTOP,
  SCREEN_SIZE_MOBILE,
  SCREEN_SIZE_TABLET,
  SCREEN_SIZE_MEDIUM_TABLET,
  SCREEN_WIDTH,
} from '../castingPax.constants';

const generalStateData = (state: any) => {
  const { general } = state;
  return general || {};
};

export const getOptions = createSelector(generalStateData, (general) => general.get('dropdownOptions'));

export const getLocations = createSelector(generalStateData, (general) => general.get('locations'));

export const getRoles = createSelector(generalStateData, (general) => general.get('roles'));

export const getUnions = createSelector(generalStateData, (general) => general.get('unionStatus'));

export const getToastList = createSelector(generalStateData, (general) => general.get('toastList'));

export const getPaths = createSelector(generalStateData, (general) => general.get('paths'));

export const getDefaultSkills = createSelector(generalStateData, (general) => general.get('skills') || []);

export const getSkills = createSelector(generalStateData, (general) => {
  const skills = general.get('skills') || [];

  return skills.map((x: SkillCategory) => {
    const obj = { id: x.id, value: x.id, label: x.title, type: 'skills' };
    return obj;
  });
});

export const getSkillsData = createSelector(generalStateData, (general) => {
  const skills = general.get('skills') || [];
  const res: OptionList = [];
  skills.forEach((x: SkillCategory) => {
    if (x.subCategories) {
      x.subCategories.forEach((y: SkillCategory) => {
        res.push({ id: y.id, value: y.id, label: y.title, type: 'skills' });
      });
    } else {
      res.push({ id: x.id, value: x.id, label: x.title, type: 'skills' });
    }
  });
  return res;
});

export const getNotificationList = createSelector(generalStateData, (general) => general.get('notifications') || []);

export const isNewNotificationIcon = createSelector(generalStateData, (general) => {
  const notis = general.get('notifications') || [];
  if (notis.length !== 0) {
    const unRead = general.get('notifications').filter((x: Notification) => x.isSeen === false);
    if (unRead.length > 0) return true;
  }
  return general.get('isNewNotification') || false;
});

export const getMessageBoxObj = createSelector(generalStateData, (general) => general.get('showMessageBox') || {});

export const getCurrentWidth = createSelector(generalStateData, (general) => general.get(SCREEN_WIDTH));

export const getScreenWidth = createSelector(generalStateData, (general) => {
  const width = general.get(SCREEN_WIDTH) || 1366;
  if (width <= 576) return SCREEN_SIZE_MOBILE;
  if (width <= 767) return SCREEN_SIZE_TABLET;
  if (width <= 991) return SCREEN_SIZE_MEDIUM_TABLET;
  if (width <= 1199) return SCREEN_SIZE_LAPTOP;
  return SCREEN_SIZE_DESKTOP;
});

export const getScreenWidthValue = createSelector(generalStateData, (general) => general.get(SCREEN_WIDTH));

export const getRates = createSelector(generalStateData, (general) => general.get('rates'));

export const getRatesOpt = createSelector(generalStateData, (general) => {
  const rates = general.get('rates') || [];
  const arr: SelectOptionTypeList = [];
  rates.map((t: RateOptions) => arr.push({ value: t.id, label: t.title, rate: t.rate }));
  return arr;
});

export const getRentalOpt = createSelector(generalStateData, (general) => {
  const rates = general.get('rental') || [];
  const arr: any = [];
  rates.map((t: RentalOption) => arr.push({ value: t.id, label: t.value }));
  return arr;
});
