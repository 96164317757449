export const performerSlider = [
  {
    breakpoint: 1366,
    settings: {
      slidesToShow: 14,
      slidesToScroll: 14,
    },
  },
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 12,
      slidesToScroll: 12,
    },
  },
  {
    breakpoint: 1124,
    settings: {
      slidesToShow: 10,
      slidesToScroll: 10,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 9,
      slidesToScroll: 9,
    },
  },
  {
    breakpoint: 992,
    settings: {
      slidesToShow: 8,
      slidesToScroll: 8,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 6,
      slidesToScroll: 6,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      vertical: true,
      arrows: true,
    },
  },
];

export const directorTopNavSlider = [
  {
    breakpoint: 1366,
    settings: {
      slidesToShow: 7,
      slidesToScroll: 7,
    },
  },
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 6,
      slidesToScroll: 6,
    },
  },
  {
    breakpoint: 1124,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 5,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 5,
    },
  },
  {
    breakpoint: 992,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
    },
  },
  {
    breakpoint: 680,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 576,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      vertical: true,
      arrows: true,
    },
  },
];

export const directorDays = [
  {
    breakpoint: 1440,
    settings: {
      slidesToShow: 9,
      slidesToScroll: 9,
    },
  },
  {
    breakpoint: 1399,
    settings: {
      slidesToShow: 8,
      slidesToScroll: 8,
    },
  },
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 6,
      slidesToScroll: 6,
    },
  },
  {
    breakpoint: 1124,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 5,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 6,
      slidesToScroll: 6,
    },
  },
  {
    breakpoint: 992,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 5,
    },
  },
  {
    breakpoint: 890,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
      variableWidth: true,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      vertical: false,
      arrows: true,
      variableWidth: true,
    },
  },
  {
    breakpoint: 400,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      vertical: false,
      arrows: true,
      variableWidth: true,
    },
  },
];

export const findPerformerCalendar = [
  {
    breakpoint: 1366,
    settings: {
      slidesToShow: 10,
      slidesToScroll: 10,
      variableWidth: true,
    },
  },
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 10,
      slidesToScroll: 10,
      variableWidth: true,
    },
  },
  {
    breakpoint: 1124,
    settings: {
      slidesToShow: 9,
      slidesToScroll: 9,
      variableWidth: true,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 6,
      slidesToScroll: 6,
      variableWidth: true,
    },
  },
  {
    breakpoint: 992,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 5,
      vertical: false,
      arrows: true,
      variableWidth: true,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 6,
      slidesToScroll: 6,
      vertical: false,
      centerMode: false,
      arrows: true,
      variableWidth: true,
    },
  },
  {
    breakpoint: 650,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
      vertical: false,
      centerMode: false,
      arrows: true,
      variableWidth: true,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
      vertical: false,
      arrows: true,
      variableWidth: true,
    },
  },
  {
    breakpoint: 400,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      vertical: false,
      arrows: true,
      variableWidth: true,
    },
  },
];
