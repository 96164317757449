import React, { FunctionComponent } from 'react';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import { MatrixType } from 'modules/Show/show.types';
import Style from 'components/castingDirector/ContactSection/ContactItem.module.css';
import arrowDownSvg from 'assets/svg/arrowDown.svg';
import CrewCallDetail from '../PopOvers/CrewCallDetail/CrewCallDetail';

interface MatrixCrewCallItemProps {
  title: string;
  icon: string;
  type: string;
  workingDay: MatrixType;
  handleCrewCall: Function;
  cssClass?: string;
}
const crewCall: FunctionComponent<MatrixCrewCallItemProps> = (props) => {
  const { title, icon, type, workingDay, handleCrewCall, cssClass } = props;
  const getComponent = () => (
    <CrewCallDetail cssClass={cssClass} workingDay={workingDay} handleCrewCall={handleCrewCall} />
  );
  return (
    <>
      <PopoverContainer itemComponent={() => getComponent()}>
        <div className={`d-flex align-items-center ${Style.contactSection}`}>
          <img src={icon} alt="" className="mr-3" />
          <p className={`mb-0 ${Style.contactTitle}`}>{title}</p>
          {type !== 'share' && <img src={arrowDownSvg} alt="" className="ml-3" />}
        </div>
      </PopoverContainer>
    </>
  );
};

export default crewCall;
