import React, { FunctionComponent, useEffect, useState, Suspense } from 'react';
import Style from 'components/ProfileMenu/Settings/Setting.module.css';
import Input from 'components/controls/input/dashboardInput/dashboardInput';
import Button from 'components/controls/button/button';
import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';
import api from 'store/services/auth.services';
import Modal from 'components/controls/Modal/Modal';
import ConfirmationModalBody from 'components/Coordinator/Matrix/Details/ConfirmationModalBody';
import roleSvg from 'assets/svg/role.svg';
import Subscription from 'components/ProfileMenu/subscription/Subscription';
import CardDetails from 'components/ProfileMenu/Settings/CardDetails';
import { connect, useSelector } from 'react-redux';
import { cardLoading, closeAccountLoading, getUser } from 'store/selector/auth.selector';
import { ROLE_PERFORMER, ROLE_DIRECTOR, LOADER_STATUS, ROLE_PRODUCTION } from 'store/castingPax.constants';
import { User } from 'modules/user/types';
import { deleteAccountBegin } from 'store/actions/auth.actions';
import { toast } from 'react-toastify';
import BillingInfo from 'components/ProfileMenu/BillingInfo/BillingInfo';
import UserInvite from './UserInvite/inviteUser';

const StripePayment = React.lazy(() => import('components/ProfileMenu/Settings/Payment/StripePayment'));

interface settingProps {
  deleteAccount: Function;
  closeLoading: boolean;
  cardLoader: boolean;
}

const Setting: FunctionComponent<settingProps> = (props: settingProps) => {
  const { deleteAccount, closeLoading, cardLoader } = props;

  const user: User = useSelector(getUser);
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [reNewPassword, setReNewPassword] = useState<string>('');
  const [disable, setDisable] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordLoading, setPasswordLoading] = useState<boolean>(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [accountDeleteModal, setAccountDeleteModal] = useState<boolean>(false);
  const [accountToken, setAccountToken] = useState<any>();
  const [stripeLoader, setStripeLoader] = useState<number>(LOADER_STATUS.NA);

  const resetPasswordField = () => {
    setOldPassword('');
    setNewPassword('');
    setReNewPassword('');
  };

  const handlePasswordChange = () => {
    if (newPassword !== reNewPassword) {
      setPasswordError(newPassword !== reNewPassword);
      return;
    }
    setPasswordLoading(true);
    try {
      api
        .updatePassword({ newPassword, oldPassword })
        .then(() => {
          resetPasswordField();
          setPasswordLoading(false);
          toast('Password Changed');
        })
        .catch((error) => {
          resetPasswordField();
          setPasswordLoading(false);
          toast.error(error.message);
        });
    } catch (error: any) {
      setPasswordError(error.message);
      setPasswordLoading(false);
    }
  };

  useEffect(() => {
    setDisable(oldPassword.length === 0 || newPassword.length === 0 || reNewPassword.length === 0);
    if (setPasswordError) {
      setPasswordError(newPassword !== reNewPassword);
    }
  });

  const deleteHandle = () => {
    api
      .fetchAccountToken()
      .then((res) => {
        setAccountToken(res);
        setAccountDeleteModal(true);
      })
      .catch((err) => console.log('err', err.message));
  };

  const handleClose = () => {
    setOpenPaymentModal(false);
  };
  const clickPaymentModal = () => {
    setOpenPaymentModal(true);
  };

  const paymentMethodBody = () => (
    <Suspense fallback={<div>Loading...</div>}>
      <StripePayment handleClose={handleClose} />
    </Suspense>
  );

  const handleRedirectStripe = () => {
    try {
      setStripeLoader(LOADER_STATUS.PENDING);
      api.updateStripeAccount({ id: user.id }).then((res) => {
        if (res) window.open(res.url);
        setStripeLoader(LOADER_STATUS.COMPLETED);
      });
    } catch (err: any) {
      setStripeLoader(LOADER_STATUS.COMPLETED);
      console.log(err.message);
    }
  };
  const accountDeleteBody = () => (
    <ConfirmationModalBody
      isShow={setAccountDeleteModal}
      handleClick={handleAccountDelete}
      description="If You Delete Your Account Never Restore Your Data Again!"
    />
  );
  const handleAccountDelete = (res: boolean) => {
    if (res && accountToken) deleteAccount(accountToken);
  };

  return (
    <div className={Style.settingWrapper}>
      <div className={Style.passwordWrapper}>
        <p className={`mb-0 ${Style.settingHeading}`}>Change Password</p>
        <div className="row">
          <div className="col-lg-6 col-xl-4">
            <div className={Style.password}>
              <Input
                value={oldPassword}
                placeholder="Private"
                type="password"
                cssClass={Style.input}
                handleChange={setOldPassword}
                inputLabel={Style.settingInputLabel}
                label="Old password"
              />
            </div>
          </div>
          <div className="col-lg-6 col-xl-8" />
        </div>
        <div className={`row ${Style.password}`}>
          <div className="col-lg-6 col-xl-4">
            <Input
              value={newPassword}
              placeholder="Private"
              type="password"
              cssClass={Style.input}
              inputLabel={Style.settingInputLabel}
              handleChange={setNewPassword}
              label="New password"
            />
          </div>
          <div className="col-lg-6 col-xl-4">
            <Input
              value={reNewPassword}
              placeholder="Private"
              type="password"
              cssClass={Style.input}
              handleChange={setReNewPassword}
              inputLabel={Style.settingInputLabel}
              label="Confirm new password"
            />
            {passwordError && <TextErrorMessage message="New password did not match" />}
          </div>
          <div className="col-lg-6 col-xl-4">
            <Button
              clickHandler={handlePasswordChange}
              label="Change password"
              isDisabled={disable}
              showLoading={passwordLoading}
              cssClass={`mt-30 ${Style.passwordButton}`}
            />
          </div>
        </div>
      </div>
      {user.role === ROLE_PRODUCTION && <BillingInfo user={user} />}
      <UserInvite />
      <div className={`pt-40 ${Style.profileWrapper}`}>
        <div className={`${Style.addCardWrapper}`}>
          <p className={`mb-0 ${Style.settingHeading}`}>Billing Method</p>
          <Button
            showLoading={cardLoader}
            clickHandler={clickPaymentModal}
            label="Add Card"
            cssClass={Style.addMethod}
          />
        </div>
        <p className={`ml-0 ${Style.profileDetailDesc}`}>Please add card</p>
        <CardDetails user={user} />
        {user.role === ROLE_DIRECTOR && !user.stripeLinked && (
          <div className={`${Style.configureAccount}`}>
            <p className={`mb-0 ${Style.settingHeading}`}>Configure your Stripe account to collect incentives</p>
            <Button
              showLoading={stripeLoader === LOADER_STATUS.PENDING}
              clickHandler={handleRedirectStripe}
              label="Update Stripe"
              cssClass={Style.addMethod}
            />
          </div>
        )}
        {user.role === ROLE_DIRECTOR && user.stripeLinked && (
          <div className={`${Style.configureAccount}`}>
            <p className={`mb-0 ${Style.settingHeading}`}>Stripe Account Linked</p>
          </div>
        )}
      </div>
      {user.role === ROLE_PERFORMER && <Subscription fetchStatus="NA" user={user} />}
      <div className={Style.accountWrapper}>
        <p className={`mb-1 ${Style.accountHeading}`}>Closing your account</p>
        <div className={`${Style.configureAccount}`}>
          <p className={`mb-3 mb-md-0 mb-sm-3 mb-xl-0 mb-lg-0 mb-xxl-0 ${Style.accountDetail}`}>
            Once you delete your account, there is no going back. Please be certain.
          </p>
          <Button
            showLoading={closeLoading}
            clickHandler={deleteHandle}
            label="Delete"
            cssClass={`${Style.deleteButton}`}
          />
        </div>
      </div>
      <div className={Style.roleDetailWrapper}>
        {openPaymentModal && (
          <Modal
            title="Add a billing method"
            body={paymentMethodBody}
            closeModal={() => handleClose()}
            icon={roleSvg}
            cssClass=""
          />
        )}
        {accountDeleteModal && (
          <Modal title="Delete Account" body={accountDeleteBody} closeModal={() => setAccountDeleteModal(false)} />
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  deleteAccount: deleteAccountBegin,
};
const mapStateToProps = (state: any) => {
  const closeLoading = closeAccountLoading(state);
  const cardLoader = cardLoading(state);
  return {
    closeLoading,
    cardLoader,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Setting);
