import React, { FunctionComponent, useEffect } from 'react';
import Style from 'views/bgperformer/bgPerformer.module.css';
import TopNavBar from 'components/TopNavBar/TopNavBar';
import Profile from 'views/bgperformer/profile/index';
import { IPerformerId } from 'modules/params/param.type';
import Booking from 'containers/bgPerformer/Booking';
import PerformerSubmission from 'containers/bgPerformer/Submission/Submission';
import JobView from 'components/JobView';
import { redirectPath, saveBookingData, saveJobKeyInLS } from 'helpers/utils';
import jobApi from 'store/services/job.services';
import ApiResponse from 'modules/response/response.types';
import { BookingRequest } from 'modules/jobs/types';
import { useLocation } from 'react-router';

interface LandingProps {
  selcetedHomeTab: string;
  bookingRequestBegin: Function;
  calendarDatesBegin: Function;
  match: any;
  openJobDetails: Function;
}

const Landing: FunctionComponent<LandingProps> = (props) => {
  const { selcetedHomeTab, bookingRequestBegin, calendarDatesBegin, openJobDetails, match } = props;
  const location = useLocation();

  if (location.state) {
    window.location.reload();
  }

  const showJobDetail = (token: string) => {
    if (token) {
      jobApi
        .verifyToken({ token })
        .then((res: ApiResponse) => {
          openJobDetails(res.data.jobId, res.data);
        })
        .catch((err) => err);
    }
  };

  if (match && match.params && match.params.token) {
    const { token } = match.params;
    showJobDetail(token);
  }

  useEffect(() => {
    const params: IPerformerId = {
      status: ['pending', 'booked', 'requested'],
    };
    bookingRequestBegin(params);
    calendarDatesBegin();
    const pathToRedirect = redirectPath.get();
    if (pathToRedirect === '/job/view/:token') {
      const tok = pathToRedirect.split('/').pop();
      showJobDetail(tok || '');
      redirectPath.clear();
    }
    const getJobData = saveJobKeyInLS.get();
    if (getJobData) {
      openJobDetails(getJobData, undefined);
      saveJobKeyInLS.clear();
    }
    const getBookingData: BookingRequest = saveBookingData.get();
    if (getBookingData) {
      openJobDetails(getBookingData.jobId, getBookingData);
      saveBookingData.clear();
    }
  }, []);

  const loadComponent = () => {
    switch (selcetedHomeTab) {
      case '2':
        return (
          <>
            <PerformerSubmission />
            <JobView />
          </>
        );
      case '3':
        return <Profile />;
      default:
        return <Booking />;
    }
  };

  return (
    <>
      <div className={Style.bgPerformerWrapper}>
        <div className={Style.TopNavWrapper}>
          <TopNavBar />
        </div>
        {loadComponent()}
      </div>
    </>
  );
};
export default Landing;
