import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

interface Props {
  showLabel?: boolean;
}

const PrintControl: React.FC<Props> = (props) => {
  const { showLabel } = props;
  const handlePrint = () => window.print();
  console.log(showLabel);
  return (
    <Container>
      <FontAwesomeIcon icon={faPrint} color="#50B5FF" onClick={handlePrint} />
      {/* {showLabel && (
        <TextBox>
          <Text>Print</Text>
        </TextBox>
      )} */}
    </Container>
  );
};

export default PrintControl;

const Container = styled.div`
  width: fit-content;
  height: 38.39px;
  border-radius: 12px;
  padding: 11px;
  background: rgba(80, 181, 255, 0.1);
  mix-blend-mode: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

// const TextBox = styled.div`
//   width: 40px;
//   height: 26.27px;
//   margin-left: 8px;
// `;

// const Text = styled.p`
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   color: #44444f;
// `;
