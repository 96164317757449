import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DownArrow from 'components/Payroll/common/DownArrow';
import { Spinner } from 'react-bootstrap';
import RateItem from './RateItem';

interface Props {
  readonly: boolean;
  rateId: string;
  setRates: Function;
  rates: any;
  loading: boolean;
}
const RateCardItems: React.FC<Props> = (props) => {
  const { readonly, setRates, rates, rateId, loading } = props;
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => {
    setOpen((currentVal) => !currentVal);
  };
  const handleChange = (e: any) => {
    if (typeof e === 'string') {
      setRates({ ...rates, template: e });
    } else {
      setRates({ ...rates, [e.target.name]: e.target.value });
    }
  };
  const handleChangeStatus = (e: any) => {
    if (e.value !== undefined) {
      setRates({ ...rates, [e.name]: e.value });
    }
  };
  const getRate = (rateType: string) => {
    if (rates.halfXRate || rates.twoXRate) {
      return rateType === 'halfXRate' ? rates.halfXRate : rates.twoXRate;
    }
    if (rates.hourly) {
      return rates.hourly * (rateType === 'halfXRate' ? 1.5 : 2);
    }
    return 0;
  };
  useEffect(() => {}, [rates]);
  return (
    <StyledRateCardItems>
      <FieldGroup>
        <RateItem
          status
          rateName="Template"
          value={`${rates.template || ''}`}
          readonly={readonly}
          name="template"
          handleChange={handleChange}
          customClass={false}
        />
        <RateItem
          status={rates.isCostume}
          rateName="Costume"
          value={`${rates.costume || ''}`}
          readonly={readonly}
          name="costume"
          statusName="isCostume"
          handleChange={handleChange}
          handleChangeStatus={handleChangeStatus}
          customClass={false}
        />
        <RateItem
          status={rates.isMp}
          rateName="MP"
          value={`${rates.mp || ''}`}
          readonly={readonly}
          name="mp"
          statusName="isMp"
          handleChange={handleChange}
          handleChangeStatus={handleChangeStatus}
          customClass={false}
        />
        <RateItem
          status={rates.isHourly}
          rateName="Hourly"
          value={`${rates.hourly || ''}`}
          type="number"
          readonly={readonly}
          name="hourly"
          statusName="isHourly"
          handleChange={handleChange}
          handleChangeStatus={handleChangeStatus}
          customClass={false}
        />
        <RateItem
          status={rates.isHalfXRate}
          rateName="1.5"
          value={getRate('halfXRate')}
          readonly
          name="halfXRate"
          statusName="isHalfXRate"
          handleChange={handleChange}
          handleChangeStatus={handleChangeStatus}
          customClass={false}
        />
        <RateItem
          status={rates.isTwoXRate}
          value={`${getRate('twoXRate')}`}
          rateName="2.x"
          readonly
          name="twoXRate"
          statusName="isTwoXRate"
          handleChange={handleChange}
          handleChangeStatus={handleChangeStatus}
          customClass={false}
        />
        {/* {open && ( */}
        <>
          <RateItem
            status={rates.isTravel}
            rateName="Travel"
            value={`${rates.travel || ''}`}
            readonly={readonly}
            name="travel"
            statusName="isTravel"
            handleChange={handleChange}
            handleChangeStatus={handleChangeStatus}
            customClass={false}
          />
          <RateItem
            status={rates.isAddOns}
            rateName="Add Ons"
            value={`${rates.addOns || ''}`}
            readonly={readonly}
            name="addOns"
            statusName="isAddOns"
            handleChange={handleChange}
            handleChangeStatus={handleChangeStatus}
            customClass={false}
          />
          <RateItem
            status={rates.isClampedRates}
            rateName="Clamped Rates"
            value={`${rates.clampedRates || ''}`}
            readonly={readonly}
            name="clampedRates"
            statusName="isClampedRates"
            handleChange={handleChange}
            handleChangeStatus={handleChangeStatus}
            customClass={false}
          />
          <RateItem
            status={rates.isGst}
            rateName="GST"
            value={`${rates.gst || ''}`}
            readonly={readonly}
            name="gst"
            statusName="isGst"
            handleChange={handleChange}
            handleChangeStatus={handleChangeStatus}
            customClass={false}
          />
          <RateItem
            status={rates.isTax}
            rateName="TAX"
            value={`${rates.tax || ''}`}
            readonly={readonly}
            name="tax"
            statusName="isTax"
            handleChange={handleChange}
            handleChangeStatus={handleChangeStatus}
            customClass={false}
          />
          <RateItem
            status={rates.isHourlyOvertime}
            rateName="Hourly Overtime"
            value={`${rates.hourlyOvertime || ''}`}
            readonly={readonly}
            name="hourlyOvertime"
            statusName="isHourlyOvertime"
            handleChange={handleChange}
            handleChangeStatus={handleChangeStatus}
            customClass={false}
          />
        </>
        {/* )} */}
      </FieldGroup>
      {/* <StyledAdvancedMenu> */}
      {/*  <AdvancedMenuHeading onClick={toggleOpen}> */}
      {/*    <p>Advanced Rules</p> */}
      {/*    <DownArrow toggleIcon={open} /> */}
      {/*    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
      {/*    {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />} */}
      {/*  </AdvancedMenuHeading> */}
      {/* </StyledAdvancedMenu> */}
    </StyledRateCardItems>
  );
};

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAdvancedMenu = styled.div`
  position: relative;
`;
const AdvancedMenuHeading = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  flex: 1;
  cursor: pointer;
  & > p {
    width: 147px;
    box-sizing: border-box;
    margin-bottom: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    margin-right: 12.1px;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    display: flex;
    align-items: center;
    /* Secondary color */
    color: #4b4d67;
  }
`;

const StyledRateCardItems = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  height: fit-content;
`;

export default RateCardItems;
