const voucherTheme = {
  colors: {
    primary: '#49d360',
    gray3: '#828282',
    gray4: '#bdbdbd',
    gray2: '#4F4F4F',
    gray6: '#f2f2f2',
    black: '#000000',
    secondaryColor: '#4b4d67',
    white: '#ffffff',
    blue2: '#2D9CDB',
    yellow: '#F2C94C',
    orange: '#F2994A',
    purple2: '#BB6BD9',
    lightBlue: '#E4F0FC',
  },
};

export default voucherTheme;
