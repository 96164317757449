/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import Styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { ChartGroup } from 'components/Payroll/common/chartGroup/components';
import { Show } from 'modules/Show/show.types';
import productionData from 'components/Payroll/common/chartGroup/data/productionData.data';
import Style from 'components/Producer/Matrix/Budget/CreateBudget.module.css';
// import BudgetChartComponent from './BudgetChartComponent';
import GreenIndicator from '../Common/GreenIndicatorComponent';
import Redindicator from '../Common/RedIndicatorComponent';
import ExportControl from '../Common/controls/export/Export.control';
import PrintControl from '../Common/controls/print/Print.control';
import EditControl from '../Common/controls/edit/Edit.control';
import AddControl from '../Common/controls/add/Add.control';
// import ControlsWrapper from '../Common/controls/ControlButtonsWrapper';

interface Props {
  showHistory?: any;
}
const BudgetHistoryComponent: React.FC<Props> = (props) => {
  const { showHistory } = props;
  useEffect(() => {
    console.log('showHistory', showHistory);
  }, [showHistory]);
  const printRef = useRef();
  return (
    <BudgetHistoryContainer>
      <HeaderContainer>
        <GreenIndicator text="In Budget" />
        <Redindicator text="Past Budget" marginLeft="15px" />
      </HeaderContainer>
      <ChartContainer>
        <ChartHeader>
          <StyledCalender className="" icon={faCalendarAlt} />
          <ChartHeaderText>History</ChartHeaderText>
        </ChartHeader>
        {/* <BudgetChartComponent /> */}
        {/* <ControlsWrapper> */}
        <div className={`p-2 ${Style.budgetWrapper}`}>
          <ExportControl printRef={printRef} />
          <PrintControl showLabel />
          <AddControl />
          <EditControl />
        </div>
        {/* </ControlsWrapper> */}
        <ChartGroup enableTextItem chartData={showHistory} printRef={printRef} />
      </ChartContainer>
    </BudgetHistoryContainer>
  );
};

const BudgetHistoryContainer = Styled.div`
display: flex;
flex-direction: column;
width: 34.25%;
border-radius: 5px;
margin-left: 10px;
@media only screen and (max-width: 1200px) {
  flex-direction: column;
}
@media only screen and (max-width: 991px) {
  width: 100%;
  margin-left: 0;
  margin-top: 15px;
}
`;

const HeaderContainer = Styled.div`
display: flex;
width: 100%;
align-items: center;
justify-content: space-around;
padding-left: 20px !important;
padding-right: 20px !important;
padding-bottom: 20px;
`;

const InBudgetContainer = Styled.div`
display: flex;
justify-content: flex-start;
width: 100%;
height: 100%;
margin-left: 0px;
font-size: 12px;
font-weight: 500;
font-family: 'Montserrat';
&:before {
    content: ' ';
    height: 10px;
    width: 10px;
    background: #49D360;
    margin-top:3px;
    margin-right:10px;
    border-radius: 100%;
}
`;

const PastBudgetContainer = Styled.div`
display: flex;
justify-content: flex-start;
width: 100%;
height: 100%;
margin-left: 0px;
font-size: 12px;
font-weight: 500;
font-family: 'Montserrat';
&:before {
    content: ' ';
    height: 10px;
    width: 10px;
    background: #EB5757;
    margin-top:3px;
    margin-right:10px;
    border-radius: 100%;
}
`;

const ChartContainer = Styled.div`
  width: 100%;
  background: #fff;
  border-radius: 10px;
  flex: 1;
`;

const ChartHeader = Styled.div`
width: 100%;
display: flex;
flex-direction: row;
border-bottom: 3px solid #F2F2F2;
padding: 15px 0;
padding-left: 30px;
align-items: center;
`;

const StyledCalender = Styled(FontAwesomeIcon)`
  color: #00a8ff;
`;

const ChartHeaderText = Styled.p`
  font-size: 14px;
  font-family: 'Montserrat';
  margin: 0;
  padding-left: 10px;
`;

export default BudgetHistoryComponent;
