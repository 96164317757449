import React, { FunctionComponent } from 'react';
import { ImageFolderItem, PerformerImage } from 'modules/user/types';
import Style from 'components/castingDirector/Images/Image.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import DirectorPhotoView from 'components/castingDirector/Images/PhotoView';

interface BasicInfoProps {
  item: ImageFolderItem;
}

const Images: FunctionComponent<BasicInfoProps> = (props: BasicInfoProps) => {
  const { item } = props;
  return (
    <>
      <div className={`d-flex align-items-center justify-content-between ${Style.ImageTitle}`}>
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faSort} />
          <p className="mb-0 pl-2">{item.isDefault ? 'Feature' : item.name}</p>
        </div>
      </div>
      <div className={Style.mainWrapper}>
        {item.images && item.images.map((image: PerformerImage) => <DirectorPhotoView photo={image} key={image.id} />)}
      </div>
    </>
  );
};
export default Images;
