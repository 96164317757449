import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { RateList } from 'modules/Show/show.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Modal from 'components/controls/Modal/Modal';
import Button from 'components/controls/button/button';
import plusSvg from 'assets/svg/plus.svg';
import { viewRateBegin } from 'store/actions/payroll.actions';
import RateItem from './RateItem';
import CustomRateItem from './customRateItem';

interface Props {
  getRateListRequest: Function;
  allRates: RateList;
  addCustomRates: any;
  getCustomRateList: Function;
  customRates: RateList;
  onClickHandler: any;
  id: Function;
  isEditAble: Function;
  viewRate: Function;
}

const SideRateOption: React.FC<Props> = (props: Props) => {
  const {
    getRateListRequest,
    allRates,
    addCustomRates,
    getCustomRateList,
    customRates,
    onClickHandler,
    id: rateId,
    isEditAble,
    viewRate,
  } = props;
  const [newRateConfigModalVisible, setNewRateConfigVisibility] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState(false);
  const [rateModalInputValue, setModalInputValue] = useState({
    id: '',
    title: '',
    rate: '',
    code: '',
    createdBy: '',
  });
  const [rates, setRates] = useState<RateList>([]);
  const [selectedId, setselectedId] = useState<any>(null);
  const [customPerformerrates, setcCustomPerformerrates] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalTitle, setModalTitle] = useState('Add');
  const userId = JSON.parse(localStorage.getItem('user') || '').id;
  useEffect(() => {
    getRateListRequest({});
    getCustomRateList({});
  }, []);
  useEffect(() => {
    setRates(allRates);
    setcCustomPerformerrates(customRates);
  }, [allRates, customRates]);
  const handleOnChange = (value: any, key: any) => {
    if (key === 'rate') {
      const parseInt = Number(value);
      setModalInputValue({ ...rateModalInputValue, [key]: parseInt });
    } else {
      setModalInputValue({ ...rateModalInputValue, [key]: value });
    }
  };
  const handleOnSubmit = () => {
    rateModalInputValue.createdBy = userId;
    addCustomRates(rateModalInputValue);
    setModalInputValue({
      id: '',
      title: '',
      rate: '',
      code: '',
      createdBy: '',
    });
    setIsClicked(false);
    getCustomRateList({});
  };
  const onChangeHandler = useCallback(
    (id: string, atrribute: string) => {
      setselectedId(id);
      const clicked = !isClicked;
      setIsClicked(clicked);
      onClickHandler(id, atrribute);
    },
    [isClicked],
  );
  const handleId = (id: any) => {
    setSelectedItem(id);
    rateId(id);
  };
  const handleEdit = (e: any) => {
    // setModalInputValue({
    //   id: e._id,
    //   title: e.title,
    //   rate: e.rate,
    //   code: e.code,
    //   createdBy: e.createdBy,
    // });
    // setModalTitle('Update');
    // setNewRateConfigVisibility(!newRateConfigModalVisible);
    viewRate({ title: e.title });
  };
  return (
    <RateOptionContainer>
      <StyledHeader>Rates</StyledHeader>
      {customRates.length > 0 ? (
        <RateItemsWrapper>
          {rates.map((item: any, i) => (
            <RateItem
              key={i}
              name={item.title}
              selected={item._id === selectedItem}
              custom={false}
              handleId={() => {
                handleId(item._id);
                isEditAble(false);
              }}
            />
          ))}
          {customRates?.map((item: any, i) => (
            <CustomRateItem
              key={i}
              item={item}
              id={item._id}
              custom={item._id === selectedId ? isClicked : false}
              onChangeHandler={onChangeHandler}
              handleReceiveId={() => {
                handleId(item._id);
                if (userId === item.createdBy) isEditAble(true);
              }}
              selected={item._id === selectedItem}
              userId={userId}
              handleEdit={() => handleEdit(item)}
            />
          ))}
        </RateItemsWrapper>
      ) : (
        <></>
      )}

      <StyledButtonWrapper
        onClick={() => {
          setNewRateConfigVisibility(!newRateConfigModalVisible);
          setModalTitle('Add');
        }}
      >
        <p>New</p>
        <div>
          <FontAwesomeIcon icon={faPlus} />
        </div>
      </StyledButtonWrapper>
      {newRateConfigModalVisible && (
        <Modal
          title={`${modalTitle} Rate Config`}
          body={() => (
            <NewRateModalBody>
              <RateLabel>Title</RateLabel>
              <StyledInput
                value={rateModalInputValue.title}
                onChange={(e) => handleOnChange(e.target.value, 'title')}
              />
              <RateLabel>Rate</RateLabel>
              <StyledInput value={rateModalInputValue.rate} onChange={(e) => handleOnChange(e.target.value, 'rate')} />
              <RateLabel>Code</RateLabel>
              <StyledInput value={rateModalInputValue.code} onChange={(e) => handleOnChange(e.target.value, 'code')} />
              <Button
                label={`${modalTitle} Rate`}
                icon={plusSvg}
                clickHandler={() => {
                  // setRateItemsList([...rateItemsList, { name: rateModalInputValue, custom: true }]);
                  // setModalInputValue('');
                  handleOnSubmit();
                  setNewRateConfigVisibility(false);
                }}
              />
            </NewRateModalBody>
          )}
          closeModal={() => setNewRateConfigVisibility(false)}
        />
      )}
    </RateOptionContainer>
  );
};

const mapDispatchToProps = {
  viewRate: viewRateBegin,
};

export default connect(null, mapDispatchToProps)(SideRateOption);
// Modal Body
const NewRateModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;
const RateLabel = styled.div`
  display: flex;
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: #4b4d67;
`;
const StyledInput = styled.input`
  border: 0.5px solid #f2f2f2;
  flex: 1;
  border-radius: 8px;
  height: 40px;
  padding: 10px;
  margin-bottom: 10px;
`;

// End modal body
const RateOptionContainer = styled.div`
  position: relative;
  width: 295px;
  padding: 12px 0px;
  border-radius: 8px;
  margin-right: 20px;
  height: 455px;
  background: white;
  padding-right: 7px;
`;

const StyledHeader = styled.p`
  margin: 0;
  margin-bottom: 12px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  color: #4b4d67;
  font-size: 14px;
  display: flex;
  line-height: 17px;
  padding-left: 15px;
`;

const StyledButtonWrapper = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  margin-top: 17px;
  margin-left: 23px;
  margin-bottom: 17px;
  width: 91.59px;
  height: 30px;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(73, 211, 96, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  cursor: pointer;

  & > p {
    width: 33px;
    height: 15px;
    border: none;
    outline: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #49d360;
    margin-bottom: 0px;
  }

  & > div {
    width: 12px;
    height: 18px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #49d360;
  }
`;

const RateItemsWrapper = styled.div`
  flex: 1;
  height: 350px;
  margin-bottom: 40px;
  overflow-x: hidden;
  overflow-y: auto;
`;
