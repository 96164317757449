import React, { FunctionComponent, useState } from 'react';
import InputStyle from 'components/controls/input/input.module.css';
import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

interface InputProps {
  label?: string;
  value: string;
  errorMessage?: string;
  handleChange: (
    formattedDate: string,
    date: Date | null,
    minDate?: moment.Moment,
    maxDate?: moment.Moment,
    cssClass?: string,
    disabled?: boolean,
  ) => void;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  cssClass?: string;
  customClass?: string;
  createShowClass?: string;
  disabled?: boolean;
  profileFieldMargin?: string;
}

const DateInput: FunctionComponent<InputProps> = (props: InputProps) => {
  const {
    label,
    value,
    handleChange,
    errorMessage,
    minDate,
    maxDate,
    cssClass,
    profileFieldMargin,
    disabled,
    customClass,
    createShowClass,
  } = props;

  const [selectedDate, setSelectedDate] = useState<Date | null>(value ? moment(value).toDate() : null);

  const parseDate = (val: Date | null) => {
    setSelectedDate(val);
    if (val) {
      handleChange(moment(val).format('YYYY-MM-DD'), val, minDate, maxDate, cssClass, disabled);
    }
  };

  return (
    <>
      <div
        className={`createShowMobile
        ${InputStyle.inputWrapper} ${InputStyle.inputGroup} ${profileFieldMargin} ${customClass} ${createShowClass}`}
      >
        <DatePicker
          selected={selectedDate}
          onChange={parseDate}
          dateFormat="dd-MM-yyyy"
          minDate={minDate ? minDate.toDate() : undefined}
          maxDate={maxDate ? maxDate.toDate() : undefined}
          disabled={disabled}
          className={`form-control ${cssClass}`}
        />
        <span className="input-datePicker">{label}</span>
        {errorMessage ? <TextErrorMessage message={errorMessage} /> : ''}
      </div>
    </>
  );
};

DateInput.defaultProps = {
  disabled: false,
};

export default DateInput;
