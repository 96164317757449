import React, { FunctionComponent } from 'react';
import Style from 'components/CreateShow/Detail/SeasonDateItem/Item.module.css';
import { ShowWorkingDay } from 'modules/Show/show.types';

interface SeasonItemProps {
  day: ShowWorkingDay;
}
const SeasonItem: FunctionComponent<SeasonItemProps> = (props: SeasonItemProps) => {
  const { day } = props;
  return (
    <div className={`col-sm-12 ${Style.seasonWrapper}`}>
      <div>
        <p className={`mb-0 ${Style.seasonDate}`}>Aug 3</p>
        <div className={Style.seasonEpisodeWrapper}>
          <p className={`mb-0 ${Style.seasonEpisodeTitle}`}>{day.title}</p>
        </div>
      </div>
    </div>
  );
};

export default SeasonItem;
