import React, { FunctionComponent, useEffect, useState } from 'react';
import Style from 'components/castingDirector/PopOvers/CoordinatorDetail/Coordinator.module.css';
import editSvg from 'assets/svg/edit.svg';
import plusBlueSvg from 'assets/svg/plus-blue.svg';
import removeSvg from 'assets/svg/remove-2.svg';
import searchSvg from 'assets/svg/search.svg';
// import profile from 'assets/images/exp.png';
import SearchList from 'components/castingDirector/PopOvers/CoordinatorDetail/SearchList';
import userApi from 'store/services/user.services';
import { User, UserList } from 'modules/user/types';
import { IAddCoordinator } from 'modules/params/param.type';
import { connect } from 'react-redux';
import { manageCoordinatorBegin, showCoordinatorInvitationBegin } from 'store/actions/show.actions';
import { getMatrixCoordinatorIds, getMatrixCoordinators } from 'store/selector/show.selector';
import { Show } from 'modules/Show/show.types';
import { checkEmail } from 'utils/FormValidation';
import { showToast } from 'store/actions/general.actions';
import { TOAST_IDS, TOAST_TYPE } from 'store/castingPax.constants';
import InvitationItem from './InvitationItem';

interface MatrixCoordinatorDetailProps {
  workingDayId: string;
  coordinatorIds: string[];
  manageCoordinator: Function;
  coordinators: any;
  showMessage: Function;
  sendCoordinatorInvitation: Function;
}

const MatrixCoordinatorDetail: FunctionComponent<MatrixCoordinatorDetailProps> = (
  props: MatrixCoordinatorDetailProps,
) => {
  const { workingDayId, manageCoordinator, coordinatorIds, coordinators, showMessage, sendCoordinatorInvitation } =
    props;
  const [searchText, setSearchText] = useState<string>('');
  const [list, setList] = useState<UserList>(coordinators.users);

  useEffect(() => {
    const timeOutId = setTimeout(() => searchUser(searchText), 500);
    return () => clearTimeout(timeOutId);
  }, [searchText]);

  const searchUser = (text: string) => {
    if (text.length === 0) {
      setList(coordinators.users);
      return;
    }
    userApi
      .searchCoordinator({ searchText: text })
      .then((res: UserList) => {
        setList(res);
      })
      .catch((err) => console.log('[ERROR]', err));
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
  };
  const handleClick = (user: User, isAdded: boolean) => {
    const params: IAddCoordinator = { id: user.id, workingDayId, isAdded };
    manageCoordinator(params, user);
  };

  const handleAddNew = () => {
    if (searchText.length === 0 || checkEmail(searchText)) {
      showMessage({
        id: TOAST_IDS.EMAIL_NOT_VALID_ERROR,
        title: 'Please enter valid email',
        type: TOAST_TYPE.ERROR,
      });
      return;
    }
    sendCoordinatorInvitation({ id: workingDayId, email: searchText, isAdded: true });
  };

  const handleRemoveInvitation = (val: string) => {
    sendCoordinatorInvitation({ id: workingDayId, email: val, isAdded: false });
  };

  return (
    <div className={Style.mainWrapper}>
      <div className={`d-flex ${Style.topActionBar}`}>
        <div className={`d-flex align-items-center ${Style.actionItemWrapper}`}>
          <img src={editSvg} alt="" />
          <p className="mb-0">Edit</p>
        </div>
        <div className={`d-flex align-items-center ${Style.actionItemWrapper}`}>
          <img src={removeSvg} alt="" />
          <p className="mb-0">Remove</p>
        </div>
        <div
          className={`d-flex align-items-center ${Style.actionItemWrapper}`}
          onClick={handleAddNew}
          onKeyPress={handleAddNew}
          tabIndex={0}
          role="button"
        >
          <img src={plusBlueSvg} alt="" />
          <p className="mb-0">Add new</p>
        </div>
      </div>
      <div className={Style.searchSection}>
        <img src={searchSvg} alt="" className={Style.searchIcon} />
        <input type="text" placeholder="Search" onChange={handleChange} />
      </div>
      <div className={`customScrollBar ${Style.manageSearchListing}`}>
        {coordinators.invitation.map((item: string, index: number) => (
          <InvitationItem key={index} item={item} handleClick={handleRemoveInvitation} />
        ))}
        {list.map((item: User) => (
          <SearchList key={item.id} item={item} isAdded={coordinatorIds.includes(item.id)} handleClick={handleClick} />
        ))}
      </div>
    </div>
  );
};

const mapStatToProps = (state: Show) => {
  const coordinators = getMatrixCoordinators(state);
  const coordinatorIds = getMatrixCoordinatorIds(state);
  return {
    coordinators,
    coordinatorIds,
  };
};

const mapDispatchToProps = {
  manageCoordinator: manageCoordinatorBegin,
  showMessage: showToast,
  sendCoordinatorInvitation: showCoordinatorInvitationBegin,
};

export default connect(mapStatToProps, mapDispatchToProps)(MatrixCoordinatorDetail);
