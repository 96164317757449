import React, { FunctionComponent, useEffect } from 'react';
import Style from 'components/ProfileMenu/Settings/Setting.module.css';
import visaImage from 'assets/images/visa-card.png';
import masterCardImage from 'assets/images/masterCard.png';
import Input from 'components/controls/input/dashboardInput/dashboardInput';
import { User } from 'modules/user/types';
import { useDispatch, useSelector } from 'react-redux';
import { userCardDetails, userCardError } from 'store/selector/auth.selector';
import { clearCardError, getCardDetailsBegin } from 'store/actions/auth.actions';
import { toast } from 'react-toastify';

interface CardDetailsProps {
  user: User;
}

const CardDetails: FunctionComponent<CardDetailsProps> = (props: CardDetailsProps) => {
  const { user } = props;
  const cardDetails: any = useSelector(userCardDetails);
  const cardError: string = useSelector(userCardError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.cardId) {
      dispatch(getCardDetailsBegin());
    }
  }, [user.cardId]);

  useEffect(() => {
    if (cardError) {
      toast(cardError);
      dispatch(clearCardError());
    }
  }, [cardError]);

  if (!cardDetails) {
    return <></>;
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between pt-4">
        <p className={`mb-0 ${Style.settingHeading}`}>Card Details</p>
        <div className={Style.billingMethodImages}>
          <img src={visaImage} alt="Visa Card" />
          <img src={masterCardImage} alt="Master Card" />
        </div>
      </div>
      <div className="row pt-4">
        <div className="col-lg-6">
          <Input
            placeholder="Card Last four digits"
            type="text"
            value={`***${cardDetails.card.last4}`}
            cssClass={Style.input}
            inputLabel={Style.settingInputLabel}
            label="Card Last four digits"
          />
        </div>
        <div className="col-lg-6">
          <Input
            placeholder="Name on Card"
            type="text"
            value={cardDetails.cardHolderName}
            cssClass={Style.input}
            inputLabel={Style.settingInputLabel}
            label="Name on Card"
          />
        </div>
        <div className="col-lg-6">
          <Input
            placeholder="Expiration"
            type="text"
            value={`${cardDetails.card.exp_month.toString().padStart(2, '0')}/${cardDetails.card.exp_year}`}
            cssClass={Style.input}
            inputLabel={Style.settingInputLabel}
            label="Expiration"
          />
        </div>
      </div>
    </>
  );
};

export default CardDetails;
