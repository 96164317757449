import React from 'react';
import styled from 'styled-components';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledDownArrow = styled.div`
  width: 12px;
  height: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 50px;
  margin-left: 22.77px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

interface Props {
  toggleIcon: boolean;
  color?: string;
}

const DownArrow: React.FC<Props> = ({ toggleIcon, color }) => (
  <StyledDownArrow>
    <FontAwesomeIcon color={color} icon={toggleIcon ? faAngleUp : faAngleDown} />
  </StyledDownArrow>
);

export default DownArrow;
