import React, { FunctionComponent, useState } from 'react';
import CheckBox from 'components/controls/checkbox/checkBox';

interface IPerformerTimeProps {
  params: any;
  handleCellIconClick: Function;
}
const PerformerVoucher: FunctionComponent<IPerformerTimeProps> = (props) => {
  const { params, handleCellIconClick } = props;

  const [checkAllowances, setCheckAllowances] = useState<boolean>(params.data.isAllowancesEnable);

  const handleAllowances = (isCheck: boolean) => {
    const { data } = params;
    data.isAllowancesEnable = isCheck;
    setCheckAllowances(isCheck);
    if (handleCellIconClick) handleCellIconClick(params, isCheck);
  };

  const checkDisable = () => {
    if (params.data.status === 'booked' && !params.data.invoiceId && !params.data.isDelete) return false;
    return true;
  };

  const handleShow = () => {
    if (!params.node.data.performerId) {
      return '';
    }
    const {
      colDef: { field },
    } = params;

    if (field === 'isAllowancesEnable') {
      return (
        <>
          <div style={{ bottom: '15px', position: 'relative' }}>
            <CheckBox
              id={`${params.data.id}-${Math.random().toString()}`}
              handleChecked={handleAllowances}
              defaultCheck={checkAllowances}
              isDisable={checkDisable()}
            />
          </div>
          {/* <span style={{ position: 'absolute', bottom: '-5px', right: '20px' }}>{params.data.rate}</span> */}
        </>
      );
    }

    return '';
  };

  return <>{handleShow()}</>;
};

export default PerformerVoucher;
