import styled from 'styled-components';

interface Props {
  withLabel?: boolean;
  width?: number;
  style?: any;
  rightRadius?: boolean;
  radius?: number;
  height?: number;
  color?: string;
  borderRight?: string;
}

const StyledSearchTab = styled.div<Props>`
  display: flex;
  padding-left: 18px;
  align-items: center;
  flex: 1;
  font-weight: 500;
  border-right: ${({ borderRight }) => (borderRight ? `1px solid ${borderRight}` : 'none')};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray4};
  width: ${({ width }) => (width ? `${width}%` : '')};
  border-top-right-radius: ${({ rightRadius }) => (rightRadius ? '8px' : '')};
  border-bottom-right-radius: ${({ rightRadius }) => (rightRadius ? '8px' : '')};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : '')};
`;

export default StyledSearchTab;
