/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useEffect, useState } from 'react';
import Styled from 'styled-components';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShowList } from 'modules/Show/show.types';
import InprogressItem from './InprogressItem';

interface ShowInprogressProps {
  showList: ShowList;
  getShowListing: Function;
}

const InprogressComponent: FunctionComponent<ShowInprogressProps> = (props) => {
  const { showList, getShowListing } = props;
  const [showSideNav, setShowSideNav] = useState(false);
  useEffect(() => {
    getShowListing();
  }, []);

  const progressItems = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <InprogressContainer>
      <HeaderContainer>
        <StyledCalender icon={faCalendarAlt} />
        <InprogresHeader>In progress</InprogresHeader>
      </HeaderContainer>
      <InprogressBottom>
        {showList && showList.map((item, key) => <InprogressItem key={key} show={item} />)}
      </InprogressBottom>
    </InprogressContainer>
  );
};

const InprogressContainer = Styled.div`
  display: flex;
  width: 20.33%;
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  overflow: hidden;
  @media only screen and (max-width: 991px) {
    width: 100%;
    margin-top: 15px;
  }
`;

const HeaderContainer = Styled.div`
display: flex;
width: 100%;
border-bottom: 3px solid #F2F2F2;
padding: 15px 0;
padding-left: 21px;
align-items: center;
`;

const InprogresHeader = Styled.p`
margin: 0;
display: flex;
width: 100%;
height: 100%;
padding-left: 6px;
font-size: 16px;
font-weight: 400;
font-family: 'Montserrat';
`;

const StyledCalender = Styled(FontAwesomeIcon)`
  color: #9B51E0;
`;

const InprogressBottom = Styled.div`
padding-top: 6px;
overflow-y: scroll;
height:100%;
max-height: 400px;
margin-bottom: 16px;
`;

export default InprogressComponent;
