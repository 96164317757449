import { SubscriptionPlan, User } from 'modules/user/types';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Style from 'components/ProfileMenu/Settings/Setting.module.css';
import Item from 'components/ProfileMenu/subscription/Item';
import api from 'store/services/auth.services';
import {
  getHistoryData,
  getHistoryLoading,
  getPagination,
  subscriptionStatus,
  userCardDetails,
} from 'store/selector/auth.selector';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from 'components/controls/Modal/Modal';
import Confirmation from 'components/ProfileMenu/subscription/Confirmation';
import { FETCH_STATUS } from 'store/castingPax.constants';
import SubscriptionHistory from 'components/ProfileMenu/subscription/SubscriptionHistory';
import { Pagination } from 'modules/jobs/types';
import { subscriptionHistoryBegin } from 'store/actions/auth.actions';

interface SubscriptionProps {
  user: User;
  cardData?: any;
  fetchStatus: string;
  historyPagination?: Pagination;
  historyData?: any;
  isLoading?: boolean;
}

const Subscription: FunctionComponent<SubscriptionProps> = (props: SubscriptionProps) => {
  const { user, cardData, fetchStatus, historyPagination, historyData, isLoading } = props;
  const [plans, setPlans] = useState<SubscriptionPlan[]>();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>();
  const dispatch = useDispatch();
  const mountedRef = useRef(true);

  useEffect(() => {
    api
      .fetchSubscriptionPlanList()
      .then((res: SubscriptionPlan[]) => {
        if (!mountedRef.current) return;
        setPlans(res);
      })
      .catch((err) => err);
  }, []);

  useEffect(() => {
    if (fetchStatus === FETCH_STATUS.SUCCESS) {
      handleClose();
    }
  }, [fetchStatus]);

  const handlePlanChange = (item: SubscriptionPlan) => {
    if (!cardData) {
      toast('Please add card detail first');
      return;
    }
    setSelectedPlan(item);
    setShowConfirmation(true);
  };

  const confirmatioModalBody = () => <Confirmation handleClose={handleClose} item={selectedPlan} />;
  const handleClose = () => {
    setSelectedPlan(undefined);
    setShowConfirmation(false);
  };

  const handleShowHistory = () => {
    setShowHistory(true);
    dispatch(subscriptionHistoryBegin({ page: 1, limit: 10 }));
  };

  const handleCloseHistory = () => setShowHistory(false);

  const historyBody = () => (
    <SubscriptionHistory loading={isLoading || false} data={historyData} pagination={historyPagination} />
  );

  return (
    <div className={`${Style.profileWrapper}`}>
      <div className={`${Style.subscriptionWrapper}`}>
        {plans &&
          plans.map((p: SubscriptionPlan, index: number) => (
            <Item key={index} item={p} clickHandle={handlePlanChange} index={index} currentPlan={user?.subscription} />
          ))}
      </div>
      <div className="mt-30">
        <p className={`mb-0 ${Style.purchaseHistory}`}>View Purchase history</p>
        <p className={`mb-0 mt-3 ${Style.purchaseHistory}`}>
          <span onClick={handleShowHistory} onKeyPress={handleShowHistory} role="button" tabIndex={0}>
            View Purchase history
          </span>
        </p>
      </div>
      {showConfirmation && (
        <Modal title="Confirm Plan" body={confirmatioModalBody} closeModal={handleClose} cssClass="" />
      )}
      {showHistory && (
        <Modal title="Subscription History" body={historyBody} closeModal={handleCloseHistory} cssClass="" />
      )}
    </div>
  );
};

const mapStateToProps = (state: User) => {
  const cardData = userCardDetails(state);
  const fetchStatus = subscriptionStatus(state);
  const historyData = getHistoryData(state);
  const historyPagination = getPagination(state);
  const isLoading = getHistoryLoading(state);

  return { cardData, fetchStatus, historyData, historyPagination, isLoading };
};

export default connect(mapStateToProps, null)(Subscription);
