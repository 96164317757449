import styled from 'styled-components';

const StyledStatus = styled.div`
  width: 100px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: rgba(73, 211, 96, 0.1);
  cursor: pointer;
  p {
    width: fit-content;
    margin-bottom: 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #49d360;
  }

  div {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.gray4};
  }
`;

export default StyledStatus;
