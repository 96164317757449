import React, { FunctionComponent } from 'react';
import Style from 'components/castingDirector/PopOvers/CoordinatorDetail/Coordinator.module.css';
import { User } from 'modules/user/types';
import { getImageThumbnail, getName } from 'helpers/utils';
import CheckBox from 'components/controls/checkbox/checkBox';

interface UserViewProps {
  item: User;
  isAdded: boolean;
  handleClick: Function;
}
const UserView: FunctionComponent<UserViewProps> = (props: UserViewProps) => {
  const { item, isAdded, handleClick } = props;
  const handleChecked = (val: boolean) => {
    handleClick(val, item);
  };
  return (
    <div
      className={`d-flex justify-content-between align-items-center ${Style.searchListWrapper} ${
        isAdded && Style.selected
      }`}
    >
      <div className="d-flex align-items-center">
        <CheckBox cssClass="shareMatrixCheckBox" id={item.id} handleChecked={handleChecked} />
        <div className="d-flex align-items-center">
          <img src={getImageThumbnail(item.profilePicUrl)} className={Style.profileImage} alt="" />
          <p className={`mb-0 ${Style.profileName}`}>{getName(item)}</p>
        </div>
      </div>
    </div>
  );
};

export default UserView;
