import React, { FunctionComponent, useEffect, useState } from 'react';
import Style from 'components/Sort/Sort.module.css';
import DropDownSearch from 'components/controls/select/DropDownSearch';
import searchSvg from 'assets/svg/search.svg';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import upArrowSvg from 'assets/svg/up-arrow.svg';
import { SCREEN_SIZE_TABLET, SCREEN_SIZE_MOBILE, SCREEN_SIZE_MEDIUM_TABLET } from 'store/castingPax.constants';
import { ScreenSize } from 'modules/general/general.type';
import { useSelector } from 'react-redux';
import { getScreenWidth } from 'store/selector/general.selector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { getFormattedCurrentDate } from 'helpers/utils';
import DateRange from 'components/controls/dateRange/dateRange';
import moment from 'moment';

interface ISortBy {
  handleSearch?: Function;
  reOrder?: Function;
  filterDates?: Function;
  cssClass?: string;
}
const SortBy: FunctionComponent<ISortBy> = (props: ISortBy) => {
  const { handleSearch, reOrder, filterDates, cssClass } = props;
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);
  const [searchText, setSearchText] = useState<string>('');
  const [dateRange, setDateRange] = useState<string>('');

  useEffect(() => {
    let timeOutId: any;
    if (handleSearch) timeOutId = setTimeout(() => handleSearch(searchText), 300);
    return () => clearTimeout(timeOutId);
  }, [searchText]);

  const handleSearchText = (event: React.FormEvent<HTMLInputElement>) => {
    setSearchText(event.currentTarget.value);
  };

  const handleReOrder = (num: number) => {
    if (reOrder) reOrder(num);
  };

  const handleDateChange = (start: any, end: any) => {
    setDateRange(`${moment(start).format('DD-MM-YYYY')} to ${moment(end).format('DD-MM-YYYY')}`);
    const arr = enumerateDaysBetweenDates(start, end);
    if (filterDates) filterDates(arr);
  };

  const enumerateDaysBetweenDates = (start: any, end: any) => {
    const date = [];
    for (let m = moment(start); m.isBefore(end); m.add(1, 'days')) {
      date.push(m.format('YYYY-MM-DD 00:00:00'));
    }
    return date;
  };
  if (
    currentScreenSize === SCREEN_SIZE_MEDIUM_TABLET ||
    currentScreenSize === SCREEN_SIZE_TABLET ||
    currentScreenSize === SCREEN_SIZE_MOBILE
  ) {
    return (
      <div className={`mobileResposniveView ${cssClass}`}>
        <div className={`d-flex flex-column justify-content-between ${Style.sortWrapper}`}>
          <div className="form-group mb-3 position-relative w-100">
            <input
              type="text"
              value={searchText}
              placeholder="Search"
              onChange={handleSearchText}
              className={`border-0 form-control ${Style.searchArea}`}
            />
            <FontAwesomeIcon icon={faSearch} className={Style.inputSearchIcon} />
          </div>
          <div className={Style.sortingContentMobile}>
            <p className={`mb-0  ${Style.sortTitle}`}>Sort by</p>
            <div className={Style.searchbarIconsWrapperMobile}>
              <div onClick={() => handleReOrder(-1)} onKeyPress={() => handleReOrder(-1)} role="button" tabIndex={0}>
                <img src={upArrowSvg} alt="" />
              </div>
              <div onClick={() => handleReOrder(1)} onKeyPress={() => handleReOrder(1)} role="button" tabIndex={0}>
                <img src={downArrowSvg} alt="" />
              </div>
            </div>
          </div>
          <div className={`w-100 d-flex align-items-center justify-content-between ${Style.dateActiveWrapperMobile}`}>
            <div className={`d-flex performerSearchWrapper searchSectionWrapper ${Style.searchSort}`}>
              <DropDownSearch defaultValue="Date" />
            </div>
            <div className={`d-flex performerSearchWrapper searchSectionWrapper ${Style.searchSort}`}>
              <DropDownSearch defaultValue="Active" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`${cssClass} ${Style.SortWebView}`}>
      <div className={`justify-content-between ${Style.sortWrapper}`}>
        <p className={`mb-0  ${Style.sortTitle}`}>Sort by</p>
        <div className={`d-flex performerSearchWrapper ${Style.searchSort} ${Style.dateSort}`}>
          <DateRange
            minDate="01-01-2021"
            startDate={getFormattedCurrentDate('MM-DD-YYYY')}
            endDate={getFormattedCurrentDate('MM-DD-YYYY')}
            handleDateChange={handleDateChange}
          >
            <div className="d-flex performerSearchWrapper align-items-center w-100">
              {dateRange && dateRange ? (
                <span className={`mb-0 ${Style.sortDateTitle}`} style={{ color: '#333' }}>
                  {dateRange}
                </span>
              ) : (
                <span className={`mb-0 ${Style.sortDateTitle}`}>Date</span>
              )}
              <FontAwesomeIcon icon={faChevronDown} className={Style.dateIcon} />
            </div>
          </DateRange>
        </div>
        <div className={`d-flex performerSearchWrapper sortActiveNoBorder searchSectionWrapper  ${Style.searchSort}`}>
          <DropDownSearch defaultValue="Active" />
        </div>
        <div className={Style.searchbarIconsWrapper}>
          <div onClick={() => handleReOrder(-1)} onKeyPress={() => handleReOrder(-1)} role="button" tabIndex={0}>
            <img src={upArrowSvg} alt="" />
          </div>
          <div onClick={() => handleReOrder(1)} onKeyPress={() => handleReOrder(1)} role="button" tabIndex={0}>
            <img src={downArrowSvg} alt="" />
          </div>
        </div>
        <div className={`form-group mb-0 position-relative ${Style.searchAreaWrapper}`}>
          <input
            type="text"
            placeholder="Search"
            onChange={handleSearchText}
            className={`border-0 form-control ${Style.searchArea}`}
          />
          <img src={searchSvg} alt="" className={Style.inputSearchIcon} />
        </div>
      </div>
    </div>
  );
};

export default SortBy;
