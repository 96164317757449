import React, { FunctionComponent, useState } from 'react';
import Style from 'components/bgPerformer/SubmissionSearch/Search.module.css';
import Button from 'components/controls/button/button';
import searchSvg from 'assets/svg/search.svg';
import searchWhiteSvg from 'assets/svg/search-white.svg';
import saveSvg from 'assets/svg/save.svg';
import eyeSvg from 'assets/svg/eye.svg';
import DropDownSearch from 'components/controls/select/DropDownSearch';
import { OptionList, Options, ScreenSize } from 'modules/general/general.type';
import PerformerSearchFilterTab from 'components/PerformerSearchFilterTabs/PerformerSearchFilterTab';
import { SearchFilterType, SearchFilterTypeList } from 'modules/PerformerList/list.types';
import { temporarySaveSearchFilter } from 'utils/LocalStorageItems';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { getScreenWidth } from 'store/selector/general.selector';
import searchBlackSvg from 'assets/svg/search-black.svg';
import { getFormattedCurrentDate } from 'helpers/utils';
import downArrowSvg from 'assets/svg/down-arrow.svg';
// import moment from 'moment';
import DateRange from 'components/controls/dateRange/dateRange';
import { SCREEN_SIZE_MOBILE } from 'store/castingPax.constants';

const SearchView: FunctionComponent = () => {
  const [category, setCategory] = useState<string>('');
  const [location, setLocation] = useState<OptionList>([]);
  const history = useHistory();
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);

  const clearHandle = () => {
    temporarySaveSearchFilter({});
    setLocation([]);
    setCategory('');
    history.push('/job/search');
  };

  const searchHandle = () => {
    history.push('/job/search');
    temporarySaveSearchFilter({ category, location });
  };

  const handleRemoveFilter = (e: React.FormEvent<HTMLDivElement>, value: string, type: string) => {
    if (type === 'category') {
      setCategory(
        category
          .split(',')
          .filter((x: string) => x !== value)
          .join(','),
      );
    } else if (type === 'location' && location) {
      const index = location.findIndex((x: Options) => x.label === value);
      if (index !== -1) {
        location.splice(index, 1);
        setLocation([...location]);
      }
    }
  };

  const showFilters = () => {
    const arr: SearchFilterTypeList = [];

    if (category) {
      category.split(',').forEach((value: string) => {
        arr.push({
          label: value,
          remove: (event) => {
            handleRemoveFilter(event, value, 'category');
          },
        });
      });
    }
    if (location) {
      location.forEach((opt: Options) => {
        arr.push({
          label: opt.label,
          remove: (event) => {
            handleRemoveFilter(event, opt.label, 'location');
          },
        });
      });
    }

    return arr.map((filter: SearchFilterType, index) => <PerformerSearchFilterTab key={index} filter={filter} />);
  };
  const handleDateChange = () => {
    console.log(handleDateChange);
    // setDateRange(`${moment(start).format('DD-MM-YYYY')} to ${moment(end).format('DD-MM-YYYY')}`);
  };
  if (currentScreenSize === SCREEN_SIZE_MOBILE) {
    return (
      <>
        <div className="MobileResponsiveView">
          <div className={Style.responsiveBoxWrapper}>
            <div className={Style.responsiveHeader}>
              <img src={searchBlackSvg} alt="search icon" />
              <p className="mb-0 ml-2">Search</p>
            </div>
            <div className="form-group">
              <DateRange
                startDate={getFormattedCurrentDate('MM-DD-YYYY')}
                endDate={getFormattedCurrentDate('MM-DD-YYYY')}
                handleDateChange={handleDateChange}
              >
                <div className={`d-flex ${Style.dateRangeInput}`}>
                  Date
                  <img src={downArrowSvg} alt="dropdown-arrow" />
                </div>
              </DateRange>
            </div>
            <div className="form-group performerSearchWrapper">
              <DropDownSearch defaultValue="Select" />
            </div>
            <div className="form-group performerSearchWrapper">
              <DropDownSearch defaultValue="Casting Director" />
            </div>
            <div className="form-group performerSearchWrapper">
              <DropDownSearch defaultValue="#BG" />
            </div>
            <div className="col-sm-12 d-flex flex-wrap">{showFilters()}</div>
            <div className="col-sm-12 text-right d-flex justify-content-between  mt-2 px-0">
              <Button label="See all jobs" icon={eyeSvg} clickHandler={clearHandle} cssClass={Style.clearBtn} />
              <div className={`mb-0 ml-2 ${Style.saveBtndiv}`}>
                <img src={saveSvg} alt="" />
                <span className="d-none">&nbsp;</span>
              </div>
              <Button
                label=""
                icon={searchWhiteSvg}
                clickHandler={() => {
                  searchHandle();
                }}
                cssClass={Style.searchBtn}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={Style.castingSearchTitle}>
        <img src={searchSvg} alt="" />
        <p className="mb-0 ml-2">Search</p>
      </div>
      <div className="row performerSearchWrapper">
        <div className="col-sm-6">
          <DropDownSearch
            cssClass={Style.marginTopThirty}
            defaultValue="Select"
            optionType="category"
            value={category}
            isMultiSelect
            handleSelect={setCategory}
          />
        </div>
        <div className="col-sm-6">
          <span className="mb-0">&nbsp;</span>
          <DropDownSearch
            cssClass={Style.searchTopMargin}
            defaultValue="Select"
            optionType="location"
            storeObject
            selectedObj={location}
            isMultiSelect
            handleSelect={setLocation}
          />
        </div>
        <div className="col-sm-12 d-flex flex-wrap">{showFilters()}</div>
      </div>
      <div className="col-sm-12 text-right d-flex justify-content-between  mt-2 px-0">
        <Button label="See all jobs" icon={eyeSvg} clickHandler={clearHandle} cssClass={Style.clearBtn} />
        <div className={`mb-0 ml-2 ${Style.saveBtndiv}`}>
          <img src={saveSvg} alt="" />
          <span className="d-none">&nbsp;</span>
        </div>
        <Button
          label=""
          icon={searchWhiteSvg}
          clickHandler={() => {
            searchHandle();
          }}
          cssClass={Style.searchBtn}
        />
      </div>
    </>
  );
};

export default SearchView;
