import React, { FunctionComponent, useEffect } from 'react';
import ShowTopNavigation from 'components/ShowTopNavigation';
import ShowLeftNavigation from 'components/ShowLeftNavigation';
import MainContainer from 'containers/MainContainer';
import MyPerformerListContainer from 'containers/MyPerformerListContainer';
import LandingStyle from 'views/castingDirector/landing/landing.module.css';
import ShowRoleContainer from 'containers/ShowJobContainer/ShowJobContainer';
import TopBarCalendar from 'components/calendar/TopBarCalender/TopBarCalendar';
import TopNavBar from 'components/TopNavBar/TopNavBar';
import { Show, ShowTopCalendarTypeList } from 'modules/Show/show.types';
import FindPerformerContainer from 'containers/FindPerformerContainer';
import PerformerSearchResultContainer from 'containers/PerformerSearchResultContainer';
import Matrix from 'components/castingDirector/index';

// import CoordinatorMatrix from 'components/Coordinator/Matrix/CoordinatorMatrix';

interface CastingDirectorLandingProps {
  selcetedHomeTab: string;
  selectedShow: Show;
  showCalendarDates: ShowTopCalendarTypeList;
  setShowSelectedDate: Function;
  getEmailTemplateRequest: Function;
  clearSelectedJobListing: Function;
  selectedShowDate: string;
  selectedWorkingDayId: string;
  recallRequest: boolean;
}
const CastingDirectorLanding: FunctionComponent<CastingDirectorLandingProps> = (props) => {
  const {
    selcetedHomeTab,
    selectedShow,
    setShowSelectedDate,
    showCalendarDates,
    getEmailTemplateRequest,
    clearSelectedJobListing,
    selectedShowDate,
    selectedWorkingDayId,
    recallRequest,
  } = props;

  useEffect(() => {
    getEmailTemplateRequest({});
  }, []);

  const loadComponent = () => {
    switch (parseInt(selcetedHomeTab, 10)) {
      case 0:
        return <Matrix />;
      case 1:
        return (
          <div className={`directorDateCalendar ${LandingStyle.landingRightSideInner}`}>
            <div className="topBarCalendarWrapper">
              <TopBarCalendar
                selectedWorkingDayId={selectedWorkingDayId}
                selectedShowDate={selectedShowDate}
                showCalendarDates={showCalendarDates}
                selectedShow={selectedShow}
                setShowDate={setShowSelectedDate}
                clearSelectedJobListing={clearSelectedJobListing}
                recallRequest={recallRequest}
              />
              <ShowRoleContainer />
            </div>
          </div>
        );
      case 2:
        return (
          <div className={`${LandingStyle.landingRightSidePerformer}`}>
            <FindPerformerContainer cssClass="DirectorSortingWrapper" />
            <PerformerSearchResultContainer />
          </div>
        );
      case 3:
        return <p>Tools</p>;
      default:
        return <Matrix />;
    }
  };

  return (
    <>
      <TopNavBar />
      <MainContainer cssClass={LandingStyle.paddingTop30}>
        <ShowTopNavigation cssClass="" />
        <div className={`paddingTop30 pt-zero ${LandingStyle.landingMainLayout}`}>
          <div className={`${LandingStyle.ladingLeftSide}`}>
            <ShowLeftNavigation />
            {selcetedHomeTab !== '0' && <MyPerformerListContainer />}
          </div>
          <div className={`ml-0 ml-md-3 ml-lg-3 ml-xl-3 ${LandingStyle.landingRightSide}`}>{loadComponent()}</div>
        </div>
      </MainContainer>
    </>
  );
};

export default CastingDirectorLanding;
