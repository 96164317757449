import React, { FunctionComponent, useState } from 'react';
import closeSvg from 'assets/svg/close.svg';
import saveSvg from 'assets/svg/save-green.svg';
import sortSvg from 'assets/svg/sort.svg';
import Style from 'components/castingDirector/MatrixItem/MatrixItem.module.css';

interface MatrixItemTitleProps {
  title: string;
  handleUpdateTitle: Function;
}

const MatrixItemTitle: FunctionComponent<MatrixItemTitleProps> = (props: MatrixItemTitleProps) => {
  const { title, handleUpdateTitle } = props;
  const [name, setName] = useState<string>(title);
  const handleTitleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };

  const handleSave = () => {
    handleUpdateTitle(name);
  };

  const handleCancel = () => {
    handleUpdateTitle('');
  };

  return (
    <>
      <div className={`d-flex justify-content-between align-items-center ${Style.editableMatrixInput}`}>
        <img src={sortSvg} alt="sort icon" className={`mr-3 ${Style.sortImage}`} />
        <input type="text" className={Style.matrixItemTitle} value={name} onChange={handleTitleChange} />
      </div>
      <div className={Style.actionsWrapper}>
        <div className="d-flex justify-content-between align-items-center">
          <div onClick={handleCancel} onKeyUp={handleCancel} role="button" tabIndex={0}>
            <img src={closeSvg} className={Style.closeIcon} alt="" />
          </div>
          <div onClick={handleSave} onKeyUp={handleSave} role="button" tabIndex={0}>
            <img src={saveSvg} alt="" className={Style.saveIcon} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MatrixItemTitle;
