import React, { FunctionComponent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import Style from 'components/castingDirector/BasicInfo/BasicInfo.module.css';
import ProfileInfo from 'components/PerformerProfile/ProfileInfo';
import { BookingDate, User } from 'modules/user/types';
import Button from 'components/controls/button/button';
import sizeCardSvg from 'assets/svg/size-card.svg';
import { formatHeight, formatInUnit, getAge, getCalendarDates, getUnionClass, getUnionValue } from 'helpers/utils';
import ProfileCalender from 'components/ProfileCalender/ProfileCalender';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import DatePopup from 'components/PerformerProfile/DatePopup/DatePopup';
import ButtonSection from 'components/PerformerProfile/ButtonSection';
import { useSelector } from 'react-redux';
import { getUser } from 'store/selector/auth.selector';
import Loader from 'components/controls/Loader/Loader';
import { UNIT_FORMAT } from 'store/castingPax.constants';
import Modal from 'components/controls/Modal/Modal';
import SizeCardView from 'views/bgperformer/profile/SizeCard/SizeCardView';
import PhoneInputFormat from 'components/phoneInput/phoneInputFormat';

interface BasicInfoProps {
  profile: User;
}
const BasicInfo: FunctionComponent<BasicInfoProps> = (props: BasicInfoProps) => {
  const { profile } = props;
  const [isSizeCard, setSizeCard] = useState<boolean>(false);
  const calendarDates = getCalendarDates(
    profile.bgPerformer?.bookingDates || [],
    profile.bgPerformer?.calendar || [],
    profile.job,
  );
  const dateComponent = () => <DatePopup dates={calendarDates} startKey={11} userId={profile.id} />;
  const sizeCardHandel = () => setSizeCard(true);
  const handleSizeCard = () => setSizeCard(false);
  const getSizeCard = () => <SizeCardView user={profile.bgPerformer} handleClose={handleSizeCard} />;

  const user = useSelector(getUser);
  return (
    <div className="d-flex align-items-center justify-content-start">
      <div className={`${Style.imageWrapper}`}>
        <img src={profile.profilePicUrl} alt="" className={Style.profileImage} />
      </div>
      <div className={`${Style.infoWrapper}`}>
        <div className="d-flex align-items-center justify-content-between mb-30">
          <div className="d-flex align-items-center">
            {profile.firstName ? (
              <p className={`mb-0 ${Style.profileName}`}>{`${profile.firstName} ${profile.lastName}`}</p>
            ) : (
              <Loader isSmallView />
            )}
            <div className={Style.performerId}>
              <p className="mb-0 mt-0">
                <span className={`${Style.afterTitle} ${getUnionClass(profile)}`}>{getUnionValue(profile)}</span>
              </p>
            </div>
          </div>

          {user.role === 'DIRECTOR' && (
            <ButtonSection performer={profile} showMessageSection={false} calendarDates={calendarDates} />
          )}
        </div>
        <div className={`position-relative ${Style.profileCard}`}>
          <div className={`row mb-2 pt-3 ${Style.profileCardInner}`}>
            <div className="col-sm-12 pt-2">
              <div className={Style.profileInfoWrapper}>
                <div className={Style.profileInfoInner}>
                  <ProfileInfo label="Gender" value={profile.bgPerformer?.gender} />
                </div>
                <div className={Style.profileInfoInner}>
                  <ProfileInfo label="Age" value={getAge(profile.bgPerformer?.dateOfBirth)} />
                </div>
                <div className={Style.profileInfoInner}>
                  <ProfileInfo label="Weight" value={formatInUnit(profile.bgPerformer?.weight, UNIT_FORMAT.LBS)} />
                </div>
                <div className={Style.profileInfoInner}>
                  <ProfileInfo label="Height" value={formatHeight(profile.bgPerformer?.height || '')} />
                </div>
                <div className={Style.profileInfoInner}>
                  <ProfileInfo label="City" value={profile?.address} />
                </div>
                <div className={Style.profileInfoInner} title={profile.phoneNo}>
                  <PhoneInputFormat label="Phone" value={profile.phoneNo} />
                </div>
                <div className={Style.profileInfoInner} title={profile.email}>
                  <ProfileInfo label="Email" value={profile.email} />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end px-3">
            <Button
              cssClass="sizeCard-btn d-flex align-items-center justify-content-center"
              label="Size card"
              icon={sizeCardSvg}
              type="primary"
              clickHandler={sizeCardHandel}
            />
          </div>
          <div className={`mt-3 ${Style.profileCardFooter}`}>
            <div className="d-flex justify-content-between">
              {calendarDates.map((date: BookingDate, index: number) => {
                if (index < 12) return <ProfileCalender userId={profile.id} key={date.id} date={date} />;
                return '';
              })}
              <PopoverContainer itemComponent={dateComponent}>
                <div className={Style.calenderDetailBox}>
                  <FontAwesomeIcon icon={faEllipsisH} />
                </div>
              </PopoverContainer>
            </div>
          </div>
        </div>
      </div>
      {isSizeCard && <Modal title="Size Card" body={getSizeCard} closeModal={handleSizeCard} />}
    </div>
  );
};

export default BasicInfo;
