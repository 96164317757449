import { fromJS } from 'immutable';
import { SkillCategory, Notification } from 'modules/general/general.type';
import ACTIONS from 'store/castingPax.constants';

const initialState = fromJS({
  searchUserList: [],
  dropdownOptions: [],
  locations: [],
  unionStatus: [],
  loading: false,
  error: '',
  toastList: [],
  skills: null,
  notifications: [],
  isNewNotification: false,
  showMessageBox: null,
  screenWidth: null,
  paths: [],
  roles: [],
  rental: [],
});

export default function generalReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.GENERAL_SETTING.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.GENERAL_SETTING.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', '')
        .set('dropdownOptions', action.data.defaultTypes)
        .set('locations', action.data.location)
        .set('skills', action.data.skills)
        .set('paths', action.data.paths)
        .set('unionStatus', action.data.unionStatus)
        .set('roles', action.data.roles)
        .set('rates', action.data.rates)
        .set('rental', action.data.rental);
    }
    case ACTIONS.GENERAL_SETTING.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.NOTIFICATIONS.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.NOTIFICATIONS.SUCCESS: {
      return state.set('loading', false).set('error', '').set('notifications', action.data);
    }
    case ACTIONS.NOTIFICATIONS.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.ADD_TOAST_IN_LIST: {
      return state.set('toastList', action.data);
    }
    case ACTIONS.REMOVE_TOAST_IN_LIST: {
      return state.set('toastList', null);
    }
    case ACTIONS.ADD_NEW_NOTIFICATION: {
      const prev = state.get('notifications') || [];
      return state.set('notifications', [action.data, ...prev]).set('isNewNotification', true);
    }
    case ACTIONS.RESET_NEW_NOTIFICATION_ICON: {
      return state.set('isNewNotification', false);
    }
    case ACTIONS.SHOW_MESSAGE_BOX: {
      return state.set('showMessageBox', action.data);
    }

    case ACTIONS.CLEAR_SHOW_MESSAGE_BOX: {
      return state.set('showMessageBox', null);
    }
    case ACTIONS.SET_ALL_SKILLS_FALSE: {
      const skills = state.get('skills') || [];
      skills.map((s: SkillCategory) => {
        const obj = s;
        obj.isChecked = false;
        if (s.subCategories) {
          s.subCategories.forEach((subskill: SkillCategory) => {
            const objSkill = subskill;
            objSkill.isChecked = false;
          });
        }
        return obj;
      });
      return state.set('skills', [...skills]);
    }

    case ACTIONS.SET_NOTIFICATION_FOR_MARK_READ_LOCALLY: {
      const prev = state.get('notifications') || [];
      const index = prev.findIndex((x: Notification) => x.id === action.data);
      if (index !== -1) {
        const noti: Notification = prev[index];
        noti.isSeen = true;
        prev.splice(index, 1, noti);
        return state.set('notifications', [...prev]);
      }
      return state;
    }

    case ACTIONS.SET_SCREEN_WIDTH: {
      return state.set('screenWidth', action.data);
    }

    default: {
      return state;
    }
  }
}
