import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
// import RateTab from './RateTab';
import styled from 'styled-components';
import { Show, RateList } from 'modules/Show/show.types';
import { getRateListRequest } from 'store/actions/show.actions';
import { getPerformerRateList } from 'store/selector/show.selector';
import FilterTab from '../../filter-tab/FilterTab';
import SearchTab from '../filter-tab/SearchTab';
import MenuTab from '../filter-tab/MenuTab';
import TabContainer from '../../common/FilterTabContainer';
// import DropMenu from './dropMenu.component';
// import Modal from '../../../../../src/components/controls/Modal/'

interface Props {
  // width?: number;
  getRateList: Function;
  rateList: RateList;
  reOrder: Function;
  handleSearch: Function;
  handleRateFilter: Function;
  handleErrorNotesFilter: Function;
  handleHideFilter: Function;
}

const dummyData3 = [
  {
    id: '1',
    label: 'Error',
    value: 'Error',
  },
  {
    id: '2',
    label: 'Notes',
    value: 'Notes',
  },
];

const dummyData2 = [
  {
    id: '1',
    label: 'Hide Completed',
    value: 'Hide Completed',
  },
];

const FilterListWrapper: React.FC<Props> = (props: Props) => {
  const {
    getRateList,
    rateList = [],
    reOrder,
    handleSearch,
    handleRateFilter,
    handleErrorNotesFilter,
    handleHideFilter,
  } = props;
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const arr: any = [];
  const toggleCheck = () => setIsChecked(!isChecked);
  useMemo(() => {
    rateList.map((item) => {
      const obj = {
        id: item.id,
        label: item.title,
        value: item.rate,
      };
      arr.push(obj);
      return arr;
    });
  }, [arr]);
  useEffect(() => {
    getRateList();
  }, []);
  return (
    <TabContainer>
      <SortByContainer>
        <SortByLabel>Sort By</SortByLabel>
      </SortByContainer>
      <FilterComponentContainer>
        <FilterTab
          extraText="Rate (Union Type)"
          placeholderText="Rate (Union Type)"
          borderRight="#e0e0e07f"
          style={{ paddingRight: '10px' }}
          data={arr}
          checked
          toggleCheck={() => {}}
          handleFilter={handleRateFilter}
        />
      </FilterComponentContainer>
      <FilterComponentContainer>
        <FilterTab
          label="Hide Completed"
          lightText
          borderRight="#e0e0e07f"
          style={{ padding: '0 10px' }}
          data={dummyData2}
          checked={isChecked}
          toggleCheck={toggleCheck}
          enableCheck
          handleFilter={handleHideFilter}
        />
      </FilterComponentContainer>
      <FilterComponentContainer>
        <FilterTab
          label="Error Notes"
          lightText
          borderRight="#e0e0e07f"
          style={{ padding: '0 10px' }}
          data={dummyData3}
          checked
          toggleCheck={() => {}}
          height={15}
          // width={30}
          handleFilter={handleErrorNotesFilter}
        />
      </FilterComponentContainer>
      <SearchComponentContainer>
        <SearchTab placeholderText="Search" handleSearch={handleSearch} />
      </SearchComponentContainer>
      <FilterComponentContainer>
        <MenuTab borderRadiusRight reOrder={reOrder} />
      </FilterComponentContainer>
    </TabContainer>
  );
};

export const FilterComponentContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
const SearchComponentContainer = styled(FilterComponentContainer)`
  flex: 1;
  border-right: 1px solid #e0e0e07f;
`;
const SortByContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SortByLabel = styled.p`
  margin: 0;
  padding-left: 20px;
  padding-right: 15px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  color: #4b4d67;
  line-height: 24.38px;
`;

const mapStateToProps = (state: Show) => {
  const rateList = getPerformerRateList(state);
  return {
    rateList,
  };
};

const mapDispatchToProps = {
  getRateList: getRateListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterListWrapper);
