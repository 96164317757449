import styled from 'styled-components';

const StyledPendingReview = styled.div`
  height: 30px;
  border-radius: 8px;
  padding: 0 9px;
  display: flex;
  align-items: center;
  gap: 8.5px;
  background: rgba(242, 153, 74, 0.1);
  cursor: pointer;
  p {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.orange};
    text-align: center;
    margin-bottom: 0;
  }
`;

export default StyledPendingReview;
