import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import routes from 'routes/index';
import privateRoutes from 'routes/private.index';
import AuthRoute from 'routes/AuthRoute';
import PublicRoute from 'routes/PublicRoute';
import NotFound from 'components/notFound/NotFound';
import 'App.css';
import './assets/css/all.min.css';
import './assets/css/normalize.css';
import Pusher from 'pusher-js';
import { URL_PUSHER_AUTH } from 'store/services/URL';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, pusherRefresh } from 'store/selector/auth.selector';
import {
  NotificationCloseJob,
  NotificationDeleteJob,
  NotificationDirectorOnNewRequest,
  NotificationGeneral,
  NotificationJobAccept,
  NotificationJobAcceptByDirector,
  NotificationJobCancel,
  NotificationJobReject,
  NotificationJobRemove,
  NotificationNewJob,
  NotificationNewJobRequest,
  NotificationNewKey,
  NotificationNewShow,
  NotificationShowAccess,
  NotificationShowAccessAccepted,
  NotificationShowAddUser,
  ROLE_DIRECTOR,
  ROLE_PERFORMER,
  ROLE_PRODUCTION,
  SHOW_FROM_NOTIFICATION,
} from 'store/castingPax.constants';
import {
  addNewCastingRequest,
  addNewJobCall,
  addPerformerInMatrixData,
  bookingRequestBegin,
  calendarDatesBegin,
  jobCloseNotfication,
  jobDeleteNotification,
  jobUpdateNotification,
  removeJobFromBooking,
} from 'store/actions/job.actions';
import { BookingRequest } from 'modules/jobs/types';
import Loader from 'components/controls/Loader/Loader';
import { ShowDayJob } from 'modules/Show/show.types';
import { addNotificationInList, screenSizeChange } from 'store/actions/general.actions';
import MessageBox from 'components/controls/messageBox/MessageBox';
import FullImageView from 'components/controls/imageViewer/FullImageView';
import { ToastContainer } from 'react-toastify';
import PusherContext from 'pusherContext';
import 'react-toastify/dist/ReactToastify.css';
import { matrixRedirect, openShowInNewTab, performerDetailRedirect } from 'helpers/utils';
import { setMatrixRedirected, setShowRedirect, showDetailBegin } from 'store/actions/show.actions';
import { setSelectedTab } from 'store/actions/auth.actions';
import { fetchPeformerUpdatedData, performerDetailBegin } from 'store/actions/myPerformer.actions';
import MessageToast from 'components/controls/Toast/MessageToast';
import 'landingAssets/css/animate.css';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';
import { User } from 'modules/user/types';

function App() {
  document.title = 'Casting pax';

  const isRefreshPusher = useSelector(pusherRefresh);
  const location = useLocation();
  const dispatch = useDispatch();
  const [pusherChannel, setPusherChannel] = useState<any>(null);
  const [pusherObj, setPusherObj] = useState<any>(null);
  const user = useSelector(getUser);
  useEffect(() => {
    const redirectFrom = matrixRedirect.get();
    const performerDetail = performerDetailRedirect.get();
    const redirectFromShow = openShowInNewTab.get();

    if (redirectFrom) {
      dispatch(setMatrixRedirected(redirectFrom));
      dispatch(setSelectedTab('0'));
      matrixRedirect.clear();
    }
    if (performerDetail) {
      dispatch(performerDetailBegin({ userId: performerDetail.id }));
      performerDetailRedirect.clear();
    }
    if (redirectFromShow) {
      dispatch(setShowRedirect(redirectFromShow));
      openShowInNewTab.clear();
    }
    const handleWindowResize = () => dispatch(screenSizeChange(window.innerWidth));
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    pusherUpdate();

    return () => {
      if (pusherObj) {
        pusherObj.unsubscribe('private-jobs');
        pusherObj.unsubscribe(`private-user-${user.id}`);
      }
      window.removeEventListener('resize', () => {});
    };
  }, []);

  useEffect(() => {
    if (isRefreshPusher) {
      pusherUpdate();
    }
  }, [isRefreshPusher]);

  const isLocationMatch = (locationId: string) => {
    const userData: User = getItemFromLocalStorage('user');
    const voucherInfo = userData?.voucher;
    if (!voucherInfo) {
      return true;
    }
    if (voucherInfo?.location?.id === locationId) {
      return true;
    }
    return false;
  };

  // console.log('voucherInfo333', voucherInfo);

  const pusherUpdate = () => {
    if (!user || !user.id) {
      return;
    }
    const channelName = `private-user-${user.id}`;
    const pusher = new Pusher('6da79a34cd3496fbe727', {
      cluster: 'us3',
      authEndpoint: URL_PUSHER_AUTH,
      auth: {
        headers: {
          'X-Auth-Token': localStorage.getItem('accessToken'),
        },
        params: {
          channel: channelName,
        },
      },
    });
    const channel = pusher.subscribe(channelName);
    setPusherChannel(channel);
    setPusherObj(pusher);

    channel.bind('message', (obj: any) => {
      if (obj.notiData) {
        if (obj.notiData.userId !== user.id) return;
      }
      if (user.role === ROLE_PERFORMER) {
        if (obj.type === NotificationNewJobRequest) {
          const newBooking: BookingRequest = { ...obj.Data };
          dispatch(addNewCastingRequest(newBooking));
          if (obj.notiData) {
            dispatch(addNotificationInList(obj.notiData));
          }
        } else if (obj.type === NotificationNewKey) {
          const { notiData } = obj;
          const newBooking: BookingRequest = { ...notiData.data };
          dispatch(jobUpdateNotification(newBooking));
          if (notiData) {
            dispatch(addNotificationInList(notiData));
          }
        } else if (obj.type === NotificationDeleteJob) {
          const { notiData } = obj;
          dispatch(jobDeleteNotification(notiData.data.jobId));
          if (notiData) {
            dispatch(addNotificationInList(notiData));
          }
        } else if (obj.type === NotificationCloseJob) {
          const { notiData } = obj;
          const newBooking: BookingRequest = { ...notiData.data };
          dispatch(jobCloseNotfication(newBooking));
          if (notiData) {
            dispatch(addNotificationInList(notiData));
          }
        } else if (obj.type === NotificationJobRemove) {
          dispatch(addNotificationInList(obj.notiData));
          dispatch(removeJobFromBooking(obj.Data));
        } else if (obj.type === NotificationJobAcceptByDirector) {
          dispatch(addNotificationInList(obj.Data));
          dispatch(
            bookingRequestBegin({
              status: ['pending', 'booked', 'requested'],
            }),
          );
          dispatch(calendarDatesBegin());
        }
      }
      if (user.role === ROLE_DIRECTOR) {
        if (
          obj.type === NotificationJobAccept ||
          obj.type === NotificationJobCancel ||
          obj.type === NotificationJobReject ||
          obj.type === NotificationDirectorOnNewRequest ||
          obj.type === NotificationShowAccess
        ) {
          dispatch(addNotificationInList(obj.Data));
        }
        if (
          obj.type === NotificationJobAccept ||
          obj.type === NotificationJobCancel ||
          obj.type === NotificationJobReject
        ) {
          const performerId: string =
            typeof obj.Data.data.performerId === 'object' ? obj.Data.data.performerId.id : obj.Data.data.performerId;
          dispatch(
            fetchPeformerUpdatedData({
              performerId,
              jobId: obj.Data.data.jobId.id,
              page: 1,
              limit: 1,
            }),
          );
          dispatch(
            addPerformerInMatrixData({
              performerId,
              workingDayIds: obj.Data.data.jobId.workingDayId,
              jobId: obj.Data.data.jobId.id,
              status: obj.Data.data.status,
              deleteDayIds: obj.deleteDayIds || [],
            }),
          );
        }
        if (obj.type === NotificationDirectorOnNewRequest) {
          dispatch(
            fetchPeformerUpdatedData({
              performerId: obj.Data.data.performerId,
              jobId: obj.Data.data.jobId.id,
              page: 1,
              limit: 1,
            }),
          );
        }
      }
      if (user.role === ROLE_DIRECTOR || user.role === ROLE_PRODUCTION) {
        if (
          obj.type === NotificationShowAccessAccepted ||
          obj.type === NotificationShowAddUser ||
          obj.type === NotificationNewShow
        ) {
          dispatch(addNotificationInList(obj.Data));
          dispatch(showDetailBegin({ showId: obj.Data.data.id }, SHOW_FROM_NOTIFICATION));
        }
      }
    });

    const channelJobs = 'private-jobs';
    const pusherJob = new Pusher('6da79a34cd3496fbe727', {
      cluster: 'us3',
      authEndpoint: URL_PUSHER_AUTH,
      auth: {
        headers: {
          'X-Auth-Token': localStorage.getItem('accessToken'),
        },
        params: {
          channel: channelJobs,
        },
      },
    });
    const channelJob = pusherJob.subscribe(channelJobs);

    if (user.role === 'PERFORMER') {
      channelJob.bind('message', (obj: any) => {
        if (obj.type === NotificationGeneral) {
          const notiData = obj.data;
          Object.assign(notiData, { isSeen: false, type: 'General' });
          dispatch(addNotificationInList(notiData));
        }
        if (obj.type === NotificationNewJob) {
          const newBooking: ShowDayJob = { ...obj.job };
          if (isLocationMatch(newBooking.location.id)) {
            dispatch(addNewJobCall(newBooking));
          }
        }
      });
    } else if (user.role === 'DIRECTOR') {
      channelJob.bind('notification', (obj: any) => {
        const notiData = obj.data;
        if (obj.type === NotificationGeneral) {
          Object.assign(notiData, { isSeen: false, type: 'General' });
        }
        dispatch(addNotificationInList(notiData));
      });
    }
  };

  const tiggerPusherMessage = (message: any) => {
    pusherChannel.trigger('client-matrix', message);
  };

  const unSubscribeChannel = () => {
    if (pusherObj) {
      pusherObj.unsubscribe('private-jobs');
      pusherObj.unsubscribe(`private-user-${user.id}`);
    }
  };

  return (
    <div className="App">
      <ToastContainer />
      <MessageToast />
      <Loader />
      <FullImageView />
      <MessageBox />
      <PusherContext.Provider value={{ tiggerPusherMessage, unSubscribeChannel }}>
        <div>
          <Switch>
            {routes &&
              routes.map((route, key) => <PublicRoute key={key} path={route.path} component={route.component} />)}
            {privateRoutes &&
              privateRoutes.map((route, key) => (
                <AuthRoute
                  key={key}
                  path={route.path}
                  url={location.pathname}
                  component={route.component}
                  routeRole={route.role}
                />
              ))}
            <Redirect exact from="/" to="/Home" />
            <Route component={NotFound} />
          </Switch>
        </div>
      </PusherContext.Provider>
    </div>
  );
}

export default App;
