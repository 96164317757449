import Button from 'components/controls/button/button';
import React, { FunctionComponent } from 'react';

interface IModalBodyProps {
  isShow: Function;
  handleClick: Function;
  description?: string;
}
const ConfirmationModalBody: FunctionComponent<IModalBodyProps> = (props) => {
  const { isShow, handleClick, description } = props;
  const clickHandler = (text: string) => {
    if (text === 'save') handleClick(true);
    else if (text === 'cancel') handleClick(false);
    isShow(false);
  };
  return (
    <>
      {description}
      <Button type="customSaveBtn" label="Ok" clickHandler={() => clickHandler('save')} />
      &ensp;
      <Button type="customCancelBtn" label="Cancel" clickHandler={() => clickHandler('cancel')} />
    </>
  );
};

export default ConfirmationModalBody;
