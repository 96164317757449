import React, { FunctionComponent, useEffect, useState } from 'react';
import Style from 'components/castingDirector/PopOvers/CoordinatorDetail/Coordinator.module.css';
import searchSvg from 'assets/svg/search.svg';
import { User, UserList } from 'modules/user/types';
import { connect } from 'react-redux';
import { getShowUsers } from 'store/selector/show.selector';
import { Show } from 'modules/Show/show.types';
import UserView from 'components/castingDirector/PopOvers/Share/UserView';
import shareSvg from 'assets/svg/share.svg';

interface MatrixCoordinatorDetailProps {
  workingDayId: string;
  users?: UserList;
}

const MatrixCoordinatorDetail: FunctionComponent<MatrixCoordinatorDetailProps> = (
  props: MatrixCoordinatorDetailProps,
) => {
  const { workingDayId, users } = props;
  const [searchText, setSearchText] = useState<string>('');
  const [list, setList] = useState<UserList>(users || []);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useEffect(() => {
    if (!users || searchText === '') {
      setList(users || []);
      return;
    }
    const result = users.filter(
      (u: User) =>
        u.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
        u.firstName.toLowerCase().includes(searchText.toLowerCase()),
    );
    setList(result);
  }, [searchText]);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
  };
  const handleClick = (isAdded: boolean, user: User) => {
    if (isAdded) {
      setSelectedIds([...selectedIds, user.id]);
      return;
    }
    const ids = selectedIds;
    ids.splice(
      ids.findIndex((x: string) => x === user.id),
      1,
    );
    setSelectedIds([...ids]);
  };
  console.log('WorkingDayId', workingDayId);

  return (
    <div className={Style.mainWrapper}>
      <div className={Style.searchSection}>
        <img src={searchSvg} alt="" className={Style.searchIcon} />
        <input type="text" placeholder="Search" onChange={handleChange} />
        {selectedIds.length > 0 && <img src={shareSvg} alt="" />}
      </div>
      {list &&
        list.length > 0 &&
        list.map((item) => <UserView key={item.id} item={item} isAdded={false} handleClick={handleClick} />)}
    </div>
  );
};

const mapStatToProps = (state: Show) => {
  const users = getShowUsers(state);
  return {
    users,
  };
};

export default connect(mapStatToProps, null)(MatrixCoordinatorDetail);
