import React, { FunctionComponent } from 'react';
import Style from 'components/bgPerformer/Update/Update.module.css';
import exportSvg from 'assets/svg/export.svg';
import { BookingRequest } from 'modules/jobs/types';
import { useDispatch } from 'react-redux';
import { markedJobUpdateStatusBegin, openJobDetails } from 'store/actions/job.actions';
import PopoverStickOnHover from 'components/controls/Popover/PopoverStick';
import { Link } from 'react-router-dom';
import { saveBookingData } from 'helpers/utils';

interface UpdateItemProps {
  news: BookingRequest;
}
const UpdateItem: FunctionComponent<UpdateItemProps> = (props) => {
  const { news } = props;
  const dispatch = useDispatch();

  const fullDetailHandle = () => {
    document.body.click();
    dispatch(openJobDetails(news.jobId, news));
    if (!news.isUpdateRead) {
      dispatch(markedJobUpdateStatusBegin({ id: news.id }));
    }
  };

  const handleLinkClick = (event: any) => {
    event.preventDefault();
  };

  const handleContextClick = () => {
    saveBookingData.save(news);
  };

  return (
    <div className={`d-flex justify-content-between position-relative ${Style.updateItemWrapper}`}>
      <PopoverStickOnHover item={news.jobId} status="update" fullDetailHandle={fullDetailHandle}>
        <div className="position-relative w-100 d-flex align-items-center">
          <Link
            className={`mb-0 text-decoration-none d-block ${Style.castingCallTitle}`}
            to="/performer/home"
            onClick={handleLinkClick}
            onContextMenu={handleContextClick}
          >
            <p className={`mb-0 ${Style.updateTitle}`}>
              {news.jobId.title}
              {!news.isUpdateRead && <div className={`${Style.pendingStatus}`} />}
            </p>
            <span className={`mb-0 ${Style.updateDescription}`}>{news.jobId.remarks}</span>
          </Link>
        </div>
      </PopoverStickOnHover>

      <div onClick={fullDetailHandle} onKeyPress={fullDetailHandle} tabIndex={0} role="button">
        <img src={exportSvg} alt="" />
      </div>
    </div>
  );
};

export default UpdateItem;
