import React from 'react';
import styled from 'styled-components';

const StatusCellRenderer = (props: any) => {
  const { value, valueFormatted } = props;
  const statusCellValue = valueFormatted || value;
  return <StatusCellContainer>{statusCellValue === 'booked' ? <StatusIndicator /> : ''}</StatusCellContainer>;
};

export default StatusCellRenderer;

const StatusIndicator = styled.div`
  position: absolute;
  margin-top: -18%;
  height: 15px;
  width: 15px;
  background-color: #f2994a;
  border-radius: 50%;
  display: flex;
`;

const StatusCellContainer = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;
