import React, { FunctionComponent, useState } from 'react';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import Button from 'components/controls/button/button';
import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';

interface IModalProp {
  handleSave: Function;
  handleClose: Function;
}
const LunchTimeModalBody: FunctionComponent<IModalProp> = (props) => {
  const { handleSave, handleClose } = props;
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [input, setinput] = useState<string>('');

  const handleSaveModal = () => {
    let number: any = input.match(/\d/g);
    number = number.join('');
    const alphabets = input.replace(/[^A-Za-z]/g, '');
    if (alphabets === 'min' || alphabets === 'Min') {
      if (number > 0 && number <= 60) {
        handleSave(input);
        handleClose();
      } else setErrorMessage('Format is InValid');
    }
    if (alphabets === 'hr' || alphabets === 'Hr') {
      if (number >= 1 && number <= 24) {
        handleSave(input);
        handleClose();
      } else setErrorMessage('Format is InValid');
    }
    if (!alphabets) {
      const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])-([0-1]?[0-9]|2[0-4]):([0-5][0-9])?$/.test(input);
      if (isValid) {
        const str: any = input;
        const str1: number = str.substring(0, 5);
        const str2: number = str.substring(6, 12);
        if (str2 > str1) {
          handleSave(input);
          handleClose();
        }
      } else setErrorMessage('Format is InValid');
    }
    setErrorMessage('Format is InValid');
  };

  return (
    <>
      <span>Enter Lunch Time</span>
      <CustomInput type="text" placeholder="Enter Lunch time " handleChange={setinput} />
      <TextErrorMessage message={errorMessage} />
      <div style={{ display: 'flex' }}>
        <Button type="customSaveBtn" clickHandler={() => handleSaveModal()} label="Save" />
        &ensp;
        <Button type="customCancelBtn" clickHandler={() => handleClose()} label="Cancel" />
      </div>
    </>
  );
};

export default LunchTimeModalBody;
