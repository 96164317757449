import React, { FunctionComponent } from 'react';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import Style from 'components/Producer/Matrix/UserManagementItem/ContactInfoItem/ContactInfoItem.module.css';

interface ContactInfoItemProps {
  label: string;
  icon: string;
  popopOver?: Function;
  cssClass?: string;
}
const ContactInfoItem: FunctionComponent<ContactInfoItemProps> = (props: ContactInfoItemProps) => {
  const { label, icon, popopOver, cssClass } = props;
  const layout = (
    <div className="d-flex align-items-center">
      <img src={icon} alt="" className={`${Style.icon} ${cssClass}`} />
      <p className={`mb-0 ${Style.description}`}>{label}</p>
      {popopOver && <img src={downArrowSvg} alt="" className={Style.arrowSvg} />}
    </div>
  );

  if (popopOver) return <PopoverContainer itemComponent={popopOver}>{layout}</PopoverContainer>;

  return <>{layout}</>;
};

export default ContactInfoItem;
