import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Style from 'components/SearchUser/SearchUser.module.css';
import { debounce } from 'lodash';
import { UserList, User } from 'modules/user/types';
import userApi from 'store/services/user.services';
import { checkEmail } from 'utils/FormValidation';
import UserView from 'components/UserView/UserView';
import Button from 'components/controls/button/button';
import ApiResponse from 'modules/response/response.types';
import { Overlay, Tooltip } from 'react-bootstrap';
import Loader from 'components/controls/Loader/Loader';
import { ROLE_DIRECTOR_ID, ROLE_PRODUCTION_ID, ROLE_PAYROLL_ID } from 'store/castingPax.constants';

interface SearchUserProps {
  label: string;
  addUserInList: Function;
  prevList: UserList;
  handleInvitation?: Function;
}

const SearchUser: FunctionComponent<SearchUserProps> = (props) => {
  const { label, addUserInList, prevList, handleInvitation } = props;
  const inititalList: UserList = [];
  const [searchResult, setSearchResult] = useState(inititalList);
  const [searchText, setSearchText] = useState('');
  const target = useRef(null);
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const ref: any = useRef();

  const useOnScreen = (reference: any) => {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
    useEffect(() => {
      observer.observe(reference.current);
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect();
      };
    }, []);
    return isIntersecting;
  };

  const isVisible = useOnScreen(ref);

  const searchName = debounce((text: string) => fetchResult(text), 1000);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    searchName(event.currentTarget.value);
  };

  const handleAddUser = (event: React.FormEvent<HTMLSpanElement>, user: User) => {
    const index = prevList.findIndex((u: User) => u.id === user.id);
    if (index === -1) {
      addUserInList([...prevList, user]);
    }
  };

  const handleClose = () => {
    setSearchText('');
    setSearchResult([]);
  };

  const fetchResult = (text: string) => {
    setShow(false);
    setSearchText(text);
    if (text.length === 0) {
      setSearchResult([]);
      setShow(false);
      return;
    }
    setIsLoading(true);
    userApi
      .searchShowUser({ searchText: text })
      .then((res: ApiResponse) => {
        setSearchResult(res.data);
        if (res.data.length > 0) setShow(true);
        if (res.data.length === 0 && checkEmail(text).length === 0) setShow(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const showSearchResult = () => {
    if (searchText.length > 0 && searchResult.length === 0) {
      return (
        <div className={`${Style.searchWrapper} ${Style.searchResultWrapper}`}>
          <div onClick={handleClose} onKeyPress={handleClose} role="button" tabIndex={0} />
          <p className="text-center">No user find</p>
          {checkEmail(searchText).length === 0 && handleInvitation && (
            <div>
              <p className={Style.inviteText}>Invite as</p>
              <div className="d-flex column-gap-20 justify-content-center">
                <Button
                  cssClass={Style.directorBtnWidth}
                  label="Director"
                  clickHandler={() => {
                    handleInvitation(searchText, ROLE_DIRECTOR_ID);
                    setSearchText('');
                  }}
                />
                <Button
                  cssClass={Style.directorBtnWidth}
                  label="Producer"
                  clickHandler={() => {
                    handleInvitation(searchText, ROLE_PRODUCTION_ID);
                    setSearchText('');
                  }}
                />
                <Button
                  cssClass={Style.directorBtnWidth}
                  label="Payroll"
                  clickHandler={() => {
                    handleInvitation(searchText, ROLE_PAYROLL_ID);
                    setSearchText('');
                  }}
                />
              </div>
            </div>
          )}
        </div>
      );
    }
    if (searchResult.length > 0) {
      return (
        <div className={`customScrollBar ${Style.searchWrapper} ${Style.searchResultWrapper}`}>
          <div onClick={handleClose} onKeyPress={handleClose} role="button" tabIndex={0} />
          <div className="d-flex flex-wrap">
            {searchResult.map((user) => (
              <UserView
                key={user.id}
                user={user}
                icon="ADD"
                handleClick={(e: React.FormEvent<HTMLSpanElement>) => {
                  handleAddUser(e, user);
                }}
              />
            ))}
          </div>
        </div>
      );
    }

    return '';
  };

  const handleHide = (e: any) => {
    if (e.pointerId) setShow(false);
  };
  return (
    <div ref={ref}>
      <input
        type="text"
        ref={target}
        className="dashboardInput"
        placeholder={label}
        onChange={(e) => handleChange(e)}
      />
      {isLoading && <Loader isSmallView />}
      {isVisible && (
        <Overlay rootClose target={target.current} onHide={handleHide} show={show} placement="bottom-start">
          {(propss) => (
            <Tooltip id="overlay-example" {...propss}>
              {showSearchResult()}
            </Tooltip>
          )}
        </Overlay>
      )}
    </div>
  );
};

export default SearchUser;
