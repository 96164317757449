import { fromJS } from 'immutable';
import ACTIONS from 'store/castingPax.constants';

const initialState = fromJS({
  loading: false,
  performers: [],
});

export default function voucherReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.UPDATE_VOUCHER_STATUS.PENDING: {
      return state.set('loading', true).set('performers', []).set('error', '');
    }
    case ACTIONS.UPDATE_VOUCHER_STATUS.SUCCESS: {
      return state.set('loading', true).set('error', '').set('performers', action.data.data);
    }
    case ACTIONS.UPDATE_VOUCHER_STATUS.ERROR: {
      return state.set('loading', false).set('performers', []).set('error', action);
    }
    default: {
      return state;
    }
  }
}
