import styled from 'styled-components';

const StyledBgCordinator = styled.div`
  height: 30px;
  background: rgba(45, 156, 219, 0.1);
  border-radius: 8px;
  padding: 0 9px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    height: 15px;
    font-size: 14px;
    font-weight: 400;
    width: fit-content;
    text-align: center;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.blue2};
    margin-bottom: 0px;
  }

  /* div {
    width: 13px;
    height: 15px;
  } */

  & > div > * {
    width: 13px;
    height: 15px;
    font-size: 14px;
    display: flex;
    line-height: 15px;
    align-items: center;
    justify-content: center;
  }
`;

export default StyledBgCordinator;
