import React, { FunctionComponent, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PrevArrow from 'components/controls/Slider/NavigationArrow/PrevArrow';
import NextArrow from 'components/controls/Slider/NavigationArrow/RightArrow';
import { ScreenSize } from 'modules/general/general.type';
import { useSelector } from 'react-redux';
import { getScreenWidth } from 'store/selector/general.selector';
import { SCREEN_SIZE_MOBILE } from 'store/castingPax.constants';

interface SliderProps {
  handleChange?: Function;
  slideToShow: number;
  goto?: number;
  sliderSettings?: any;
}
const TopSliderWrapper: FunctionComponent<SliderProps> = (props) => {
  const { children, handleChange, slideToShow, goto, sliderSettings } = props;
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);
  const refSlider = useRef<Slider>(null);
  const dots = false;
  const infinite = false;
  const speed = 500;

  if (refSlider && goto && goto > 1) {
    refSlider.current?.slickGoTo(goto);
  }

  if (currentScreenSize === SCREEN_SIZE_MOBILE) {
    return (
      <>
        <Slider
          ref={refSlider}
          dots={dots}
          infinite={infinite}
          speed={speed}
          slidesToShow={slideToShow}
          slidesToScroll={slideToShow}
          prevArrow={<PrevArrow />}
          nextArrow={<NextArrow />}
          afterChange={() => handleChange}
          responsive={sliderSettings}
        >
          {children}
        </Slider>
      </>
    );
  }
  return (
    <>
      <Slider
        ref={refSlider}
        dots={dots}
        infinite={infinite}
        speed={speed}
        slidesToShow={slideToShow}
        slidesToScroll={slideToShow}
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
        afterChange={() => handleChange}
        responsive={sliderSettings}
      >
        {children}
      </Slider>
    </>
  );
};

export default TopSliderWrapper;
