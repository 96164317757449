import React, { FunctionComponent, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import TabItemHeader from 'components/TopNavBar/Tabs/TabItemHeader/TabItemHeader';
import matrixSvg from 'assets/svg/matrix.svg';
import matrixActiveSvg from 'assets/svg/matrix-green.svg';
import sendSvg from 'assets/svg/send.svg';
import sendActiveSvg from 'assets/svg/send-green.svg';
import searchSvg from 'assets/svg/search.svg';
import searchActiveSvg from 'assets/svg/search-green.svg';
/* import settingSvg from 'assets/svg/setting.svg';
import settingActiveSvg from 'assets/svg/setting-green.svg';
 */
import calendarSvg from 'assets/svg/calendar.svg';
import calendarActiveSvg from 'assets/svg/calendar-active.svg';
import profileSvg from 'assets/svg/profile.svg';
import profileActiveSvg from 'assets/svg/profile-green.svg';
import { User } from 'modules/user/types';
import { ROLE_DIRECTOR } from 'store/castingPax.constants';
import { selectedTabState } from 'helpers/utils';

interface HomeTabsProps {
  user: User;
  defaultTab: string;
  setSelectedTab: Function;
  getSettingRequest: Function;
  setDatesForFitler: Function;
}
const HomeTabs: FunctionComponent<HomeTabsProps> = (props) => {
  const { setSelectedTab, defaultTab, getSettingRequest, user, setDatesForFitler } = props;
  const history = useHistory();
  const location = useLocation();
  const isOnHomePage = () => location.pathname.includes('home');
  const [key, setKey] = useState<string>(defaultTab && isOnHomePage() ? defaultTab : '9');

  useEffect(() => {
    getSettingRequest();
  }, []);

  useEffect(() => {
    if (key !== defaultTab && isOnHomePage()) {
      setKey(defaultTab);
    }
  }, [defaultTab]);

  const handleChange = (newValue?: string) => {
    if (user.role === 'PERFORMER' && location.pathname.toLowerCase() !== '/performer/home') {
      history.push('/performer/home');
    } else if (user.role === 'DIRECTOR' && location.pathname.toLowerCase() !== '/casting/home') {
      history.push('/casting/home');
    }
    let selectedTab = '1';
    setKey(newValue || '1');
    if (!newValue) {
      return;
    }
    switch (newValue) {
      case '0':
        selectedTab = '0';
        break;
      case '1':
        selectedTab = '1';
        break;
      case '2':
        selectedTab = '2';
        break;
      case '3':
        selectedTab = '3';
        break;
      default:
        break;
    }
    if (selectedTab === '2' && user.role === ROLE_DIRECTOR) {
      setDatesForFitler([]);
    }
    selectedTabState.save(selectedTab);
    setSelectedTab(selectedTab);
  };

  const castingDirectorTabs = () => (
    <Tabs activeKey={key} id="casting-pax" onSelect={(k) => handleChange(k ?? '1')}>
      <Tab
        eventKey="0"
        title={<TabItemHeader label="Matrix" path={key === '0' && isOnHomePage() ? matrixActiveSvg : matrixSvg} />}
      />
      <Tab
        eventKey="1"
        title={<TabItemHeader label="Casting Request" path={key === '1' && isOnHomePage() ? sendActiveSvg : sendSvg} />}
      />
      <Tab
        eventKey="2"
        title={
          <TabItemHeader label="Find Performer" path={key === '2' && isOnHomePage() ? searchActiveSvg : searchSvg} />
        }
      />
      {/* <Tab
        eventKey="3"
        title={<TabItemHeader label="Tools" path={key === '3' && isOnHomePage() ? settingActiveSvg : settingSvg} />}
      /> */}
    </Tabs>
  );

  const bgPerformerTabs = () => (
    <Tabs activeKey={key} id="casting-pax" onSelect={(k) => handleChange(k ?? '1')}>
      <Tab
        eventKey="1"
        title={
          <TabItemHeader label="Calendar" path={key === '1' && isOnHomePage() ? calendarActiveSvg : calendarSvg} />
        }
      />
      <Tab
        eventKey="2"
        title={<TabItemHeader label="Submit" path={key === '2' && isOnHomePage() ? calendarActiveSvg : calendarSvg} />}
      />
      <Tab
        eventKey="3"
        title={<TabItemHeader label="Profile" path={key === '3' && isOnHomePage() ? profileActiveSvg : profileSvg} />}
      />
    </Tabs>
  );

  const appTabs = () => {
    const type = user.role;
    switch (type) {
      case 'PERFORMER':
        return bgPerformerTabs();
      case 'DIRECTOR':
        return castingDirectorTabs();
      default:
        return '';
    }
  };

  return <div className="topHomeTabs">{appTabs()}</div>;
};

export default HomeTabs;
