import styled from 'styled-components';

const StyledBottomInfoTab = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  @media only screen and (max-width: 1199px) {
    gap: 7px;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export default StyledBottomInfoTab;
