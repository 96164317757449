import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import Style from 'components/Producer/Matrix/Invoices/DailyCost.module.css';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import BudgetExport from 'components/Producer/Matrix/Budget/Popover/BudgetExport';
import downloadArrowSvg from 'assets/svg/download-arrow.svg';
import arrowDownSvg from 'assets/svg/arrowDown.svg';
import printSvg from 'assets/svg/print.svg';
import DailyCostItem from 'components/Producer/Matrix/Invoices/DailyCostItem';
import { Pagination } from 'modules/jobs/types';
import PaginationComponent from 'components/controls/pagination/Pagination';
import { getDailyInvoices, getDailyInvoicesLoading, getDailyInvoicesPagination } from 'store/selector/show.selector';
import { fetchDailyCostInvoices } from 'store/actions/show.actions';
import { getFormattedCurrentDate } from 'helpers/utils';
import DateRange from 'components/controls/dateRange/dateRange';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import moment from 'moment';
import { DailyInvoiceList } from 'modules/general/general.type';

interface IDailyCostProps {
  dailyInvoices?: DailyInvoiceList;
  pagination?: Pagination;
  fetchDailyCost?: Function;
  loading?: boolean;
  id: string;
  handleDownload: Function;
  handlePrint: (event: React.FormEvent<HTMLDivElement>) => void;
  exportLoading: number;
  loaderType: string;
  printRef: any;
}
const DailyCostInvoices: FunctionComponent<IDailyCostProps> = (props: IDailyCostProps) => {
  const {
    printRef,
    dailyInvoices,
    pagination,
    id,
    fetchDailyCost,
    loading,
    exportLoading,
    loaderType,
    handleDownload,
    handlePrint,
  } = props;
  const [dateRange, setDateRange] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const handlePages = (updatePage: any) => {
    loadData(updatePage.selected + 1);
  };

  const handleDateChange = (start: string, end: string) => {
    setDateRange(`${moment(start).format('DD-MM-YYYY')} to ${moment(end).format('DD-MM-YYYY')}`);
    const invoiceStartDate = moment(start).format('YYYY-MM-DD 00:00:00');
    const invoiceEndDate = moment(end).format('YYYY-MM-DD 00:00:00');
    setStartDate(invoiceStartDate);
    setEndDate(invoiceEndDate);
    if (fetchDailyCost) {
      fetchDailyCost({ page: 1, limit: 10, showId: id, startDate: invoiceStartDate, endDate: invoiceEndDate });
    }
  };

  const loadData = (pageNumber: number) => {
    if (fetchDailyCost) {
      fetchDailyCost({ page: pageNumber, limit: 10, showId: id });
    }
  };

  const handleExportDownload = (exportType: String, type: string) => {
    if (exportType === 'csv') return;
    handleDownload(exportType, type, startDate, endDate);
  };

  const getComponent = (
    <BudgetExport
      data={dailyInvoices || []}
      type="invoice"
      loaderType={loaderType}
      isloading={exportLoading}
      handleClick={handleExportDownload}
    />
  );

  return (
    <>
      <div className={`${Style.buttonsWrapper}`}>
        <div>
          <DateRange
            startDate={getFormattedCurrentDate('MM-DD-YYYY')}
            endDate={getFormattedCurrentDate('MM-DD-YYYY')}
            minDate="01-01-2021"
            handleDateChange={handleDateChange}
          >
            <div className={`d-flex ${Style.dateRangeInput}`}>
              {dateRange && dateRange ? dateRange : ' Date'}
              <img src={downArrowSvg} alt="dropdown-arrow" />
            </div>
          </DateRange>
        </div>
        <div className="mr-2" role="button" onClick={handlePrint} onKeyPress={handlePrint} tabIndex={0}>
          <img src={printSvg} alt="" />
        </div>
        <div className={`mr-2 ${Style.exportButtonWrapper}`}>
          <img src={downloadArrowSvg} alt="" />
          <p className={`mb-0 ${Style.exportTitle}`}>Export</p>
          <PopoverContainer itemComponent={() => getComponent}>
            <img src={arrowDownSvg} alt="" className="ml-3" />
          </PopoverContainer>
        </div>
      </div>
      <div>
        <DailyCostItem print={printRef} loading={loading} items={dailyInvoices || []} />
      </div>
      {pagination && pagination.totalPages > 1 && dailyInvoices && dailyInvoices.length > 0 && (
        <PaginationComponent
          page={pagination.page}
          totalPages={pagination.totalPages > 0 ? pagination.totalPages : 1}
          handlePagination={handlePages}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  const dailyInvoices = getDailyInvoices(state);
  const pagination = getDailyInvoicesPagination(state);
  const loading = getDailyInvoicesLoading(state);
  return {
    dailyInvoices,
    pagination,
    loading,
  };
};
const mapDispatchToProps = {
  fetchDailyCost: fetchDailyCostInvoices,
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyCostInvoices);
