import React, { FunctionComponent, useEffect, useState } from 'react';
import Style from 'containers/bgPerformer/ExpandedSearch/ExpandedSearch.module.css';
import SearchResultItem from 'components/bgPerformer/ExpandedSearch/SearchResultItem/SearchResultItem';
import { ShowJob } from 'modules/Show/show.types';
import PaginationComponent from 'components/controls/pagination/Pagination';
import Search from 'components/bgPerformer/SubmissionSearch/index';
import { SearchJobParams } from 'modules/general/general.type';
import { gettemporarySaveSearchFilter } from 'utils/LocalStorageItems';
import { Pagination } from 'modules/jobs/types';

interface ExpandedSearchProps {
  jobs?: ShowJob[];
  bookingIds?: String[];
  pagination?: Pagination;
}

const ExpandedSearch: FunctionComponent<ExpandedSearchProps> = (props: ExpandedSearchProps) => {
  const { jobs, bookingIds, pagination } = props;
  const [jobFilter, setJobFilter] = useState<SearchJobParams>();
  const [page, setPage] = useState<number>(1);
  const handlePages = (updatePage: any) => {
    setPage(updatePage.selected + 1);
  };

  useEffect(() => {
    setJobFilter(gettemporarySaveSearchFilter());
  }, []);

  const searchId = (id: String) => {
    if (bookingIds) return bookingIds.includes(id);
    return false;
  };

  return (
    <div className="expandedSearchbarWrapper">
      <div className={`${Style.searchWrapper}`}>
        <Search prevFilter={jobFilter || {}} nextPage={page} />
      </div>
      <div className={`${Style.searchResultWrapper}`}>
        {jobs &&
          jobs.length > 0 &&
          jobs.map((job: ShowJob) => <SearchResultItem job={job} isApplied={searchId(job.id)} key={job.id} />)}

        {pagination && pagination.nextPage > pagination.page && (
          <PaginationComponent
            page={pagination.page}
            totalPages={pagination.totalPages > 0 ? pagination.totalPages : 1}
            handlePagination={handlePages}
          />
        )}
      </div>
    </div>
  );
};

export default ExpandedSearch;
