import React, { FunctionComponent, useMemo } from 'react';
import moment from 'moment-timezone';
import SearchProfileContStyle from 'containers/FindPerformerContainer/SearchProfileContainer.module.css';
import SliderWrapper from 'components/controls/Slider/SliderWrapper';
import PerformerCalender from 'components/bgPerformer/Calender/Calendar';
import { JobEventList, JobEvent } from 'modules/jobs/types';
import { BookingDate, BookingDateList } from 'modules/user/types';
import { findPerformerCalendar } from 'components/controls/Slider/SliderResponsiveness';

interface TopCalendarProps {
  jobEvent?: JobEventList;
  cssClass?: string;
}

const TopCalendar: FunctionComponent<TopCalendarProps> = (props: TopCalendarProps) => {
  const { jobEvent, cssClass } = props;

  const calendarDates = useMemo(() => {
    const date = moment();
    const arr: BookingDateList = [];
    let i = 0;

    for (i = 1; i < 31; i += 1) {
      const obj: BookingDate = {
        id: i.toString(),
        date: date.format('DD-MM-YYYY'),
        day: date.format('DD'),
        month: date.format('MMM'),
      };

      if (jobEvent) {
        const index: number = jobEvent.findIndex((x: JobEvent) => x.date.includes(date.format('YYYY-MM-DD')));

        if (index !== -1) {
          Object.assign(obj, { type: jobEvent[index].status, job: jobEvent[index].jobs });
        }
      }

      arr.push(obj);
      date.add(1, 'days');
    }
    return arr;
  }, [jobEvent]);
  return (
    <>
      <div className={`mb-3 justify-content-between hello ${SearchProfileContStyle.searchCalenderWrapper} ${cssClass}`}>
        <SliderWrapper slideToShow={14} sliderSettings={findPerformerCalendar}>
          {calendarDates.map((date: BookingDate) => (
            <PerformerCalender key={date.id} bookingObj={date} />
          ))}
        </SliderWrapper>
      </div>
    </>
  );
};

export default TopCalendar;
