import React from 'react';
import styled from 'styled-components';
import ChartItemSection from './ChartItemSection';
import IShowData from '../interface/showData.interface';

interface Props {
  show?: IShowData;
  maxValue?: number;
  enableTextItem?: boolean;
}

interface IChartContainer {
  budgetExceeded?: boolean;
  height?: number;
  enableTextItem?: boolean;
}

const TEXT_ENABLED_MAX_HEIGHT = 198.06;
const TEXT_DISABLED_MAX_HEIGHT = 249.96;
const ChartItemBox: React.FC<Props> = (props) => {
  const { maxValue, show, enableTextItem } = props;
  const showMaxValue = Math.max(Number(show?.showBudget.budget), Number(show?.showBudget.expense));
  const height = (showMaxValue / maxValue!) * (enableTextItem ? TEXT_ENABLED_MAX_HEIGHT : TEXT_DISABLED_MAX_HEIGHT);
  const isBudgetExceeded = Number(show?.showBudget.expense) > Number(show?.showBudget.budget);
  // const budgetPercent = (Number(show?.showBudget.budget) / showMaxValue) * 100;
  const getItemHeight = () => {
    let itemHeight = 0;
    const budget = Number(show?.showBudget.budget);
    const expense = Number(show?.showBudget.expense);
    if (budget > expense) {
      itemHeight = (expense / budget) * 100;
    } else {
      itemHeight = (budget / expense) * 100;
    }

    return itemHeight;
  };
  return (
    <ChartItemContainer budgetExceeded={isBudgetExceeded} height={height} enableTextItem={enableTextItem}>
      <ChartItemSection heightPercent={getItemHeight()} />
    </ChartItemContainer>
  );
};

export default ChartItemBox;

const ChartItemContainer = styled.div<IChartContainer>`
  position: relative;
  height: ${({ height }) => `${height}px`};
  width: 7.35px;
  border-radius: 14px;
  background: ${({ budgetExceeded }) => (budgetExceeded ? '#EB5757' : '#E0E0E0')};
  width: ${({ enableTextItem }) => (enableTextItem ? '7.35px' : '9.47px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;
