import React, { FunctionComponent, useEffect, useState } from 'react';
import searchSvg from 'assets/svg/search.svg';
import Style from 'components/TopNavBar/TopNavBar.module.css';
import { User, UserList } from 'modules/user/types';
import { userApi } from 'store/services/user.services';
import Loader from 'components/controls/Loader/Loader';
import SearchItem from 'components/TopNavBar/SearchItem';
import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';
import { Pagination } from 'modules/jobs/types';
import { defaultPagination } from 'helpers/utils';
import ApiResponse from 'modules/response/response.types';

const Search: FunctionComponent = () => {
  const [toggleResult, setToggleResult] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [result, setResult] = useState<UserList>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [pagination, setPagination] = useState<Pagination>(defaultPagination());

  useEffect(() => {
    const timeOutId = setTimeout(() => searchRecord(), 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  const searchRecord = () => {
    if (!search) {
      handleCloseSearchResult();
      return;
    }
    setLoading(true);
    setError('');
    fetchRecords(1);
  };

  const fetchRecords = (page: number) => {
    try {
      userApi.searchUser({ searchText: search, type: 'short', page }).then((res: ApiResponse) => {
        if (res.pagination) {
          setResult(res.pagination.page === 1 ? res.data : [...result, ...res.data]);
          setPagination(res.pagination);
        } else {
          setResult(res.data);
        }
        setLoading(false);
        setToggleResult(true);
      });
    } catch (e: any) {
      setLoading(false);
      setError(e.message);
    }
  };
  const handleCloseSearchResult = () => {
    setToggleResult(false);
    setSearch('');
    setResult([]);
    setPagination(defaultPagination());
  };

  const handleLoadMore = () => {
    fetchRecords(pagination.nextPage);
  };
  return (
    <>
      <div className="position-relative web-display-only">
        <input
          type="text"
          value={search}
          className={Style.customSearchbar}
          placeholder="Find Performer"
          onChange={handleOnChange}
        />
        {loading ? (
          <Loader cssClass="searchLoader" isSmallView />
        ) : (
          <img src={searchSvg} className={Style.searchIcon} alt="" />
        )}
        {toggleResult ? (
          <div className={Style.searchNotificationsWrapper}>
            <div className={Style.notificationListing}>
              <div className={`customScrollBar ${Style.notificationBody}`}>
                {error && <TextErrorMessage message={error} />}
                {result.length === 0 && <p className="text-center mb-0 mt-4">No Record Found</p>}
                <ul>
                  <li>
                    {result.map((u: User, index: number) => (
                      <SearchItem type="performerSearch" cssClass={Style.searchPerformerImage} item={u} key={index} />
                    ))}
                  </li>
                </ul>
                {pagination.nextPage > pagination.page && (
                  <div
                    onClick={handleLoadMore}
                    className={Style.loadMoreBtn}
                    onKeyPress={handleLoadMore}
                    role="button"
                    tabIndex={0}
                  >
                    Load more
                  </div>
                )}
              </div>
            </div>
            <div
              className={Style.profileBgColor}
              onClick={handleCloseSearchResult}
              onKeyPress={handleCloseSearchResult}
              role="button"
              tabIndex={0}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
export default Search;
