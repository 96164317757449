import React, { FunctionComponent, useContext } from 'react';
import { Link } from 'react-router-dom';
import Style from 'components/ProfileMenu/LeftNavigation/LeftNavigation.module.css';
import settingSvg from 'assets/svg/setting-green.svg';
import privacySvg from 'assets/svg/privacy.svg';
import helpSvg from 'assets/svg/help.svg';
import qrCodeSvg from 'assets/svg/qrCode.svg';
import taxDocumentIcon from 'assets/svg/tax-document.svg';
import recycleSvg from 'assets/svg/recycle.svg';
// import voucherSvg from 'assets/svg/voucher-green.svg';
// import calendarActiveSvg from 'assets/svg/calendar-active.svg';
import logoutSvg from 'assets/svg/logout-white.svg';
import Button from 'components/controls/button/button';
import { useDispatch, useSelector } from 'react-redux';
import { callLogout } from 'store/actions/auth.actions';
import { useHistory, useLocation } from 'react-router';
import { getUser } from 'store/selector/auth.selector';
import { ROLE_PERFORMER } from 'store/castingPax.constants';
import PusherContext from 'pusherContext';
import voucherSvg from 'assets/svg/voucher.svg';

const LeftNavigation: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUser);
  const location = useLocation();
  const { unSubscribeChannel } = useContext(PusherContext);

  const handleLogout = () => {
    unSubscribeChannel();
    dispatch(callLogout());
    history.push('/login');
  };
  const active = true;

  const itemCssClasses = (type: string) => {
    if (location.pathname.includes(type)) return `${Style.itemTitle} ${Style.selected}`;
    return Style.itemTitle;
  };

  return (
    <div className={`${Style.leftNavigationWrapper}`}>
      <div className={`${Style.itemWrapper}`}>
        <div className={`d-flex ${active && 'active'} ${Style.item}`}>
          <img src={settingSvg} alt="" />
          <Link to="/settings" className={itemCssClasses('settings')}>
            Settings
          </Link>
        </div>
        <div className={`d-flex ${active && 'active'} ${Style.item}`}>
          <img src={privacySvg} alt="" />
          <Link to="/privacy-setting" className={itemCssClasses('privacy-setting')}>
            Privacy
          </Link>
        </div>
        <div className={`d-flex ${active && 'active'} ${Style.item}`}>
          <img src={helpSvg} alt="" />
          <Link to="/help_center" className={itemCssClasses('help_center')}>
            Help Centre
          </Link>
        </div>

        {user.role === ROLE_PERFORMER && (
          <>
            <div>
              <p className={`mb-3 ${Style.payRoll}`}>Payroll</p>
            </div>
            <div className={`d-flex ${active && 'active'} ${Style.item}`}>
              <img src={voucherSvg} alt="voucher" />
              <Link to="/voucher_info" className={itemCssClasses('voucher_info')}>
                Voucher Info
              </Link>
            </div>
            <div className={`d-flex ${active && 'active'} ${Style.item}`}>
              <img src={qrCodeSvg} alt="" />
              <Link to="/qr_code" className={itemCssClasses('qr_code')}>
                QR Code
              </Link>
            </div>
            <div className={`d-flex ${active && 'active'} ${Style.item}`}>
              <img src={recycleSvg} alt="" />
              <Link to="/days" className={itemCssClasses('days')}>
                Days worked
              </Link>
            </div>
            <div className={`d-flex ${active && 'active'} ${Style.item}`}>
              <img src={recycleSvg} alt="" />
              <Link to="/residency_proof" className={itemCssClasses('/residency_proof')}>
                proof of residency docs
              </Link>
            </div>
            {/*  <div className={`d-flex ${active && 'active'} ${Style.item}`}>
              <img src={voucherSvg} alt="" />
              <Link to="/voucher_info" className={itemCssClasses('voucher')}>
                voucher info
              </Link>
            </div>
            <div className={`d-flex ${active && 'active'} ${Style.item}`}>
              <img src={calendarActiveSvg} alt="" />
              <Link to="/residency_proof" className={itemCssClasses('residency')}>
                proof of residency docs
              </Link>
            </div> */}
          </>
        )}
        <div className="d-flex align-items-center justify-content-center">
          <Button icon={logoutSvg} cssClass={Style.button} label="Logout" clickHandler={handleLogout} />
        </div>
      </div>
    </div>
  );
};

export default LeftNavigation;
