import React from 'react';
import styled from 'styled-components';

const PerformerCaellRenderer = (props: any) => {
  const { value, valueFormatted } = props;

  const statusCellValue = valueFormatted || value;
  console.log(statusCellValue, 'value');
  return (
    <Container>
      <TextBox>{value}</TextBox>
    </Container>
  );
};

export default PerformerCaellRenderer;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 5px;
  width: 160px;
`;

const TextBox = styled.p`
  text-align: 'left';
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
